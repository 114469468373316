import SubjectList from "@/components/eAristoStudent/subjects/SubjectList";

const eAristoStudentRootView = () => import(/* webpackChunkName: "student" */ '../views/eAristoStudent/eAristoStudentRootView');
const eAristoSubjectView = () => import(/* webpackChunkName: "student" */ "../views/eAristoStudent/eAristoSubjectView");
const eAristoStudentSuperAdminPreSelect = () => import(/* webpackChunkName: "student" */ "../views/eAristoStudent/eAristoStudentSuperAdminPreSelect");
import routeGuardStudent from "../routeGuards/routeGuardStudent";
import SubjectModuleList from "@/components/eAristoStudent/subjects/SubjectModuleList";
import eAristoStudentTopicsRootView from "@/views/eAristoStudent/eAristoStudentTopicsRootView";
import eAristoStudentTopicContentView from "@/views/eAristoStudent/eAristoStudentTopicContentView";
import eAristoStudentStatisticsView from "@/views/eAristoStudent/eAristoStudentStatisticsView";
import StatisticsIndex from "@/components/eAristoStudent/statistics/StatisticsIndex";

export default (router) => {
 return [
     {
         path: '/eAristoStudent',
         component: eAristoStudentRootView,
         name: 'eAristoStudentRoot',
         beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
         redirect: {name: 'eAristoStudentSuperAdminPreselect'},
         children: [
             {
                 path: 'earisto-student-sa-preselect',
                 name: "eAristoStudentSuperAdminPreselect",
                 component: eAristoStudentSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
             {
                 path: 'subjects',
                 name: 'eAristoStudentSubjects',
                 component: eAristoSubjectView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
                 redirect: {name: 'eAristoStudentSubjectsList'},
                 children: [
                     {
                         path: '',
                         name: 'eAristoStudentSubjectsList',
                         component: SubjectList,
                     },
                     {
                         path: ':subjectId',
                         name: 'eAristoStudentSubjectModuleList',
                         component: SubjectModuleList,
                     }
                 ]
             },
             {
                 path: 'statistics',
                 name: 'eAristoStudentStatistics',
                 component: eAristoStudentStatisticsView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
                 redirect: {name: 'eAristoStudentStatisticsIndex'},
                 children: [
                     {
                         path: '',
                         name: 'eAristoStudentStatisticsIndex',
                         component: StatisticsIndex,
                     },
                 ],
             },
             {
                 path: 'subjects/:subjectId/module/:moduleId?',
                 name: 'eAristoStudentSubjectModuleTopics',
                 component: eAristoStudentTopicsRootView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
             {
                 path: 'subjects/:subjectId/module/:moduleId/topic/:topic?',
                 name: 'eAristoStudentSubjectModuleTopicContent',
                 component: eAristoStudentTopicContentView,
                 beforeEnter: (to, from, next) => routeGuardStudent(to, from, next)(router),
             },
         ],
     },
 ];
}