export const isNumber = (event, decimals) => {
    const evt = (event) ? event : window.event;

    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
    } else {
        const value = evt.target.value;
        
        if ((value.length === 0 && charCode === 46) || (value.indexOf(".") > -1 && charCode === 46)) {
            evt.preventDefault();
        }
        if (value.indexOf(".") > -1 && value.split('.')[1].length >= decimals) {
            evt.preventDefault();
        }
        return true;
    }
};