<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <v-container fluid>
                    <department-subject-switcher
                            :is-open="isDepartmentSubjectSwitcherOpened"
                            :is-show-button="isDepSubjNavigation()"
                            @changeDialogState="isDepartmentSubjectSwitcherOpened = $event"
                            route-back="chiefdeptHome"
                            :route-to="switcherDialogRoute"
                            :type="navigationSelectorType"
                    />
                </v-container>

                <v-subheader class="mt-1 grey--name name--darken-1">Електронний журнал</v-subheader>
                <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";
    import {navigationSelectorTypesEnum} from "../../../constants/navigationSelectorTypesEnum";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        data: () => ({
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'turned_in',
                    name: 'Журнали',
                    route: {
                        name: 'headDeptRegistersRoot'
                    }
                },

                {
                    divider: true
                },

                {
                    icon: 'assessment',
                    name: 'Щомісячна атестація',
                    route: {
                        name: 'chiefDeptAttestation'
                    }
                },
                {
                    icon: 'access_time',
                    name: 'Перескл. ПМК',
                    route: {
                        name: 'headDeptReworksModulesRoot'
                    }
                },
                {
                    icon: 'equalizer',
                    name: 'Рейтинг',
                    children: [
                        {name: 'Рейтинг кафедри', route: {name: "currentDepartmentRating"}},
                        {name: 'Рейтинг НПП', route: {name: "headDeptRatingRoot"}},
                    ]
                },
            ],

            isDepartmentSubjectSwitcherOpened: false,
            switcherDialogRoute: "",

            navigationSelectorType: navigationSelectorTypesEnum.nonFiltered,
        }),
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);

            this.dialogNavigationDepartmentSubject();
        },
        watch: {
            $route (to, from){
                this.dialogNavigationDepartmentSubject();
            }
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            },
            isDepSubjNavigation() {
                const routeName = this.$route.name;

                if ((routeName === 'headDeptRegistersRoot' || routeName === 'headDeptReworksModulesRoot')) {
                    return this.$route.params.department !== undefined && this.$route.params.subject !== undefined;   
                }
                
                if (routeName === 'headDeptRatingRoot' || 
                    routeName === 'headDepartmentRating' || 
                    routeName === 'currentDepartmentRating') {
                    return this.$route.params.department !== undefined;
                }
            },
            isDepNavigation() {
                return (this.$route.params.department !== undefined);
            },
            dialogNavigationDepartmentSubject() {
                const routeName = this.$route.name;
                if ((routeName === 'headDeptRegistersRoot' || routeName === 'headDeptReworksModulesRoot')) {
                    
                    if (!this.isDepSubjNavigation()) {
                        this.isDepartmentSubjectSwitcherOpened = true;
                        this.headDeptRegistersRoot = routeName;
                    }
                    this.navigationSelectorType = navigationSelectorTypesEnum.nonFiltered;
                    
                }
                if (routeName === 'headDeptRatingRoot' || 
                    routeName === 'headDepartmentRating' || 
                    routeName === 'currentDepartmentRating') {

                    console.log("isDepNavigation")
                    if (!this.isDepNavigation()) {
                        this.isDepartmentSubjectSwitcherOpened = true;
                    }

                    this.navigationSelectorType = navigationSelectorTypesEnum.moderatorReworks;
                }
            },
        },
    }
</script>