import RatingRootView from '../views/rating/RatingRootView';
import RatingSuperAdminPreSelect from "../views/rating/RatingSuperAdminPreSelect";
import RatingHomeView from "../views/rating/RatingHomeView";

import routeGuardRating from "../routeGuards/routeGuardRating";
import RatingAllRootView from "../views/rating/RatingAllRootView";
import RatingUserDirectionsIndexRootView from "../views/rating/RatingUserDirectionsIndexRootView";


export default (router) => {
    return [
        {
            path: '/rating',
            component: RatingRootView,
            name: 'ratingRoot',
            beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
            redirect: {name: 'ratingSuperAdminPreselect'},
            children: [
                {
                    path: 'rating-sa-preselect',
                    name: "ratingSuperAdminPreselect",
                    component: RatingSuperAdminPreSelect,
                    beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
                },
                {
                    path: '',
                    name: 'ratingHome',
                    component: RatingHomeView,
                    beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
                },
                {
                    path: 'currentRating/:department?',
                    name: "currentRating",
                    component: RatingAllRootView,
                    beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
                },
                {
                    path: 'currentRating/:department?/ratingIndexes/:direction?',
                    name: "currentRatingWorkTypes",
                    component: RatingUserDirectionsIndexRootView,
                    beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
                },
            ],
        }
    ];
}