<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                width="250"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";
    import {navigationSelectorTypesEnum} from "../../../constants/navigationSelectorTypesEnum";
    import {rolesEnum} from "../../../constants/rolesEnum";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {            
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }
                    
                    if (this.$auth.userHasAtLeastOneRole(item.permissions)) {
                        return item;
                    }
                    
                    return;
                }).filter(Boolean);
            },
            isDepSubjNavigation() {
                if (this.$route.name === 'moderatorReworksRoot') {
                   return this.$route.params.department !== undefined;
                }
                
                return (this.$route.params.department !== undefined && 
                        this.$route.params.subject !== undefined);
            }
        },
        data: () => ({
            switcherDialogRoute: "",
            isDepartmentSubjectSwitcherOpened: false, 
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'list',
                    name: 'Напрями та показники діяльності',
                    route: {
                        name: 'ratingDirections'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist]
                },
                {
                    icon: 'timeline',
                    name: 'Рейтинг НПП (перевірка)',
                    route: {
                        name: 'ratingCheck'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist, rolesEnum.ratingistChecker]
                },
                {
                    icon: 'bar_chart',
                    name: '∑ рейтинг викладачів',
                    route: {
                        name: 'ratingProfessorStat'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist, rolesEnum.rector]
                },
                {
                    icon: 'clear_all',
                    name: '∑ рейтинг кафедр',
                    route: {
                        name: 'ratingDepartmentStat'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist, rolesEnum.rector]
                },
                {
                    icon: 'toc',
                    name: '∑ за напрямками',
                    route: {
                        name: 'ratingDirectionsStat'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist, rolesEnum.rector]
                },
                {
                    icon: 'view_list',
                    name: 'Перевіряючі',
                    route: {
                        name: 'ratingCheckers'
                    },
                    permissions: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.ratingist]
                },
            ],
            navigationSelectorType: navigationSelectorTypesEnum.moderator,
        })
    }
</script>