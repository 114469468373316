<template>
    <div v-if="!loading">
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(subject ,i) in statistics"
                :key="i"
            >
                <v-expansion-panel-header>
                    <div class="d-flex justify-space-between">
                        <div class="d-flex align-center">
                            <div v-if="subject.isPassed != null" class="mr-3">
                                <v-icon v-if="subject.isPassed" color="green" large>check_circle</v-icon>
                                <v-icon v-else color="gray" large>cancel</v-icon>
                            </div>
                            <div>{{subject.name}}</div>
                        </div>
                        <div class="d-flex" :style="getTopProgressWidth()">
                            <v-progress-linear
                                v-if="subject.progress != null"
                                :value="subject.progress"
                                height="25"
                            >
                                <strong>{{ subject.progress.toFixed(2) }}%</strong>
                            </v-progress-linear>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-treeview
                        rounded
                        hoverable
                        open-on-click
                        :items="formatTree(subject.eAristoModules)"
                    >
                        <template v-slot:prepend="{ item }">
                            <v-icon v-if="!item.children">
                                mdi-account
                            </v-icon>
                        </template>

                        <template v-slot:label="{ item }">
                            <div class="d-flex justify-space-between">
                                <div class="d-flex align-center">
                                    <div v-if="item.isPassed !== undefined" class="mr-3">
                                        <v-icon small v-if="item.isPassed === null" color="gray">remove_circle_outline</v-icon>
                                        <v-icon small v-if="item.isPassed === false" color="red">cancel</v-icon>
                                        <v-icon small v-if="item.isPassed === true" color="green">check_circle</v-icon>
                                    </div>
                                    <div>{{item.name}}</div>
                                </div>
                                <div v-if="item.progress !== null && item.progress !== undefined" 
                                     class="d-flex" 
                                     :style="getChildProgressWidth()"
                                >
                                    <v-progress-linear
                                        :value="item.progress"
                                        color="green"
                                        height="25"
                                    >
                                        <strong>{{ item.progress.toFixed(1) }}%</strong>
                                    </v-progress-linear>
                                </div>
                            </div>
                        </template>
                    </v-treeview>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
    <div v-else>
        <e-aristo-loading-placeholder></e-aristo-loading-placeholder>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import eAristoLoadingPlaceholder from "../common/eAristoLoadingPlaceholder";

export default {
    components: {
        eAristoLoadingPlaceholder,
    },
    data() {
        return {
            loading: false,
            statistics: [],
        }
    },
    mounted() {
        this.loading = true;
        HttpService.getInstance()
            .get("eAristoStudent/statistics")
            .then(res => {
                this.statistics = res.map(statistic => {
                    const specs = statistic.specialities.map(s => s.nameShortUk);

                    statistic.name = `${statistic.nameUk} (${specs.join(", ")})`;
                    
                    return statistic;
                });

                this.loading = false;
            });
    },
    methods: {
        formatTree(eAristoModules) {
            return eAristoModules.map(em => {
                return {
                    id: em.id,
                    name: `${em.orderBy}. ${em.nameUk}`,
                    isPassed: em.isPassed,
                    progress: em.progress,
                    children: em.eAristoTopics.map(et => {
                        return {
                            id: et.id,
                            name: `${et.orderNumber}. ${et.nameUk}`,
                            isPassed: et.isPassed,
                        };
                    })
                };
            });
        },
        
        getTopProgressWidth() {
            if (this.$vuetify.breakpoint.mobile) {
                return 'width: 150px;';
            }
            else {
                return 'min-width: 250px;';
            }
        },

        getChildProgressWidth() {
            if (this.$vuetify.breakpoint.mobile) {
                return 'width: 100px;';
            }
            else {
                return 'min-width: 200px;';
            }
        }
    }
}
</script>