import {rolesEnum} from "./rolesEnum";

export const cardItemsInitial = [
    {
        titlePath: "navigation.admin.title",
        descriptionPath: "navigation.admin.description",
        link: "adminRoot",
        color: "red darken-3",
        role: [rolesEnum.admin, rolesEnum.edudept],
    },
    {
        titlePath: "navigation.dean.title",
        descriptionPath: "navigation.dean.description",
        link: "deanRoot",
        color: "deep-orange accent-4",
        role: [rolesEnum.admin, rolesEnum.dean, rolesEnum.inspector],
    },
    {
        titlePath: "navigation.chiefdept.title",
        descriptionPath: "navigation.chiefdept.description",
        link: "chiefdeptRoot",
        color: "orange darken-3",
        role: [rolesEnum.admin, rolesEnum.headDepartment],
    },
    {
        titlePath: "navigation.moderator.title",
        descriptionPath: "navigation.moderator.description",
        link: "moderatorRoot",
        color: "light-blue darken-2",
        role: [rolesEnum.admin, rolesEnum.moderator],
    },
    {
        titlePath: "navigation.teacher.title",
        descriptionPath: "navigation.teacher.description",
        link: "teacherRoot",
        color: "blue darken-4",
        role: [rolesEnum.professor, rolesEnum.admin, rolesEnum.moderator, rolesEnum.headDepartment, rolesEnum.curator],
    },
    {
        titlePath: "navigation.teacherFeedback.title",
        descriptionPath: "navigation.teacherFeedback.description",
        link: "feedbackRoot",
        color: "deep-purple darken-2",
        role: [rolesEnum.admin, rolesEnum.professor],
    },
    {
        titlePath: "navigation.reworks.title",
        descriptionPath: "navigation.reworks.description",
        link: "reworksRoot",
        color: "deep-orange darken-4",
        role: [rolesEnum.admin, rolesEnum.professor],
    },
    {
        titlePath: "navigation.student.title",
        descriptionPath: "navigation.student.description",
        link: "studentRoot",
        color: "indigo darken-3",
        role: [rolesEnum.admin, rolesEnum.student],
    },
    {
        titlePath: "navigation.rating.title",
        descriptionPath: "navigation.rating.description",
        link: "ratingRoot",
        color: "cyan darken-3",
        role: [rolesEnum.admin, rolesEnum.professor],
    },
    {
        titlePath: "navigation.ratingAdmin.title",
        descriptionPath: "navigation.ratingAdmin.description",
        link: "ratingAdminRoot",
        color: "teal darken-4",
        role: [rolesEnum.admin, rolesEnum.ratingist, rolesEnum.ratingistChecker, rolesEnum.rector],
    },
    {
        titlePath: "navigation.labstaff.title",
        descriptionPath: "navigation.labstaff.description",
        link: "labstaffRoot",
        color: "light-blue darken-2",
        role: [rolesEnum.admin, rolesEnum.labstaff],
    },
    {
        titlePath: "navigation.finalExam.title",
        descriptionPath: "navigation.finalExam.description",
        link: "finalExamRoot",
        color: "red darken-2",
        role: [rolesEnum.admin, rolesEnum.finalExam, rolesEnum.finalExamAdmin, rolesEnum.finalExamStudent],
    },

    {
        titlePath: "navigation.superAdminKrok.title",
        titleConst: "superAdminKrok",
        descriptionPath: "navigation.superAdminKrok.description",
        link: "superAdminStepRoot",
        color: "red darken-3",
        role: [rolesEnum.admin, rolesEnum.edudept],
    },
    {
        titlePath: "navigation.modulesKrok.title",
        titleConst: "modulesKrok",
        descriptionPath: "navigation.modulesKrok.description",
        link: "stepAdmin",
        color: "blue accent-2",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.professor],
    },
    {
        titlePath: "navigation.spaKrok.title",
        titleConst: "spaKrok",
        descriptionPath: "navigation.spaKrok.description",
        link: "stepAdminSpa",
        color: "indigo darken-3",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.professor],
    },
    {
        titlePath: "navigation.profileKrok.title",
        titleConst: "profileKrok",
        descriptionPath: "navigation.profileKrok.description",
        link: "stepAdminProfile",
        color: "deep-orange darken-1",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.dean],
    },
    {
        titlePath: "navigation.modulesQuizList.title",
        titleConst: "quizList",
        descriptionPath: "navigation.modulesQuizList.description",
        link: "quizListRoot",
        color: "red darken-4",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.student],
    },
    {
        titlePath: "navigation.nbSupervisor.title",
        titleConst: "nbSuperVisorRoot",
        descriptionPath: "navigation.nbSupervisor.description",
        link: "nbSuperVisorRoot",
        color: "red darken-4",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.reworksSupervisor],
    },
    {
        titlePath: "navigation.nbQuiz.title",
        titleConst: "nbQuizAdmin",
        descriptionPath: "navigation.nbQuiz.description",
        link: "nbQuizAdminRoot",
        color: "cyan darken-4",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.professor],
    },
    {
        titlePath: "navigation.eAristoStudent.title",
        titleConst: "eAristoStudent",
        descriptionPath: "navigation.eAristoStudent.description",
        link: "eAristoStudentRoot",
        color: "light-blue darken-4",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.student],
    },
    {
        titlePath: "navigation.eAristoProfessor.title",
        titleConst: "eAristoProfessor",
        descriptionPath: "navigation.eAristoProfessor.description",
        link: "eAristoProfessorRoot",
        color: "light-blue darken-1",
        role: [rolesEnum.admin, rolesEnum.edudept, rolesEnum.moderator],
    },
];