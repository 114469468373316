<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex>
                <rating-directions-index :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import RatingDirectionsIndex from "../../components/rating/ratingDirections/RatingDirectionsIndex";

    export default {
        components: {
            NavigationListTemplate,
            RatingDirectionsIndex
        },
        data: () => ({}),
    }
</script>