<template>
    <v-flex>
        <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                :nudge-right="40"
                :return-value.sync="dateTime"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-on="on"
                        v-model="formattedTime"
                        :label="label"
                        :error-messages="errorMessages"
                        prepend-icon="access_time"
                        readonly
                ></v-text-field>
            </template>
            <v-time-picker
                    v-if="menu"
                    format="24hr"
                    v-model="dateTimeLocal"
                    @change="save"
                    :min="minDateLocal"
                    :max="maxDateLocal"
            ></v-time-picker>
        </v-menu>
    </v-flex>
</template>
<script>
    import moment from "moment";

    export default {
        data: () => ({
            menu: false,
            dateTimeLocal: null,
        }),
        props: {
            value: {
                type: [Date, String],
            },
            errorMessages: {
                type: [Array, String],
            },
            label: {
                type: String,
                default: "Please, chose a time"
            },
            minDate: {
                type: [Date, String],
                default: undefined,
            },
            maxDate: {
                type: [Date, String],
                default: undefined,
            },
        },
        computed: {
            formattedTime: {
                get() {
                    if (this.dateTime !== null && this.dateTime !== undefined) {
                        return moment(this.dateTime).format("HH:mm");
                    }

                    return undefined;
                },
                set(time) {
                    const formattedDate = moment(this.dateTime).format("YYYY-MM-DD");
                    this.dateTime = moment(`${formattedDate}T${time}`).toDate();
                }
            },
            dateTime: {
                get() {
                    return this.formatTime(this.value);
                },
                set() {},
            },
            minDateLocal: {
                get() {
                    return this.formatTime(this.minDate);
                },
                set() {}
            },
            maxDateLocal: {
                get() {
                    return this.formatTime(this.maxDate);
                },
                set() {}
            }
        },
        methods: {
            formatTime(dateTime) {
                if (dateTime !== null && dateTime !== undefined) {
                    const momentObj = moment(dateTime);
                    let offsetInMinutes = momentObj.utcOffset();
                    const momentAndOffset = momentObj.utc().add(0, 'm');

                    return momentAndOffset.format("YYYY-MM-DD HH:mm");
                } else {
                    return null;
                }
            },
            save(time) {
                const formattedDate = moment.utc(this.value).format("YYYY-MM-DD");
                const dateTimeToSave = moment.utc(`${formattedDate}T${time}`).toDate();
                
                this.$refs.menu.save(dateTimeToSave);

                if (this.menu) {
                    this.$emit("input", moment.utc(dateTimeToSave).toDate());
                    this.$emit("change", moment.utc(dateTimeToSave).toDate());
                }
            },
        }
    }
</script>