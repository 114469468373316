<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th>№ п/п</th>
                    <th>ПІП</th>
                    <th>Код</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(student, index) in students">
                    <td>{{index + 1}}.</td>
                    <td>{{student.surname}} {{student.name}}</td>
                    <td>{{student.quizAttemptCode}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    export default {
        props: {
            students: {
                type: Array,
                default: () => [],
            }
        }
    }
</script>