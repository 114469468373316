<template>
    <v-app>
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <super-admin-toolbar
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-content>
            <router-view/>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import SuperAdminToolbar from "../../components/superAdminStep/common/SuperAdminToolbar";
    import NavigationDrawer from "../../components/superAdminStep/common/NavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            SuperAdminToolbar,
            navigation: NavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
        methods: {
            isRouteWithNav() {
                return this.$route.params.group !== undefined;
            }
        },
    }
</script>
