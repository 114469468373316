<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout v-if="!$vuetify.breakpoint.mobile" row wrap style="width: 100%;">
            <v-flex v-if="showTopicsBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'eAristoStudentSubjectModuleTopics', 
                            params: { subjectId: $route.params.subjectId, moduleId: $route.params.moduleId }}" 
                           color="primary"
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToTopicsList")}}</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">{{$t("eAristoStudent.topics")}}</v-subheader>
                            <navigation-list-template :items="topics"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.moduleId" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("eAristoStudent.selectTopic")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <topic-content-student :key="$route.fullPath" v-if="$route.params.moduleId"/>
            </v-flex>
        </v-layout>


        <v-layout v-if="$vuetify.breakpoint.mobile" row wrap style="width: 100%;">
            <v-flex v-if="showTopicsBlock()" style="width: 100%;">
                <v-btn :to="{name: 'eAristoStudentSubjectModuleTopics', 
                            params: { subjectId: $route.params.subjectId, moduleId: $route.params.moduleId }}"
                       color="primary"
                       class="px-3"
                ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToTopicsList")}}</v-btn>
                <v-card>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="'100%'">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">{{$t("eAristoStudent.topics")}}</v-subheader>
                            <navigation-list-template :items="topics"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="width: calc(100% - 10px);">
                <v-card v-if="!$route.params.moduleId" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("eAristoStudent.selectTopic")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <topic-content-student :key="$route.fullPath" v-if="$route.params.moduleId"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import TopicContentStudent from "../../components/eAristoStudent/topicContent/TopicContentStudent";
    import HttpService from "../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            TopicContentStudent,
        },
        data: () => ({
            topics: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`eAristoStudent/getTopics/${this.$route.params.moduleId}`)
                .then(resp => {
                    this.topics = resp.map(topic => {
                        topic.name = `${topic.orderNumber}. ${topic.nameUk}`;
                        topic.route = {
                            name: 'eAristoStudentSubjectModuleTopicContent',
                            params: {
                                subjectId: this.$route.params.subjectId,
                                moduleId: this.$route.params.moduleId,
                                topic: topic.id,
                            }
                        };

                        return topic;
                    });
                });
        },
        methods: {
            showTopicsBlock() {
                return this.$route.name === "eAristoStudentSubjectModuleTopicContent" 
                    && this.$route.params.subjectId 
                    && this.$route.params.moduleId;
            },

        },
    }
</script>