import eAristoRootProfessorRootView from '../views/eAristoProfessor/eAristoRootProfessorRootView';
import eAristoProfessorHomeView from "../views/eAristoProfessor/eAristoProfessorHomeView";
import eAristoModulesView from "../views/eAristoProfessor/eAristoModulesView";
import eAristoProfessorTopicsRootView from "../views/eAristoProfessor/eAristoProfessorTopicsRootView";
import eAristoProfessorSuperAdminPreSelect from "../views/eAristoProfessor/eAristoProfessorSuperAdminPreSelect";
import routeGuardeAristo from "../routeGuards/routeGuardeAristo";
import eAristoProfessorTopicContentRootView from "@/views/eAristoProfessor/eAristoProfessorTopicContentRootView";

export default (router) => {
    return [
        {
            path: '/eAristo',
            component: eAristoRootProfessorRootView,
            name: 'eAristoProfessorRoot',
            beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
            redirect: {name: 'eAristoProfessorSuperAdminPreselect'},
            children: [
                {
                    path: 'eAristo-sa-preselect',
                    name: "eAristoProfessorSuperAdminPreselect",
                    component: eAristoProfessorSuperAdminPreSelect,
                    beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
                },
                {
                    path: '',
                    name: 'eAristoProfessorHome',
                    component: eAristoProfessorHomeView,
                    beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
                },
                {
                    path: 'eAristoEducationalPlan/:department?/:subject?',
                    name: "eAristoEducationalPlan",
                    component: eAristoModulesView,
                    beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
                },
                {
                    path: 'eAristoEducationalPlan/:department?/:subject?/module/:module?',
                    name: "eAristoEducationalTopicsPlan",
                    component: eAristoProfessorTopicsRootView,
                    beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
                },
                {
                    path: 'eAristoEducationalPlan/:department/:subject/module/:module/topic/:topic',
                    name: "eAristoEducationalTopicContent",
                    component: eAristoProfessorTopicContentRootView,
                    beforeEnter: (to, from, next) => routeGuardeAristo(to, from, next)(router),
                },
            ],
        },
    ];
}