<template>
    <v-layout row wrap xs12>
        <v-flex xs3>
            <v-select
                    :items="specialities"
                    v-model="form.speciality"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Спеціальність"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-select
                    :items="faculties"
                    v-model="form.faculty"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Факультет"
                    :disabled="form.speciality === null"
            ></v-select>
        </v-flex>
        <v-flex xs3 v-if="form.finalExamName !== null">
            <v-btn @click="getRangeList()" :disabled="loading" color="primary">Переглянути статистику</v-btn>
        </v-flex>
        <v-flex xs12>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Назва іспиту</th>
                        <th class="text-left">К-сть "5"</th>
                        <th class="text-left">% "5"</th>
                        <th class="text-left">К-сть "4"</th>
                        <th class="text-left">% "4"</th>
                        <th class="text-left">К-сть "3"</th>
                        <th class="text-left">% "3"</th>
                        <th class="text-left">Загальна к-сть оцінок</th>
                        <th class="text-left">Середня оцінка</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in statistics" :key="item.name">
                        <td>{{item.finalExamName.name}}</td>
                        <td>{{item.count5}}</td>
                        <td>{{item.percent5}}</td>
                        <td>{{item.count4}}</td>
                        <td>{{item.percent4}}</td>
                        <td>{{item.count3}}</td>
                        <td>{{item.percent3}}</td>
                        <td>{{item.totalCount}}</td>
                        <td>{{item.totalAverage}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../../../HttpService";

    export default {
        data: () => ({
            specialities: [],
            faculties: [],

            form: {
                speciality: null,
                faculty: null,
            },
            
            statistics: [],
            loading: false,
        }),
        watch: {
            "form.speciality": function (newSpec, oldSpec) {
                if (newSpec !== null) {
                    HttpService.getInstance()
                        .get(`faculties/getAllBySpeciality/${newSpec.id}`)
                        .then(resp => {
                            this.faculties = resp;
                        })
                }
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("specialities/getAllWithFinalExam")
                .then(resp => {
                    this.specialities = resp;
                })
        },
        methods: {
            getRangeList() {
                this.loading = true;
                HttpService.getInstance()
                .get(`finalExamAdminReports/getFinalExamStatistics/${this.form.faculty.id}/${this.form.speciality.id}`)
                .then(resp => {
                    this.loading = false;
                    this.statistics = resp;
                });
            },
        }
    }
</script>