<template>
    <v-layout row wrap xs12>
        <v-flex xs3>
            <v-select
                    :items="specialities"
                    v-model="form.speciality"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Спеціальність"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-select
                    :items="faculties"
                    v-model="form.faculty"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Факультет"
                    :disabled="form.speciality === null"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-btn :disabled="form.faculty === null" @click="printDialog = true">
                <v-icon class="mr-2">print</v-icon>Роздрукувати підсумковий протокол</v-btn>
        </v-flex>

        <v-dialog v-model="printDialog" max-width="1300px">
            <print-final-protocol
                    v-if="printDialog"
                    :faculty-id="form.faculty.id"
                    :speciality-id="form.speciality.id"
                    @close="printDialog = false"
            ></print-final-protocol>
        </v-dialog>
    </v-layout>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import PrintFinalProtocol from "./PrintFinalProtocol";

    export default {
        components: {
          PrintFinalProtocol,  
        },
        data: () => ({
            specialities: [],
            faculties: [],

            form: {
                speciality: null,
                faculty: null,
            },
            printDialog: false,
        }),
        watch: {
            "form.speciality": function (newSpec, oldSpec) {
                if (newSpec !== null) {
                    HttpService.getInstance()
                        .get(`faculties/getAllBySpeciality/${newSpec.id}`)
                        .then(resp => {
                            this.faculties = resp;
                        })
                }
            },
        },
        mounted() {
            HttpService.getInstance()
                .get("specialities/getAllWithFinalExam")
                .then(resp => {
                    this.specialities = resp;
                })
        }
    }
</script>