<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>

            <v-flex style="height: calc(100% - 58px); position: fixed; width: 160px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-list-item :to="{name: 'professorsDepartmentsGrid'}"
                                         exact-active-class="list-title-active">
                                <v-list-item-content>
                                    <v-list-item-title>Всі кафедри</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-subheader class="mt-4 grey--name name--darken-1">Кафедри</v-subheader>
                            <navigation-list-template :items="departments"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 160px; width: calc(100% - 460px);">
                <v-card>
                    <professors-index :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import ProfessorsIndex from "../../../../components/admin/Dictionaries/Professors/ProfessorsIndex";
    import NavigationListTemplate from "../../../../components/common/NavigationListTemplate";

    export default {
        components: {
            ProfessorsIndex,
            NavigationListTemplate
        },
        mounted() {
            HttpService.getInstance()
                .get("departments/getAll")
                .then(res => {
                    this.departments = res.map(department => {
                        department.name = department.nameShortUk;
                        department.route = {
                            name: 'professorsDepartmentsGrid',
                            params: {
                                departmentId: department.id,
                            }
                        };

                        return department;
                    });
                });
        },
        data: () => {
            return {
                departments: [],
            };
        }
    }
</script>
<style lang="scss">
    .list-title-active {
        background-color: rgba(0, 0, 0, 0.12);
    }
    
    .v-list-item--active {
        background-color: transparent !important;
    }
</style>