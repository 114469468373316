<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <help-desk-my-tickets/>
        </v-layout>
    </v-container>
</template>
<script>
    import HelpDeskMyTickets from "../../../components/dashboard/helpdesk/HelpDeskMyTickets";
    
    export default  {
        components: {
            HelpDeskMyTickets,
        }
    }
</script>