<template>
    <div>
        <v-layout row wrap class="mt-6">
            <v-flex xs12 text-center>
                <router-link :to="{name: 'announcementsList'}" class="display-1 cursor-pointer">
                    {{ $t("announcements.title") }}
                </router-link>
            </v-flex>
        </v-layout>
        <v-layout row wrap justify-center>
            <v-flex v-if="announcements.length === 0" xs12 sm12>
                <v-alert outlined color="info" icon="info" :value="true">
                    <div class="subtitle-1">{{ $t("announcements.norecords") }}</div>
                </v-alert>
            </v-flex>
            <v-flex xs12 sm6 v-for="(announcement, index) in announcements" :key="index">
                <announcement-short :announcement="announcement"/>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import HttpService from "../../HttpService";
    import AnnouncementShort from "./AnnouncementShort";
    
    export default {
        components: {
            AnnouncementShort  
        },
        data: () => ({
           announcements: [], 
        }),
        mounted() {
            HttpService.getInstance()
                .get("announcement")
                .then(resp => {
                    this.announcements = resp;
                })
        },
    }
</script>