<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <search-bar
                        :loading="loading"
                        input-label="ПІП студента..."
                        @search="getItemsSearch($event)"
                />
                <v-flex xs12>
                    <expelled-academic-vacation-grid
                            :items="items"
                            :total="total"
                            :loading="loading"
                            @paginate="onPaginate"
                            @change="dialogMove = true"
                            :selectedItems.sync="selectedItems"
                    >
                    </expelled-academic-vacation-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialogMove" max-width="450">
            <restore-students-dialog
                    v-if="dialogMove"
                    :selected="selectedItems"
                    @shouldCloseGroupMove="dismissDialogs"
                    @groupMoved="groupMoved"
            ></restore-students-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import ExpelledAcademicVacationGrid from "./ExpelledAcademicVacationGrid";
    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import RestoreStudentsDialog from "./RestoreStudentsDialog";
    import SearchBar from "../../common/SearchBar";

    export default {
        components: {
            ExpelledAcademicVacationGrid,
            RestoreStudentsDialog,
            SearchBar,
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                dialogMove: false,
                selectedItems: [],
            }
        },
        methods: {
            getItemsSearch(searchString){
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination));
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`student/getAllExpelledAcademVacation?${queryParams}`)
                    .then(students => {
                        this.items = students.items;
                        this.total = students.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            dismissDialogs() {
                this.dialogMove = false;

                this.selectedItems = [];
                this.items = [];
                this.total = 0;

                this.getItems();
            },
            groupMoved() {
                this.dialogMove = false;
                this.getItems();

                this.selectedItems = [];
                this.items = [];
                this.total = 0;
            },
        },
    }
</script>