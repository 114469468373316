<template>
    <v-navigation-drawer
            fixed
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :fixed="true"
            :value="drawer"
            width="260"
            @input="$emit('toggleDrawer', $event)"
    >
        <v-list dense>
            <v-subheader class="mt-3 grey--name name--darken-1">КРОК</v-subheader>
            <v-subheader class="mt-3 grey--name name--darken-1">Довідники</v-subheader>
            <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'grain',
                    name: 'Інше',
                    children: [
                        {name: 'КРОК-и', route: {name: "krokGrid"}},
                        {name: 'Профілі', route: {name: "profilesGrid"}},
                        {name: 'Пули тестових завдань', route: {name: "questionPoolsGrid"}},
                        {name: 'Дисципліна-пул тестів', route: {name: "subjectPoolGrid"}},
                        {name: 'Статистика складань', route: {name: "stats"}},
                    ]
                },
                {
                    divider: true
                },
            ],
        })
    }
</script>