<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-card>
                        <v-card-title>Виберіть викладача, щоб переглянути аккаунт</v-card-title>
                        <v-card-text>
                            <v-tabs
                                v-model="tab"
                            >
                                <v-tab key="all">
                                    Глобальний пошук
                                </v-tab>
                                <v-tab key="byDept">
                                    Уточнююючий пошук
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item key="all">
                                    <v-container>
                                        <v-layout
                                            text-center
                                            wrap
                                        >
                                            <v-flex xs12 class="mt-6">
                                                <v-autocomplete
                                                    :items="professors"
                                                    v-model="selectedProfessor"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    solo
                                                    label="Викладач"
                                                ></v-autocomplete>
                                            </v-flex>
                                            <v-flex v-if="selectedProfessor" xs12 class="mt-6">
                                                <v-btn :disabled="loadingSave" @click="putData()" large dark
                                                       color="red darken-2">Перейти
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-tab-item>

                                <v-tab-item key="byDept">
                                    <v-container>
                                        <v-layout
                                            text-center
                                            wrap
                                        >
                                            <v-flex xs12 class="mt-6">
                                                <v-container grid-list-md>
                                                    <v-layout v-if="!loading" wrap>
                                                        <v-select
                                                            :items="departments"
                                                            v-model="selectedDepartment"
                                                            item-text="nameUk"
                                                            item-value="id"
                                                            return-object
                                                            solo
                                                            label="Кафедра"
                                                        ></v-select>
                                                    </v-layout>
                                                    <v-layout v-if="!loading && selectedDepartment" wrap>
                                                        <v-autocomplete
                                                            :items="professorsByDepartment"
                                                            v-model="selectedProfessor"
                                                            item-text="name"
                                                            item-value="id"
                                                            return-object
                                                            solo
                                                            label="Викладач"
                                                        ></v-autocomplete>
                                                    </v-layout>
                                                </v-container>
                                            </v-flex>
                                            <v-flex v-if="selectedProfessor" xs12 class="mt-6">
                                                <v-btn :disabled="loadingSave" @click="putData()" large dark
                                                       color="red darken-2">Перейти
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-tab-item>

                            </v-tabs-items>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import HttpService from "../../HttpService";
import {rolesEnum} from "../../constants/rolesEnum";

export default {
    data: () => ({
        loading: false,

        tab: null,

        departments: [],

        selectedDepartment: null,
        professorsByDepartment: [],

        loadingSave: false,

        selectedProfessor: null,
        professors: [],
    }),
    mounted() {
        const roles = this.getUserRole();

        if (roles.indexOf(rolesEnum.admin) > -1) {

            this.loading = true;
            HttpService.getInstance()
                .get(`professors/getAll`)
                .then(resp => {
                    this.loading = false;

                    this.professors = resp.map(prof => {
                        let profNamePrefix = "";

                        if (prof.academicPosition) {
                            profNamePrefix = `${prof.academicPosition.nameShortUk} `;
                        }

                        prof.name = `${profNamePrefix}${prof.surname} ${prof.name} ${prof.patronymic}`;

                        return prof;
                    });
                });

            this.loading = true;

            HttpService.getInstance()
                .get("departments/getAll")
                .then(resp => {
                    this.loading = false;
                    this.departments = resp
                });
        } else {
            this.$router.push({name: "nbQuizAdminHome"}).catch(err => {});
        }

    },
    methods: {
        getUserRole() {
            return this.$auth.getRoles();
        },
        putData() {
            this.loadingSave = true;

            HttpService.getInstance()
                .put("superAdmin/setProfessor", this.selectedProfessor)
                .then(resp => {
                    this.loadingSave = false;

                    this.$router.push({name: "nbQuizAdminHome"}).catch(err => {});
                })
                .catch(err => {
                    this.loadingSave = false;
                });
        }
    },
    watch: {
        selectedDepartment: function(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                HttpService.getInstance()
                    .get(`professors/getAllByDepartment/${this.selectedDepartment.id}`)
                    .then(resp => {
                        this.loading = false;

                        this.professorsByDepartment = resp.map(prof => {
                            let profNamePrefix = "";

                            if (prof.academicPosition) {
                                profNamePrefix = `${prof.academicPosition.nameShortUk} `;
                            }

                            prof.name = `${profNamePrefix}${prof.surname} ${prof.name} ${prof.patronymic}`;

                            return prof;
                        });
                    });
            }
        }
    }
}
</script>