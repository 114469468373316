import AuthView from '../views/common/AuthView';

import CallbackView from '../views/common/CallbackView';
import SilentCallbackView from '../views/common/SilentCallbackView';

export default (router) => {
    return [
        {
            path: '/auth',
            name: 'auth',
            component: AuthView,
            children: [
                {
                    path: 'callback',
                    name: 'callback',
                    component: CallbackView,
                },
                {
                    path: 'silentcallback',
                    name: 'silentcallback',
                    component: SilentCallbackView,
                }
            ]
        }
    ];
}
