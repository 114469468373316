<template>
    <v-card>
        <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0">Налаштування студента</h3>
            </div>
        </v-card-title>
        <v-card-text>
            <form @submit="submitForm" novalidate="true">
                <v-flex xs12>
                    <v-switch
                            label="Показувати своє прізвище та ім'я при висвітленні середніх оцінок в групі"
                            v-model="form.shareAchievementsWithGroup"
                    ></v-switch>
                </v-flex>
                <v-flex xs12>
                    <v-switch
                            label="Показувати своє прізвище та ім'я при висвітленні середніх оцінок на факультеті"
                            v-model="form.shareAchievementsWithFaculty"
                    ></v-switch>
                </v-flex>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                    >Зберегти
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card-text>
    </v-card>
</template>
<script>
    import {remapErrors} from "../../../helpers/errorsHelper";
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            loading: false,

            form: {
                shareAchievementsWithGroup: false,
                shareAchievementsWithFaculty: false,
            },
            errors: {
                shareAchievementsWithGroup: null,
                shareAchievementsWithFaculty: null,
            }
        }),
        mounted() {
            this.$ga.event('student', 'settings', 'open');

            HttpService.getInstance()
                .get("settings/studentSettings")
                .then(resp => {
                    this.loading = false;
                    this.form = resp;
                });
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.$ga.event('student', 'settings', 'save');

                this.loading = true;
                
                HttpService.getInstance()
                    .put("settings/studentSettings", this.form)
                    .then(resp => {
                        this.loading = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loading = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>