<template>
    <v-container fluid fill-height>
        <v-layout v-if="!isMobile()" row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :width="300" :mobile-break-point="0">
                        <v-list dense>
                            <v-subheader class="mt-3 grey--name name--darken-1">{{$t("studentKrok.topics")}}</v-subheader>
                            <navigation-list-template @selectValue="setTopNavigationItem($event)" :items="topics"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>
            <v-flex style="margin-left: 310px; width: calc(100% - 310px);">

                <quiz-list-wrapper :key="$route.fullPath" v-if="$route.params.topic"/>

                <v-card v-if="!$route.params.topic">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("studentKrok.selectTopic")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
        
        <v-layout v-if="isMobile()" row wrap>
            <v-flex class="mb-2">
                <v-card height="100%">
                    <v-list v-if="!$route.params.topic" dense>
                        <v-subheader class="mt-3 grey--name name--darken-1">{{$t("studentKrok.topics")}}</v-subheader>
                        <navigation-list-template @selectValue="setTopNavigationItem($event)" :items="topics"/>
                    </v-list>
                    <v-expansion-panel v-if="$route.params.topic" v-model="expansionIndex">
                        <v-expansion-panel-content>
                            <template v-slot:header>
                                <div>{{getTopNavigationTitle}}</div>
                            </template>
                            <navigation-list-template @selectValue="onSelectTopic($event)" :items="topics"/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-card>
            </v-flex>
            <v-flex>

                <quiz-list-wrapper :key="$route.fullPath" v-if="$route.params.topic"/>

                <v-card v-if="!$route.params.topic">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("studentKrok.selectTopic")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import HttpService from "../../HttpService";
    import QuizListWrapper from "./QuizListWrapper";
    import {mapMutations} from "vuex";
    import {mapGetters} from "vuex";

    export default {
        components: {
            NavigationListTemplate,
            QuizListWrapper,
        },
        mounted() {
            this.getTopics();
        },
        computed: {
            ...mapGetters(['getTopNavigationTitle']),
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            isMobile() {
                return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm";
            },
            onSelectTopic(value) {
                this.setTopNavigationItem(value);
                this.expansionIndex = null;
            },
            getTopics() {
                HttpService.getInstance()
                    .get(`teacher/navigation/studentTopics/${this.$route.params.subject}/${this.$route.params.module}`)
                    .then(res => {
                        this.topics = res.map(topic => {
                            return {
                                name: `${topic.classNumber}. ${topic.orderNumber}. ${topic.nameUk} (${topic.academicHours} год.)`,
                                route: {
                                    name: 'quizList',
                                    params: {
                                        topic: topic.id,
                                    }
                                }
                            }
                        });
                    });
            }
        },
        data: () => {
            return {
                topics: [],
                expansionIndex: null,
            }
        },
        watch: {
            "$route.params": function (newParams, oldParams) {
                if (newParams.module !== oldParams.module
                ) {
                    this.getTopics();
                }
            },
            "expansionIndex": function(newIndex, oldIndex) {
                console.log("newIndex", newIndex);
                console.log("oldIndex", oldIndex);
            }
        }
    }
</script>