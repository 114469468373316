<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <settings-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import SettingsIndex from "../../components/dashboard/settings/SettingsIndex";
    
    export default  {
        components: {
            SettingsIndex,
        }
    }
</script>