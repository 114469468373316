<template>
    <v-card>
        <v-card-title class="title">Тестування</v-card-title>
        <v-card-text>
            <div v-if="studentsCodes.length === 0">
                Ви впевнені, що хочете розпочати тестування для вибраних студентів?
                <hr>
                <div class="caption">{{formattedSelectedStudents()}}</div>
            </div>
            <div v-if="studentsCodes.length !== 0">
                <group-codes-print ref="print" :students="studentsCodes"/>
            </div>
        </v-card-text>
        <v-card-actions v-if="studentsCodes.length === 0">
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    @click.native="startQuiz"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
        <v-card-actions class="no-print" v-if="studentsCodes.length !== 0">
            <v-spacer></v-spacer>
            <v-btn
                    color="warning display-none"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Закрити
            </v-btn>
            <v-btn
                    class="display-none"
                    color="primary"
                    @click.native="print"
            >Надрукувати
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import GroupCodesPrint from "./GroupCodesPrint";
    import { Printd } from 'printd';

    export default {
        data: () => ({
            loading: false,
            studentsCodes: [],
            printD: new Printd(),
            cssText: `
                .title,
                .display-none {
                   display: none !important;
                },
                table.table {
                   width: 100%;
                   margin-bottom: 1rem;
                   background-color: transparent;
                   border-collapse: collapse;
                }

                table.table th, table.table td {
                   padding: .2rem .75rem;
                   vertical-align: top;
                   border-top: 1px solid #dee2e6;
                }
                table.table thead th {
                   vertical-align: bottom;
                   border-bottom: 2px solid #dee2e6;
                }
            `
        }),
        components: {
            GroupCodesPrint  
        },
        props: {
            selectedStudents: {
                type: Array,
                default: () => [],
            },
            startGroupQuizUrl: String,
        },
        mounted() {
          this.loading = false;
          this.studentsCodes = [];
        },
        methods: {
            print() {
                this.printD.print(this.$el, this.cssText);
            },
            startQuiz() {
                this.loading = true;

                HttpService.getInstance()
                    .post(this.startGroupQuizUrl,
                        {
                            students: this.selectedStudents,
                            module: {id: this.$route.params.module},
                            subject: {id: this.$route.params.subject},
                            stepProfile: {id: this.$route.params.profile}
                        }
                    )
                    .then((resp) => {
                        this.studentsCodes = resp;
                        
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            formattedSelectedStudents() {
                return this.selectedStudents.map(s => `${s.surname} ${s.name}`).join(", ");
            }
        }
    }
</script>