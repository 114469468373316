<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
            <v-flex :key="index" xs2 v-for="(computer, index) in computers">
                <nb-supervisor-pc :computer="computer" @stateChanged="loadData()"></nb-supervisor-pc>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import NbSupervisorPc from "./NbSupervisorPc";

    export default {
        components: {
            NbSupervisorPc,
        },
        data: () => {
            return {
                computers: [],
                interval: null,
            }
        },
        mounted() {
            this.loadData();
            
            this.interval = setInterval(() => {
                this.loadData();
            }, 10000);
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
        methods: {
            loadData() {
                HttpService.getInstance()
                    .get(`nbSupervisor/getAll`)
                    .then(resp => {
                        this.computers = resp;
                    })  
            },
        }
    }
</script>