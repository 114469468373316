import {rolesEnum} from "../constants/rolesEnum";

export default (to, from, next) => (router) => {
    if (!router.app.$auth.isAuthenticated()) {

        router.app.$cookie.set('returnUrl', to.fullPath, 7);

        next({name: 'unauthorized'});
    }

    if (!router.app.$auth.userHasAtLeastOneRole(
        [
            rolesEnum.dean,
            rolesEnum.admin,
            rolesEnum.edudept,
            rolesEnum.headDepartment,
            rolesEnum.inspector,
            rolesEnum.professor,
            rolesEnum.rector,
        ])) {

        next({name: 'cannot-access'});
    }

    next();
}