<template>
    <div>
        <div v-if="!loading">
            <div v-if="!$vuetify.breakpoint.mobile" class="d-flex justify-space-between">
                <v-btn :to="{name: 'eAristoStudentSubjectsList'}"
                       color="primary"
                       class="px-3"
                ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToSubjectList")}}</v-btn>
                <h3><span class="font-weight-light">{{$t("eAristoStudent.subjectName")}}:</span> {{selectedSubject ? selectedSubject.nameUk : "-"}}</h3>
            </div>

            <div v-if="$vuetify.breakpoint.mobile" class="d-flex flex-column">
                <v-btn :to="{name: 'eAristoStudentSubjectsList'}"
                       color="primary"
                       class="px-3"
                ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToSubjectList")}}
                </v-btn>
                <h3><span class="font-weight-light">{{$t("eAristoStudent.subjectName")}}:</span> {{selectedSubject ? selectedSubject.nameUk : "-"}}</h3>
            </div>

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            {{$t("eAristoStudent.moduleName")}}
                        </th>
                        <th class="text-left">
                            {{$t("eAristoStudent.action")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="module in modules"
                        :key="module.id"
                    >
                        <td>{{ module.name }}</td>
                        <td>
                            <v-btn icon
                                   :to="{ name: 'eAristoStudentSubjectModuleTopics', 
                                   params: { subjectId: $route.params.subjectId, moduleId: module.id }}">
                                <v-icon color="primary">send</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else>
            <e-aristo-loading-placeholder></e-aristo-loading-placeholder>
        </div>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import eAristoLoadingPlaceholder from "../common/eAristoLoadingPlaceholder";

export default {
    components: {
        eAristoLoadingPlaceholder,
    },
    data () {
        return {
            loading: true,
            modules: [],
            selectedSubject: null,
        }
    },
    mounted() {
        this.loading = true;
        HttpService.getInstance()
            .get(`subjects/${this.$route.params.subjectId}`)
            .then(selectedSubject => {
                this.selectedSubject = selectedSubject;
            });
        
        HttpService.getInstance()
            .get(`eAristoStudent/getModules/${this.$route.params.subjectId}`)
            .then(res => {
                this.modules = res.map(module => {
                        return {
                            id: module.id,
                            name: `${module.orderBy}. ${module.nameUk}`,
                            route: {
                                name: "eAristoStudentModules",
                                params: {
                                    subject: this.$route.params.subjectId,
                                    moduleId: module.id
                                }
                            },
                        };
                    });

                this.loading = false;
            });
    },
}
</script>