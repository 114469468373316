<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <v-container fluid>
                    <department-subject-switcher
                            :type="navigationSelectorType"
                            :is-open="isDepartmentSubjectSwitcherOpened"
                            :is-show-button="isDepSubjNavigationVal"
                            @changeDialogState="isDepartmentSubjectSwitcherOpened = $event"
                            :route-to="switcherDialogRoute"
                            route-back="teacherHome"
                    />
                </v-container>

                <v-subheader class="mt-1 grey--name name--darken-1">Електронний журнал</v-subheader>
                <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";
    import {navigationSelectorTypesEnum} from "../../../constants/navigationSelectorTypesEnum";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);

            this.isDepSubjNavigation();
            this.dialogNavigationDepartmentSubject(this.$route.name);
        },
        watch: {
            $route (to, from){
                if (to.name != from.name) {
                    setTimeout(() => {
                        this.isDepartmentSubjectSwitcherOpened = false;
                    }, 100);
                }

                this.isDepSubjNavigation();
                this.dialogNavigationDepartmentSubject(to.name);
            }
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            },
            isDepSubjNavigation() {
               console.log("isDepSubjNavigation");
               console.log("this.$route.params.department", this.$route.params.department);
               console.log("this.$route.params.subject", this.$route.params.subject);
                console.log("(this.$route.params.department !== undefined && this.$route.params.subject !== undefined)", (this.$route.params.department !== undefined &&
                    this.$route.params.subject !== undefined));
                if (this.$route.name === 'timeAccounting') {
                     this.isDepSubjNavigationVal = this.$route.params.department !== undefined;
                     
                     return;
                }
                
                this.isDepSubjNavigationVal = (this.$route.params.department !== undefined && 
                        this.$route.params.subject !== undefined);

                return;
            },
            dialogNavigationDepartmentSubject(routeName) {
                if (!this.isDepSubjNavigation() && this.$route.name !== 'teacherHome') {
                    this.navigationSelectorType = navigationSelectorTypesEnum.professorFiltered;

                    // if (this.$route.params.department === undefined) {
                            this.isDepartmentSubjectSwitcherOpened = true;
                     
                    // }

                    this.switcherDialogRoute = routeName;
                }

                if (this.$route.name === 'timeAccounting') {
                    this.navigationSelectorType = navigationSelectorTypesEnum.moderatorReworks;

                    this.switcherDialogRoute = routeName;
                  
                        this.isDepartmentSubjectSwitcherOpened = true;
                    
                }
            },
        },
        data: () => ({
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'turned_in',
                    name: 'Оцінки',
                    route: {
                        name: 'marksRoot'
                    }
                },
                // {
                //     icon: 'schedule',
                //     name: 'Відпрацювання',
                //     route: {
                //         name: 'reworkSchedule'
                //     }
                // },
                // {
                //     icon: 'calendar_today',
                //     name: 'Щомісячний звіт успішності',
                //     route: {
                //         name: "monthlyReport"
                //     }
                // },
                {
                    icon: 'date_range',
                    name: 'Облік академічного часу',
                    route: {
                        name: "timeAccounting"
                    }
                },
                {
                    divider: true
                },
            ],

            isDepartmentSubjectSwitcherOpened: false,
            navigationSelectorTypesEnum,

            navigationSelectorType: navigationSelectorTypesEnum.professorFiltered,

            switcherDialogRoute: "",

            isDepSubjNavigationVal: true,

        })
    }
</script>