import moment from "moment";

import topicMarkAdmissionTypes from "../constants/topicMarkAdmissionTypes";
import topicMarkAdmissionEmissionTypes from "../constants/topicMarkAdmissionEmissionTypes";

export const getTopicMarkAdmissionEmissionTypeText = (topicAbsence) => {
    const topicMarkAdmissionEmissionType = topicMarkAdmissionEmissionTypes.find(t => t.id === topicAbsence.admissionEmissionType);

    if (topicMarkAdmissionEmissionType) {
        return topicMarkAdmissionEmissionType.nameUk;
    }

    return "";
};

export const getTopicMarkAdmissionTypeText = (topicAbsence) => {
    const topicMarkAdmissionType = topicMarkAdmissionTypes.find(t => t.id === topicAbsence.admissionType);

    if (topicMarkAdmissionType) {
        return topicMarkAdmissionType.nameUk;
    }

    return "";
};

export const getTopicMarkAdmissionTypeIcon = (topicAbsence) => {
    const topicMarkAdmissionType = topicMarkAdmissionTypes.find(t => t.id === topicAbsence.admissionType);

    if (topicMarkAdmissionType) {
        return topicMarkAdmissionType.icon;
    }

    return "";
};

export const getMarkAdmissionValidity = (topicAbsence) => {
    const admissionEnd = moment(topicAbsence.admissionEnd);
    return admissionEnd.diff(moment(), "minutes") > 0;
};

export const getMarkAdmissionIsEnding = (topicAbsence) => {
    const admissionEnd = moment(topicAbsence.admissionEnd);
    return admissionEnd.diff(moment(), "days") > 0;
};