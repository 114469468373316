<template>
    <v-container fluid>
        <v-layout justify-center>
            <v-container grid-list-md>
<!--                <student-support-contacts></student-support-contacts>-->
                
                <navigation-cards/>
                <announcements-list/>
                <surveys-list/>
                <birthdays-list/>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationCards from "../../components/dashboard/NavigationCards";
    import AnnouncementsList from "../../components/dashboard/AnnouncementsList";
    import SurveysList from "../../components/dashboard/SurveysList";
    import BirthdaysList from "../../components/dashboard/BirthdaysList";
    import {rolesEnum} from "../../constants/rolesEnum";
    import StudentSupportContacts from "../../components/dashboard/StudentSupportContacts";

    export default {
        components: {
            NavigationCards,
            AnnouncementsList,
            SurveysList,
            BirthdaysList,
            StudentSupportContacts,
        },
        methods: {
            clickBtn() {
                this.$auth.login();
            },
            logOut() {
                this.$auth.logout();
            },
            userIsAdminOrEdudeptOrProfessor() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept, rolesEnum.professor]);
            },
        },
    }
</script>
