<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <rating-directions-stat-index></rating-directions-stat-index>
        </v-layout>
    </v-container>
</template>
<script>
    import RatingDirectionsStatIndex from "../../components/ratingAdmin/ratingDirectionsStat/RatingDirectionsStatIndex";

    export default  {
        components: {
            RatingDirectionsStatIndex,
        }
    }
</script>