<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <v-flex xs12>
                    <admin-teacher-list-grid
                            :professors="items"
                            :loading="loading"
                    >
                    </admin-teacher-list-grid>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AdminTeacherListGrid from "./AdminTeacherListGrid";

    export default {
        components: {
            AdminTeacherListGrid,
        },
        data: () => {
            return {
                items: [],
                loading: false,
            }
        },
        mounted() {
            this.getItems();
        },
        methods: {
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`FinalExamAdminExaminers/getFinalExamAllExaminers`)
                    .then(items => {
                        this.items = items;
                        this.total = items.length;
                        
                        this.loading = false;
                    })
            },
        },
    }
</script>