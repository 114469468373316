<template>
    <div>
        <div v-if="loading">
            <v-layout align-center justify-center>
                <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </div>
        
        <div class="d-flex flex-md-column align-center mt-4">
            <slide-viewer :current-index="currentSlideIndex" 
                          :should-next-image="couldGoNextImage" 
                          :images="slidesArray" 
                          @slideSelect="onSlideSelect"
            ></slide-viewer>
        </div>
        
        <e-aristo-question-student-quiz-index v-if="questionsToPass.length > 0 && !this.isCorrectCurrentSection" 
                                              :questions-to-pass="questionsToPass"
                                              :quiz-attempt-id="quizAttemptId"
                                              @submittedAnswers="onSubmittedAnswers"
        ></e-aristo-question-student-quiz-index>
        
        <v-alert color="success" dark v-if="this.isCorrectCurrentSection">
            {{$t("eAristoStudent.youAnsweredCorrectThisBlock")}}
        </v-alert>
    </div>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";
import SlideViewer from "@/components/common/slide-viewer/SlideViewer.vue";
import eAristoQuestionStudentQuizIndex
    from "@/components/eAristoStudent/topicContent/questions/eAristoQuestionStudentQuizIndex.vue";

const configuration = AppConfig(config);

export default {
    name: "PresentationStudentContent",
    components: {
        eAristoQuestionStudentQuizIndex,
        SlideViewer,
    },
    props: {
        slidesContent: {
            type: Array,
            default: () => {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
        questionsPresentation: {
            type: Array,
            default: () => {
                return [];
            }
        },
        questionsPresentationSegments: {
            type: Array,
            default: () => {
                return [];
            }
        },
        quizAttemptId: {
            type: Number,
        }
    },
    data: () => {
        return {
            questionsToPass: [],
            
            currentSlideIndex: 0,
        };
    },
    computed: {
        couldGoNextImage: {
            get() {
                const foundQuestionPresentation = this.questionsPresentationSegments.find(q => q.marker === this.currentSlideIndex);
                
                return this.isCorrectCurrentSection || !foundQuestionPresentation;
            },
            set(val) { }
        },
        isCorrectCurrentSection: {
            get() {
                const foundQuestionPresentation = this.questionsPresentationSegments.find(q => q.marker === this.currentSlideIndex);

                return foundQuestionPresentation && foundQuestionPresentation.answeredCorrect;
            }
        },
        slidesArray: {
            get() {
                return this.slidesContent.map(s => s.fileName);
            }
        }
    },
    methods: {
        uploadedPresentation(slides) {
            this.$emit('uploadedPresentation', slides);
        },
        slideImageUrl(image) {
            return `${configuration.apiEndpoint}/file/get/presentationSlideImage/${image}`;
        },
        onSlideSelect(slideIndex) {
            const slideName = this.slidesArray[slideIndex];
            this.questionsToPass = this.questionsPresentation.filter(p => p.slide.fileName === slideName);
            
            this.currentSlideIndex = slideIndex;
        },
        onSubmittedAnswers(submitResult) {
            if (!submitResult) {
              
                this.onSlideSelect(0);
                
                // console.log("this.questionsPresentationSegments", this.questionsPresentationSegments);
                // console.log("this.currentSlideIndex", this.currentSlideIndex);
                //
                // const foundQuestionPresentation = this.questionsPresentationSegments.find(q => q.marker === this.currentSlideIndex);
                //
                // const index = this.questionsPresentationSegments.indexOf(foundQuestionPresentation);
                //
                // console.log("index", index);
                //
                //
                // const previousQuestionSegment = this.questionsPresentationSegments[index - 1];
                //
                // console.log("previousQuestionSegment", previousQuestionSegment);
                //
                // if (previousQuestionSegment && previousQuestionSegment.marker >= 0) {
                //     this.currentSlideIndex = previousQuestionSegment.marker;
                // }
                //
                // if (index === 0) {
                //     this.currentSlideIndex = 0;
                // }
            }
            
            this.$emit('submittedAnswers', submitResult);
        }
    }
}
</script>

<style scoped lang="scss">
.slide-img {
    border: 1px solid #ccc;
    box-shadow: 3px 5px 10px 0px #ccc;
}
</style>