<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex>
                <department-rating-directions-index :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";
    import DepartmentRatingDirectionsIndex from "../../../components/chiefDept/rating/DepartmentRatingDirectionsIndex";

    export default {
        components: {
            NavigationListTemplate,
            DepartmentRatingDirectionsIndex
        },
        data: () => ({}),
    }
</script>