<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout v-if="isBlank" row wrap>
            <v-flex xs12>
                <div class="headline">Виберіть курс</div>
                <v-list dense>
                    <navigation-nav-list-semesters route-to="lecturesFullRoot"></navigation-nav-list-semesters>
                </v-list>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationNavListSemesters from "../../../components/common/left-navigation/NavigationNavListSemesters";

    export default {
        components: {
            NavigationNavListSemesters
        },
        data: () => ({
            isBlank: true,
        }),
        mounted() {
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            checkIfIsBlank(route) {
                if (route.params.subject !== undefined) {
                    this.isBlank = false;
                } else {
                    this.isBlank = true;
                }
            }
        },
        watch: {
            $route(route) {
                this.checkIfIsBlank(route);
            }
        }
    }
</script>