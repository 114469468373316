<template>
    <v-card>
        <v-card-title class="title">Видача допусків з поважної причини за період карантину</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете видати допуски з поважної причини у вибраній групі на період карантину COVID-19 (починаючи з 6 березня 2020 по теперішній час)?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="emitQuarantineAdmissions()"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
            };
        },
        methods: {
            emitQuarantineAdmissions() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`dean/emitQuarantineAdmissions/${this.$route.params.group}`)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    });
            }
        }
    }
</script>