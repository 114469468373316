<template>
    <v-navigation-drawer
            fixed
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :fixed="true"
            :value="drawer"
            @input="$emit('toggleDrawer', $event)"
    >
        <v-list dense>
            <v-subheader class="mt-4 grey--name name--darken-1">Електронний журнал</v-subheader>
            <navigation-list-template :items="menuItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            this.menuItems = this.remapItems(this.menuItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (!this.$auth.userHasAtLeastOneRole(item.roles)) {
                        return;
                    }
                    
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            menuItems: [],
            menuItemsRaw: [
                {
                    roles: ["dean", "inspector", "admin", "edudept"],
                    icon: 'account_balance',
                    name: 'Журнали',
                    route: {name: 'deanRegistersRoot'}
                },
                {
                    roles: ["dean", "inspector", "admin", "edudept"],
                    icon: 'account_box',
                    name: 'Студенти',
                    children: [
                        {name: 'Групи', route: {name: "deanGroupsGrid"}},
                        {name: 'Студенти', route: {name: "deanStudentsGrid"}},
                        {name: 'Логіни-паролі студентів', route: {name: "deanStudentsAccountsGrid"}},
                        {name: 'Відраховані / Ак.відпустка', route: {name: "expelledAcademicVacationDean"}},
                    ]
                },
                {
                    roles: ["dean", "admin", "edudept"],
                    icon: 'beenhere',
                    name: 'Допуски до занять',
                    children: [
                        {name: 'Пошук за групою', route: {name: "admissionsGroup"}},
                        {name: 'Пошук за студентом', route: {name: "admissionsStudent"}},
                        {name: 'Перегляд за документами', route: {name: "admissionsDocumentsStudent"}},
                    ]
                },
                {
                    roles: ["dean", "admin", "edudept"],
                    icon: 'toc',
                    name: 'Журнал допусків',
                    route: {name: 'admissionsJournal'}
                },
                // {
                //     roles: ["dean", "inspector", "admin", "edudept"],
                //     icon: 'calendar_today',
                //     name: 'Атестаційні відомості',
                //     children: [
                //         {name: 'Щомісячна атестація', route: {name: "home"}},
                //         {name: 'Перевірка атестації', route: {name: "home"}},
                //     ]
                // },
                // {
                //     roles: ["dean", "inspector", "admin", "edudept"],
                //     icon: 'description',
                //     name: 'Екзаменаційні відомості',
                //     children: [
                //         {name: 'Поточні відомості', route: {name: "home"}},
                //         {name: 'Заключні відомості', route: {name: "home"}},
                //     ]
                // },
                {
                    roles: ["dean", "inspector", "admin", "edudept"],
                    icon: 'equalizer',
                    name: 'Статистика',
                    children: [
                        {name: 'Успішність, пропуски', route: {name: "deanSuccessRate"}},
                    ]
                },
                {
                    divider: true
                },
            ],
        })
    }
</script>