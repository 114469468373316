<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">Додавання запитань з тексту</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12 md12>
                            <v-textarea
                                    :rows="20"
                                    solo
                                    label="Питання у вигляді тексту для розпарсювання"
                                    v-model="form.questions"
                                    :error-messages="errors.questions"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs6>
                            <div class="subheading">Кількість відповідей</div>
                            <v-radio-group v-model="form.answerCount">
                                <v-radio
                                        label="3 відповіді"
                                        :value="3"
                                ></v-radio>
                                <v-radio
                                        label="4 відповіді"
                                        :value="4"
                                ></v-radio>
                                <v-radio
                                        label="5 відповідей"
                                        :value="5"
                                ></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex xs6>
                            <div class="subheading">Правильна відповідь</div>
                            <v-radio-group v-model="form.answerRightType">
                                <v-radio
                                        v-for="(answer, index) in answerRightTypes"
                                        :key="index"
                                        :label="answer.name"
                                        :value="answer.id"
                                ></v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                       text
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../../HttpService";
    import {remapErrors} from "../../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../../common/LoadingPlaceholder";
    import answerRightTypes from "../../../../../constants/answerRightType";

    export default {
        props: {
            poolId: Number,
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    name: null,
                    questions: null,
                    answerCount: 5,
                    answerRightType: 0,
                },
                errors: {
                    name: [],
                    questions: [],
                },
                loading: false,
                loadingSave: false,
                answerRightTypes,
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post(`stepQuestion/createBulk/${this.poolId}`, this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>