import EmptyView from "../views/EmptyView";
import ManagementProfileRootView from '../components/stepAdminProfile/management/ManagementProfileRootView';
import StepAdminProfileRootView from "../views/stepAdminProfile/StepAdminProfileRootView";
import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/stepAdminProfileModules',
         name: 'stepAdminProfileRoot',
         component: StepAdminProfileRootView,
         children: [
             {
                 path: '',
                 redirect: {name: 'stepAdminProfile'},
             },
             {
                 path: '/stepAdminProfileModules/dashboard',
                 name: 'stepAdminProfile',
                 component: EmptyView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                 children: [
                     {
                         path: '/stepAdminProfileModules/management/:profile/:faculty?/:speciality?/:semester?',
                         name: 'stepAdminProfileManagement',
                         component: ManagementProfileRootView,
                         beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                     },
                 ]
             },
             
         ],
     },
 ];
}