<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.surname" v-model="form.surname"
                                          label="Прізивще"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.name" v-model="form.name"
                                          label="Ім'я"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.patronymic" v-model="form.patronymic"
                                          label="По-батькові"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <birthday-picker :errorMessages="errors.birthday" v-model="form.birthday"/>
                        </v-flex>
                        <v-flex xs12>
                            <v-radio-group :error-messages="errors.gender" v-model="form.gender" mandatory>
                                <v-radio label="Чоловіча" :value="0"></v-radio>
                                <v-radio label="Жіноча" color="red" :value="1"></v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import maxBy from "lodash/maxBy";
    import BirthdayPicker from "../../../common/BirthdayPicker";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            items: Array,
        },
        components: {
            LoadingPlaceholder,
            BirthdayPicker,
        },
        data: () => {
            return {
                form: {
                    surname: '',
                    name: '',
                    patronymic: '',
                    gender: null, 
                    birthday: null,
                },
                errors: {
                    surname: [],
                    name: [],
                    patronymic: [],
                    gender: [],
                    birthday: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`student/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                let additionalParams = {
                    faculty: {id: this.$route.params.faculty},
                    semester: {id: this.$route.params.semester},
                    speciality: {id: this.$route.params.speciality},
                    academicGroup: {id: this.$route.params.group}
                };
                
                HttpService.getInstance()
                    .post("student", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>