<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex v-if="showModuleBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Модулі</v-subheader>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex :style="showModuleBlock() ? 'margin-left: 300px; width: calc(100% - 460px);': ''">
                <modules-index v-if="renderModulesGrid()" :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import ModulesIndex from "../../components/moderator/modules/ModulesIndex";

    export default {
        components: {
            NavigationListTemplate,
            ModulesIndex
        },
        data: () => ({}),
        mounted() {
        },
        methods: {
            renderModulesGrid() {
              return this.$route.params.department && this.$route.params.subject; 
            },
            showModuleBlock() {
                return this.$route.params.module !== undefined;
            },

        },
    }
</script>