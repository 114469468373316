<template>
    <v-card tile>
        <v-app-bar card dark color="orange">
            <v-btn icon
                   dark
                   tabs
                   @click.native="$emit('shouldClose')"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Пропуски для {{paymentItem.student.surname}} {{paymentItem.student.name}} для платежу з Order Id {{paymentItem.orderId}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-container grid-list-md>
            <admission-types-legend/>

            <v-flex xs12>
                <v-data-table
                        :headers="headers"
                        :items="reworkItems"
                        :loading="loading"
                        class="elevation-3"
                        hide-default-footer
                >
                    <template v-slot:item="props">
                        <tr>
                            <td class="text-xs-left">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-if="props.item.isSubjectPayable" color="red" v-on="on">
                                            attach_money
                                        </v-icon>
                                    </template>
                                    <span>Оплачувані допуски</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-if="!props.item.isSubjectPayable" color="green" v-on="on">
                                            strikethrough_s
                                        </v-icon>
                                    </template>
                                    <span>НЕоплачувані допуски</span>
                                </v-tooltip>
                            <td class="text-xs-left">{{ props.item.subject.nameUk }}</td>
                            <td class="text-xs-left">{{ `${props.item.topic.orderNumber}. ${props.item.topic.nameUk}`}}
                            </td>
                            <td class="text-xs-left">{{ formatShortDate(props.item.classDate) }}</td>
                            <td class="text-xs-left">
                                <mark-unsatisfactory-icons :value="props.item.value"/>
                            </td>
                            <td class="text-xs-left">
                                <admission-details v-if="props.item.topicAbsence != null" :topicAbsence="props.item.topicAbsence"/>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
        </v-container>
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../HttpService";
    import DatePicker from "../../../components/common/DatePicker";
    import AdmissionDetails from "../../../components/common/rework-admissions/AdmissionDetails";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import {markValuesEnum} from "../../../constants/markValuesEnum";
    import MarkUnsatisfactoryIcons from "../../common/rework-admissions/MarkUnsatisfactoryIcons";

    export default {
        components: {
            DatePicker,
            AdmissionDetails,
            AdmissionTypesLegend,
            MarkUnsatisfactoryIcons
        },
        data: () => ({
            formatShortDate,
            markValuesEnum,
            loading: false,
            loadingSave: false,
            reworkItems: [],

            selectedReworkItem: null,

            filter: {
                dateFrom: moment(new Date()).subtract(3, "months"),
                dateTo: new Date(),
            },
            headers: [
                // {text: 'Оплачуваний', sortable: false},
                {text: 'Дисципліна', sortable: false},
                {text: 'Тема', sortable: false},
                {text: 'Дата заняття', sortable: false},
                {text: 'Оцінка', sortable: false},
                {text: 'Допуск', sortable: false},
            ],
            confirmRework: false,
        }),
        props: {
            paymentItem: Object,
        },
        mounted() {
            this.getItems();
        },
        methods: {
            getItems() {
                this.loading = true;
                HttpService.getInstance()
                    .get(`adminPaymentHistory/getReworks/${this.paymentItem.id}`)
                    .then(resp => {
                        this.reworkItems = resp;
                        this.loading = false;
                    });
            },
        },
    }
</script>