<template>
    <v-layout wrap>
        <v-flex xs12>
            <v-card>
                <v-card-text>
                    <v-container fluid fill-height>
                        <v-layout row wrap justify-center>
                            <v-flex xs12>
                                <div class="d-flex align-center">
                                <v-text-field large
                                              clearable
                                              autofocus
                                              :loading="loading"
                                              label="Прівище та/або ім'я студента"
                                              v-model="searchString"
                                              hide-details
                                              @click:clear="getStudents('')"
                                              v-on:keyup.enter="getStudents(searchString)"
                                />
                                
                                <v-btn class="ml-5" @click="getStudents(searchString)" color="primary">Пошук</v-btn>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs12>
            <admissions-student-list :students="students" :loading="loading"/>
        </v-flex>
    </v-layout>
</template>

<script>
    import HttpService from "../../../HttpService";
    import throttle from "lodash/throttle"

    import AdmissionsStudentList from "../../common/rework-admissions/AdmissionsStudentList";

    export default {
        components: {
            AdmissionsStudentList
        },
        data: () => ({
            students: [],
            loading: false,
            searchString: "",
        }),
        methods: {
            getStudents(searchString) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`dean/getReworkStudents?searchString=${searchString}`)
                    .then(resp => {
                        this.students = resp;
                        this.loading = false;
                    })
            },
        }
    }
</script>