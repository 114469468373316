<template>
    <div>
        <v-menu
                v-if="topicInfo.professor"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                transition="slide-x-transition"
                offset-x
        >
            <template v-slot:activator="{ on }">
                <span v-on="on" class="cursor-pointer">{{(getProfessorInitials())}}</span>
            </template>
            <v-card>
                <v-card-text>{{topicInfo.professor.surname}} {{topicInfo.professor.name}} {{topicInfo.professor.patronymic}}</v-card-text>
            </v-card>
        </v-menu>
        <div v-if="!topicInfo.professor">-</div>
    </div>
</template>
<script>
    export default {
        props: {
            topicInfo: {
                type: Object,
                default: () => {},
            }
        },
        data: () => ({
           menu: false, 
        }),
        methods: {
            getProfessorInitials() {
                if (this.topicInfo.professor) {
                    return `${this.topicInfo.professor.surname.charAt(0)}${this.topicInfo.professor.name.charAt(0)}${this.topicInfo.professor.patronymic.charAt(0)}`
                }

                return null;
            },
        }
    }
</script>