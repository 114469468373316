<template>
    <v-card>
        <v-card-title class="title">Видалення ВСІХ запитань</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете видалити УСІ запитання з даної теми?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    text
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так, видалити ВСІ
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            type: {
                type: String, // lecture / topic
                required: true,
            }
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;
                
                let url = null;

                if (this.type === "topic") {
                    url = `reworkQuiz/byTopic/${this.$route.params.topic}`;
                }
                else if (this.type === "lecture") {
                    url = `reworkQuiz/byLecture/${this.$route.params.lecture}`;
                }

                HttpService.getInstance()
                    .delete(url)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("removed");
                    });
            }
        }
    }
</script>