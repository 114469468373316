<template>
    <div>
        <v-card v-if="isShowButton" ripple color="info" dark hover>
            <v-card-text @click="isOpenLocal = true" class="selected-department-subject pt-0 pb-0">
                    <v-row>
                        <v-col sm="2" class="px-1">
                            <v-icon class="mr-1">account_balance</v-icon>
                        </v-col>
                        <v-col sm="10" class="px-1">
                            <span class="body-2">{{departmentName}}</span>
                        </v-col>
                    </v-row>
                    <v-row v-if="type !== navigationSelectorTypesEnum.moderatorReworks">
                        <v-col sm="2" class="px-1">
                            <v-icon class="mr-1">book</v-icon>
                        </v-col>
                        <v-col sm="10" class="px-1">
                            <span class="body-2">{{subjectName}}</span>
                        </v-col>
                    </v-row>
            </v-card-text>
        </v-card>

        <v-dialog persistent v-model="isOpenLocal" max-width="500px">
            <v-card style="min-height: 180px;">
                <v-card-text class="pt-6">
                    <v-select
                            :items="departments"
                            v-model="form.department"
                            item-text="nameUk"
                            item-value="id"
                            return-object
                            label="Кафедра"
                            filled
                            :disabled="departmentDisabled"
                            :loading="loading"
                    ></v-select>
                    <v-select
                            v-if="form.department !== null && type !== navigationSelectorTypesEnum.moderatorReworks"
                            :items="subjects"
                            v-model="form.subject"
                            item-text="name"
                            item-value="id"
                            return-object
                            label="Дисципліна"
                            filled
                            hide-details
                            :loading="loading"
                    ></v-select>
                    <div v-if="form.department !== null && 
                                userSettings.favoriteSubjects.length > 0 && 
                                type === navigationSelectorTypesEnum.professorFiltered" 
                         class="caption">
                        Деякі дисципліни приховані згідно <router-link :to="{name: 'settings'}">Ваших налаштувань</router-link>.
                    </div>
                    <div v-if="form.department !== null && 
                                userSettings.favoriteSubjects.length === 0 && 
                                type === navigationSelectorTypesEnum.professorFiltered"
                         class="caption">
                        Ви можете налаштувати, які дисципліни будуть у списку за допомогою <router-link :to="{name: 'settings'}">персоніфікованих налаштувань</router-link>.
                    </div>
                </v-card-text>
                <v-card-actions v-if="isShowButton">
                    <v-btn color="primary" text @click="isOpenLocal = false">
                        Відміна
                    </v-btn>
                </v-card-actions>
                <v-card-actions v-if="!isShowButton">
                    <v-btn color="orange lighten-1" dark @click="goBack()">
                        Назад
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import HttpService from "../../HttpService";
    import {navigationSelectorTypesEnum} from "../../constants/navigationSelectorTypesEnum";

    export default {
        data: () => ({
            isOpenLocal: false,

            departments: [],
            subjects: [],
            departmentDisabled: false,
            form: {
                department: null,
                subject: null,
            },
            loading: false,
            departmentName: "",
            subjectName: "",

            navigationSelectorTypesEnum,

            userSettings: {
                defaultDepartment: null,
                defaultSubject: null,
                favoriteSubjects: []
            },
            departmentWasSetByUserSettings: false,
            subjectWasSetByUserSettings: false,
        }),
        mounted() {
            this.loading = true;

            this.resetForm();

            HttpService.getInstance()
                .get("settings/professorSettings")
                .then(resp => {
                    this.userSettings = resp;

                    return HttpService.getInstance()
                        .get("teacher/navigation/department");
                })
                .then(departments => {
                    this.departments = departments;

                    this.loading = false;

                    this.preSetDepartmentWhenOnlyOne(departments);

                    if (this.$route.params.department === undefined) {
                        return;
                    }

                    const department = this.getDepartment();

                    if (!department) {
                        return;
                    }

                    return HttpService.getInstance()
                        .get(`teacher/navigation/department/${department.id}/subject`);
                })
                .then(subjects => {
                    this.processSubjects(subjects);

                    this.loading = false;

                    this.getSubject();
                });
        },
        props: {
            isOpen: {
                type: Boolean,
                defaultsTo: false,
            },
            isShowButton: {
                type: Boolean,
                defaultsTo: false,
            },
            routeTo: {
                type: String,
                required: true, 
            },
            routeBack: {
                type: String,
                required: true,
            },
            type: {
                type: Number,
                default: navigationSelectorTypesEnum.nonFiltered,
            }
        },
        methods: {
            getDepartment() {
                const routeDepartment = parseInt(this.$route.params.department);
                if (this.$route.params.department === undefined) {
                    return;
                }

                const foundDepartment = this.departments.find(d => d.id === routeDepartment);

                if (foundDepartment) {
                    this.departmentName = foundDepartment.nameUk;
                }
                
                return foundDepartment;
            },
            getSubject() {
                const routeSubject = parseInt(this.$route.params.subject);
                if (this.$route.params.subject === undefined) {
                    return;
                }

                const foundSubject = this.subjects.find(d => d.id === routeSubject);

                if (foundSubject) {
                    const subjSpecialities = foundSubject.specialities.map(spec => spec.nameShortUk);
                    
                    this.subjectName = `${foundSubject.nameUk} (${foundSubject.nameShortUk}) (${subjSpecialities.join(", ")})`;
                }
                
                return foundSubject;
            },
            preSetDepartmentWhenOnlyOne(departments) {
                if (departments && departments.length === 1) {
                    this.departmentDisabled = true;
                    this.form.department = departments[0];
                }
                else {
                    if (this.userSettings.defaultDepartment !== null &&
                        this.type === this.navigationSelectorTypesEnum.professorFiltered &&
                        !this.departmentWasSetByUserSettings) {
                        const foundDefaultDepartment = departments.find(d => d.id === this.userSettings.defaultDepartment.id);
                        
                        if (foundDefaultDepartment !== undefined) {
                            this.departmentWasSetByUserSettings = true;
                            this.departmentDisabled = true;
                            this.form.department = foundDefaultDepartment;
                        }
                    }
                    else {
                        this.departmentDisabled = false;
                    }
                }
            },
            resetForm() {
                this.form = {
                    department: null,
                    subject: null,
                }
            },
            goBack() {
                this.$router.push({name: this.routeBack}).catch(err => {});
                this.isOpenLocal = false;
            },
            processSubjects(subjects) {
                if (subjects) {

                    let subjectsFiltered = subjects;
                    
                    let defaultSubject = this.userSettings.defaultSubject;

                    if (this.userSettings.favoriteSubjects.length !== 0 && 
                        this.type === this.navigationSelectorTypesEnum.professorFiltered) {
                        const favoriteSubjectsIds = this.userSettings.favoriteSubjects.map(s => s.id);

                        subjectsFiltered = subjects.filter(s => {
                            return favoriteSubjectsIds.indexOf(s.id) > -1 || (defaultSubject !== null && defaultSubject.id === s.id);
                        });
                    }

                    this.subjects = subjectsFiltered.map(s => {
                        const subjSpecialities = s.specialities.map(spec => spec.nameShortUk);

                        s.name = `${s.nameUk} (${s.nameShortUk}) (${subjSpecialities.join(", ")})`;
                        return s;
                    });
                    
                    if (this.userSettings.defaultSubject !== null && 
                        this.type === this.navigationSelectorTypesEnum.professorFiltered && 
                        !this.subjectWasSetByUserSettings) {
                        const foundFavoriteSubject = this.subjects.find(s => s.id === this.userSettings.defaultSubject.id);
                        
                        if (foundFavoriteSubject !== undefined) {
                            this.subjectWasSetByUserSettings = true;
                            this.form.subject = foundFavoriteSubject;
                        }
                    }
                }
            }
        },
        watch: {
            "form.department": function (department) {
                if (department !== null) {
                    if (+this.type === +navigationSelectorTypesEnum.moderatorReworks) {
                        this.$router.push(
                            {
                                name: this.routeTo,
                                params: {
                                    department: this.form.department.id,
                                }
                            }
                        ).catch(err => {});

                        this.getDepartment();
                        this.isOpenLocal = false;
                        
                        return;
                    }
                    
                    this.loading = true;
                    
                    HttpService.getInstance()
                        .get(`teacher/navigation/department/${department.id}/subject`)
                        .then(subjects => {
                            this.processSubjects(subjects);
                            
                            this.loading = false;

                            this.getDepartment();
                            this.getSubject();
                        });
                }
            },
            "form.subject": function (subject) {
                if (subject !== null) {
                    this.$router.push(
                        {
                            name: this.routeTo,
                            params: {
                                department: this.form.department.id,
                                subject: this.form.subject.id
                            }
                        }
                    ).catch(err => {});
                    
                    this.isOpenLocal = false;

                    this.getDepartment();
                    this.getSubject();
                }
            },
            isOpenLocal: function (isOpenLocal, oldVal) {
                this.$emit('changeDialogState', isOpenLocal);
            },
            isOpen: function (isOpen, oldVal) {
                if (isOpen !== oldVal && isOpen) {
                    this.isOpenLocal = isOpen;
                    
                    this.resetForm();

                    this.preSetDepartmentWhenOnlyOne(this.departments);
                }
            }
        },
    }
</script>
<style lang="scss">
    .selected-department-subject {
        color: white !important;
    }
</style>