<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <curators-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import CuratorsIndex from "../../../../components/admin/PedagicalStaff/Curators/CuratorsIndex";
    
    export default  {
        components: {
            CuratorsIndex,
        }
    }
</script>