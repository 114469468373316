<template>
    <v-card>
        <v-card-text>
            <v-flex xs12>
                <v-btn large color="red" dark @click="generateAccountsDialog = true">
                    <v-icon>restore</v-icon>
                    <span class="ml-1">Згенерувати логіни/паролі</span>
                </v-btn>
                <v-btn color="primary" @click="print()">
                    <v-icon>print</v-icon>  
                    <span class="ml-1">Роздрукувати</span>
                </v-btn>
            </v-flex>
            
            <div ref="printArea" class="print-area">
                <div class="group-students" v-for="groupStudent in groupsStudents">
                    <div class="title">{{groupStudent.academicGroup.groupNumber}} група</div>

                    <table class="student-table elevation-4 mt-2 mb-6">
                        <thead>
                        <tr>
                            <th></th>
                            <th>ПІП студента</th>
                            <th>Логін</th>
                            <th>Первинний пароль</th>
                            <th>Активований</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(studentAccount, index) in groupStudent.studentAccounts">
                            <td>{{index + 1}}.</td>
                            <td width="300">{{`${studentAccount.surname} ${studentAccount.name} ${studentAccount.patronymic}`}}</td>
                            <td width="200">{{studentAccount.username}}</td>
                            <td width="200">{{studentAccount.primaryPassword}}</td>
                            <td>
                                <div class="for-display">
                                    <v-icon v-if="studentAccount.passwordChanged">done</v-icon>
                                    <v-icon v-if="!studentAccount.passwordChanged">clear</v-icon>
                                </div>
                                <div class="for-print">
                                    <div v-if="studentAccount.passwordChanged">Так</div>
                                    <div v-if="!studentAccount.passwordChanged">Ні</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </v-card-text>

        <v-dialog v-model="generateAccountsDialog" max-width="450" persistent scrollable>
            <accounts-generation-confirm
                    v-if="generateAccountsDialog"
                    @shouldClose="generateAccountsDialog = false"
                    @generated="generated"
            >
            </accounts-generation-confirm>
        </v-dialog>
    </v-card>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import { Printd } from 'printd';
    import AccountsGenerationConfirm from "./AccountsGenerationConfirm";

    export default {
        components: {
            AccountsGenerationConfirm  
        },
        data: () => ({
            generateAccountsDialog: false,
            
            groupsStudents: [],
            printD: new Printd(),
            cssText: `
                .print-area {
                    font-family: 'Roboto', sans-serif;
                }
                
                table.student-table {
                    width: 100%;
                }
              
                tr td {
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                }
                
                tr td:last-child {
                    border-right: none;
                }
                
                .group-students, .student-table {
                    margin-bottom: 3em;    
                }
                
                .group-students {
                    margin-bottom: 3em;    
                    page-break-after: always;
                }
                
                .for-display {
                    display: none !important;
                }
                
                .title {
                    font-weight: bold;
                    font-size: 1.5em;
                }
            `,
        }),
        mounted() {
            this.getItems();
        },
        methods: {
            getItems() {
                HttpService.getInstance()
                    .get(`studentAccounts/${this.$route.params.faculty}/${this.$route.params.speciality}/${this.$route.params.semester}`)
                    .then(resp => {
                        this.groupsStudents = resp;
                    });
            },
            generated() {
                this.generateAccountsDialog = false;
                this.getItems();
            },
            print() {
                this.printD.print(this.$refs.printArea, this.cssText);
            },
        }
    }
</script>

<style lang="scss">
    table.student-table {
        width: 100%;
        
        tr {
            td, th {
                padding: 10px;
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
                
                &:last-child {
                    border-right: none;
                }
            }
        }
    }
    
    .for-print {
        display: none;
    }
</style>