<template>
    <div>
        <h3>Silent callback component</h3>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.$auth.handleSilentAuthentication()
                .then()
                .catch(err => {
                    console.log(err);
                });
        }
    };
</script>