<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <admin-payment-history-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import AdminPaymentHistoryIndex from "../../../components/admin/Payments/AdminPaymentHistoryIndex";
    export default  {
        components: {
            AdminPaymentHistoryIndex,
        }
    }
</script>