<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template slot="pageText" slot-scope="props">
            Пункт {{ props.pageStart }} - {{ props.pageStop }} з {{ props.itemsLength }}
        </template>
        <template v-slot:item="props">
            <td class="text-xs-left">{{ props.item.surname }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">{{ props.item.patronymic }}</td>
            <td class="text-xs-left">{{ props.item.phone }}</td>
            <td class="text-xs-left">{{ props.item.email }}</td>
            <td class="justify-center layout px-0">
                <!--<v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">-->
                    <!--<v-icon color="primary">edit</v-icon>-->
                <!--</v-btn>-->
                <!--<v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">-->
                    <!--<v-icon color="red">delete</v-icon>-->
                <!--</v-btn>-->
                <v-btn icon v-if="props.item.photo !== '' && props.item.photo !== null" class="mx-0" @click="$emit('previewPhoto', props.item.photo)">
                    <v-icon color="blue">assignment_ind</v-icon>
                </v-btn>
            </td>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            mapSpecialitiesToString(specialities) {
                return specialities.map(spec => spec.nameUk).join(", ");
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Прізвище', value: 'surname'},
                {text: 'Ім"я', value: 'name'},
                {text: 'По батькові', value: 'patronymic'},
                {text: 'Телефон', value: 'phone'},
                {text: 'Email', value: 'email'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>