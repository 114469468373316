<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <search-bar
                        :loading="loading"
                        input-label="Логін або ПІП викладача або назва кафедри..."
                        @search="getItemsSearch($event)"
                />
                <v-flex xs12>
                    <professors-grid
                            :items="items"
                            :total="total"
                            :loading="loading"
                            @paginate="onPaginate"
                            @editItem="editItem"
                            @deleteItem="deleteItem"
                    >
                    </professors-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" max-width="860px" scrollable>
            <add-edit-professors
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-professors>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import ProfessorsGrid from "./ProfessorsGrid";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditProfessors from "./AddEditProfessors";
    import DeleteConfirm from "./DeleteConfirm";
    import SearchBar from "../../common/SearchBar";

    export default {
        components: {
            ProfessorsGrid,
            AddEditProfessors,
            DeleteConfirm,
            SearchBar,
        },
        mounted() {
            if (this.$route.params.departmentId !== undefined) {
                this.pagination = Object.assign({}, this.pagination);
            }
        },
        watch: {
            '$route.params.departmentId': function (newDepartmentId) {
                if (newDepartmentId !== undefined) {
                    this.pagination = Object.assign({}, this.pagination);

                    this.getItems(objectQueryStringify(this.pagination), newDepartmentId);
                }
            },
        },
        methods: {
            getItemsSearch(searchString){
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
            },
            getItems(queryParams, departmentId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`professors/grid/${departmentId}?${queryParams}`)
                    .then(departments => {
                        this.items = departments.items;
                        this.total = departments.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
                }
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>