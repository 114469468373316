import Vue from 'vue';
import Vuex from 'vuex';
import studNavigation from './store/admin/studNavigation';
import snackMessages from "./store/admin/snackMessages";
import topNavigation from "./store/admin/topNavigation";

import loadingState from "./store/loadingState";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        studNavigation,
        snackMessages,
        loadingState,
        topNavigation,
    },
    state: {},
    mutations: {},
    actions: {},
});
