<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="professors"
                :server-items-length="professors.length"
                :loading="loading"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item.professor="{item}">
                {{ item.professor.surname }} {{item.professor.name}} {{item.professor.patronymic}}
            </template>
            
            <template v-slot:item.meetLink="{item}">
                <div v-if="(item.meetLink !== '' && item.meetLink !== null)">
                    <a :href="item.meetLink" target="_blank">
                        {{item.meetLink}}
                    </a>
                </div>
                <div v-if="(item.meetLink === '' || item.meetLink === null)"><i>Немає посилання</i></div>
            </template>
            
            <template v-slot:item.finalExamNames="{item}">
                <v-chip :key="index" v-for="(finalExamName, index) in item.finalExamNames">
                    <div>{{finalExamName.name}} ({{finalExamName.speciality.nameShortUk}})</div>
                </v-chip>
            </template>

            <template v-slot:item.student="{item}">
                <div v-if="item.student !== null">
                    {{ item.student.surname }} {{item.student.name}} {{item.student.patronymic}}
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
    import {formatLongDate} from "../../../helpers/dateHelper";
    
    export default {
        props: {
            professors: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        data: () => ({
            headers: [
                {text: 'ПІП викладача', value: 'professor'},
                {text: 'Посилання на GoogleMeet', value: 'meetLink'},
                {text: 'Дисципліна', value: 'finalExamNames'},
                {text: 'Студент, який екзаменується', value: 'student'},
                // {text: 'Дії', value: 'actions', sortable: false}
            ],
            formatLongDate,
        }),
    }
</script>
<style lang="scss">
    .exam-block {
        display: inline-block;
        border-right: 1px solid #CCCCCC;
        
        &:first-child {
            border-left: 1px solid #CCCCCC;
        }
    }
</style>