<template>
    <div>
        <v-container v-if="loading" fluid fill-height>
            <v-layout align-center justify-center>
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-container>
        <v-list v-if="!loading" two-line>
            <template v-for="(topic, index) in topics">
                <v-list-item
                        :class="{
                                    'active-list-item': isSelectedTopic(topic), 
                                    'passed-list-item': (topic.lectureTopicClass !== null),
                                }"
                        :key="topic.id"
                        :ripple="topic.lectureTopicClass === null"
                        @click="(topic.lectureTopicClass === null) 
                                        ? toggleTopic(topic) 
                                        : null"
                >
                    <v-list-item-content class="topic-number">
                                
                        <span class="title">
                            <v-tooltip right>
                                 <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">turned_in</v-icon> 
                                 </template>
                                <span>Номер лекції по порядку</span>
                            </v-tooltip>
                            <span class="text-center">{{topic.orderNumber}}</span>
                        </span>

                        <span v-if="topic.lectureTopicClass !== null" class="caption">
                            <v-tooltip right slot="activator">
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">event</v-icon> 
                                </template>
                                <span>Дата проведення</span>
                            </v-tooltip>
                            <span>{{formatDate(topic.lectureTopicClass.classDate)}}</span>
                        </span>

                    </v-list-item-content>
                    <v-list-item-content>
                        {{ topic.nameUk }}
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text>
                            <div class="subtitle-1 academic-hours">{{ topic.academicHours }} год.</div>
                        </v-list-item-action-text>
                        <v-icon
                                large
                                v-if="topic.lectureTopicClass === null && !isSelectedTopic(topic)"
                                color="grey lighten-1"
                        >radio_button_unchecked</v-icon>

                        <v-icon
                                large
                                v-if="topic.lectureTopicClass === null && isSelectedTopic(topic)"
                                color="blue darken-4"
                        >radio_button_checked</v-icon>
                        <v-icon
                                large
                                v-if="topic.lectureTopicClass !== null"
                                color="orange darken-3"
                        >done_all</v-icon>
                    </v-list-item-action>

                </v-list-item>
                <v-divider
                        v-if="index + 1 < topics.length"
                        :key="`divider${topic.id}`"
                ></v-divider>
            </template>
        </v-list>
    </div>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {formatMarkDate} from "../../../helpers/dateHelper";

    export default {
        data: () => ({
            topics: [],
            selectedTopics: [],
            loading: false,
        }),
        props: {
            activeModule: {
                type: Object,
            }
        },
        mounted() {
            this.loading = true;
            HttpService.getInstance()
                .get(`teacher/navigation/lectureTopicsClasses/${this.activeModule.id}/${this.$route.params.group}`)
                .then(resp => {
                    this.topics = resp;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = true;
                });
        },
        watch: {
            selectedTopics: function (newSelectedTopics) {
                this.$emit('selectedTopics', newSelectedTopics);
            }
        },
        methods: {
            formatDate(date) {
                return formatMarkDate(date);
            },
            isSelectedTopic(topic) {
                const topicIsFound = this.selectedTopics.find(t => t.id === topic.id);

                return topicIsFound !== undefined;
            },
            toggleTopic(topic) {
                const topicIsFound = this.selectedTopics.find(t => t.id === topic.id);

                if (topicIsFound === undefined && (this.selectedTopics.length === 0)
                ) {
                    this.selectedTopics.push(topic);
                }
                else {
                    this.selectedTopics = this.selectedTopics.filter(t => t.id !== topic.id);
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '~vuetify/src/styles/settings/_colors.scss';

    .active-list-item {
        background: rgba(0, 0, 0, 0.04);

        & .academic-hours {
            color: map-get($blue, "darken-4");
        }
    }

    .inactive-list-item {
        background: rgba(0, 0, 0, 0.0);
        opacity: 0.4;

        & .v-list__tile {
            cursor: not-allowed !important;
        }
    }

    .passed-list-item {
        background: rgba(0, 0, 0, 0.08);

        & .v-list__tile {
            cursor: not-allowed !important;
        }
    }

    .topic-number {
        min-width: 85px;
        max-width: 110px;
    }
</style>