<template>
    <div>
        <v-card class="mb-5" v-if="!loading">
            <v-card-text>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів:</div>
                    <div class="title">{{totalRating.totalSum}}</div>
                </div>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів (згідно коефіцієнтів):</div>
                    <div class="title">{{totalRating.totalSumRatio}}</div>
                </div>
                <div class="d-flex align-center mt-2">
                    <v-btn v-if="!totalRating.isConfirmed" color="primary" @click="confirmDialog = true">Затвердити звіт</v-btn>
                    <div v-if="totalRating.isConfirmed" class="title font-weight-light">
                        <v-chip
                                color="green"
                                text-color="white"
                        >
                            Затверджено ({{formatShortDate(totalRating.confirmationDate)}})
                        </v-chip></div>
                    <div class="title font-weight-light ml-12">Завідувач кафедрою: 
                                <v-chip
                                    v-if="!totalRating.isDptHeadApproved"
                                    class="ma-2"
                                    color="red"
                                    text-color="white"
                                >
                                    Не затверджено
                                </v-chip>
                                <v-chip
                                        v-if="totalRating.isDptHeadApproved"
                                        class="ma-2"
                                        color="green"
                                        text-color="white"
                                >
                                    Затверджено ({{formatShortDate(totalRating.confirmationDptHeadApproveDate)}})
                                </v-chip>
                    </div>
                    <div v-if="totalRating.isConfirmed" class="title font-weight-light ml-12">
                        <v-btn @click="print()" color="primary" text><v-icon>print</v-icon>  Роздрукувати звіт</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        
        <rating-directions 
                :rating-worktype-directions="ratingWorktypeDirections" 
                route-to-name="currentRatingWorkTypes" 
                :route-to-params="{department: $route.params.department}"
        ></rating-directions>

        <v-dialog v-model="confirmDialog" max-width="500">
            <approve-confirm-position
                    @shouldCloseConfirm="confirmDialog = false"
                    @shouldConfirm="confirmApprove()"
                    :loading="loading"
            >
            </approve-confirm-position>
        </v-dialog>

        <v-dialog v-model="printDialog" max-width="1200">
            <print-rating-dialog :confirm-id="totalRating.id" @close="printDialog = false"></print-rating-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import ApproveConfirmPosition from "./ApproveConfirmPosition";
    import PrintRatingDialog from "./PrintRatingDialog";
    import RatingDirections from "./RatingDirections";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        components: {
            ApproveConfirmPosition,
            PrintRatingDialog,
            RatingDirections,
        },
        mounted() {
            this.getRatingSummary();
            this.getItems();
        },
        data: () => {
            return {
                loading: false,
                confirmDialog: false,
                printDialog: false,
                ratingWorktypeDirections: [],
                totalRating: {
                    id: null,
                    totalSum: 0,
                    totalSumRatio: 0,
                    isConfirmed: null,
                    confirmationDate: null,
                    isDptHeadApproved: null,
                    confirmationDptHeadApproveDate: null,
                },

                formatShortDate,
            }
        },
        methods: {
            getRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getUserRatingSummary`)
                    .then(totalRating => {
                        this.totalRating = totalRating;

                        this.loading = false;
                    });
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDirectionsIndividualWithSum`)
                    .then(directions => {
                        this.ratingWorktypeDirections = directions;

                        this.loading = false;
                    })
            },
            confirmApprove() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`ratingUser/confirmRatingSummary/${this.$route.params.department}`)
                    .then(_ => {
                        this.getRatingSummary();
                        
                        this.loading = false;
                        this.confirmDialog = false;
                    });
            },
            print() {
                this.printDialog = true;
            }
        },
    }
</script>
<style lang="scss">
    .header-wrapper {
        min-height: 90px;
    }
    
    .no-word-break {
        word-break: normal !important;
    }
</style>