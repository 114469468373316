export const topicEvaluationModeEnum = {
    isNotEvaluated: 0,
    isEvaluated: 1,
};

export default [
    {
        id: topicEvaluationModeEnum.isNotEvaluated,
        nameUk: "Не оцінювана",
        nameEn: "Not evaluated",
    },
    {
        id: topicEvaluationModeEnum.isEvaluated,
        nameUk: "Оцінювана",
        nameEn: "Evaluated",
    }
];