<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="students"
                :server-items-length="students.length"
                :loading="loading"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item.student="{item}">
                <div class="text-bold">
                    {{ item.student.surname }} <span :class="{'blured': isBlur || isBlurAfter}">
                    {{item.student.name}} {{item.student.patronymic}}</span>
                </div>
                <div>{{item.student.academicGroup.groupNumber}} група</div>
            </template>
            
            <template v-slot:item.isCheckedIn="{item}">
                <div v-if="item.isCheckedIn" class="text--darken-4 green--text">
                    <v-icon>done</v-icon>
                </div>
                <v-icon v-if="!item.isCheckedIn">clear</v-icon>
            </template>

            <template v-slot:item.finalExams="{item}">
                    <v-card :key="`${index}_${item.id}`" v-for="(exam, index) in item.finalExams" 
                            class="d-inline-block ma-1"
                            :class="{
                                'green lighten-3 is-passed': exam.isPassed,
                                'orange lighten-3': !exam.isPassed && checkIfNowExamining(exam)
                            }" 
                            style="width: 250px;">
                        <v-card-title class="subtitle-2">
                            <v-tooltip left>
                                <template v-slot:activator="{on}">
                                    <strong v-on="on">{{exam.name.substring(0, 25)}}...</strong>
                                </template>
                                <span>{{exam.name}}</span>
                            </v-tooltip>
                        </v-card-title>
                        <v-card-subtitle class="pb-0">{{formatLongDate(exam.examDateTime)}}</v-card-subtitle>
                        <v-card-text class="pb-1">
                            <a v-if="exam.examiner.meetLink !== null && exam.examiner.meetLink !== ''"
                               class="d-block text-truncate"
                               :class="{'blured': isBlur}"
                               :href="exam.examiner.meetLink" target="_blank">
                                {{exam.examiner.professor.surname}} {{exam.examiner.professor.name}} {{exam.examiner.professor.patronymic}}
                            </a>
                            <div v-if="exam.examiner.meetLink === null || exam.examiner.meetLink === ''"
                                 :class="{'blured': isBlur}"
                                 class="d-block text-truncate"
                            >
                                {{exam.examiner.professor.surname}} {{exam.examiner.professor.name}} {{exam.examiner.professor.patronymic}}
                            </div>
                        </v-card-text>
                        <v-card-text class="pt-1 pb-1">
                            <v-chip :class="{'blured': isBlurAfter}" :color="getMarkColor(+exam.totalClassicValue, isBlurAfter)" dark>
                                {{exam.totalClassicValue}}
                            </v-chip>
                            <v-chip class="ml-2" :class="{'blured': isBlurAfter}" 
                                    color="orange lighten-3">{{exam.totalValue.toFixed(2)}}</v-chip>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center border_top_actions">
                            <v-tooltip bottom class="ml-2">
                                <template v-slot:activator="{on}">
                                    <v-btn v-on="on" icon @click="showDetailFn(exam.id)"
                                           small
                                           color="primary"><v-icon>remove_red_eye</v-icon></v-btn>
                                </template>
                                <span>Переглянути протокол</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn class="ml-5" v-on="on" @click="printProtocolFn(exam.id)" small icon>
                                        <v-icon>print</v-icon>
                                    </v-btn>
                                </template>
                                <span>Роздрукувати протокол</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-if="exam.finalExamScreenshot" v-slot:activator="{on}">
                                    <v-btn @click="showScreenShotFn(exam.finalExamScreenshot)" class="ml-5" color="orange darken-3" v-on="on" small icon>
                                        <v-icon>image</v-icon>
                                    </v-btn>
                                </template>
                                <span>Переглянути скріншот</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-if="exam.finalExamScreenshot === null" v-slot:activator="{on}">
                                    <v-btn disabled class="ml-5" color="gray" v-on="on" small icon>
                                        <v-icon>image</v-icon>
                                    </v-btn>
                                </template>
                                <span>Немає скріншоту</span>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
            </template>
        </v-data-table>

        <v-dialog max-width="1100" v-model="printDialog">
            <print-protocol-dialog 
                    v-if="printDialog" 
                    :exam-id="this.printExamId" 
                    @close="closePrintProtocol()"></print-protocol-dialog>
        </v-dialog>
        
        <v-dialog max-width="1300" v-model="detailDialog">
            <v-card v-if="detailDialog">
                <v-container>
                    <v-layout>
                        <student-exam-detail is-from-admin :exam-id="+this.detailExamId" :is-set-started="false"></student-exam-detail>
                    </v-layout>
                </v-container>
            </v-card>
        </v-dialog>
        
        <v-dialog max-width="1300" v-model="screenShotDialog">
            <preview-screen-shot :photo-url="screenShotUrlId" v-if="screenShotDialog"></preview-screen-shot>
        </v-dialog>
    </div>
</template>

<script>
    import moment from "moment";
    import {formatLongDate} from "../../../helpers/dateHelper";
    import PrintProtocolDialog from "../teacherDashboard/PrintProtocolDialog";
    import StudentExamDetail from "../teacherDashboard/StudentExamDetail";
    import PreviewScreenShot from "../common/PreviewScreenShot";
    
    export default {
        components: {
            PrintProtocolDialog,
            StudentExamDetail,
            PreviewScreenShot,
        },
        props: {
            students: {
                type: Array,
            },
            loading: {
                type: Boolean,
            },
            isBlur: {
                type: Boolean,
            },
            isBlurAfter: {
                type: Boolean,
            }
        },
        data: () => ({
            headers: [
                {text: 'ПІП студента', value: 'student', width: "280px"},
                {text: 'Реєстрація на іспит', value: 'isCheckedIn', width: "50px"},
                {text: 'Екзаменаційні дисципліни', value: 'finalExams'},
                // {text: 'Дії', value: 'actions', sortable: false}
            ],
            formatLongDate,

            printDialog: false,
            printExamId: null,

            detailDialog: false,
            detailExamId: null,
            
            screenShotDialog: false,
            screenShotUrlId: null,
        }),
        methods: {
            printProtocolFn(examId) {
                this.printDialog = true;
                this.printExamId = examId;
            },
            closePrintProtocol() {
                this.printDialog = false;
                this.printExamId = null;
            },

            showDetailFn(examId) {
                this.detailDialog = true;
                this.detailExamId = examId;
            },
            closeDetailFn() {
                this.detailDialog = false;
                this.detailExamId = null;
            },
            showScreenShotFn(screenShotUrl) {
                this.screenShotDialog = true;
                this.screenShotUrlId = screenShotUrl;
            },
            
            checkIfNowExamining(exam) {
                const examDateTimeStartMoment = moment(exam.examDateTime);
                
                const examDateTimeEndMoment = examDateTimeStartMoment.clone().add(30, "minute");
                
                if (moment().isAfter(examDateTimeStartMoment) && moment().isBefore(examDateTimeEndMoment)) {
                    return true;
                }
                
                return false;
            },
            
            getMarkColor(mark, isBlured) {
                if (isBlured) {
                    return 'grey lighten-1';
                }
                
                switch (mark) {
                    case 5:
                        return 'green darken-3';
                    case 4:
                        return 'light-blue darken-3';
                    case 3:
                        return 'orange darken-1';
                    case 2:
                        return 'red darken-1';
                    default:
                        return 'grey darken-1';

                }
            }
        }
    }
</script>

<style lang="scss">
    .border_top_actions {
        border-top: 1px solid #ccc;
    }
    
    .is-passed {
        .border_top_actions {
            border-top: 1px solid white;
        }
    }
</style>