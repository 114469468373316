<template>
    <div>
        <v-card v-if="!loading">
            <v-card-title>{{directionSum.order}}. {{directionSum.directionName}}</v-card-title>
            <v-card-text>
                <div class="d-flex align-center">
                    <div class="font-weight-thin mr-5">Кількість балів за даним напрямком</div>
                    <div class="title">{{directionSum.pointsSum}}</div>
                </div>
            </v-card-text>
        </v-card>
        <v-container v-if="loading">
            <v-row justify="center">
                <v-progress-circular color="primary" indeterminate size="150"></v-progress-circular>
            </v-row> 
        </v-container>
        <v-container>
            <v-row class="mb-2">
                <v-btn-toggle v-model="expandableState" mandatory>
                    <v-btn small color="green" text :value="true">
                        <span>Показати всі</span>
                    </v-btn>
                    <v-btn small color="red" text :value="false">
                        <span>Показати заповнені</span>
                    </v-btn>
                </v-btn-toggle>
                <div class="ml-2 d-inline-flex body-2">
                    <div class="department_individual pa-1">Індивідуальні & кафедральні показники</div>
                    <div class="only_department pa-1">Тільки кафедральні показники</div>
                    <div class="only_individual pa-1">Тільки індивідуальні показники</div>
                </div>
            </v-row>
            <v-row class="mb-2" v-if="ratingCheckerMode">
                <div class="d-flex flex-column">
                    <v-btn @click="approveAllEmpty()" color="primary">Затвердити усі пусті види робіт</v-btn>
                    <div class="caption">Види робіт, в яких наявні рейтингові позиції (будь-які) будуть ігноруватися</div>
                </div>
            </v-row>
            <v-row justify="center">
                <v-expansion-panels focusable v-model="expandedItems" multiple>
                    <v-expansion-panel
                            v-for="(workType, i) in workTypes"
                            :key="i"
                    >
                        <v-expansion-panel-header 
                        :class="{
                            'department_individual': workType.isDepartment && workType.isIndividual, 
                            'only_individual': !workType.isDepartment && workType.isIndividual,
                            'only_department': workType.isDepartment && !workType.isIndividual 
                        }">
                            <div class="d-flex">
                                <div class="d-flex flex-column worktype-name">
                                    <div class="font-weight-thin mb-2">Назва виду роботи</div>
                                    <div class="">
                                        <span class="mr-1" v-if="workType.isConfirmed === true">
                                            <v-icon color="green">check</v-icon>
                                        </span>
                                        <span class="mr-1" v-if="workType.isRejected === true">
                                            <v-icon color="red">clear</v-icon>
                                        </span>
                                        <strong>{{workType.orderCustom}}.</strong> {{workType.name}}
                                    </div>
                                    <div class="description-small">{{workType.description}}</div>
                                </div>
                                <div class="d-flex flex-column possible-points px-4">
                                    <div class="font-weight-thin mb-2">Можлива кількість балів</div>
                                    <div class="">{{workType.points}}</div>
                                    <v-divider/>
                                    <worktype-points 
                                            :work-type-is-english="workType.isEnglish"
                                            :work-type-points="workType.points"
                                            :rating-settings="ratingSettings"
                                    ></worktype-points>
                                </div>
                                <div class="d-flex flex-column px-4">
                                    <div class="font-weight-thin mb-2">Набрана кількість балів</div>
                                    <div class="title">
                                        <div v-if="workType.sum > 0 || workType.sum < 0" class="green--text">{{workType.sum}}</div>
                                        <div v-if="workType.sum === 0" class="red--text">{{workType.sum}}</div>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="ma-2" v-if="shouldShowWorktypeControlls()">
                                <v-btn @click="openDialogConfirmWorktype(workType.id)" color="primary" small outlined>
                                    Затвердити ВСІ позиції
                                </v-btn>
                                <v-btn class="ml-2" @click="openDialogRejectWorktype(workType.id)" color="red" small dark outlined>
                                    Відхилити напрямок (без позицій)
                                </v-btn>
                            </div>
                            <div v-if="couldAddPosition(workType)"
                                 class="mt-2">
                                <v-btn @click="addNewPosition(workType)" color="primary" text>Додати нову позицію
                                </v-btn>
                            </div>

                            <v-simple-table v-if="workType.workTypeRatingItems.length">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left" width="45%">Назва позиції</th>
                                        <th class="text-left">Кількість балів</th>
                                        <th v-if="showProfessor" class="text-left">Викладач</th>
                                        <th class="text-left">Вкладення</th>
                                        <th class="text-left" width="12%">Дії</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                            v-for="ratingItem in workType.workTypeRatingItems"
                                            :key="ratingItem.id"
                                    >
                                        <td v-html="ratingItem.workName"></td>
                                        <td>
                                            <div v-if="!showJoined">{{ratingItem.point}}</div>
                                            <div v-if="showJoined">{{ratingItem.pointJoined}}</div>
                                        </td>
                                        <td v-if="showProfessor">
                                            <div v-if="!showJoined" class="professor-name">
                                                {{ratingItem.professor.surname}}&nbsp;{{nameInitials(ratingItem.professor.name)}}&nbsp;{{nameInitials(ratingItem.professor.patronymic)}}
                                            </div>
                                            
                                            <div v-if="showJoined">
                                                <ul>
                                                    <li v-for="proffessorJ in ratingItem.professorsJoined">
                                                        <div :class="{'professor-name': true, 'font-weight-bold': proffessorJ.isMain}">
                                                            <span class="subtitle-2" style="width: 30px; display: inline-block">{{proffessorJ.pointSum}}</span>
                                                            <span class="ml-1">{{proffessorJ.surname}}&nbsp;{{nameInitials(proffessorJ.name)}}&nbsp;{{nameInitials(proffessorJ.patronymic)}}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="ratingItem.workUrl !== '' && ratingItem.workUrl !== null">
                                                URL: <a class="attachment_file_link" target="_blank" :href="formatUrl(ratingItem.workUrl)">{{ratingItem.workUrl}}</a>
                                            </div>
                                            <v-divider v-if="(ratingItem.workUrl !== '' && ratingItem.workUrl !== null) && ratingItem.ratingFiles.length" 
                                                       class="mt-2 mb-2"></v-divider>
                                            <ul>
                                                <li v-for="file in ratingItem.ratingFiles">
                                                    <a class="attachment_file_link" target="_blank"
                                                       :href="`${$config.apiEndpoint}/file/get/ratingFile/${file.fileName}`">
                                                        {{file.fileName}} ({{(file.size/1024).toFixed(1)}} kb)
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <template v-if="couldAddEditPosition(workType, ratingItem)">
                                                <v-btn icon @click="onEditPosition(ratingItem.id, workType)">
                                                    <v-icon color="blue darken-4">create</v-icon>
                                                </v-btn>
                                                <v-btn v-if="ratingItem.isMain !== false" icon @click="onRemovePosition(ratingItem.id)">
                                                    <v-icon color="red darken-4">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-if="ratingCheckerMode && !acceptLoading">
                                                <div style="min-width: 100px;">
                                                    <v-btn v-if="!ratingItem.isAccepted"
                                                           class="mx-1"
                                                           fab
                                                           dark
                                                           small
                                                           color="primary"
                                                           @click="acceptPosition(ratingItem.id)"
                                                    >
                                                        <v-icon>check</v-icon>
                                                    </v-btn>
                                                    <v-btn v-if="!ratingItem.isRejected"
                                                           class="mx-1"
                                                           fab
                                                           dark
                                                           small
                                                           color="red"
                                                           @click="openRejectPosition(ratingItem.id)"
                                                    >
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                            <v-icon v-if="ratingItem.isAccepted === true" color="green" size="30">done_outline</v-icon>
                                            <div v-if="ratingItem.isRejected === true">
                                                <v-icon color="red" size="30">clear</v-icon>
                                                
                                                <position-rejection-info
                                                        :id="ratingItem.id"
                                                        :comment="ratingItem.comment"
                                                        @modified="rejected()"
                                                ></position-rejection-info>
                                            </div>
                                            
                                            <template v-if="acceptLoading">
                                                <v-progress-circular :indeterminate="true"
                                                                     color="info"
                                                ></v-progress-circular>
                                            </template>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
                <div class="subtitle-1 font-weight-thin mt-4" 
                     v-if="workTypes.length === 0 && !loading">Немає позицій для відображення</div>
            </v-row>
        </v-container>

        <v-dialog v-model="dialog" max-width="850px" scrollable>
            <add-edit-position
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :work-type-id="workType.id"
                    :work-type-points="workType.points"
                    :work-type-is-english="workType.isEnglish"
                    :work-type-is-enumerable="workType.isEnumerable"
                    :rating-settings="ratingSettings"
                    :is-department-mode="isDepartmentMode"
            ></add-edit-position>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="400">
            <delete-confirm-position
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm-position>
        </v-dialog>

        <v-dialog v-model="dialogReject" max-width="500">
            <reject-confirm-position
                    @shouldCloseRejectConfirm="dialogReject = false"
                    @rejected="rejected"
                    :position-id="dialogRejectId"
            >
            </reject-confirm-position>
        </v-dialog>

        <v-dialog v-model="dialogRejectWorktype" max-width="500">
            <reject-confirm-worktype
                    @shouldCloseRejectConfirm="dialogRejectWorktype = false"
                    @rejected="rejectedWorktype"
                    :work-type-id="dialogWorkTypeId"
            >
            </reject-confirm-worktype>
        </v-dialog>

        <v-dialog v-model="dialogConfirmWorktype" max-width="500">
            <approve-confirm-worktype
                    @shouldCloseApproveConfirm="dialogConfirmWorktype = false"
                    @approved="approvedWorktype"
                    :work-type-id="dialogWorkTypeId"
            >
            </approve-confirm-worktype>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AddEditPosition from "./AddEditPosition";
    import DeleteConfirmPosition from "./DeleteConfirmPosition";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import WorktypePoints from "./WorktypePoints";
    import {nameInitials} from "../../../helpers/stringHelper";
    import RejectConfirmPosition from "./RejectConfirmPosition";
    import PositionRejectionInfo from "./PositionRejectionInfo";
    import ApproveConfirmWorktype from "./ApproveConfirmWorktype";
    import RejectConfirmWorktype from "./RejectConfirmWorktype";
    import {rolesEnum} from "../../../constants/rolesEnum";

    export default {
        components: {
            AddEditPosition,
            DeleteConfirmPosition,
            RejectConfirmPosition,
            WorktypePoints,
            PositionRejectionInfo,
            ApproveConfirmWorktype,
            RejectConfirmWorktype,
        },
        props: {
            summaryApiEndpoint: {
                type: String,
                default: ""
            },
            itemsApiEndpoint: {
                type: String,
                default: ""
            },
            couldEdit: {
                type: Boolean,
                default: true,
            },
            isDepartmentMode: {
                type: Boolean,
                default: false,
            },
            showProfessor: {
                type: Boolean,
                default: false,
            },
            showJoined: {
                type: Boolean,
                default: false,
            },
            ratingCheckerMode: {
                type: Boolean,
                default: false,
            }
        },
        data: () => {
            return {
                nameInitials,
                
                dialog: false,
                workType: null,

                editId: null,
                editMode: false,
                dialogRemove: false,
                deleteId: null,
                dialogReject: false,
                dialogRejectId: null,
                loading: false,

                dialogRejectWorktype: false,
                dialogConfirmWorktype: false,
                dialogWorkTypeId: null,

                workTypes: [],
                workTypesInitial: [],
                directionSum: {},
                ratingSettings: {
                    englishRatio: 0,
                    ukrainianRatio: 0,
                    externalWorkRatio: 0,
                },
                expandedItems: [],
                expandableState: true,

                acceptLoading: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get(`ratingUser/getRatingSettings`)
                .then(ratingSettings => {
                    this.ratingSettings = ratingSettings;
                });
            
            this.getSummary();
            this.getItems();
        },
        watch: {
            expandableState: function (shouldFilter, oldShouldFilter) {
                if (shouldFilter !== oldShouldFilter) {
                    this.filterFilledUpItems(shouldFilter);
                }
            },
        },
        methods: {
            couldAddPosition(workType) {
                if (this.isDepartmentMode) {
                    return (this.couldEdit && workType.isConfirmed !== true && (workType.isDepartment && !workType.isIndividual));
                }
                else {
                    return (this.couldEdit && workType.isConfirmed !== true && ((workType.isDepartment && workType.isIndividual) || (!workType.isDepartment && workType.isIndividual)));
                }
            },
            couldAddEditPosition(workType, ratingItem) {
                if (this.isDepartmentMode && !this.ratingCheckerMode) {
                    return (this.couldEdit && (ratingItem && !ratingItem.isAccepted) && (workType.isDepartment && !workType.isIndividual));
                }
                else if (!this.ratingCheckerMode){
                    return (this.couldEdit && (ratingItem && !ratingItem.isAccepted) && ((workType.isDepartment && workType.isIndividual) || (!workType.isDepartment && workType.isIndividual)));
                }
                else {
                    return false;
                }
            },
            filterFilledUpItems(shouldFilter) {
                if (shouldFilter === false) { // Show only filled-up items
                    const filteredItems = [...this.workTypes].filter(w => w.workTypeRatingItems.length > 0);

                    this.workTypes = filteredItems;
                    this.$forceUpdate();
                }
                else {
                    this.workTypes = [...this.workTypesInitial];

                    this.$forceUpdate();
                }
            },
            addNewPosition(workType) {
                this.dialog = true;
                this.editMode = false;
                this.workType = workType;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;
                this.editMode = false;

                this.getSummary();
                this.getItems();
            },

            onEditPosition(positionId, workType) {
                this.dialog = true;
                this.editId = positionId;
                this.workType = workType;
                this.editMode = true;
            },

            onRemovePosition(positionId) {
                this.dialogRemove = true;
                this.deleteId = positionId;
            },

            removed() {
                this.getSummary();
                this.getItems();

                this.dialogRemove = false;
                this.deleteId = null;
            },

            getSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(this.summaryApiEndpoint)
                    .then(directionSum => {
                        this.directionSum = directionSum;

                        this.loading = false;
                    })
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(this.itemsApiEndpoint)
                    .then(items => {
                        this.workTypes = [...items];
                        this.workTypesInitial = [...items];

                        this.filterFilledUpItems(this.expandableState);
                        
                        this.loading = false;
                    });
            },
            approveAllEmpty() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`ratingAdmin/approveAllEmptyDirectionWorktypes/${this.$route.params.direction}/${this.$route.params.department}`)
                    .then(_ => {
                        this.loading = false;

                        this.getItems();
                    });
            },
            acceptPosition(positionId) {
                this.acceptLoading = true;
                HttpService.getInstance()
                .post(`ratingAdmin/acceptPosition/${positionId}`)
                .then(_ => {
                    this.acceptLoading = false;
                    
                    this.getItems();
                });
            },
            openRejectPosition(positionId) {
                this.dialogReject = true;
                this.dialogRejectId = positionId;
            },
            rejected() {
                this.getItems();

                this.dialogReject = false;
                this.dialogRejectId = null;
            },
            openDialogRejectWorktype(workTypeId) {
                this.dialogRejectWorktype = true;
                this.dialogWorkTypeId = workTypeId;
            },
            openDialogConfirmWorktype(workTypeId) {
                this.dialogConfirmWorktype = true;
                this.dialogWorkTypeId = workTypeId;
            },
            rejectedWorktype() {
                this.getItems();

                this.dialogRejectWorktype = false;
                this.dialogWorkTypeId = null;
            },
            approvedWorktype() {
                this.getItems();

                this.dialogConfirmWorktype = false;
                this.dialogWorkTypeId = null;
            },
            formatUrl(url) {
                if (url.startsWith("http")) {
                    return url;
                }
                else {
                    return `http://${url}`;
                }
            },
            shouldShowWorktypeControlls() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.ratingist]) && this.ratingCheckerMode;
            }
        },
    }
</script>
<style lang="scss">
    .description-small {
        font-size: 10px;
        margin-top: 10px;
    }
    
    .worktype-name {
        width: 60%;
    }

    .possible-points {
        width: 20%;
    }
    
    .points-preview-block {
        min-width: 80px;
    }
    
    .attachment_file_link {
        word-break: break-all;
    }
    
    .department_individual {
        background-color: rgb(187, 222, 251, 0.3);
    }

    .only_individual {
        background-color: rgb(255, 248, 225, 0.60);
    }

    .only_department {
        background-color: rgb(255, 235, 238, 0.60);
    }
    
    .professor-name {
        white-space: nowrap;
    }
</style>