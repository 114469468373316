<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap xs12>
            <v-flex xs12 class="mt-5">
                <v-expansion-panels popout>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="title">Протокол ранжування</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <final-exam-protocols-ranging></final-exam-protocols-ranging>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="title">Протокол денний</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <final-exam-protocols-daily></final-exam-protocols-daily>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="title">Протокол підсумковий</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <final-exam-protocols-final></final-exam-protocols-final>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="title">Статистика по іспитам</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <final-exam-statistics></final-exam-statistics>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import FinalExamProtocolsDaily from "./FinalExamProtocolsDaily";
    import FinalExamProtocolsFinal from "./FinalExamProtocolsFinal";
    import FinalExamProtocolsRanging from "./FinalExamProtocolsRanging";
    import FinalExamStatistics from "./FinalExamStatistics";
    
    export default {
        components: {
            FinalExamProtocolsDaily,
            FinalExamProtocolsFinal,
            FinalExamProtocolsRanging,
            FinalExamStatistics,
        }
    }
</script>