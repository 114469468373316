<template>
    <v-container grid-list-md>
        <surveys-list/>
    </v-container>
</template>
<script>
    import SurveysList from "../../components/dashboard/SurveysList";

    export default {
        components: {
            SurveysList,
        },
    }
</script>