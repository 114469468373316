<template>
    <div>
        <vue-plyr
            ref="plyr"
            :options="options"
        >
            <video
                controls
                crossorigin
                playsinline
            >
                <source
                    size="720"
                    :src="getVideoLink(this.videoContent.fileName)"
                    type="video/mp4"
                />
            </video>
        </vue-plyr>
    </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";

const configuration = AppConfig(config);

export default {
    name: 'VideoPlayer',
    props: {
        videoContent: {
            type: Object,
            default: () => {
                return null;
            }
        },
    },
    data() {
        return {
            options: {
                controls: ['fullscreen', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings'],
                settings: ['quality', 'speed'],
                speed: {selected: 1, options: [0.75, 1, 1.25, 1.5]},
            },
        }
    },
    mounted() {
        this.$refs.plyr.player.on('timeupdate', () => {
            const currentTime = this.$refs.plyr.player.currentTime;
            this.$emit("currentTime", currentTime);
        })
    },
    methods: {
        getVideoLink(videoFile) {
            return `${configuration.apiEndpoint}/file/get/video/${videoFile}`;
        },
    },
}
</script>