<template>
    <div v-if="!loading">
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        №
                    </th>
                    <th class="text-left">
                        {{$t("eAristoStudent.topicName")}}
                    </th>
                    <th class="text-left">
                        {{$t("eAristoStudent.action")}}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="topic in topics"
                    :key="topic.id"
                >
                    <td>{{ topic.orderNumber }}</td>
                    <td>{{ topic.nameUk }}</td>
                    <td>
                        <v-btn icon
                               :to="{ name: 'eAristoStudentSubjectModuleTopicContent', 
                               params: { subjectId: $route.params.subjectId, moduleId: $route.params.moduleId, topic: topic.id }}">
                            <v-icon color="primary">send</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
    <div v-else>
        <e-aristo-loading-placeholder></e-aristo-loading-placeholder>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import eAristoLoadingPlaceholder from "../common/eAristoLoadingPlaceholder";

export default {
    components: {
        eAristoLoadingPlaceholder,
    },
    data() {
        return {
            loading: false,
            topics: [],
        }
    },
    mounted() {
        this.loading = true;
        HttpService.getInstance()
            .get(`eAristoStudent/getTopics/${this.$route.params.moduleId}`)
            .then(resp => {
                console.log(resp);
                this.topics = resp;

                this.loading = false;
            });
    },
}
</script>