<template>
    <v-container grid-list-md fluid fill-height>
        <vue-headful
                title="Панель іспиту"
        />
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 160px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Спеціальності</v-subheader>
                            <navigation-list-template :items="specialitiesNav"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex v-if="$route.params.specialityId !== undefined" style="margin-left: 160px; width: calc(100% - 460px);">
                <v-card>
                    <admin-student-list-index></admin-student-list-index>
                </v-card>
            </v-flex>
            <v-flex v-else style="margin-left: 160px; width: calc(100% - 460px);">
                <div class="title">Будь ласка, виберіть дату та спеціальність</div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import AdminStudentListIndex from "../../components/finalExam/adminDashboard/AdminStudentListIndex";

    export default {
        components: {
            AdminStudentListIndex,
            NavigationListTemplate
        },
        mounted() {
            HttpService.getInstance()
                .get("navigation/getFinalExamSpecs")
                .then(navItems => {
                    this.specialitiesNav = this.remapSubjNavItems(navItems);
                });
        },
        methods: {
            remapSubjNavItems(subjNavItems) {
                if (subjNavItems === undefined) {
                    return [];
                }

                let subjNavigationRemapped = subjNavItems.slice(0);

                subjNavigationRemapped.map(spec => {

                    spec.name = spec.nameShortUk;

                    spec.route = {
                        name: 'adminStudentExamList',
                        params: {
                            specialityId: spec.id,
                            date: this.$route.params.date,
                        }
                    };
                    //
                    // let someSpecActive = spec.find(ch => ch.model);
                    //
                    // if (spec.id.toString() === this.$route.params.speciality && someSpecActive !== undefined) {
                    //     spec.model = true;
                    // }

                    return spec;
                });

                return subjNavigationRemapped;
            },
        },
        data: () => {
            return {
                specialitiesNav: [],
            };
        }
    }
</script>