export const paymentStatusesEnum = {
    new: 0,
    inProgress: 1,
    waitConfirmation: 2,
    failure: 3,
    finished: 4,
    aborted: 4
};

export default [
    {
        id: paymentStatusesEnum.new,
        nameUk: "Новий платіж",
        nameEn: "New payment",
        color: "blue"
    },
    {
        id: paymentStatusesEnum.inProgress,
        nameUk: "В обробці",
        nameEn: "In progress",
        color: "yellow"
    },
    {
        id: paymentStatusesEnum.waitConfirmation,
        nameUk: "Очікує підтвердження",
        nameEn: "Wait for confirmation",
        color: "amber"
    },
    {
        id: paymentStatusesEnum.failure,
        nameUk: "Помилка",
        nameEn: "Failure",
        color: "red"
    },
    {
        id: paymentStatusesEnum.finished,
        nameUk: "Проведений",
        nameEn: "Finished",
        color: "green"
    },
    {
        id: paymentStatusesEnum.aborted,
        nameUk: "Перерваний",
        nameEn: "Aborted",
        color: "orange"
    },
];
