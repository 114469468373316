<template>
    <v-container fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 450px;">
                <v-card height="100%">
                    <v-navigation-drawer :width="450" :mobile-break-point="0">
                        <v-list dense two-line>
                            <v-subheader class="mt-3 grey--name name--darken-1">Профілі та дисципліни</v-subheader>
                            <navigation-list-template :items="profiles"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>
            <v-flex style="margin-left: 460px; width: calc(100% - 460px);">
                <subject-pool-index v-if="$route.params.subject"/>
                <v-card v-if="!$route.params.subject">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть дисципліну та модуль
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import SubjectPoolIndex from "../../../../components/superAdminStep/Dictionaries/SubjectPool/SubjectPoolIndex";
    import NavigationListTemplate from "../../../../components/common/NavigationListTemplate";
    import HttpService from "../../../../HttpService";

    export default {
        components: {
            SubjectPoolIndex,
            NavigationListTemplate,
        },
        mounted() {
            HttpService.getInstance()
                .get("stepProfile/getAll")
                .then(res => {
                    this.profiles = res.map(profile => {
                        profile.children = profile.subjects.map(s => {
                            return {
                                name: `${s.nameUk} (${s.nameShortUk})`,
                                route: {
                                    name: 'subjectPoolGrid',
                                    params: {
                                        subject: s.id,
                                    }
                                }
                            }
                        });

                        return profile;
                    });
                });
        },
        data: () => {
            return {
                profiles: [],
            }
        }
    }
</script>