<template>
    <v-navigation-drawer
            fixed
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :fixed="true"
            :value="drawer"
            width="260"
            @input="$emit('toggleDrawer', $event)"
    >
        <v-list dense class="mt-4">
            <navigation-list-template :items="settingsItems" @selectValue="setTopNavigationItem($event)"/>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            this.settingsItems = this.remapItems(this.settingsItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            settingsItems: [],
            settingsItemsRaw: [
                {icon: 'account_circle', name: 'Профіль', route: {name: "profile"}},
                {icon: 'settings', name: 'Налаштування', route: {name: "settings"}},
            ],
        })
    }
</script>