<template>
    <div>
        <v-btn class="mb-2" @click="$refs.questionsGrid.addItem()">Додати запитання</v-btn>
        <e-aristo-question-index ref="questionsGrid"></e-aristo-question-index>
    </div>
</template>

<script>
import EAristoQuestionIndex from "@/components/eAristoProfessor/topicContent/eAristoQuestions/eAristoQuestionIndex.vue";

export default {
    name: "AllTopicContent",
    components: {EAristoQuestionIndex}
}
</script>

<style scoped>

</style>