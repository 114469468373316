<template>
    <marks-tabs>
        <overview-tab-content slot="overview-tab"/>
        <mark-tab-content
                slot="markContent" 
                slot-scope="tabScope"
                v-if="tabScope.shouldShow"
                :module="tabScope.module"
                :activeModule="tabScope.activeModule"
        />
    </marks-tabs>
</template>
<script>
    import MarksTabs from "../../common/MarksTabs";
    import OverviewTabContent from "./OverviewTabContent";
    import MarkTabContent from "./MarkTabContent";

    export default {
        components: {
            MarksTabs,
            OverviewTabContent,
            MarkTabContent,
        }
    }
</script>