<template>
    <v-layout row wrap>
        <v-flex xs12>
            <v-card class="mb-2">
                <v-card-text>
                    <div class="title">{{$t('studentQuiz.quizIsFinished')}}</div>
                    <div class="display-2 mt-5">{{quizFinish.percentage.toFixed(2)}}%</div>
                    <div class="display-2 mt-2">{{quizFinish.correctQuestions}}/{{quizFinish.totalQuestions}}</div>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../HttpService";
    
    export default {
        data: () => ({
            quizFinish: {
                totalQuestions: 0,
                correctQuestions: 0,
                percentage: 0,
                traditionalMark: 0,
            },
        }),
        mounted() {
            console.log('this.$route.params', this.$route.params);
            HttpService.getInstance()
                .post(`studentStep/finishQuizAttempt/${this.$route.params.quizId}`)
                .then(resp => {
                    this.quizFinish = resp;
                });  
        },
    }
</script>