<template>
    <VueFileAgent
        :uploadUrl="`${configuration.apiEndpoint}/eAristoProfessor/topic/${topicId}/upload/presentation`"
        :uploadHeaders="{
           'Cache-Control': '',
           'Authorization': `Bearer ${getAccessToken()}`
        }"
        :multiple="false"
        :deletable="true"
        :linkable="true"
        :meta="true"
        :accept="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
        :maxSize="'50MB'"
        :maxFiles="1"
        :helpText="'Виберіть PPTX файл (не гарантується 100% відтворення, як на Вашому компʼютері)'"
        :errorText="{
            type: 'Виберіть, будь ласка, файл формату PPTX',
            size: 'Ви вибрали надто великий файл!',
        }"
        :thumbnailSize="300"
        @select="filesSelected($event)"
        @upload="filesUploaded($event)"
        v-model="presentationFile"
    >
        <template v-slot:file-preview>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-layout>
                <v-icon class="icon-presentation" x-large>contacts</v-icon>
            </v-container>
        </template>
    </VueFileAgent>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";

let configuration = AppConfig(config);

export default {
    name: "PresentationUploader",
    props: {
        topicId: {
            type: Number,
        },
    },
    methods: {
        getAccessToken() {
            return localStorage.getItem('access_token');
        },
        filesSelected() {
            this.loadingSave = true;
            this.uploaded = true;
        },
        filesUploaded(files) {
            console.log("files", files[0].data);
            this.$emit('uploadedFiles', files[0].data)
            this.loadingSave = false;
        },
    },
    data: () => {
        return {
            configuration,
            loadingSave: false,
            presentationFile: null,
            uploaded: false,
        };
    },
}
</script>

<style lang="scss">
.vue-file-agent .file-preview-wrapper:before {
    background: rgba(0,0,0,.05);
}

.icon-presentation {
    position: absolute !important;
    left: 40%;
}
</style>