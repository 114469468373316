<template>
    <v-navigation-drawer
            :fixed="true"
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :value="drawer"
            fixed
            @input="$emit('toggleDrawer', $event)"
    >
        <v-container fluid>
            <v-btn class="mb-6" small :to="{
                        name: 'headDeptRatingRoot', 
                        params: {department: $route.params.department}
                      }">
                <v-icon>keyboard_backspace</v-icon>
                Перейти до меню
            </v-btn>
        </v-container>

        <v-progress-circular v-if="loading" :size="150"
                             color="primary"
                             indeterminate></v-progress-circular>

        <navigation-list-template :items="professors"/>
    </v-navigation-drawer>
</template>
<script>
    import NavigationStudNavList from '../../common/NavigationStudNavList';
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            NavigationStudNavList,
            DepartmentSubjectSwitcher,
            NavigationListTemplate,
        },
        props: {
            drawer: {
                type: Boolean,
            },
        },
        mounted() {
            this.getProfessors();
        },
        data: () => {
          return {
              professors: [],
              loading: false,
          }
        },
        methods: {
            getProfessors() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/navigation/departments/${this.$route.params.department}/professors`)
                    .then(professors => {
                        this.loading = false;

                        this.professors = professors.map(p => {
                            return {
                                name: `${p.academicPosition.nameShortUk} ${p.surname} ${p.name} ${p.patronymic}`,
                                route: {
                                    name: 'headDepartmentRating',
                                    params: {
                                        department: this.$route.params.department,
                                        professor: p.id,
                                    }
                                }
                            };
                        });
                    })
            }
        }
    }
</script>