<template>
    <div>
        <v-card class="mb-5" v-if="!loading">
            <v-card-text>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів:</div>
                    <div class="title">{{totalRating.totalSum}}</div>
                </div>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів (згідно коефіцієнтів):</div>
                    <div class="title">{{totalRating.totalSumRatio}}</div>
                </div>
                <div class="d-flex align-center mt-2">
                    <div class="title font-weight-light">
                        <v-chip
                                v-if="totalRating.isConfirmed"
                                color="green"
                                text-color="white"
                        >
                            Затверджено викладачем ({{formatShortDate(totalRating.confirmationDate)}})
                        </v-chip>
                        <v-chip
                                v-if="!totalRating.isConfirmed"
                                color="red"
                                text-color="white"
                        >
                            НЕ затверджено викладачем
                        </v-chip>
                    </div>

                    <v-btn v-if="totalRating.isConfirmed && !totalRating.isDptHeadApproved" color="primary" class="ml-2" 
                           @click="confirmDialog = true">Затвердити звіт</v-btn>
                    <div v-if="totalRating.isDptHeadApproved" class="title font-weight-light ml-6">Завідувач кафедрою: 
                        <v-chip
                            class="ma-2"
                            color="green"
                            text-color="white"
                    >
                        Затверджено ({{formatShortDate(totalRating.confirmationDptHeadApproveDate)}})
                    </v-chip>
                    </div>
                    <v-btn v-if="totalRating.isConfirmed"
                           color="red" dark class="ml-2"
                           @click="confirmDialogRemove = true">Зняти затвердежння</v-btn>
                    <div v-if="totalRating.isConfirmed"  class="title font-weight-light ml-12">
                        <v-btn @click="print()" color="primary" text><v-icon>print</v-icon>  Роздрукувати звіт</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        
        <rating-directions 
                :rating-worktype-directions="ratingWorktypeDirections" 
                route-to-name="headDepartmentRatingWorkTypes" 
                :route-to-params="{department: $route.params.department, professor: $route.params.professor}"
        ></rating-directions>

        <v-dialog v-model="confirmDialog" max-width="600">
            <approve-confirm-head-dept-prof
                    @shouldCloseConfirm="confirmDialog = false"
                    @shouldConfirm="confirmApprove()"
                    :loading="loading"
            >
            </approve-confirm-head-dept-prof>
        </v-dialog>
        
        <v-dialog v-model="confirmDialogRemove" max-width="600">
            <remove-confirm-head-dept
                    @shouldCloseConfirm="confirmDialogRemove = false"
                    @shouldConfirm="confirmRemove()"
                    :loading="loading"
            >
            </remove-confirm-head-dept>
        </v-dialog>

        <v-dialog v-model="printDialog" max-width="1200">
            <print-rating-dialog :confirm-id="totalRating.id" @close="printDialog = false"></print-rating-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import RatingDirections from "../../rating/ratingDirections/RatingDirections";
    import RemoveConfirmHeadDept from "./RemoveConfirmHeadDept";
    import ApproveConfirmHeadDeptProf from "./ApproveConfirmHeadDeptProf";
    import PrintRatingDialog from "../../rating/ratingDirections/PrintRatingDialog";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        components: {
            ApproveConfirmHeadDeptProf,
            PrintRatingDialog,
            RatingDirections,
            RemoveConfirmHeadDept,
        },
        mounted() {
            this.getRatingSummary();
            this.getItems();
        },
        data: () => {
            return {
                loading: false,
                confirmDialog: false,
                confirmDialogRemove: false,
                printDialog: false,
                ratingWorktypeDirections: [],
                totalRating: {
                    id: null,
                    totalSum: 0,
                    totalSumRatio: 0,
                    isConfirmed: null,
                    confirmationDate: null,
                    isDptHeadApproved: null,
                    confirmationDptHeadApproveDate: null,
                },
                
                formatShortDate,
            }
        },
        methods: {
            getRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getUserRatingSummaryByProfessorId/${this.$route.params.professor}`)
                    .then(totalRating => {
                        this.totalRating = totalRating;

                        this.loading = false;
                    });
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDirectionsIndividualWithSumByProfessorId/${this.$route.params.professor}`)
                    .then(directions => {
                        this.ratingWorktypeDirections = directions;

                        this.loading = false;
                    })
            },
            confirmApprove() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`ratingUser/confirmRatingHeadDeptSummary/${this.totalRating.id}`)
                    .then(_ => {
                        this.getRatingSummary();
                        
                        this.loading = false;
                        this.confirmDialog = false;
                    });
            },
            confirmRemove() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`ratingUser/removeConfirmRatingHeadDeptSummary/${this.totalRating.id}`)
                    .then(_ => {
                        this.getRatingSummary();
                        
                        this.loading = false;
                        this.confirmDialogRemove = false;
                    });
            },
            print() {
                this.printDialog = true;
            }
        },
    }
</script>
<style lang="scss">
    .header-wrapper {
        min-height: 90px;
    }
    
    .no-word-break {
        word-break: normal !important;
    }
</style>