<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <navigation-drawer-stud-nav
                @toggleDrawer="drawerApp = $event"
                :drawer="drawerApp"
                route-to="registers"
                :additional-route-params="{
                                            department: $route.params.department, 
                                            subject: $route.params.subject}"
        />
        <toolbar
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-main>
            <router-view/>
        </v-main>
        
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/admin/common/Toolbar";
    import TeacherNavigationDrawer from "../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import NavigationDrawerStudNav from "../../components/admin/common/NavigationDrawerStudNav";

    export default {
        components: {
            Toolbar,
            NavigationDrawerStudNav,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
