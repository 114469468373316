<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout v-if="!isBlank" row wrap>
            <v-flex xs12>
                <div class="headline">Виберіть групу</div>
                <navigation-stud-nav-list route-to="marksGroupRoot"
                                          :api-endpoint="`navigation/getNavRegistersForProfessor/${$route.params.department}/${$route.params.subject}`"
                                          :additional-route-params="{
                                            department: $route.params.department, 
                                            subject: $route.params.subject}"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationStudNavList from "../../components/common/NavigationStudNavList";

    export default {
        components: {
            NavigationStudNavList,
        },
        data: () => ({
            isBlank: true,
        }),
        mounted() {
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            checkIfIsBlank(route) {
                if (route.params.department !== undefined && route.params.subject !== undefined) {
                    this.isBlank = false;
                }
                else {
                    this.isBlank = true;
                }
            }
        },
        watch: {
            $route(route) {
                this.checkIfIsBlank(route);
            }
        }
    }
</script>