<template>
    <div>
        <v-card class="mb-2">
            <v-card-text>
                <v-layout row wrap>
                    <v-flex xs6 style="padding: 0px;">
                        <v-checkbox
                                class="mt-0 automatic-checkbox"
                                :label="$t('studentQuiz.automaticSwitch')"
                                v-model="automaticSwitch"
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs6>
                        <div class="title">
                            {{$t('studentQuiz.questionNo')}} {{getQuestionNumber()}}
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text v-if="!loading" class="px-1">
                <div>
                    <v-card
                            class="mb-4"
                            color="orange lighten-4"
                    >
                        <v-card-text>
                            {{question.question}}
                        </v-card-text>
                    </v-card>
                    
                    <div class="ma-2">
                        <student-quiz-answer
                                v-if="!question.isAnswered && !wasAnswered"
                                v-for="(answer, index) in question.stepQuestionAnswers"
                                :key="index"
                                :answer="answer"
                                :question="question"
                                @selectAnswer="onSelectAnswer"
                        />
                        <div v-if="!isStudentTest && (question.isAnswered || wasAnswered)">
                            <v-card
                                    class="mb-1"
                                    color="orange lighten-3"
                                    dark
                            >
                                <v-card-text>
                                    {{$t('studentQuiz.answeredQuestion')}}
                                </v-card-text>
                            </v-card>
                        </div>
                        
                        <div v-if="isStudentTest">
                            <div v-if="question.isAnswered && question.isRight === true">
                                <v-card
                                        class="mb-1"
                                        color="green lighten-3"
                                        dark
                                >
                                    <v-card-text>
                                        Відповідь вірна!
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div v-if="question.isAnswered && question.isRight === false">
                                <v-card
                                        class="mb-1"
                                        color="red dark-1"
                                        dark
                                >
                                    <v-card-text>
                                        Відповідь невірна!
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div v-if="question.isAnswered && question.rightAnswer">
                                <v-card
                                        class="mb-1"
                                        color="green darken-3"
                                        dark
                                >
                                    <v-card-text>
                                        <strong>Вірна відповідь:</strong>
                                        <div>{{question.rightAnswer.answer}}</div>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                        
                    </div>
                    <div class="mt-3 mb-2 d-flex flex-grow-1 justify-center">
                        <v-btn
                                @click="previousQuestion"
                                :disabled="!canGoToPreviousQuestion()"
                        >
                            <v-icon>keyboard_arrow_left</v-icon>
                            {{$t('studentQuiz.previousQuestion')}}
                        </v-btn>
                        <v-btn
                                class="ml-2"
                                color="primary"
                                @click="nextQuestion()"
                                :disabled="!canGoToNextQuestion()"
                        >
                            {{$t('studentQuiz.nextQuestion')}}
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="mt-5 mb-2 d-flex flex-grow-1 justify-center">
                    <v-btn
                            large
                            dark
                            color="red darken-1"
                            @click="finishQuiz()"
                    >{{$t('studentQuiz.finishQuiz')}}
                    </v-btn>
                </div>
            </v-card-text>
            <v-container v-if="loading" fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                            :size="150"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>
<script>
    import StudentQuizAnswer from "./StudentQuizAnswer";
    import HttpService from "../../HttpService";
    import store from "../../store";

    export default {
        data:() => ({
           automaticSwitch: true, 
        }),
        props: {
            question: {
                type: Object,
                default: () => {
                    return {
                        question: '',
                        stepQuestionAnswers: [],
                        stepAttemptQuestionId: 0,
                        isAnswered: false,
                    }
                }
            },
            questionCount: {
                type: Number,
                default: 0,
            },
            questionsAnswered: {
                type: Number,
                default: 0,
            },
            wasAnswered: {
              type: Boolean,
              default: false,  
            },
            loading: {
                type: Boolean,
                default: false,
            },

            ApiFinishQuizAttempt: String,
            ApiSaveQuestionAnswer: String,

            routeQuizFinish: String,
            routeQuizHome: String,
            routeQuizAdditionalParams: Object,
            routeQuizFinishAdditionalParams: Object,

            isStudentTest: Boolean,
        },
        components: {
            StudentQuizAnswer,
        },
        methods: {
            finishQuiz() {
                store.commit("snackMessages/set", {message: "Час для тестування вийшов", color: 'error'});

                HttpService.getInstance()
                    .post(this.ApiFinishQuizAttempt)
                    .then(resp => {
                        this.$router.push({
                            name: this.routeQuizFinish,
                            params: {...{}, ...this.routeQuizFinishAdditionalParams}
                        });
                    });
            },
            getQuestionNumber() {
                return parseInt(this.$route.params.questionNumber);
            },
            canGoToNextQuestion() {
                return this.getQuestionNumber() < this.questionCount;
            },
            nextQuestion() {
                if (this.getQuestionNumber() + 1 > this.questionCount) {
                    
                    if (this.questionCount <= this.questionsAnswered) {
                        this.finishQuiz();
                    }

                    return;
                }

                this.$router.push({
                    name: this.routeQuizHome,
                    params:
                        {
                            ...{
                                "questionNumber": this.getQuestionNumber() + 1,
                            }, 
                            ...this.routeQuizAdditionalParams
                        }
                });
            },
            canGoToPreviousQuestion() {
                return this.getQuestionNumber() > 1;
            },
            previousQuestion() {
                if (this.getQuestionNumber() - 1 < 1) {
                    return;
                }
                
                this.$router.push({
                    name: this.routeQuizHome,
                    params:
                        {
                            ...{
                                "questionNumber": this.getQuestionNumber() - 1,
                            }, 
                            ...this.routeQuizAdditionalParams
                        }
                });
            },
            onSelectAnswer(answer) {
                const answerSave = {
                    question: this.question,
                    answer: answer,
                };

                HttpService.getInstance({}, {}, true)
                    .post(this.ApiSaveQuestionAnswer, answerSave)
                    .then(resp => {
                        this.$emit('selectAnswer');

                        if (this.automaticSwitch) {
                            this.nextQuestion();
                        }
                    })
                    .catch(err => {
                        if (err.isExpiredQuiz) {
                            this.finishQuiz();
                        }
                    });
            }
        },
    }
</script>
<style lang="scss">
    .question {
        margin-bottom: 30px;
        border-bottom: 1px solid #ccc;
    }
    .automatic-checkbox {
        padding-top: 0px;
        
        & .v-input__slot {
            margin-bottom: 0px !important;
        }
        
        & .v-messages {
            display: none;
        }
    }
</style>