<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            loading-text="Завантаження... Зачекайте, будь ласка"
            class="elevation-3"
            hide-default-footer
            disable-pagination
    >
        <template v-slot:item.isApproved="{item}">
            <v-icon v-if="item.isApproved === true" color="green">check</v-icon>
        </template>
        <template v-slot:item.nameUk="{item}">
            {{item.nameUk}}
        </template>
        <template v-slot:item.maleFemaleRatio="{item}">
            {{item.countMales}}/{{item.countFemales}}
        </template>
        <template v-slot:item.totalSumSalaryRateSum="{item}">
            {{item.totalSumSalaryRateSum ? item.totalSumSalaryRateSum.toFixed(2) : "-"}}
        </template>        
        <template v-slot:item.averageAge="{item}">
            {{item.averageAge ? item.averageAge.toFixed(2) : "-"}}
        </template>
        <template v-slot:item.totalSum="{item}">
            <div class="font-weight-bold subtitle-1">{{item.totalSum}}</div>
        </template>
    </v-data-table>
</template>
<script> 
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            items(newItems, oldItems) {
                if (newItems.length > 0) {
                    const itemHeader = newItems[0];

                    itemHeader.ratingDirections.forEach(direction => {
                        this.headers.push(
                            {
                                text: `${direction.order}`,
                                value: `direction${direction.order}`,
                                width: "10%",
                            },
                            // {
                            //     text: `${direction.order} * k`,
                            //     value: `direction${direction.order}_ratio`,
                            //     width: "10%",
                            // }
                        );
                    });
                }
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: "Затв.", value: 'isApproved'},
                {text: '№', value: 'index'},
                {text: 'Назва', value: 'nameUk'},
                {text: 'К-сть спів.', value: 'professorsCount'},
                {text: '∑ ставок', value: 'salaryRateSum'},
                {text: 'μ вік', value: 'averageAge'},
                {text: 'Ч/Ж', value: 'maleFemaleRatio'},
                {text: '∑', value: 'totalSum'},
                {text: '∑/∑ ст.', value: 'totalSumSalaryRateSum'},
                // {text: '∑*k', value: 'totalSumRatio'},
                // {text: '∑*k / викл.', value: 'professorsCountTotalCorrelation'},
            ],
        }),
    }
</script>