<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex >
                <v-card>
                    <teacher-accountance-time :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import TeacherAccountanceTime from '@/components/teacher/teacherAccountanceTime/TeacherAccountanceTime';

export default  {
    components: {
        TeacherAccountanceTime,
    },
    data: () => {
        return {


        };
    },
}
</script>