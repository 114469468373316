<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-subheader>Тип посади співробітника</v-subheader>
                            <v-divider></v-divider>
                        </v-flex>

                        <v-btn-toggle
                                v-model="form.staffType"
                                mandatory
                        >
                            <v-btn :key="i" v-for="(staffType, i) in staffTypes" :value="staffType.id" rounded>
                                {{staffType.nameUk}}
                            </v-btn>
                        </v-btn-toggle>
                        
                        <v-flex xs12>
                            <v-subheader>Ідентифікаційні дані співробітника</v-subheader>
                            <v-divider></v-divider>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.surname" v-model="form.surname"
                                          label="Прізвище"></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.name" v-model="form.name"
                                          label="Ім'я"></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.patronymic" v-model="form.patronymic"
                                          label="По-батькові"></v-text-field>
                        </v-flex>
                        <v-flex xs6 v-if="form.staffType !== staffTypesEnum.adminNb">
                            <birthday-picker :errorMessages="errors.birthday" v-model="form.birthday"/>
                        </v-flex>
                        <v-flex xs6>
                            <v-radio-group :error-messages="errors.gender" v-model="form.gender" :mandatory="false">
                                <v-radio color="primary" label="Чоловіча" :value="0"></v-radio>
                                <v-radio color="red darken-3" label="Жіноча" :value="1"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <template v-if="form.staffType !== staffTypesEnum.adminNb">
                            <v-flex xs12>
                                <v-subheader>Дані про роботу</v-subheader>
                                <v-divider></v-divider>
                            </v-flex>
    
                            <v-flex xs4> 
                                <v-select
                                        :items="salaryRates"
                                        :error-messages="errors.salaryRate"
                                        v-model="form.salaryRate"
                                        label="Ставка з/п"
                                ></v-select>
                            </v-flex>
                        </template>
                        <template v-if="form.staffType === staffTypesEnum.deanOffice">
                            <v-flex xs12>
                                <v-subheader>Дані про факультет</v-subheader>
                                <v-divider></v-divider>
                            </v-flex>
                            
                            <v-flex xs12>
                                <v-select
                                        :items="faculties"
                                        :error-messages="errors.faculty"
                                        v-model="form.faculty"
                                        item-text="nameUk"
                                        item-value="id"
                                        return-object
                                        label="Факультет"
                                        multiple
                                        chips
                                ></v-select>
                            </v-flex>
                        </template>

                        <template v-if="form.staffType === staffTypesEnum.laboratoryStaff">
                            <v-flex xs12>
                                <v-subheader>Дані про кафедру та дисципліни</v-subheader>
                                <v-divider></v-divider>
                            </v-flex>

                            <v-flex xs12>
                                <v-select
                                        :items="departments"
                                        :error-messages="errors.departments"
                                        v-model="form.departments"
                                        item-text="nameUk"
                                        item-value="id"
                                        return-object
                                        label="Кафедри"
                                        multiple
                                        chips
                                ></v-select>
                            </v-flex>

                            <v-flex xs12>
                                <v-select
                                        :items="subjects"
                                        :error-messages="errors.subjects"
                                        v-model="form.subjects"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        label="Дисципліни"
                                        multiple
                                        chips
                                        append-icon="control_point"
                                        @click:append="selectAllSubjects"
                                ></v-select>
                            </v-flex>
                        </template>

                        <v-flex xs12>
                            <v-subheader class="red" dark>
                                Дані про користувача
                                <v-switch
                                        v-if="editMode"
                                        class="ml-12 mt-6"
                                        v-model="form.user.isEditUser"
                                ></v-switch>
                            </v-subheader>
                            <v-divider></v-divider>
                        </v-flex>

                        <template v-if="form.user.isEditUser">
                            <v-flex xs12>
                                <v-switch
                                        label="Може логінитися також через OAuth"
                                        v-model="form.user.isOauth"
                                        :error-messages="errors.user.isOauth"
                                ></v-switch>
                            </v-flex>

                            <v-flex xs12 v-if="form.user.isOauth">
                                <v-text-field :error-messages="errors.user.email" v-model="form.user.email"
                                              label="Email"></v-text-field>
                            </v-flex>

                            <v-flex xs4>
                                <v-text-field :error-messages="errors.user.username" v-model="form.user.username"
                                              label="Логін"></v-text-field>
                            </v-flex>

                            <v-flex xs4>
                                <v-text-field :error-messages="errors.user.password" v-model="form.user.password"
                                              label="Пароль"></v-text-field>
                            </v-flex>

                            <v-flex xs4>
                                <v-text-field :error-messages="errors.user.passwordConfirmation"
                                              v-model="form.user.passwordConfirmation"
                                              label="Підтвердження паролю"></v-text-field>
                            </v-flex>
                        </template>

                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {arrayToQueryString} from "../../../helpers/querystringHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import BirthdayPicker from "../../common/BirthdayPicker";
    import cyrillicToTranslit from "cyrillic-to-translit-js";
    import staffTypes, {staffTypesEnum} from "../../../constants/staffTypes";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            BirthdayPicker,
        },
        data: () => {
            return {
                staffTypesEnum,
                staffTypes: staffTypes.filter(s => s.id !== staffTypesEnum.professor),
                subjects: [],
                departments: [],
                faculties: [],
                form: {
                    surname: null,
                    name: null,
                    patronymic: null,
                    birthday: null,
                    gender: null,
                    staffType: staffTypesEnum.deanOffice,
                    departments: null,
                    subjects: null,
                    salaryRate: null,
                    faculty: null,
                    user: {
                        isEditUser: true,
                        isOauth: false,
                        email: '',
                    }
                },
                errors: {
                    surname: [],
                    name: [],
                    patronymic: [],
                    birthday: [],
                    gender: [],
                    staffType: [],
                    departments: [],
                    subjects: [],
                    salaryRate: [],
                    faculty: [],
                    user: {
                        id: null,
                        isOauth: [],
                        email: [],
                        username: [],
                    }
                },
                salaryRates: [
                    0.10,
                    0.25,
                    0.40,
                    0.50,
                    0.75,
                    1.00,
                    1.25,
                    1.50,
                    1.75,
                    2.00,
                ],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("departments/getAll")
                .then(resp => {
                    this.departments = resp;
                });

            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.faculties = resp;
                });
            
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`staffManagement/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        watch: {
            "form.surname": function (surname) {
                if (surname !== null) {
                    this.createUsername(this.form.surname, this.form.name);
                }
            },
            "form.name": function (name) {
                if (name !== null) {
                    this.createUsername(this.form.surname, this.form.name);
                }
            },
            "form.departments": function (departments) {
                if (departments !== null) {
                    HttpService.getInstance()
                        .get(`subjects/getByDepartments?${arrayToQueryString(departments.map(d => d.id), "departments")}`)
                        .then(subjects => {
                            this.subjects = subjects.map(s => {
                                const subjSpecialities = s.specialities.map(spec => spec.nameShortUk);

                                s.name = `${s.nameUk} (${s.nameShortUk}) (${subjSpecialities.join(", ")})`;

                                return s;
                            });
                        });
                }
            },
            "form.staffType": function (staffType) {
                if (staffType !== null) {
                    switch (staffType) {
                        case staffTypesEnum.deanOffice:
                            this.form.departments = null;
                            this.form.subjects = null;
                            
                            break;
                        case staffTypesEnum.laboratoryStaff:
                            this.form.faculties = null;
                            
                            break;
                        case staffTypesEnum.educationalDepartment:
                            this.form.departments = null;
                            this.form.subjects = null;
                            this.form.faculties = null;
                            
                            break;
                    }
                }
            }
        },
        methods: {
            createUsername(surname, name) {
                const surnameLc = (surname !== null) ? surname.toLowerCase() : '';
                const nameLc = (name !== null) ? name.toLowerCase() : '';
                this.form.user.username = cyrillicToTranslit({ preset: "uk" })
                    .transform(`${surnameLc} ${nameLc}`, ".");
            },
            selectAllSubjects() {
                if (this.form.departments.length !== 0) {
                    this.form.subjects = this.subjects;
                }
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("staffManagement", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>