<template>
    <v-app>
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <toolbar
                :is-lang-switcher="false"
                color="indigo darken-3"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-content>
            <router-view/>
            <v-card v-if="!$route.params.subject" class="ma-2">
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <div class="headline">
                                <v-icon>keyboard_backspace</v-icon>
                                Виберіть дисципліну та модуль
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/common/Toolbar";
    import QuizListNavigationDrawer from "../../components/quizList/common/QuizListNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            navigation: QuizListNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
