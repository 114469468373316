<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :loading="loading"
        :options.sync="pagination"
        class="elevation-3"
        :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">{{ format(props.item.quizStart, "DD.MM.YY HH:mm") }}</td>
                <td class="text-xs-left">{{ format(props.item.quizEnd, "DD.MM.YY HH:mm") }}</td>
                <td class="text-xs-left">{{ props.item.quizResultCount }}</td>
                <td class="text-xs-left">{{ props.item.quizResultCountCorrect }}</td>
                <td class="text-xs-left">{{ props.item.quizResult }}</td>
                <td class="text-xs-left">
                    <v-btn
                        v-if="props.item.quizResult === null"
                        color="primary"
                        dark
                        @click="continueQuiz(props.item.id)"
                    >
                        {{$t("studentKrok.continue")}}
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import format from "date-fns/format";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            translateHeaders(items) {
                return items.map(i => {
                    i.text = this.$t(i.textConst);

                    return i;
                });
            },
            continueQuiz(id) {
                this.$router.push({
                    name: 'studentQuizTest',
                    params: {quizId: id, questionNumber: 1}
                });
            }
        },
        mounted() {
            this.headers = this.translateHeaders(this.headers);
        },
        data: () => ({
            format,
            pagination: {
                totalItems: 0,
            },
            headers: [
                {textConst: 'studentKrok.start', value: 'quizStart'},
                {textConst: 'studentKrok.finish', value: 'quizEnd'},
                {textConst: 'studentKrok.questionCount', value: 'quizResultCount'},
                {textConst: 'studentKrok.questionCorrectCount', value: 'quizResultCountCorrect'},
                {textConst: 'studentKrok.questionCorrectPercentage', value: 'quizResult'},
                {textConst: 'studentKrok.actions', value: '', sortable: false},
            ],
        }),
    }
</script>