<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12 md12>
                            <v-autocomplete
                                    :items="professors"
                                    v-model="form.professor"
                                    item-text="name"
                                    item-value="id"
                                    solo
                                    return-object
                                    label="Викладач-екзаменатор"
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import DatePicker from "../../../common/DatePicker";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            DatePicker,
        },
        data: () => {
            return {
                form: {
                    professor: null,
                },
                errors: {
                },
                loading: false,
                loadingSave: false,

                professors: [],
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("professors/getAll")
                .then(items => {
                    this.professors = items.map(i => {
                        i.name = `${i.surname} ${i.name} ${i.patronymic}`;
                        
                        return i;
                    });
                });
            
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post(`FinalExamAdminExaminers/saveFinalExamExaminers/${this.$route.params.examId}`, this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>