<template>
    <v-card>
        <v-card-title class="title">Видача допусків</v-card-title>
        <v-card-text v-if="admission !== null">
            <div>Ви впевнені, що хочете пролонгувати даний допуск?</div>
            <v-select
                    class="mt-4"
                    solo
                    light
                    :items="durationItems"
                    v-model="admission.topicAbsence.prolongationDays"
                    item-text="name"
                    item-value="duration"
                    label="Тривалість пролонгації"
            ></v-select>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseSaveConfirm')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="saveAdmissions"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
                durationItems: [
                    {
                        duration: 7,
                        name: "7 днів"
                    },
                    {
                        duration: 14,
                        name: "14 днів"
                    },
                    {
                        duration: 21,
                        name: "21 дня"
                    }
                ]
            };
        },
        props: {
            admission: {
                type: Object,
                default: () => {
                    return {
                        topicAbsence: {
                            prolongationDays: 7,
                        }
                    }
                }
            },
        },
        mounted() {
            this.admission.topicAbsence.prolongationDays = 7;
        },
        methods: {
            saveAdmissions() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`dean/prolongateAdmission`, this.admission.topicAbsence)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    });
            }
        }
    }
</script>