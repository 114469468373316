<template>
    <v-card>
        <v-card-title primary-title>
            <div>
                <h3 class="headline mb-0">Налаштування кафедри та дисциплін</h3>
            </div>
        </v-card-title>
        <v-card-text>
            <form @submit="submitForm" novalidate="true">
                <v-flex xs12>
                    <v-layout wrap>
                        <v-flex xs12>
                            <div class="title font-weight-thin">Кафедра по замовчуванню</div>
                            <div class="caption">
                                Ви можете обрати кафедру по замовчуванню, у випадку коли <strong>Ви працюєте на декількох кафедрах</strong> або ж у випадку
                                коли Ви бажаєте обрати дисципліну по замовчуванню.
                            </div>
                            <v-select
                                    :items="departments"
                                    v-model="form.defaultDepartment"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Кафедра"
                                    filled
                                    :loading="loading"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <div class="title font-weight-thin">Дисципліна по замовчуванню</div>
                            <div class="caption">
                                Ви можете обрати дисципліну по замовчуванню, яка буде обиратися автоматично <strong>при користуванні панеллю викладача</strong>.
                                При користуванні <strong>іншими панелями дане налаштування буде ігноруватися</strong>.
                            </div>
                            <v-select
                                    :disabled="subjects.length === 0"
                                    :items="subjects"
                                    v-model="form.defaultSubject"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Дисципліна"
                                    filled
                                    :loading="loading"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <div class="title font-weight-thin">Улюблені дисципліни</div>
                            <div class="caption">
                                Ви можете обрати так звані "улюблені дисципліни" – це дисципліни з якими Ви найчастіше працюєте. 
                                При користуванні цим налаштуванням у випадаючому списку дисциплін будуть показуватися лише обрані дисципліни.
                                При користуванні <strong>іншими панелями дане налаштування буде ігноруватися</strong>.
                            </div>
                            <div class="text-left">
                                <v-treeview
                                        open-all
                                        selectable
                                        return-object
                                        v-model="form.favoriteSubjects"
                                        :items="departmentWithSubjects"
                                ></v-treeview>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12>
                    
                </v-flex>
                <v-card-actions>
                    <v-btn
                            text
                            color="red"
                            type="button"
                            :loading="loading"
                            :disabled="loading"
                            @click="resetProfessorSettings()"
                    >Скинути налаштування
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                    >Зберегти
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card-text>
    </v-card>
</template>
<script>
    import {remapErrors} from "../../../helpers/errorsHelper";
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            loading: false,
            
            departments: [],
            subjects: [],

            departmentWithSubjects: [],

            form: {
                defaultDepartment: null,
                defaultSubject: null,
                favoriteSubjects: []
            },
            errors: {
                defaultDepartment: null,
                defaultSubject: null,
                favoriteSubjects: null,
            }
        }),
        mounted() {
            this.loading = true;

            this.$ga.event('professor', 'settings', 'open');

            HttpService.getInstance()
                .get("teacher/navigation/department")
                .then(departments => {
                    this.departments = departments;
                });

            HttpService.getInstance()
                .get("teacher/navigation/departmentWithSubjects")
                .then(departmentWithSubjects => {
                    this.departmentWithSubjects = this.remapFavoriteSubjects(departmentWithSubjects);
                })
                .then(() => {
                    return HttpService.getInstance()
                        .get("settings/professorSettings");
                })
                .then(resp => {
                    this.loading = false;

                    this.form = resp;
                });
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.$ga.event('professor', 'settings', 'save');

                this.loading = true;

                HttpService.getInstance()
                    .put("settings/professorSettings", this.form)
                    .then(resp => {
                        this.loading = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loading = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
            resetProfessorSettings() {
                HttpService.getInstance()
                    .delete("settings/resetProfessorSettings")
                    .then(resp => {
                        this.form = resp;
                        this.loading = false;
                    });
            },
            remapFavoriteSubjects(departmentWithSubjects) {
                return departmentWithSubjects.map(d => {
                    return {
                        id: d.id,
                        name: d.nameUk,
                        children: d.subjects.map(s => {
                            const specs = s.specialities.map(s => s.nameShortUk);

                            return {
                                id: s.id,
                                name: `${s.nameUk} (${specs.join(", ")})`,
                            };
                        })
                    };
                });
            }
        },
        watch: {
            "form.defaultDepartment": function (department) {
                if (department) {
                    this.loading = true;
                    
                    HttpService.getInstance()
                        .get(`teacher/navigation/department/${department.id}/subject`)
                        .then(subjects => {
                            if (subjects) {
                                this.subjects = subjects.map(s => {
                                    const subjSpecialities = s.specialities.map(spec => spec.nameShortUk);

                                    s.name = `${s.nameUk} (${s.nameShortUk}) (${subjSpecialities.join(", ")})`;
                                    return s;
                                });
                            }

                            this.loading = false;
                        });   
                }
            }
        }
    }
</script>