<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>

        <navigation v-if="$route.name !== 'eAristoProfessorSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>

        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/eAristoProfessor/common/Toolbar";
    import eAristoProfesorNavigationDrawer from "../../components/eAristoProfessor/common/eAristoProfesorNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            navigation: eAristoProfesorNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
    }
</script>
