<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Викладачі</v-subheader>
                            <navigation-list-template :loading="isProfessorsLoading" :items="professors"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex v-if="!isSelectedProfessor" xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть викладача
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <group-distribution-list :key="$route.fullPath" v-if="isSelectedProfessor"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import GroupDistributionList from "../../components/moderator/groupDistribution/GroupDistributionList";
    import { nameInitials } from "../../helpers/stringHelper";

    export default {
        components: {
            NavigationListTemplate,
            GroupDistributionList,
        },
        data: () => ({
            isBlank: true,
            professors: [],
            isSelectedProfessor: false,
            isProfessorsLoading: true,
        }),
        mounted() {
            this.isSelectedProfessor = this.$route.params.professor !== undefined;

            if (this.$route.params.department !== undefined && this.$route.params.subject !== undefined) {
                this.getProfessors();
            }
            
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            getProfessors() {
                this.isProfessorsLoading = true;
                
                HttpService.getInstance()
                    .get(`teacher/navigation/departments/${this.$route.params.department}/professors`)
                    .then(res => {
                        this.professors = res.map(professor => {
                            professor.name = `${professor.academicPosition.nameShortUk} ${professor.surname} ${nameInitials(professor.name)} ${nameInitials(professor.patronymic)}`;
                            professor.route = {
                                name: 'groupDistribution',
                                params: {
                                    department: this.$route.params.department,
                                    subject: this.$route.params.subject,
                                    professor: professor.id,
                                }
                            };

                            return professor;
                        });
                        this.isProfessorsLoading = false;
                    });
            },
            checkIfIsBlank(route) {
                if (route.params.department !== undefined && route.params.subject !== undefined) {
                    this.isBlank = false;
                }
                else {
                    this.isBlank = true;
                }
            }
        },
        watch: {
            $route: function(route, prevRoute) {
                if (route.params.department !== undefined && 
                    route.params.subject !== undefined && 
                    prevRoute.params.subject !== route.params.subject) {
                    this.getProfessors();
                }
                if (route.params.professor !== prevRoute.params.professor) {
                    this.isSelectedProfessor = this.$route.params.professor !== undefined;
                }
                
                this.checkIfIsBlank(route);
            },
        }
    }
</script>