<template>
    <v-card>
        <v-card-title class="title">Перегляд фото</v-card-title>
        <v-card-text>
            <img width="600" alt="" :src="photoUrlFull"/>
        </v-card-text>
    </v-card>
</template>
<script>
    import {AppConfig} from "../../../../config/configFactory";
    import config from "../../../../app.config";
    
    let configuration = AppConfig(config);
    
    export default {
        data: () => {
            return {
            };
        },
        computed: {
            photoUrlFull: {
                get() { return `${configuration.apiEndpoint}/file/getApplicantPhoto/${this.photoUrl}`},
                set(val) { }
            }
        },
        props: {
            photoUrl: String,
        },
    }
</script>