<template>
    <v-container grid-list-md style="max-width: 100%">
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5 mb-2">Статистика по викладачам</h2>
        <v-data-table
            fixed-header
            height="75vh"
            :headers="headers"
            :items="data"
            :loading="loading"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.professor="{item}">
                {{item.surname}} {{item.name}}
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import LineChart from '@/components/common/statistics/LineChart';
    import StatisticsHeader from "@/components/common/statistics/StatisticsHeader";
    import HttpService from "@/HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                loading: false,
                dates: [],
                
                data: [],
                headers: [
                    {text: 'Викладач', value: 'professor'},
                    {text: 'ф5', value: 'markInitial5'},
                    {text: 'ф4', value: 'markInitial4'},
                    {text: 'ф3', value: 'markInitial3'},
                    {text: 'ф2', value: 'markInitial2'},
                    {text: 'фНб', value: 'markInitialAbsence'},
                    {text: 'ф~', value: 'markInitialAverage'},
                    
                    {text: 'нб-5', value: 'markReworkNb5'},
                    {text: 'нб-4', value: 'markReworkNb4'},
                    {text: 'нб-3', value: 'markReworkNb3'},
                    {text: 'нб-∑', value: 'markReworkNbSum'},

                    {text: '2-5', value: 'markRework2_5'},
                    {text: '2-4', value: 'markRework2_4'},
                    {text: '2-3', value: 'markRework2_3'},
                    {text: '2-∑', value: 'markRework2_Sum'},

                    {text: 'р5', value: 'markResult5'},
                    {text: 'р4', value: 'markResult4'},
                    {text: 'р3', value: 'markResult3'},
                    {text: 'р2', value: 'markResult2'},
                    {text: 'рНб', value: 'markResultAbsence'},
                    {text: 'р~', value: 'markResultAverage'},
                ],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loading = true;
                HttpService.getInstance()
                    .get(`statisticsMarks/getByProfessorMarks?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loading = false;
                    })
                    .catch(e => {})
            },
        }
    }
</script>