<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex>
                <rating-check-department-rating-directions-index :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>

    import RatingCheckDepartmentRatingDirectionsIndex
        from "../../components/ratingAdmin/ratingCheck/RatingCheckDepartmentRatingDirectionsIndex";

    export default {
        components: {
            RatingCheckDepartmentRatingDirectionsIndex,
        },
        data: () => ({}),
    }
</script>