<template>
    <div class="kti-table-wrapper">
        <table class="table table-kti">
            <thead>
                <tr>
                    <th rowspan="2">№ п/п</th>
                    <th rowspan="2">ПІП</th>
                    <th rowspan="2">Група</th>
                    <th rowspan="2">Спец-ть</th>
                    <th rowspan="2">Дата</th>
                    <th rowspan="2">Оцінка</th>
                    <th rowspan="2">Тест (%)</th>
                    <th rowspan="2">Попередні результати</th>
                    <th :colspan="calculateColSpan()">Субтести (%)</th>
                </tr>
                <tr>
                    <th class="rotate" v-for="profileResult in getProfileResults()">
                        <div><span> {{profileResult.name}} ({{profileResult.percentage}}%) </span></div>
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="(student, index) in results">
                <td>{{index + 1}}.</td>
                <td>{{student.surname}} {{student.name}}</td>
                <td>{{student.groupNumber}}</td>
                <td>{{student.specialityName}}</td>
                <td>{{student.stepQuestionResult != null ? formatDate(student.stepQuestionResult.dateEnd) : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.traditionalMark : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.percentage : '-'}}</td>
                <td>
                    <span v-if="student.stepQuestionFinishes.length === 0">-</span>
                    <table class="table">
                        <tbody>
                        <tr v-for="result in student.stepQuestionFinishes">
                            <td>{{formatDate(result.dateEnd)}}</td>
                            <td>{{result.percentage.toFixed(2)}}%</td>
                            <td>{{result.traditionalMark}}</td>
                        </tr>
                        </tbody>
                    </table>
                </td>
                <td v-for="profileResult in student.profileResults">
                    {{profileResult.percentageResult != null ? profileResult.percentageResult.toFixed(2) : '-'}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import format from "date-fns/format";

    export default {
        props: {
            results: {
                type: Array,
                default: () => [],
            }
        },
        methods: {
            getProfileResults() {
              if (this.results.length === 0) {
                  return [];
              }  
              
              return this.results[0].profileResults;
            },
            calculateColSpan() {
                if (this.results.length === 0) {
                    return 0;
                }

                return this.results[0].profileResults.length;
            },
            formatDate(date) {
                return format(date, "DD.MM.YY");
            },
        }
    }
</script>
<style lang="scss">
    .kti-table-wrapper {
        width: 100%; 
        overflow-x: auto;
    }
    
    th.rotate {
        height: 220px;
        font-weight: normal;
        border: 1px solid #ccc;
        vertical-align: middle !important;
        padding: 0.05rem !important;

    }
    
    .rotate {
        filter:  progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
        -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
        -moz-transform: rotate(-90.0deg); 
        -ms-transform: rotate(-90.0deg);
        -o-transform: rotate(-90.0deg); 
        -webkit-transform: rotate(-90.0deg);
        transform: rotate(-90.0deg);
    }
</style>