<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <moderators-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import ModeratorsIndex from "../../../../components/admin/PedagicalStaff/Moderators/ModeratorsIndex";
    export default  {
        components: {
            ModeratorsIndex,
        }
    }
</script>