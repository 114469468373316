<template>
    <div>
        <vue-plyr
            ref="plyr"
            :options="options"
        >
            <video
                disablePictureInPicture
                playsinline
            >
                <source
                    size="720"
                    :src="getVideoLink(this.videoContent.fileName)"
                    type="video/mp4"
                />
            </video>
        </vue-plyr>
        <v-btn class="mt-1" v-if="!isPlay && showPlayPause" @click="setPlayPlayer()"><v-icon>play_circle</v-icon>  {{$t('eAristoStudent.playVideo')}}</v-btn>
        <v-btn class="mt-1" v-if="isPlay && showPlayPause" @click="setPausePlayer()"><v-icon>pause_circle</v-icon>  {{$t('eAristoStudent.pauseVideo')}}</v-btn>
    </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";

const configuration = AppConfig(config);

export default {
    name: 'VideoPlayerLimited',
    props: {
        videoContent: {
            type: Object,
            default: () => {
                return null;
            }
        },
        showPlayPause: {
            type: Boolean,
            default: () => {
                return true;
            }
        }
    },
    data() {
        return {
            options: {
                controls: ['current-time', 'duration', 'volume', 'settings'],
                settings: ['quality', 'speed'],
                speed: {selected: 1, options: [0.75, 1, 1.25]},
                keyboard: { focused: false, global: false },
                clickToPlay: false,
                invertTime: false,
            },
            
            isPlay: false,
            
            playerInstance: null,
        }
    },
    mounted() {
        this.playerInstance = this.$refs.plyr.player;
        
        this.playerInstance.on('timeupdate', () => {
            const currentTime = this.$refs.plyr.player.currentTime;
            this.$emit("currentTime", currentTime);
        })
    },
    methods: {
        getVideoLink(videoFile) {
            return `${configuration.apiEndpoint}/file/get/video/${videoFile}`;
        },
        setVideoTime(time) {
            this.playerInstance.currentTime = time;
        },
        setPausePlayer() {
            this.isPlay = false;
            this.playerInstance.pause();
        },
        setPlayPlayer() {
            this.isPlay = true;
            this.playerInstance.play();
        },
    },
}
</script>