<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5 mb-2">Статистика по курсу</h2>
        <v-data-table
            fixed-header
            height="80vh"
            :headers="headers"
            :items="data"
            :loading="loading"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.student.surname="{item}">
                {{item.student.surname}} {{item.student.name}} {{item.student.patronymic}}
            </template>
            <template v-slot:item.avgMark="{item}">
                {{item.avgMark.toFixed(2)}}
            </template>
            <template v-slot:item.count5Percentage="{item}">
                {{item.count5Percentage.toFixed(2)}}% ({{item.count5}})
            </template>
            <template v-slot:item.count4Percentage="{item}">
                {{item.count4Percentage.toFixed(2)}}% ({{item.count4}})
            </template>
            <template v-slot:item.count3Percentage="{item}">
                {{item.count3Percentage.toFixed(2)}}% ({{item.count3}})
            </template>
            <template v-slot:item.count2Percentage="{item}">
                {{item.count2Percentage.toFixed(2)}}% ({{item.count2}})
            </template>
            <template v-slot:item.countAbsPercentage="{item}">
                {{item.countAbsPercentage.toFixed(2)}}% ({{item.countAbs}})
            </template>
            <template v-slot:item.countReworksPercentage="{item}">
                {{item.countReworksPercentage.toFixed(2)}}% ({{item.countReworks}})
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import LineChart from '@/components/common/statistics/LineChart';
    import StatisticsHeader from "@/components/common/statistics/StatisticsHeader";
    import HttpService from "@/HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                loading: false,
                dates: [],
                
                data: [],
                headers: [
                    {text: 'п/п', value: 'index'},
                    {text: 'ПІП', value: 'student.surname'},
                    {text: 'μ бал', value: 'avgMark'},
                    {text: '∑ оцінок', value: 'countMarks'},
                    {text: '∑ 5', value: 'count5Percentage'},
                    {text: '∑ 4', value: 'count4Percentage'},
                    {text: '∑ 3', value: 'count3Percentage'},
                    {text: '∑ 2', value: 'count2Percentage'},
                    {text: '∑ Нб', value: 'countAbsPercentage'},
                    {text: '∑ відпр.', value: 'countReworksPercentage'},
                ],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loading = true;
                HttpService.getInstance()
                    .get(`statisticsMarks/getStudents/${this.$route.params.faculty}/${this.$route.params.speciality}/${this.$route.params.semester}?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loading = false;
                    })
                    .catch(e => {})
            },
        }
    }
</script>