<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <teacher-toolbar
                :is-lang-switcher="false"
                color="blue darken-4"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        
        <navigation v-if="$route.name !== 'teacherSuperAdminPreselect'" @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import TeacherToolbar from "../../components/teacher/common/TeacherToolbar";
    import TeacherNavigationDrawer from "../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            TeacherToolbar,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
