<template>
    <div>
        <admin-exam-settings-dates-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </admin-exam-settings-dates-grid>

        <v-dialog v-model="dialog" max-width="900px" scrollable>
            <add-edit-admin-exam-settings-dates
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-admin-exam-settings-dates>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import AdminExamSettingsDatesGrid from "./AdminExamSettingsDatesGrid";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditAdminExamSettingsDates from "./AddEditAdminExamSettingsDates";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            AdminExamSettingsDatesGrid,
            AddEditAdminExamSettingsDates,
            DeleteConfirm
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`finalExamAdmin/getFinalExamAcademicGroupDate`)
                    .then(faculties => {
                        this.items = faculties.items;
                        this.total = faculties.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>