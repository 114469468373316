<template>
    <div style="width: 100%;">
        <v-layout wrap>
            <v-flex xs12>
                <v-layout wrap>
                    <v-flex xs3 class="align-center">
                        <date-picker
                                v-model="filter.dateFrom"
                                label="Дата заняття (від)"
                        />
                    </v-flex>
                    <v-flex xs3>
                        <date-picker
                                v-model="filter.dateTo"
                                label="Дата заняття (до)"
                        />
                    </v-flex>
                    <v-flex xs3 class="px-1">
                        <v-text-field 
                                clearable
                                v-model="filter.studentSurnameName" 
                                label="Прізвище або ім'я студента"></v-text-field>
                    </v-flex>
                    <v-flex xs2 class="justify-end">
                        <div class="d-flex align-center align-content-center">
                            <v-btn :loading="loading" color="primary" @click="getItemsWrapper()">Застосувати</v-btn>
                        </div>
                    </v-flex>
                </v-layout>
                <faculty-speciality-semester-group-selector 
                        :navigation-end-point="getNavigationEndPoint()"
                        @selectSemester="onSelectSemester($event)"
                        @selectAcademicGroup="onSelectAcademicGroup($event)"
                />
            </v-flex>
        </v-layout>

        <admission-types-legend/>

        <v-flex xs12>
            <admissions-journal-grid
                    :items="items"
                    :total="total"
                    :loading="loading"
                    @paginate="onPaginate"
            >
            </admissions-journal-grid>
        </v-flex>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AdmissionsJournalGrid from "./AdmissionsJournalGrid";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import DatePicker from "../../common/DatePicker";
    import moment from "moment";
    import FacultySpecialitySemesterGroupSelector from "../../common/FacultySpecialitySemesterGroupSelector";

    export default {
        components: {
            AdmissionsJournalGrid,
            AdmissionTypesLegend,
            DatePicker,
            FacultySpecialitySemesterGroupSelector
        },
        mounted() {
            this.getItemsWrapper();
        },
        methods: {
            getNavigationEndPoint() {
                return `navigation/getNavForDean`;
            },
            getItemsWrapper() {
              this.getItems(objectQueryStringify(this.pagination));  
            },
            getItems(queryParams) {
                this.loading = true;

                const dateFromQuery = moment(this.filter.dateFrom).format("YYYY-MM-DD");
                const dateToQuery = moment(this.filter.dateTo).format("YYYY-MM-DD");

                HttpService.getInstance()
                    .get(`reworks/getAdmissionsJournal/?${queryParams}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&semesterId=${this.filter.semesterId}&academicGroupId=${this.filter.academicGroupId}&studentSurnameName=${this.filter.studentSurnameName}`)
                    .then(admissions => {
                        this.items = admissions.items;
                        this.total = admissions.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            onSelectSemester(semester) {
                    this.filter.semesterId = semester ? semester.id : null;

                    this.$nextTick(() => {
                        this.getItems(objectQueryStringify(this.pagination));
                    });
                
            },
           onSelectAcademicGroup(academicGroup) {
                    this.filter.academicGroupId = academicGroup ? academicGroup.id : null;

                    this.$nextTick(() => {
                        this.getItems(objectQueryStringify(this.pagination));
                    });
                
           }
        },
        data: () => {
            return {
                filter: {
                    dateFrom: moment(new Date()).subtract(6, "years"),
                    dateTo: new Date(),
                    
                    semesterId: null,
                    academicGroupId: null,
                    studentSurnameName: "",
                },
                
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>