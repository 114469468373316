<template>
    <v-navigation-drawer
            fixed
            :clipped="$vuetify.breakpoint.mdAndUp"
            app
            :value="drawer"
            @input="$emit('toggleDrawer', $event)"
            :width="300"
    >
            <v-list dense class="mt-4" expand>
                <navigation-list-template :items="feedbackMenuItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
    </v-navigation-drawer>
</template>
<script>
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
            this.feedbackMenuItems = this.remapItems(this.feedbackMenuItemsRaw);
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                let itemsRemapped = items.slice(0);
                
                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        
                        item.children = item.children.map(ch => {
                            if (this.$route.params.subject && ch.id.toString() === this.$route.params.subject.toString()) {
                                
                                this.setTopNavigationItem(ch);
                            }
                            
                            return ch;
                        });
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            }
        },
        data: () => ({
            feedbackMenuItems: [],
            feedbackMenuItemsRaw: [
                {icon: 'assignment', name: 'Оголошення', route: {name: "announcementsFeedback"}},
            ]
        })
    }
</script>