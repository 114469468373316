<template>
    <v-alert
            v-if="userIsAdminOrStudent"
            outlined
            type="warning"
            prominent
            border="left"
    >
        <h3 class="text-center">{{$t("dashboard.supportTitle")}}</h3>
        <div>{{$t("dashboard.accountQuestions")}} – <a href="mailto:ez@pdmu.edu.ua">ez@pdmu.edu.ua</a></div>
        <v-divider class="mt-2 mb-2"></v-divider>
        <div class="body-2">{{$t("dashboard.postNote")}}</div>
    </v-alert>
</template>
<script>
    import {rolesEnum} from "../../constants/rolesEnum";

    export default {
        methods: {
            userIsAdminOrStudent() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.student]);
            },
        }
    }
</script>