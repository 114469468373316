<template>
    <v-flex xs12>
        <v-layout wrap justify-space-between>
            <v-flex class="align-center">
                <date-picker
                        v-model="dateFromComputed"
                        type="month"
                        :max-date="new Date()"
                        label="Місяць від"
                />
            </v-flex>
            <v-flex>
                <date-picker
                        v-model="dateToComputed"
                        type="month"
                        :max-date="new Date()"
                        label="Місяць до"
                />
            </v-flex>
            <v-flex class="justify-end">
                <div class="d-flex align-center align-content-center">
                    <v-btn :loading="loading" color="primary" @click="onFilter()">Застосувати</v-btn>
                </div>
            </v-flex>
        </v-layout>
    </v-flex>
</template>
<script>
    import DatePicker from "../DatePicker";
    import moment from "moment";

    export default {
        components: {
            DatePicker,
        },
        data: () => ({
            dateFromData: null,
            dateToData: null,
        }),
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            dateFrom: {
                type: Date,
                default: moment(new Date()).subtract(6, "months").startOf("month").toDate(),
            },
            dateTo: {
                type: Date,
                default: moment(new Date()).endOf("month").toDate(),
            }
        },
        mounted() {
            this.dateFromData = this.dateFrom;
            this.dateToData = this.dateTo;
        },
        computed: {
            dateFromComputed: {
                get() { return this.dateFrom},
                set(val) {
                    this.dateFromData = val;
                    this.$emit('filter', {dateFrom: this.dateFromData, dateTo: this.dateToData});
                }
            },
            dateToComputed: {
                get() { return this.dateTo},
                set(val) {
                    this.dateToData = val;
                    this.$emit('filter', {dateFrom: this.dateFromData, dateTo: this.dateToData});
                }
            }
        },
        methods: {
            onFilter() {
                this.$emit('filter', {dateFrom: this.dateFromData, dateTo: this.dateToData});
            }
        }
    }
</script>