<template>
    <div>
        <div v-if="!isMobile()" class="title">{{getTopNavigationTitle}}</div>
        <div v-if="quizQuestions.length > 0" class="subheading mb-2 heading-wrapper">Всього питань: {{quizQuestions.length}}</div>
        
        <v-card class="mb-2 question-wrapper" onmousedown='return false;' onselectstart='return false;' :key="index" v-for="(question, index) in quizQuestions">
            <v-card-title class="subheading">{{index + 1}}. <span v-html="question.questionText"></span></v-card-title>
            <v-card-text v-if="question.image">
                <img width="350" :src="questionImageUrl(question.image, 500)"/>
            </v-card-text>
            <v-card-text>
                <div v-for="questionsAnswer in question.questionAnswers">
                    <div class="answer" v-if="questionsAnswer.isCorrect">
                        <v-icon>lens</v-icon>
                        <div class="sub-answer">
                            <strong> <span
                                    class="answer-text">{{questionsAnswer.answerText}}</span></strong>
                            <div v-if="questionsAnswer.image">
                                <img width="350" :src="questionImageUrl(questionsAnswer.image)"/>
                            </div>
                        </div>
                    </div>
                    <div class="answer" v-if="!questionsAnswer.isCorrect">
                        <v-icon>panorama_fish_eye</v-icon>
                        <div class="sub-answer">
                            <span class="answer-text">{{questionsAnswer.answerText}}</span>
                            <div v-if="questionsAnswer.image">
                                <img width="350" :src="questionImageUrl(questionsAnswer.image)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-card class="mb-2" v-if="quizQuestions.length === 0 && loading === false">
            Немає питань до теми
        </v-card>
        <v-container v-if="loading" fluid fill-height>
            <v-layout align-center justify-center>
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import HttpService from "../../HttpService";
    import {mapGetters} from "vuex";

    import {AppConfig} from "@/config/configFactory";
    import config from "@/app.config";

    let configuration = AppConfig(config);
    
    export default {
        data: () => ({
            quizQuestions: [],
            loading: false,
        }),
        mounted() {
            this.loadData();
        },
        computed: {
            ...mapGetters(['getTopNavigationTitle']),
        },
        methods: {
            isMobile() {
                return this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm";
            },
            questionImageUrl(image) {
                return `${configuration.apiEndpoint}/file/get/reworkQuizImage/${image}`;
            },
            loadData() {
                this.loading = true;
                const subjectId = this.$route.params.subject;
                const moduleId = this.$route.params.module;
                const topicId = this.$route.params.topic;

                HttpService.getInstance()
                    .get(`quizList/getAll/${subjectId}/${moduleId}/${topicId}`)
                    .then(resp => {
                        this.quizQuestions = resp;

                        this.loading = false;
                    })
            },
        }
    }
</script>
<style lang="scss">
    .answer {
        display: flex;
        margin-bottom: 8px;
    }
    .sub-answer {
        display: flex;
        flex-direction: column;
    }
    .question-wrapper,
    .heading-wrapper {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
</style>