<template>
    <div>
        <v-tabs fixed-tabs v-model="activeTopicType">
            <v-tab>Теми практичних занять</v-tab>
            <v-tab>Теми самостійних занять</v-tab>
        </v-tabs>
        <topics-index v-if="activeTopicType === 0"></topics-index>
        <topics-independent-index v-if="activeTopicType === 1"></topics-independent-index>
    </div>
</template>

<script>
import TopicsIndex from "@/components/moderator/topics/TopicsIndex.vue";
import TopicsIndependentIndex from "@/components/moderator/topicsIndependent/TopicsIndependentIndex.vue";

export default {
    name: "TopicsWrapper",
    components: {
        TopicsIndex,
        TopicsIndependentIndex
    },
    data: () => {
        return {
            activeTopicType: 0,
        }
    }
}
</script>

<style scoped>

</style>