<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.nameUk="{item}">{{ item.faculty.nameUk }}</template>
        <template v-slot:item.deans="{item}">
            <v-chip v-for="dean in item.deans" :key="dean.id">
                {{ dean.surname }} {{ dean.name }} {{ dean.patronymic }}
            </v-chip>
            <div v-if="item.deans.length === 0">-</div>
        </template>
        <template v-slot:item.inspectors="{item}">
            <v-chip v-for="inspector in item.inspectors" :key="inspector.id">
                {{ inspector.surname }} {{ inspector.name }} {{ inspector.patronymic }}
            </v-chip>
            <div v-if="item.inspectors.length === 0">-</div>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn icon class="mx-0" @click="$emit('editItem', item.faculty.id)">
                <v-icon color="primary">edit</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            mapSpecialitiesToString(specialities) {
                return specialities.map(spec => spec.nameUk).join(", ");
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва факультету', value: 'nameUk', sortable: false},
                {text: 'Декани', value: 'deans', sortable: false},
                {text: 'Інспектори', value: 'inspectors', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>