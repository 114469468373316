<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout v-if="!$vuetify.breakpoint.mobile" row wrap style="width: 100%;">
            <v-flex v-if="showModuleBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'eAristoStudentSubjectModuleList', 
                            params: { subjectId: $route.params.subjectId }}" 
                           color="primary"
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToModuleList")}}</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">{{$t("eAristoStudent.modules")}}</v-subheader>
                            <navigation-list-template :items="modules"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.moduleId" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("eAristoStudent.selectModule")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <topic-list :key="$route.fullPath" v-if="$route.params.moduleId"/>
            </v-flex>
        </v-layout>


        <v-layout v-if="$vuetify.breakpoint.mobile" row wrap style="width: 100%;">
            <v-flex v-if="showModuleBlock()" style="width: 100%;">
                <v-btn :to="{name: 'eAristoStudentSubjectModuleList', 
                            params: { subjectId: $route.params.subjectId }}"
                       color="primary"
                       class="px-3"
                ><v-icon>keyboard_backspace</v-icon> {{$t("eAristoStudent.goToModuleList")}}</v-btn>
                <v-card>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="'100%'">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">{{$t("eAristoStudent.modules")}}</v-subheader>
                            <navigation-list-template :items="modules"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="width: calc(100% - 10px);">
                <v-card v-if="!$route.params.moduleId" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("eAristoStudent.selectModule")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <topic-list :key="$route.fullPath" v-if="$route.params.moduleId"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import TopicList from "../../components/eAristoStudent/topics/TopicList";
    import HttpService from "../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            TopicList
        },
        data: () => ({
            modules: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`eAristoStudent/getModules/${this.$route.params.subjectId}`)
                .then(resp => {
                    this.modules = resp.map(module => {
                        module.name = `${module.orderBy}. ${module.nameUk}`;
                        module.route = {
                            name: 'eAristoStudentSubjectModuleTopics',
                            params: {
                                subjectId: this.$route.params.subjectId,
                                moduleId: module.id
                            }
                        };

                        return module;
                    });
                });
        },
        methods: {
            showModuleBlock() {
                return this.$route.name === "eAristoStudentSubjectModuleTopics" && this.$route.params.subjectId;
            },

        },
    }
</script>