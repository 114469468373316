<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout v-if="!isBlank" row wrap>
            <v-flex xs12>
                <div class="headline">Виберіть групу</div>
                <navigation-stud-nav-list :key="$route.fullPath"
                                          :route-to="routeTo"
                                          :api-endpoint="getNavigationApiEndpoint"
                                          :additional-route-params="{subject: $route.params.subject, department: $route.params.department}"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationStudNavList from "../../../components/common/NavigationStudNavList";

    export default {
        components: {
            NavigationStudNavList,
        },
        props: {
            routeTo: {
                type: String,
                defaultsTo: "registers"
            },
        },
        data: () => ({
            isBlank: true,
        }),
        mounted() {
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            checkIfIsBlank(route) {
                if (route.params.department !== undefined && route.params.subject !== undefined) {
                    this.isBlank = false;
                }
                else {
                    this.isBlank = true;
                }
            }
        },
        computed: {
          getNavigationApiEndpoint() {
              return `navigation/getNavRegistersForDepartmentSubject/${this.$route.params.department}/${this.$route.params.subject}`;
          }  
        },
        watch: {
            $route(route) {
                this.checkIfIsBlank(route);
            }
        },
    }
</script>