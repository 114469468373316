<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <polls-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import PollsIndex from "../../../components/admin/MainDashboard/Polls/PollsIndex";
    export default  {
        components: {
            PollsIndex,
        }
    }
</script>