<template>
    <v-layout wrap>
        <template v-if="isReworkAvailable">
        <v-flex xs12>
            <search-header-name-academic-group :loading="loading" @search="getStudents($event)"/>
        </v-flex>
        <v-flex xs12>
            <admissions-student-list
                    :students="students"
                    :loading="loading"
                    :mode="reworkModeEnum.teacherReworks"
            />
        </v-flex>
        </template>
        <template v-if="!loading && !isReworkAvailable">
            <v-alert
                    prominent
                    type="error"
                    class="mt-5"
            >
                <v-row align="center">
                    <v-col class="grow">
                        Панель відробітків з вибраної дисципліни не доступна згідно графіку відробітків. Для складання графіку відробітків зверніться, будь ласка, до модератора кафедри.
                    </v-col>
                </v-row>
            </v-alert>
        </template>
    </v-layout>
</template>

<script>
    import HttpService from "../../../HttpService";
    import AdmissionsStudentList from "../../common/rework-admissions/AdmissionsStudentList";
    import {reworkModeEnum} from "../../../constants/_internal/reworkModeEnum";
    import SearchHeaderNameAcademicGroup from "../../common/SearchHeaderNameAcademicGroup";

    export default {
        components: {
            AdmissionsStudentList,
            SearchHeaderNameAcademicGroup,
        },
        data: () => ({
            students: [],
            loading: false,
            reworkModeEnum,
            searchString: "",
            
            isReworkAvailable: false,
        }),
        mounted() {
            this.checkIsReworkAvailable();
        },
        methods: {
            checkIsReworkAvailable() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherReworks/isReworkAvailable/${this.$route.params.department}/${this.$route.params.subject}`)
                    .then(resp => {
                        if (resp.isReworksAvailable) {
                            this.isReworkAvailable = resp.isReworksAvailable;
                            
                            this.getStudents({searchString: "", academicGroup: null});
                        }
                        this.loading = false;
                    })  
            },
            getStudents({searchString, academicGroup}) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherReworks/${this.$route.params.department}/${this.$route.params.subject}?searchString=${searchString}&academicGroupId=${academicGroup ? academicGroup.id : ''}`)
                    .then(resp => {
                        this.students = resp;
                        this.loading = false;
                    });
            },
        }
    }
</script>