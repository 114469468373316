<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{ editMode ? 'Редагування питання' : 'Додавання питання' }}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-textarea
                            solo
                            name="input-7-4"
                            v-model="form.question"
                            :error-messages="errors.question"
                            label="Текст запитання"
                        ></v-textarea>
                        <v-flex xs12>
                            <image-uploader
                                v-model="form.image"
                                :type="fileUploadEnum.reworkQuiz"
                                :image-endpoint="`${$config.apiEndpoint}/file/get`"
                            />
                        </v-flex>

                        <div class="text--accent-1 h2 mt-5">Відповіді</div>

                        <v-flex xs12 v-for="(quizAnswer, index) in form.quizAnswers" :key="index" class="mb-5">
                            <v-row
                                no-gutters
                            >
                                <v-col cols="8">
                                    <v-text-field
                                        v-model="quizAnswer.answer"
                                        :label="`Відповідь ${index + 1}`"></v-text-field>
                                    <v-switch
                                        style="margin-top: 0px;"
                                        v-model="quizAnswer.isRight"
                                        label="Ця відповідь вірна"
                                        @change="answerChange($event, quizAnswer.id)"
                                    ></v-switch>
                                </v-col>

                                <v-col cols="4">
                                    <image-uploader
                                        v-model="quizAnswer.image"
                                        :type="fileUploadEnum.reworkQuiz"
                                        :image-endpoint="`${$config.apiEndpoint}/file/get`"
                                    />
                                </v-col>
                            </v-row>
                            <v-divider class="mt-3"></v-divider>
                        </v-flex>

                    </v-layout>

                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldCloseAddEdit')"
                    :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                    color="primary"
                    type="submit"
                    :loading="loadingSave"
                    :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
import HttpService from "../../../HttpService";
import {remapErrors} from "../../../helpers/errorsHelper";
import LoadingPlaceholder from "../../common/LoadingPlaceholder";
import ImageUploader from "@/components/common/ImageUploader";
import fileUploadEnum from "@/constants/_internal/fileUploadEnum";

export default {
    props: {
        editId: Number,
        editMode: Boolean,
    },
    components: {
        LoadingPlaceholder,
        ImageUploader,
    },
    data: () => {
        return {
            form: {
                question: null,
                image: null,
                quizAnswers: [
                    {
                        answer: "",
                        isRight: true,
                    },
                    {
                        answer: "",
                        isRight: false,
                    },
                    {
                        answer: "",
                        isRight: false,
                    },
                    {
                        answer: "",
                        isRight: false,
                    },
                    {
                        answer: "",
                        isRight: false,
                    },
                ],
            },
            radioGroup: null,
            errors: {
                question: [],
                image: [],
                quizAnswers: [],
            },
            loading: false,
            loadingSave: false,
            fileUploadEnum,
        }
    },
    mounted() {
        if (this.editMode) {
            this.loading = true;

            HttpService.getInstance()
                .get(`reworkQuiz/${this.editId}`)
                .then(resp => {
                    this.loading = false;
                    this.form = resp;
                })
        }
    },
    methods: {
        submitForm(e) {
            e.preventDefault();

            this.loadingSave = true;
            
            const savePayload = {
                ...this.form,
                subject: {
                    id: this.$route.params.subject,
                },
                module: {
                    id: this.$route.params.module,
                },
                lecture: this.$route.params.lecture ? {id: this.$route.params.lecture} : null,
                topic:  this.$route.params.topic ? {id: this.$route.params.topic} : null,
            };

            HttpService.getInstance()
                .post("reworkQuiz", savePayload)
                .then(resp => {
                    this.loadingSave = false;
                    this.$emit("addedEdited");
                })
                .catch(err => {
                    this.loadingSave = false;
                    if (err.errors) {
                        this.errors = remapErrors(err.errors);
                    }
                });
        },
        answerChange(event, quizAnswerId) {
            this.form.quizAnswers.forEach(q => {
                if (q.id !== quizAnswerId) {
                    q.isRight = false;
                }
            });
        },
    }
}
</script>