<template>
    <div>
        <modules-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @navigateTo="navigateTo"
        >
        </modules-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import ModulesGrid from "./ModulesGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";

    export default {
        components: {
            ModulesGrid,
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                subject: {
                    finalControlType: null,
                },
                maxOrderBy: 0,
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`subjects/${this.$route.params.subject}`)  
              .then(subject => {
                  this.subject = subject;
              });
        },
        methods: {
            navigateTo(moduleId, routeName) {  // topicsPlan || topicsPlan
                this.$router.push({
                    name: routeName, 
                    params: {
                        subject: this.$route.params.subject, 
                        department: this.$route.params.department
                    }
                }).catch(err => {});  
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`eAristoProfessor/module/getGridModules/${this.$route.params.department}/${this.$route.params.subject}?${queryParams}`)
                    .then(modules => {
                        this.items = modules.items;
                        this.total = modules.total;
                        
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
    }
</script>