<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <search-bar 
                        :loading="loading" 
                        input-label="Назва дисципліни..."
                        @search="getItemsSearch($event)"
                />
                <v-flex xs12>
                    <subjects-grid
                            :items="items"
                            :total="total"
                            :loading="loading"
                            @paginate="onPaginate"
                            @editItem="editItem"
                            @deleteItem="deleteItem"
                    >
                    </subjects-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" max-width="700px" scrollable>
            <add-edit-subject
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-subject>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import SubjectsGrid from "./SubjectsGrid";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditSubject from "./AddEditSubject";
    import DeleteConfirm from "./DeleteConfirm";
    import SearchBar from "../../common/SearchBar";

    export default {
        components: {
            SubjectsGrid,
            AddEditSubject,
            DeleteConfirm,
            SearchBar,
        },
        mounted() {
            if (this.$route.params.specialityId !== undefined) {
                let filter = [{field: 'specialityId', value: this.$route.params.specialityId, operator: 'eq'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
            }
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },

                searchString: "",
            }
        },
        watch: {
            '$route.params.specialityId': function (newSpecialityId) {
                if (newSpecialityId !== undefined) {

                    this.pagination = Object.assign({}, this.pagination);

                    this.getItems(objectQueryStringify(this.pagination), newSpecialityId);
                }
            },
            '$route.params': function (newParams) {
                if (!newParams.specialityId) {
                    this.getItems(objectQueryStringify(this.pagination), newParams.specialityId);
                }
            },
        },
        methods: {
            getItemsSearch(searchString){
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination), this.$route.params.specialityId);
            },
            getItems(queryParams, specialityId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`subjects/grid/${specialityId}?${queryParams}`)
                    .then(subjects => {
                        this.items = subjects.items;
                        this.total = subjects.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination), this.$route.params.specialityId);
                }
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.departmentId);
            },
        },
    }
</script>