<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.nameUk="{ item }">{{ item.ratingDirection.directionName }}</template>        
        <template v-slot:item.checkers="{ item }">
            <v-chip v-for="professor in item.professors" :key="professor.id">
                {{ professor.surname }} {{ professor.name }} {{ professor.patronymic }}
            </v-chip>
            <div v-if="item.professors.length === 0">-</div>
        </template>        
        <template v-slot:item.actions="{ item }">
            <v-btn icon class="mx-0" @click="$emit('editItem', item.ratingDirection.id)">
                <v-icon color="primary">edit</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            mapSpecialitiesToString(specialities) {
                return specialities.map(spec => spec.nameUk).join(", ");
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва напрямку)', value: 'nameUk', sortable: false},
                {text: 'Перевіряючі', value: 'checkers', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>