<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <audit-index/>
        </v-layout>
    </v-container>
</template>

<script>
    import AuditIndex from "../../../components/admin/Logs/Audit/AuditIndex";
    
    export default  {
        components: {
            AuditIndex,
        }
    }
</script>