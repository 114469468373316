<template>
    <v-card>
        <v-card-title class="title">Відхилення робочого напрямку</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете відхилити робочий напрямок? Це дасть можливість викладачу
            внести НОВУ позицію проте не дозволить редагувати уже прийняті позиції.
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseRejectConfirm')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="rejectItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            workTypeId: Number,
        },
        methods: {
            rejectItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .post(`ratingAdmin/rejectWorktype/${this.workTypeId}/${this.$route.params.department}`)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("rejected");
                    });
            }
        }
    }
</script>