<template>
    <div>
        <v-btn
                v-for="(button, index) in buttonsCount"
                :key="index"
                :color="getQuestionColor(index)"
                :class="{'ma-1': true, 'is-answered': !isStudentTest && isAnswered(index), 'is-right': isRight(index)}"
                small
                :disabled="!isStudentTest && isAnswered(index)"
                @click="changeQuestion(index)"
        >
            {{index + 1}}
        </v-btn>
    </div>
</template>

<script>
    export default {
        props: {
            buttonsCount: {
                type: Number,
                default: 0
            },
            questionsOverview: {
                type: Array,
                default: () => [],
            },

            routeQuizHome: String,
            routeQuizAdditionalParams: Object,
            
            isStudentTest: Boolean,
        },
        methods: {
            isAnswered(index) {
                const question = this.questionsOverview[index];

                if (question === undefined) {
                    return false;
                }

                return !!question.isAnswered;
            },
            isRight(index) {
                const question = this.questionsOverview[index];

                if (question === undefined) {
                    return false;
                }

                return !!question.isRight;
            },
            changeQuestion(index) {
                if (!this.isStudentTest && this.isAnswered(index)) {
                    return;
                }

                this.$router.push({
                    name: this.routeQuizHome,
                    params: {
                        ...{
                            "questionNumber": index + 1,
                        },
                        ...this.routeQuizAdditionalParams
                    }
                });
            },
            isQuestionActive(questionNumber) {
                const questionNumberRoute = parseInt(this.$route.params.questionNumber);

                return questionNumberRoute === questionNumber;
            },
            getQuestionColor(index) {
                if (this.isAnswered(index) && this.isQuestionActive(index + 1)) {
                    return "active-answered";
                }

                if (this.isAnswered(index)) {
                    return "warning";
                }

                if (this.isQuestionActive(index + 1)) {
                    return "primary";
                }

                return '';
            }
        }
    }
</script>

<style>
    .is-answered {
        cursor: not-allowed !important;
    }
    
    button.is-right {
        border: 2px solid green !important;
    }
    
    button.is-answered-test {
        pointer-events: all;
    }

    .active-answered {
        border: 2px solid blue;
    }
</style>