<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}} теми індивідувльної</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.nameUk"
                                        v-model="form.nameUk"
                                        row-height="3"
                                        label="Назва теми"
                            ></v-textarea>
                        </v-flex>
<!--                        <v-flex xs12>-->
<!--                            <v-textarea :error-messages="errors.nameEn"-->
<!--                                          v-model="form.nameEn"-->
<!--                                        row-height="3"-->
<!--                                        label="Назва теми (english)"-->
<!--                            ></v-textarea>-->
<!--                        </v-flex>-->
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.academicHours"
                                          v-model="form.academicHours"
                                          @keypress="isNumber($event, 2)"
                                          label="Кількість академічних годин"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.orderNumber"
                                          v-model="form.orderNumber"
                                          v-mask="['###']"
                                          label="№ по порядку"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs4>
                            <v-switch
                                    label="Задати інший номер заняття"
                                    v-model="customClassNumber"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs4>
                            <v-text-field :error-messages="errors.classNumber"
                                          v-model="form.classNumber"
                                          v-mask="['###']"
                                          :disabled="!customClassNumber"
                                          label="№ заняття"
                            ></v-text-field>
                        </v-flex>
                       
                        <v-flex xs12>
                            <v-switch
                                    label="Заблокувати від зміни іншою кафедрою"
                                    v-model="form.isBlocked"
                                    :error-messages="errors.isBlocked"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12 v-if="form.department">
                            <div class="grey--text">Тема належить до кафедри: {{form.department.nameUk}}</div>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-checkbox v-model="createAnother" label="Створити ще одну"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import {isNumber} from "../../../helpers/numberHelper";
    import {topicEvaluationModeEnum} from "../../../constants/topicEvaluationModeTypes";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            maxTopicOrder: {
                type: Number,
                default: 0,
            },
            maxClassOrder: {
                type: Number,
                default: 0,
            },
        },
        components: {
            LoadingPlaceholder
        },
        data: () => {
            return {
                form: {
                    nameUk: null,
                    nameEn: null,
                    academicHours: null,
                    orderNumber: null,
                    classNumber: null,
                    isBlocked: false,
                    evaluationMode: 1,
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    academicHours: [],
                    orderNumber: [],
                    classNumber: [],
                    evaluationMode: [],
                },
                loading: false,
                loadingSave: false,
                customClassNumber: false,
                createAnother: false,
                isNumber,

                topicEvaluationModeEnum,
            }
        },
        mounted() {
            this.updateOrderNumber();

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`topic/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        watch: {
          "form.orderNumber": function(newOrderNumber) {
              this.form.classNumber = newOrderNumber;
          }  
        },
        methods: {
            updateOrderNumber() {
                if (!this.editMode) {
                    this.form.orderNumber = this.maxTopicOrder + 1;
                    this.form.classNumber = this.maxClassOrder + 1;
                }
            },
            submitForm() {
                this.loadingSave = true;

                const additionalParams = {
                    module: {id: this.$route.params.module},
                    department: {id: this.$route.params.department}
                };

                HttpService.getInstance()
                    .post("topicIndependent", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited", this.createAnother);
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>