<template>
    <v-card class="elevation-8" v-if="survey">
        <v-card-title primary-title>
            <div>
                <div class="headline">{{survey[`title${$i18n.lang}`]}}</div>
                <span class="grey--text">
                    <div>
                            {{ moment().format(localeDateFormats[$i18n.lang].short) }}
                        </div>
                </span>
            </div>
        </v-card-title>
        <v-card-text>
            <div class="subtitle-1 mb-6">{{survey[`description${$i18n.lang}`]}}</div>
            <survey-question :key="index" v-for="(question, index) in survey.pollQuestionPool.pollQuestions" :question="question"/>
        </v-card-text>
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../HttpService";
    import localeDateFormats from "../../i18n/locale-date-formats";
    import SurveyQuestion from "./survey/SurveyQuestion";

    export default {
        components: {
            SurveyQuestion 
        },
        data: () => ({
            moment,
            localeDateFormats: localeDateFormats,
            survey: {
                pollQuestionPool: {
                    pollQuestions: [],
                }
            },
        }),
        mounted() {
            HttpService.getInstance()
                .get(`survey/${this.$route.params.id}`)
                .then(resp => {
                    this.survey = resp;
                });
        },
    }
</script>