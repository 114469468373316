export const getMarkChipColor = (mark) => {
    if (mark) {
        switch (mark.value) {
            case 5:
                return "green";
            case 4:
                return "blue";
            case 3:
                return "orange";
            case 2:
                return "red";
            case 1:
                return "grey lighten-2";
            case 0:
                return "red";
        }
    }

    return "";
};

export const getMarkClass = (mark) => {
    let className = "text-xs-left cursor-pointer mark mark-wrapper ";
    if (mark !== null) {
        switch (mark.value) {
            case 5:
                className += "mark5";
                break;
            case 4:
                className += "mark4";
                break;
            case 3:
                className += "mark3";
                break;
            case 2:
                className += "mark2";
                break;
            case 1:
                className += "mark1";
                break;
            case 0:
                className += "mark0";
                break;
        }
    }

    return className;
};