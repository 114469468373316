<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5 mb-2">Статистика по кафедрам</h2>
        <v-data-table
            fixed-header
            height="80vh"
            :headers="headers"
            :items="data"
            :loading="loading"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.avgMark="{item}">
                {{item.avgMark.toFixed(2)}}
            </template>
            <template v-slot:item.avgMarkProfMaleStudMale="{item}">
                {{item.avgMarkProfMaleStudMale.toFixed(2)}} ({{item.avgMarkProfMaleStudMalePercentage.toFixed(2)}})
            </template>
            <template v-slot:item.avgMarkProfMaleStudFemale="{item}">
                {{item.avgMarkProfMaleStudFemale.toFixed(2)}} ({{item.avgMarkProfMaleStudFemalePercentage.toFixed(2)}})
            </template>
            <template v-slot:item.avgMarkProfFemaleStudMale="{item}">
                {{item.avgMarkProfFemaleStudMale.toFixed(2)}} ({{item.avgMarkProfFemaleStudMalePercentage.toFixed(2)}})
            </template>
            <template v-slot:item.avgMarkProfFemaleStudFemale="{item}">
                {{item.avgMarkProfFemaleStudFemale.toFixed(2)}} ({{item.avgMarkProfFemaleStudFemalePercentage.toFixed(2)}})
            </template>
            
            <template v-slot:item.avgMarkLess35="{item}">
                {{item.avgMarkLess35.toFixed(2)}} ({{item.avgMarkLess35Percentage.toFixed(2)}})
            </template>
            <template v-slot:item.avgMark35To55="{item}">
                {{item.avgMark35To55.toFixed(2)}} ({{item.avgMark35To55Percentage.toFixed(2)}})
            </template>
            <template v-slot:item.avgMarkGreater55="{item}">
                {{item.avgMarkGreater55.toFixed(2)}} ({{item.avgMarkGreater55Percentage.toFixed(2)}})
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import HttpService from "@/HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            StatDateRange,
        },
        data () {
            return {
                loading: false,
                dates: [],
                
                data: [],
                headers: [
                    {text: 'Кафедра', value: 'department.nameUk'},
                    {text: '∑ ♂ викл.', value: 'sumProfMale'},
                    {text: '∑ ♀ викл.', value: 'sumProfFemale'},
                    {text: 'μ бал', value: 'avgMark'},
                    {text: 'μ бал ♂ → ♂', value: 'avgMarkProfMaleStudMale'},
                    {text: 'μ бал ♂ → ♀', value: 'avgMarkProfMaleStudFemale'},
                    {text: 'μ бал ♀ → ♂', value: 'avgMarkProfFemaleStudMale'},
                    {text: 'μ бал ♀ → ♀', value: 'avgMarkProfFemaleStudFemale'},
                    {text: 'μ бал <35', value: 'avgMarkLess35'},
                    {text: 'μ бал 35-55', value: 'avgMark35To55'},
                    {text: 'μ бал >55', value: 'avgMarkGreater55'},
                ],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loading = true;
                HttpService.getInstance()
                    .get(`statisticsMarks/getByDepartmentsGenderAge?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loading = false;
                    })
                    .catch(e => {})
            },
        }
    }
</script>