<template>
    <form @submit="submitForm" novalidate="true">
        <v-app-bar dark color="primary">
                <v-btn
                        class="ml-4"
                        dark
                        icon
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave">
                    <v-icon>close</v-icon>
                </v-btn>
            
            <v-toolbar-title>{{editMode ? 'Редагування' : 'Додавання'}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-select
                    :disabled="loading || editMode"
                    :loading="loading"
                    filled
                    dark
                    :items="specialities"
                    v-model="form.speciality"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Спеціальність"
                    :error-messages="errors.speciality"
                    class="mb-0 mt-6"
            ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
                    :error-messages="errors.name"
                    :loading="loading"
                    :disabled="!form.subjects.length"
                    filled
                    dark
                    v-model="form.name"
                    label="Назва плану"
                    class="mb-0 mt-6"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-switch
                    :disabled="!form.subjects.length"
                    dark
                    color="white"
                    label="За замовчуванням"
                    v-model="form.isDefault"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                        text
                        @click.native="dialog = false"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-toolbar-items>
            <template v-slot:extension>
            <v-tabs
                    slot="extension"
                    v-model="activeTab"
                    background-color="primary"
                    dark
                    slider-color="yellow"
                    centered
                    @change="onChangeTab($event)"
            >
                <v-tab
                        v-for="(year, index) in years"
                        :key="index"
                >
                    {{ year.yearNumber }} курс
                </v-tab>
            </v-tabs>
            </template>
        </v-app-bar>
        <v-tabs-items v-if="!loading" v-model="activeTab">
            <v-tab-item
                    v-for="(year, index) in years"
                    :key="index"
            >
                <v-card flat>
                    <v-card-text class="scrollable-content">
                        <v-container fill-height>
                            <v-layout style="display: block; min-height: 50vh;">
                                <v-data-table
                                        :headers="headers"
                                        :items="form.subjects"
                                        :loading="loading"
                                        class="elevation-3 education-plan-grid"
                                >
                                    <template v-slot:item.semesters="{item}">
                                        <v-flex xs12 class="py-2">
                                            <div class="v-btn-toggle theme--light">
                                                <v-btn v-for="(semester, index) in semesters" :key="index"
                                                       :color="(semester.semesterNumber % 2 === 0) 
                                                           ? 'success' : 'warning'"
                                                       :input-value="getSemesterModelValue(item.semesters, semester)"
                                                       @click="onChangeSemester(item.id, semester)">
                                                        <span>{{
                                                            getLabelForSemester(semester.semesterNumber)}} 
                                                            ({{semester.semesterNumber}} сем.)</span>
                                                </v-btn>
                                            </div>
                                        </v-flex>
                                    </template>
                                    <template v-slot:item.lectures="{item}">
                                        <v-flex xs12 class="py-2">
                                            <div class="v-btn-toggle theme--light">
                                                <v-btn v-for="(semester, index) in semesters" :key="index"
                                                       :color="(semester.semesterNumber % 2 === 0) 
                                                           ? 'info' : 'warning'"
                                                       :input-value="getLectureSemesterModelValue(item.semestersLectures, semester)"
                                                       @click="onChangeLectureSemester(item.id, semester)">
                                                        <span>{{
                                                            getLabelForSemester(semester.semesterNumber)}} 
                                                            ({{semester.semesterNumber}} сем.)</span>
                                                </v-btn>
                                            </div>
                                        </v-flex>
                                    </template>
                                </v-data-table>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-container v-if="form.speciality === null">
            <div class="headline grey--text">Виберіть спочатку спеціальність, а також зазначте назву</div>
        </v-container>
        <v-container v-if="loading" fluid fill-height style="background: white; min-height: 50vh">
            <v-layout align-center justify-center>
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-container>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => ({
            headers: [
                {text: 'Дисципліна', value: 'nameUk'},
                {text: 'Практичні заняття', value: 'semesters', sortable: false},
                {text: 'Лекції', value: 'lectures', sortable: false},
            ],
            activeTab: 0,
            years: [],
            form: {
                name: '',
                isDefault: true,
                speciality: null,
                subjects: [],
            },
            errors: {
                name: [],
            },
            loading: false,
            loadingSave: false,
            specialities: [],
            semesters: [],
        }),
        watch: {
            "form.speciality.id": function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    this.loading = true;

                    HttpService.getInstance()
                        .get(`semester/year/${newVal}`)
                        .then(resp => {
                            this.years = resp;
                            this.loading = false;

                            if (this.years.length && this.years[this.activeTab]) {
                                this.semesters = this.years[this.activeTab].semesters;
                            }
                        })
                        .then(() => {
                            if (!this.editMode) {
                                this.loading = true;

                                HttpService.getInstance()
                                    .get(`educationPlan/createNew/${newVal}`)
                                    .then(resp => {
                                        this.loading = false;
                                        this.form = resp;
                                    });
                            }
                        });
                }
            }
        },
        mounted() {
            this.loading = true;

            HttpService.getInstance()
                .get("specialities/getAll")
                .then(resp => {
                    this.specialities = resp;
                    this.loading = false;
                });


            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`educationPlan/${this.editId}`)
                    .then(resp => {
                        this.form = resp;
                    })
                    .then(() => {
                        HttpService.getInstance()
                            .get(`semester/year/${this.form.speciality.id}`)
                            .then(resp => {
                                this.loading = false;

                                this.years = resp;
                                if (this.years.length && this.years[this.activeTab]) {
                                    this.semesters = this.years[this.activeTab].semesters;
                                }
                            }) 
                    });
            }
        },
        methods: {
            onChangeLecture: function(itemId, lectureValue) {
                let item = this.form.subjects.find(subject => subject.id === itemId);
                
                if (item) {
                    let indexOfValue = item.semesters.findIndex(s => s.id === semester.id);
                    if (indexOfValue > -1) {
                        item.semesters.splice(indexOfValue, 1);
                    }
                    else {
                        item.semesters.push(semester);
                    }
                }
            },
            
            getSemesterModelValue(itemSemesters, semester) {
                if (itemSemesters == null) {
                    return undefined;
                }
                return itemSemesters.find(s => s.id === semester.id);
            },
            
            getLectureSemesterModelValue(itemSemesters, semester) {
                if (itemSemesters == null) {
                    return undefined;
                }
                return itemSemesters.find(s => s.id === semester.id);
            },
            
            
            onChangeSemester: function (itemId, semester) {
                let item = this.form.subjects.find(subject => subject.id === itemId);

                if (item) {
                    let indexOfValue = item.semesters.findIndex(s => s.id === semester.id);
                    if (indexOfValue > -1) {
                        item.semesters.splice(indexOfValue, 1);
                    }
                    else {
                        item.semesters.push(semester);
                    }
                }
            },

            onChangeLectureSemester: function (itemId, semester) {
                let item = this.form.subjects.find(subject => subject.id === itemId);

                if (item) {
                    if (item.semestersLectures === null) {
                        item.semestersLectures = [];
                    }
                    
                    let indexOfValue = item.semestersLectures.findIndex(s => s.id === semester.id);
                    if (indexOfValue > -1) {
                        item.semestersLectures.splice(indexOfValue, 1);
                    }
                    else {
                        item.semestersLectures.push(semester);
                    }
                }
            },
            
            getLabelForSemester(semesterNumber) {
                return (semesterNumber % 2 === 0) ? "Весняний" : "Осінній";
            },
            onChangeTab(tabId) {
                if (this.years.length && this.years[tabId]) {
                    this.semesters = this.years[tabId].semesters;
                }
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("educationPlan", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>

<style lang="scss">
    table.education-plan-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
            }
        }
    }
    
    .education-plan-grid table {
        & tr {
            & td {
                display: table-cell;
                
                &.lectures-column {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    
    .scrollable-content {
        overflow-y: auto;
        height: calc(100vh - 128px);
        padding-bottom: 30px;
    }
</style>