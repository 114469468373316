<template>
    <v-card>
        <v-card-title class="title">Копіювати</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете скопіювати дане оголошення?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingCopy"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="copyItem"
                    :loading="loadingCopy"
                    :disabled="loadingCopy"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../../HttpService";

    export default {
        data: () => {
            return {
                loadingCopy: false,
            };
        },
        props: {
            copyId: Number,
        },
        methods: {
            copyItem() {
                this.loadingCopy = true;

                HttpService.getInstance()
                    .post(`announcementsAdmin/copy`, {id: this.copyId})
                    .then(resp => {
                        this.loadingCopy = false;

                        this.$emit("copied");
                    });
            }
        }
    }
</script>