<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <v-flex xs12>
                    <admin-final-exam-admin-list-grid
                            :professors="items"
                            :loading="loading"
                    >
                    </admin-final-exam-admin-list-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" max-width="900px" scrollable>
            <add-edit-final-exam-admin
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-final-exam-admin>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import AdminFinalExamAdminListGrid from "./AdminFinalExamAdminListGrid";
    import AddEditFinalExamAdmin from "./AddEditFinalExamAdmin";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";

    export default {
        components: {
            AdminFinalExamAdminListGrid,
            AddEditFinalExamAdmin,
        },
        data: () => {
            return {
                items: [],
                loading: false,
                
                dialog: false,
                editId: null,
                editMode: false,
            }
        },
        mounted() {
            this.getItems();
        },
        methods: {
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`FinalExamAdminList/getFinalExamAdmins`)
                    .then(items => {
                        this.items = items.items;
                        this.total = items.length;
                        
                        this.loading = false;
                    })
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems();
            },
        },
    }
</script>