<template>
    <v-container fluid fill-height>
        <v-layout v-if="!loading" row wrap justify-center>
            <v-flex xs12>
                <div v-for="faculty in groupsNav">
                    <div class="headline font-weight-thin font-italic">{{faculty.nameUk}}</div>
                    <div v-for="speciality in faculty.specialities">
                        <div class="title font-weight-thin">
                            <v-icon>{{speciality.icon}}</v-icon>
                            {{speciality.nameUk}}
                        </div>
                        <div v-for="semester in speciality.semesters">
                            <v-list subheader two-line>
                                <v-subheader class="subtitle-1">{{semester.yearNumber}} курс
                                    ({{semester.semesterNumber}} семестр)
                                </v-subheader>
                                <v-list-item
                                        v-for="(academicGroup, index) in semester.academicGroups"
                                        :key="academicGroup.id"
                                        @click=""
                                        :class="{'active-group': isSelected(academicGroup.id)}"
                                >
                                    <v-list-item-action>
                                        <v-checkbox :value="getGroupValue(academicGroup.id, 'group')"
                                                    @change="setGroupValue($event, academicGroup.id, 'group')"
                                                    class="checkbox-fix"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content @click="setSelectedGroup(academicGroup.id)">
                                        <v-list-item-title>
                                            {{academicGroup.groupNumber}} група
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action class="mr-12">
                                        <v-checkbox v-if="getGroupValue(academicGroup.id, 'group')"
                                                    label="Тимчасово"
                                                    color="orange"
                                                    :value="getGroupValue(academicGroup.id, 'isTemporary')"
                                                    @change="setGroupValue($event, academicGroup.id, 'isTemporary')"
                                                    class="checkbox-fix"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <date-picker
                                                v-if="getGroupValue(academicGroup.id, 'isTemporary')"
                                                :value="getGroupValue(academicGroup.id, 'tillDate')"
                                                @change="setGroupValue($event, academicGroup.id, 'tillDate')"
                                                label="Дата, до"
                                                :min-date="new Date()"
                                        ></date-picker>
                                        <span v-if="getGroupValue(academicGroup.id, 'isTemporary')" class="red--text">{{getError(academicGroup.id, 'tillDate') }}</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>
                    <v-divider></v-divider>
                </div>
            </v-flex>
            <v-flex xs12 class="mt-4">
                <v-btn @click="saveGroupDistribution()"
                       :disabled="loading || !selectedGroups.length || loadingSave"
                       :loading="loadingSave"
                       large
                       color="primary"
                >Зберегти
                </v-btn>
            </v-flex>
        </v-layout>
        <v-container v-if="loading" fluid fill-height>
            <v-layout align-center justify-center>
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-container>
    </v-container>
</template>
<script>
    import HttpService from "../../../HttpService";
    import DatePicker from "../../common/DatePicker";
    import {remapErrors} from "../../../helpers/errorsHelper";

    export default {
        components: {
            DatePicker
        },
        data: () => ({
            loading: false,
            groupsNav: [],
            selectedGroups: [],
            loadingSave: false,
            errors: {}
        }),
        mounted() {
            this.loading = true;

            HttpService.getInstance()
                .get(`navigation/getNavRegistersForDepartmentSubject/${this.$route.params.department}/${this.$route.params.subject}`)
                .then(resp => {
                    this.groupsNav = resp;
                    this.loading = false;
                })
                .then(() => {
                    return HttpService.getInstance()
                        .get(`moderator/groupDistribution/${this.$route.params.professor}/${this.$route.params.subject}`)
                })
                .then(respReworks => {
                    this.selectedGroups = respReworks.academicGroups.map(r => {
                        return {
                            group: true,
                            id: r.id,
                            isTemporary: r.isTemporary,
                            tillDate: r.tillDate,
                        }
                    });
                });
        },
        methods: {
            getError(groupId, field) {
                let groupIsSelected = this.selectedGroups.find(sg => sg.id === groupId);
                
                if (groupIsSelected === undefined) {
                    return "";
                }
                
                let firstLvl = this.errors[`academicGroups[${this.selectedGroups.indexOf(groupIsSelected)}]`];
                
                if (firstLvl === undefined) {
                    return "";
                }

                return firstLvl[field];
            },
            setGroupValue(value, groupId, field) {
                let foundVal = this.selectedGroups.find(s => s.id === groupId);

                if (foundVal === undefined) {
                    let newFreshVal = {id: groupId, group: undefined, isTemporary: false, tillDate: undefined};
                    newFreshVal[field] = value;
                    this.selectedGroups.push(newFreshVal); // ad new object when not found
                }
                else { 
                    this.selectedGroups.splice(this.selectedGroups.findIndex(g => g.id === groupId), 1);
                    if ((value === false || value === null) && field === "group") {
                        return; // remove object at all if unchecked group checkbox
                    }

                    let newVal = Object.assign({}, foundVal);
                    newVal[field] = value === null ? false : value; // checkbox returns null instead of false
                    this.selectedGroups.push(newVal);
                }
            },
            getGroupValue(groupId, field) {
                let foundVal = this.selectedGroups.find(s => s.id === groupId);
                if (foundVal === undefined) {
                    return undefined;
                }

                return foundVal[field];
            },
            isSelected(groupId) {
                return this.selectedGroups.indexOf(groupId) > -1;
            },
            setSelectedGroup(groupId) {
                let groupIndex = this.selectedGroups.indexOf(groupId);
                if (groupIndex > -1) {
                    this.selectedGroups.splice(groupIndex, 1);
                }
                else {
                    this.selectedGroups.push(groupId);
                }
            },
            saveGroupDistribution() {
                let groupDistributionModel = {
                    academicGroups: this.selectedGroups,
                    professor: {id: this.$route.params.professor},
                    subject: {id: this.$route.params.subject},
                };

                this.loadingSave = true;

                HttpService.getInstance()
                    .post(`moderator/groupDistribution`, groupDistributionModel)
                    .then(resp => {
                        this.loadingSave = false;
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>
<style lang="scss">
    .active-group {
        background: rgba(0, 0, 0, 0.03);
    }

    .group-list-item {
        height: 60px;
    }
    .checkbox-fix {
        margin-top: 12px !important;
    }
</style>