<template>
    <div>
        <div v-if="loading">
            <v-layout align-center justify-center>
                <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </div>

        <v-tabs
            v-if="slidesContent.length === 0"
            v-model="uploadTab"
            background-color="transparent"
            color="primary"
            grow
        >
            <v-tab :key="1">PDF файл (рекомендовано)</v-tab>
            <v-tab :key="2">PPTX файл</v-tab>
        </v-tabs>

        <v-tabs-items v-if="slidesContent.length === 0 && !loading" v-model="uploadTab">
            <v-tab-item :key="1">
                <div class="mt-2">
                    <presentation-pdf-uploader @uploadedFiles="uploadedPresentation($event)" :topic-id="+$route.params.topic"/>
                </div>
            </v-tab-item>
            <v-tab-item :key="2">
                <div class="mt-2">
                    <presentation-uploader @uploadedFiles="uploadedPresentation($event)" :topic-id="+$route.params.topic"/>
                </div>
            </v-tab-item>
        </v-tabs-items>
        
        
        <div v-if="slidesContent.length !== 0 && !loading" class="d-flex flex-md-column align-center mt-4">
            <v-btn large class="mb-2 mt-2" color="red" dark @click="deletePresentation($route.params.topic)">Видалити презентацію (всі слайди)</v-btn>
            
            <div class="mb-2" :key="index" v-for="(slide, index) in slidesContent">
                <h4>Слайд №{{ slide.orderNumber + 1 }}</h4>
                <img class="slide-img" width="600" :src="slideImageUrl(slide.fileName)"/>
                
                <div class="mb-3">
                    <v-btn @click="addAfterSlide(slide.id)" class="mt-2">Додати запитання після слайду №{{slide.orderNumber + 1}}</v-btn>
                </div>
            </div>
        </div>

        <e-aristo-question-index :education-material-presentation-id="currentSlideId" :topic-question-type="eAristoTopicQuestionType.PresentationQuestions" ref="questionsGrid"></e-aristo-question-index>

        <v-dialog v-model="dialogRemove" max-width="450">
            <delete-confirm
                @shouldCloseDeleteConfirm="dialogRemove = false"
                @removed="removed"
                :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>
    </div>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";
import PresentationUploader from "@/components/eAristoProfessor/topicContent/PresentationUploader.vue";
import eAristoQuestionIndex from "@/components/eAristoProfessor/topicContent/eAristoQuestions/eAristoQuestionIndex.vue";
import DeleteConfirm from "./DeleteConfirm.vue";
import {eAristoTopicQuestionType} from "@/constants/eAristo/eAristoTopicQuestionType";
import PresentationPdfUploader from "@/components/eAristoProfessor/topicContent/PresentationPdfUploader.vue";

const configuration = AppConfig(config);

export default {
    name: "PresentationContent",
    components: {
        DeleteConfirm,
        PresentationUploader,
        PresentationPdfUploader,
        eAristoQuestionIndex,
    },
    props: {
        slidesContent: {
            type: Array,
            default: () => {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            dialogRemove: false,
            deleteId: null,

            currentSlideId: null,

            eAristoTopicQuestionType: eAristoTopicQuestionType,

            uploadTab: 0,
        };
    },
    methods: {
        uploadedPresentation(slides) {
            this.$emit('uploadedPresentation', slides);
        },
        slideImageUrl(image) {
            return `${configuration.apiEndpoint}/file/get/presentationSlideImage/${image}`;
        },
        addAfterSlide(slideId) {
            console.log("slideId", slideId);
            this.currentSlideId = slideId;
            
            this.$refs.questionsGrid.addItemSlide(slideId);
        },

        deletePresentation(itemId) {
            this.dialogRemove = true;
            this.deleteId = itemId;
        },
        removed() {
            this.dialogRemove = false;
            this.deleteId = null;

            this.$emit("uploadedPresentation", null);
        },
    }
}
</script>

<style scoped lang="scss">
.slide-img {
    border: 1px solid #ccc;
    box-shadow: 3px 5px 10px 0px #ccc;
}
</style>