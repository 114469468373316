export const logLevelsEnum = {
    debug: 'Debug',
    error: 'Error',
    fatal: 'Fatal',
    verbose: 'Verbose',
    warning: 'Warning',
    information: 'Information',
};

export default [
    {
        id: logLevelsEnum.debug,
        color: "#E0F2F1",
        name: "Debug",
    },
    {
        id: logLevelsEnum.error,
        color: "#EF5350",
        name: "Error",
    },
    {
        id: logLevelsEnum.fatal,
        color: "#B71C1C",
        name: "Fatal",
    },
    {
        id: logLevelsEnum.verbose,
        color: "#CFD8DC",
        name: "Verbose",
    },
    {
        id: logLevelsEnum.warning,
        color: "#FFCC80",
        name: "Warning",
    },
    {
        id: logLevelsEnum.information,
        color: "#90CAF9",
        name: "Information",
    },
];
