export const topicMarkAdmissionEmissionTypesEnum = {
    deanOffice: 0,
    liqPay: 1,
    bankOffice: 2,
};

export default [
    {
        id: topicMarkAdmissionEmissionTypesEnum.deanOffice,
        nameUk: "Виданий деканатом",
        nameEn: "Emitted by dean office",
    },
    {
        id: topicMarkAdmissionEmissionTypesEnum.liqPay,
        nameUk: "Виданий та оплачений через особистий кабінет",
        nameEn: "Emitted and payed through personal account",
    },
    {
        id: topicMarkAdmissionEmissionTypesEnum.bankOffice,
        nameUk: "Виданий та оплачений через банківську касу", 
        nameEn: "Emitted abd payed through bank office",
    },
];
