<template>
    <div>
        <question-grid
                :items="items"
                :total="total"
                :loading="loading"
                :topic-question-type="topicQuestionType"
                @paginate="onPaginate"
                @search="onSearch"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @deleteAll="dialogRemoveAll = true"
        >
        </question-grid>

        <v-dialog v-model="dialog" max-width="900px">
            <add-edit-question-wrapper
                    v-if="dialog"
                    @shouldClose="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :edit-question-type="editQuestionType"
                    :education-material-presentation-id="educationMaterialPresentationId"
                    :education-material-video-id="educationMaterialVideoId"
                    :current-video-time="currentVideoTime"
            ></add-edit-question-wrapper>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm-question
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm-question>
        </v-dialog>

        <v-dialog v-model="dialogRemoveAll" max-width="450">
            <delete-confirm-all-questions
                    @shouldCloseDeleteConfirm="dialogRemoveAll = false"
                    @removedAll="removedAll"
                    :topic-id="$route.params.topic"
                    :topic-question-type="topicQuestionType"
            >
            </delete-confirm-all-questions>
        </v-dialog>
    </div>
</template>

<script>
    import QuestionGrid from "./QuestionGrid";
    import HttpService from "@/HttpService";
    import {objectQueryStringify} from "@/helpers/querystringHelper";
    import AddEditQuestionWrapper from "./AddEditQuestionWrapper";
    import DeleteConfirmQuestion from "./DeleteConfirmQuestion";
    import DeleteConfirmAllQuestions from "./DeleteConfirmAllQuestions";
    import {eAristoTopicQuestionType} from "@/constants/eAristo/eAristoTopicQuestionType";

    export default {
        components: {
            QuestionGrid,
            AddEditQuestionWrapper,
            DeleteConfirmQuestion,
            DeleteConfirmAllQuestions,
        },
        props: {
            educationMaterialVideoId: {
                type: Number,
                default: null,
            },
            educationMaterialPresentationId: {
                type: Number,
                default: null,
            },
            topicQuestionType: {
                type: Number,
                default: eAristoTopicQuestionType.TopicQuestions,
            }
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;
                
                let url = "";
                
                if (this.topicQuestionType === eAristoTopicQuestionType.VideoQuestions) {
                    url = "eAristoProfessor/questions/video";
                }
                else if (this.topicQuestionType === eAristoTopicQuestionType.PresentationQuestions) {
                    url = "eAristoProfessor/questions/presentation";
                }
                else if (this.topicQuestionType === eAristoTopicQuestionType.TopicQuestions) {
                    url = "eAristoProfessor/questions/topic";
                }

                HttpService.getInstance()
                    .get(`${url}/${this.$route.params.topic}?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItemSlide(slideNumber) {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            addItemVideo(currentVideoTime) {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
                
                this.currentVideoTime = currentVideoTime;
            },
            addItem(currentTime) {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem({id, questionType}) {
                this.dialog = true;
                this.editId = id;
                this.editMode = true;
                
                this.editQuestionType = questionType;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            removedAll() {
                this.dialogRemoveAll = false;

                this.getItems(objectQueryStringify(this.pagination));
            },
            onSearch(searchStr) {
                this.pagination.filter = [{
                    field: 'question',
                    value: searchStr,
                    operator: 'contains',
                }];

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialogRemoveAll: false,
                dialog: false,
                dialogRemove: false,
                editId: null,
                editQuestionType: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                search: '',

                currentVideoTime: null,
                currentSlide: null,
            }
        }
    }
</script>