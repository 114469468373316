<template>
    <v-card>
        <v-card-title class="title">Видалення ВСІХ запитань</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете видалити ВСІ запитання?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    color="red"
                    dark
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "@/HttpService";
    import {eAristoTopicQuestionType} from "@/constants/eAristo/eAristoTopicQuestionType";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            topicId: Number,
            topicQuestionType: {
                type: Number,
                default: eAristoTopicQuestionType.TopicQuestions,
            }
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;

                let url = "";

                if (this.topicQuestionType === eAristoTopicQuestionType.VideoQuestions) {
                    url = "eAristoProfessor/questions/video";
                }
                else if (this.topicQuestionType === eAristoTopicQuestionType.PresentationQuestions) {
                    url = "eAristoProfessor/questions/presentation";
                }
                else if (this.topicQuestionType === eAristoTopicQuestionType.TopicQuestions) {
                    url = "eAristoProfessor/questions/topic";
                }

                HttpService.getInstance()
                    .delete(`${url}/${this.topicId}`)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("removedAll");
                    });
            }
        }
    }
</script>