<template>
    <div>
        <e-aristo-question-student-quiz-question
            class="mb-2"
            :key="index"
            v-for="(question, index) in questionsData"
            :question="question"
            @selectAnswer="onSelectAnswer"
            @changeAnswer="onChangeAnswer"
        >
        </e-aristo-question-student-quiz-question>

        <v-btn @click="sendAnswers()" class="ml-2 mt-5" :disabled="!couldSendAnswer || loading">{{$t("eAristoStudent.sendAnswers")}}</v-btn>
    </div>
</template>

<script>
import eAristoQuestionStudentQuizQuestion
    from "@/components/eAristoStudent/topicContent/questions/eAristoQuestionStudentQuizQuestion.vue";
import {eAristoQuestionStyleType} from "@/constants/eAristo/eAristoQuestionStyleType";
import Vue from "vue";
import HttpService from "@/HttpService";
import store from "@/store";

export default {
    name: "eAristoQuestionStudentQuizIndex",
    components: {
        eAristoQuestionStudentQuizQuestion,
    },
    data: () => {
        return {
            questionsData: [],
            loading: false,
            couldSendAnswer: false,
            eAristoQuestionStyleType,
        }
    },
    props: {
        questionsToPass: {
            type: Array,
            default: () => {
                return [];
            }
        },
        quizAttemptId: {
            type: Number,
        },
        isOverallQuiz: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    mounted() {
        this.questionsData = this.questionsToPass;
    },
    methods: {
        checkCouldSendAnswer(questions) {
            if (!questions) {
                return false;
            }
            
            let isAnswered = [];

            for (const question of questions) {
                if ((question.questionType === eAristoQuestionStyleType.singleChoice || 
                        question.questionType === eAristoQuestionStyleType.singleChoiceImage) && 
                    question.questionAnswers.find(a => a.answered) !== undefined) {
                    isAnswered.push(question.id);
                }
                
                if (question.questionType === eAristoQuestionStyleType.completeTheSentence && 
                    (question.questionAnswers.filter(a => a.answer != null && a.answer !== '').length === question.questionAnswers.length)) {
                    isAnswered.push(question.id);
                }

                if (question.questionType === eAristoQuestionStyleType.writeAnswer &&
                    (question.questionAnswers.filter(a => a.answer != null && a.answer !== '').length === question.questionAnswers.length)) {
                    isAnswered.push(question.id);
                }
            }
            
            this.couldSendAnswer = isAnswered.length === questions.length;
        },
        onSelectAnswer({question, answer}) {
            for (let i = 0; i < this.questionsData.length; i++) {
                if (this.questionsData[i].id === question.id) {
                    for (let j = 0; j < this.questionsData[i].questionAnswers.length; j++) {
                        if (this.questionsData[i].questionAnswers[j].id === answer.id) {
                            Vue.set(this.questionsData[i].questionAnswers, j, {
                                ...this.questionsData[i].questionAnswers[j],
                                answered: true,
                            });
                        } else {
                            Vue.set(this.questionsData[i].questionAnswers, j, {
                                ...this.questionsData[i].questionAnswers[j],
                                answered: false,
                            });
                        }
                    }
                }
            }
            
            this.checkCouldSendAnswer(this.questionsData);
        },
        onChangeAnswer({question, answer}) {
            for (let i = 0; i < this.questionsData.length; i++) {
                if (this.questionsData[i].id === question.id) {
                    for (let j = 0; j < this.questionsData[i].questionAnswers.length; j++) {
                        if (this.questionsData[i].questionAnswers[j].id === answer.id) {
                            Vue.set(this.questionsData[i].questionAnswers, j, {
                                ...this.questionsData[i].questionAnswers[j],
                                answer: answer.answer,
                            });
                        }
                    }
                }
            }

            this.checkCouldSendAnswer(this.questionsData);
        },
        sendAnswers() {
            this.loading = true;

            if (!this.isOverallQuiz) {
                HttpService.getInstance({}, {}, true)
                    .post(`eAristoStudent/saveQuiz/${this.quizAttemptId}`, this.questionsData)
                    .then(resp => {
                        this.loading = false;
                        if (resp === false) {
                            store.commit("snackMessages/set", {
                                message: this.$t("eAristoStudent.youAnsweredWrong"),
                                color: 'warning'
                            });
                        } else {
                            store.commit("snackMessages/set", {message: this.$t("eAristoStudent.youAnsweredCorrect"), color: 'success'});
                        }

                        this.$emit("submittedAnswers", resp);
                    });
            } else {
                HttpService.getInstance({}, {}, true)
                    .post(`eAristoStudent/saveQuizOverall/${this.quizAttemptId}`, this.questionsData)
                    .then(resp => {
                        this.loading = false;

                        store.commit("snackMessages/set", {message: this.$t("eAristoStudent.youAnsweredThisBlock"), color: 'info'});

                        this.$emit("submittedAnswers", resp);
                    });
            }
            
        }
    }
}
</script>

<style scoped>

</style>