<template>
    <v-dialog v-model="confirmationModalComputed" persistent max-width="600">
        <v-card>
            <v-card-title class="headline">{{title}}</v-card-title>
            <v-card-text>
                {{message}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        type="button"
                        color="primary"
                        text
                        @click.native="$emit('cancel')"
                        :disabled="loading"
                >{{buttonNoText}}
                </v-btn>
                <v-btn
                        type="button"
                        color="red"
                        dark
                        @click.native="$emit('confirm')"
                        :loading="loading"
                        :disabled="loading"
                >{{buttonYesText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: {
            value: {
                tyep: Boolean,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: 'Ви впевнені що бажаєте змінити бали ECTS?'
            },
            message: {
                type: String,
                default: 'Це може призвести до непередбачених наслідків для оцінювання тем.'
            },
            buttonNoText: {
                type: String,
                default: 'Ні',
            },
            buttonYesText: {
                type: String,
                default: 'Так'
            }
        },
        computed: {
            confirmationModalComputed: {
                get() { return this.value},
                set(val) { this.$emit('input', val)}
            }
        },
    }
</script>