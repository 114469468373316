<template>
    <div>
        <v-card class="mb-5" v-if="!loading">
            <v-card-text>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів:</div>
                    <div class="title">{{totalRating.totalSum}}</div>
                </div>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів (згідно коефіцієнтів):</div>
                    <div class="title">{{totalRating.totalSumRatio}}</div>
                </div>
                <div class="d-flex align-center mt-2">
                    <v-btn v-if="!totalRating.isConfirmed" color="primary" @click="confirmDialog = true">Затвердити кафедральний звіт</v-btn>
                    <div v-if="totalRating.isConfirmed" class="title font-weight-light">
                        <v-chip
                                color="green"
                                text-color="white"
                        >
                            Затверджено завідувачем кафедрою
                        </v-chip></div>
                    <div v-if="totalRating.isConfirmed" class="title font-weight-light ml-12">
                        <v-btn @click="print()" color="primary" text><v-icon>print</v-icon>  Роздрукувати звіт</v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        
        <rating-directions 
                :rating-worktype-directions="ratingWorktypeDirections" 
                route-to-name="headDepartmentDepartmentRating" 
                :route-to-params="{department: $route.params.department}"
        ></rating-directions>

        <v-dialog v-model="confirmDialog" max-width="600">
            <approve-confirm-position
                    @shouldCloseConfirm="confirmDialog = false"
                    @shouldConfirm="confirmApprove()"
                    :loading="loading"
            >
            </approve-confirm-position>
        </v-dialog>

        <v-dialog v-model="printDialog" max-width="1200">
            <print-dept-rating-dialog :confirm-id="totalRating.id" @close="printDialog = false"></print-dept-rating-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import ApproveConfirmPosition from "./ApproveConfirmPosition";
    import RatingDirections from "../../rating/ratingDirections/RatingDirections";
    import PrintDeptRatingDialog from "./PrintDeptRatingDialog";

    export default {
        components: {
            ApproveConfirmPosition,
            PrintDeptRatingDialog,
            RatingDirections,
        },
        mounted() {
            if (this.$route.params.department) {
                this.getRatingSummary();
                this.getItems();   
            }
        },
        data: () => {
            return {
                loading: false,
                confirmDialog: false,
                printDialog: false,
                ratingWorktypeDirections: [],
                totalRating: {
                    id: null,
                    totalSum: 0,
                    totalSumRatio: 0,
                    isConfirmed: null,
                    confirmationDate: null,
                },
            }
        },
        methods: {
            getRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDepartmentRatingSummary/${this.$route.params.department}`)
                    .then(totalRating => {
                        this.totalRating = totalRating;

                        this.loading = false;
                    });
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDirectionsDepartmentWithSum/${this.$route.params.department}`)
                    .then(directions => {
                        this.ratingWorktypeDirections = directions;

                        this.loading = false;
                    })
            },
            confirmApprove() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`ratingUser/confirmDepartmentRatingSummary/${this.$route.params.department}`)
                    .then(_ => {
                        this.getRatingSummary();
                        
                        this.loading = false;
                        this.confirmDialog = false;
                    });
            },
            print() {
                this.printDialog = true;
            }
        },
    }
</script>
<style lang="scss">
    .header-wrapper {
        min-height: 90px;
    }
    
    .no-word-break {
        word-break: normal !important;
    }
</style>