import { render, staticRenderFns } from "./eAristoStatistics.vue?vue&type=template&id=bbe25c8a&scoped=true"
import script from "./eAristoStatistics.vue?vue&type=script&lang=js"
export * from "./eAristoStatistics.vue?vue&type=script&lang=js"
import style0 from "./eAristoStatistics.vue?vue&type=style&index=0&id=bbe25c8a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe25c8a",
  null
  
)

export default component.exports