<template>
    <v-navigation-drawer
            fixed
            :clipped="$vuetify.breakpoint.mdAndUp"
            app
            :value="drawer"
            @input="$emit('toggleDrawer', $event)"
            :width="300"
    >
            <v-list dense class="mt-4" expand>
                <navigation-list-template :items="studMenuItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
    </v-navigation-drawer>
</template>
<script>
    import HttpService from "../../../HttpService";
    import NavigationListTemplate from '../../common/NavigationListTemplate';
    import {mapMutations} from "vuex";
    import moment from "moment";
    import {rolesEnum} from "../../../constants/rolesEnum";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        mounted() {
           if (this.$auth.userHasAtLeastOneRole([rolesEnum.finalExam, rolesEnum.finalExamAdmin, rolesEnum.admin])) {
               this.getExamDates();
           }
           else {
               this.studMenuItems = this.remapItems(this.studMenuItemsRaw).filter(Boolean);
           }
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            remapItems(items) {
                
               return items.map(s => {
                   
                   if ((s.nameConst === 'adminSettings' || s.nameConst === 'adminFinalExamAdminList') 
                       && this.$auth.userHasAtLeastOneRole([rolesEnum.dean])) {
                       return;
                   }
                   
                   if (!this.$auth.userHasAtLeastOneRole(s.roles)) {
                       return;
                   }
                   
                   if (s.nameConst === "finalExamStudentDashboard") {
                       s.name = this.$t("finalExam.menuFinalExamStudentDashboard");
                   }
                   
                   if (s.nameConst === "finalExamResults") {
                       s.name = this.$t("finalExam.menuFinalExamResults");
                   }
                   
                    if (s.nameConst === "adminStudentExamList") {
                         s.children = this.dates.map(d => {
                            const dateReturn = {
                                name: moment(d).format("DD.MM.YYYY"),
                                route: {
                                    name: "adminStudentExamList",
                                    params: {
                                        date: moment(d).format("DD-MM-YYYY"),
                                    }
                                }
                            };

                            return dateReturn;
                        });
                         
                        return s; 
                    }
                    
                    if (s.nameConst === "adminSettings") {
                        s.children = [
                            {
                                name: "Налаштування дат",
                                route: {
                                    name: "adminSettingsDates",
                                }
                            },
                            {
                                name: "Налаштування екзаменаторів",
                                route: {
                                    name: "adminSettingsExaminers",
                                }
                            },
                            {
                                name: "Налаштування критеріїв",
                                route: {
                                    name: "adminSettingsCriterias",
                                }
                            },
                        ];
                    }
                    
                    return s;
                });
            },
            
            getExamDates() {
                HttpService.getInstance()
                .get("FinalExamAdmin/getFinalExamDates")
                .then(resp => {
                    this.dates = resp;

                    this.studMenuItems = this.remapItems(this.studMenuItemsRaw).filter(Boolean);
                })
            }
        },
        data: () => ({
            dates: [],
            
            studMenuItems: [],
            studMenuItemsRaw: [
                {roles: ["admin", "finalExamStudent"], icon: 'border_color', nameConst: 'finalExamStudentDashboard', 
                    route: {name: "finalExamStudentDashboard"}, name: 'Випускні іспити'},
                {roles: ["admin", "finalExamStudent"], icon: 'format_align_left', nameConst: 'finalExamResults', name: 'Результати іспитів', 
                    route: {name: "examResult"}},
                {roles: ["admin", "finalExamStudent"], icon: 'assessment', name: 'Результати КРОК', route: {name: "studentKrokResult"}},

                {roles: ["admin", "finalExamStudent"], divider: true},
                {roles: ["admin", "finalExam"], icon: 'insert_invitation', nameConst: 'calendar', name: 'Студенти, які складають іспит', 
                    route: {name: "studentExamList"}},
                {roles: ["admin", "finalExam"], icon: 'settings', nameConst: 'settings', name: 'Основні налаштування', 
                    route: {name: "teacherSettings"}},
                {roles: ["admin", "finalExam"], divider: true},
                {roles: ["admin", "finalExamAdmin"], icon: 'format_align_left', nameConst: 'adminStudentExamList', name: 'Студенти, які складають іспит (адмін)'},
                {roles: ["admin", "finalExamAdmin"], icon: 'list', nameConst: 'adminTeacherExamList', name: 'Екзаменатори', 
                    route: {name: "adminTeacherExamList"}},                
                {roles: ["admin", "finalExamAdmin"], icon: 'assignment_ind', nameConst: 'adminFinalExamAdminList', name: 'Голови, секретарі, декани', 
                    route: {name: "adminFinalExamAdminList"}},
                {roles: ["admin", "finalExamAdmin"], icon: 'settings', nameConst: 'adminSettings', name: 'Налаштування (адмін)'},
                {roles: ["admin", "finalExamAdmin"], icon: 'timeline', nameConst: 'finalExamKiosk', name: 'Kiosk', route: {name: "finalExamKiosk"}},
                {roles: ["admin", "finalExamAdmin"], icon: 'format_list_numbered', nameConst: 'finalExamProtocols', name: 'Протоколи', route: {name: "finalExamProtocols"}},
            ],
        })
    }
</script>