<template>
    <v-flex>
        <v-menu
                ref="menu"
                :close-on-content-click="false"
                v-model="menu"
                :nudge-right="40"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        v-on="on"
                        v-model="formattedDate"
                        :label="label"
                        :error-messages="errorMessages"
                        prepend-icon="event"
                        readonly
                        :disabled="disabled"
                ></v-text-field>
            </template>
            <v-date-picker
                    v-model="date"
                    @input="save"
                    :type="type"
                    :min="minDateLocal"
                    :max="maxDateLocal"
            ></v-date-picker>
        </v-menu>
    </v-flex>
</template>
<script>
    import moment from "moment";
    import {formatShortDate, formatMonthDate} from "../../helpers/dateHelper";

    export default {
        data: () => ({
            date: null,
            menu: false,
            minDateLocal: undefined,
            maxDateLocal: undefined
        }),
        props: {
            value: {
                type: [Date, String, Object],
            },
            errorMessages: {
                type: [Array, String],
                default: () => [],
            },
            label: {
                type: String,
                default: "Please, choose a date"
            },
            minDate: {
                type: [Date, String],
                default: undefined,
            },
            maxDate: {
                type: [Date, String],
                default: undefined,
            },
            ignoreTimezone: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "date"
            }
        },
        computed: {
            formattedDate: {
                get() {
                    if (this.date !== null && this.date !== undefined) {
                        
                        if (this.type === "date") {
                            return formatShortDate(this.date);
                        }
                        if (this.type === "month") {
                            return formatMonthDate(this.date);
                        }
                        
                        return "Cannot parse date";
                    }

                    return undefined;
                }
            }
        },
        mounted: function () {
            this.date = this.formatDate(this.value);
            this.minDateLocal = this.formatDate(this.minDate);
            this.maxDateLocal = this.formatDate(this.maxDate);
        },
        watch: {
            value: function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.date = this.formatDate(this.value);
                    this.minDateLocal = this.formatDate(this.minDate);
                    this.maxDateLocal = this.formatDate(this.maxDate);
                }
            }
        },
        methods: {
            formatDate(date) {
                if (date !== null && date !== undefined) {
                    const momentObj = moment(date);
                    let offsetInMinutes = momentObj.utcOffset();
                    const momentAndOffset = momentObj.utc().add(offsetInMinutes, 'm');
                    
                    return momentAndOffset.format("YYYY-MM-DD");
                }
            },
            save(date) {                
                this.$emit("input", moment.utc(date).toDate());
                this.$emit("change", moment.utc(date).toDate());
                this.$refs.menu.save(date)
            },
        }
    }
</script>