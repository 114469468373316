<template>
    <v-card style="margin-bottom: 20px;">
        <v-container grid-list-md text-center fill-height fluid>
            <v-layout row wrap fill-height>
                <v-flex xs8 class="text-sm-left">
                    <div class="subtitle-1">Вивантаження списку абітурієнтів з БД ЄДБО</div>
                    <div v-if="!fetchingInfo.notYetStarted" class="body2">
                        Останнє оновлення: {{formatLongDate(fetchingInfo.updatedAt)}}
                    </div>
                    <div v-if="!fetchingInfo.notYetStarted" class="body2">
                        Статус: {{getFetchingState(fetchingInfo.state)}}
                    </div>
                    <div v-if="!fetchingInfo.notYetStarted && fetchingInfo.state === edboFetchingStatusEnum.Failed"
                         class="body2 red--text">
                        Помилка: {{fetchingInfo.error}}</div>

                    <v-progress-linear v-if="fetchingInfo.state === edboFetchingStatusEnum.Fetching"
                                       v-model="progress"></v-progress-linear>
                </v-flex>
                <v-flex xs4>
                    <v-btn v-if="fetchingInfo.state !== edboFetchingStatusEnum.Fetching"
                           :disabled="loading"
                           color="info"
                           @click="startFetching()"
                    >
                        Запустити
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container grid-list-md text-center fill-height fluid>
            <v-layout row wrap fill-height>
                <v-flex xs8 class="text-sm-left">
                    <div class="subtitle-1">Вивантаження детальної інформації про абітурієнтів з БД ЄДБО</div>
                    <div v-if="!fetchingInfoDetail.notYetStarted" class="body2">
                        Останнє оновлення: {{formatLongDate(fetchingInfoDetail.updatedAt)}}
                    </div>
                    <div v-if="!fetchingInfoDetail.notYetStarted" class="body2">
                        Статус: {{getFetchingState(fetchingInfoDetail.state)}}
                    </div>
                    <div v-if="!fetchingInfoDetail.notYetStarted && fetchingInfoDetail.state === edboFetchingStatusEnum.Failed"
                         class="body2 red--text">
                        Помилка: {{fetchingInfoDetail.error}}</div>

                    <v-progress-linear v-if="fetchingInfoDetail.state === edboFetchingStatusEnum.Fetching"
                                       v-model="progressDetail"></v-progress-linear>
                </v-flex>
                <v-flex xs4>
                    <v-btn v-if="fetchingInfoDetail.state !== edboFetchingStatusEnum.Fetching"
                           :disabled="loadingDetail"
                           color="info"
                           @click="startDetailFetching()"
                    >
                        Запустити
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {edboFetchingTypeEnum} from "../../../../constants/_internal/edboFetchingTypeEnum";
    import {edboFetchingStatusEnum} from "../../../../constants/_internal/edboFetchingStatusEnum";
    import {formatLongDate} from "../../../../helpers/dateHelper";
    import SignalRConnection from "../../../../SignalRService";

    export default {
        created() {
            this.connection = SignalRConnection("applicantsFetchingProgress");
        },
        mounted() {
            this.connection.on("SetProgress", (user, progress) => {
                this.progress = progress;
            });

            this.connection.on("SetDetailsProgress", (user, progress) => {
                this.progressDetail = progress;
            });

            this.connection.on("SetOnFinish", () => {
                this.getFetchStateInfo();
            });

            this.connection.on("SetDetailsOnFinish", () => {
                this.getFetchDetailStateInfo();
            });

            this.getFetchStateInfo();
            this.getFetchDetailStateInfo();
        },
        methods: {
            getFetchingState(state) {
                switch (state) {
                    case edboFetchingStatusEnum.Succeed:
                        return "Успішно";
                    case edboFetchingStatusEnum.Fetching:
                        return "Вивантаження з ЄДБО...";
                    case edboFetchingStatusEnum.Failed:
                        return "Помилка";
                }
            },
            getFetchStateInfo() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`applicants/status/${edboFetchingTypeEnum.applicantList}`)
                    .then(resp => {
                        this.fetchingInfo = resp;
                        this.loading = false;
                    });
            },
            getFetchDetailStateInfo() {
                this.loadingDetail = true;

                HttpService.getInstance()
                    .get(`applicants/status/${edboFetchingTypeEnum.applicantDetail}`)
                    .then(resp => {
                        this.fetchingInfoDetail = resp;
                        this.loadingDetail = false;
                    });
            },
            startFetching() {
                this.loading = true;

                HttpService.getInstance()
                    .post("applicants")
                    .then(resp => {
                        this.fetchingInfo = resp;
                        this.loading = false;
                    });
            },
            startDetailFetching() {
                this.loadingDetail = true;

                HttpService.getInstance()
                    .post("applicants/details")
                    .then(resp => {
                        this.fetchingInfoDetail = resp;
                        this.loadingDetail = false;
                    });
            }
        },
        data: () => {
            return {
                formatLongDate,
                edboFetchingStatusEnum,

                connection: null,

                progress: 0,
                progressDetail: 0,

                loading: true,
                loadingDetail: true,

                fetchingInfo: {
                    createdAt: null,
                    error: null,
                    finishedAt: null,
                    notYetStarted: true,
                    state: null,
                    type: null,
                    updatedAt: null,
                },
                fetchingInfoDetail: {
                    createdAt: null,
                    error: null,
                    finishedAt: null,
                    notYetStarted: true,
                    state: null,
                    type: null,
                    updatedAt: null,
                }
            }
        }
    }
    
</script>