<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <academic-positions-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import AcademicPositionsIndex from "../../../../components/admin/Dictionaries/AcademicPositions/AcademicPositionsIndex";
    export default  {
        components: {
            AcademicPositionsIndex,
        }
    }
</script>