<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <admin-home-wrapper/>
        </v-layout>
    </v-container>
</template>
<script>
    import AdminHomeWrapper from "../../../components/admin/Home/AdminHomeWrapper";

    export default  {
        components: {
            AdminHomeWrapper,
        }
    }
</script>