<template>
    <v-card>
        <v-card-text>
            <v-container fluid fill-height>
                <v-layout row wrap justify-center>
                    <v-flex xs12>
                        <div class="d-flex">
                            <v-text-field large
                                          clearable
                                          autofocus
                                          :loading="loading"
                                          label="Прівище та/або ім'я студента"
                                          v-model="searchString"
                                          hide-details
                                          @keyup.enter="$emit('search', {searchString, academicGroup})"
                                          @click:clear="$emit('search', {searchString: '', academicGroup})"
                            />
                            <v-btn class="ml-5" @click="$emit('search', {searchString, academicGroup})" color="primary">Пошук</v-btn>
                        </div>
                    </v-flex>
                    
                    <v-flex xs12 class="mt-2">
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <span class="gray--text">Пошук за академічною групою</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <navigation-stud-nav-list :api-endpoint="getNavigationEndPoint()"
                                                              route-to=""
                                                              :additional-route-params="{}"
                                                              :withNavigation="false"
                                                              v-model="academicGroup"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    import NavigationStudNavList from "./NavigationStudNavList";
    import {rolesEnum} from "../../constants/rolesEnum";

    export default {
        components: {
            NavigationStudNavList,
        },
        props: {
            loading: {
                type: Boolean,
            }
        },
        data: () => ({
            searchString: "",
            academicGroup: null,
        }),
        methods: {
            getNavigationEndPoint() {
                if (this.userIsTeacher()) {
                    return `navigation/getAllGroupsOnDepartmentNavigation/${this.$route.params.department}`;
                } else if (this.userIsAdminOrEdudept()) {
                    return `navigation/getNavRegisters`;
                }

                return "";
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
        },
        watch: {
            academicGroup: {
                handler: function(newAcademicGroup, oldAcademicGroup) {
                    if (newAcademicGroup !== null) {
                        this.searchString = "";
                        this.$emit('search', {searchString: "", academicGroup: this.academicGroup})
                    }
                },
                deep: true,
            }
        }
    }
</script>