<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Навігація</v-subheader>
                            <navigation-nav-list-semesters route-to="adminSuccessRate" :both-semesters="true"></navigation-nav-list-semesters>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="!isSelectedSemester" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть факультет, спеціальність та курс
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <statistics-marks-by-faculty-year :key="$route.fullPath" v-if="isSelectedSemester"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationNavListSemesters from "@/components/common/left-navigation/NavigationNavListSemesters";
    import StatisticsMarksByFacultyYear from "@/components/dean/statsSuccessRate/StatisticsMarksByFacultyYear";

    export default {
        components: {
            NavigationNavListSemesters,
            StatisticsMarksByFacultyYear,
        },
        props: {
            routeTo: {
                type: String,
                default: "groupsGrid"
            },  
        },
        data: () => {
            return {
                isSelectedSemester: false,
            };
        },
        mounted() {
            this.isSelectedSemester = this.$route.params.semester !== undefined;
        },
        watch: {
            $route: function(route, prevRoute) {
                if (route.params.semester !== prevRoute.params.semester) {
                    this.isSelectedSemester = route.params.semester !== undefined;
                }
            },
        },
    }
</script>