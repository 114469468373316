<template>
    <v-container grid-list-md>
       <dean-admissions-student-wrapper/>
    </v-container>
</template>

<script>
    import DeanAdmissionsStudentWrapper from "../../components/dean/admissionsStudent/DeanAdmissionsStudentWrapper";
    
    export default {
        components: {
            DeanAdmissionsStudentWrapper
        },
    }
</script>
