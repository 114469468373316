<template>
    <span>
        <v-btn text icon color="green" v-if="getMarkAdmissionValidity(topicAbsence)">
            <v-icon large>local_activity</v-icon>
        </v-btn>

        <v-btn text icon color="orange" v-if="!getMarkAdmissionValidity(topicAbsence)">
            <v-icon large>local_activity</v-icon>
        </v-btn>

        <v-tooltip bottom v-if="topicAbsence.admissionType != null">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text icon color="red">
                        <v-icon>{{getTopicMarkAdmissionTypeIcon(topicAbsence)}}</v-icon>
                    </v-btn>
                </template>
            <span>{{getTopicMarkAdmissionTypeText(topicAbsence)}}</span>
        </v-tooltip>
    </span>
</template>
<script>
    import {
        getMarkAdmissionValidity,
        getTopicMarkAdmissionTypeIcon,
        getTopicMarkAdmissionTypeText
    } from "../../../helpers/topicAbsenceHelper";
    
    export default {
        props: {
            topicAbsence: Object,
        },
        data: () => ({
            getTopicMarkAdmissionTypeText,
            getTopicMarkAdmissionTypeIcon,
            getMarkAdmissionValidity
        }),
    }
</script>