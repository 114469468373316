<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{$t("studentKrok.problematicQuestions")}}</span>
        </v-card-title>
        <v-card-text>
            <v-container grid-list-md text-xs-center>
                <v-layout row wrap :key="index" v-for="(question, index) in questions">
                    <v-flex xs12>
                        <v-card>
                            <v-card-text>
                                <v-layout row wrap>
                                    <v-flex xs2>
                                        <div class="">{{$t("studentKrok.errorCount")}}</div>
                                        <div class="title">{{question.count}}</div>
                                    </v-flex>
                                    <v-flex xs10>
                                        <div class="text-xs-left">{{question.stepQuestion.question}}</div>
                                        <div class="mt-2">
                                            <div class="text-xs-left" :class="{'green--text': answer.isRight}"
                                                 :key="indexA"
                                                 v-for="(answer, indexA) in question.stepQuestion.stepQuestionAnswers">
                                                <v-icon v-if="answer.isRight">done</v-icon>
                                                <v-icon v-if="!answer.isRight">clear</v-icon>
                                                {{answer.answer}}
                                            </div>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
                <v-layout align-center justify-center v-if="loading">
                    <v-progress-circular
                            :size="150"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-layout>
                <v-layout v-if="questions.length === 0 && !loading">
                    <v-flex xs12>
                        {{$t("studentKrok.noProblematicQuestions")}}
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    flat
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >
                {{$t("studentKrok.close")}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                questions: [],
                loading: false,
            }
        },
        mounted() {
            this.loading = true;

            HttpService.getInstance()
                .get(`studentStep/frequentlyFailedQuestions/${this.$route.params.subject}`)
                .then(resp => {
                    this.questions = resp;
                    this.loading = false;
                });
        },

    }
</script>