<template>
    <v-container grid-list-md text-center>
        <v-layout row wrap justify-center>
            <v-flex xs12 md6>
                <student-settings v-if="userIsStudent()"/>
                <professor-settings v-if="userIsProfessor()"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import StudentSettings from "./StudentSettings";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import ProfessorSettings from "./ProfessorSettings";

    export default {
        components: {
            StudentSettings,
            ProfessorSettings,
        },
        data: () => ({}),
        methods: {
            userIsStudent() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.student]);
            },
            userIsProfessor() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor, rolesEnum.admin]);
            },
        }
    }
</script>