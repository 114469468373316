<template>
    <v-container grid-list-md>
    <statistics-header :date-from="dateFrom" :date-to="dateTo" @filter="onFilter($event)"/> 
    <line-chart :chart-data="datacollection" :options="options"></line-chart>
        <button @click="fillData()">Randomize data</button>
    </v-container>
</template>

<script>
    import LineChart from '../../../common/statistics/LineChart';
    import StatisticsHeader from "../../../common/statistics/StatisticsHeader";
    import moment from "moment";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
        },
        data () {
            return {
                dateFrom: moment(new Date()).subtract(6, "months").startOf("month").toDate(),
                dateTo: moment(new Date()).endOf("month").toDate(),
                
                datacollection: null,
                options: {
                    fill: false,
                    responsive: true,
                    maintainAspectRatio: false,
                },
            }
        },
        mounted () {
            this.fillData()
        },
        methods: {
            onFilter({dateFrom, dateTo}) {
                this.dateFrom = dateFrom;
                this.dateTo = dateTo;
            },
            fillData () {
                this.datacollection = {
                    labels: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#3677f8',
                            borderColor: '#3677f8',
                            fill: false,
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }, {
                            label: 'Data One',
                            backgroundColor: '#f89662',
                            borderColor: '#f89662',
                            fill: false,
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }
                    ]
                }
            },
            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }
        }
    }
</script>