<template>
    <div>
        <v-progress-linear
            v-if="loadingState > 0"
            height="3"
            style="margin: 0; z-index: 999; position: absolute"
            :indeterminate="true"
            color="info"
        ></v-progress-linear>

        <v-app-bar
            :color="color"
            dark
            app
            height="50px"
            :clipped-left="$vuetify.breakpoint.mdAndUp"
            fixed
        >
            <v-toolbar-title style="width: 250px" class="ml-0 pl-0 cursor-pointer" @click="navigateToHome()">
                <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')"></v-app-bar-nav-icon>
                <toolbar-logo></toolbar-logo>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-title>{{ getTopNavigationTitle }}</v-toolbar-title>

            <v-spacer></v-spacer>


            <quick-navigation/>
            <logout-button/>
        </v-app-bar>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import HttpService from "../../../HttpService";
import QuickNavigation from "@/components/common/toolbar-controls/QuickNavigation";
import LogoutButton from "@/components/common/toolbar-controls/LogoutButton";
import ToolbarLogo from "@/components/common/ToolbarLogo";

export default {
    name: 'group-navigation-tour',
    props: {
        color: {
            type: String,
            default: 'info'
        }
    },
    components: {
        QuickNavigation,
        LogoutButton,
        ToolbarLogo
    },
    computed: {
        ...mapGetters(['selectedNavigationItem', 'getTopNavigationTitle']),
        loadingState() {
            return this.$store.getters['loadingState/loadingState'];
        },
    },
    methods: {
        onStopCallback() {
            this.$cookie.set("teacherGroupSelectTour", true, 30);
        },
        isRouteWithAdditionalNav() {
            return true;
            // return this.$route.params.group !== undefined;
        },
        logout() {
            HttpService.getInstance()
                .post('/authorization/revoke', {token: this.$auth.getRefreshToken()})
                .then(() => {
                    this.$auth.logout();
                    this.$router.push({name: 'login'});
                });
        },
        goToMainDashboard() {
            window.location.href = this.$config.mainDashboardUrl;
        },
        navigateToHome() {
            this.$router.push('/')
                .catch(err => {});
        }
    },
}
</script>