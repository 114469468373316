<template>
    <div class="ma-5">
        <div class="display-1 mb-3">Налаштування</div>
        <v-card class="px-4 py-2 mb-2">
            <v-layout wrap>
                <v-flex xs6>
                    <v-text-field
                            v-model="meetLink"
                            label="Посилання Google Meet"
                            placeholder="Посилання Google Meet"
                            filled
                    ></v-text-field>
                </v-flex>
            </v-layout>
            <v-btn :disabled="isLoading" @click="saveSettings()" color="primary" large>Зберегти</v-btn>
        </v-card>
    </div>
</template>

<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            meetLink: "",
            isLoading: false,
        }),
        mounted() {
            HttpService.getInstance()
                .get("FinalExamTeacher/getSettings")
                .then(settings => {
                    this.meetLink = settings.meetLink;
                });
        },
        methods: {
            saveSettings() {
                this.isLoading = true;

                HttpService.getInstance()
                    .post("FinalExamTeacher/saveSettings", {meetLink: this.meetLink})
                    .then(() => {
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.meetLink = "";
                    })
            }
        }
    }
</script>