<template>
    <v-list dense>
        <template v-if="!loading" v-for="studNav in studNavigationItems">
            <v-subheader class="mt-3 grey--text text--darken-1">{{studNav.name}}</v-subheader>
            <v-list-tile v-if="studNav.specialities.length === 0">
                <span class="grey--text text--lighten-1 ml-2"><i>Немає даних</i></span>
            </v-list-tile>
            <navigation-list-template :items="studNav.specialities"/>
        </template>
        <template v-if="loading">
            <v-container v-if="loading" fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                            :size="150"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-container>
        </template>
        <template v-if="studNavigationItems.length === 0 && !loading">
            <v-list-tile>
                <span class="grey--text text--lighten-1"><i>Немає груп</i></span>
            </v-list-tile>
        </template>
    </v-list>
</template>
<script>
    import NavigationListTemplate from '../common/NavigationListTemplate';
    import HttpService from "../../HttpService";
    import {mapMutations} from "vuex";

    export default {
        components: {
            'navigation-list-template': NavigationListTemplate,
        },
        data: () => ({
            studNavigationItems: [],
            loading: false,
        }),
        mounted() {
            this.loading = true;

            this.getMenuItems();            
        },
        computed: {
            apiUrl: function () {
                if (this.stepNavigationApiUrl != null) {
                    return this.stepNavigationApiUrl;
                }
                return `navigation/getAdminStepNavigation/${this.$route.params.subject}`;
            }
        },
        props: {
            routeTo: {
                type: String,
                default: "registers"
            },
            additionalRouteParams: {
                type: Object,
                default: () => {
                }
            },
            stepNavigationApiUrl: {
                type: String,
                default: null
            }
        },
        watch: {
            "$route.params.subject": function (newSubject, oldSubject) {
                if (newSubject !== oldSubject) {
                    this.getMenuItems();
                }
            },
            "stepNavigationApiUrl": function (newUrl, oldUrl) {
                if (newUrl !== oldUrl) {
                    this.getMenuItems();
                }
            }
        },
        methods: {
            getMenuItems() {
                HttpService.getInstance()
                    .get(this.apiUrl)
                    .then(navItems => {
                        this.studNavigationItems = this.remapStudNavItems(navItems);
                        this.loading = false;
                    })
            },
            remapStudNavItems(studNavigationItems) {
                if (studNavigationItems === undefined) {
                    return [];
                }

                let studNavigationItemsRemapped = studNavigationItems.slice(0);

                studNavigationItemsRemapped.map(fac => {
                    fac.name = fac.nameUk;
                    fac.specialities.map(spec => {
                        
                        spec.name = spec.nameUk;
                        
                        spec.children = spec.semesters.map(sem => {
                            sem.name = `${sem.yearNumber} курс (${sem.semesterNumber} семестр)`;

                            sem.route = {
                                name: this.routeTo,
                                params: {
                                    ...{
                                        faculty: fac.id,
                                        speciality: spec.id,
                                        semester: sem.id,
                                    },
                                    ...this.additionalRouteParams
                                }
                            };

                            return sem;
                        });

                        let someSemesterActive = spec && spec.children.find(ch => ch && ch.model);

                        if (spec.id.toString() === this.$route.params.speciality && someSemesterActive !== undefined) {
                            spec.model = true;
                        }

                        delete spec.semesters;
                        spec.children = spec.children.filter(ch => ch !== undefined);

                        if (spec.children.length === 0) {
                            return undefined;
                        }

                        return spec;
                    });

                    if (fac.specialities.length === 0) {
                        return undefined;
                    }

                    return fac;
                });

                return studNavigationItemsRemapped;
            },
        },
    }
</script>