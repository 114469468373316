<template>
    <v-card>
        <v-card-title class="title">Видалення</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете видалити?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    text
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            deleteId: Number,
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .delete(`questionPool/${this.deleteId}`)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("removed");
                    });
            }
        }
    }
</script>