<template>
    <div>
        <div v-if="!mark"></div>
        <div v-else-if="mark.value === 0">{{$t('student.abs')}}</div>
        <div v-else-if="mark.value === 1">
            <v-icon color="green" class="non-evaluable-mark">check_circle_outline</v-icon>
        </div>
        <div v-else>{{mark.value}}</div>
    </div>
</template>
<script>
    export default {
        props: {
            mark: {
                type: Object,
                default: () => {
                    return {value: null}
                }
            }
        }
    }
</script>
<style lang="scss">
    .non-evaluable-mark {
        line-height: 10px !important;
    }
</style>