<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.speciality="{item}">{{ item.speciality.nameUk }}</template>
        <template v-slot:item.isDefault="{item}">
            <v-icon v-if="!item.isDefault">radio_button_unchecked</v-icon>
            <v-icon v-if="item.isDefault">radio_button_checked</v-icon>
        </template>
        <template v-slot:item.actions="{item}">
            <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                <v-icon color="primary">edit</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                <v-icon color="red">delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    import finalControlTypes from "../../../../constants/admin/finalControlTypes";
    
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва', value: 'name'},
                {text: 'Спеціальність', value: 'speciality'},
                {text: 'За замовчуванням', value: 'isDefault'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>