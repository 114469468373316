import ChiefDeptRootView from '../views/chiefDept/ChiefDeptRootView';
import ChiefDeptHomeView from "../views/chiefDept/ChiefDeptHomeView";

import ChiefDeptSuperAdminPreSelect from "../views/chiefDept/ChiefDeptSuperAdminPreSelect";
import EmptyView from "../views/common/EmptyView";
import ChiefDeptRegistersView from "../views/chiefDept/Register/ChiefDeptRegistersView";
import ChiefDeptRegisterRootView from "../views/chiefDept/Register/ChiefDeptRegisterRootView";
import ChiefDeptRegisterDeepRootView from "../views/chiefDept/ChiefDeptRegisterDeepRootView";
import ReworksRootView from "../views/common/ReworksRootView";
import {reworksViewMode} from "../constants/_internal/reworksViewMode";
import routeGuardHeadDept from "../routeGuards/routeGuardHeadDept";
import ChiefDeptRatingView from "../views/chiefDept/rating/ChiefDeptRatingView";
import ChiefDeptRatingDeepRootView from "../views/chiefDept/rating/ChiefDeptRatingDeepRootView";
import ChiefDeptRatingRootView from "../views/chiefDept/rating/ChiefDeptRatingRootView";
import routeGuardRating from "../routeGuards/routeGuardRating";
import ChiefDeptRatingUserDirectionsIndexRootView
    from "../views/chiefDept/rating/ChiefDeptRatingUserDirectionsIndexRootView";
import RatingAllRootView from "../views/rating/RatingAllRootView";
import ChiefDeptRatingAllRootView from "../views/chiefDept/rating/ChiefDeptRatingAllRootView";
import ChiefDeptRatingDirectionsIndexRootView from "../views/chiefDept/rating/ChiefDeptRatingDirectionsIndexRootView";

export default (router) => {
 return [
     {
         path: '/chiefdept',
         component: ChiefDeptRootView,
         name: 'chiefdeptRoot',
         redirect: {name: 'chiefdeptSuperAdminPreselect'},
         beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
         children: [
             {
                 path: 'chiefdept-sa-preselect',
                 name: "chiefdeptSuperAdminPreselect",
                 component: ChiefDeptSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
             {
                 path: '',
                 name: 'chiefdeptHome',
                 component: ChiefDeptHomeView,
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
             {
                 path: '/attestation',
                 name: 'chiefDeptAttestation',
                 component: EmptyView,
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
             {
                 path: 'reworksModules/:department?/:subject?/:professor?',
                 name: "headDeptReworksModulesRoot",
                 component: ReworksRootView,
                 props: { mode: reworksViewMode.reworkModuleHeadept },
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
             {
                 path: 'headDepartmentRegister',
                 component: EmptyView,
                 children: [
                     {
                         path: '/headDepartmentRegisters/:department?/:subject?',
                         name: 'headDeptRegistersRoot',
                         component: ChiefDeptRegistersView,
                         props: {routeTo: "headDepartmentRegisters"}
                     }
                 ]
             },
             {
                 path: '/chiefdept/headDepartmentRating',
                 component: EmptyView,
                 children: [
                     {
                         path: '/chiefdept/headDepartmentRating/:department?',
                         name: 'headDeptRatingRoot',
                         component: ChiefDeptRatingView,
                         props: {routeTo: "headDepartmentRating"}
                     }
                 ]
             },
             // Department rating
             {
                 path: '/chiefdept/departmentRating/:department?',
                 name: "currentDepartmentRating",
                 component: ChiefDeptRatingAllRootView,
                 beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
             },
             {
                 path: '/chiefdept/departmentRating/:department?/:professor?/ratingIndexes/:direction?',
                 name: "headDepartmentDepartmentRating",
                 component: ChiefDeptRatingUserDirectionsIndexRootView,
                 beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
             },
         ],
     },
     {
         path: '/chiefdept/registers/:department?/:subjectSel?/:faculty?/:speciality?/:year?/:group?/:subject?',
         component: ChiefDeptRegisterDeepRootView,
         name: 'headDepartmentRegistersFullRoot',
         redirect: {name: 'headDepartmentRegisters'},
         children: [
             {
                 path: '/chiefdept/registers/:department?/:subjectSel?/:faculty?/:speciality?/:year?/:group?/:subject?',
                 name: "headDepartmentRegisters",
                 component: ChiefDeptRegisterRootView,
                 props: {routeTo: "headDepartmentRegisters"},
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
         ]
     },
     {
         path: '/chiefdept/headDepartmentUserRating/:department?/:professor?',
         component: ChiefDeptRatingDeepRootView,
         name: 'headDepartmentRatingFullRoot',
         redirect: {name: 'headDepartmentRegisters'},
         children: [
             {
                 path: '/chiefdept/headDepartmentUserRating/:department?/:professor?',
                 name: "headDepartmentRating",
                 component: ChiefDeptRatingRootView,
                 props: {routeTo: "headDepartmentRating"},
                 beforeEnter: (to, from, next) => routeGuardHeadDept(to, from, next)(router),
             },
             {
                 path: '/chiefdept/headDepartmentUserRating/:department?/:professor?/ratingIndexes/:direction?',
                 name: "headDepartmentRatingWorkTypes",
                 component: ChiefDeptRatingDirectionsIndexRootView,
                 beforeEnter: (to, from, next) => routeGuardRating(to, from, next)(router),
             },
         ]
     },
 ];
}