<template>
    <v-card>
        <v-card-title class="title">Блокування</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете заблокувати даний аккаунт?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseBlockConfirm')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="blockItem"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
            };
        },
        props: {
            blockId: Number,
        },
        methods: {
            blockItem() {
                this.loading = true;

                HttpService.getInstance()
                    .delete(`userManagement/${this.blockId}`)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("blocked");
                    });
            }
        }
    }
</script>