<template>
            <v-flex>
                <rating-check-department-select-index></rating-check-department-select-index>
            </v-flex>
       
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import RatingCheckDepartmentSelectIndex from "../../components/ratingAdmin/ratingCheck/RatingCheckDepartmentSelectIndex";

    export default {
        components: {
            NavigationListTemplate,
            RatingCheckDepartmentSelectIndex,
        },
        data: () => ({}),
        mounted() {
        },
        methods: {
            showModuleBlock() {
                return this.$route.params.module !== undefined;
            },

        },
    }
</script>