<template>
    <v-card>
        <v-card-title class="title">Видача допусків</v-card-title>
        <v-card-text>
            <div style="border-bottom: 1px solid #ccc;">Ви впевнені, що хочете видати вибрані допуски ({{admissions.length}})?</div>
            <v-layout class="mb-2 mt-6" 
                      :key="index" 
                      align-center wrap v-for="(admission, index) in admissionsSelected">
                <v-flex xs3>{{admission.topic.nameUk}}</v-flex>
                <v-flex xs2 class="text-center title font-weight-medium">
                    {{formatShortDate(admission.classDate)}}
                </v-flex>
                <v-flex xs3>
                    <v-select
                            solo
                            light
                            :items="topicMarkAdmissionTypesList"
                            v-model="admission.admissionType"
                            item-text="nameUk"
                            item-value="id"
                            label="Тип допуску"
                            hide-details
                    ></v-select>
                </v-flex>
                <v-flex xs3 class="ml-4">
                    <date-picker
                            :min-date="new Date()"
                            :error-messages="getAdmissionError(index, 'admissionEnd')"
                            v-model="admission.admissionEnd"
                            disabled
                            label="Завершення дії допуску"
                    ></date-picker>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseSaveConfirm')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="saveAdmissions"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import topicMarkAdmissionTypes, {topicMarkAdmissionTypesEnum} from "../../../constants/topicMarkAdmissionTypes";
    import DatePicker from "../DatePicker";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import moment from "moment";

    export default {
        data: () => {
            return {
                loading: false,
                typeItems: [],
                formatShortDate,
                topicMarkAdmissionTypes,
                topicMarkAdmissionTypesEnum,
                errors: [],
                topicMarkAdmissionTypesList: [],

                reworkIntervalDays: 0,
                deanEmitsPayableAdmission: false,
            };
        },
        components: {
          DatePicker,  
        },
        props: {
            admissions: Array,
        },
        computed: {
            admissionsSelected() {
                return this.admissions.map(a => {
                    a.admissionType = this.deanEmitsPayableAdmission ? topicMarkAdmissionTypesEnum.noValidReason : topicMarkAdmissionTypesEnum.validReason;
                    a.admissionEnd = moment().add(this.reworkIntervalDays, "days").toDate();
                    
                    return a;
                });
            }
        },
        mounted() {
            HttpService.getInstance()
            .get(`dean/getReworkAdmissionsSettings`)
            .then(resp => {
                this.reworkIntervalDays = resp.reworkIntervalDays;
                this.deanEmitsPayableAdmission = resp.deanEmitsPayableAdmission;

                if (!this.deanEmitsPayableAdmission) {
                    this.topicMarkAdmissionTypesList = this.topicMarkAdmissionTypes.filter(t => t.id !== topicMarkAdmissionTypesEnum.noValidReason);
                }
                else {
                    this.topicMarkAdmissionTypesList = this.topicMarkAdmissionTypes;
                }
            });
        },
        methods: {
            getAdmissionError(index, fieldName) {
                const indexField = this.errors[`reworkAdmissions[${index}]`];
                
                if (indexField) {
                    return this.errors[`reworkAdmissions[${index}]`][fieldName];
                }
                
                return [];
            },
            saveAdmissions() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`dean/saveAdmissions`, {reworkAdmissions: this.admissionsSelected})
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    })
                    .catch(err => {
                        this.loading = false;
                        
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>