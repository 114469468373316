export default {
    default: {
        oidc: {
            clientId: "eplatoCoreApp",
            responseType: "id_token token",
            scope: "openid profile email ePlatoMain",
            automaticSilentRenew: true,
            loadUserInfo: true,
            revokeAccessTokenOnSignout: true,
        },
        GA: {
            id: ""
        },
        SentryDSN: "",
    },
    development: {
        oidc: {
            authority: "http://localhost:5010",
            redirectUri: "http://localhost:8888/auth/callback",
            postLogoutRedirectUri: "http://localhost:8888/unauthorized",
            silentRedirectUri: "http://localhost:8888/auth/silentcallback"
        },
        apiEndpoint: "http://localhost:5000/api",
        GA: {id: ""},
        SentryDSN: "https://88a634f053d04840b1909065b2581240@sentry.io/1295176",
    },
    staging: {
        oidc: {
            authority: "https://auth-stg.pdmu.edu.ua",
            redirectUri: "https://ez-stg.pdmu.edu.ua/auth/callback",
            postLogoutRedirectUri: "https://ez-stg.pdmu.edu.ua/unauthorized",
            silentRedirectUri: "https://ez-stg.pdmu.edu.ua/auth/silentcallback"
        },
        apiEndpoint: "https://ezapi-stg.pdmu.edu.ua/api",
        GA: {id: "UA-227376017-1"},
        SentryDSN: "https://88a634f053d04840b1909065b2581240@sentry.io/1295176",
    },
    production: {
        oidc: {
            authority: "https://auth.pdmu.edu.ua",
            redirectUri: "https://ez.pdmu.edu.ua/auth/callback",
            postLogoutRedirectUri: "https://ez.pdmu.edu.ua/unauthorized",
            silentRedirectUri: "https://ez.pdmu.edu.ua/auth/silentcallback"
        },
        apiEndpoint: "https://ezapi.pdmu.edu.ua/api",
        GA: {id: "UA-227376017-1"},
        SentryDSN: "https://103ef1b4af594cb8b512e74043a8d954@sentry.io/5182867",
    },
}