<template>
    <v-card>
        <v-card-title class="title">Видалення осиротілих відео</v-card-title>
        <v-card-text>
            <v-simple-table v-if="!loading">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left" style="width: 800px" width="800px">
                            Назва файлу
                        </th>
                        <th class="text-left" style="width: 100px" width="100px">
                            Розмір
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="file in files">
                        <td>{{file.fileName}}</td>
                        <td>{{(file.contentLength / 1024 / 1024).toFixed(2)}} Mb</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            
            <v-container v-if="loading" fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingDelete"
            >Ні, не видаляти
            </v-btn> 
            <v-btn
                    text
                    color="red"
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete || loading"
            >Так, видалити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
                loadingDelete: false,
                files: [],
            };
        },
        mounted() {
            this.getFiles();
        },
        methods: {
            getFiles() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`systemSettings/getVideoFiles`)
                    .then(resp => {
                        this.files = resp;
                        
                        this.loading = false;
                    });

            },
            removeItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .post(`systemSettings/clearVideoFiles`, this.files)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("shouldCloseDeleteConfirm");
                    });
            }
        }
    }
</script>