<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex>
                <v-card>
                    <v-container grid-list-md>
                        <e-aristo-statistics></e-aristo-statistics>
<!--                        <v-layout wrap>-->
<!--                            <v-flex xs12>-->
<!--                                <div class="headline">-->
<!--                                    <v-icon>keyboard_backspace</v-icon>-->
<!--                                    Виберіть кафедру-->
<!--                                </div>-->
<!--                            </v-flex>-->
<!--                        </v-layout>-->
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import EAristoStatistics from "@/components/admin/eAristoStatistics/eAristoStatistics.vue";

export default {
    components: {
        EAristoStatistics,
    },
    props: {
        routeTo: {
            type: String,
            default: "groupsGrid"
        },
    },
    // data: () => {
    //     return {
    //         isSelectedDepartment: false,
    //         departments: [],
    //     };
    // },
    // mounted() {
    //     HttpService.getInstance()
    //         .get("departments/getAll")
    //         .then(res => {
    //             this.departments = res.map(department => {
    //                 department.name = department.nameUk;
    //                 department.route = {
    //                     name: 'adminTimeAccounting',
    //                     params: {
    //                         department: department.id,
    //                     }
    //                 };
    //
    //                 return department;
    //             });
    //         });
    //
    //     this.isSelectedDepartment = this.$route.params.department !== undefined;
    // },
}
</script>