import '@babel/polyfill';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuex from 'vuex'

import './styles/progress-bar.scss';

import 'vuetify/dist/vuetify.min.css';
import './styles/main.scss';

import Auth from "./auth/auth-oidc";
import ConfigFactory from "./config/configFactory";
import config from "./app.config";
import VueCookie from "vue-cookie";
import VueI18n from 'vue-i18n';
import wysiwyg from "vue-wysiwyg";

import localeEn from "./i18n/locale-en";
import localeUa from "./i18n/locale-uk";

import "vue-wysiwyg/dist/vueWysiwyg.css";
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueTheMask from 'vue-the-mask';
import VueAnalytics from 'vue-analytics';
import {AppConfig} from "./config/configFactory";
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import vueHeadful from 'vue-headful';
import { VueMaskDirective } from 'v-mask';
import VueFileAgent from 'vue-file-agent';
import { ProgressPlugin } from 'bootstrap-vue';
import VueCountdown from '@chenfengyuan/vue-countdown';

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(ProgressPlugin);

Vue.use(VueFileAgent);

Vue.directive('mask', VueMaskDirective);

Vue.component('vue-headful', vueHeadful);

Vue.component(VueCountdown.name, VueCountdown);
Vue.use(ConfigFactory, config);
Vue.use(Vuex);
Vue.use(VueCookie);
Vue.use(VueTheMask);
Vue.use(Auth);
Vue.use(VueI18n);

Vue.use(VuePlyr, {
    plyr: {}
})

Vue.use(wysiwyg, {
    image: {
        dropzoneOptions: {
            method: "POST",
            headers: {
                "Cache-Control": "",
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
            }
        }
    }
});


let configuration = AppConfig(config);

Vue.use(VueAnalytics, {
    id: configuration.GA.id,
    router,
});

// Sentry.init({
//     dsn: configuration.SentryDSN,
//     integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
// });

const i18n = new VueI18n({
    locale: 'uk-UA',
    fallbackLocale: 'en-US',
    messages: {
        "en-US": localeEn,
        "uk-UA": localeUa
    },
});

Vue.config.productionTip = false;

export const vueInstance = new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
