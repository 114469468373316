<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex v-if="!loading">
                <div class="display-1 mb-4">{{$t("finalExam.resultKrokExam")}}</div>
                <v-card class="mb-3 px-4 py-2">
                        <v-layout wrap v-if="krokResult.result !== null">
                            <v-flex xs12 class="align-center">
                                <div>
                                    <v-layout wrap class="d-flex justify-center">
                                        <v-flex xs12 md6>
                                            <v-alert type="success" v-if="krokResult.isPassed === true">
                                                Вітаємо з успішним складанням!
                                            </v-alert>

                                            <v-alert type="error" v-if="krokResult.isPassed === false">
                                                На жаль, Вам не вдалося скласти успішно...
                                            </v-alert>

                                            <v-alert type="warning" v-if="krokResult.minPercentage === krokResult.result">
                                                Хух... Ну тобі й повезло...! ;)
                                            </v-alert>
                                        </v-flex>
                                    </v-layout>
                                </div>
                                <div class="text-h5 text-center">Ваш результат:</div>
                                <div class="text-h4 text-center mt-4">{{krokResult.result}}%</div>
                                <div class="text-title text-center mt-3">
                                    <div>Критерій "склав": <strong>{{krokResult.minPercentage}}%</strong></div>
                                    <div>Позиція Вашого результату в загальному рейтингу по спеціальності: <strong>{{krokResult.resultPosition}}</strong></div>
                                    <div>Аналогічні результати ({{krokResult.result}}%): <strong>{{krokResult.analogicResults}}</strong></div>
                                    <div>Ваш результат кращий ніж у <strong>{{krokResult.betterThanPercentage.toFixed(2)}}%</strong></div>
                                </div>
                            </v-flex>
                        </v-layout>

                    <v-layout wrap v-if="krokResult.result === null">
                        <v-flex xs12 class="align-center">
                            <div>
                                <v-layout wrap class="d-flex justify-center">
                                    <v-flex xs12 md6>
                                        <v-alert type="info">
                                            Очікуйте на результати від Центру Тестування!
                                        </v-alert>
                                    </v-flex>
                                </v-layout>
                            </div>
                            <div>
                                <div v-if="krokResult.code !== null" class="title d-flex justify-center">Ваш код ЦТ: {{krokResult.code}}</div>
                                <v-layout v-if="krokResult.code === null" wrap class="d-flex justify-center">
                                    <v-flex xs12 md6>
                                        <v-alert type="warning">
                                            Вас немає в списку отримувачів результату складання КРОК. Вам необхідно звернутися у деканат для отримання результату.
                                        </v-alert>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            
            <v-flex v-if="loading">
                <div class="d-flex justify-center align-center">
                    <v-progress-circular color="primary" indeterminate size="150"></v-progress-circular>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            krokResult: {
                code: null,
                result: null,
                isPassed: null,
                resultPosition: null,
                analogicResults: null,
                minPercentage: null,
                betterThanPercentage: null,
            },
            loading: false,
        }),
        mounted() {
            this.$ga.event('student', 'view_krok_result', 'Krok result');                

            this.getKrokResult();
        },
        methods: {
            getKrokResult() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get("KrokResult/studentKrokResult")
                    .then(resp => {
                        this.krokResult = resp;

                        this.loading = false;
                    })
            },
        }
    }
</script>