export const moduleMarkStatusesEnum = {
    absence: 0, // не з'явився
    notAllowed: 1, // не допущений (є 2-ки)
    notCompleted: 2, // не виконав (є нб)
    notPassed: 3, // не склав
    passed: 4
};

export default [
    {
        id: moduleMarkStatusesEnum.absence,
        nameUk: "Не зв'явився",
        nameEn: "Absence",
        icon: 'gps_off'
    },
    {
        id: moduleMarkStatusesEnum.notAllowed,
        nameUk: "Не допущений", // (є 2-ки)
        nameEn: "Not allowed", // (є 2-ки)
        icon: 'not_interested'
    },
    {
        id: moduleMarkStatusesEnum.notCompleted,
        nameUk: "Не виконав", // (є нб)
        nameEn: "Not completed", // (є нб)
        icon: 'layers_clear'
    },
    {
        id: moduleMarkStatusesEnum.notPassed,
        nameUk: "Не склав",
        nameEn: "Not passed",
        icon: 'link_off'
    },
    {
        id: moduleMarkStatusesEnum.passed,
        nameUk: "Cклав", 
        nameEn: "Passed",
        icon: 'done'
    },
];
