<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Кафедри</v-subheader>
                            <navigation-list-template :items="departments"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="!isSelectedDepartment" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть кафедру
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <moderator-accountance-time :key="$route.fullPath" v-if="isSelectedDepartment"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import NavigationListTemplate from "@/components/common/NavigationListTemplate";
import HttpService from "@/HttpService";
import ModeratorAccountanceTime from "@/components/moderator/moderatorAccountanceTime/ModeratorAccountanceTime";

export default {
    components: {
        NavigationListTemplate,
        ModeratorAccountanceTime,
    },
    props: {
        routeTo: {
            type: String,
            default: "groupsGrid"
        },
    },
    data: () => {
        return {
            isSelectedDepartment: false,
            departments: [],
        };
    },
    mounted() {
        HttpService.getInstance()
            .get("departments/getAll")
            .then(res => {
                this.departments = res.map(department => {
                    department.name = department.nameUk;
                    department.route = {
                        name: 'adminTimeAccounting',
                        params: {
                            department: department.id,
                        }
                    };

                    return department;
                });
            });

        this.isSelectedDepartment = this.$route.params.department !== undefined;
    },
    watch: {
        $route: function(route, prevRoute) {
            if (route.params.department !== prevRoute.params.department) {
                this.isSelectedDepartment = route.params.department !== undefined;
            }
        },
    },
}
</script>