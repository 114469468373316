<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5 mb-2">Статистика по факультетах</h2>
        <div class="caption mb-2">По замовчуванню, відображаються дані за останній календарний міяць (-30 днів від сьогоднішньої дати)</div>
        <v-data-table
            :headers="headers"
            :items="data"
            :loading="loadingFaculties"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.countAll="{item}">
                {{item.countAll}}
            </template>

            <template v-slot:item.countStudentAccount="{item}">
                {{item.countStudentAccountPercentage.toFixed(2)}}% ({{item.countStudentAccount}})
            </template>

            <template v-slot:item.countDeanAll="{item}">
                {{item.countDeanAllPercentage.toFixed(2)}}% ({{item.countDeanAll}})
            </template>

            <template v-slot:item.countDeanFreeAdmissionPayableSubject="{item}">
                {{item.countDeanFreeAdmissionPayableSubjectPercentage.toFixed(2)}}% ({{item.countDeanFreeAdmissionPayableSubject}})
            </template>

            <template v-slot:item.countOverdueDeanFreeAdmissionPayableSubject="{item}">
                {{item.countOverdueDeanFreeAdmissionPayableSubjectPercentage.toFixed(2)}}% ({{item.countOverdueDeanFreeAdmissionPayableSubject}})
            </template>

            <template v-slot:item.countDeanFreeAdmissionFreeAdmissionSubject="{item}">
                {{item.countDeanFreeAdmissionFreeAdmissionSubjectPercentage.toFixed(2)}}% ({{item.countDeanFreeAdmissionFreeAdmissionSubject}})
            </template>
            
        </v-data-table>
    </v-container>
</template>

<script>
    import StatisticsHeader from "../../common/statistics/StatisticsHeader";
    import HttpService from "../../../HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                headers: [
                    {text: 'Факультет', value: 'faculty.nameUk'},
                    {text: '∑', value: 'countAll'},
                    {text: '∑ кабінет студента', value: 'countStudentAccount'},
                    {text: '∑ деканат', value: 'countDeanAll'},
                    {text: '∑ платні дисципліни', value: 'countDeanFreeAdmissionPayableSubject'}, // по довідці
                    {text: '∑ прострочених, платні дисципліни', value: 'countOverdueDeanFreeAdmissionPayableSubject'}, // всі видані деканатом, у яких закінчився термін
                    {text: '∑ безоплатні дисципліни', value: 'countDeanFreeAdmissionFreeAdmissionSubject'},
                ],
                loadingFaculties: false,
                
                dates: [],
                
                data: [],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loadingFaculties = true;
                HttpService.getInstance()
                    .get(`statisticsReworkAdmissions/getAll?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loadingFaculties = false;
                    })
                    .catch(e => {
                        
                    })
            },
        }
    }
</script>