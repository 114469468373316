export const En = {
    mark: "DD.MM.YY",
    short: "DD/MM/YYYY",
    long: "DD/MM/YYYY HH:mm",
    auditLog: "DD/MM/YYYY HH:mm:ss",
    month: "MMMM YYYY",
};

export const Uk = {
    mark: "DD.MM.YY",
    short: "DD.MM.YYYY",
    long: "DD.MM.YYYY HH:mm",
    auditLog: "DD/MM/YYYY HH:mm:ss",
    month: "MMMM YYYY",
};

export default {
    En,
    Uk,
    "en-US": En,
    "uk-UA": Uk,
}