<template>
    <div>
        <v-layout row wrap>
            <v-flex xs5>
                <v-card class="mb-2">
                    <v-card-text>
                        <div class="title">
                            <countdown
                                    v-if="countdown !== 0"
                                    :time="countdown"
                            >
                                <template slot-scope="props">
                                    {{$t('studentQuiz.remaining')}}：{{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                                </template>
                            </countdown>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card class="questions-card">
                    <v-card-text class="px-2 questions-wrapper">
                        <step-quiz-buttons
                                :buttons-count="questionOverview.questionCount"
                                :questions-overview="questionOverview.stepQuestionOverviewItems"
                                
                                :route-quiz-home="routeQuizHome"
                                :route-quiz-additional-params="routeQuizAdditionalParams"

                                :is-student-test="isStudentTest"
                        />
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs7>
                <student-quiz-question
                        :question="activeQuestion"
                        :question-count="questionOverview.questionCount"
                        :questions-answered="questionsAnswered"
                        :was-answered="activeQuestionWasAnswered"
                        :loading="loading"
                        @selectAnswer="activeQuestionWasAnswered = true"
                        
                        :api-finish-quiz-attempt="ApiFinishQuizAttempt"
                        :route-quiz-finish="routeQuizFinish"
                        :route-quiz-home="routeQuizHome"
                        :route-quiz-additional-params="routeQuizAdditionalParams"
                        :route-quiz-finish-additional-params="routeQuizFinishAdditionalParams"
                        :api-save-question-answer="ApiSaveQuestionAnswer"
                        
                        :is-student-test="isStudentTest"
                />
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import HttpService from "../../HttpService";
    import StepQuizButtons from "./StepQuizButtons";
    import StudentQuizQuestion from "./StudentQuizQuestion";
    import differenceInMilliseconds from 'date-fns/difference_in_milliseconds';
    import store from "../../store";

    export default {
        components: {
            StepQuizButtons,
            StudentQuizQuestion,
        },
        data: () => ({
            activeQuestion: {id: 0, question: '', stepQuestionAnswers: []},
            activeQuestionWasAnswered: false,
            questionOverview: {
                questionCount: 0,
                quizEndTime: null,
                stepQuestionOverviewItems: [],
            },
            questionsAnswered: 0,
            countdown: 0,
            loading: false,
        }),
        props: {
            ApiGetQuestionStudentOverview: String,
            ApiFinishQuizAttempt: String,
            ApiGetQuestionStudent: String,
            ApiSaveQuestionAnswer: String,

            routeQuizFinish: String,
            routeQuizFinishAdditionalParams: Object,

            routeQuizHome: String,
            routeQuizAdditionalParams: Object,
            
            isStudentTest: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.getQuestion(this.$route.params.questionNumber);
            this.getQuizOverview();
        },
        methods: {
            finishQuiz() {
                store.commit("snackMessages/set", {message: "Час для тестування вийшов", color: 'error'});

                HttpService.getInstance()
                    .post(this.ApiFinishQuizAttempt)
                    .then(resp => {
                        this.$router.push({
                            name: 'studentQuizFinish',
                        });
                    });
            },
            getQuestion(questionNumber) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`${this.ApiGetQuestionStudent}/${questionNumber}`)
                    .then(resp => {
                        this.activeQuestion = resp;
                        this.activeQuestionWasAnswered = false;

                        this.loading = false;
                    });
            },
            getQuizOverview() {
                this.loading = true;

                HttpService.getInstance()
                    .get(this.ApiGetQuestionStudentOverview)
                    .then(resp => {
                        this.questionOverview = resp;

                        this.questionsAnswered = resp.stepQuestionOverviewItems.filter(s => s.isAnswered).length;
                        this.countdown = differenceInMilliseconds(resp.quizEndTime, new Date());

                        this.loading = false;
                    })
                    .catch(err => {
                        if (err.isExpiredQuiz) {
                            this.finishQuiz();
                        }
                    });
            }
        },
        watch: {
            "$route.params.questionNumber": function (questionNumberNew, questionNumberOld) {
                if (questionNumberNew !== questionNumberOld) {
                    this.getQuestion(questionNumberNew);
                    this.getQuizOverview();
                }
            }
        },
    }
</script>
<style>
    .questions-card {
        height: 70vh;
    }

    .questions-wrapper {
        height: 100%;
        overflow-y: auto;
    }
</style>