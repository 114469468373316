<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.name" v-model="form.name"
                                          label="Назва"></v-text-field>
                        </v-flex>

                        <div class="title">Дисципліни</div>
                        <v-flex xs12 md12 :key="index" v-for="(subject, index) in form.subjects">
                            <v-layout>
                                <v-flex xs8>
                                    <v-autocomplete
                                            :items="subjects"
                                            v-model="form.subjects[index]"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            label="Дисципліна"
                                    ></v-autocomplete>
                                </v-flex>
                                <v-flex xs3>
                                    <v-text-field
                                            :error-messages="getError(index, 'percentage')"
                                            @keypress="isNumber($event, 2)"
                                            v-model="form.subjects[index].percentage" 
                                            label="%"></v-text-field>
                                </v-flex>
                                <v-flex>
                                    <v-btn @click="removeSubject(index)" color="red" dark>
                                        <v-icon>clear</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12>
                            <div v-if="errors.subjects.length !== 0" class="red--text">{{errors.subjects}}</div>
                        </v-flex>
                        <v-btn @click="addSubject()">Додати дисципліну</v-btn>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        text
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import {isNumber} from "../../../../helpers/numberHelper";
    

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    name: null,
                    subjects: [{}],
                },
                errors: {
                    name: [],
                    subjects: [],
                },
                subjects: [],
                loading: false,
                loadingSave: false,
                isNumber: isNumber,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("subjects/getAll")
                .then(resp => {
                    this.subjects = resp.map(s => {
                        s.name = `${s.nameUk} (${s.specialities.map(spec => spec.nameShortUk).join(", ")}) `;
                        
                        return s;
                    });
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`stepProfile/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            getError(index, field) {
                const formIndex = this.errors[`subjects[${index}]`]
                if (formIndex === undefined) {
                    return [];
                }
                
                if (formIndex[field] !== undefined) {
                    return formIndex[field];
                }
                
                return [];
            },
            removeSubject(index) {
              this.form.subjects.splice(index, 1);  
            },
            addSubject() {
                this.form.subjects.push({percentage: 0});                
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("stepProfile", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>