<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            loading-text="Завантаження... Зачекайте, будь ласка"
            class="elevation-3"
            hide-default-footer
            disable-pagination
    >
        <template v-slot:item.isApproved="{item}">
            <v-icon v-if="item.isApproved === true" color="green">check</v-icon>
        </template>
        <template v-slot:item.surnamename="{item}">
            {{item.surname}} {{item.name}} {{item.patronymic}}
        </template>
        <template v-slot:item.academicPosition="{item}">
            {{item.academicPosition ? item.academicPosition.nameShortUk : ""}}
        </template>
        <template v-slot:item.departments="{item}">
            {{item.departments.map(d => d.nameUk).join(", ")}}
        </template>
        <template v-slot:item.totalSum="{item}">
            <div class="font-weight-bold subtitle-1">{{item.totalSum}}</div>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            items(newItems, oldItems) {
              if (newItems.length > 0) {
                  const itemHeader = newItems[0];

                  itemHeader.ratingDirections.forEach(direction => {
                      this.headers.push({
                          text: `${direction.order}. ${direction.directionName.slice(0, 40)}...`,
                          value: `direction${direction.order}`,
                          width: "10%",
                      });
                  });
              }
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: "Затв.", value: 'isApproved'},
                {text: '№', value: 'index'},
                {text: 'ПІП', value: 'surnamename'}, 
                {text: 'Вік', value: 'age'},
                {text: 'Посада', value: 'academicPosition'},
                {text: 'Ставка', value: 'salaryRate'},
                {text: 'Кафедра', value: 'departments'},
                {text: '∑', value: 'totalSum'},
            ],
        }),
    }
</script>