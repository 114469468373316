<template>
    <div>
        <students-grid
                :items="items"
                :total="total"
                :loading="loading"
                @editItem="editItem"
                @change="moveSelectedDialog()"
                @deleteSelected="deleteSelectedDialog()"
                @expulseSelected="dialogExpellSelected = true"
                @academicVacationSelected="dialogAcademicVacationSelected = true"
                :selectedItems.sync="selectedItems"
        >
        </students-grid>

        <v-dialog v-model="dialog" max-width="450px" scrollable>
            <add-edit-student
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :items="items"
            ></add-edit-student>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-dialog v-model="dialogDeleteSelected" persistent max-width="500">
            <v-card>
                <v-card-title class="subtitle-1">Ви дійсно хочете видалити виділених студентів?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            @click.native="dialogDeleteSelected = false"

                    >Ні
                    </v-btn>
                    <v-btn
                            color="primary"
                            text
                            @click.native="deleteSelected"

                    >Так
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExpellSelected" persistent max-width="500">
            <v-card>
                <v-card-title class="subtitle-1">Ви дійсно хочете відрахувати виділених студентів?</v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            При відрахуванні студента протягом навчального року, оцінки за всі НЕзараховані дисципліни анулюються!
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            @click.native="dialogExpellSelected = false"

                    >Ні
                    </v-btn>
                    <v-btn
                            color="primary"
                            text
                            @click.native="expellSelected"

                    >Так
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogAcademicVacationSelected" persistent max-width="500">
            <v-card>
                <v-card-title class="subtitle-1">Ви дійсно хочете відправити в академічну відпустку виділених студентів?</v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            При відправці в академічну відпустку студента протягом навчального року, оцінки за всі НЕзараховані дисципліни анулюються!
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            @click.native="dialogAcademicVacationSelected = false"

                    >Ні
                    </v-btn>
                    <v-btn
                            color="primary"
                            text
                            @click.native="academVacationSelected"

                    >Так
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMove" max-width="450">
            <move-students-dialog
                    v-if="dialogMove"
                    :selected="selectedItems"
                    @shouldCloseGroupMove="dismissDialogs"
                    @groupMoved="groupMoved"
            ></move-students-dialog>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import StudentsGrid from "./StudentsGrid";
    import AddEditStudent from "./AddEditStudent";
    import DeleteConfirm from "./DeleteConfirm";
    import MoveStudentsDialog from "./MoveStudentsDialog";

    export default {
        components: {
            StudentsGrid,
            AddEditStudent,
            DeleteConfirm,
            MoveStudentsDialog
        },
        mounted() {
            this.getItemsWithRoute();
        },
        methods: {
            dismissDialogs() {
                this.dialogMove = false;
                this.dialogAddSeveral = false;
                
                this.selectedItems = [];
                this.items = [];
                this.total = 0;
            },
            moveSelectedDialog() {
                this.dialogMove = true;
            },
            getItemsWithRoute() {
              this.getItems(this.$route.params.faculty, this.$route.params.speciality, this.$route.params.semester, this.$route.params.group);  
            },
            groupMoved() {
                this.dialogMove = false;
                this.getItemsWithRoute();
                
                this.selectedItems = [];
                this.items = [];
                this.total = 0;
            },
            deleteSelectedDialog() {
                this.dialogDeleteSelected = true;
            },
            deleteSelected() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`student/deletes`, this.selectedItems)
                    .then(() => {
                        this.dialogDeleteSelected = false;
                        this.loading = false;

                        this.getItemsWithRoute();
                    });
            },
            expellSelected() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`student/expell`, this.selectedItems)
                    .then(() => {
                        this.dialogExpellSelected = false;
                        this.loading = false;

                        this.getItemsWithRoute();
                    });
            },
            academVacationSelected() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`student/academicVacation`, this.selectedItems)
                    .then(() => {
                        this.dialogAcademicVacationSelected = false;
                        this.loading = false;

                        this.getItemsWithRoute();
                    });
            },
            getItems(facultyId, specialityId, semesterId, groupId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`student/${facultyId}/${specialityId}/${semesterId}/${groupId}`)
                    .then(students => {
                        this.items = students.items.map((item, index) => {
                            item.index = index + 1;
                            
                            return item;
                        });
                        this.total = students.total;
                        this.loading = false;
                    })
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItemsWithRoute();
            },
            addedSeveral() {
                this.dialogAddSeveral = false;

                this.getItemsWithRoute();
                // this.selectedItems = [];
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItemsWithRoute();
            },
        },
        watch: {
          "$route.params": function (newRoute, oldRoute) {
              if (newRoute.faculty !== oldRoute.faculty || 
                  newRoute.speciality !== oldRoute.speciality || 
                  newRoute.semester !== oldRoute.semester ||
                  newRoute.group !== oldRoute.group) {
                  this.getItems(newRoute.faculty, newRoute.speciality, newRoute.semester, newRoute.group);
                  this.selectedItems = [];
              }
          }  
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                dialogDeleteSelected: false,
                dialogMove: false,
                dialogAddSeveral: false,

                dialogExpellSelected: false,
                dialogAcademicVacationSelected: false,

                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,

                loading: false,

                selectedItems: [],
            }
        }
    }
</script>