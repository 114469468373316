<template>
    <div>
        <div class="headline">Лекційні заняття</div>
        <v-container v-if="selected.length > 0">
            <v-row class="d-flex align-top">
                <v-col md="4">
                    <span>Перемістити вибрані лекції в інший модуль:</span>
                </v-col>
                <v-col md="5">
                    <v-select
                        :items="modules"
                        v-model="selectedModule"
                        item-text="name"
                        item-value="id"
                        return-object
                        label="Модуль"
                        solo
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-btn @click.native="moveToModule" :disabled="(selectedModule === null || loading === true)">Перемістити</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-data-table
            show-select
            v-model="selected"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.professors="{ item }">
                <v-chip v-for="professor in props.item.professors" :key="professor.id">
                    {{ professor.surname }} {{ professor.name }} {{ professor.patronymic }}
                </v-chip>
                <div v-if="props.item.professors.length === 0">-</div>
            </template>
            <template v-slot:item.actions="{item}">
                <div class="justify-center layout px-0">
                    <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import HttpService from "@/HttpService";

export default {
    props: {
        items: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loading: {
            type: Boolean,
        },
    },
    mounted() {
        HttpService.getInstance()
            .get(`module/getAll/${this.$route.params.department}/${this.$route.params.subject}`)
            .then(resp => {
                this.modules = resp.map(module => {
                    module.name = `${module.orderBy}. ${module.nameUk}`;

                    return module;
                });
            });
    },
    methods: {
        moveToModule() {
            HttpService.getInstance()
                .put(`lecture/moveToModule/${this.selectedModule.id}`, this.selected)
                .then((resp) => {
                    this.$emit("moveditems");
                })
        }
    },
    watch: {
        pagination(paginationVal, prevPaginationVal) {
            this.$emit("paginate", paginationVal);
        },
    },
    data: () => ({
        pagination: {
            totalItems: 0,
        },
        headers: [
            {text: 'Тема лекції (укр)', value: 'nameUk'},
            // {text: 'Тема лекції (eng)', value: 'nameEn'},
            {text: 'Лектор (-и)', value: '', sortable: false},
            {text: 'Дії', value: 'actions', sortable: false}
        ],
        modules: [],
        selected: [],
        selectedModule: null,
    }),
}
</script>