<template>
    <div>
        <span
            class="headline">{{ editMode ? 'Редагування питання' : 'Додавання тесту "впишіть пропущені слова"' }}</span>
        <v-container grid-list-md>
            <v-layout v-if="!loading" wrap>
                <v-flex xs12 md12>
                    <v-alert
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                    >
                        Введіть питання разом зі словами, які потрібно буде вписати студенту. Позначте дані слова (які
                        необхідно вписати), квадратними дужками –
                        []. <br> Наприклад, "Людське [серце] має 4 [камери] та 2 кола кровообігу."
                    </v-alert>
                    <v-textarea
                        :rows="5"
                        solo
                        label="Питання"
                        v-model="form.question"
                        :error-messages="errors.question"
                    ></v-textarea>

                    <v-btn text small @click="transformQuestion()">Перетворити питання</v-btn>
                    <v-btn text small @click="undoTransformQuestion()">Відмінити перетворення питання</v-btn>
                </v-flex>

                <v-flex :key="index" xs12 wrap v-for="(answer, index) in form.questionAnswers">
                    <v-layout align-center>
                        <v-text-field
                            xs-8
                            disabled
                            v-model="answer.answer"
                            :label="`Відповідь #${index + 1}`"
                        ></v-text-field>
                    </v-layout>
                </v-flex>
            </v-layout>

            <loading-placeholder v-else></loading-placeholder>
        </v-container>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import {remapErrors} from "@/helpers/errorsHelper";
import LoadingPlaceholder from "@/components/common/LoadingPlaceholder";

export default {
    props: {
        editId: Number,
        editMode: Boolean,

        educationMaterialVideoId: {
            type: Number,
            default: null,
        },
        educationMaterialPresentationId: {
            type: Number,
            default: null,
        },
        currentVideoTime: {
            type: Number,
            default: null,
        },
    },
    components: {
        loadingPlaceholder: LoadingPlaceholder,
    },
    data: () => {
        return {
            form: {
                id: 0,
                question: '',
                questionAnswers: []
            },
            errors: {
                question: [],
            },
            loading: false,
            loadingSave: false,
        }
    },
    mounted() {
        if (this.editMode) {
            this.loading = true;

            HttpService.getInstance()
                .get(`eAristoProfessor/questions/${this.editId}`)
                .then(resp => {
                    this.loading = false;
                    this.form = resp;
                    
                    for(const answer of this.form.questionAnswers) {
                        this.form.question = this.form.question.replace(`[${answer.orderNumber + 1}]`, `[${answer.answer}]`);
                    }
                    
                    this.form.questionAnswers = [];
                })
        }
    },
    methods: {
        saveForm() {
            this.transformQuestion();
            
            let payload = {
                id: this.form.id,
                question: this.form.question,
                questionAnswers: this.form.questionAnswers.map((questionAnswer, index) => {
                    return {
                        id: questionAnswer.id ?? 0,
                        answer: questionAnswer.answer,
                        isCorrect: true,
                        orderNumber: index,
                    }
                }),
                ...{subjectId: this.$route.params.subject},
                ...{moduleId: this.$route.params.module},
                ...{topicId: this.$route.params.topic},

            }

            if (!this.editMode) {
                payload = {
                    ...payload,
                    ...{educationMaterialVideoId: this.educationMaterialVideoId},
                    ...{educationMaterialPresentationId: this.educationMaterialPresentationId},
                    ...{currentVideoTime: Math.round(this.currentVideoTime)}
                }
            }

            this.loadingSave = true;
            this.$emit("loadingSave", true);

            HttpService.getInstance()
                .post("eAristoProfessor/completeTheSentence", payload)
                .then(resp => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    this.$emit("addedEdited");
                })
                .catch(err => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    if (err.errors) {
                        this.errors = remapErrors(err.errors);
                    }
                });
        },
        undoTransformQuestion() {
            console.log("this.form.questionAnswers", this.form.questionAnswers);
            for(const answer of this.form.questionAnswers) {
                this.form.question = this.form.question.replace(`[${answer.orderNumber + 1}]`, `[${answer.answer}]`);
            }

            this.form.questionAnswers = [];
        },
        transformQuestion() {
            const answerWordArray = [];
            let ordinalCounter = 0;

            const replacedQuestion = this.form.question.replace(/\[([^\]]+)\]/g, (_, match) => {
                answerWordArray.push(match);
                ordinalCounter++;

                return `[${ordinalCounter}]`;
            });

            this.form.question = replacedQuestion;

            const answers = answerWordArray.map((answerWord, index) => {
                return {
                    answer: answerWord,
                    orderNumber: index,
                };
            });

            this.form.questionAnswers = answers;
        },
        addAnswer() {
            if (this.form.questionAnswers.length <= 5) {
                this.form.questionAnswers.push({});
            }
        },
    }
}
</script>