<template>
    <div>
        <div v-for="group in groupsStudents">
            <div class="subheading">{{group.groupNumber}} група</div>
            <group-codes-print :students="group.students"/>
        </div>
    </div>
</template>
<script>
    import GroupCodesPrint from "./GroupCodesPrint";

    export default {
        props: {
            groupsStudents: {
                type: Array,
                default: () => [],
            }
        },
        components: {
            GroupCodesPrint,
        },
    }
</script>