<template>
    <div>
        <div class="mb-2">
            <div v-for="logLevel in logLevels" class="preview-block" :style="`background: ${logLevel.color}`">
                {{logLevel.name}}
            </div>
        </div>
        <form @submit="search" novalidate="true">
            <div class="mb-2">
                <v-layout row wrap justify-center>
                    <v-flex xs4>
                        <v-text-field
                                v-model="filter.name"
                                clearable
                                @click:clear=""
                                hide-details
                                label="Повідомлення"
                                solo
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                        <v-text-field
                                v-model="filter.template"
                                clearable
                                hide-details
                                label="Шаблон"
                                solo
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs3>
                        <v-btn type="submit" color="primary">Пошук</v-btn>
                    </v-flex>
                </v-layout>
            </div>
        </form>
        <logs-grid
                :items="items"
                :has-previous="hasPrevious"
                :has-next="hasNext"
                :loading="loading"
                :pagination="pagination"
                @paginate="onPaginate"
                @viewItem="viewItem"
        >
        </logs-grid>

        <v-dialog v-model="dialog" max-width="900px" scrollable>
            <view-log
                    v-if="dialog"
                    @shouldClose="dialog = false"
                    :item="item"
            ></view-log>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import LogsGrid from "./LogsGrid";
    import {objectLogsQueryStringify} from "../../../../helpers/querystringHelper";
    import {auditGridDirectionsEnum} from "../../../../constants/auditGridDirectionsEnum";
    import logLevels from "../../../../constants/logLevels";
    import ViewLog from "./ViewLog";

    export default {
        components: {
            LogsGrid,
            ViewLog
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                itemId: null,
                items: [],
                hasPrevious: false,
                hasNext: false,
                loading: false,
                pagination: {
                    itemsPerPage: 10,
                    objectId: '',
                    direction: auditGridDirectionsEnum.default,
                },
                filter: {
                    name: "",
                    template: "",
                },
                logLevels,
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            },
            getItems(queryParamsInp) {
                this.loading = true;

                const queryParams = {...{}, ...queryParamsInp};

                const filters = [
                    {
                        field: "RenderedMessage",
                        value: this.filter.name,
                        operator: "contains",
                    }, 
                    {
                        field: "MessageTemplate",
                        value: this.filter.template,
                        operator: "contains",
                    },
                ];

                const queryParamsWithFilters = {...queryParams, ...{filter: filters}};
                
                HttpService.getInstance()
                    .get(`logs/getLogList?${objectLogsQueryStringify(queryParamsWithFilters)}`)
                    .then(audit => {
                        this.items = audit.items;
                        this.hasPrevious = audit.hasPrevious;
                        this.hasNext = audit.hasNext;
                        this.loading = false;
                    })
            },
            search(e) {
                e.preventDefault();

                this.getItems(this.pagination);
            },
            onPaginate(direction) {
                switch (direction) {
                    case auditGridDirectionsEnum.next:
                        if (!this.hasNext) {
                            break;
                        }
                        const lastItem = this.items[this.items.length - 1];

                        this.pagination.objectId = lastItem.id;
                        this.scrollToTop();
                        
                        break;
                    case auditGridDirectionsEnum.previous:
                        if (!this.hasPrevious) {
                            break;
                        }
                        const firstItem = this.items[0];

                        this.pagination.objectId = firstItem.id;

                        this.scrollToTop();

                        break;
                    case auditGridDirectionsEnum.default:
                        this.scrollToTop();

                        break;                     
                }
                
                this.pagination.direction = direction;
                
                this.getItems(this.pagination);
            },
            viewItem(item) {
                this.dialog = true;
                this.item = item;
            },
        },
        mounted() {
            this.getItems(this.pagination);
        },
    }
</script>
<style lang="scss">
    .preview-block {
        display: inline-flex;
        width: 100px;
        height: 30px;
        color: white;
        margin-right: 10px;
        padding: 5px;
    }
</style>