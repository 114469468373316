import deepmerge from "deepmerge";

export const AppConfig = (config) => {
    let defaultConfig = {};
    let developmentConfig = {};
    let stagingConfig = {};
    let productionConfig = {};

    if (config.hasOwnProperty('default') ) {
        defaultConfig = config.default;
    }
    if (config.hasOwnProperty('development') ) {
        developmentConfig = config.development;
    }
    if (config.hasOwnProperty('staging') ) {
        stagingConfig = config.staging;
    }
    if (config.hasOwnProperty('production') ) {
        productionConfig = config.production;
    }

    let finalConfig = {
        configMode: null,
    };
    
    let configMode = process.env.VUE_APP_CONFIG_MODE_ENV;
    
    if (!configMode) {
        throw new Error("VUE_APP_CONFIG_MODE_ENV not set to any mode!");
    }

    if (configMode === "development") {
        finalConfig = deepmerge(defaultConfig, developmentConfig);
        finalConfig.configMode = "development";
    }
    if (configMode === "staging") {
        finalConfig = deepmerge(defaultConfig, stagingConfig);
        finalConfig.configMode = "staging";
    }
    if (configMode === "production") {
        finalConfig = deepmerge(defaultConfig, productionConfig);
        finalConfig.configMode = "production";
    }

    return finalConfig;
};

export default {
    install: function (Vue, config) {
        Vue.prototype.$config = AppConfig(config);
    }
}