import {HubConnectionBuilder, LogLevel} from '@aspnet/signalr';
import {AppConfig} from "./config/configFactory";
import config from "./app.config";
// import router from "./router";

let configuration = AppConfig(config);

const SignalRConnection = (methodName) => {
    const connection = new HubConnectionBuilder()
        .withUrl(`${configuration.apiEndpoint}/ws/${methodName}`,
            {
                accessTokenFactory: () => {
                    return localStorage.getItem('access_token');
                }
            })
        .configureLogging(LogLevel.Information)
        .build();

    connection.start()
        .catch((err) => {
            return console.error(err);
        });
    
    return connection;
};

export default SignalRConnection;