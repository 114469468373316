<template>
    <v-card>
        <v-toolbar dark color="orange" fixed>
            <v-btn icon dark @click="$emit('shouldCloseAddEdit')">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Редагування пулу: {{editItem.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-container grid-list-md class="mt-5 pr-1">
                <v-flex row wrap>
                    <question-index :edit-item-obj="editItem"/>
                </v-flex>
            </v-container>
        </v-card-text>
        
    </v-card>
</template>

<script>
    import QuestionIndex from "./QuestionPool/QuestionIndex";

    export default {
        components: {
            QuestionIndex,  
        },
        props: {
            editItem: {
                type: Object,
                default: () => {
                    return {
                        name: '',
                    }
                }
            },
        },
    }
</script>

<style lang="scss">
    .answer-text {
        vertical-align: text-bottom;
    }
</style>