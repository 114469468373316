<template>
    <v-card>
        <v-card-title class="title">Переведення на наступний семестр</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете перевести <strong>дані групи та студентів</strong> на наступний семестр? 
            <div class="text--darken-2 red--text mt-5">Студенти та групи, які навчаються на останньому навчальному семестрі для вибраної 
                спеціальності будуть автоматично відраховані, як такі, що виконали в повному об'ємі навчальну програму.</div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseDeleteConfirm')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
            };
        },
        props: {
            selected: Array,
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .post(`academicGroup/MoveNextYear`, {academicGroups: this.selected})
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("moved");
                    });
            }
        }
    }
</script>