<template>
    <v-app>
        <chief-dept-rating-navigation-drawer :drawer="drawerApp"></chief-dept-rating-navigation-drawer>
       
        <toolbar
                :is-lang-switcher="false"
                color="orange darken-3"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../../components/common/Toolbar";
    import TeacherNavigationDrawer from "../../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../../components/common/SnackDisplayer";
    import ChiefDeptRatingNavigationDrawer from "../../../components/chiefDept/common/ChiefDeptRatingNavigationDrawer";

    export default {
        components: {
            Toolbar,
            ChiefDeptRatingNavigationDrawer,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            navigationApiEndpoint: "",
        }),
        computed: {
            getNavigationApiEndpoint() {
                return `navigation/getNavRegistersForDepartmentSubject/${this.$route.params.department}/${this.$route.params.subjectSel}`;
            }
        }
    }
</script>
