<template>
    <div>
        <div class="headline">Напрямки науково-педагогічної діяльності</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item.navigation="{item}">
                <div class="justify-start d-flex">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="$emit('navigateTo', item.id, 'ratingIndexes')" v-on="on" icon class="mx-0">
                                <v-icon color="green">assignment</v-icon>
                            </v-btn>
                        </template>
                        <span>Перейти до списку напрямків рейтингування</span>
                    </v-tooltip>
                </div>
            </template>
            
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-start">
                    <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </div>
            </template>
            
        </v-data-table>
    </div>
</template>
<script>
    import finalControlTypes from "../../../constants/admin/finalControlTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Навігація', value: 'navigation', sortable: false, width: "10%"},
                {text: 'Назва', value: 'directionName', sortable: false,},
                {text: 'Порядковий номер', value: 'order', sortable: false,},
                {text: 'Коефіцієнт', value: 'ratio', sortable: false,},
                {text: 'Дії', value: 'actions', sortable: false, width: "10%"}
            ],
        }),
    }
</script>