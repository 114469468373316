<template>
    <div>
        <v-form ref="searchForm" @submit="submitSearch" novalidate="true">
            <v-card-title class="px-0">
                <v-btn color="red" dark @click="$emit('deleteAll')">Видалити всі запитання</v-btn>
                <v-spacer></v-spacer>
                <!--                <v-text-field-->
                <!--                        v-model="search"-->
                <!--                        label="Пошук по змісту запитанню"-->
                <!--                        single-line-->
                <!--                        hide-details-->
                <!--                        clearable-->
                <!--                        @click:clear="$emit('search', null)"-->
                <!--                ></v-text-field>-->
                <!--                <v-btn-->
                <!--                        color="primary"-->
                <!--                        type="submit">-->
                <!--                    <v-icon>search</v-icon>-->
                <!--                </v-btn>-->
            </v-card-title>
        </v-form>

        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                    'items-per-page-text': 'Питань на сторінці', 
                    'items-per-page-options': [10,25,50,{'text':'Всі','value':-1}]
                }"
        >
            <template slot="pageText" slot-scope="props">
                Пункт {{ props.pageStart }} - {{ props.pageStop }} з {{ props.itemsLength }}
            </template>

            <template v-slot:item.questionAnswers="{item}">
                <div v-if="item.questionType === 0">
                    <div v-for="answer in item.questionAnswers">
                        <v-icon v-if="answer.isCorrect">done</v-icon>
                        <v-icon v-if="!answer.isCorrect">remove</v-icon>
                        <span class="answer-text">{{ answer.answer }}</span>
                    </div>
                </div>

                <div v-if="item.questionType === 1">
                    <div v-for="answer in item.questionAnswers">
                        <span class="answer-text">[{{answer.orderNumber + 1}}] {{ answer.answer }}</span>
                    </div>
                </div>

                <div v-if="item.questionType === 2">
                    <div v-for="answer in item.questionAnswers">
                        <span class="answer-text"><v-icon>edit</v-icon>  {{ answer.answer }}</span>
                    </div>
                </div>
            </template>

            <template v-slot:item.actions="{item}">
                <v-btn icon class="mx-0" @click="$emit('editItem', {id: item.id, questionType: item.questionType})">
                    <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </template>

            <template v-slot:item.slide="{item}">
               {{item.slide.orderNumber + 1}}
            </template>
            
            <template v-slot:item.videoLocation="{item}">
                {{formatVideoTime(item.videoLocation)}}
            </template>
            
            <template v-slot:item.questionType="{item}">
                <div>{{mapQuestionType(item.questionType)}}</div>
            </template>
            
        </v-data-table>
    </div>
</template>
<script>
import {eAristoTopicQuestionType} from "@/constants/eAristo/eAristoTopicQuestionType";
import moment from "moment";

export default {
    props: {
        items: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loading: {
            type: Boolean,
        },
        topicQuestionType: {
            type: Number,
            default: eAristoTopicQuestionType.TopicQuestions,
        }
    },
    watch: {
        pagination(paginationVal, prevPaginationVal) {
            this.$emit("paginate", paginationVal);
        }
    },
    mounted() {
       this.headers = this.headersInitial; 
       
       if (this.topicQuestionType === eAristoTopicQuestionType.PresentationQuestions) {
           this.headers.push(
               {text: 'Після слайду', value: 'slide', sortable: false, width: "10%"}
           );
       }
       else if (this.topicQuestionType === eAristoTopicQuestionType.VideoQuestions) {
           this.headers.push(
               {text: 'Відео, час', value: 'videoLocation', sortable: false, width: "10%"}
           );
       }
       
       this.headers.push(this.headerActions);
    },
    methods: {
        mapSpecialitiesToString(specialities) {
            return specialities.map(spec => spec.nameUk).join(", ");
        },
        submitSearch(e) {
            e.preventDefault();

            this.$emit('search', this.search);
        },

        formatVideoTime(time) {
            const timeMoment = moment.unix(time).utc();

            return timeMoment.format("HH:mm:ss");
        },

        mapQuestionType(questionType) {
            if (questionType === 0) {
                return "Тест з 1 правильною відповіддю";
            }
            else if (questionType === 1) {
                return "Вписати пропущене слово";
            }
            else if (questionType === 2) {
                return "Вписати відповідь";
            }
            else if (questionType === 3) {
                return "Тест з 1 правильною відповіддю (зображення)";
            }
        }
    },
    data: () => ({
        search: null,
        pagination: {
            totalItems: 0,
        },
        headers: [],
        headersInitial: [
            {text: 'ID', value: 'id', sortable: false},
            {text: 'Запитання', value: 'question', sortable: false},
            {text: 'Відповіді', value: 'questionAnswers', sortable: false, width: "30%"},
            {text: 'Тип запитання', value: 'questionType', sortable: false, width: "15%"},
        ],
        headerActions: {text: 'Дії', value: 'actions', sortable: false, width: '150px'},
    }),
}
</script>