<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <profiles-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import ProfilesIndex from "../../../../components/superAdminStep/Dictionaries/Profiles/ProfilesIndex";
    export default  {
        components: {
            ProfilesIndex,
        }
    }
</script>