<template>
    <v-navigation-drawer
            :fixed="true"
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :value="drawer"
            fixed
            @input="$emit('toggleDrawer', $event)"
    >
        <v-container fluid>
            <v-btn class="mb-6" small :to="{name: 'registersRoot'}">
                <v-icon>keyboard_backspace</v-icon>
                Перейти до меню
            </v-btn>
        </v-container>

        <navigation-nav-list-semesters route-to="lecturesFullRoot"></navigation-nav-list-semesters>

    </v-navigation-drawer>
</template>
<script>
    import NavigationNavListSemesters from "../../common/left-navigation/NavigationNavListSemesters";

    export default {
        components: {
            NavigationNavListSemesters
        },
        props: {
            drawer: {
                type: Boolean,
            },
        },
    }
</script>