<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Факультет
                    </th>
                    <th class="text-left">
                        ∑ 5
                    </th>
                    <th class="text-left">
                        ∑ 4 
                    </th>
                    <th class="text-left">
                        ∑ 3 
                    </th>
                    <th class="text-left">
                        ∑ 2
                    </th>
                    <th class="text-left">
                        ∑ "Не завершено"
                    </th>
                    <th class="text-left">
                        ∑ "Порушення регламенту"
                    </th>
                    <th class="text-left">
                       Всі спроби
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in data">
                    <td>{{item.faculty.nameUk}}</td>
                    <td>{{item.count5Percentage}}% ({{item.count5}})</td>
                    <td>{{item.count4Percentage}}% ({{item.count4}})</td>
                    <td>{{item.count3Percentage}}% ({{item.count3}})</td>
                    <td>{{item.count2Percentage}}% ({{item.count2}})</td>
                    <td>{{item.countNotFinishedPercentage}}% ({{item.countNotFinished}})</td>
                    <td>{{item.countPenaltyPercentage}}% ({{item.countPenalty}})</td>
                    <td>{{item.countAll}}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        
    </v-container>
</template>

<script>
    import LineChart from '../../common/statistics/LineChart';
    import StatisticsHeader from "../../common/statistics/StatisticsHeader";
    import HttpService from "../../../HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                dates: [],
                
                data: [],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                HttpService.getInstance()
                    .get(`statisticsReworks/getAll?${queryParam}`)
                    .then(data => {
                        this.data = data;
                    })
                    .catch(e => {
                        
                    })
            },
        }
    }
</script>