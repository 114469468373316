<template>
    <div>
    <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            hide-default-footer
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template slot="pageText" slot-scope="props"></template>
        <template v-slot:item="props">
            <tr>
                <td class="text-center px-0" :style="`background: ${getCellColor(props.item.level)}`">
                    <v-btn icon class="mx-0" @click="$emit('viewItem', props.item)">
                        <v-icon color="primary">visibility</v-icon>
                    </v-btn>
                </td>
                <td class="text-xs-left">{{ props.item.messageTemplate }}</td>
                <td class="text-xs-left">{{ props.item.renderedMessage.substring(0, 200) }}...</td>
                <td class="text-xs-left">{{ props.item.properties.ipAddress }}</td>
                <td class="text-xs-left">{{ props.item.properties.ipAddressLocal }}</td>
                <td class="text-xs-left">{{ props.item.properties.userAgent }}</td>
                <td class="text-xs-left">{{ props.item.properties.device }}</td>
                <td class="text-xs-left">{{ props.item.properties.httpMethod }}</td>
                <td class="text-xs-left">{{ formatAuditLogDate(props.item.timestamp) }}</td>
            </tr>
        </template>
    </v-data-table>
    <div v-if="hasPrevious || hasNext" class="text-center pt-2">
        <v-btn
               color="primary"
               :disabled="!hasPrevious"
               @click="$emit('paginate', auditGridDirectionsEnum.previous)"
        ><v-icon>chevron_left</v-icon></v-btn>
        
        <v-btn
                color="primary"
                :disabled="!hasNext" 
               @click="$emit('paginate', auditGridDirectionsEnum.next)"
        ><v-icon>chevron_right</v-icon></v-btn>
    </div>
    </div>
</template>
<script>
    import {formatAuditLogDate} from "../../../../helpers/dateHelper";
    import {auditGridDirectionsEnum} from "../../../../constants/auditGridDirectionsEnum";
    import logLevels from "../../../../constants/logLevels";
    
    export default {
        props: {
            items: {
                type: Array,
            },
            hasPrevious: Boolean,
            hasNext: Boolean,
            pagination: {
                type: Object,
            },
            loading: {
                type: Boolean,
            },
        },
        methods: {
          getCellColor(level) {
              const foundItem = logLevels.find(l => l.id === level);
              
              if (foundItem) {
                  return foundItem.color;
              }
              
              return "";
          }  
        },
        data: () => ({
            headers: [
                {text: 'Дії', value: 'actions', sortable: false},
                {text: 'Шаблон', value: 'messageTemplate', sortable: false},
                {text: 'Повідомлення', value: 'renderedMessage', sortable: false},
                {text: 'IP адреса', value: 'ipAddress', sortable: false},
                {text: 'IP адреса вн.', value: 'ipAddressLocal', sortable: false},
                {text: 'Браузер', value: 'userAgent', sortable: false},
                {text: 'Пристрій', value: 'device', sortable: false},
                {text: 'HTTP Method', value: 'httpMethod', sortable: false},
                {text: 'Дата', value: 'timestamp', sortable: false}
            ],
            formatAuditLogDate,
            auditGridDirectionsEnum,
        }),
    }
</script>