<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування питання' : 'Додавання питання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>

                        <v-flex xs12 md12>
                            <v-textarea
                                    :rows="5"
                                    solo
                                    label="Питання"
                                    v-model="form.question"
                                    :error-messages="errors.question"
                            ></v-textarea>
                        </v-flex>

                        <v-flex :key="index" xs12 wrap v-for="(answer, index) in form.stepQuestionAnswers">
                            <v-layout align-center>
                                <v-checkbox 
                                        v-model="answer.isRight"
                                        xs-2
                                        hide-details 
                                        class="shrink mr-2"
                                        :disabled="isAnswerDisabled(answer.isRight)"
                                ></v-checkbox>
                                <v-text-field
                                        xs-8
                                        v-model="answer.answer"
                                        :label="`Відповідь #${index + 1}`"
                                ></v-text-field>
                                <v-btn
                                        v-if="index !== 0"
                                        xs-2 
                                        color="red" 
                                        dark 
                                        @click="removeAnswer(index)"
                                >
                                    <v-icon>clear</v-icon>
                                </v-btn>
                            </v-layout>
                        </v-flex>

                        <v-flex xs12>
                            <div class="red--text" v-if="errors.stepQuestionAnswers">
                                {{errors.stepQuestionAnswers}}
                            </div>
                        </v-flex>
                        
                        
                        <v-btn v-if="form.stepQuestionAnswers.length <= 5" @click="addAnswer()" color="primary">
                            <v-icon>add</v-icon> Додати питання
                        </v-btn>
                    </v-layout>

                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        text
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../../HttpService";
    import {remapErrors} from "../../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            questionPoolId: Number,
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    question: '',
                    stepQuestionAnswers: [{}]
                },
                errors: {
                    question: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`stepQuestion/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            removeAnswer(index) {
                this.form.stepQuestionAnswers.splice(index, 1);
            },
            addAnswer() {
                if (this.form.stepQuestionAnswers.length <= 5) {
                    this.form.stepQuestionAnswers.push({});
                }
            },
            anyRightAnswer() {
                const foundItem = this.form.stepQuestionAnswers.find(s => s.isRight);

                return foundItem !== undefined;
            },

            isAnswerDisabled(isRightChecked) {
                return !isRightChecked && this.anyRightAnswer();
            },

            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("stepQuestion", {...this.form, ...{stepQuestionPoolId: this.questionPoolId}})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>