<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-btn @click="dialogBulk = true" color="primary">Додати пакетом</v-btn>
            <v-btn class="ml-4" @click="dialogBulkRemove = true" dark color="red">Видалити всі</v-btn>
        </div>
        <quizes-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @search="onSearch"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </quizes-grid>
        <v-dialog v-model="dialog" max-width="1000px" scrollable>
            <add-edit-quiz
                v-if="dialog"
                @shouldCloseAddEdit="dialog = false"
                @addedEdited="addedEdited"
                :edit-mode="editMode"
                :edit-id="editId"
            ></add-edit-quiz>
        </v-dialog>

        <v-dialog v-model="dialogBulk" max-width="1000px" scrollable>
            <add-bulk-quiz
                v-if="dialogBulk"
                @shouldCloseAddEdit="dialogBulk = false"
                @addedEdited="addedEdited"
            ></add-bulk-quiz>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                @shouldCloseDeleteConfirm="dialogRemove = false"
                @removed="removed"
                :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-dialog v-model="dialogBulkRemove" max-width="400">
            <delete-bulk-confirm
                :type="type"
                @shouldCloseDeleteConfirm="dialogBulkRemove = false"
                @removed="removed"
            >
            </delete-bulk-confirm>
        </v-dialog>

        <v-btn
            color="primary"
            dark
            fixed
            bottom
            right
            fab
            @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import QuizesGrid from "./QuizesGrid";
    import AddEditQuiz from "./AddEditQuiz";
    import AddBulkQuiz from "./AddBulkQuiz";
    import DeleteConfirm from "./DeleteConfirm";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import DeleteBulkConfirm from "@/components/nbQuizAdmin/quizes/DeleteBulkConfirm.vue";

    export default {
        components: {
            QuizesGrid,
            AddEditQuiz,
            AddBulkQuiz,
            DeleteConfirm,
            DeleteBulkConfirm,
        },
        props: {
            type: {
                type: String, // lecture / topic
                required: true,
            }
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                dialogBulkRemove: false,
                dialogBulk: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                module: {
                    topicsAllCount: 0,
                },
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`module/${this.$route.params.module}`)  
              .then(module => {
                  this.module = module;
              })
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;
                
                let url = null;
                
                if (this.type === "topic") {
                    url = `reworkQuiz/getAll/byTopic/${this.$route.params.topic}?${queryParams}`;
                }
                else if (this.type === "lecture") {
                    url = `reworkQuiz/getAll/byLecture/${this.$route.params.lecture}?${queryParams}`;
                }
                

                HttpService.getInstance()
                    .get(url)
                    .then(quizes => {
                        this.items = quizes.items;
                        this.total = quizes.total;

                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.dialogBulkRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            onSearch(searchStr) {
                this.pagination.filter = [{
                    field: 'question',
                    value: searchStr,
                    operator: 'contains',
                }];

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
    }
</script>