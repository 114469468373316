<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex >
                <v-card>
                    <statistics-reworks-all-index :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>

    import StatisticsReworksAllIndex from "@/components/admin/StatisticsReworks/StatisticsReworksAllIndex";

    export default  {
        components: {
            StatisticsReworksAllIndex,
        },
        data: () => {
            return {
             

            };
        },
    }
</script>