<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.groupNumber"
                                          v-model="form.groupNumber"
                                          v-mask="['###']"
                                          suffix="група"
                                          label="Номер групи"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-switch
                                    label="Навчальний план по замовчуванню"
                                    v-model="form.educationPlanByDefault"
                                    color="red"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                    :items="educationPlans"
                                    :error-messages="errors.educationPlan"
                                    v-model="form.educationPlan"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Навчальний план"
                                    :disabled="form.educationPlanByDefault"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import maxBy from "lodash/maxBy";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            items: Array,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    groupNumber: null,
                    educationPlanByDefault: true,
                    educationPlan: null,                    
                },
                errors: {
                    groupNumber: [],
                    educationPlan: [],
                },
                loading: false,
                loadingSave: false,
                
                educationPlans: [],
            }
        },
        mounted() {
            let maxGroupNumber = maxBy(this.items, 'groupNumber');

            this.form.groupNumber = maxGroupNumber !== undefined ? parseInt(maxGroupNumber.groupNumber) + 1 : null;

            HttpService.getInstance()
                .get(`educationPlan/getAllBySpeciality/${this.$route.params.speciality}`)
                .then(resp => {
                   this.educationPlans = resp;
                   this.form.educationPlan = this.educationPlans.find(ep => {
                       return ep.isDefault;
                   });
                });
            
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`academicGroup/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                        
                        this.form.educationPlanByDefault = this.form.educationPlan.isDefault;
                    })
            }
        },
        watch: {
          "form.educationPlanByDefault": function (newVal, oldVal) {
              if (newVal !== oldVal && newVal === true) {
                  this.form.educationPlan = this.educationPlans.find(ep => {
                      return ep.isDefault;
                  });
              }
          }  
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                let additionalParams = {
                    faculty: {id: this.$route.params.faculty},
                    semester: {id: this.$route.params.semester},
                    speciality: {id: this.$route.params.speciality}
                };
                
                HttpService.getInstance()
                    .post("academicGroup", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>