<template>
    <v-card v-if="survey" :to="{name: 'surveySingle', params: {id: survey.id}}" ripple class="elevation-8">
        <v-card-title primary-title>
            <div>
                <div class="headline">{{survey[`title${$i18n.lang}`]}}</div>
                <span class="grey--text">
                    <div>
                            {{ moment().format(localeDateFormats[$i18n.lang].short) }}
                        </div>
                </span>
            </div>
        </v-card-title>
    </v-card>
</template>
<script>
    import moment from "moment";
    import localeDateFormats from "../../i18n/locale-date-formats";

    export default {
        props: {
            survey: {
                type: Object
            }
        },
        data: () => ({
            moment,
            localeDateFormats: localeDateFormats,
        }),
    }
</script>
<style scoped lang="scss">
    .title-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .title {
        display: block;
        padding: 0.5em;
        background: rgba(0, 0, 0, 0.45);
        color: white;
        width: 100%;

        & .headline {
            line-height: 26px !important;
            margin-bottom: 15px;
        }
    }

    .subtitle {
        font-size: 14px;
        font-weight: normal;
        justify-content: space-between;
        display: flex;
        align-items: baseline;

        & div {
            display: inline-flex;
        }
    }
</style>