<template>
    <div>
        <textarea v-show="!imageSrc || shouldChange" class="paste-image-area" @paste="onPaste" 
                  placeholder="Клікніть тут та вставте зображення натискаючи праву кнопку миші та вибравши пункт 'Вставити' або за допомогою комбінації Ctrl+V"
        />
        <img v-if="imageSrc !== null && !shouldChange" width="300px" :src="imageSrc"/>
        <v-progress-linear
                v-if="isLoading"
                color="primary"
                striped
                indeterminate
                rounded
                height="10"
        ></v-progress-linear>
        
        <v-btn @click="shouldChange = true" v-if="imageSrc && !shouldChange">Змінити зображення</v-btn>

        <v-snackbar
                color="info"
                top
                v-model="snackbar"
        >
            Скріншот успішно завантажений!
            <v-btn
                    color="white"
                    text
                    @click="snackbar = false"
            >
                Зрозуміло
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import HttpService from "../../HttpService";

    export default {
        data: () => ({
            imageSrc: null,
            shouldChange: false,
            isLoading: false,
            snackbar: false,
        }),
        props: {
            imageUrl: {
                type: String,
            }
        },
        updated() {
            if (this.imageUrl !== null && this.imageUrl !== "") {
                this.imageSrc = this.imageUrl;
            }
        },
        methods: {
            onPaste(event) {
                let items = (event.clipboardData  || event.originalEvent.clipboardData).items;
                // find pasted image among pasted items
                let blob = null;
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf("image") === 0) {
                        blob = items[i].getAsFile();
                    }
                }
                // load image if there is a pasted image
                if (blob !== null) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.imageSrc = event.target.result;
                        
                        this.uploadImage(blob);
                    };
                    reader.readAsDataURL(blob);
                }
            },
            uploadImage(file){
                this.isLoading = true;
                const formData = new FormData();
                formData.append('file', file);

                HttpService.getInstance(null, null, true)
                    .post("file/upload/finalExamScreenshot", formData)
                    .then(resp => {
                        this.isLoading = false;
                        this.shouldChange = false;
                        this.$emit("uploaded", resp);
                        this.snackbar = true;
                    })
                    .catch(e => {
                        this.isLoading = true;
                    })
            }
        }
    }
</script>

<style lang="scss">
    .paste-image-area {
        border: 2px solid red;
        width: 100%;
        height: 85px;
        color: red;
        padding: 5px;
    }
</style>