<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 160px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-list-item :to="{name: 'subjectsGrid'}"
                                         exact-active-class="list-title-active">
                                <v-list-item-content>
                                    <v-list-item-title>Всі спеціальності</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-subheader class="mt-4 grey--name name--darken-1">Спеціальності</v-subheader>
                            <navigation-list-template :items="specialitiesNav"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 160px; width: calc(100% - 460px);">
                <v-card>
                    <subjects-index/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import NavigationListTemplate from "../../../../components/common/NavigationListTemplate";
    import SubjectsIndex from "../../../../components/admin/Dictionaries/Subjects/SubjectsIndex";

    export default {
        components: {
            SubjectsIndex,
            NavigationListTemplate
        },
        mounted() {
            HttpService.getInstance()
                .get("navigation/getNavSubjects")
                .then(navItems => {
                    this.specialitiesNav = this.remapSubjNavItems(navItems);
                });
        },
        methods: {
            remapSubjNavItems(subjNavItems) {
                if (subjNavItems === undefined) {
                    return [];
                }

                let subjNavigationRemapped = subjNavItems.slice(0);

                subjNavigationRemapped.map(spec => {

                    spec.name = spec.nameShortUk;

                    spec.route = {
                        name: 'subjectsGrid',
                        params: {
                            specialityId: spec.id,
                        }
                    };
                    //
                    // let someSpecActive = spec.find(ch => ch.model);
                    //
                    // if (spec.id.toString() === this.$route.params.speciality && someSpecActive !== undefined) {
                    //     spec.model = true;
                    // }

                    return spec;
                });

                return subjNavigationRemapped;
            },
        },
        data: () => {
            return {
                specialitiesNav: [],
            };
        }
    }
</script>