<template>
    <div>
        <div class="headline">Теми занять</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.navigation="{item}">
                <div class="justify-center d-flex">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click="$emit('navigateTo', item.id, 'quizBankTopic')" v-on="on" icon class="mx-0">
                                <v-icon color="green">assignment</v-icon>
                            </v-btn>
                        </template>
                        <span>Перейти до списку тестів</span>
                    </v-tooltip>
                </div>
            </template>
            
            <template v-slot:item.evaluationMode="{item}">
                {{getEvaluationModeType(item.evaluationMode)}}
            </template>
            
            <template v-slot:item.quizCount="{item}">
                
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import topicEvaluationModeTypes, {topicEvaluationModeEnum} from "../../../constants/topicEvaluationModeTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
          getEvaluationModeType(type) {
              const foundEvaluationType = topicEvaluationModeTypes.find(i => i.id === type);
              
              if (foundEvaluationType) {
                  return foundEvaluationType.nameUk;
              }
              
              return "";
          }  
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Навігація', value: 'navigation', sortable: false},
                {text: '№ п/п', value: 'orderNumber'},
                {text: '№ заняття', value: 'classNumber'},
                {text: 'Назва (укр)', value: 'nameUk'},
                // {text: 'Назва (eng)', value: 'nameEn'},
                {text: 'Академічні години', value: 'academicHours'},
                {text: 'Тип теми', value: 'evaluationMode'},
                {text: 'К-сть тестів', value: 'countQuestions', sortable: false}
            ],
            topicEvaluationModeEnum,
        }),
    }
</script>