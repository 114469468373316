<template>
    <div>
        <div v-if="!this.$auth.userHasAtLeastOneRole([rolesEnum.dean])" class="pb-3 d-flex align-center justify-space-between">
            <div>
                <v-btn @click="confirmDistribute = true"
                       class="ml-4" dark color="red light-4">Розподілити студенти та білети!
                </v-btn>
                <v-btn @click="publishConfirm = true"
                       class="ml-4" dark color="red darken-3">Оголосити результати
                </v-btn>
            </div>

            <div>
                <v-switch
                        class="mr-5"
                        v-model="isBlur"
                        hide-details
                        label="Приховати екзаменатора"
                ></v-switch>
                <v-switch
                        class="mr-5"
                        v-model="isBlurAfter"
                        hide-details
                        label="Приховати оцінки"
                ></v-switch>
            </div>
        </div>

        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <v-flex xs12>
                    <div class="title">Статистика <v-btn small icon @click="getExamStats()"><v-icon>cached</v-icon></v-btn></div>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Іспит</th>
                        <th class="text-left">Загальна кількість</th>
                        <th class="text-left">Кількість проекзаменованих</th>
                        <th class="text-left">Кількість, які залишилися</th>
                        <th>Друк</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :class="{'green lighten-4': examStat.needToPass === 0}" v-for="examStat in examStats" :key="examStat.id">
                        <td>{{ examStat.name }}</td>
                        <td>{{ examStat.all }}</td>
                        <td>{{ examStat.passed }}</td>
                        <td>{{ examStat.needToPass }}</td>
                        <td>
                            <v-btn @click="printBulk(examStat.id)" icon><v-icon>print</v-icon></v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
                </v-flex>
            </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <v-flex xs12>
                    <v-card height="100%" class="px-4 py-2">
                        <div class="d-flex align-center">
                            <v-text-field
                                    clearable
                                    autofocus
                                    :loading="loading"
                                    label="Прізвище студента"
                                    hide-details
                                    class="ml-2"
                                    v-model="filter.studentName"
                                    @click:clear="getItems()"
                            />
                            <v-text-field
                                    clearable
                                    :loading="loading"
                                    label="Прізвище екзаменатора"
                                    hide-details
                                    class="ml-2"
                                    v-model="filter.examinerName"
                                    @click:clear="getItems()"
                            />
                            <v-select
                                    :items="examNames"
                                    label="Назва іспиту"
                                    hide-details
                                    class="ml-2"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    v-model="filter.examNameType"
                            ></v-select>
                            <v-btn class="ml-5" @click="clearFilter()">Очистити</v-btn>
                            <v-btn class="ml-5" color="primary" @click="getItems()">Пошук</v-btn>
                        </div>
                    </v-card>
                </v-flex>
                <v-flex xs12>
                    <admin-student-list-grid
                            :students="items"
                            :loading="loading"
                            :is-blur="isBlur"
                            :is-blur-after="isBlurAfter"
                    >
                    </admin-student-list-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="publishConfirm" max-width="700">
            <publish-confirm
                    @shouldCloseDeleteConfirm="publishConfirm = false"
                    @removed="removed"
            >
            </publish-confirm>
        </v-dialog>

        <v-dialog v-model="confirmDistribute" max-width="700">
            <confirm-distribute
                    @shouldCloseDeleteConfirm="confirmDistribute = false"
                    @removed="removed"
            >
            </confirm-distribute>
        </v-dialog>
        
        <v-dialog v-model="dialogMultiplePrint" max-width="1200">
            <print-multiple-protocols-dialog 
                    v-if="dialogMultiplePrint" 
                    :exam-name-id="dialogMultiplePrintExamNameId"
                    @close="dialogMultiplePrint = false"
            >
            </print-multiple-protocols-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AdminStudentListGrid from "./AdminStudentListGrid";
    import moment from "moment";

    import PublishConfirm from "./PublishConfirm";
    import ConfirmDistribute from "./ConfirmDistribute";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import PrintMultipleProtocolsDialog from "./PrintMultipleProtocolsDialog";

    export default {
        components: {
            AdminStudentListGrid,
            PublishConfirm,
            ConfirmDistribute,
            PrintMultipleProtocolsDialog,
        },
        data: () => {
            return {
                dialogSubjectSettings: false,
                dialogGroupsSettings: false,

                items: [],
                loading: false,

                confirmDistribute: false,
                publishConfirm: false,

                interval: null,
                
                examNames: [],
                
                filter: {
                    studentName: "",
                    examinerName: "",
                    examNameType: {
                        id: null,
                    }
                },
                examStats: [],
                
                rolesEnum,
                
                dialogMultiplePrint: false,
                dialogMultiplePrintExamNameId: null,
                
                isBlur: false,
                isBlurAfter: false,
            }
        },
        mounted() {
            this.getItems();
            
            this.getExamNames();
            
            this.getExamStats();

            this.interval = setInterval(function () {
                this.getItems();
            }.bind(this), 30000);
        },
        methods: {
            getItems() {
                this.loading = true;
                
                const examTypeId = this.filter.examNameType ? this.filter.examNameType.id : null;

                HttpService.getInstance()
                    .get(`FinalExamAdminManage/getExamStudentsLists/${this.$route.params.specialityId}`+
                        `?dateStr=${this.$route.params.date}&examNameId=${examTypeId}&examinerName=${this.filter.examinerName}&studentName=${this.filter.studentName}`)
                    .then(items => {
                        this.items = items;
                        this.total = items.length;

                        this.loading = false;
                    })
            },
            
            getExamNames() {
                HttpService.getInstance()
                .get(`FinalExamAdmin/getFinalExamNames/${this.$route.params.specialityId}`)
                .then(items => {
                    this.examNames = items;
                });
            },
            getExamStats() {
                HttpService.getInstance()
                    .get(`FinalExamAdminManage/GetExamStats/${this.$route.params.specialityId}?dateStr=${this.$route.params.date}`)
                    .then(items => {
                        this.examStats = items;
                    });
            },
            
            removed() {
                this.confirmDistribute = false;
                this.publishConfirm = false;

                this.getItems();
            },

            clearFilter() {
                this.filter.examNameType = null;
                this.filter.examinerName = "";
                this.filter.studentName = "";
                
                this.getItems();
            },

            printBulk(examNameId) {
                this.dialogMultiplePrintExamNameId = examNameId;
                this.dialogMultiplePrint = true;
            }
        },
        beforeDestroy: function () {
            clearInterval(this.interval);
        }
    }
</script>

<style lang="scss">
    .blured {
        filter:blur(4px);
    }
</style>