var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VueFileAgent',{attrs:{"uploadUrl":`${_vm.configuration.apiEndpoint}/eAristoProfessor/topic/${_vm.topicId}/upload/presentationPdf`,"uploadHeaders":{
       'Cache-Control': '',
       'Authorization': `Bearer ${_vm.getAccessToken()}`
    },"multiple":false,"deletable":true,"linkable":true,"meta":true,"accept":'application/pdf',"maxSize":'50MB',"maxFiles":1,"helpText":'Виберіть PDF файл (зберігайте вашу презентацію в PDF в Microsoft Power Point)',"errorText":{
        type: 'Виберіть, будь ласка, файл формату PDF',
        size: 'Ви вибрали надто великий файл!',
    },"thumbnailSize":300},on:{"select":function($event){return _vm.filesSelected($event)},"upload":function($event){return _vm.filesUploaded($event)}},scopedSlots:_vm._u([{key:"file-preview",fn:function(){return [_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"size":150,"color":"primary","indeterminate":""}})],1),_c('v-icon',{staticClass:"icon-presentation",attrs:{"x-large":""}},[_vm._v("contacts")])],1)]},proxy:true}]),model:{value:(_vm.presentationFile),callback:function ($$v) {_vm.presentationFile=$$v},expression:"presentationFile"}})
}
var staticRenderFns = []

export { render, staticRenderFns }