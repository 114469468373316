import AdminRootView from "../views/superAdminStep/SuperAdminStepRootView";

import EmptyView from '../views/common/EmptyView';
import MockView from '../views/MockView';

import ProfilesView from '../views/superAdminStep/Dictionaries/Other/ProfilesView';
import KroksView from '../views/superAdminStep/Dictionaries/Other/KroksView';
import QuestionPoolsView from '../views/superAdminStep/Dictionaries/Other/QuestionPoolsView';
import SubjectPoolView from '../views/superAdminStep/Dictionaries/Other/SubjectPoolView';
import StatsView from '../views/superAdminStep/Dictionaries/Other/StatsView';

export default (router) => {
    return [
        {
            path: '/superAdminStep',
            component: AdminRootView,
            name: 'superAdminStepRoot',
            redirect: {name: 'profilesGrid'},
            children: [
                {
                    path: 'in-development',
                    name: 'inDevelopment',
                    component: MockView,
                },
                {
                    path: 'dictionaries',
                    component: EmptyView,
                    children: [
                        {
                            path: 'profiles',
                            name: 'profilesGrid',
                            component: ProfilesView,
                        },
                        {
                            path: 'questionPools',
                            name: 'questionPoolsGrid',
                            component: QuestionPoolsView,
                        },
                        {
                            path: 'subjectPool/:subject?',
                            name: 'subjectPoolGrid',
                            component: SubjectPoolView,
                        },
                        {
                            path: 'stats/:speciality?/:semester?/:department?',
                            name: 'stats',
                            component: StatsView,
                        },
                        {
                            path: 'krok',
                            name: 'krokGrid',
                            component: KroksView,
                        },
                    ]
                },

            ],
        }
    ]
}