<template>
    <div>
        <admin-exam-settings-examiners-grid
                :items="items"
                :total="total"
                :loading="loading"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </admin-exam-settings-examiners-grid>

        <v-dialog v-model="dialog" max-width="900px" scrollable>
            <add-edit-admin-exam-settings-examiners
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-admin-exam-settings-examiners>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="500">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import AdminExamSettingsExaminersGrid from "./AdminExamSettingsExaminersGrid";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditAdminExamSettingsExaminers from "./AddEditAdminExamSettingsExaminers";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            AdminExamSettingsExaminersGrid,
            AddEditAdminExamSettingsExaminers,
            DeleteConfirm
        },
        mounted() {
            this.getItems();
        },
        methods: {
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`finalExamAdminExaminers/getFinalExamExaminers/${this.$route.params.examId}`)
                    .then(items => {
                        this.items = items.items;
                        this.total = items.total;
                        this.loading = false;
                    })
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems();
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems();
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>