<template>
    <v-card>
        <v-card-title>
            <span class="headline">Деталізація для студента {{student.surname}} {{student.name}}</span>
        </v-card-title>
        
        <v-card-text>
            <v-simple-table v-if="!loading">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left" style="width: 80%">
                            Тема
                        </th>
                        <th class="text-left">
                            Статус
                        </th>
                        <th class="text-left">
                            %
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="individualWorkTopic in individualWorkTopics">
                        <td>{{individualWorkTopic.orderNumber}}. {{individualWorkTopic.nameUk}}</td>
                        <td>
                            <v-icon v-if="individualWorkTopic.isPassed === null" color="red">remove_circle_outline</v-icon>
                            <v-icon v-if="individualWorkTopic.isPassed === false" color="gray">cancel</v-icon>
                            <v-icon v-if="individualWorkTopic.isPassed === true" color="green">check_circle</v-icon>
                        </td>
                        <td>{{individualWorkTopic.percentage ?? "-"}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-layout v-if="loading" align-center justify-center>
                <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
            >Закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import HttpService from "@/HttpService";

    export default {
        props: {
            activeModule: {
                type: Object
            },
            student: {
                type: Object
            }
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                loading: false,
                
                individualWorkTopics: [],

                errors: {},
            }
        },
        mounted() {
          this.getDetails(); 
        },
        methods: {
            getDetails() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherIndividualWorkMarks/getTopicsStatus/${this.activeModule.id}/${this.student.id}`)
                    .then(res => {
                        this.individualWorkTopics = res;
                        
                        this.loading = false;
                    });
            },
        }
    }
</script>