<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <academic-statuses-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import AcademicStatusesIndex from "../../../../components/admin/Dictionaries/AcademicStatuses/AcademicStatusesIndex";
    export default  {
        components: {
            AcademicStatusesIndex,
        }
    }
</script>