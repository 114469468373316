<template>
    <div>
        <rating-worktypes-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @navigateTo=""
        >
        </rating-worktypes-grid>

        <v-dialog v-model="dialog" max-width="850px" scrollable>
            <add-edit-worktype
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-worktype>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="400">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="orange"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import RatingWorktypesGrid from "./RatingWorktypesGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AddEditWorktype from "./AddEditWorktype";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            RatingWorktypesGrid,
            AddEditWorktype,
            DeleteConfirm
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingWorktypes/${this.$route.params.direction}?${queryParams}`)
                    .then(items => {
                        this.items = items.items;
                        this.total = items.total;

                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;                 
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited(createAnother) {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
                
                if (createAnother) {
                    setTimeout(() => {
                        this.addItem();
                    }, 200);
                }
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
    }
</script>