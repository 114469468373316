<template>
    <v-card>
        <v-card-title>
            <span v-if="activeModule.finalControlType === finalControlTypesEnum.finalModuleControl" class="headline">Додавання результату модульного контролю</span>
            <span v-if="activeModule.finalControlType === finalControlTypesEnum.nonEvaluableCredit" class="headline">Додавання результату заліку</span>
        </v-card-title>
        <v-card-text>
            <module-selector
                    v-if="activeModule.finalControlType === finalControlTypesEnum.finalModuleControl"
                    :activeModule="activeModule" 
                    :loadingSave="loadingSave" 
                    :errors="errors"
                    @change="onChangeModule($event)"
            />
            <module-non-evaluable-selector
                    v-if="activeModule.finalControlType === finalControlTypesEnum.nonEvaluableCredit"
                    :activeModule="activeModule"
                    :loadingSave="loadingSave"
                    :errors="errors"
                    @change="onChangeModule($event)"
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="orange darken-1"
                    dark
                    type="button"
                    :disabled="loading"
                    @click="saveModule"
            >
                <span v-if="activeModule.finalControlType === finalControlTypesEnum.finalModuleControl">Внести результат МК</span>
                <span v-if="activeModule.finalControlType === finalControlTypesEnum.nonEvaluableCredit">Внести результат заліку</span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import ModuleSelector from "./ModuleSelector";
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import finalControlTypes, {finalControlTypesEnum} from "../../../constants/admin/finalControlTypes";
    import ModuleNonEvaluableSelector from "./ModuleNonEvaluableSelector";

    export default {
        props: {
            activeModule: {
                type: Object
            }
        },
        components: {
            LoadingPlaceholder,
            ModuleSelector,
            ModuleNonEvaluableSelector,
        },
        data: () => {
            return {
                loading: false,
                moduleMarkValues: {
                    moduleViews: []
                },

                loadingSave: false,
                errors: {},
                finalControlTypesEnum,
            }
        },
        mounted() {
            this.$ga.event('professor', 'addModule', 'groupId', this.$route.params.group);

            this.errors = {};  
        },
        methods: {
            passNextStep(stepNumber) {
                this.activeStep = stepNumber;
            },
            onChangeModule(value) {
                this.moduleMarkValues = value;
            },
            saveModule() {
                this.loadingSave = true;
                
                let url = "";
                
                if (this.activeModule.finalControlType === finalControlTypesEnum.finalModuleControl) {
                    url = `teacher/teacherModuleMarks/${this.activeModule.id}/${this.$route.params.group}`;
                }

                if (this.activeModule.finalControlType === finalControlTypesEnum.nonEvaluableCredit) {
                    url = `teacher/teacherModuleMarks/nonEvaluable/${this.activeModule.id}/${this.$route.params.group}`;
                }
                
                HttpService.getInstance()
                    .post(url,
                        {
                            ...this.moduleMarkValues.moduleViews,
                            ...{
                                professor: {id: 1},
                                department: {id: this.$route.params.department},
                                subject: {id: this.$route.params.subject},
                            }
                        }
                    )
                    .then(resp => {
                        this.loadingSave = false;
                        this.isSaved = true;
                        this.$emit('shouldSaveAndClose');
                    })
                    .catch(err => {                        
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>