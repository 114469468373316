<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <v-list dense>
            <navigation-drawer-lectures-nav
                    @toggleDrawer="drawerApp = $event"
                    :drawer="drawerApp"
            />
        </v-list>
        <toolbar
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-main>
            <router-view/>
        </v-main>

        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/admin/common/Toolbar";
    import TeacherNavigationDrawer from "../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import NavigationDrawerLecturesNav from "../../components/admin/common/NavigationDrawerLecturesNav";

    export default {
        components: {
            Toolbar,
            NavigationDrawerLecturesNav,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
