<template>
    <div class="mt-4">
        <v-expansion-panels v-model="expandable">
            <v-expansion-panel lazy>
                <v-expansion-panel-header>
                    <div><v-icon>format_list_numbered</v-icon> Перегляд інформації по модулях</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <modules-overview />
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel lazy>
                <v-expansion-panel-header>
                    <div><v-icon>sort</v-icon> Перегляд середніх оцінок</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <marks-mean />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
    import BlockLoader from "../../common/BlockLoader";
    
    import ModulesOverview from "../../common/ModulesOverview";
    import MarksMean from "../../common/MarksMean";

    export default {
        components: {
            BlockLoader,
            ModulesOverview,
            MarksMean
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                expandable: 0,
            }
        },
    }
</script>

