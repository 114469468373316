<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.nameUk" v-model="form.nameUk"
                                          label="Назва (укр)"></v-text-field>
                        </v-flex>
<!--                        <v-flex xs12>-->
<!--                            <v-text-field :error-messages="errors.nameEn" v-model="form.nameEn"-->
<!--                                          label="Назва (eng)"></v-text-field>-->
<!--                        </v-flex>-->
                        <v-flex xs12 md6>
                            <v-text-field :error-messages="errors.nameShortUk" v-model="form.nameShortUk"
                                          label="Скорочено (укр)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field :error-messages="errors.nameShortEn" v-model="form.nameShortEn"
                                          label="Скорочено (eng)"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                        <v-checkbox
                              label="Оплачувані пропуски"
                              v-model="form.isSubjectPayable"
                              required
                            ></v-checkbox>
                                        
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-text-field :error-messages="errors.reworkPrice" 
                                          v-model="form.reworkPrice"
                                          v-mask="['###']"
                                          suffix="UAH"
                                          label="Ціна відпрацювання"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-radio-group :error-messages="errors.finalControlType" 
                                           v-model="form.finalControlType" 
                                           :mandatory="false">
                                <v-radio v-for="(finalControlType, index) in getFinalControlTypes()" 
                                         color="primary" 
                                         :key="index"
                                         :label="finalControlType.nameConst" 
                                         :value="finalControlType.typeEnum"
                                ></v-radio>
                            </v-radio-group>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch
                                    label="Блокування модулів"
                                    v-model="form.canBlockModules"
                                    color="primary"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12 md6>
                            <v-switch
                                    label="Блокування тем"
                                    v-model="form.canBlockTopics"
                                    color="primary"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                    :items="departments"
                                    :error-messages="errors.departments"
                                    v-model="form.departments"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Кафедри"
                                    multiple
                                    chips
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                    :items="specialities"
                                    :error-messages="errors.specialities"
                                    v-model="form.specialities"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Спеціальності на яких викладається дисципліна"
                                    multiple
                                    chips
                            ></v-select>
                        </v-flex>
                        <v-flex v-if="!editMode" xs12>
                            <v-flex xs12 md6>
                                <v-switch
                                        label="Додати всім викладачам вибраних (-ої) кафедр (-и)"
                                        v-model="form.addToAllProfessors"
                                        color="red"
                                ></v-switch>
                            </v-flex>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import finalControlTypes from "../../../../constants/admin/finalControlTypes";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                departments: [],
                specialities: [],
                semesters: [],
                academicSemesters: [],
                form: {
                    nameUk: null,
                    nameEn: null,
                    nameShortUk: null,
                    nameShortEn: null,
                    isSubjectPayable: false,
                    reworkPrice: null,
                    finalControlType: null,
                    canBlockModules: false,
                    canBlockTopics: false,
                    departments: [],
                    specialities: [],
                    addToAllProfessors: false,
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    nameShortUk: [],
                    nameShortEn: [],
                    reworkPrice: [],
                    finalControlType: [],
                    canBlockModules: [],
                    canBlockTopics: [],
                    departments: [],
                    specialities: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("departments/getAll")
                .then(resp => {
                    this.departments = resp;
                });

            HttpService.getInstance()
                .get("specialities/getAll")
                .then(resp => {
                    this.specialities = resp;
                });

            
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`subjects/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("subjects", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
            getFinalControlTypes() {
                return finalControlTypes;
            }
        }
    }
</script>