<template>
    <VueFileAgent
        :uploadUrl="`${configuration.apiEndpoint}/eAristoProfessor/topic/${topicId}/upload/video`"
        :uploadHeaders="{
           'Cache-Control': '',
           'Authorization': `Bearer ${getAccessToken()}`
        }"
        :multiple="false"
        :deletable="true"
        :linkable="true"
        :meta="true"
        :accept="'video/mp4'"
        :maxSize="'2048MB'"
        :maxFiles="1"
        :helpText="'Виберіть MPEG-4/MP4 файл'"
        :errorText="{
            type: 'Виберіть, будь ласка, файл формату MPEG-4/MP4',
            size: 'Ви вибрали надто великий файл!',
        }"
        :thumbnailSize="300"
        @select="filesSelected($event)"
        @upload="filesUploaded($event)"
        v-model="presentationFile"
    >
        <template v-slot:file-preview>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-layout>
                <v-icon class="icon-video" x-large>movie</v-icon>
            </v-container>
        </template>
    </VueFileAgent>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";

let configuration = AppConfig(config);

export default {
    name: "VideoUploader",
    props: {
        topicId: {
            type: Number,
        },
    },
    methods: {
        getAccessToken() {
            return localStorage.getItem('access_token');
        },
        filesSelected() {
            this.loadingSave = true;
            this.uploaded = true;
        },
        filesUploaded(files) {
            console.log("files", files[0].data);
            this.$emit('uploadedFiles', files[0].data)
            this.loadingSave = false;
        },
    },
    data: () => {
        return {
            configuration,
            loadingSave: false,
            presentationFile: null,
            uploaded: false,
        };
    },
}
</script>

<style lang="scss">
.vue-file-agent .file-preview-wrapper:before {
    background: rgba(0,0,0,.05);
}

.icon-video {
    position: absolute !important;
    left: 40%;
}
</style>