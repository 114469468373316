<template>
    <div>
        <v-progress-linear
                v-if="loadingState > 0"
                height="3"
                style="margin: 0; z-index: 999; position: absolute"
                :indeterminate="true"
                :color="color"
        ></v-progress-linear>
        <v-app-bar
                :color="color"
                dark
                app
                :clipped-left="$vuetify.breakpoint.mdAndUp"
                fixed
        >
            <v-toolbar-title style="width: 200px" class="ml-0 pl-0 cursor-pointer" @click="navigateToHome()">
                <v-app-bar-nav-icon @click.stop="$emit('toggleDrawer')"></v-app-bar-nav-icon>
                <toolbar-logo></toolbar-logo>
            </v-toolbar-title>

            <language-switcher v-if="isLangSwitcher"/>

            <v-spacer></v-spacer>
            
            <quick-navigation/>
            <logout-button/>
            
        </v-app-bar>
    </div>
</template>
<script>
    import LanguageSwitcher from "../common/LanguageSwitcher";
    import QuickNavigation from "../common/toolbar-controls/QuickNavigation";
    import LogoutButton from "../common/toolbar-controls/LogoutButton";
    import ToolbarLogo from "./ToolbarLogo";

    export default {
        components: {
            QuickNavigation,
            LogoutButton,
            LanguageSwitcher,
            ToolbarLogo
        },
        props: {
            color: {
                type: String,
                default: 'info'
            },
            isLangSwitcher: {
                type: Boolean,
                default: true,
            }
        },
        methods: {
            navigateToHome() {
                this.$router.push('/')
                    .catch(err => {});
            }  
        },
        computed: {
            loadingState() {
                return this.$store.getters['loadingState/loadingState'];
            },
        },
    }
</script>