<template>
    <div v-if="!loading">
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        {{$t("eAristoStudent.subjectName")}}
                    </th>
                    <th class="text-left">
                        {{$t("eAristoStudent.action")}}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="subject in subjects"
                    :key="subject.id"
                >
                    <td>{{ subject.name }}</td>
                    <td>
                        <v-btn icon
                               :to="{ name: 'eAristoStudentSubjectModuleList', params: { subjectId: subject.id }}">
                            <v-icon color="primary">send</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
    <div v-else>
        <e-aristo-loading-placeholder></e-aristo-loading-placeholder>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import eAristoLoadingPlaceholder from "../common/eAristoLoadingPlaceholder";

export default {
    components: {
        eAristoLoadingPlaceholder,
    },
    data() {
        return {
            loading: false,
            subjects: [],
        }
    },
    mounted() {
        this.loading = true;
        HttpService.getInstance()
            .get("navigationStudent/getSubjectsByStudent")
            .then(res => {
                this.subjects = res.map(subject => {
                    const specs = subject.specialities.map(s => s.nameShortUk);

                    return {
                        id: subject.id,
                        name: `${subject.nameUk} (${specs.join(", ")})`,
                    };
                });

                this.loading = false;
            });
    },
}
</script>