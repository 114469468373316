<template>
    <v-menu v-if="$auth.isAuthenticated()" offset-y left transition="scale-transition">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
                <v-icon>apps</v-icon>
            </v-btn>
        </template>
        <v-list two-line>
            <v-list-item :to="{name: 'helpdeskRoot'}" class="help-desk-item">
                <v-list-item-content>
                    <v-list-item-title>HelpDesk</v-list-item-title>
                    <v-list-item-subtitle>Служба підтримки користувачів</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :key="index" :to="{name: cardItem.link}" v-for="(cardItem, index) in cardItems">
                <v-list-item-content>
                    <v-list-item-title>{{cardItem.title}}</v-list-item-title>
                    <v-list-item-subtitle>{{cardItem.description}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    import {cardItemsInitial} from "../../../constants/dashboardCardItems";

    export default {
        methods: {
            setCardItems() {
                const mappedCards = cardItemsInitial.map(card => {
                    card.title = this.$t(card.titlePath);
                    card.description = this.$t(card.descriptionPath);

                    return card;
                });

                return mappedCards.filter(card => {
                    return this.$auth.hasRoles(card.role);
                });
            }
        },
        mounted() {
            this.cardItems = this.setCardItems();
        },
        watch: {
            '$i18n.locale': function () {
                this.cardItems = this.setCardItems();
            }
        },
        data: () => {
            return {
                cardItems: [],
            }
        }
    }
</script>

<style lang="scss">
    .help-desk-item {
        background: #FFCDD2;
    }
</style>