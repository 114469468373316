<template>
    <div class="ma-5">
        <div class="display-1 mb-3">
            <v-btn color="primary" v-if="$route.name === 'adminStudentExamListDetail'"
                   :to="{name: 'adminStudentExamList', 
                                        params: {
                                                date: $route.params.date,
                                                specialityId: $route.params.specialityId,
                                        }
                                    }"
                   class="mr-3">
                <v-icon>keyboard_backspace</v-icon> Назад
            </v-btn>
            Оцінювання студента <v-btn @click="printDialog = true" large icon><v-icon>print</v-icon> </v-btn>
        </div>
        <v-card class="px-4 py-2 mb-2" v-if="finalExamStudent && finalExamStudent.student">
            <v-layout wrap>
                <v-flex xs4>
                    <div class="body-2">ПІП студента</div>
                    <div class="title">
                        {{finalExamStudent.student.surname}}
                        {{finalExamStudent.student.name}}
                        {{finalExamStudent.student.patronymic}}
                    </div>
                </v-flex>
                <v-flex xs2>
                    <div class="body-2">Академічна група</div>
                    <div class="title">
                        {{finalExamStudent.student.academicGroup.groupNumber}}
                    </div>
                </v-flex>
                <v-flex xs3>
                    <div class="body-2">Факультет та спеціальність</div>
                    <div class="title">
                        {{finalExamStudent.student.faculty.nameShortUk}}
                        {{finalExamStudent.student.speciality.nameShortUk}}
                    </div>
                </v-flex>
                <v-flex xs3 class="text--darken-3 red--text">
                    <div class="body-2">Номер екзаменаційного білету</div>
                    <div class="title">
                        {{finalExamStudent.examinationNumber}}
                    </div>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-flex xs4>
                <image-uploader
                        class="mt-3"
                        v-model="finalExamStudent.finalExamScreenshot"
                        :image-endpoint="`${$config.apiEndpoint}/file/get`"
                        :type="fileUploadEnum.finalExamScreenShot">
                </image-uploader>
                </v-flex>
                <v-flex xs4 class="pa-4">
                    <v-alert type="info">Ви можете зробити скріншот та завантажити його АЛЬТЕРНАТИВНИМ шляхом. 
                        Для цього перейдіть на вкладку з GoogleMeet-ом, натисніть на клавіатурі кнопку PrScr або PrintScreen, потім
                        перейдіть до цього вікна та виконайте інструкції, які вказані праворуч. 
                        <v-icon style="font-size: 50px;">arrow_right_alt</v-icon>
                    </v-alert>

                    <v-btn v-if="finalExamStudent.finalExamScreenshot"
                           @click="showScreenShotFn(finalExamStudent.finalExamScreenshot)"
                           color="orange darken-3" dark small>
                        Переглянути скріншот у повний розмір
                    </v-btn>
                </v-flex>
                <v-flex xs4 class="pa-4">
                    <image-clipboard-uploader 
                            :image-url="`${$config.apiEndpoint}/file/get/finalExamScreenshot/${finalExamStudent.finalExamScreenshot}`"
                            @uploaded="finalExamStudent.finalExamScreenshot = $event"
                    ></image-clipboard-uploader>
                </v-flex>
            </v-layout>
        </v-card>

        <v-card class="px-4 py-2 mb-5">
            <v-layout wrap>
                <v-flex xs12>
                    <div class="title">Перша частина (загальні уміння та навички)</div>
                </v-flex>
                <v-flex xs12>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">№ п/п</th>
                                <th class="text-left">Назва умінь та навичок</th>
                                <th class="text-left">Бал</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in generalSkills" :key="item.name">
                                <td>{{ item.order }}</td>
                                <td>{{ item.name }}</td>
                                <td>
                                    <v-btn-toggle v-model="item.value" mandatory>
                                        <v-btn :value="0" color="red darken-1">0</v-btn>
                                        <v-btn :value="0.5" color="blue darken-1">0.5</v-btn>
                                        <v-btn :value="1" color="green darken-3">1.0</v-btn>
                                    </v-btn-toggle>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="mt-3 subtitle-1" v-if="generalSkills.length > 0">
                        Середнє значення (СА1): сума балів / {{generalSkills.length}}:
                        <strong>{{getBaseMean.toFixed(2)}}</strong></div>
                </v-flex>
            </v-layout>
        </v-card>
        <v-card class="px-4 py-2 mb-5">
            <v-layout wrap>
                <v-flex xs12>
                    <div class="title">Друга частина (Демонстрація основних умінь)</div>
                </v-flex>
                <v-flex xs12>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">№ п/п</th>
                                <th class="text-left">Назва умінь та навичок</th>
                                <th class="text-left">Бал</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in mainSkills" :key="item.name">
                                <td>{{ item.order }}</td>
                                <td>{{ item.name }}</td>
                                <td>
                                    <v-btn-toggle v-model="item.value" mandatory>
                                        <v-btn :value="0" color="red darken-1">0</v-btn>
                                        <v-btn :value="1" color="green darken-3">1.0</v-btn>
                                    </v-btn-toggle>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="mt-3 subtitle-1" v-if="mainSkills.length > 0">
                        Середнє значення (СА1): сума балів / {{mainSkills.length}}:
                        <strong>{{getMainMean.toFixed(2)}}</strong></div>
                </v-flex>
            </v-layout>
        </v-card>

        <v-card class="px-4 py-2">
            <v-layout wrap>
                <v-flex xs12>
                    <div class="title">Підсумкова оцінка студента за іспит:</div>
                    <table class="table-sum">
                        <tr>
                            <td>Оцінка за 200-бальною шкалою (СА1+СА2)/2*200</td>
                            <td>Оцінка за чотирибальною шкалою</td>
                        </tr>
                        <tr>
                            <td>{{getCalculateAll.toFixed(2)}}</td>
                            <td>{{getCalculateClassic}}</td>
                        </tr>
                    </table>
                </v-flex>
            </v-layout>
        </v-card>

        <v-card class="px-4 py-2 mt-4" v-if="resp && resp.professor">
            <v-layout wrap>
                <v-flex xs12>
                    <div class="title">Екзаменатор:</div>
                    <div class="subtitle-1">{{resp.professor.surname}} {{resp.professor.name}} {{resp.professor.patronymic}}</div>
                    
                </v-flex>
            </v-layout>
        </v-card>

        <v-btn @click="saveResults()"
               v-if="((!finalExamStudent.isPublishedResult && !isFromAdmin) || 
                (!finalExamStudent.isPublishedResult && isFromAdmin && 
                !$auth.userHasAtLeastOneRole([rolesEnum.dean]))
               )"
               :disabled="isLoading"
               :loading="isLoading"
               color="primary"
               class="mt-5"
               large>
            Зберегти результати
        </v-btn>
        
        <v-dialog max-width="1100" v-model="printDialog">
            <print-protocol-dialog :exam-id="this.examId" @close="printDialog = false"></print-protocol-dialog>
        </v-dialog>
        
        <v-dialog max-width="1300" v-model="screenShotDialog">
            <preview-screen-shot v-if="screenShotDialog" :photo-url="screenShotUrlId"></preview-screen-shot>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {finalExamCriteriaEnum} from "../../../constants/_internal/finalExamCriteriaEnum";
    import ImageUploader from "../../common/ImageUploader";
    import {fileUploadEnum} from "../../../constants/_internal/fileUploadEnum";
    import PrintProtocolDialog from "./PrintProtocolDialog";
    import PreviewScreenShot from "../common/PreviewScreenShot";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import ImageClipboardUploader from "../../common/ImageClipboardUploader";

    export default {
        components: {
            PrintProtocolDialog,
            ImageUploader,
            PreviewScreenShot,
            ImageClipboardUploader,
        },
        data: () => ({
            finalExamStudent: {},
            generalSkills: [],
            mainSkills: [],
            finalExamCriteriaEnum,

            resp: {},

            isLoading: false,
            fileUploadEnum,
            
            printDialog: false,

            screenShotDialog: false,
            screenShotUrlId: null,

            rolesEnum
        }),
        props: {
            examId: {
                type: Number,
            },
            isSetStarted: {
                type: Boolean,
            },
            isFromAdmin: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.getDetailItem();
            
            if (this.isSetStarted) {
                this.setStarted();
            }
        },
        methods: {
            showScreenShotFn(screenShotUrl) {
                this.screenShotDialog = true;
                this.screenShotUrlId = screenShotUrl;
            },
            
            setStarted() {
                HttpService.getInstance(null, null, true)
                .post(`FinalExamTeacher/SetStartedExam/${this.examId}`)
                .then(resp => {
                    
                });
            },
            
            getDetailItem() {
                HttpService.getInstance()
                    .get(`FinalExamTeacher/GetStudentCriteriaResult/${this.examId}`)
                    .then(resp => {
                        this.resp = resp;

                        this.finalExamStudent = resp.finalExamStudent;

                        this.generalSkills = resp.results.filter(r => r.criteriaType === finalExamCriteriaEnum.generalSkills);
                        this.mainSkills = resp.results.filter(r => r.criteriaType === finalExamCriteriaEnum.mainSkills);
                    });
            },
            saveResults() {
                this.isLoading = true;
                
                const resp = {...this.resp, totalClassicValue: this.getCalculateClassic, totalValue: this.getCalculateAll};
                
                HttpService.getInstance()
                    .post(`FinalExamTeacher/SaveStudentCriteriaResult/${this.examId}`, resp)
                    .then(resp => {
                        this.isLoading = false;
                    });

            }
        },
        computed: {
            getBaseMean() {
                const arrayOfMarks = this.generalSkills.map(b => b.value);

                const sum = arrayOfMarks.reduce((a, b) => a + b, 0);

                if (this.generalSkills.length > 0) {
                    return sum / this.generalSkills.length;
                } else {
                    return 0;
                }
            },
            getMainMean() {
                const arrayOfMarks = this.mainSkills.map(b => b.value);

                const sum = arrayOfMarks.reduce((a, b) => a + b, 0);

                if (this.mainSkills.length > 0) {
                    return sum / this.mainSkills.length;
                } else {
                    return 0;
                }
            },

            getCalculateAll() {
                if (this.generalSkills.length === 0) {
                    return 0;
                }
                
                if (this.generalSkills.length !== 0 && this.mainSkills.length !== 0) {
                    return ((this.getBaseMean + this.getMainMean) / 2) * 200;
                }
                else if (this.generalSkills.length !== 0 && this.mainSkills.length === 0) {
                    return ((this.getBaseMean) / 1) * 200;
                }
                else if (this.generalSkills.length === 0 && this.mainSkills.length !== 0) {
                    return ((this.getMainMean) / 1) * 200;
                }

            },

            getCalculateClassic() {
                const allPoints = this.getCalculateAll;

                if (allPoints <= 200 && allPoints >= 180) {
                    return 5;
                } else if (allPoints >= 140) {
                    return 4;
                } else if (allPoints >= 101) {
                    return 3;
                } else if (allPoints < 101) {
                    return 2;
                } else {
                    return 0;
                }
            },
        }
    }
</script>
<style scoped lang="scss">
    .v-btn-toggle > .v-btn:not(.v-btn--active) {
        opacity: 0.3;
    }

    .v-btn-toggle > .v-btn.v-btn--active {
        color: white;
    }

    .table-sum td {
        border: 1px solid #ccc;
        padding: 10px;
    }
</style>