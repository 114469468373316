<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :loading="loading"
        :options.sync="pagination"
        class="elevation-3"
        :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-left">{{
                        props.item.semester !== undefined ?
                            `${props.item.semester.yearNumber} курс (${props.item.semester.semesterNumber} семестр)` : ''
                    }}
                </td>
                <td class="text-xs-left">{{
                        props.item.speciality !== undefined ? props.item.speciality.name : ''
                    }}
                </td>
                <td width="200" class="text-xs-left">
                    <v-chip v-for="profile in props.item.profiles" :key="profile.id">
                        {{ `${profile.name} (${profile.percentage}%)` }}
                    </v-chip>
                </td>
                <td class="justify-center layout px-0">
                    <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loading: {
            type: Boolean,
        },
    },
    watch: {
        pagination(paginationVal, prevPaginationVal) {
            this.$emit("paginate", paginationVal);
        }
    },
    methods: {
        mapSpecialitiesToString(specialities) {
            return specialities.map(spec => spec.nameUk).join(", ");
        }
    },
    data: () => ({
        pagination: {
            totalItems: 0,
        },
        headers: [
            {text: 'Назва', value: 'name'},
            {text: 'Семестр', value: 'semester.semesterNumber'},
            {text: 'Спеціальність', value: 'speciality.name'},
            {text: 'Дисципліни', value: 'objects'},
            {text: 'Дії', value: 'actions', sortable: false}
        ],
    }),
}
</script>