<template>
    <div>
        <v-btn color="red" dark @click="dialogRemoveVideo = true">Видалити осиротілі файли eAristo Video</v-btn>
        <v-btn class="ml-5" color="red" dark @click="dialogRemovePresentations = true">Видалити осиротілі файли eAristo Presentations</v-btn>

        <v-dialog v-model="dialogRemoveVideo" max-width="1000px">
            <delete-confirm-video
                v-if="dialogRemoveVideo"
                @shouldCloseDeleteConfirm="dialogRemoveVideo = false"
                @removed="removedVideo"
            >
            </delete-confirm-video>
        </v-dialog>

        <v-dialog v-model="dialogRemovePresentations" max-width="1000px">
            <delete-confirm-presentations
                v-if="dialogRemovePresentations"
                @shouldCloseDeleteConfirm="dialogRemovePresentations = false"
                @removed="removedPresentations"
            >
            </delete-confirm-presentations>
        </v-dialog>
    </div>
</template>

<script>
import DeleteConfirmVideo from "@/components/admin/SystemSettings/DeleteConfirmVideo.vue";
import DeleteConfirmPresentations from "@/components/admin/SystemSettings/DeleteConfirmPresentations.vue";

export default {
    name: "SystemSettingsIndex",
    components: {
        DeleteConfirmVideo,
        DeleteConfirmPresentations,
    },
    data: () => ({
        dialogRemoveVideo: false,
        dialogRemovePresentations: false,
    }),
    methods: {
        removedVideo() {
            this.dialogRemoveVideo = false;
        },
        removedPresentations() {
            this.dialogRemovePresentations = false;
        }
    },
}
</script>

<style scoped>

</style>