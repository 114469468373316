<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12 md12>
                            <v-text-field v-model="form.name"
                                          label="Назва критерію"
                            ></v-text-field>
                        </v-flex>

                        <v-flex xs12 md12>
                            <v-select
                                    label="Порядок критерію"
                                    :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]"
                                    v-model="form.order"
                            >
                            </v-select>
                        </v-flex>
                        <v-flex xs6>
                            <v-radio-group v-model="form.criteriaType" :mandatory="false">
                                <v-radio color="primary" label="Загальні навички" 
                                         :value="finalExamCriteriaEnum.generalSkills"></v-radio>
                                <v-radio color="red darken-3" label="Основні навички" 
                                         :value="finalExamCriteriaEnum.mainSkills"></v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import DatePicker from "../../../common/DatePicker";
    import {finalExamCriteriaEnum} from "../../../../constants/_internal/finalExamCriteriaEnum";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            DatePicker,
        },
        data: () => {
            return {
                form: {
                    name: null,
                    order: 1,
                    criteriaType: 0,
                },
                errors: {
                },
                loading: false,
                loadingSave: false,
                
                finalExamCriteriaEnum,
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post(`FinalExamAdminCriteria/SaveFinalExamCriteria/${this.$route.params.examId}`, this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>