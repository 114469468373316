<template>
    <div class="slider">
        <vue-load-image v-if="currentImage">
            <img slot="image" width="100%" :src="slideImageUrl(currentImage)" alt="Slider Image"/>
            <img slot="preloader" width="100%" src="../../../assets/images/image-loader.gif" alt="Loader Image"/>
            <div slot="error">Something went wrong. Please reload the page.</div>
        </vue-load-image>
        
        <v-alert  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                  v-if="isLastImage"
        >
            {{$t('eAristoStudent.youFinishedPresentation')}}
        </v-alert>
        <div class="d-flex justify-space-between align-center mb-4">
            <v-btn :disabled="!couldPrevImage" @click="prevImage()"><v-icon>chevron_left</v-icon> {{$t('eAristoStudent.presPrev')}}</v-btn>
            <v-btn :disabled="!(couldNextImage && shouldNextImage)" @click="nextImage()">{{$t('eAristoStudent.presNext')}} <v-icon>chevron_right</v-icon></v-btn>
        </div>
    </div>
</template>

<script>
import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";
import VueLoadImage from 'vue-load-image';

const configuration = AppConfig(config);
export default {
    name: "SlideViewer",
    components: {
        VueLoadImage,  
    },
    props: {
        images: {
            type: Array,
            default: () => [],
        },
        shouldNextImage: {
            type: Boolean,
            default: () => true,
        },
        currentIndex: {
          type: Number,  
        },
    },
    data: () => {
        return {
            // currentIndex: 0
        };
    },
    computed: {
        currentImage() {
            return this.images[this.currentIndex];
        },
        couldPrevImage() {
            return this.currentIndex > 0;
        },
        couldNextImage() {
            return this.currentIndex < (this.images.length - 1);
        },
        isLastImage() {
            return this.currentIndex === (this.images.length - 1);
        }
    },
    mounted() {
        this.$emit("slideSelect", this.currentIndex);
    },
    methods: {
        slideImageUrl(image) {
            return `${configuration.apiEndpoint}/file/get/presentationSlideImage/${image}`;
        },
        prevImage() {
            this.$emit("slideSelect", this.currentIndex - 1);
        },
        nextImage() {
            this.$emit("slideSelect", this.currentIndex + 1);
        },
    }
}
</script>

<style scoped>

</style>