<template>
    <v-card class="elevation-3 mb-4">
        <v-card-title class="title font-weight-light" primary-title>
            {{question[`text${$i18n.lang}`]}}
        </v-card-title>
        <v-card-text>
            <div v-if="question.questionType == pollQuestionTypesEnum.singleChoice">
                    <v-radio-group v-model="radio">
                        <v-radio
                                v-for="(answer, index) in question.answers"
                                :key="index"
                                :label="answer[`title${$i18n.lang}`]"
                                :value="answer.id"
                        ></v-radio>
                    </v-radio-group>
            </div>
            <div v-if="question.questionType == pollQuestionTypesEnum.multipleChoice">
                <div >
                    <v-checkbox v-for="(answer, index) in question.answers" 
                                :key="index"
                                v-model="checkbox" 
                                :label="answer[`title${$i18n.lang}`]" 
                                :value="answer.id"
                    ></v-checkbox>
                </div>
            </div>
            <div v-if="question.questionType == pollQuestionTypesEnum.freeAnswer">
                <v-textarea 
                            v-model="textarea"
                            label="Ваша відповідь..."
                ></v-textarea>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import {pollQuestionTypesEnum} from "../../../constants/admin/pollQuestionType";

    export default {
        data: () => ({
            pollQuestionTypesEnum,
            radio: null,
            checkbox: [],
            textarea: '',
        }),
        props: {
            question: {
                type: Object,
                default: () => {
                    return {
                        pollQuestions: [],
                    }
                },
            }
        }
    }
</script>