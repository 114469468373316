<template>
    <v-container class="register-container" style="position: relative">
        <block-loader v-if="loading"/>
        <v-layout justify-center>
            <v-flex xs12>
                <table class="marks-table elevation-3 mb-6" v-if="modulesInfo">
                    <thead>
                    <tr>
                        <th class="counter-header" rowspan="2"/>
                        <th class="student-name corner-left-top">
                            Модулі
                        </th>
                        <th :colspan="moduleAndModuleClass.finalControlType === finalControlTypesEnum.finalModuleControl ? 3 : 2" 
                            class="cell-lesson-date"
                            v-for="(moduleAndModuleClass, index) in modulesInfo.moduleAndModuleClasses">
                            <div class="lesson-date">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">
                                            Модуль №{{moduleAndModuleClass.orderBy}}
                                        </span>
                                    </template>
                                    <span>{{moduleAndModuleClass.nameUk}}</span>
                                </v-tooltip>
                            </div>
                        </th>
                        <th class="cell-lesson-date">
                            <div class="lesson-date">
                                Дисципліна
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th class="student-name corner-left-bottom">
                            ПІП студента
                        </th>
                        <template
                                v-for="(moduleAndModuleClass, index) in modulesInfo.moduleAndModuleClasses">
                            <th class="cell-module-overview-type">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">П</span>
                                    </template>
                                    <span>Поточні оцінки</span>
                                </v-tooltip>
                            </th>
                            <th v-if="moduleAndModuleClass.finalControlType === finalControlTypesEnum.finalModuleControl" 
                                class="cell-module-overview-type">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">МК</span>
                                    </template>
                                    <span>Модульний контроль</span>
                                </v-tooltip>
                            </th>
                            <th class="cell-module-overview-type">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="cursor-pointer">&sum;</span>
                                    </template>
                                    <span>Загальна сума</span>
                                </v-tooltip>
                            </th>
                        </template>
                        <th class="cell-module-overview-type">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="cursor-pointer">&sum;</span>
                                </template>
                                <span>Загальна сума</span>
                            </v-tooltip>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(studentsModule, index) in modulesInfo.studentsModules">
                        <td class="counter-cell">{{index + 1}}.</td>
                        <td class="student-name">
                            {{studentsModule.student.surname}} {{studentsModule.student.name}}
                            {{studentsModule.student.patronymic}}
                        </td>
                        <template  v-for="(moduleMark, index) in studentsModule.moduleControlMarks">
                            <td class="module-overview-cell">
                                {{valueNullToDash(moduleMark.marksValueCurrent)}}
                            </td>
                            <td v-if="moduleMark.finalControlType === finalControlTypesEnum.finalModuleControl" class="module-overview-cell">
                                {{valueNullToDash(moduleMark.moduleControlPoints)}}
                            </td>
                            <td class="module-overview-cell">
                                {{valueNullToDash(moduleMark.totalPoints)}}
                            </td>
                        </template>
                        <td class="module-overview-cell">
                            {{getSubjectControlMark(studentsModule)}}
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td></td>
                        <td class="cell-professor-name corner-prof-bottom">
                            ПІП викладача
                        </td>
                        <template
                                v-for="(moduleAndModuleClass, index) in modulesInfo.moduleAndModuleClasses">
                            <td :colspan="moduleAndModuleClass.finalControlType === finalControlTypesEnum.finalModuleControl ? 3 : 2"  
                                class="cell-professor-name">
                                <marks-list-professor-cell :topic-info="moduleAndModuleClass"/>
                            </td>
                        </template>
                    </tr>
                    </tfoot>
                </table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HttpService from "../../HttpService";
    import moment from "moment";
    import BlockLoader from "./BlockLoader";
    import MarksListProfessorCell from "./MarksListProfessorCell";
    import {finalControlTypesEnum} from "../../constants/admin/finalControlTypes";
    
    export default {
        components: {
            BlockLoader,
            MarksListProfessorCell,
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                modulesInfo: {
                    studentsModules: [],
                    moduleAndModuleClasses: [],
                },
                loading: false,
                finalControlTypesEnum,
            }
        },
        mounted() {
            this.loading = true;
            HttpService.getInstance()
                .get(`teacher/teacherMarks/marksOverview/${this.$route.params.group}/${this.$route.params.subject}`)
                .then(resp => {
                    this.modulesInfo = resp;
                    this.loading = false;
                });
        },
        methods: {
            getTopicDate(topicAndTopicClass) {
                if (topicAndTopicClass.topicClass) {
                    return moment(topicAndTopicClass.topicClass.classDate).format("DD.MM.YY");
                }

                return null;
            },
            getSubjectControlMark(studentsModule) {
                if (studentsModule.subjectControlMark) {
                    return studentsModule.subjectControlMark.totalPoints;
                }
                else {
                    return '-';
                }
            },
            valueNullToDash(value) {
                if (!value) {
                    return '-';
                }
                
                return value.toFixed(2);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .register-container {
        padding: 5px;
    }

    .lesson-date {
        font-weight: 200;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }

    .lesson-date-topic {
        text-align: right;
    }

    .student-name-text {
        position: absolute;
        bottom: 2px;
        left: 2px;
    }

    .cell-lesson-date {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        min-width: 25px;
    }

    .cell-module-overview-type {
        text-align: center;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .corner-left-top {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9em;
        text-align: right;
        color: #ccc;
    }

    .corner-left-bottom {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9em;
        text-align: left;
        color: #ccc;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                line-height: 1.5em;
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
                &.module-overview-cell {
                    border-right: 1px solid #ccc;
                    text-align: center;
                }
            }
        }
    }
</style>