<template>
    <div>
        <div class="headline">Теми практичних занять</div>

        <v-container v-if="selected.length > 0">
            <v-row class="d-flex align-top">
                <v-col md="4">
                    <span>Перемістити вибрані теми в інший модуль:</span>
                </v-col>
                <v-col md="5">
                    <v-select
                        :items="modules"
                        v-model="selectedModule"
                        item-text="name"
                        item-value="id"
                        return-object
                        label="Модуль"
                        solo
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-btn @click.native="moveToModule" :disabled="(selectedModule === null || loading === true)">
                        Перемістити
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-data-table
            show-select
            v-model="selected"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.isBlocked="{item}">
                <v-icon v-if="!item.isBlocked">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isBlocked">radio_button_checked</v-icon>
            </template>

            <template v-slot:item.evaluationMode="{item}">
                {{ getEvaluationModeType(item.evaluationMode) }}
            </template>

            <template v-slot:item.actions="{item}">
                <div style="min-width: 80px;">
                    <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import topicEvaluationModeTypes, {topicEvaluationModeEnum} from "../../../constants/topicEvaluationModeTypes";
import HttpService from "@/HttpService";

export default {
    props: {
        items: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loading: {
            type: Boolean,
        },
    },
    watch: {
        pagination(paginationVal, prevPaginationVal) {
            this.$emit("paginate", paginationVal);
        },
    },
    mounted() {
        this.getModulesList();
    },
    methods: {
        getEvaluationModeType(type) {
            const foundEvaluationType = topicEvaluationModeTypes.find(i => i.id === type);

            if (foundEvaluationType) {
                return foundEvaluationType.nameUk;
            }

            return "";
        },
        getModulesList() {
            HttpService.getInstance()
                .get(`module/getAll/${this.$route.params.department}/${this.$route.params.subject}`)
                .then(resp => {
                    this.modules = resp.filter(module => module.id !== this.$route.params.module).map(module => {
                        module.name = `${module.orderBy}. ${module.nameUk}`;
                        
                        return module;
                    });
                });
        },
        moveToModule() {
            HttpService.getInstance()
                .put(`topic/moveToModule/${this.selectedModule.id}`, this.selected)
                .then((resp) => {
                    this.$emit("moveditems");
                })
        }
    },
    data: () => ({
        pagination: {
            totalItems: 0,
        },
        headers: [
            {text: '№ п/п', value: 'orderNumber'},
            {text: '№ заняття', value: 'classNumber'},
            {text: 'Назва (укр)', value: 'nameUk'},
            // {text: 'Назва (eng)', value: 'nameEn'},
            {text: 'Академічні години', value: 'academicHours'},
            {text: 'Блокування', value: 'isBlocked'},
            {text: 'Тип теми', value: 'evaluationMode'},
            {text: 'Дії', value: 'actions', sortable: false}
        ],
        topicEvaluationModeEnum,

        modules: [],
        selected: [],
        selectedModule: null,
    }),
}
</script>