<template>
    <v-app>
        <vue-headful title="ePlato"/>
        <toolbar
                :is-lang-switcher="false"
                color="orange darken-3"
                @toggleDrawer="drawerApp = !drawerApp"
        />

        <navigation v-if="$route.name !== 'chiefdeptSuperAdminPreselect'" 
                    @toggleDrawer="drawerApp = $event" 
                    :drawer="drawerApp"
                    :key="$route.fullPath"
        />

        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/common/Toolbar";
    import ChiefDeptNavigationDrawer from "../../components/chiefDept/common/ChiefDeptNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            toolbar: Toolbar,
            navigation: ChiefDeptNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
