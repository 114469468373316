const state = {
    selectedNavigationItem: {
        faculty: {
            id: null, 
            nameShortEn: '',
            nameShortUk: '',
        },
        groupNumber: null, 
        semester: {
            id: null, 
            semesterNumber: null,
            yearNumber: null, 
        }
    },
};

const getters = {
    selectedNavigationItem: state => {
        return {
            facultyName: state.selectedNavigationItem.faculty.nameShortUk,
            groupNumber: state.selectedNavigationItem.groupNumber,
            yearNumber: state.selectedNavigationItem.semester.yearNumber,
        };
    },
};

const actions = {
};

const mutations = {
    setSelectedNavigationItem(state, navItem) {
        state.selectedNavigationItem = navItem;
    }
};


export default {
    state,
    getters,
    actions,
    mutations
}