<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-flex xs12 sm8 md8>
                <announcement-full/>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import AnnouncementFull from "../../components/dashboard/AnnouncementFull";
    export default {
        components: {
            AnnouncementFull,
        }
    }
</script>