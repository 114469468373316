<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.approved="{item}">
            <v-icon v-if="!item.approved">radio_button_unchecked</v-icon>
            <v-icon v-if="item.approved">radio_button_checked</v-icon>
        </template>

        <template v-slot:item.published="{item}">
            <v-icon v-if="!item.published">radio_button_unchecked</v-icon>
            <v-icon v-if="item.published">radio_button_checked</v-icon>
        </template>
        
        <template v-slot:item.publishStartTime="{item}">
            {{formatLongDate(item.publishStartTime)}}
        </template>

        <template v-slot:item.publishEndTime="{item}">
            {{formatLongDate(item.publishEndTime)}}
        </template>
        
        <template v-slot:item.announcementFrom="{item}">
            {{getAnnouncementFromType(item)}}
        </template>
        
        <template v-slot:item.addressed="{item}">
            <div v-if="item.role">Роль: {{item.role.name}}</div>
            <div v-if="item.department">Кафедра: {{item.department.nameUk}}</div>
            <div v-if="item.faculty">Факультет: {{item.faculty.nameUk}}</div>
            <div v-if="item.speciality">Спеціальність: {{item.speciality.nameUk}}</div>
            <div v-if="item.semester">Курс (семестр): {{item.semester.yearNumber}} курс ({{item.semester.semesterNumber}} семестр)</div>
            <div v-if="item.academicGroup">Група: {{item.academicGroup.groupNumber}}</div>
        </template>
        
        <template v-slot:item.actions="{item}">
            <div style="width: 120px;">
                <v-btn icon class="mx-0" @click="$emit('copyItem', item.id)">
                    <v-icon color="primary">filter_none</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                    <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate, formatLongDate} from "../../../../helpers/dateHelper";
    import announcementFromTypes from "../../../../constants/mainDashboard/announcementFromTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            formatShortDate,
            formatLongDate,
            announcementFromTypes,
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва (укр)', value: 'titleUk'},
                {text: 'Назва (eng)', value: 'titleEn'},
                {text: 'Затверджено', value: 'approved'},
                {text: 'Опубліковано', value: 'published'},
                {text: 'Початок публікації', value: 'publishStartTime'},
                {text: 'Завершення публікації', value: 'publishEndTime'},
                {text: 'Відправник', value: 'announcementFrom', sortable: false},
                {text: 'Адресат', value: 'addressed', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
        methods: {
            getAnnouncementFromType(type) {
                const foundType = this.announcementFromTypes.find(a => a.id === type.announcementFromType);
                
                if (foundType) {
                    return foundType.name;
                }
                
                return "";
            }
        }
    }
</script>