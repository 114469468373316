<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex v-if="showTopicsBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'eAristoEducationalTopicsPlan', 
                            params: {department: $route.params.department, subject: $route.params.subject, module: $route.params.module}}" 
                           color="primary"
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> Перейти до списку тем</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Теми занять</v-subheader>
                            <navigation-list-template :items="topics"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.module" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть тему індивідуального заняття
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <topic-content-index :key="$route.fullPath" v-if="$route.params.topic"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import TopicContentIndex from "../../components/eAristoProfessor/topicContent/TopicContentIndex";
    import HttpService from "../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            TopicContentIndex
        },
        data: () => ({
            topics: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`eAristoProfessor/topic/getAllByModule/${this.$route.params.module}`)
                .then(resp => {
                    this.topics = resp.map(topic => {
                        topic.name = `${topic.orderNumber}. ${topic.nameUk}`;
                        topic.route = {
                            name: 'eAristoEducationalTopicContent',
                            params: {
                                department: this.$route.params.department,
                                subject: this.$route.params.subject,
                                module: this.$route.params.module,
                                topic: topic.id,
                            }
                        };

                        return topic;
                    });
                });
        },
        methods: {
            showTopicsBlock() {
                return this.$route.name === "eAristoEducationalTopicContent" && 
                    this.$route.params.department && 
                    this.$route.params.subject && 
                    this.$route.params.topic;
            },

        },
    }
</script>