<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-flex xs12 sm8 md8>
                <survey-full/>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import SurveyFull from "../../components/dashboard/SurveyFull";
    export default {
        components: {
            SurveyFull,
        }
    }
</script>