<template>
    <navigation-list-template :loading="loading" :items="studNavigationItems"/>
</template>

<script>
    import HttpService from "../../../HttpService";
    import NavigationListTemplate from "../NavigationListTemplate";

    export default {
        data: () => {
            return {
                studNavigationItems: [],
                loading: false,
            };
        },
        props: {
            bothSemesters: {
                type: Boolean,
                default: false
            },
            routeTo: {
                type: String,
                default: "groupsGrid"
            },
            additionalRouteParams: {
                type: Object,
                default: () => {
                }
            }
        },
        components: {
            NavigationListTemplate,
        },
        mounted() {
            this.loading = true;

            let API_URL = "navigation/getNavRegisters";
            
            if (this.bothSemesters) {
                API_URL = `${API_URL}?mode=bothSemesters`;
            }
            
            HttpService.getInstance()
                .get(API_URL)
                .then(navItems => {
                    this.loading = false;
                    this.studNavigationItems = this.remapStudNavItems(navItems);
                })
        },
        methods: {
            remapStudNavItems(studNavigationItems) {
                if (studNavigationItems === undefined) {
                    return [];
                }

                let studNavigationItemsRemapped = studNavigationItems.slice(0);

                studNavigationItemsRemapped.map(fac => {
                    fac.name = fac.nameUk;
                    fac.children = fac.specialities.map(spec => {

                        spec.name = spec.nameUk;

                        spec.children = spec.semesters.map(sem => {
                            sem.name = `${sem.yearNumber} курс (${sem.semesterNumber} семестр)`;
                            sem.model = false;

                            if (sem.id.toString() === this.$route.params.semester) {
                                sem.model = true;
                            }

                            sem.route = {
                                name: this.routeTo,
                                params: 
                                    {
                                        ...{
                                            faculty: fac.id,
                                            speciality: spec.id,
                                            semester: sem.id
                                        },
                                        ...this.additionalRouteParams
                                    }
                            };
                            return sem;
                        });

                        let someSemesterActive = spec.children.find(ch => ch.model);

                        spec.model = false;
                        if (spec.id.toString() === this.$route.params.speciality && someSemesterActive !== undefined) {
                            spec.model = true;
                        }

                        delete spec.semesters;

                        return spec;
                    });

                    let someSpecsActive = fac.children.find(ch => ch.model);

                    fac.model = false;
                    if (fac.id.toString() === this.$route.params.faculty && someSpecsActive !== undefined) {
                        fac.model = true;
                    }

                    delete fac.specialities;

                    return fac;
                });

                return studNavigationItemsRemapped;
            },
        }
    }
</script>

<style>

</style>