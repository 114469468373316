<template>
    <v-flex>
        <v-card height="100%" class="px-4 py-2">
            <div class="d-flex align-center">
                <v-text-field 
                              clearable
                              autofocus
                              :loading="loading"
                              :label="inputLabel"
                              v-model="searchString"
                              hide-details
                              @click:clear="$emit('search', '')"
                              v-on:keyup.enter="$emit('search', searchString)"
                />
                <v-btn class="ml-5" @click="$emit('search', searchString)" color="primary">Пошук</v-btn>
            </div>
        </v-card>
    </v-flex>
</template>
<script>
    export default {
        data: () => ({
            searchString: "",
        }),
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            inputLabel: {
                type: String,
                default: "Введіть щось для пошуку..."
            }
        }
    }
</script>