<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">Перетворення тексту у тести</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <div v-if="!loading" wrap>
                        <v-radio-group v-model="questionCount">
                            <template v-slot:label>
                                <div>Кількість відповідей</div>
                            </template>
                            <v-radio
                                label="4 відповіді"
                                :value="4"
                            ></v-radio>
                            <v-radio
                                label="5 відповідей"
                                :value="5"
                            ></v-radio>
                        </v-radio-group>

                        <v-radio-group v-model="rightAnswer">
                            <template v-slot:label>
                                <div>Вірна відповідь</div>
                            </template>
                            <v-radio
                                label="Перша"
                                :value="1"
                            ></v-radio>
                            <v-radio
                                label="Позначена символом *"
                                :value="0"
                            ></v-radio>
                        </v-radio-group>

                        <v-layout v-if="!loading" wrap>
                            <v-textarea
                                solo
                                rows="20"
                                v-model="testsGift"
                                label="Текст запитань"
                            ></v-textarea>
                        </v-layout>

                        <v-btn
                            @click="transform"
                            color="primary"
                        >Перетворити
                        </v-btn>

                        <div class="mt-3 headline h2">Передперегляд перетворення</div>
                        <v-divider class="mt-3"></v-divider>
                        <div v-for="quizQuestion in quizTransformResult">
                            <div>{{ quizQuestion.question }}</div>
                            <div v-for="quizAnswer in quizQuestion.quizAnswers">
                             <span>
                                <v-icon v-if="quizAnswer.isRight">check_circle</v-icon>
                                <v-icon v-if="!quizAnswer.isRight">remove</v-icon>
                            </span>
                                <span>{{ quizAnswer.answer }}</span>
                            </div>
                        </div>
                        
                        <div v-if="errors">
                            <div class="h3">Помилки валідації:</div>
                            {{errors}}
                        </div>
                    </div>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    disabled
                    color="primary"
                    text
                    @click.native="$emit('shouldCloseAddEdit')"
                    :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                    disabled
                    color="primary"
                    type="submit"
                    :loading="loadingSave"
                    :disabled="!quizTransformResult || loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
import HttpService from "../../../HttpService";
import {remapErrors} from "../../../helpers/errorsHelper";
import LoadingPlaceholder from "../../common/LoadingPlaceholder";
import ImageUploader from "@/components/common/ImageUploader";

export default {
    props: {
        editId: Number,
        editMode: Boolean,
    },
    components: {
        LoadingPlaceholder,
        ImageUploader,
    },
    data: () => {
        return {
            questionCount: 5,
            rightAnswer: 0,
            testsGift: null,
            quizTransformResult: null,
            result: null,
            form: {
                question: null,

            },
            errors: null,
            loading: false,
            loadingSave: false,
        }
    },
    methods: {
        transform() {
            const initialText = this.testsGift.trim().split("\n");
            console.log("initialText", initialText);

            this.result = initialText;

            let curline;
            let linenum = 0;
            let firstspace;
            let maxlines = this.questionCount;

            let correctfirst = false;
            let correctasterix = true;

            if (this.rightAnswer === 1) {
                correctfirst = true;
                correctasterix = false;
            }

            const quizAll = [];

            let quiz = {
                question: null,
                quizAnswers: null
            };
            let answers = [];

            while (initialText.length) {
                curline = this.trim(initialText.shift());

                firstspace = curline.indexOf(' ');

                if (curline !== "") {
                    if (linenum === 0) {
                        quiz.question = curline;
                        quiz.quizAnswers = null;
                    } else if ((correctfirst && linenum === 1) || (correctasterix && curline[0] === "*")) {
                        if (correctasterix) {
                            curline = this.trim(curline.substr(1));
                            firstspace = curline.indexOf(' ');
                        }
                        answers.push({"answer": curline, "isRight": true});
                    } else {
                        if (curline !== "-") {
                            answers.push({"answer": curline, "isRight": false});
                        }
                    }
                    if (++linenum > maxlines) {
                        linenum = 0;
                        quiz.quizAnswers = answers;
                        quizAll.push(quiz);

                        quiz = {
                            question: null,
                            quizAnswers: null
                        };
                        answers = [];
                    }
                }
            }
            if (linenum === maxlines) {
                linenum = 0;
            }

            this.quizTransformResult = quizAll;

        },
        trim(str, chars) {
            return this.ltrim(this.rtrim(str, chars), chars);
        },
        ltrim(str, chars) {
            chars = chars || "";
            return str.replace(new RegExp("^[" + chars + "]+", "g"), "");
        },
        rtrim(str, chars) {
            chars = chars || "";
            return str.replace(new RegExp("[" + chars + "]+$", "g"), "");
        },

        submitForm(e) {
            e.preventDefault();

            this.loadingSave = true;
            
            const payload = this.quizTransformResult.map(q => {
                return {
                    ...q,
                    subject: {
                        id: this.$route.params.subject,
                    },
                    module: {
                        id: this.$route.params.module,
                    },
                    lecture: this.$route.params.lecture ? {id: this.$route.params.lecture} : null,
                    topic: this.$route.params.topic ? {id: this.$route.params.topic} : null,
                }
            });

            HttpService.getInstance()
                .post("reworkQuiz/bulk", payload)
                .then(resp => {
                    this.loadingSave = false;
                    this.$emit("addedEdited");
                })
                .catch(err => {
                    this.loadingSave = false;
                    if (err.errors) {
                        this.errors = err.errors;
                    }
                });
        },
    }
}
</script>