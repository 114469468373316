<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <v-layout v-if="loading" align-center justify-center>
            <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-layout>
        <line-chart v-if="!loading" :chart-data="datacollection" :options="options"></line-chart>
    </v-container>
</template>

<script>
import LineChart from '../../common/statistics/LineChart';
import moment from "moment";
import HttpService from "@/HttpService";
import {formatShortDate} from "@/helpers/dateHelper";
import StatDateRange from "@/components/common/StatDateRange";

export default {
    components: {
        LineChart,
        StatDateRange,
    },
    data () {
        return {
            datacollection: null,
            options: {
                fill: false,
                responsive: true,
                maintainAspectRatio: false,
            },

            dates: [],
            
            loading: false,
        }
    },
    mounted () {
        this.getData();
    },
    methods: {
        getData(dateFrom, dateTo) {
            this.loading = true;
            
            let queryParam = "";
            if (dateFrom && dateTo) {
                queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
            }

            HttpService.getInstance()
                .get(`statisticsMarks/getByDaysDepartment/${this.$route.params.department}?${queryParam}`)
                .then(data => {
                    this.data = data;
                    
                    this.fillData();
                    this.loading = false;
                })
                .catch(e => {

                })
        },

        fillData() {
            const labels = this.data.map(d => formatShortDate(d.date));
            const avgMark = this.data.map(d => d.statisticsUniversityAvg.avgMark.toFixed(1));
            const count2Percentage = this.data.map(d => d.statisticsUniversityAvg.count2Percentage.toFixed(1));
            const countAbsPercentage = this.data.map(d => d.statisticsUniversityAvg.countAbsPercentage.toFixed(1));

            const avgMarkActual = this.data.map(d => d.statisticsActual.avgMark.toFixed(1));
            const count2PercentageActual = this.data.map(d => d.statisticsActual.count2Percentage.toFixed(1));
            const countAbsPercentageActual = this.data.map(d => d.statisticsActual.countAbsPercentage.toFixed(1));

            this.datacollection = {
                labels,
                datasets: [
                    {
                        label: 'Середня оцінка, університет',
                        backgroundColor: '#B2DFDB',
                        borderColor: '#B2DFDB',
                        fill: false,
                        data: avgMark,
                    },
                    {
                        label: '% 2, університет',
                        backgroundColor: '#FFCDD2',
                        borderColor: '#FFCDD2',
                        fill: false,
                        data: count2Percentage,
                    },
                    {
                        label: '% нб, університет',
                        backgroundColor: '#FFE0B2',
                        borderColor: '#FFE0B2',
                        fill: false,
                        data: countAbsPercentage,
                    },
                    {
                        label: 'Середня оцінка, кафедра',
                        backgroundColor: '#388E3C',
                        borderColor: '#388E3C',
                        fill: false,
                        data: avgMarkActual,
                    },
                    {
                        label: '% 2, кафедра',
                        backgroundColor: '#D32F2F',
                        borderColor: '#D32F2F',
                        fill: false,
                        data: count2PercentageActual,
                    },
                    {
                        label: '% нб, кафедра',
                        backgroundColor: '#EF6C00',
                        borderColor: '#EF6C00',
                        fill: false,
                        data: countAbsPercentageActual,
                    }
                ]
            }
        },

        clearFilter() {
            this.dates = [];

            this.getData();
        },

        search(dates) {
            this.dates = dates;
            this.getData(this.dates[0], this.dates[1]);
        },
    }
}
</script>