<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th rowspan="2">№ п/п</th>
                <th rowspan="2">ПІП</th>
                <th rowspan="2">Група</th>
                <th rowspan="2">Спец-ть</th>
                <th colspan="5">Результати</th>
                <th rowspan="2">Попередні результати</th>
            </tr>
            <tr>
                <th>Дата</th>
                <th>% вірних</th>
                <th>Всього запитань</th>
                <th>Вірно запитань</th>
                <th>Оцінка</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, index) in results">
                <td>{{index + 1}}.</td>
                <td>{{student.surname}} {{student.name}}</td>
                <td>{{student.groupNumber}}</td>
                <td>{{student.specialityName}}</td>
                <td>{{student.stepQuestionResult != null ? formatDate(student.stepQuestionResult.dateEnd) : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.percentage : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.totalQuestions : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.correctQuestions : '-'}}</td>
                <td>{{student.stepQuestionResult != null ? student.stepQuestionResult.traditionalMark : '-'}}</td>
                <td>
                    <table class="table">
                        <tbody>
                            <tr v-for="result in student.stepQuestionFinishes">
                                <td>{{formatDate(result.dateEnd)}}</td>
                                <td>{{result.percentage}}%</td>
                                <td>{{result.traditionalMark}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import format from "date-fns/format";
    
    export default {
        props: {
            results: {
                type: Array,
                default: () => [],
            }
        },
        methods: {
            formatDate(date) {
                return format(date, "DD.MM.YY");
            },
        }
    }
</script>