<template>
    <v-container grid-list-md>
        <admissions-student-wrapper v-if="showReworksBlock()" :key="$route.fullPath"/>
    </v-container>
</template>

<script>
    import AdmissionsStudentWrapper from "../../components/reworks/reworkMark/AdmissionsStudentWrapper";

    export default {
        components: {
            AdmissionsStudentWrapper
        },
        methods: {
            showReworksBlock() {
                return this.$route.params.department && this.$route.params.subject;
            },
        },
    }
</script>