import EmptyView from "../views/EmptyView";
import ManagementRootView from '../components/stepAdmin/management/ManagementRootView';
import StepAdminRootView from "../views/stepAdmin/StepAdminRootView";
import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/stepAdminModules',
         name: 'stepAdminRoot',
         component: StepAdminRootView,
         children: [
             {
                 path: '',
                 redirect: {name: 'stepAdmin'},
             },
             {
                 path: '/stepAdminModules/dashboard',
                 name: 'stepAdmin',
                 component: EmptyView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                 children: [
                     {
                         path: '/stepAdminModules/management/:subject/:module/:faculty?/:speciality?/:semester?',
                         name: 'stepAdminManagement',
                         component: ManagementRootView,
                         beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                     },
                 ]
             },
             
         ],
     },
 ];
}