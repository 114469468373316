<template>
    <div>
        <span class="headline">{{ editMode ? 'Редагування питання' : 'Додавання тесту "напишіть відповідь"' }}</span>
        <v-container grid-list-md>
            <v-layout v-if="!loading" wrap>

                <v-flex xs12 md12>
                    <v-textarea
                        :rows="5"
                        solo
                        label="Питання"
                        v-model="form.question"
                        :error-messages="errors.question"
                    ></v-textarea>
                </v-flex>

                <v-flex :key="index" xs12 wrap v-for="(answer, index) in form.questionAnswers">
                    <v-layout align-center>
                        <v-text-field
                            xs-8
                            v-model="answer.answer"
                            :label="`Відповідь`"
                        ></v-text-field>
                    </v-layout>
                </v-flex>

                <v-flex xs12>
                    <div class="red--text" v-if="errors.questionAnswers">
                        {{ errors.questionAnswers }}
                    </div>
                </v-flex>
            </v-layout>

            <loading-placeholder v-else></loading-placeholder>
        </v-container>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import {remapErrors} from "@/helpers/errorsHelper";
import LoadingPlaceholder from "@/components/common/LoadingPlaceholder";

export default {
    props: {
        editId: Number,
        editMode: Boolean,

        educationMaterialVideoId: {
            type: Number,
            default: null,
        },
        educationMaterialPresentationId: {
            type: Number,
            default: null,
        },
        currentVideoTime: {
            type: Number,
            default: null,
        },
    },
    components: {
        loadingPlaceholder: LoadingPlaceholder,
    },
    data: () => {
        return {
            form: {
                id: 0,
                question: '',
                questionAnswers: [
                    {
                        answer: "",
                        isCorrect: true,
                    }
                ],
            },
            errors: {
                question: [],
            },
            loading: false,
            loadingSave: false,
        }
    },
    mounted() {
        if (this.editMode) {
            this.loading = true;

            HttpService.getInstance()
                .get(`eAristoProfessor/questions/${this.editId}`)
                .then(resp => {
                    this.loading = false;
                    this.form = resp;
                })
        }
    },
    methods: {
        saveForm() {
            let payload = {
                id: this.form.id,
                question: this.form.question,
                questionAnswers: this.form.questionAnswers.map((questionAnswer, index) => {
                    return {
                        id: questionAnswer.id ?? 0,
                        answer: questionAnswer.answer,
                        isCorrect: questionAnswer.isCorrect,
                        orderNumber: index,
                    }
                }),
                ...{subjectId: this.$route.params.subject},
                ...{moduleId: this.$route.params.module},
                ...{topicId: this.$route.params.topic},

            }

            if (!this.editMode) {
                payload = {
                    ...payload,
                    ...{educationMaterialVideoId: this.educationMaterialVideoId},
                    ...{educationMaterialPresentationId: this.educationMaterialPresentationId},
                    ...{currentVideoTime: Math.round(this.currentVideoTime)}
                }
            }

            this.loadingSave = true;
            this.$emit("loadingSave", true);

            HttpService.getInstance()
                .post("eAristoProfessor/writeAnswer", payload)
                .then(resp => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    this.$emit("addedEdited");
                })
                .catch(err => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    if (err.errors) {
                        this.errors = remapErrors(err.errors);
                    }
                });
        },
    }
}
</script>