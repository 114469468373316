<template>
    <v-row>
        <v-col
                v-for="direction in ratingWorktypeDirections"
                :key="direction.id"
                cols="12"
                sm="6"
                md="4"
                lg="4"
        >
            <v-card>
                <v-card-title class="subheading font-weight-bold no-word-break">
                    <div class="header-wrapper">
                        {{ direction.order }}. {{ direction.directionName }}
                    </div>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <div class="subtitle-1">Коефіцієнт напрямку: <strong>{{direction.ratio}}</strong></div>
                    <div class="subtitle-1">Кількість набраних балів: <strong>{{direction.pointsSum}}</strong></div>
                    <div class="subtitle-1">Кількість балів (з коефіцієнтом): <span class="text-h5 text-bold">{{direction.pointsSumRatio}}</span></div>

                    <div class="my-2">
                        <div>
                            <span class="green--text subtitle-1">{{direction.approved}}</span> /
                            <span class="red--text subtitle-1">{{direction.rejected}}</span> /
                            <span class="font-weight-bold subtitle-1">{{direction.total}}</span></div>
                        <b-progress class="mt-2" :max="direction.total" show-value>
                            <b-progress-bar
                                    :value="direction.total - direction.approved - direction.rejected"
                                    variant="warning"></b-progress-bar>
                            <b-progress-bar :value="direction.approved" variant="success"></b-progress-bar>
                            <b-progress-bar :value="direction.rejected" variant="danger"></b-progress-bar>
                        </b-progress>
                    </div>
                    
                    <div v-if="direction.rejectedWorkTypes.length > 0" class="my-2">
                        <div class="subtitle-2 red--text">Відхилені види робіт:</div>
                        <ul>
                            <li v-for="rejectedWorkType in direction.rejectedWorkTypes">
                                {{rejectedWorkType.orderCustom}}. {{rejectedWorkType.name}}
                            </li>
                        </ul>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn :to="{name: routeToName, 
                            params: {direction: direction.id, ...routeToParams}}" 
                           color="deep-purple lighten-2"
                           text>Види робіт <v-icon>keyboard_arrow_right</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        props: {
            ratingWorktypeDirections: Array,
            routeToName: String,
            routeToParams: Object,
        },
        
    }
</script>