<template>
    <div>
        <vue-headful
                title="Випускний іспит Kiosk"
        />
        <final-exam-kiosk-index></final-exam-kiosk-index>
    </div>
</template>

<script>
    import FinalExamKioskIndex from "../../components/finalExam/adminDashboard/FinalExamKioskIndex";
    
    export default {
        components: {
            FinalExamKioskIndex
        },
        data: () => ({
        }),
    }
</script>