<template>
    <div>
        <div v-if="loading">
            <v-layout align-center justify-center>
                <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </div>
        
        <video-uploader v-if="!this.videoContent && !this.loading" 
                        @uploadedFiles="uploadedVideo($event)" 
                        :topic-id="+$route.params.topic"
        ></video-uploader>
        <div v-if="this.videoContent && !this.loading" class="mx-1 my-1" style="max-width: 100%">
            <div>
                <v-btn large class="mb-2" color="red" dark @click="deleteVideo($route.params.topic)">Видалити відео</v-btn>

                <v-btn
                    @click="$emit('reloadVideo')"
                    class="mx-2"
                    fab
                    dark
                    small
                    text
                    color="primary"
                >
                    <v-icon dark>
                        autorenew
                    </v-icon>
                </v-btn>
                
                <v-chip
                    v-if="!videoContent.isProcessed"
                    class="ma-2"
                    color="orange"
                    label
                    outlined
                >
                    {{videoContent.status}}
                </v-chip>

                <v-chip
                    v-if="videoContent.isProcessed"
                    class="ma-2"
                    color="green"
                    label
                    outlined
                >
                    {{videoContent.status}}
                </v-chip>
            </div>
            
            <video-player :video-content="videoContent" @currentTime="onCurrentTime"></video-player>

            <v-btn class="mt-3" v-if="currentVideoTime" @click="addQuestionItem(currentVideoTime)">Додати запитання ({{formatVideoTime(currentVideoTime)}})</v-btn>
            
            <e-aristo-question-index ref="questionsGrid" :education-material-video-id="videoContent ? videoContent.id : null" :topic-question-type="eAristoTopicQuestionType.VideoQuestions"></e-aristo-question-index>
        </div>

        <v-dialog v-model="dialogRemove" max-width="450">
            <delete-confirm
                @shouldCloseDeleteConfirm="dialogRemove = false"
                @removed="removed"
                :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>
    </div>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import VideoUploader from "@/components/eAristoProfessor/topicContent/VideoUploader";
import VideoPlayer from "@/components/common/video-player/VideoPlayer";
import eAristoQuestionIndex from "../eAristoQuestions/eAristoQuestionIndex";
import DeleteConfirm from "./DeleteConfirm.vue";
import config from "@/app.config";
import moment from "moment/moment";
import {eAristoTopicQuestionType} from "@/constants/eAristo/eAristoTopicQuestionType";

const configuration = AppConfig(config);
export default {
    name: "VideoContent",
    components: {
        VideoUploader,
        VideoPlayer,
        eAristoQuestionIndex,
        DeleteConfirm,
    },
    props: {
        videoContent: {
            type: Object,
            default: () => {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            dialogRemove: false,
            deleteId: null,
            
            currentVideoTime: 0,

            eAristoTopicQuestionType: eAristoTopicQuestionType,
        };
    },
    methods: {
        uploadedVideo(video) {
            this.$emit("uploadedVideo", video);
        },
        onCurrentTime(time) {
            this.currentVideoTime = time;
        },
        deleteVideo(itemId) {
            this.dialogRemove = true;
            this.deleteId = itemId;
        },
        removed() {
            console.log("removed");
            this.dialogRemove = false;
            this.deleteId = null;

            this.$emit("uploadedVideo", null);
        },
        formatVideoTime(time) {
            const timeMoment = moment.unix(time).utc();

            return timeMoment.format("HH:mm:ss");
        },

        addQuestionItem(currentVideoTime) {
            this.$refs.questionsGrid.addItemVideo(currentVideoTime);
        }
    }
}
</script>

<style scoped>

</style>