<template>
    <div>
        <groups-grid
                :items="items"
                :total="total"
                :loading="loading"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @change="moveSelectedDialog()"
                @changeMove="changeMoveSelectedDialog()"
                @deleteSelected="deleteSelectedDialog()"
                @deleteSelectedWithStudents="delectedSelectedDialogWithStudents()"
                :selectedItems.sync="selectedItems"
        >
        </groups-grid>

        <v-dialog v-model="dialog" max-width="450px" scrollable>
            <add-edit-academic-group
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :items="items"
            ></add-edit-academic-group>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="405px">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-dialog v-model="dialogDeleteSelected" persistent max-width="500">
            <v-card>
                <v-card-title class="subtitle-1">
                    <span>Ви дійсно хочете видалити виділені групи</span><span v-if="dialogDeleteSelectedWithStudents"> РАЗОМ зі студентами</span>?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="primary"
                            @click.native="deleteSelectedClose()"

                    >Ні
                    </v-btn>
                    <v-btn
                            color="red"
                            text
                            @click.native="deleteSelected"

                    >Так
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMove" max-width="450px">
            <move-next-year-confirm v-if="dialogMove"
                                    @shouldCloseDeleteConfirm="dialogMove = false"
                                    @moved="moved"
                                    :selected="selectedItems">
                
            </move-next-year-confirm>
        </v-dialog>
        
        <v-dialog v-model="dialogChangeMove" max-width="450px">
            <move-group-dialog v-if="dialogChangeMove"
                               @shouldCloseGroupMove="dialogChangeMove = false"
                               @groupMoved="groupMoved"
                               :selected="selectedItems"
            >
            </move-group-dialog>
        </v-dialog>

        <v-dialog v-model="dialogAddSeveral" max-width="450px" scrollable>
            <add-several-academic-group
                    v-if="dialogAddSeveral"
                    :items="items"
                    @shouldCloseAddSeveral="dismissDialogs"
                    @addedSeveral="addedSeveral"
            ></add-several-academic-group>
        </v-dialog>

        <v-speed-dial
                dark
                fixed
                bottom
                right
                fab
                direction="top"
                v-model="fab"
        >
            <template v-slot:activator>
                <v-btn
                        color="blue darken-2"
                        dark
                        fab
                >
                    <v-icon v-if="fab">close</v-icon>
                    <v-icon v-else>add</v-icon>
                </v-btn>
            </template>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="green"
                            @click="dialogAddSeveral = true"
                    >
                        <v-icon>control_point_duplicate</v-icon>
                    </v-btn>
                </template>
                <span>Додати декілька</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="indigo"
                            @click="addItem()"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                <span>Додати одну</span>
            </v-tooltip>
            
        </v-speed-dial>
    </div>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import GroupsGrid from "../Groups/GroupsGrid";
    import AddEditAcademicGroup from "./AddEditAcademicGroup";
    import AddSeveralAcademicGroup from "./AddSeveralAcademicGroup";
    import DeleteConfirm from "./DeleteConfirm";
    import MoveNextYearConfirm from "./MoveNextYearConfirm";
    import MoveGroupDialog from "./MoveGroupDialog";

    export default {
        components: {
            GroupsGrid,
            AddEditAcademicGroup,
            AddSeveralAcademicGroup,
            DeleteConfirm,
            MoveNextYearConfirm,
            MoveGroupDialog
        },
        mounted() {
            this.getItemsWithRoute();
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                dialogDeleteSelected: false,
                dialogDeleteSelectedWithStudents: false,
                dialogMove: false,
                dialogChangeMove: false,
                dialogAddSeveral: false,

                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,

                loading: false,

                selectedItems: [],
                fab: false,
            }
        },
        methods: {
            dismissDialogs() {
                this.dialogMove = false;
                this.dialogAddSeveral = false;
            },
            moveSelectedDialog() {
                this.dialogMove = true;
            },
            changeMoveSelectedDialog() {
                this.dialogChangeMove = true;
            },
            getItemsWithRoute() {
              this.getItems(this.$route.params.faculty, this.$route.params.speciality, this.$route.params.semester);  
            },
            groupMoved() {
                this.dialogMove = false;
                this.getItemsWithRoute();                
            },
            deleteSelectedDialog() {
                this.dialogDeleteSelected = true;
            },
            delectedSelectedDialogWithStudents() {
                this.dialogDeleteSelected = true;
                this.dialogDeleteSelectedWithStudents = true;
            },
            deleteSelectedClose() {
                this.dialogDeleteSelected = false;
                this.dialogDeleteSelectedWithStudents = false;
            },
            deleteSelected() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`academicGroup/deletes`, this.selectedItems)
                    .then(() => {
                        this.dialogDeleteSelected = false;
                        this.dialogDeleteSelectedWithStudents = false;
                        this.loading = false;

                        this.getItemsWithRoute();
                    });
            },
            getItems(facultyId, specialityId, semesterId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`academicGroup/${facultyId}/${specialityId}/${semesterId}`)
                    .then(subjects => {
                        this.items = subjects.items;
                        this.total = subjects.total;
                        this.loading = false;
                    })
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItemsWithRoute();
            },
            addedSeveral() {
                this.dialogAddSeveral = false;

                this.getItemsWithRoute();
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItemsWithRoute();
            },
            moved() {
                this.dialogMove = false;

                this.getItemsWithRoute();
            }
        },
        watch: {
          "$route.params": function (newRoute, oldRoute) {
              if (newRoute.faculty !== oldRoute.faculty || 
                  newRoute.speciality !== oldRoute.speciality || 
                  newRoute.semester !== oldRoute.semester) {
                  this.getItems(newRoute.faculty, newRoute.speciality, newRoute.semester);
                  this.selectedItems = [];
              }
          }  
        },
    }
</script>