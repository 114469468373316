<template>
    <v-card>
        <v-card-title>
            <span class="headline">Додавання оцінки</span>
        </v-card-title>
        <v-card-text>

            <div v-if="activeStep === 1">
                <topic-selector :activeModule="activeModule" @selectedTopics="setSelectedTopics($event)"/>
            </div>

            <div v-if="activeStep === 2">
                <mark-selector :activeModule="activeModule" 
                               :selected-topics="selectedTopics"
                               :errors="errors"
							   :loadingSave="loadingSave"
                               @change="onChangeMarks($event)"/>
            </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
                    :disabled="loadingSave"
            >Відміна
            </v-btn>
            <v-btn
                    v-if="selectedTopics.length > 0 && activeStep === 1"
                    color="primary"
                    type="button"
                    @click="passNextStep(2)"
            >Далі
                <v-icon>keyboard_arrow_right</v-icon>
            </v-btn>
            <v-btn
                    v-if="activeStep === 2 && !isSaved"
                    color="orange darken-1"
                    dark
                    type="button"
                    :disabled="loadingSave"
                    :loading="loadingSave"
                    @click="saveMarks"
            >Внести оцінки
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import TopicSelector from "../../common/marks-management/TopicSelector";
    import MarkSelector from "../../common/marks-management/MarkSelector";
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";

    export default {
        props: {
            activeModule: {
                type: Object
            }
        },
        components: {
            LoadingPlaceholder,
            TopicSelector,
            MarkSelector
        },
        data: () => {
            return {
                activeStep: 1,

                selectedTopics: [],
                loading: false,
                topicsMarkValues: null,
                
                errors: {},
                loadingSave: false,
                isSaved: false, 
            }
        },
        mounted() {
          this.errors = {};  
        },
        methods: {
            setSelectedTopics(topics) {
                this.selectedTopics = topics;
            },
            passNextStep(stepNumber) {
                this.activeStep = stepNumber;
            },
            onChangeMarks(value) {
                this.topicsMarkValues = value;
            },
            saveMarks() {
                this.$ga.event('professor', 'addMarks', 'groupId', this.$route.params.group);

                this.loadingSave = true;
                HttpService.getInstance()
                    .post(`teacher/teacherMarks`,
                        {
                            ...this.topicsMarkValues,
                            ...{
                                professor: {id: 1},
                                department: {id: this.$route.params.department},
                                subject: {id: this.$route.params.subject},
                                module: {id: this.activeModule.id},
                                academicGroup: {id: this.$route.params.group},
                            }
                        }
                    )
                    .then(resp => {
                        this.loadingSave = false;
                        this.isSaved = true;
                        this.$emit('shouldSaveAndClose');
                        this.$store.commit('snackMessages/set', {message: "Оцінки успішно внесені", color: 'success'});
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>