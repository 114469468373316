<template>
    <v-layout wrap>
        <v-flex xs12>
            <div class="subtitle-1">Список студентів, які мають завантажені документи для (певних) пропусків</div>
        </v-flex>
        <v-flex xs12>
            <admissions-student-list :students="students" :loading="loading"/>
        </v-flex>
    </v-layout>
</template>

<script>
    import HttpService from "../../../HttpService";

    import AdmissionsStudentList from "../../common/rework-admissions/AdmissionsStudentList";

    export default {
        components: {
            AdmissionsStudentList
        },
        data: () => ({
            students: [],
            loading: false,
        }),
        mounted() {
            this.getStudentDocumentsRework();
        },
        methods: {
            getStudentDocumentsRework() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`dean/getReworkDocumentsStudents`)
                    .then(resp => {
                        this.students = resp;
                        this.loading = false;
                    })
            },
        }
    }
</script>