import moment from "moment";
import localeDateFormats from "../i18n/locale-date-formats";
import {vueInstance} from "../main";

export const formatMarkDate = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);
    
    return moment(value).format(localeDateFormats[vueInstance.$i18n.locale].mark);
};

export const formatShortDate = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);

    return moment(value).format(localeDateFormats[vueInstance.$i18n.locale].short);
};

export const formatMonthDate = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);

    return moment(value).format(localeDateFormats[vueInstance.$i18n.locale].month);
};

export const formatLongDate = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);

    return moment(value).format(localeDateFormats[vueInstance.$i18n.locale].long);
};

export const formatAuditLogDate = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);

    return moment(value).format(localeDateFormats[vueInstance.$i18n.locale].auditLog);
};

export const formatLongMonths = (value) => {
    if (!value)  {
        return '';
    }
    moment.locale(vueInstance.$i18n.locale);

    return moment({year: value.year, month: value.month - 1, day: 1}).format("MMMM YYYY")
};