<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <expelled-academic-vacation-index></expelled-academic-vacation-index>
        </v-layout>
    </v-container>
</template>
<script>
    import ExpelledAcademicVacationIndex from "../../../../components/admin/Students/ExpelledAcademicVacation/ExpelledAcademicVacationIndex";
    export default  {
        components: {
            ExpelledAcademicVacationIndex,
        }
    }
</script>