<template>
    <div class="mark-input-control">
        <v-btn-toggle :value="value" @change="onChangeValue($event)">
            <v-btn v-if="isAbsence" small color="red darken-2" text :value="0">
                нб
            </v-btn>
           <template v-if="topic.evaluationMode === topicEvaluationModeEnum.isEvaluated">
               <v-btn v-if="!isReworkMode" class="mark-btn" small color="red" text :value="2">
                   2
               </v-btn>
               <v-btn class="mark-btn" small color="orange" text :value="3">
                   3
               </v-btn>
               <v-btn class="mark-btn" small color="blue" text :value="4">
                   4
               </v-btn>
               <v-btn class="mark-btn" small color="green" text :value="5">
                   5
               </v-btn>
           </template>
            <template v-if="topic.evaluationMode === topicEvaluationModeEnum.isNotEvaluated">
                <v-btn class="mark-btn" small color="green" text :value="1">
                    <v-icon color="green">check_circle_outline</v-icon>
                </v-btn>
            </template>
        </v-btn-toggle>
<!--        <span class="ml-2">-->
<!--          <v-chip color="grey lighten-2">{{ectsValue}}</v-chip>-->
<!--        </span>-->
    </div>
</template>

<script>
    import {topicEvaluationModeEnum} from "../../constants/topicEvaluationModeTypes";
    
    export default {
        props: {
            value: {
                type: Number,
                default: null,
            },
            id: {
                type: Number,
                default: null,
            },
            
            isAbsence: {
                type: Boolean,
                default: true,
            },
            isReworkMode: {
                type: Boolean,
                default: false,
            },
            topic: {
                type: Object,
                default: () => ({
                    evaluationMode: topicEvaluationModeEnum.isEvaluated
                }),
            }
        },
        data: () => ({
            // ectsValue: 0,
            topicEvaluationModeEnum,
        }),
        mounted() {
            // this.calculateEcts(this.value);
        },
        updated() {
          // this.calculateEcts(this.value);  
        },
        methods: {
            onChangeValue(value) {
                // this.calculateEcts(value);
                this.$emit('change', {
                    value,
                    id: this.id,
                });
            },
            // calculateEcts: function (value) {
            //     let ectsPoints = 0;
            //     switch (value) {
            //         case 5:
            //             ectsPoints = this.ects5;
            //             break;
            //         case 4:
            //             ectsPoints = this.ects4;
            //             break;
            //         case 3:
            //             ectsPoints = this.ects3;
            //             break;
            //         case 2:
            //         case 0:
            //         default:
            //             ectsPoints = 0;
            //     }
            //
            //     this.ectsValue = ectsPoints;
            // }
        }
    }
</script>

<style lang="scss">
    .mark-input-control {
        min-width: 200px;
        max-width: 230px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding-right: 10px;
        
        & button.v-btn {
            padding: 0 !important;
            min-width: 30px !important;
        }
    }

    .v-btn--active:before {
        opacity: .3 !important;
    }
</style>