<template>
    <div>
        <rework-schedules-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
        >
        </rework-schedules-grid>

        <v-dialog v-model="dialog" max-width="700px" scrollable>
            <edit-rework-schedule
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-item="editItemItem"
            ></edit-rework-schedule>
        </v-dialog>

    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import ReworkSchedulesGrid from "./ReworkSchedulesGrid";
    import EditReworkSchedule from "./EditReworkSchedule";

    export default {
        components: {
            ReworkSchedulesGrid,
            EditReworkSchedule
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`teacher/teacherReworkSchedule?${queryParams}`)
                    .then(reworkSchedules => {
                        this.items = reworkSchedules;
                        this.total = reworkSchedules.length;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editItemItem = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialog: false,
                editItemItem: null,
                editMode: false,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>