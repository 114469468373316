<template>
    <v-card>
        <v-card-title class="title">Тестування</v-card-title>
        <v-card-text>
            <div v-if="groupsStudents.length === 0">
                Ви впевнені, що хочете розпочати тестування для вибраних груп?
                <hr>
                <div class="caption">{{formattedSelectedGroups()}}</div>
            </div>
            <div v-if="groupsStudents.length !== 0">
                <semester-codes-print ref="print" :groups-students="groupsStudents"/>
            </div>
        </v-card-text>
        <v-card-actions v-if="groupsStudents.length === 0">
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    @click.native="startQuiz"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
        <v-card-actions class="no-print" v-if="groupsStudents.length !== 0">
            <v-spacer></v-spacer>
            <v-btn
                    color="warning display-none"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Закрити
            </v-btn>
            <v-btn
                    class="display-none"
                    color="primary"
                    @click.native="print"
            >Надрукувати
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import SemesterCodesPrint from "./SemesterCodesPrint";
    import { Printd } from 'printd';

    export default {
        data: () => ({
            loading: false,
            groupsStudents: [],
            printD: new Printd(),
            cssText: `
                .title,
                .display-none {
                    display: none !important;
                },
                .subheading {
                    margin-top: 20px;
                },
                table.table {
                   width: 100%;
                   margin-bottom: 1rem;
                   background-color: transparent;
                   border-collapse: collapse;
                }

                table.table th, table.table td {
                   padding: .2rem .75rem;
                   vertical-align: top;
                   border-top: 1px solid #dee2e6;
                }
                table.table thead th {
                   vertical-align: bottom;
                   border-bottom: 2px solid #dee2e6;
                }
            `
        }),
        components: {
            SemesterCodesPrint
        },
        props: {
            selectedGroups: {
                type: Array,
                default: () => [],
            },
            startSemesterQuizUrl: String,
        },
        mounted() {
            this.loading = false;
            this.groupsStudents = [];
        },
        methods: {
            print() {
                this.printD.print(this.$el, this.cssText);
            },
            startQuiz() {
                this.loading = true;

                HttpService.getInstance()
                    .post(this.startSemesterQuizUrl,
                        {
                            academicGroups: this.selectedGroups,
                            module: {id: this.$route.params.module},
                            subject: {id: this.$route.params.subject},
                            stepProfile: {id: this.$route.params.profile}
                        }
                    )
                    .then((resp) => {
                        this.groupsStudents = resp;

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            formattedSelectedGroups() {
                return this.selectedGroups.map(g => g.groupNumber).join(", ");
            }
        }
    }
</script>