<template>
    <div>
        <topics-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @navigateTo="navigateTo"
        >
        </topics-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import TopicsGrid from "./TopicsGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";

    export default {
        components: {
            TopicsGrid,
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                maxTopicOrder: 0,
                maxClassOrder: 0,
                module: {
                    topicsAllCount: 0,
                },
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`eAristoProfessor/module/${this.$route.params.module}`)  
              .then(module => {
                  this.module = module;
              })
        },
        methods: {
            navigateTo(topicId, routeName) {
                this.$router.push({
                    name: routeName,
                    params: {
                        subject: this.$route.params.subject,
                        department: this.$route.params.department,
                        module: this.$route.params.module,
                        topic: topicId,
                    }
                }).catch(err => {});
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`eAristoProfessor/topic/getByModule/${this.$route.params.module}?${queryParams}`)
                    .then(modules => {
                        this.items = modules.items;
                        this.total = modules.total;

                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
    }
</script>