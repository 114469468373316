<template>
    <v-card>
        <v-card-title class="title">Договір оферти на активацію допуску на пропущене заняття</v-card-title>
        <v-card-text style="min-height: 250px;">
            <block-loader v-if="loading"></block-loader>

            <h3 class="mt-1 mb-6"><router-link :to="{name: 'payment-terms-and-conditions'}">Постійне посилання на цей договір оферти</router-link></h3>
            <div v-if="!loading" v-html="text"></div>
        </v-card-text>
        <v-card-actions v-if="showAgreeBtn">
            <v-spacer></v-spacer>
            <v-btn
                @click="$emit('shouldClose')"
                color="primary"
            >Позначити як прочитане та закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import HttpService from "../../../HttpService";
import BlockLoader from "@/components/common/BlockLoader.vue";

export default {
    components: {
        BlockLoader
    },
    props: {
        showAgreeBtn: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        loading: false,
        text: '',
    }),
    mounted() {
        this.getText();
    },
    methods: {
        getText() {
            this.loading = true;

            HttpService.getInstance()
                .get(`settings/liqPayTermsAndConditions`)
                .then(resp => {
                    this.text = resp;
                    this.loading = false;
                });
        }
    }
}
</script>