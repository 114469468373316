import merge from "lodash/merge";

const defaultRoleMatrix = {
    marks: {
        read: true,
        write: false,
        delete: false,
    }
};

const userRoleMatrix = {
    admin: merge(defaultRoleMatrix, {
        marks: {
            write: true,
            delete: true,
        }
    }),
    rector: merge(defaultRoleMatrix, {}),
    dean: merge(defaultRoleMatrix, {}),
    inspector: merge(defaultRoleMatrix, {}),
    moderator: merge(defaultRoleMatrix, {}),
    professor: merge(defaultRoleMatrix, {}),
    headDepartment: merge(defaultRoleMatrix, {}),
    student: merge(defaultRoleMatrix, {}),
    curator: merge(defaultRoleMatrix, {}),
};

export default userRoleMatrix;