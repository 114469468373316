<template>
    <v-container fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :width="300" :mobile-break-point="0">
                        <v-list dense>
                            <v-subheader class="mt-3 grey--name name--darken-1">{{$t("studentKrok.spaSubjects")}}</v-subheader>
                            <navigation-list-template :items="subjects"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>
            <v-flex style="margin-left: 310px; width: calc(100% - 310px);">
                
                <quiz-by-subjects-index v-if="$route.params.subject"/>
                
                <v-card v-if="!$route.params.subject">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    {{$t("studentKrok.selectSubject")}}
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import QuizBySubjectsIndex from "../../components/student/QuizBySubjects/QuizBySubjectsIndex";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import HttpService from "../../HttpService";

    export default {
        components: {
            QuizBySubjectsIndex,
            NavigationListTemplate,
        },
        mounted() {
            HttpService.getInstance()
                .get("teacher/navigation/studentSubjects")
                .then(res => {
                    this.subjects = res.map(subject => {
                        return {
                            name: `${subject.nameUk}`,
                            route: {
                                name: 'studentSubjectQuiz',
                                params: {
                                    subject: subject.id,
                                }
                            }
                        }
                    });
                });
        },
        data: () => {
            return {
                subjects: [],
            }
        }
    }
</script>