<template>
    <v-card>
        <v-card-title class="title">Перегляд скріншота</v-card-title>
        <v-card-text>
            <img v-if="this.photoUrl !== null" width="1250" alt="" :src="photoUrlFull"/>
            <div v-if="this.photoUrl === null">Немає зображення</div>
        </v-card-text>
    </v-card>
</template>
<script>
    import {AppConfig} from "../../../config/configFactory";
    import config from "../../../app.config";

    let configuration = AppConfig(config);

    export default {
        data: () => {
            return {
            };
        },
        computed: {
            photoUrlFull: {
                get() { return `${configuration.apiEndpoint}/file/get/finalExamScreenshot/${this.photoUrl}`},
                set(val) { }
            }
        },
        props: {
            photoUrl: String,
        },
    }
</script>