<template>
    <div>
        <question-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @search="onSearch"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @deleteAll="dialogRemoveAll = true"
                @addBulk="dialogAddBulk = true"
        >
        </question-grid>

        <v-dialog v-model="dialog" max-width="700px">
            <add-edit-question
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :question-pool-id="editItemObj.id"
            ></add-edit-question>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm-question
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm-question>
        </v-dialog>

        <v-dialog v-model="dialogRemoveAll" max-width="290">
            <delete-confirm-all-questions
                    @shouldCloseDeleteConfirm="dialogRemoveAll = false"
                    @removedAll="removedAll"
                    :pool-id="editItemObj.id"
            >
            </delete-confirm-all-questions>
        </v-dialog>

        <v-dialog v-model="dialogAddBulk" max-width="700px">
            <add-bulk-questions
                    v-if="dialogAddBulk"
                    @shouldCloseAddEdit="dialogAddBulk = false"
                    @addedEdited="addedBulk"
                    :pool-id="editItemObj.id"
            ></add-bulk-questions>
        </v-dialog>

        <v-btn
                color="orange"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
                class="mr-2"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import QuestionGrid from "./QuestionGrid";
    import HttpService from "../../../../../HttpService";
    import {objectQueryStringify} from "../../../../../helpers/querystringHelper";
    import AddEditQuestion from "./AddEditQuestion";
    import DeleteConfirmQuestion from "./DeleteConfirmQuestion";
    import DeleteConfirmAllQuestions from "./DeleteConfirmAllQuestions";
    import AddBulkQuestions from "./AddBulkQuestions";

    export default {
        components: {
            QuestionGrid,
            AddEditQuestion,
            DeleteConfirmQuestion,
            DeleteConfirmAllQuestions,
            AddBulkQuestions
        },
        props: {
            editItemObj: {
                type: Object,
                default: () => {
                    return {
                        name: '',
                    }
                }
            },
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`stepQuestion/grid/${this.editItemObj.id}?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            addedBulk() {
                this.dialogAddBulk = false;

                this.getItems(objectQueryStringify(this.pagination));
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            removedAll() {
                this.dialogRemoveAll = false;

                this.getItems(objectQueryStringify(this.pagination));
            },
            onSearch(searchStr) {
                this.pagination.filter = [{
                    field: 'question',
                    value: searchStr,
                    operator: 'contains',
                }];

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialogAddBulk: false,
                dialogRemoveAll: false,
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                search: ''
            }
        }
    }
</script>