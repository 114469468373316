<template>
    <div>
        <marks-list-pager
                v-if="marksInfo.marksCount !== null"
                :marksCount="marksInfo.marksCount"
                :marksToLoad="marksToLoad"
                :currentPage="currentPage"
                @pageChange="onMarkPageChange"
        />

        <v-container class="register-container" ref="marksWrapper" style="position: relative">
            <block-loader v-if="loading"/>
            <v-layout justify-center>
                <v-flex xs12>
                    <table class="marks-table elevation-3 mb-6">
                        <thead>
                        <tr>
                            <th class="counter-header" rowspan="2" ref="counterHeader"></th>
                            <th class="student-name corner-left-top" ref="studentNameHeader">
                                Дата та тема заняття
                            </th>
                            <th rowspan="2"
                                class="cell-lesson-number"
                                :key="index"
                                v-for="(topicAndTopicClass, index) in this.marksInfo.topicAndTopicClasses">
                                <div v-if="topicAndTopicClass.topicClass !== null &&
                                    topicAndTopicClass.topicClass.editType === topicMarkEditTypesEnum.errorCorrection" class="triangle edit"></div>
                                <marks-list-topic-header-popover
                                        :topic-info="topicAndTopicClass"
                                        :activeModule="activeModule"
                                        :mark-edit-delete-settings="markEditDeleteSettings"
                                        @save="markEdited()"
                                        @removed="markRemoved()"
                                >
                                    <div class="lesson-date">
                                        <div>{{getTopicDate(topicAndTopicClass)}}</div>
                                        <div class="class-order-number">{{topicAndTopicClass.orderNumber}}</div>
                                    </div>
                                </marks-list-topic-header-popover>
                            </th>
                        </tr>
                        <tr>
                            <th class="student-name corner-left-bottom">
                                ПІП студента
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(studentMark, index) in this.marksInfo.studentsMarks">
                            <td class="counter-cell">{{index + 1}}.</td>
                            <td class="student-name">
                                {{studentMark.student.surname}} {{studentMark.student.name}}
                                {{studentMark.student.patronymic}}
                            </td>
                            <td
                                    v-for="(mark, index) in studentMark.marks"
                            >
                                <mark-list-mark-cell :mark="mark" :student="studentMark.student" :module="activeModule" @addSingleMark="addSingleMark($event)"/>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td></td>
                            <td class="cell-professor-name corner-prof-bottom">
                                ПІП викладача
                            </td>
                            <td class="cell-professor-name"
                                v-for="(topicAndTopicClass, index) in this.marksInfo.topicAndTopicClasses">
                                <marks-list-professor-cell :topic-info="topicAndTopicClass"/>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="addMarkDialog" max-width="990px" scrollable>
            <add-mark-dialog
                    v-if="addMarkDialog"
                    :activeModule="activeModule"
                    @shouldSaveAndClose="markAdded"
                    @shouldClose="addMarkDialog = false"
            ></add-mark-dialog>
        </v-dialog>
        
        <v-dialog v-model="addSingleMarkDialog" max-width="1000px" scrollable>
            <add-single-mark-dialog
                    v-if="addSingleMarkDialog"
                    :activeModule="activeModule"
                    :student-id="addSingleMarkSelectedStudentId"
                    :topic-class-id="addSingleMarkSelectedTopicClassId"
                    @shouldClose="onCloseSingleMark"
                    @saved="onSavedSingleMark"
            ></add-single-mark-dialog>
        </v-dialog>

        <v-dialog v-model="fixMarksDialog" max-width="600">
            <marks-fix-confirm
                    @shouldClose="fixMarksDialog = false"
                    @removed="marksFixed"
                    :module-id="activeModule.id"
            >
            </marks-fix-confirm>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="600">
            <marks-delete-confirm
                    @shouldClose="dialogRemove = false"
                    @removed="marksRemoved"
                    :module-id="activeModule.id"
            >
            </marks-delete-confirm>
        </v-dialog>
        
        <v-dialog v-model="printDialog" max-width="1200">
            <print-marks-dialog :activeModule="activeModule" @close="printDialog = false"></print-marks-dialog>
        </v-dialog>

        <v-speed-dial
                dark
                fixed
                bottom
                right
                fab
                direction="top"
                v-model="fab"
        >
            <template v-slot:activator>
                <v-btn
                        color="red darken-1"
                        dark
                        fab
                >
                    <v-icon v-if="fab">close</v-icon>
                    <v-icon v-else>add</v-icon>
                </v-btn>
            </template>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="primary"
                            @click="addMarkDialog = true"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                <span>Додати оцінку</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="blue-grey lighten-1"
                            @click="onPrint()"
                    >
                        <v-icon>print</v-icon>
                    </v-btn>
                </template>
                <span>Роздрукувати журнал групи</span>
            </v-tooltip>

            <v-tooltip bottom v-if="userIsAdminOrEdudept()">
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="orange darken-1"
                            @click="fixMarksDialog = true"
                    >
                        <v-icon>edit</v-icon>
                    </v-btn>
                </template>
                <span>Видалити оцінок поновлених студентів</span>
            </v-tooltip>

            <v-tooltip bottom v-if="userIsAdminOrEdudept()">
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            fab
                            dark
                            small
                            color="red"
                            @click="dialogRemove = true"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </template>
                <span>Видалити ВСІ оцінки з даного модуля</span>
            </v-tooltip>
        </v-speed-dial>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatMarkDate} from "../../../helpers/dateHelper";
    import AddMarkDialog from "./AddMarkDialog";
    import BlockLoader from "../../common/BlockLoader";
    import MarksListPager from "../../common/MarksListPager";
    import MarksListProfessorCell from "../../common/MarksListProfessorCell";
    import MarkListMarkCell from "../../common/MarkListMarkCell";
    import MarksListTopicHeaderPopover from "../../common/MarksListTopicHeaderPopover";
    import {topicMarkEditTypesEnum} from "../../../constants/topicMarkEditTypes";
    import AddSingleMarkDialog from "../../common/AddSingleMarkDialog";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import MarksFixConfirm from "./MarksFixConfirm";
    import MarksDeleteConfirm from "./MarksDeleteConfirm";
    import PrintMarksDialog from "./PrintMarksDialog";

    export default {
        components: {
            AddSingleMarkDialog,
            AddMarkDialog,
            BlockLoader,
            MarksListPager,
            MarksListProfessorCell,
            MarkListMarkCell,
            MarksListTopicHeaderPopover,
            MarksFixConfirm,
            MarksDeleteConfirm,
            PrintMarksDialog,
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                fab: false,
                addMarkDialog: false,
                fixMarksDialog: false,
                dialogRemove: false,
                
                addSingleMarkDialog: false,
                addSingleMarkSelectedStudentId: null,
                addSingleMarkSelectedTopicClassId: null,
                
                printDialog: false,
                
                marksInfo: {
                    studentsMarks: [],
                    topicAndTopicClasses: [],
                    marksCount: null,
                },
                marksToLoad: 0,
                loading: false,
                currentPage: 0,
                topicMarkEditTypesEnum,
                
                markEditDeleteSettings: {
                    markEditMinutes: 0,
                    markDeleteMinutes: 0,
                }
            }
        },
        mounted() {
            this.$ga.event('professor', 'getMarks', 'groupId', this.$route.params.group);

            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.getMarks();
                });
            });
        },
        methods: {
            onMarkPageChange(value) {
                this.currentPage = value;
                this.getMarks();
            },
            getMarks() {
                this.marksToLoad = this.calculateMarksToLoad();

                this.loading = true;
                HttpService.getInstance()
                    .get(`teacher/teacherMarks/${this.$route.params.group}/${this.activeModule.id}/${this.marksToLoad}/${this.calculateSkipParam()}`)
                    .then(resp => {
                        this.marksInfo = resp;
                        this.loading = false;
                    });
                
                HttpService.getInstance()
                .get('settings/markEditDeleteTime')
                .then(resp => {
                    if (resp) {
                        this.markEditDeleteSettings.markDeleteMinutes = resp.markDeleteIntervalMinutes;
                        this.markEditDeleteSettings.markEditMinutes = resp.markEditIntervalMinutes;
                    }
                });
            },
            calculateSkipParam() { 
                return this.currentPage * this.marksToLoad;
            },
            markAdded() {
                this.addMarkDialog = false;
                this.getMarks();
            },
            markEdited() {
                this.getMarks();
            },
            markRemoved() {
                this.getMarks();
            },
            getTopicDate(topicAndTopicClass) {
                if (topicAndTopicClass.topicClass) {
                    return formatMarkDate(topicAndTopicClass.topicClass.classDate);
                }

                return null;
            },
            calculateMarksToLoad() {
                const marksWrapperWidth = this.$refs.marksWrapper.clientWidth;
                const counterHeaderWidth = 30;
                const studentNameHeaderWidth = 250;

                const widthForMarks = marksWrapperWidth - counterHeaderWidth - studentNameHeaderWidth;
                const markWidth = 70;

                return Math.round(widthForMarks / markWidth);
            },
            addSingleMark({topicClassId, studentId}) {
                this.addSingleMarkDialog = true;
                this.addSingleMarkSelectedTopicClassId = topicClassId;
                this.addSingleMarkSelectedStudentId = studentId;
            },
            onSavedSingleMark() {
                this.getMarks();
                
                this.addSingleMarkDialog = false;
                this.addSingleMarkSelectedTopicClassId = null;
                this.addSingleMarkSelectedStudentId = null;
            },
            onCloseSingleMark() {
                this.addSingleMarkDialog = false;
                this.addSingleMarkSelectedTopicClassId = null;
                this.addSingleMarkSelectedStudentId = null;
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
            marksFixed() {
                this.fixMarksDialog = false;
                this.getMarks();
            },
            marksRemoved() {
                this.dialogRemove = false;
                this.getMarks();
            },
            onPrint() {
                this.printDialog = true;
                this.$ga.event('professor', 'printMarks', 'groupId', this.$route.params.group);
            }
        },
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }
</style>