export default {
    message: {
        hello: 'привіт світ'
    },
    announcements: {
        title: "Оголошення",
        norecords: "Немає актуальних оголошень на даний момент...",
        types: {
            educationalDpt: "Навчальний відділ",
            deanOffice: "Деканат",
            department: "Кафедра",
            curator: "Куратор",
        }
    },
    dashboard: {
        "loginButtonTextFull": "Увійти в ePlato",
        "loginButtonTextShort": "Увійти",
        "testVersionExclamation": "Це тестова версія! Вхід для студентів тільки через <a href=\"https://ez.pdmu.edu.ua\">ez.pdmu.edu.ua</a>!!!",
        "supportTitle": "Контакти служби підтримки Еж",
        "accountQuestions": "З питань відновлення доступу, пишіть, вказуючи: факультет, курс, група, ПІБ на e-mail ",
        "postNote": "УВАГА! Немає потреби контактувати з іншими відповідальними особами за роботу Еж. " +
        "При необхідності, Ваше запитання/проблему буде автоматично перенаправлено до інших спеціалістів!",
        "cannotLogIn": "Не вдається увійти?",
        "universityName": "Полтавський державний медичний університет"
    },
    surveys: {
        title: "Опитування",
        norecords: "Немає актуальних опитувань на даний момент...",
    },
    toolbar: {
        announcements: "Оголошення",
        surveys: "Опитування",
        logOut: "Вийти",
        settings: "Налаштування"
    },
    navigation: {
        student: {
            title: "Студент",
            description: "Всі ваші оцінки"
        },
        admin: {
            title: "Адмін",
            description: "Панель адміністратора"
        },
        dean: {
            title: "Декан",
            description: "Панель декана"
        },
        chiefdept: {
            title: "Зав.кафедрою",
            description: "Панель завідуючого кафедрою"
        },
        moderator: {
            title: "Модератор",
            description: "Панель модератора"
        },
        teacher: {
            title: "Викладач",
            description: "Панель викладача"
        },
        rating: {
            title: "Рейтинг",
            description: "Панель рейтингу"
        },
        ratingAdmin: {
            title: "Рейтинг:Адмін",
            description: "Панель адміністратора рейтингу"
        },
        reworks: {
            title: "Відпрацювання",
            description: "Панель відпрацювань"
        },
        labstaff: {
            title: "Лаборант",
            description: "Панель лаборанта"
        },
        teacherFeedback: {
            title: "Feedback",
            description: "Панель зворотнього зв'язку"
        },
        finalExam: {
            title: "Випускний іспит",
            description: "Панель випускного іспиту",
        },

        superAdminKrok: {
            title: "СуперАдмін Крок",
            description: "СуперАдмін Крок"
        },
        krok: {
            title: "Крок",
            description: "Тренінг по КРОК-ах 1"
        },
        modulesKrok: {
            title: "Крок: Модулі",
            description: "Тестування по модулях"
        },
        spaKrok: {
            title: "Крок: СПА",
            description: "СПА тестування"
        },
        profileKrok: {
            title: "Крок: КТІ",
            description: "КТІ тестування"
        },
        modulesQuizList: {
            title: "Банк тестів",
            description: "Банк тестів по модулях"
        },
        nbSupervisor: {
            title: "Супервізор перескл.",
            description: "Панель супервізора"
        },
        nbQuiz: {
            title: "ЕжНб: Банк тестів",
            description: "Банк тестів ЕжНб",
        },
        eAristoStudent: {
            title: "eAristo: Студент",
            description: "Самостійне навчання студента",
        },
        eAristoProfessor: {
            title: "eAristo: Викладач",
            description: "Програма самостійного навчання",
        },
    },

    finalExam: {
        infoAbout: "Інформація про випускні іспити",
        notYetRegistered: "Ви ще не зареєстровані на іспит",
        registerForExam: "Зареєструватися на іспит",
        youAreRegistered: "Ви зареєструвалися на іспит",
        examName: "Назва іспиту",
        googleMeetLinkExam: "Посилання на Google Meet для складання іспиту",
        linkShow10MinsBeforeExam: "Посилання буде вказане за 10хв до початку іспиту",
        navigateToExam: "Перейти до іспиту",
        noMeetLinkAvailable: "Немає посилання на GoogleMeet",
        dateAndTimeExam: "Дата та час початку іспиту",
        examinationPaperNumber: "Номер білету",
        ticketNumberWillBeShown10Mins: "Номер білету буде вказаний за 10хв до початку іспиту",
        passed: "Пройдено",
        notPassed: "Не пройдено",
        resultGraduationExams: "Результати складання випускних іспитів",
        noGraduationExamResults: "Немає результатів складання іспиту",
        examResultTraditional: "Результат складання (традиційна оцінка)",
        noPublishedResult: "Немає оголошеного результату",
        examResultEcts: "Результат складання (ECTS)",
        menuFinalExamStudentDashboard: "Випускні іспити",
        menuFinalExamResults: "Результати іспитів",
        chooseMenuItemOnLeft: "Виберіть пункт меню ліворуч",
        stStatus: "Статус",
        resultKrokExam: "Результат складання КРОК"
    },
    studentKrok: {
        electronicRegister: "Електронний журнал",
        spaTraining: "Тренінг по СПА",
        ktiTraining: "Тренінг по (КРОК) КТІ",
        spaSubjects: "Дисципліни СПА",
        topics: "Теми",
        selectSubject: "Виберіть дисципліну",
        selectTopic: "Виберіть тему",
        questionsCount: "Кількість тестів для тестування",
        startQuiz: "Розпочати тестування",
        problematicQuestions: "Проблемні питання",
        attempt: "Спроба",
        from: "з",
        attemptsOnPage: "Спроб на сторінці",
        all: "Всі",
        continue: "Продовжити",
        start: "Початок",
        finish: "Завершення",
        questionCount: "Всього запитань",
        questionCorrectCount: "Вірних відповідей",
        questionCorrectPercentage: "% вірних відповідей",
        actions: "Дії",
        noData: "Немає даних",
        errorCount: "К-сть помилок:",
        close: "Закрити",
        noProblematicQuestions: "Наразі немає проблемних запитань",
        withProblematicQuestions: "Включити проблемні питання",
    },
    nbSupervisor: {
        quizComputers: "Список комп'ютерів"
    },
    studentQuiz: {
        remaining: "Залишилося",
        automaticSwitch: "Автоматично переключати запитання",
        questionNo: "Питання №",
        previousQuestion: "Попереднє запитання",
        nextQuestion: "Наступне запитання",
        answeredQuestion: "На дане питання вже була дана відповідь",
        finishQuiz: "Завершити тестування",
        quizIsFinished: "Тестування завершено",
        logoutFromSystem: "Вийти зі системи"
    },
    student: {
        marks: "Оцінки",
        calendar: "Календар оцінок",
        marksMonthly: "Оцінки помісячно",
        absents: "Пропуски занять",
        reworks: "Консультації & відпрацювання",
        payments: "Історія платежів",
        
        chooseSubject: "Виберіть дисципліну",
        showInfoModule: "Перегляд інформації по модулях",
        showGroupStatistics: "Перегляд статистики в групі",
        showFacultyStatistics: "Перегляд статистики на факультеті",
        
        moduleName: "Назва модуля",
        currentPoints: "Поточний бал",
        averageMark: "Середня оцінка",
        moduleControlMark: "Оцінка за модульний контроль",
        totalMark: "Загальна оцінка",
        totalMarkClassic: "Загальна оцінка по класичній шкалі",
        totalMarkECTS: "Загальна оцінка ECTS",
        date: "Дата",
        professor: "Викладач",
        pip: "ПІП",
        topic: "Тема",
        subject: "Дисципліна",
        abs: "нб",
        numberof: "Кількість",
        numberof2: "Кількість '2'",
        numberofabsences: "Кількість пропусків",

        paymentMsg1: "Щоб обрати пропущені заняття без поважної причини",
        paymentMsg2: "для оплати клікніть на них. Ви можете обрати одразу декілька.",
        
        pay: 'Сплатити',
        choosenAbs: 'Обрано пропусків',
        totalReworkPrice: 'Загальна вартість відпрацювання',
        
        attachment: "Вкладення",
        department: "Кафедра",
        hours: "Години",
        reworkPrice: "Вартість відпрацювання пропущеного заняття",
        absenceType: "Заборгованість",
        
        emited: "Виданий",
        expired: "Прострочений",
        nonvalid: "Не валідний",
        
        
        paymentStatus: 'Статус',
        description: 'Опис',
        amount: 'Сума',
        currency: 'Грошова одиниця',
        paymentDate: 'Дата оплати',
        card: 'Картка',
        
        overview: 'Огляд',
        module: 'Модуль',
        practicalClasses: 'Практичні заняття',
        lecturesClasses: 'Лекційні заняття',
        independentClasses: 'Самостійна робота',
    },
    eAristoStudent: {
        subjectList: "Дисципліни",
        favorites: "Улюблені",
        statistics: "Статистика проходження",
        goToModuleList: "Перейти до списку модулів",
        modules: "Модулі",
        selectModule: "Виберіть модуль",
        goToTopicsList: "Перейти до списку тем",
        topics: "Теми",
        selectTopic: "Виберіть тему",
        topicName: "Назва теми",
        action: "Дія",
        quizStart: "Початок",
        quizEnd: "Кінець",
        quizResult: "Результат",
        quizFinished: "Завершено",
        quizCounted: "Зараховано",
        quizInteractiveVideo: "Інтерактивнe відео",
        quizInteractivePresentation: "Інтерактивна презентація",
        quizTopicAll: "Тестування по темі",
        thisActivity: "Даний вид активності необхідно пройти лише після завершення інших видів активностей:",
        quizStartQuiz: "Розпочати тестування",
        quizFinishQuiz: "Завершити тестування",
        topicAndQuizPassed: "Тему та тестування пройдено",
        sendAnswers: "Відправити відповіді",
        youAnsweredWrong: "Ви відповіли неправильно на запитання. Необхідно пройти заново останній фрагмент матеріалу та тестування.",
        youAnsweredCorrect: "Ви відповіли правильно!",
        youAnsweredThisBlock: "Ви відповіли на даний блок запитань!",
        pleaseSelectOneAnswer: "Будь ласка, виберіть одну правильну відповідь та натисність \"Відправити відповідь\"",
        pleaseFillMissedWords: "Будь ласка, заповніть пропущені слова в поля, які нижче та натисність \"Відправити відповідь\". Ви повинні заповнити усі поля.",
        answer: "Відповідь",
        youAnsweredCorrectThisBlock: "Ви успішно відповіли на даний блок запитань!",
        youNotAnsweredCorrectThisBlock: "Ви НЕ склали успішно даний блок запитань!",
        videoBrowserWarning: "На жаль, проходження інтерактивного відео у <strong>веб-переглядачі Mozilla Firefox не підтримується</strong>. Будь ласка, оберіть будь-який інший сучасний веб-переглядач: Chrome, Safari, Opera.",
        subjectName: "Назва дисципліни",
        moduleName: "Назва модуля",
        goToSubjectList: "Перейти до списку дисциплін",
        yourResultIs: "Ваш результат",
        clickFinishTestToFinishTopic: "Натисніть \"Завершити тестування\" щоб завершити проходження даної теми.",
        clickFinishTestRestartTopic: "Натисніть \"Завершити тестування\" після чого Ви зможете розпочати нове тестування, щоб спробувати знову.",
        playVideo: "Відтворити",
        pauseVideo: "Пауза",
        presPrev: "Попередній",
        presNext: "Наступний",
        youFinishedPresentation: "Ви завершили перегляд презентації. Це останній слайд. Якщо необхідно, дайте відповіді на запитання під даним слайдом і перейдіть до іншого виду активності."
    },
}