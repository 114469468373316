<template>
    <div>
        <h3 class="text-center mb-3">{{topic ? `${topic.orderNumber}. ${topic.nameUk}` : ""}}</h3>

        <v-expansion-panels v-model="panel">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h4>Інтерактивне відео</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                    <video-content :video-content="videoContentItem" :loading="loadingVideo" @uploadedVideo="onUploadedVideo" @reloadVideo="onReloadVideo"></video-content>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <h4>Інтерактивна презентація</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                    <presentation-content :slides-content="slidesContent" :loading="loadingSlides" @uploadedPresentation="onUploadedPresentation"></presentation-content>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                   <h4>Тестування по темі</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    
                    <all-topic-content></all-topic-content>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import VideoContent from "@/components/eAristoProfessor/topicContent/videoContent/VideoContent.vue";
import PresentationContent from "@/components/eAristoProfessor/topicContent/presentationContent/PresentationContent.vue";
import AllTopicContent from "@/components/eAristoProfessor/topicContent/AllTopicContent.vue";

export default {
    components: {
        VideoContent,
        PresentationContent,
        AllTopicContent,
    },
    data: () => {
        return {
            dialogFeedback: false,
            
            panel: 0,
            loading: false,
            loadingVideo: false,
            loadingSlides: false,
            videoContentItem: null,
            slidesContent: [],
            topic: null,
        };
    },
    mounted() {
        this.loading = true;

        HttpService.getInstance()
            .get(`topic/${this.$route.params.topic}`)
            .then(topic => {
                this.loading = false;
                this.topic = topic;
            })

        this.getVideo();
        this.getSlides();
    },
    methods: {
        getVideo() {
            this.loadingVideo = true;
            HttpService.getInstance()
                .get(`eAristoProfessor/videoContent/${this.$route.params.topic}`)
                .then(videoContentItem => {
                    this.loadingVideo = false;
                    this.videoContentItem = videoContentItem ? videoContentItem : null;
                })
        },

        getSlides() {
            this.loadingSlides = true;
            HttpService.getInstance()
                .get(`eAristoProfessor/slidesContent/${this.$route.params.topic}`)
                .then(slidesContent => {
                    this.loadingSlides = false;
                    this.slidesContent = slidesContent;
                })
        },
        
        onReloadVideo() {
            this.getVideo();
        },
        onUploadedVideo(video) {
            this.getVideo();
        },
        onUploadedPresentation() {
            this.getSlides();
        }
    }
}
</script>