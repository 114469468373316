<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex v-if="showTopicBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'quizBankModule', 
                            params: {department: $route.params.department, subject: $route.params.subject, module: $route.params.module}}" 
                           color="primary"
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> Перейти до тем & лекцій</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Теми</v-subheader>
                            <navigation-list-template :items="topics"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.topic" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть тему
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <quizes-index type="topic" :key="$route.fullPath" v-if="$route.params.topic"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import QuizesIndex from "../../components/nbQuizAdmin/quizes/QuizesIndex";
    import HttpService from "../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            QuizesIndex
        },
        data: () => ({
            topics: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`teacher/navigation/studentTopics/${this.$route.params.subject}/${this.$route.params.module}`)
                .then(resp => {
                    this.topics = resp.map(topic => {
                        topic.name = `${topic.orderNumber}. ${topic.nameUk}`;
                        topic.route = {
                            name: 'quizBankTopic',
                            params: {
                                department: this.$route.params.department,
                                subject: this.$route.params.subject,
                                module: this.$route.params.module,
                                topic: topic.id
                            }
                        };

                        return topic;
                    });
                });
        },
        methods: {
            showTopicBlock() {
                return this.$route.name === "quizBankTopic" &&
                    this.$route.params.department &&
                    this.$route.params.subject &&
                    this.$route.params.module;
            },

        },
    }
</script>