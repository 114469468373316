<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Факультети</v-subheader>
                            <navigation-list-template :items="faculties"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="!isSelectedFaculty" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть факультет
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <statistics-reworks-admissions-by-faculty :key="$route.fullPath" v-if="isSelectedFaculty"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import NavigationListTemplate from "@/components/common/NavigationListTemplate";
import HttpService from "@/HttpService";
import StatisticsMarksTimeFacultyIndex from "@/components/admin/StatisticsMarks/StatisticsMarksTimeFacultyIndex";
import StatisticsReworksAdmissionsByFaculty
    from "@/components/admin/StatisticsReworkAdmissions/StatisticsReworksAdmissionsByFaculty";

export default {
    components: {
        NavigationListTemplate,
        StatisticsReworksAdmissionsByFaculty,
    },
    props: {
        routeTo: {
            type: String,
            default: "groupsGrid"
        },
    },
    data: () => {
        return {
            isSelectedFaculty: false,
            faculties: [],
        };
    },
    mounted() {
        HttpService.getInstance()
            .get("faculties/getAll")
            .then(res => {
                this.faculties = res.map(faculty => {
                    faculty.name = faculty.nameUk;
                    faculty.route = {
                        name: 'statisticsReworkAdmissions',
                        params: {
                            faculty: faculty.id,
                        }
                    };

                    return faculty;
                });
            });

        this.isSelectedFaculty = this.$route.params.faculty !== undefined;
    },
    watch: {
        $route: function(route, prevRoute) {
            if (route.params.faculty !== prevRoute.params.faculty) {
                this.isSelectedFaculty = route.params.faculty !== undefined;
            }
        },
    },
}
</script>