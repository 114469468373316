<template>
    <v-card @click="$router.push({name: 'announcementSingle', params: {id: announcement.id}})" 
            ripple class="elevation-8 announcement-card" min-height="100" v-if="announcement">
        <v-img
                v-if="announcement.titleImageUrl !== null"
                class="blue--text text--darken-4 cursor-pointer"
                height="200px"
                :src="`${$config.apiEndpoint}/file/get/announcement/${announcement.titleImageUrl}`"
        >
        </v-img>
        <div class="title-wrapper">
                <div class="title">
                    <div class="headline">{{announcement[`title${$i18n.lang}`]}}</div>
                    <div class="subtitle">
                        <div>
                            {{ formatShortDate(new Date()) }}
                        </div>
                        <div>
                            <v-chip color="primary" text-color="white">
                                <div v-if="announcement.announcementFromType === announcementFromTypesEnum.department && 
                                            announcement.belongsToDepartment !== null">
                                    Кафедра: {{announcement.belongsToDepartment.nameUk}}
                                </div>
                                <div v-if="announcement.announcementFromType === announcementFromTypesEnum.deanOffice && 
                                            announcement.belongsToDean !== null">
                                    Декан: {{announcement.belongsToDean.surname}} {{announcement.belongsToDean.name}}
                                </div>
                                <div v-if="announcement.announcementFromType === announcementFromTypesEnum.curator && 
                                            announcement.belongsToCurator !== null">
                                    Куратор: {{announcement.belongsToCurator.surname}} {{announcement.belongsToCurator.name}}
                                </div>
                                <div v-if="announcement.announcementFromType === announcementFromTypesEnum.educationalDpt">
                                    Навчальний відділ
                                </div>
                            </v-chip>
                        </div>
                    </div>
                </div>
            </div>
    </v-card>
</template>

<script>
    import announcementFromTypes, {announcementFromTypesEnum} from "../../constants/mainDashboard/announcementFromTypes";
    import {formatShortDate} from "../../helpers/dateHelper";

    export default {
        props: {
            announcement: {
                type: Object
            }
        },
        data: () => ({
            formatShortDate,
            announcementFromTypesEnum
        }),
        methods: {
          getAnnouncementType(announcementFromType) {
              return announcementFromTypes.find(a => a.id === announcementFromType).nameConst;
          }  
        },
    }
</script>
<style scoped lang="scss">
    .title-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }

    .title {
        display: block;
        padding: 0.5em;
        background: rgba(21, 101, 192, 0.45);
        color: white;
        width: 100%;

        & .headline {
            line-height: 26px !important;
            margin-bottom: 15px;
        }
    }

    .subtitle {
        font-size: 14px;
        font-weight: normal;
        justify-content: space-between;
        display: flex;
        align-items: baseline;
        
        & div {
            display: inline-flex;
        }
    }
    
    .announcement-card .v-responsive.v-image {
        height: inherit !important;
    }
</style>