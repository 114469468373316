<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex>
                <div xs12 class="display-1 mb-4">{{ $t("finalExam.infoAbout") }}</div>
                <div v-if="!isCheckedIn">
                    <v-alert type="warning" class="mb-3">
                        {{$t("finalExam.notYetRegistered")}}
                    </v-alert>
                    
                    <v-btn
                            :disabled="isLoading"
                            @click="checkinToExam"
                            large color="primary">
                        {{$t("finalExam.registerForExam")}}
                    </v-btn>
                </div>
                <div v-if="isCheckedIn">
                    <v-alert type="info">
                        {{$t("finalExam.youAreRegistered")}}
                    </v-alert>
                </div>
                <v-card v-if="isCheckedIn"
                        :key="index"
                        v-for="(exam, index) in exams"
                        class="mb-3 px-4 py-2">
                    <v-layout wrap>
                        <v-flex xs4>
                            <div class="body-2">
                                {{$t("finalExam.examName")}}
                            </div>
                            <div class="title">
                                {{exam.finalExamName.name}}
                            </div>
                        </v-flex>
                        <v-flex xs3>
                            <div class="body-2">
                                {{$t("finalExam.googleMeetLinkExam")}}
                            </div>
                            <div class="title">
                                <div v-if="!exam.isTimeApproached && !exam.isPassed">
                                    <v-alert type="info" class="body-2">
                                        {{$t("finalExam.linkShow10MinsBeforeExam")}}
                                    </v-alert>
                                </div>
                                <div v-if="exam.isTimeApproached && !exam.isPassed">
                                    <a v-if="exam.meetUrl !== '' && exam.meetUrl !== null" 
                                       :href="`${exam.meetUrl}`" target="_blank">
                                        {{$t("finalExam.navigateToExam")}}
                                    </a>
                                    <div class="body-1" v-if="exam.meetUrl === '' || exam.meetUrl === null">
                                        <i>{{$t("finalExam.noMeetLinkAvailable")}}</i>
                                    </div>
                                </div>
                                <div v-if="exam.isPassed">-</div>
                            </div>
                        </v-flex>
                        <v-flex xs2>
                            <div class="body-2">
                                {{$t("finalExam.dateAndTimeExam")}}
                            </div>
                            <div class="title">
                                <div>{{formatLongDate(exam.examDateTime)}}</div>
                            </div>
                        </v-flex>
                        <v-flex xs2 class="text--darken-3 red--text">
                            <div class="body-2">{{$t("finalExam.examinationPaperNumber")}}</div>
                            <div class="title">
                                <div v-if="!exam.isTimeApproached && !exam.isPassed">
                                    <v-alert type="info" class="body-2">
                                        {{$t("finalExam.ticketNumberWillBeShown10Mins")}}
                                    </v-alert>
                                </div>
                                <div v-if="exam.isTimeApproached && !exam.isPassed">
                                    <div>{{exam.examinationNumber}}</div>
                                </div>
                                <div v-if="exam.isPassed">-</div>
                            </div>
                        </v-flex>
                        <v-flex xs1 class="text--darken-3">
                            <div class="body-2">{{$t("finalExam.stStatus")}}</div>
                            <div class="subtitle-2">
                                <div v-if="exam.isPassed">{{$t("finalExam.passed")}}</div>
                                <div v-if="!exam.isPassed">{{$t("finalExam.notPassed")}}</div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import moment from "moment";
    import {formatLongDate} from "../../../helpers/dateHelper";
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            isCheckedIn: false,
            exams: [],
            isLoading: false,
            formatLongDate,
            
            interval: null,
        }),
        mounted() {
            this.getIsCheckIn();
            
            this.getExamsList();

            this.interval = setInterval(function () {
                this.getExamsList();
            }.bind(this), 30000);
        },
        methods: {
            getExamsList() {
                HttpService.getInstance()
                    .get("FinalExamStudent/GetStudentExamList")
                    .then(resp => {
                        this.exams = resp;
                    })  
            },
            
            getIsCheckIn() {
                this.isLoading = true;
                HttpService.getInstance()
                    .get("FinalExamStudent/checkIn")
                    .then(resp => {
                        this.isLoading = false;
                        this.isCheckedIn = resp;
                    })
            },
            checkinToExam() {
                this.isLoading = true;
                HttpService.getInstance()
                    .post("FinalExamStudent/checkIn")
                    .then(resp => {
                        this.isLoading = false;
                        this.isCheckedIn = resp;
                        
                        this.getExamsList();
                    });
            }
        },
        beforeDestroy: function(){
            clearInterval(this.interval);
        }
    }
</script>