<template>
    <v-navigation-drawer
            :fixed="true"
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :value="drawer"
            fixed
            @input="$emit('toggleDrawer', $event)"
    >
        <v-container fluid>
            <v-btn class="mb-6" small :to="{
                        name: 'headDeptRegistersRoot', 
                        params: {department: $route.params.department, subject: $route.params.subject}
                        }">
                <v-icon>keyboard_backspace</v-icon>
                Перейти до меню
            </v-btn>
            
            <department-subject-switcher
                    :is-open="isDepartmentSubjectSwitcherOpened"
                    :is-show-button="isDepSubjNavigation()"
                    @changeDialogState="isDepartmentSubjectSwitcherOpened = $event"
                    route-back="chiefdeptHome"
                    route-to="headDeptRegistersRoot"
            />
        </v-container>
        
        <navigation-stud-nav-list :api-endpoint="getNavigationApiRoute" 
                                  :route-to="routeTo" 
                                  :additional-route-params="additionalRouteParams"
        />
    </v-navigation-drawer>
</template>
<script>
    import NavigationStudNavList from '../../common/NavigationStudNavList';
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";

    export default {
        components: {
            NavigationStudNavList,
            DepartmentSubjectSwitcher
        },
        props: {
            drawer: {
                type: Boolean,
            },
            routeTo: {
                type: String,
                default: "registers"
            },
            additionalRouteParams: {
                type: Object,
                default: () => {
                }
            }
        },
        computed: {
            getNavigationApiRoute() {
                return `navigation/getNavRegistersForDepartmentSubject/${this.$route.params.department}/${this.$route.params.subject}`;
            }
        },
        methods: {
            isDepSubjNavigation() {
                return (this.$route.params.department !== undefined &&
                    this.$route.params.subject !== undefined);
            },
            dialogNavigationDepartmentSubject() {
                if (!this.isDepSubjNavigation() && this.$route.name === 'marksRoot') {
                    this.isDepartmentSubjectSwitcherOpened = true;
                }
            },  
        },
        data: () => ({
            isDepartmentSubjectSwitcherOpened: false,
        }),
    }
</script>