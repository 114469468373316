<template>
    <div class="d-flex justify-space-between align-center mb-4">
        <div class="flex-grow-1"></div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    style="max-width: 300px;"
                    v-model="dateRangeText"
                    label="Фільтрувати за датами"
                    prepend-icon="calendar_month"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="dates"
                no-title
                scrollable
                range
            >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Відміна
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                >
                    ОК
                </v-btn>
            </v-date-picker>
        </v-menu>
        <v-select
            style="max-width: 250px"
            class="ml-2"
            :items="filterItems"
            v-model="dateFilter"
            @change="onFilter($event)"
            item-text="name"
            item-value="id"
            label="Фільтри"
            hide-details
            solo
        ></v-select>
        <v-btn class="ml-2"
               @click="clearFilter()" text>Очистити
        </v-btn>
        <v-btn class="ml-2"
               @click="search()" color="primary">Пошук
        </v-btn>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "StatDateRange",
    data() {
        return {
            dates: [],
            menu: false,

            dateFilter: null,
            filterItems: [
                {
                    id: '7days',
                    name: 'Останні 7 днів',
                },
                {
                    id: '1week',
                    name: 'Останній 1 тиждень',
                },
                {
                    id: '2week',
                    name: 'Останні 2 тижня',
                },
                {
                    id: '1month',
                    name: 'Останній 1 місяць',
                },
                {
                    id: '2month',
                    name: 'Останні 2 місяці',
                },
                {
                    id: '3month',
                    name: 'Останні 3 місяці',
                },
            ],
        };
    },
    methods: {
        onFilter(evt) {
            switch(evt) {
                case '7days':
                    this.dates = [
                        moment().subtract(7, 'days').format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),
                    ];

                    break;

                case '1week':
                    this.dates = [
                        moment().startOf('week').format("YYYY-MM-DD"),
                        moment().endOf('week').format("YYYY-MM-DD"),
                    ];
                    break;
                case '2week':
                    this.dates = [
                        moment().subtract(2, 'weeks').startOf('week').format("YYYY-MM-DD"),
                        moment().endOf('week').format("YYYY-MM-DD"),
                    ];
                    break;

                case '1month':
                    this.dates = [
                        moment().startOf('month').format("YYYY-MM-DD"),
                        moment().endOf('month').format("YYYY-MM-DD"),
                    ];
                    break;
                case '2month':
                    this.dates = [
                        moment().subtract(2, 'months').startOf('month').format("YYYY-MM-DD"),
                        moment().endOf('month').format("YYYY-MM-DD"),
                    ];
                    break;
                case '3month':
                    this.dates = [
                        moment().subtract(3, 'months').startOf('month').format("YYYY-MM-DD"),
                        moment().endOf('month').format("YYYY-MM-DD"),
                    ];
                    break;
            }

            this.$emit("onSearch", this.dates);
        },
        search() {
            this.$emit("onSearch", this.dates);
        },
        clearFilter() {
            this.dates = [];
            this.dateFilter = null;

            this.$emit("onClear", this.dates);
        },
    },
    computed: {
        dateRangeText () {
            return this.dates.map(d => moment(d).format("DD.MM.YYYY")).join(' ~ ')
        },
    },
}
</script>

<style scoped>

</style>