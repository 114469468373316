<template>
    <div>
        <v-card>
            <v-card-text v-if="!loading" class="px-1">
                <div>
                    <div class="caption">
                        <template v-if="eAristoQuestionStyleType.singleChoice === question.questionType || eAristoQuestionStyleType.singleChoiceImage === question.questionType">
                            {{$t("eAristoStudent.pleaseSelectOneAnswer")}}
                        </template>

                        <template v-if="eAristoQuestionStyleType.completeTheSentence === question.questionType">
                            {{$t("eAristoStudent.pleaseFillMissedWords")}}
                        </template>
                    </div>
                    <v-card
                        class="mb-4"
                        color="orange lighten-4"
                    >
                        <v-card-text>
                            {{ question.question }}
                        </v-card-text>

                        <div class="mt-4 ml-4" v-if="question.image">
                            <img :width="getQuestionImageSize()" :src="questionImageUrl(question.image)" alt=""/>
                        </div>
                    </v-card>

                    <template v-if="eAristoQuestionStyleType.singleChoice === question.questionType" >
                        <div class="ma-2">
                            <v-card-text
                                v-for="(answer, index) in question.questionAnswers"
                                :key="index"
                                class="answer"
                                :class="{'answer': true, 'selected': answer.answered }"
                                @click="onSelectAnswer(question, answer)"
                            >
                                {{ index + 1 }}. {{ answer.answer }}
                            </v-card-text>
                        </div>
                    </template>

                    <template v-if="eAristoQuestionStyleType.singleChoiceImage === question.questionType">
                        <div class="ma-2">
                            <v-card-text
                                v-for="(answer, index) in question.questionAnswers"
                                :key="index"
                                :class="{'answer': true, 'selected': answer.answered }"
                                @click="onSelectAnswer(question, answer)"
                            >
                                {{ index + 1 }}. {{ answer.answer }}
                                <div class="mt-4" v-if="answer.image">
                                    <img :width="getQuestionAnswerImageSize()" 
                                         :src="questionImageUrl(answer.image)" alt=""/>
                                </div>
                            </v-card-text>
                        </div>
                    </template>

                    <div v-if="eAristoQuestionStyleType.completeTheSentence === question.questionType" class="ma-2">
                        <v-card-text
                            v-for="(answer, index) in question.questionAnswers"
                            :key="index"
                        >
                            <v-text-field
                                xs-8
                                v-model="answer.answer"
                                :label="`${$t('eAristoStudent.answer')} #${index + 1}`"
                                @change="onChangeAnswer(question, answer)"
                            ></v-text-field>
                        </v-card-text>
                        
                    </div>

                    <div v-if="eAristoQuestionStyleType.writeAnswer === question.questionType" class="ma-2">
                        <v-card-text
                            v-for="(answer, index) in question.questionAnswers"
                            :key="index">
                            <v-text-field
                                xs-8
                                v-model="answer.answer"
                                :label="`${$t('eAristoStudent.answer')} #${index + 1}`"
                                @change="onChangeAnswer(question, answer)"
                            ></v-text-field>
                        </v-card-text>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {eAristoQuestionStyleType} from "@/constants/eAristo/eAristoQuestionStyleType";
import config from "@/app.config";
import {AppConfig} from "@/config/configFactory";

const configuration = AppConfig(config);
export default {
    components: {},
    props: {
        question: {
            type: Object,
            default: () => ({
                question: '',
                isAnswered: null,
                questionAnswers: [],
            }),
        }
    },
    methods: {
        onSelectAnswer(question, answer) {
            this.$emit("selectAnswer", {question, answer});
        },
        onChangeAnswer(question, answer) {
            this.$emit("selectAnswer", {question, answer});
        },
        questionImageUrl(image) {
            return `${configuration.apiEndpoint}/file/get/eAristoQuestions/${image}`;
        },

        getQuestionImageSize() {
            if (this.$vuetify.breakpoint.mobile) {
                return '100%';
            }
            else {
                return 450;
            }
        },
        getQuestionAnswerImageSize() {
            if (this.$vuetify.breakpoint.mobile) {
                return '100%';
            }
            else {
                return 400;
            }
        }
    },
    data: () => {
        return {
            loading: false,
            eAristoQuestionStyleType,
        }
    }
}
</script>
<style lang="scss">
.answer {
    &:hover {
        cursor: pointer;
        background: #BBDEFB;
    }
}
.selected {
    background: #1791f8 !important;
}
</style>