<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <v-subheader class="mt-1">
                    <div class="title">Тестування КТІ</div>
                </v-subheader>
                 
                <v-subheader class="mt-1 grey--name name--darken-1"></v-subheader>
                <navigation-list-template :items="stepProfiles"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import HttpService from "../../../HttpService";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        data: () => ({
            isDepartmentSubjectSwitcherOpened: false,
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'turned_in',
                    name: 'Modules',
                    route: {
                        name: 'marksRoot'
                    }
                },
            ],
            stepProfiles: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`stepKrok/getAll`)
                .then(resp => {
                    this.stepProfiles = this.remapItems(resp);
                })
        },
        methods: {
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    item.name = `${item.name}`;

                    item.route = {
                        name: "stepAdminProfileManagement",
                        params: {
                            profile: item.id,
                        }
                    };
                    return item;
                });
            },
        },
    }
</script>