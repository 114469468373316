export const topicMarkEditTypesEnum = {
    rework: 0,
    errorCorrection: 1,
};

export default [
    {
        id: topicMarkEditTypesEnum.rework,
        nameUk: "Відпрацювання",
        nameEn: "Rework",
    },
    {
        id: topicMarkEditTypesEnum.errorCorrection,
        nameUk: "Виправлення",
        nameEn: "Correction",
    }
];