<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />
        <div class="caption mb-3">За замовчуванням (при відсутності фільтрації) відображаються дані за <strong> поточний навчальний рік</strong></div>
        <v-layout v-if="!loading" row wrap justify-center>
            <v-flex xs12>
                <div class="h3">Загальна кількість академічних годин: </div>
                <div><span class="font-weight-thin">- практичні заняття:</span> {{totalPracticalHrs}}</div>
                <div class="mb-3"><span class="font-weight-thin">- лекційні заняття:</span> {{totalLectureHrs}}</div>
                
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Факультет
                            </th>
                            <th class="text-left">
                                Спеціальність
                            </th> 
                            <th class="text-left">
                                Викладач
                            </th>
                            <th class="text-left">
                                Практичні заняття, год
                            </th>
                            <th class="text-left">
                                Лекційні заняття, год
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in data"
                            :key="item.name"
                        >
                            <td>{{ item.facultyName }}</td>
                            <td>{{ item.specialityName }}</td>
                            <td>{{ item.professorName }}</td>
                            <td>{{ item.practicalHrs }}</td>
                            <td>{{ item.lectureHrs }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
        <v-container v-if="loading" fluid fill-height>
            <v-layout align-center justify-center>
                <v-progress-circular
                    :size="150"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-container>
    </v-container>
</template>
<script>
import HttpService from "@/HttpService";
import StatDateRange from "@/components/common/StatDateRange";
import moment from "moment";

export default {
    components: {
        StatDateRange,
    },
    data: () => {
        return {
            loading: false,
            data: [],

            totalPracticalHrs: 0,
            totalLectureHrs: 0,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        clearFilter() {
            this.dates = [];

            this.getData();
        },

        search(dates) {
            this.dates = dates;
            this.getData(this.dates[0], this.dates[1]);
        },

        getData(dateFrom, dateTo) {
            let queryParam = "";
            if (dateFrom && dateTo) {
                queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
            }

            this.loading = true;
            HttpService.getInstance()
                .get(`adminAcademicTime/getDepartmentTeacherTimeAccounting/${this.$route.params.department}?${queryParam}`)
                .then(resp => {
                    this.data = this.remapData(resp.items);
                    this.totalPracticalHrs = resp.totalPracticalHrs;
                    this.totalLectureHrs = resp.totalLectureHrs;
                    
                    this.loading = false;
                });
        },
        
        remapData(data) {
            console.log("data", data);
            const newItems = [];
            
            for (const faculty of data) {
                const item = {
                    facultyName: faculty.faculty.nameUk,
                    specialityName: "~",
                    professorName: "~",
                    practicalHrs: faculty.practicalHrs,
                    lectureHrs: faculty.lectureHrs,
                };

                newItems.push(item);
                for (const teacherDepartmentTimeAccounting of faculty.teacherDepartmentTimeAccountings) {
                    const specialityItem = {
                        facultyName: '~',
                        specialityName: teacherDepartmentTimeAccounting.speciality.nameUk,
                        professorName: "~",
                        practicalHrs: teacherDepartmentTimeAccounting.practicalHrs,
                        lectureHrs: teacherDepartmentTimeAccounting.lectureHrs,
                    };
                    
                    newItems.push(specialityItem);
                    
                    for (const timeAccountingItem of teacherDepartmentTimeAccounting.timeAccountingItems) {
                        const specialityItem = {
                            facultyName: '~',
                            specialityName: '~',
                            professorName: `${timeAccountingItem.professor.surname} ${timeAccountingItem.professor.name.charAt(0)}.${timeAccountingItem.professor.patronymic.charAt(0)}.`,
                            practicalHrs: timeAccountingItem.practicalHrs,
                            lectureHrs: timeAccountingItem.lectureHrs,
                        };

                        newItems.push(specialityItem);

                    }
                }
            }
            
            return newItems;
        }
    },
}
</script>