import {rolesEnum} from "../constants/rolesEnum";

export default (to, from, next) => (router) => {
    if (!router.app.$auth.isAuthenticated()) {

        router.app.$cookie.set('returnUrl', to.fullPath, 7);

        next({name: 'unauthorized'});
    }

    if (!router.app.$auth.userHasAtLeastOneRole([rolesEnum.moderator, rolesEnum.admin, rolesEnum.edudept])) {
        next({name: 'cannot-access'});
    }

    next();
}