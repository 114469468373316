<template>
    <v-card>
        <v-card-title class="title">Перегляд завантаженого зображення</v-card-title>
        <v-card-text>
            <img width="950" alt="attachment" :src="photoUrlFull"/>
   
            <div v-if="userIsDeanOrInspector()">
                <v-text-field
                        class="mt-5"
                        v-model="rejectionText"
                        label="Коментар декана"
                ></v-text-field>
                <v-btn @click="saveRejection()" dark color="red">Забракувати</v-btn>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="item.topicAbsence === null && userIsStudent()" 
                   :disabled="loading"
                   @click="$emit('modifyAttachment', item)">Змінити зображення</v-btn>
            <v-btn
                    @click="$emit('shouldClose')"
                    color="primary"
            >Закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import ImageUploader from "../../common/ImageUploader";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            ImageUploader,
        },
        data: () => ({
            rejectionText: "",
            loading: false,
        }),
        props: {
            item: Object,
        },
        computed: {
            photoUrlFull: {
                get() { return `${this.$config.apiEndpoint}/file/get/reworkAdmission/${this.item.attachmentFileName}`},
                set(val) { }
            }
        },
        methods: {
            userIsStudent() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.student, rolesEnum.admin]);
            },
            userIsDeanOrInspector() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.dean, rolesEnum.inspector, rolesEnum.admin]);
            },
            saveRejection() {
                HttpService.getInstance()
                    .put(`dean/rejectAdmissionDocument/${this.item.id}`, {rejectionText: this.rejectionText})
                    .then(resp => {
                        this.loading = false;
                        this.$emit('shouldCloseAndUpdate');
                    });
            }
        }
    }
</script>