<template>
    <div>
        <div class="headline">Модулі</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item="props">
                <tr>
                    <td class="px-2">
                        <div class="justify-center d-flex">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="$emit('navigateTo', props.item.id, 'eAristoEducationalTopicsPlan')"
                                           v-on="on" icon class="mx-0">
                                        <v-icon color="green">assignment</v-icon>
                                    </v-btn>
                                </template>
                                <span>Перейти до списку тем індивідуальної роботи</span>
                            </v-tooltip>
                        </div>
                    </td>
                    <td class="text-xs-left">
                        <span class="ml-2">{{ props.item.orderBy }}</span>
                    </td>
                    <td class="text-xs-left">{{ props.item.nameUk }}</td>
<!--                    <td class="text-xs-left">{{ props.item.nameEn }}</td>-->
                    <td class="text-xs-left">
                        <span class="ml-2">{{ props.item.topicsEvaluableCount }}</span>
                    </td>
                    <td class="text-xs-left">{{ props.item.topicsAllCount }}</td>
                    <td class="text-xs-left">{{ mapFinalControlType(props.item.finalControlType) }}</td>
                    <td class="text-xs-left">
                        <v-icon v-if="!props.item.isBlocked">radio_button_unchecked</v-icon>
                        <v-icon v-if="props.item.isBlocked">radio_button_checked</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import finalControlTypes from "../../../constants/admin/finalControlTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Навігація', value: 'navigation', sortable: false},
                {text: '№ п/п', value: 'orderBy'},
                {text: 'Назва (укр)', value: 'nameUk'},
                {text: 'Оцінювальні теми', value: 'topicsEvaluableCount'},
                {text: 'Всього тем', value: 'topicsAllCount'},
                {text: 'Тип', value: 'finalControlType'},
                {text: 'Блокування', value: 'isBlocked'}
            ],
        }),
    }
</script>