<template>
    <div>
        <search-bar
                :loading="loading"
                input-label="Логін або ПІП студента..."
                @search="getItemsSearch($event)"
        />
        <v-flex xs12>
            <admin-home-student-grid
                    :items="items"
                    :total="total"
                    :loading="loading"
                    @paginate="onPaginate"
            >
            </admin-home-student-grid>
        </v-flex>
    </div>
</template>
<script>
    import SearchBar from "../common/SearchBar";
    import AdminHomeStudentGrid from "./AdminHomeStudentGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import HttpService from "../../../HttpService";
    import isEmpty from "lodash/isEmpty";

    export default {
        components: {
            SearchBar,
            AdminHomeStudentGrid,
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                searchString: "",
            }
        },
        methods: {
            getItemsSearch(searchString){
                this.searchString = searchString;
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination));
            },
            getItems(queryParams) {
                if (isEmpty(this.searchString)) {
                    return;
                }
                
                this.loading = true;

                HttpService.getInstance()
                    .get(`student/getLookUp?${queryParams}`)
                    .then(students => {
                        this.items = students.items;
                        this.total = students.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
    }
</script>