<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <navigation-drawer-stud-nav
                @toggleDrawer="drawerApp = $event"
                :drawer="drawerApp"
                route-to="marksGroupRoot"
                :additional-route-params="{
                                            department: $route.params.department, 
                                            subject: $route.params.subject}"
        />
        <teacher-toolbar
                :is-lang-switcher="false"
                color="blue darken-4"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        
        <snack-displayer/>
    </v-app>
</template>

<script>
    import TeacherToolbar from "../../components/teacher/common/TeacherToolbar";
    import TeacherNavigationDrawer from "../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import NavigationDrawerStudNav from "../../components/teacher/common/NavigationDrawerStudNav";

    export default {
        components: {
            TeacherToolbar,
            NavigationDrawerStudNav,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
