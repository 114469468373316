<template>
    <div>
        <applicants-fetching/>

        <v-card>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs9>
                            <v-text-field v-model="searchSurname"
                                          label="Пошук за прізвищем, ім'ям, по-батькові"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs3>
                            <v-btn small @click="searchBy()">Пошук</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            
        </v-card>
        <applicants-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @previewPhoto="previewPhoto"
        >
        </applicants-grid>
        
        <v-dialog v-model="dialogPhotoPreview" max-width="650">
            <image-preview :photo-url="photoUrl"></image-preview>
        </v-dialog>
        
        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import ApplicantsFetching from "./ApplicantsFetching";

    import ApplicantsGrid from "./ApplicantsGrid";
    import ImagePreview from "./ImagePreview";
    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    
    export default {
        components: {
            ApplicantsFetching,
            ApplicantsGrid,
            ImagePreview,
            // StudentsGrid,
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                dialogPhotoPreview: false,
                photoUrl: "",
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },

                searchSurname: "",
            }
        },
        mounted() {
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`applicants?${queryParams}`)
                    .then(faculties => {
                        this.items = faculties.items;
                        this.total = faculties.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    let filter = [{field: 'surnameNamePatronymic', value: this.searchSurname, operator: 'contains'}];

                    this.pagination = Object.assign({}, this.pagination, {filter});
                    paginationData = Object.assign({}, paginationData, {filter});
                    
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            previewPhoto(photoUrl) {
                this.dialogPhotoPreview = true;
                this.photoUrl = photoUrl;
            },

            searchBy() {
                let filter = [{field: 'surnameNamePatronymic', value: this.searchSurname, operator: 'contains'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
                
                this.getItems(objectQueryStringify(this.pagination));
            }
        },
    }
</script>