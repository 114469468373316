<template>
    <div>
        <div class="group-btns-wrapper">
            <v-btn v-if="selectedItemsComputed.length" @click="$emit('change')" color="primary">
                Перевести на наступний семестр
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('changeMove')" color="primary">
                Перевести
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('deleteSelected')" color="red lighten-2" dark>
                Видалити виділені
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('deleteSelectedWithStudents')" color="red" dark>
                Видалити виділені РАЗОМ зі студентами
            </v-btn>
        </div>
        <v-data-table
                :hide-default-footer="true"
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                item-key="id"
                class="elevation-3"
                show-select
                v-model="selectedItemsComputed"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                    <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import finalControlTypes from "../../../../constants/admin/finalControlTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            selectedItems: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        computed: {
            selectedItemsComputed: {
                get() { return this.selectedItems},
                set(val) { this.$emit('update:selectedItems', val)}
            }
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
        data: () => ({
            selected: [],
            headers: [
                {text: 'Номер групи ', value: 'groupNumber', sortable: false},
                {text: 'К-сть студентів у групі', value: 'studentsCount', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>
<style lang="scss">
    .group-btns-wrapper {
        margin-bottom: 10px;
        height: 36px;
        
        & .v-btn {
            margin-right: 10px;
        }
    }
</style>