<template>
    <v-card>
        <v-card-title>
            <span class="title font-weight-light">Перегляд логів</span>
        </v-card-title>
        <v-card-text>
            <v-container grid-list-md>
                <v-layout v-if="!loading" wrap>
                    <v-flex xs12>
                        <v-layout wrap>
                            <v-flex xs12 class="preview-block" :style="`background: ${getCellColor(item.level)}`">
                                {{item.level}}</v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex xs4 class="title font-weight-light">Шаблон</v-flex>
                            <v-flex xs8 class="word-break-logs">{{item.messageTemplate}}</v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex xs4 class="title font-weight-light">Повідомлення</v-flex>
                            <v-flex xs8 class="word-break-logs"><code class="word-break-logs">{{item.renderedMessage}}</code></v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex xs4 class="title font-weight-light">Виключення</v-flex>
                            <v-flex xs8 class="word-break-logs"><code class="word-break-logs">{{item.properties}}</code></v-flex>
                        </v-layout>
                        <v-layout wrap>
                            <v-flex xs4 class="title font-weight-light">Дата</v-flex>
                            <v-flex xs8 class="word-break-logs">{{formatAuditLogDate(item.timestamp)}}</v-flex>
                        </v-layout>
                       
                    </v-flex>
                </v-layout>
                <loading-placeholder v-else></loading-placeholder>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
            >Закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {formatAuditLogDate} from "../../../../helpers/dateHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import logLevels from "../../../../constants/logLevels";

    export default {
        props: {
            item: Object,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => ({
            loading: false,
            formatAuditLogDate,
        }),
        methods: {
            getCellColor(level) {
                const foundItem = logLevels.find(l => l.id === level);

                if (foundItem) {
                    return foundItem.color;
                }

                return "";
            }
        }
    }
</script>

<style>
    .word-break-logs {
        word-break: break-all;
    }
    .preview-block {
        width: 100px;
        height: 30px;
        color: white;
        margin-right: 10px;
        padding: 5px;
    }
</style>