<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <scientific-degrees-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import ScientificDegreesIndex from "../../../../components/admin/Dictionaries/ScientificDegrees/ScientificDegreesIndex";
    export default  {
        components: {
            ScientificDegreesIndex,
        }
    }
</script>