<template>
    <div>
        <modules-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @navigateTo="navigateTo"
        >
        </modules-grid>

        <v-dialog v-model="dialog" max-width="850px" scrollable>
            <add-edit-module
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
                    :subject-final-control-type="subject.finalControlType"
                    :max-order-by="maxOrderBy"
            ></add-edit-module>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import ModulesGrid from "./ModulesGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AddEditModule from "./AddEditModule";
    import DeleteConfirm from "./DeleteConfirm";
    import maxBy from "lodash/maxBy";

    export default {
        components: {
            ModulesGrid,
            AddEditModule,
            DeleteConfirm
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                subject: {
                    finalControlType: null,
                },
                maxOrderBy: 0,
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`subjects/${this.$route.params.subject}`)  
              .then(subject => {
                  this.subject = subject;
              });
        },
        methods: {
            navigateTo(moduleId, routeName) {  // topicsPlan || topicsPlan
                this.$router.push({
                    name: routeName, 
                    params: {
                        subject: this.$route.params.subject, 
                        department: this.$route.params.department
                    }
                }).catch(err => {});  
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`module/${this.$route.params.department}/${this.$route.params.subject}?${queryParams}`)
                    .then(modules => {
                        this.items = modules.items;
                        this.total = modules.total;
                        
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;

                const module = maxBy(this.items, 'orderBy');

                if (module) {
                    this.maxOrderBy = module.orderBy;
                }
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
    }
</script>