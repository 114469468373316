<template>
    <v-card>
        <v-card-title style="display: block;">
            <div class="headline">
                Історія редагувань оцінок
            </div>
            <div class="subtitle-1" style="display: inline-flex">
                за тему "<div style="max-width: 350px;" class="text-truncate">{{topicInfo.nameUk}}</div>"
                ({{formatShortDate(topicInfo.topicClass.classDate)}})
            </div>
        </v-card-title>
        <v-card-text>
            
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
            >Закрити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import MarkSelector from "./MarkSelector";
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        props: {
            activeModule: {
                type: Object
            },
            selectedTopics: {
                type: Array,
            },
            topicInfo: {
                type: Object,
                default: () => {
                    return {
                        topicClass: null,
                    };
                }
            },
        },
        components: {
            LoadingPlaceholder,
            MarkSelector
        },
        data: () => {
            return {
                activeStep: 1,
                loading: false,
                topicsMarkValues: null,

                errors: {},
                formatShortDate,
                infoTopicClass: {
                    topicsMarkValues: [],
                    classDate: null,
                }
            }
        },
        mounted() {
            this.errors = {};
            
            // HttpService.getInstance()
            //     .get(`teacher/teacherMarks/getMarksForEdit/${this.topicInfo.topicClass.id}`)
            //     .then(res => {
            //         this.infoTopicClass = res;
            //     });
        },
    }
</script>