<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'currentDepartmentRating', params: {department: $route.params.department}}"
                           color="primary"
                           block
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> Перейти до напрямків</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Напрямки рейтингування</v-subheader>
                            <navigation-list-template :items="ratingDirections"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.direction" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть напрямок
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <rating-user-worktypes-index :key="$route.fullPath" 
                                             v-if="$route.params.direction"
                                             :is-department-mode="true"
                                             :show-professor="true"
                                             :show-joined="true"
                                             :could-edit="!totalRating.isConfirmed"
                                             :items-api-endpoint="`ratingUser/getWorkTypesDepartmentWithSum/${$route.params.direction}/${$route.params.department}`"
                                             :summary-api-endpoint="`ratingUser/getDirectionDepartmentSum/${$route.params.direction}/${$route.params.department}`"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";
    import RatingUserWorktypesIndex from "../../../components/rating/ratingUserWorktypes/RatingUserWorktypesIndex";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            RatingUserWorktypesIndex
        },
        data: () => ({
            ratingDirections: [],
            totalRating: {
                isConfirmed: null,
                confirmationDate: null,
            },
        }),
        mounted() {
            this.getRatingSummary();
            
            HttpService.getInstance()
                .get(`ratingDirections/getAll`)
                .then(resp => {
                    this.ratingDirections = resp.map(direction => {
                        direction.name = `${direction.order}. ${direction.directionName}`;
                        direction.route = {
                            name: 'headDepartmentDepartmentRating',
                            params: {
                                direction: direction.id,
                                department: this.$route.params.department,
                            }
                        };

                        return direction;
                    });
                });
        },
        methods: {
            getRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDepartmentRatingSummary/${this.$route.params.department}`)
                    .then(totalRating => {
                        this.totalRating = totalRating;

                        this.loading = false;
                    });
            },
            showDirectionsBlock() {
                return this.$route.name === "headDepartmentDepartmentRating" && this.$route.params.direction;
            },

        },
    }
</script>