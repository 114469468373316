<template>
    <div>
        <div class="headline ml-2 mt-2">Вибір кафедри</div>
        <v-layout justify-center>
            <v-flex xs12>
                <v-progress-circular
                        v-if="loading"
                        :size="100"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-flex>
        </v-layout>

        <v-simple-table v-if="!loading">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Дії
                    </th>
                    <th class="text-left">
                        Кафедра
                    </th>
                    <template v-if="departmentInfo.length > 0">
                        <th
                                v-for="ratingDirectionsInfo in departmentInfo[0].ratingDirectionsInfo"
                                class="text-left">
                            {{ratingDirectionsInfo.order}}. {{ratingDirectionsInfo.directionName}}
                        </th>
                    </template>
                    <th class="text-left">
                        ВСЬОГО
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="department in departmentInfo"
                >
                    <td>
                        <v-btn icon
                               color="primary"
                               :to="{ name: 'ratingCheckDirections', params: { department: department.id }}">
                            <v-icon>send</v-icon>
                        </v-btn>
                    </td>
                    <td>{{department.nameUk}}</td>
                    <td width="15%" v-for="ratingDirection in department.ratingDirectionsInfo">
                        <div class="my-2">
                            <div>
                                <span class="green--text subtitle-1">{{ratingDirection.approved}}</span> /
                                <span class="red--text subtitle-1">{{ratingDirection.rejected}}</span> /
                                <span class="font-weight-bold subtitle-1">{{ratingDirection.total}}</span></div>
                            <b-progress class="mt-2" :max="ratingDirection.total" show-value>
                                <b-progress-bar
                                        :value="ratingDirection.total - ratingDirection.approved - ratingDirection.rejected"
                                        variant="warning"></b-progress-bar>
                                <b-progress-bar :value="ratingDirection.approved" variant="success"></b-progress-bar>
                                <b-progress-bar :value="ratingDirection.rejected" variant="danger"></b-progress-bar>
                            </b-progress>
                        </div>
                    </td>
                    <td>
                        <div class="font-weight-bold">{{department.total}}</div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                departmentInfo: [],
                loading: false,
            };
        },
        mounted() {
            this.loading = true;
            HttpService.getInstance()
                .get("ratingAdmin/getCheckDepartments")
                .then(items => {
                    this.loading = false;
                    this.departmentInfo = items;
                });
        }
    }
</script>