<template>
    <div>
        <h2 class="text-center mb-3">{{ topic ? `${topic.orderNumber}. ${topic.nameUk}` : "" }}</h2>

        <v-layout v-if="isTestPassed" align-center justify-center>
            <v-icon color="green" x-large>check_circle</v-icon>  <h3>{{$t("eAristoStudent.topicAndQuizPassed")}}</h3>
        </v-layout>
        
        <v-layout align-center justify-center v-if="testList.length">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            {{$t("eAristoStudent.quizStart")}}
                        </th>
                        <th class="text-left">
                            {{$t("eAristoStudent.quizEnd")}}
                        </th>
                        <th class="text-left">
                            {{$t("eAristoStudent.quizResult")}}
                        </th>
                        <th class="text-left">
                            {{$t("eAristoStudent.quizFinished")}}
                        </th>
                        <th class="text-left">
                            {{$t("eAristoStudent.quizCounted")}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="test in testList"
                        :key="test.id"
                    >
                        <td>{{ formatLongDate(test.quizStart) }}</td>
                        <td>{{ formatLongDate(test.quizEnd) }}</td>
                        <td>{{ test.quizResult }}</td>
                        <td>
                            <v-icon v-if="test.isFinished" color="green">check_circle</v-icon>
                            <v-icon v-else color="gray">cancel</v-icon>
                        </td>
                        <td>
                            <v-icon v-if="test.isPassed" color="green">check_circle</v-icon>
                            <v-icon v-else color="gray">cancel</v-icon>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-layout>

        <v-layout v-if="!loading && !loadingFinishQuiz && !isTestPassed">
            <!--        <div class="mb-4">-->
            <!--            <v-btn @click="dialogFeedback = true" color="primary" outlined rounded>Оцінити матеріал анонімно</v-btn>-->
            <!--        </div>-->

            <v-expansion-panels v-if="quiz">
                <v-expansion-panel :key="1" v-if="quiz.isVideo">
                    <v-expansion-panel-header>
                        <div class="d-flex justify-start align-center">
                            <v-icon v-if="isVideoFinished" color="green" class="mr-2">check_circle</v-icon>  <h4>{{$t("eAristoStudent.quizInteractiveVideo")}}</h4>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div>
                            <video-student-content :video-content="videoContentItem"
                                                   :loading="loadingVideo"
                                                   :questions-video="quiz.questionsVideo"
                                                   :quiz-attempt-id="quiz.id"
                                                   :questions-video-segments="questionsVideoMarkersSegments"
                                                   @submittedAnswers="onSubmittedAnswers()"
                            ></video-student-content>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel :key="2" v-if="quiz.isPresentation">
                    <v-expansion-panel-header>
                        <div class="d-flex justify-start align-center">
                            <v-icon v-if="isPresentationFinished" color="green" class="mr-2">check_circle</v-icon>  <h4>{{$t("eAristoStudent.quizInteractivePresentation")}}</h4>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div>
                            <presentation-student-content
                                :slides-content="slidesContent"
                                :loading="loadingSlides"
                                :questions-presentation="quiz.questionsPresentation"
                                :quiz-attempt-id="quiz.id"
                                :questions-presentation-segments="questionsPresentationSegments"
                                @submittedAnswers="onSubmittedAnswers()"
                            ></presentation-student-content>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel :key="3" v-if="quiz.isAll">
                    <v-expansion-panel-header>
                        <h4>{{$t("eAristoStudent.quizTopicAll")}}</h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <overall-topic-content
                            v-if="(!quiz.isPresentation || (quiz.isPresentation && isPresentationFinished)) && 
                                    (!quiz.isVideo || (quiz.isVideo && isVideoFinished))"
                            :questions-overall="quiz.questionsAll"
                            :quiz-attempt-id="quiz.id"

                            :questions-overall-result="quiz.questionsOverallResult"
                            :questions-overall-result-passed="quiz.questionsOverallResultPassed"
                            @submittedAnswers="onSubmittedAnswers()"
                        ></overall-topic-content>
                        
                        <v-alert v-else type="warning">
                            {{$t("eAristoStudent.thisActivity")}}
                            <div v-if="quiz.isPresentation && !isPresentationFinished">- {{$t("eAristoStudent.quizInteractivePresentation")}}</div>
                            <div v-if="quiz.isVideo && !isVideoFinished">- {{$t("eAristoStudent.quizInteractiveVideo")}}</div>
                        </v-alert>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-layout>

        <v-layout v-if="!loading" align-center justify-center>
            <div v-if="!quiz && !isTestPassed" class="d-flex justify-space-between align-center mb-4">
                <v-btn @click="startQuiz()" :loading="loadingStartQuiz" color="primary" large>{{$t("eAristoStudent.quizStartQuiz")}}</v-btn>
            </div>
    
            <div v-if="couldFinishTopicQuiz && !isTestPassed" class="d-flex d-flex-column justify-space-between align-center mb-4 mt-6">
                <v-btn @click="finishQuiz()" :loading="loadingFinishQuiz" color="primary" large>{{$t("eAristoStudent.quizFinishQuiz")}}</v-btn>
            </div>
        </v-layout>

        <v-layout v-if="loading" align-center justify-center>
            <v-progress-circular size="125" color="primary" indeterminate></v-progress-circular>
        </v-layout>

        <v-dialog v-model="dialogFeedback" max-width="700">
            <feedback-form
                :topic-id="1"
                @shouldCloseForm="dialogFeedback = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import FeedbackForm from "@/components/eAristoStudent/topics/FeedbackForm";
import VideoContent from "@/components/eAristoProfessor/topicContent/videoContent/VideoContent.vue";
import PresentationStudentContent
    from "@/components/eAristoStudent/topicContent/presentationContent/PresentationStudentContent.vue";
import HttpService from "@/HttpService";
import VideoStudentContent from "@/components/eAristoStudent/topicContent/videoContent/VideoStudentContent.vue";
import every from "lodash/every";
import store from "@/store";
import {formatLongDate} from "@/helpers/dateHelper";
import OverallTopicContent from "@/components/eAristoStudent/topicContent/overallTopicContent/OverallTopicContent.vue";

export default {
    components: {
        VideoStudentContent,
        PresentationStudentContent,
        OverallTopicContent,
        VideoContent,
        FeedbackForm,
    },
    data: () => {
        return {
            formatLongDate,
            
            dialogFeedback: false,

            panel: 0,
            loading: false,
            loadingVideo: false,
            loadingSlides: false,
            videoContentItem: null,
            slidesContent: [],
            topic: null,

            testList: [],

            isTestPassed: null,
            
            quiz: null,

            loadingStartQuiz: false,
            loadingFinishQuiz: false,
            
            questionsPresentationSegments: [],
            questionsVideoMarkersSegments: [],
            
            isPresentationFinished: false,
            isVideoFinished: false,
            
            couldFinishTopicQuiz: false,
        };
    },
    mounted() {
        this.loading = true;

        HttpService.getInstance()
            .get(`eAristoStudent/topic/${this.$route.params.topic}`)
            .then(topic => {
                this.topic = topic;
            })
        
        this.getTestList();

        this.getIsTestPassed();
        
        this.getQuiz();
    },
    methods: {
        getTestList() {
            HttpService.getInstance()
                .get(`eAristoStudent/list/${this.$route.params.topic}`)
                .then(testList => {
                    this.testList = testList;
                })
        },
        getIsTestPassed() {
            HttpService.getInstance()
                .get(`eAristoStudent/isTestPassed/${this.$route.params.topic}`)
                .then(isTestPassed => {
                    this.isTestPassed = isTestPassed;
                })  
        },
        getQuiz() {
            this.loading = true;
            
            HttpService.getInstance()
                .get(`eAristoStudent/getQuiz/${this.$route.params.topic}`)
                .then(quiz => {
                    this.quiz = quiz;

                    this.questionsPresentationSegments = quiz.questionsPresentationSegments;
                    this.questionsVideoMarkersSegments = quiz.questionsVideoSegments;
                    
                    this.panel = 0;
                    

                    this.checkCouldFinishQuiz(this.questionsPresentationSegments, this.questionsVideoMarkersSegments, this.quiz);

                    if (quiz && !quiz.isFinished) {
                        this.getVideo();
                        this.getSlides();
                    }

                    this.loading = false;
                })
        },
        startQuiz() {
            this.loadingStartQuiz = true;
            HttpService.getInstance()
                .post(`eAristoStudent/startQuiz/${this.$route.params.topic}`)
                .then(quiz => {
                    this.quiz = quiz;

                    this.questionsPresentationSegments = quiz.questionsPresentationSegments;
                    this.questionsVideoMarkersSegments = quiz.questionsVideoSegments;

                    this.loadingStartQuiz = false;

                    this.panel = 0;

                    this.getVideo();
                    this.getSlides();
                })
                .catch(e => {
                    this.loadingStartQuiz = false;
                })
        },

        getVideo() {
            this.loadingVideo = true;
            HttpService.getInstance()
                .get(`eAristoStudent/videoContent/${this.$route.params.topic}`)
                .then(videoContentItem => {
                    this.loadingVideo = false;
                    this.videoContentItem = videoContentItem ? videoContentItem : null;
                })
        },

        getSlides() {
            this.loadingSlides = true;
            HttpService.getInstance()
                .get(`eAristoStudent/slidesContent/${this.$route.params.topic}`)
                .then(slidesContent => {
                    this.loadingSlides = false;
                    this.slidesContent = slidesContent;
                })
        },

        onSubmittedAnswers() {
            HttpService.getInstance()
                .get(`eAristoStudent/getQuiz/${this.$route.params.topic}`)
                .then(quiz => {
                    this.quiz = quiz;

                    this.questionsPresentationSegments = quiz.questionsPresentationSegments;
                    this.questionsVideoMarkersSegments = quiz.questionsVideoSegments;
                    
                    this.checkCouldFinishQuiz(this.questionsPresentationSegments, this.questionsVideoMarkersSegments, this.quiz);
                })
        },
        checkCouldFinishQuiz(presentationSegments, videoSegments, quiz) {
            if (!quiz) {
                this.couldFinishTopicQuiz = false;
                
                return;
            }
            
           const presentationAnsweredCorrect = every(presentationSegments, { answeredCorrect: true });
           const videoSegmentsAnsweredCorrect = every(videoSegments, { answeredCorrect: true });
           
           if (this.quiz.isPresentation && this.isPresentationFinished === false && presentationAnsweredCorrect === true) {
               store.commit("snackMessages/set", {message: "Ви успішно завершили секцію інтерактивної презентації", color: 'info'});
           }

            if (this.quiz.isVideo && this.isVideoFinished === false && videoSegmentsAnsweredCorrect === true) {
                store.commit("snackMessages/set", {message: "Ви успішно завершили секцію інтерактивного відео", color: 'info'});
            }
           
           this.isPresentationFinished = presentationAnsweredCorrect;
           this.isVideoFinished = videoSegmentsAnsweredCorrect;
           
           console.log("presentationAnsweredCorrect", presentationAnsweredCorrect);
           console.log("videoSegmentsAnsweredCorrect", videoSegmentsAnsweredCorrect);
           
           this.couldFinishTopicQuiz = presentationAnsweredCorrect && videoSegmentsAnsweredCorrect && (!quiz.isAll || (quiz.isAll && quiz.questionsOverallResult != null));
        },
        
        finishQuiz() {
            this.loadingFinishQuiz = true;
            HttpService.getInstance({}, {}, true)
                .post(`eAristoStudent/finishQuiz/${this.$route.params.topic}`)
                .then(resp => {
                    this.loadingFinishQuiz = false;
                    this.couldFinishTopicQuiz = false;
                    
                    this.getIsTestPassed();
                    this.getTestList();
                    this.getQuiz();
                    
                    if (resp.isPassed) {
                        store.commit("snackMessages/set", {message: "Ви успішно завершили дану тему!", color: 'success'});
                    }
                    else {
                        store.commit("snackMessages/set", {message: "На жаль, Ви не змогли продемонструвати здобуті знання на необхідному рівні... Спробуйте іще раз!", color: 'warning'});
                    }
                });
        }
    }
}
</script>