<template>
    <div>
        <subject-pool-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </subject-pool-grid>

        <v-dialog v-model="dialog" max-width="700px">
            <add-edit-subject-pool
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-subject-pool>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>
    </div>
</template>
<script>
    import SubjectPoolGrid from "./SubjectPoolGrid";
    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditSubjectPool from "./AddEditSubjectPool";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            SubjectPoolGrid,
            AddEditSubjectPool,
            DeleteConfirm
        },
        methods: {
            getItems(queryParams, subjectId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`modulepool/grid/${subjectId}?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.subject);
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.subject);
            },
        },
        watch: {
            '$route.params.subject': function (newSubjectId) {
                if (newSubjectId !== undefined) {
                    this.pagination = Object.assign({}, this.pagination);

                    this.getItems(objectQueryStringify(this.pagination), newSubjectId);
                }
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>