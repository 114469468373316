<template>
    <v-card>
        <v-card-title class="title">Генерація логінів та паролів</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете згенерувати 
            логіни та паролі для даного <span class="font-weight-bold">курсу</span>?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="red"
                    text
                    large
                    @click.native="generateAccounts()"
                    :loading="loading"
                    :disabled="loading"
            >Згенерувати
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
            };
        },
        props: {
            deleteId: Number,
        },
        methods: {
            generateAccounts() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`studentAccounts/${this.$route.params.faculty}/${this.$route.params.speciality}/${this.$route.params.semester}`)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("generated");
                    });
            }
        }
    }
</script>