<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <statistics-marks-by-faculty-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import StatisticsMarksByFacultyIndex
        from "../../../components/admin/StatisticsMarks/StatisticsMarksByFaculty/StatisticsMarksByFacultyIndex";

    export default  {
        components: {
            StatisticsMarksByFacultyIndex
        }
    }
</script>