<template>
    <div>
        <v-menu
                v-if="topicAbsence != null"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="350"
                offset-x
                left
                offset-overflow
                transition="slide-x-transition"
                :max-width="350"
        >
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <template v-if="topicAbsence.isValid">
                        <admission-info :topic-absence="topicAbsence"/>
                    </template>
                    <template v-if="!topicAbsence.isValid">
                        <v-btn text icon color="red">
                            <v-icon large>local_activity</v-icon>
                        </v-btn>
                    </template>
                    <template v-if="topicAbsence === null">-</template>
                </span>
            </template>

            <v-card>
                <v-card-text>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>Тип випуску допуску:</v-flex>
                            <v-flex xs6 class="text-center">
                                {{topicAbsence != null
                                ? getTopicMarkAdmissionEmissionTypeText(topicAbsence)
                                : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>Тип допуску:</v-flex>
                            <v-flex xs6 class="text-center font-weight-medium">
                                {{topicAbsence != null ? getTopicMarkAdmissionTypeText(topicAbsence) : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>Дата видачі:</v-flex>
                            <v-flex xs6 class="text-center">
                                {{topicAbsence != null && topicAbsence.admissionTime !== null ? formatShortDate(topicAbsence.admissionTime) : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>Початок дії допуску:</v-flex>
                            <v-flex xs6 class="text-center">
                                {{topicAbsence != null && topicAbsence.admissionBegin !== null ? formatLongDate(topicAbsence.admissionBegin) : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right red--text" xs6>Завершення дії допуску:</v-flex>
                            <v-flex xs6 :class="`text-center font-weight-bold ${getMarkAdmissionIsEnding(topicAbsence) ? 'red--text' : ''}`">
                                {{topicAbsence != null && topicAbsence.admissionEnd !== null ? formatLongDate(topicAbsence.admissionEnd) : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                   
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>Кількість пролонгацій:</v-flex>
                            <v-flex xs6 class="text-center">
                                {{topicAbsence != null && topicAbsence.prolongationCount !== null ? topicAbsence.prolongationCount : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="info-block">
                        <v-layout class="body-1 font-weight-light">
                            <v-flex class="text-xs-right" xs6>UID:</v-flex>
                            <v-flex xs6 class="text-center">
                                {{topicAbsence != null && topicAbsence.uniqueId !== null ? topicAbsence.uniqueId : "-"}}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 v-if="!topicAbsence.isValid" class="info-block">
                        <div class="font-weight-bold red--text text-center">Не валідний!</div>
                    </v-flex>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
    import {formatLongDate, formatShortDate} from "../../../helpers/dateHelper";
    import AdmissionInfo from "./AdmissionInfo";
    import {
        getTopicMarkAdmissionTypeText,
        getTopicMarkAdmissionEmissionTypeText,
        getMarkAdmissionIsEnding
    } from "../../../helpers/topicAbsenceHelper";

    export default {
        components: {
            AdmissionInfo  
        },
        data: () => ({
            menu: false,
            formatLongDate,
            formatShortDate,
            getTopicMarkAdmissionTypeText,
            getTopicMarkAdmissionEmissionTypeText,
            getMarkAdmissionIsEnding
        }),
        props: {
            topicAbsence: Object,
        },
    }
</script>
<style lang="scss">
    .info-block {
        border-bottom: 1px solid #ccc;
        padding: 5px;
        
        &:last-child {
            border-bottom: none;
        }
    }
</style>