<template>
    <div>
        <question-pools-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @search="onSearch"
                @editItem="editItem"
                @deleteItem="deleteItem"
        >
        </question-pools-grid>

        <v-dialog v-model="dialog" max-width="700px">
            <add-edit-question-pool
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="false"
                    :edit-id="editId"
            ></add-edit-question-pool>
        </v-dialog>

        <v-dialog v-model="editDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <edit-question-pool
                    v-if="editDialog"
                    @shouldCloseAddEdit="editDialog = false"
                    @edited="addedEdited"
                    :edit-item="editItemSel"
            ></edit-question-pool>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import QuestionPoolsGrid from "./QuestionPoolsGrid";
    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";
    import AddEditQuestionPool from "./AddEditQuestionPool";
    import EditQuestionPool from "./EditQuestionPool";
    import DeleteConfirm from "./DeleteConfirm";

    export default {
        components: {
            QuestionPoolsGrid,
            AddEditQuestionPool,
            EditQuestionPool,
            DeleteConfirm
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`questionPool?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination));
                }
            },
            onSearch(searchStr) {
                this.pagination.filter = [{
                    field: 'name',
                    value: searchStr,
                    operator: 'contains',
                }];

                this.getItems(objectQueryStringify(this.pagination));
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(item) {
                this.editDialog = true;
                this.editItemSel = item;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialog: false,
                editDialog: false,
                dialogRemove: false,
                editItemSel: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>