<template>
    <div>
        <vue-headful title="ePlato"/>
        <student-exam-detail :exam-id="+$route.params.examId" :is-set-started="true"></student-exam-detail>
    </div>
</template>

<script>
    import StudentExamDetail from "../../components/finalExam/teacherDashboard/StudentExamDetail";
    
    export default {
        components: {
            StudentExamDetail,
        },
        data: () => ({
        }),
        
    }
</script>