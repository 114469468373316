<template>
    <div>
        <span
            class="headline">{{ editMode ? 'Редагування тесту з однією правильною відповіддю  (зображення)' : 'Додавання тесту з однією правильною відповіддю (зображення)' }}</span>
        <v-container grid-list-md>
            <v-layout v-if="!loading" wrap>
                <v-flex xs12>
                    <image-uploader
                        v-model="form.image"
                        :type="fileUploadEnum.eAristoQuestions"
                        :image-endpoint="`${$config.apiEndpoint}/file/get`"
                    />
                </v-flex>
                <v-flex xs12 md12>
                    <v-textarea
                        :rows="5"
                        solo
                        label="Питання"
                        v-model="form.question"
                        :error-messages="errors.question"
                        autofocus
                    ></v-textarea>
                </v-flex>

                <v-flex class="mt-3" :key="index" xs12 wrap v-for="(answer, index) in form.questionAnswers">
                    <v-layout align-center>
                        <v-checkbox
                            v-model="answer.isCorrect"
                            xs-2
                            hide-details
                            class="shrink mr-2"
                            :disabled="isAnswerDisabled(answer.isCorrect)"
                        ></v-checkbox>
                        <v-text-field
                            xs-8
                            v-model="answer.answer"
                            autofocus
                            :label="`Відповідь #${index + 1}`"
                        ></v-text-field>
                        <v-btn
                            v-if="index !== 0"
                            xs-2
                            color="red"
                            dark
                            text
                            class="ml-2"
                            @click="removeAnswer(index)"
                        >
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-layout>
                    <image-uploader
                        v-model="answer.image"
                        :type="fileUploadEnum.eAristoQuestions"
                        :image-endpoint="`${$config.apiEndpoint}/file/get`"
                    />
                    <v-divider class="dashed-divider mt-8" light></v-divider>
                </v-flex>

                <v-flex xs12>
                    <div class="red--text" v-if="errors.questionAnswers">
                        {{ errors.questionAnswers }}
                    </div>
                </v-flex>


                <v-btn v-if="form.questionAnswers.length <= 5" @click="addAnswer()" color="primary">
                    <v-icon>add</v-icon>
                    Додати відповідь
                </v-btn>
            </v-layout>

            <loading-placeholder v-else></loading-placeholder>
        </v-container>
    </div>
</template>

<script>
import HttpService from "@/HttpService";
import {remapErrors} from "@/helpers/errorsHelper";
import LoadingPlaceholder from "@/components/common/LoadingPlaceholder";
import ImageUploader from "@/components/common/ImageUploader.vue";
import fileUploadEnum from "@/constants/_internal/fileUploadEnum";

export default {
    props: {
        editId: Number,
        editMode: Boolean,

        educationMaterialVideoId: {
            type: Number,
            default: null,
        },
        educationMaterialPresentationId: {
            type: Number,
            default: null,
        },
        currentVideoTime: {
            type: Number,
            default: null,
        },
    },
    components: {
        loadingPlaceholder: LoadingPlaceholder,
        ImageUploader,
    },
    data: () => {
        return {
            form: {
                id: 0,
                image: null,
                question: '',
                questionAnswers: [{}]
            },
            errors: {
                question: [],
            },
            loading: false,
            loadingSave: false,

            fileUploadEnum,
        }
    },
    mounted() {
        if (this.editMode) {
            this.loading = true;

            HttpService.getInstance()
                .get(`eAristoProfessor/questions/${this.editId}`)
                .then(resp => {
                    this.loading = false;
                    this.form = resp;
                })
        }
    },
    methods: {
        saveForm() {
            let payload = {
                id: this.form.id,
                image: this.form.image,
                question: this.form.question,
                questionAnswers: this.form.questionAnswers.map((questionAnswer, index) => {
                    return {
                        id: questionAnswer.id ?? 0,
                        answer: questionAnswer.answer,
                        image: questionAnswer.image,
                        isCorrect: questionAnswer.isCorrect,
                        orderNumber: index,
                    }
                }),
                ...{subjectId: this.$route.params.subject},
                ...{moduleId: this.$route.params.module},
                ...{topicId: this.$route.params.topic},
                
            }
            
            if (!this.editMode) {
                payload = {
                    ...payload,
                    ...{educationMaterialVideoId: this.educationMaterialVideoId},
                    ...{educationMaterialPresentationId: this.educationMaterialPresentationId},
                    ...{currentVideoTime: Math.round(this.currentVideoTime)}
                }
            }

            this.loadingSave = true;
            this.$emit("loadingSave", true);
            
            HttpService.getInstance()
                .post("eAristoProfessor/singleCorrectImageQuestion", payload)
                .then(resp => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    this.$emit("addedEdited");
                })
                .catch(err => {
                    this.loadingSave = false;
                    this.$emit("loadingSave", false);
                    if (err.errors) {
                        this.errors = remapErrors(err.errors);
                    }
                });
        },
        removeAnswer(index) {
            this.form.questionAnswers.splice(index, 1);
        },
        addAnswer() {
            if (this.form.questionAnswers.length <= 5) {
                this.form.questionAnswers.push({});
            }
        },
        anyRightAnswer() {
            const foundItem = this.form.questionAnswers.find(s => s.isRight);

            return foundItem !== undefined;
        },

        isAnswerDisabled(isRightChecked) {
            return !isRightChecked && this.anyRightAnswer();
        },
    }
}
</script>
<style lang="scss">
.dashed-divider {
    border-style: dashed !important;
    border-color: rgba(0,0,0,.72) !important;
}
</style>