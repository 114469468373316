<template>
    <v-card>
        <v-card-title>
            <span class="headline">Друк денного протоколу</span>
        </v-card-title>
        <v-card-text>
            <iframe v-show="!loading" :src="getPdfLink()" @load="iframeLoaded()" style="width: 100%; height: 700px; border: none;"></iframe>
            <v-layout class="d-flex justify-center" v-if="loading">
                <v-progress-circular
                        :size="150"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    @click="$emit('close')"
                    color="primary"
                    text
            >Відміна
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import moment from "moment";
    
    export default {
        data: () => ({
            loading: true,
        }),
        props: {
            facultyId: {
                type: Number,
            },
            specialityId: {
                type: Number,
            },
            finalExamNameId: {
                type: Number,
            },
            finalExamDate: {
                type: String,
            }
        },
        methods: {
            getPdfLink() {
                return `${this.$config.apiEndpoint}/FinalExamPdf/getPrintDailyStudents/${this.facultyId}/${this.specialityId}/${this.finalExamNameId}?finalExamDate=${moment(this.finalExamDate, "DD.MM.YYYY").format("DD-MM-YYYY")}`;
            },
            iframeLoaded() {
                this.loading = false;
            }
        }
    }
</script>