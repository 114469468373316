<template>
    <v-layout wrap>
        <v-flex xs12>
            <v-card min-height="150">
                <v-card-text>
                    <div class="title mb-2">Завантаження першокурсників</div>
                    <div class="mb-2">Якщо існують прізвища, ім'я або по-батькові які складаються <br> 
                        з <strong>двох окремих слів без риски</strong> 
                        (тобто розділені тільки пробілом), то необхідно <strong>замість пробілу</strong><br> 
                        внести нижнє підкреслення "_" в якості розділового знаку.</div>
                    <div class="d-flex align-center">
                        <v-file-input
                                v-model="fileCodes"
                                chips
                                show-size
                                accept="text/csv"
                                label="CSV файл з першокурсниками"></v-file-input>
                        <v-btn :disabled="!fileCodes || loading"
                               :loading="loading"
                               class="ml-6"
                               color="primary" @click="uploadFile()">Завантажити CSV
                        </v-btn>
                    </div>

                    <v-alert v-if="resp.length > 0" type="error">
                        <div v-for="stud in resp">{{stud.surname}} {{stud.name}}</div>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";

    export default {
        components: {
            // StudentsGrid,
        },
        data: () => {
            return {
                fileCodes: null,
                resp: [],
                loading: false,
            }
        },
        mounted() {
        },
        methods: {
            uploadFile() {
                const formData = new FormData();
                formData.append("file", this.fileCodes);

                let acessToken = localStorage.getItem('access_token');

                this.loading = true;

                HttpService.getInstance(
                    {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${acessToken}`
                    }
                )
                    .post("applicantsImport/import", formData)
                    .then(resp => {
                        this.resp = resp;

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
        },
    }
</script>