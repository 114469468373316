import FinalExamRootView from "../views/finalExam/FinalExamRootView";

import FinalExamStudentView from "../views/finalExam/FinalExamStudentView";
import FinalExamResultStudentView from "../views/finalExam/FinalExamResultStudentView";
import FinalExamTeacherStudListView from "../views/finalExam/FinalExamTeacherStudListView";
import FinalExamTeacherStudDetailView from "../views/finalExam/FinalExamTeacherStudDetailView";
import FinalExamTeacherSettings from "../views/finalExam/FinalExamTeacherSettings";
import AdminFinalExamDateView from "../views/finalExam/AdminFinalExamDateView";
import AdminTeacherListView from "../views/finalExam/AdminTeacherListView";
import AdminFinalExamSettingsDate from "../views/finalExam/AdminFinalExamSettingsDate";
import AdminFinalExamSettingsExaminersView from "../views/finalExam/AdminFinalExamSettingsExaminersView";
import AdminFinalExamSettingsExamCriteriaView from "../views/finalExam/AdminFinalExamSettingsExamCriteriaView";
import FinalExamStartView from "../views/finalExam/FinalExamStartView";
import AdminFinalExamAdminListView from "../views/finalExam/AdminFinalExamAdminListView";
import routeGuardFinalExam from "../routeGuards/routeGuardFinalExam";
import routeGuardAdmin from "../routeGuards/routeGuardAdmin";
import FinalExamKioskView from "../views/finalExam/FinalExamKioskView";
import FinalExamProtocolsView from "../views/finalExam/FinalExamProtocolsView";
import KrokResultStudentView from "../views/finalExam/KrokResultStudentView";

export default (router) => {
 return [
     {
         path: '/finalExam',
         component: FinalExamRootView,
         name: 'finalExamRoot',
         beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
         redirect: {name: 'finalExamStart'},
         children: [
             // {
             //     path: 'student-sa-preselect',
             //     name: "studentSuperAdminPreselect",
             //     component: StudentSuperAdminPreSelect,
             //     beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             // },
             {
                 path: 'finalExamStart',
                 name: 'finalExamStart',
                 component: FinalExamStartView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'finalExamStudentDashboard',
                 name: 'finalExamStudentDashboard',
                 component: FinalExamStudentView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },  
             {
                 path: 'examResult',
                 name: 'examResult',
                 component: FinalExamResultStudentView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'krokResult',
                 name: 'studentKrokResult',
                 component: KrokResultStudentView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'studentExamList',
                 name: 'studentExamList',
                 component: FinalExamTeacherStudListView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'studentExamList/:examId',
                 name: 'studentExamListDetail',
                 component: FinalExamTeacherStudDetailView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'teacherSettings',
                 name: 'teacherSettings',
                 component: FinalExamTeacherSettings,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'adminStudentExamList/:date?/:specialityId?',
                 name: 'adminStudentExamList',
                 component: AdminFinalExamDateView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             
             {
                 path: 'adminTeacherExamList',
                 name: 'adminTeacherExamList',
                 component: AdminTeacherListView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             {
                 path: 'adminFinalExamAdminList',
                 name: 'adminFinalExamAdminList',
                 component: AdminFinalExamAdminListView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
             

             {
                 path: 'adminSettingsDates',
                 name: 'adminSettingsDates',
                 component: AdminFinalExamSettingsDate,
                 beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
             },
             {
                 path: 'adminSettingsExaminers/:examId?',
                 name: 'adminSettingsExaminers',
                 component: AdminFinalExamSettingsExaminersView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },

             {
                 path: 'adminSettingsCriterias/:examId?',
                 name: 'adminSettingsCriterias',
                 component: AdminFinalExamSettingsExamCriteriaView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },

             {
                 path: 'finalExamKiosk',
                 name: 'finalExamKiosk',
                 component: FinalExamKioskView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },

             {
                 path: 'finalExamProtocols',
                 name: 'finalExamProtocols',
                 component: FinalExamProtocolsView,
                 beforeEnter: (to, from, next) => routeGuardFinalExam(to, from, next)(router),
             },
         ],
     },
 ];
}