<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="pagination"
            class="elevation-3"
            hide-default-footer
    >
        <template v-slot:item.criteriaType="{item}">
            <div v-if="item.criteriaType === finalExamCriteriaEnum.generalSkills">Загальні навички</div>
            <div v-if="item.criteriaType === finalExamCriteriaEnum.mainSkills">Основні навички</div>
        </template>
        
        <template v-slot:item.actions="{item}">
<!--            <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">-->
<!--                <v-icon color="primary">edit</v-icon>-->
<!--            </v-btn>-->
<!--            <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">-->
<!--                <v-icon color="red">delete</v-icon>-->
<!--            </v-btn>-->
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../../helpers/dateHelper";
    import {finalExamCriteriaEnum} from "../../../../constants/_internal/finalExamCriteriaEnum";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Критерій', value: 'name'},
                {text: 'Порядок', value: 'order'},
                {text: 'Тип', value: 'criteriaType'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
            formatShortDate,
            finalExamCriteriaEnum,
        }),
    }
</script>