<template>
    <v-container fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-card-text>
                        <div class="subheading">Спеціальність, курс, кафедра</div>
                        <v-select
                                :items="specialities"
                                v-model="form.speciality"
                                item-text="nameUk"
                                item-value="id"
                                return-object
                                label="Спеціальність"
                                box
                        ></v-select>
                        <v-select
                                :items="semesters"
                                v-model="form.semester"
                                item-text="value"
                                item-value="id"
                                return-object
                                label="Курс"
                                box
                        ></v-select>
                        <v-select
                                :items="departments"
                                v-model="form.department"
                                item-text="nameUk"
                                item-value="id"
                                return-object
                                label="Кафедра"
                                box
                        ></v-select>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex style="margin-left: 310px; width: calc(100% - 300px);">
                <stats-index v-if="$route.params.department"/>
                <v-card v-if="!$route.params.department">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть спеціальність, курс та кафедру
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import StatsIndex from "../../../../components/superAdminStep/Dictionaries/Stats/StatsIndex";
    import NavigationListTemplate from "../../../../components/common/NavigationListTemplate";
    import HttpService from "../../../../HttpService";

    export default {
        components: {
            StatsIndex,
            NavigationListTemplate,
        },
        mounted() {
            HttpService.getInstance()
                .get("navigation/getSpecialities")
                .then(res => {
                    this.specialities = res;
                });
            
            if (this.$route.params.speciality) {
                this.form.speciality = {
                    id: Number(this.$route.params.speciality),
                };
            }

            if (this.$route.params.semester) {
                this.form.semester = {
                    id: Number(this.$route.params.semester),
                };
            }

            if (this.$route.params.department) {
                this.form.department = {
                    id: Number(this.$route.params.department),
                };
            }
        },
        watch: {
            "form.speciality": function (speciality) {
                if (speciality !== null) {
                    this.$router.push({
                       name: "stats",
                       params: {
                           speciality: speciality.id,
                       }
                    });

                    this.form.department = null;
                    this.form.semester = null;

                    HttpService.getInstance()
                        .get(`navigation/getSemestersBySpeciality/${speciality.id}`)
                        .then(res => {
                            this.semesters = res.map(r => {
                                r.value = `${r.yearNumber} курс (${r.semesterNumber} семестр)`;
                                
                                return r;
                            });
                        })
                }
            },
            "form.semester": function (semester) {                
                if (semester !== null) {
                    this.$router.push({
                        name: "stats",
                        params: {
                            speciality: this.form.speciality.id,
                            semester: semester.id
                        }
                    });
                    
                    this.form.department = null;
                    
                    HttpService.getInstance()
                        .get(`navigation/getDepartmentsBySpecialityYear/${this.form.speciality.id}/${semester.id}`)
                        .then(res => {
                            this.departments = res.map(r => {
                                r.value = `${r.name} (${r.nameShort})`;
                                
                                return r;
                            });
                        });
                }
            },
            "form.department": function (department) {
                if (department !== null) {
                    this.$router.push({
                        name: "stats",
                        params: {
                            speciality: this.form.speciality.id,
                            semester: this.form.semester.id,
                            department: department.id,
                        }
                    });
                }
            }
        },
        data: () => {
            return {
                specialities: [],
                semesters: [],
                departments: [],
                
                form: {
                    speciality: null,
                    semester: null,
                    department: null,
                }
            }
        }
    }
</script>