<template>
    <div>
        <v-btn @click="emitQuarantineAdmissions()" class="mb-2">Видати ВСІЙ групі допуски з поважної причини на період карантину</v-btn>
        <v-data-table
                :headers="headers"
                :items="students"
                :loading="loading"
                :server-items-length="students.length"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item="props">
                <tr :class="{'red lighten-5': props.item.countAbsences > 0}">
                    <td class="text-xs-left">{{`${props.item.surname} ${props.item.name} ${props.item.patronymic}`}}</td>
                    <td class="text-xs-left">{{props.item.countUnsatisfactory}}</td>
                    <td class="text-xs-left">{{props.item.countAbsences}}</td>
                    <td class="justify-center layout px-0">
                        <div class="d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mx-0" @click="openAdmissionsByStudent(props.item)">
                                        <v-icon color="primary">assignment</v-icon>
                                    </v-btn>
                                </template>
                                <span>Переглянути пропуски</span>
                            </v-tooltip>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="admissionsPerStudentDialog"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable>
            <admissions-list-per-student
                    v-if="admissionsPerStudentDialog"
                    @shouldCloseAddEdit="admissionsPerStudentDialog = false"
                    :student="selectedStudent"
            ></admissions-list-per-student>
        </v-dialog>

        <v-dialog v-model="quarantineAdmissions" max-width="700">
            <confirm-quarantine-admissions
                    @shouldCloseDeleteConfirm="quarantineAdmissions = false"
                    @saved="quarantineAdmissions = false"
            >
            </confirm-quarantine-admissions>
        </v-dialog>

    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AdmissionsListPerStudent from "../../common/rework-admissions/AdmissionsListPerStudent";
    import ConfirmQuarantineAdmissions from "./ConfirmQuarantineAdmissions";

    export default {
        components: {
            AdmissionsListPerStudent,
            ConfirmQuarantineAdmissions
        },
        mounted() {
            this.getStudents();
        },
        data: () => ({
            admissionsPerStudentDialog: false,
            headers: [
                {text: 'ПІП', sortable: false},
                {text: 'К-сть незарахованих занять ("2")', sortable: false},
                {text: 'К-сть пропущених занять ("нб")', sortable: false},
                {text: 'Дії', sortable: false},
            ],
            loading: false,
            students: [],
            
            quarantineAdmissions: false,
        }),
        methods: {
            getStudents() {
                HttpService.getInstance()
                    .get(`reworks/getAcademicGroupReworks/${this.$route.params.group}`)
                    .then(resp => {
                        this.students = resp;
                    });  
            },
            openAdmissionsByStudent(student) {
                this.selectedStudent = student;
                this.admissionsPerStudentDialog = true;
            },
            emitQuarantineAdmissions(){
                this.quarantineAdmissions = true;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .reworks-list-dialog {
        overflow-y: unset !important;
    }
</style>