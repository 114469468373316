<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 class="text-center">
                <div>
                    <img
                            width="280px"
                            :src="require('../../assets/pdmu_stem.png')"
                            alt="eplato_stem"
                    >
                  <div class="headline text--darken-3 orange--text font-weight-bold">{{$t("dashboard.universityName")}}</div>
                </div>

                <div v-if="$config.configMode === 'staging'" class="headline red--text"
                     v-html="$t('dashboard.testVersionExclamation')"></div>

                <div class="px-md-12 px-xs-4 mt-10 button-mrg">
                    <v-btn :loading="isLoading" @click="login()" large ripple color="info">
                        {{$t("dashboard.loginButtonTextFull")}}
                    </v-btn>
                    <div class="mt-12">
                        <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey lighten-1"
                                        small
                                        text
                                >{{$t("dashboard.cannotLogIn")}}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                <div class="px-2" style="max-width: 600px">
                                    <student-support-contacts></student-support-contacts>
                                </div>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import StudentSupportContacts from "../../components/dashboard/StudentSupportContacts";

    export default {
        components: {
          StudentSupportContacts,  
        },
        data: () => ({
            isLoading: false,
        }),
        methods: {
            login() {
                this.isLoading = true;
                this.$auth.login(this.$i18n.locale);
            }
        },
    }
</script>
<style lang="scss" scoped>
    .button-mrg {
        margin-bottom: 15em;
    }
</style>