<template>
    <div style="width: 150px; display: block;">
        <v-progress-linear :color="getProgressColor()" v-model="markMeanPercentage"></v-progress-linear>
    </div>
</template>
<script>
    export default {
        props: {
            markMeanPercentage: {
                type: Number,
                default: 0
            },
            markMeanValue: {
                type: Number,
                default: 0
            },
        },
        methods: {
            getProgressColor() {
                if (this.markMeanValue <= 5 && this.markMeanValue >= 4) {
                    return 'green accent-4';
                } else if (this.markMeanValue >= 3) {
                    return 'light-blue accent-4';
                } else if (this.markMeanValue >= 2) {
                    return 'amber darken-4';
                } else {
                    return 'red';
                }
            }
        }
    }
</script>