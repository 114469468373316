<template>
    <v-layout wrap>
        <v-flex xs12>
            <v-card min-height="150">
                <v-card-text>
                    <div class="title mb-2">Завантаження кодів студентів</div>
                    <div class="d-flex align-center">
                        <v-file-input
                                v-model="fileCodes"
                                chips
                                show-size
                                accept="text/csv"
                                label="CSV файл з кодами студентів"></v-file-input>
                        <v-btn :disabled="!fileCodes || loading"
                               :loading="loading"
                               class="ml-6"
                               color="primary" @click="uploadFile()">Завантажити
                        </v-btn>
                    </div>

                    <v-alert v-if="resp.length > 0" type="error">
                        <div v-for="stud in resp">{{stud.surname}} {{stud.name}}</div>
                    </v-alert>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" min-height="150">
                <v-card-text>
                    <div class="title mb-2">Завантаження РЕЗУЛЬТАТІВ студентів</div>
                    <div class="d-flex align-center">
                        <v-file-input
                                v-model="fileResults"
                                chips
                                show-size
                                accept="text/csv"
                                label="CSV файл з результатами студентів"></v-file-input>
                        <v-btn :disabled="!fileResults || loadingResults"
                               :loading="loadingResults"
                               class="ml-6"
                               color="primary" @click="uploadResultsFile()">Завантажити
                        </v-btn>
                    </div>

                    <v-alert v-if="resultsResp.length > 0" type="error">
                        <div v-for="res in resultsResp">{{res}}</div>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            fileCodes: null,

            resp: [],
            loading: false,

            fileResults: null,
            resultsResp: [],
            loadingResults: false,
        }),
        methods: {
            uploadFile() {
                const formData = new FormData();
                formData.append("file", this.fileCodes);

                let acessToken = localStorage.getItem('access_token');

                this.loading = true;
                
                HttpService.getInstance(
                    {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${acessToken}`
                    }
                )
                    .post("krokResult/krokResultCodes", formData)
                    .then(resp => { 
                        this.resp = resp;
                        
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            
            uploadResultsFile() {
                const formData = new FormData();
                formData.append("file", this.fileResults);

                let acessToken = localStorage.getItem('access_token');

                this.loadingResults = true;

                HttpService.getInstance(
                    {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${acessToken}`
                    }
                )
                    .post("krokResult/krokResults", formData)
                    .then(resp => {
                        this.resultsResp = resp;

                        this.loadingResults = false;
                    })
                    .catch(() => {
                        this.loadingResults = false;
                    });
            }
        }
    }
</script>