<template>
    <v-navigation-drawer
            :fixed="true"
            app
            :clipped="$vuetify.breakpoint.mdAndUp"
            :value="drawer"
            fixed
            @input="$emit('toggleDrawer', $event)"
    >
        <v-container fluid>
            <v-btn class="mb-6" small :to="{name: backBtnRouteTo}">
                <v-icon>keyboard_backspace</v-icon>
                Перейти до меню
            </v-btn>
        </v-container>
        
        <navigation-stud-nav-list :api-endpoint="apiEndpoint" 
                                  :route-to="routeTo" 
                                  :additional-route-params="additionalRouteParams"
        />
    </v-navigation-drawer>
</template>
<script>
    import NavigationStudNavList from '../../common/NavigationStudNavList';
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";

    export default {
        components: {
            NavigationStudNavList,
            DepartmentSubjectSwitcher
        },
        props: {
            drawer: {
                type: Boolean,
            },
            routeTo: {
                type: String,
                default: "registers"
            },
            backBtnRouteTo: {
                type: String,
                default: "registersRoot"
            },
            additionalRouteParams: {
                type: Object,
                default: () => {
                }
            },
            apiEndpoint: {
                type: String,
                default: "navigation/getNavRegisters"
            }
        },
        methods: {
            isDepSubjNavigation() {
                return (this.$route.params.department !== undefined &&
                    this.$route.params.subject !== undefined);
            },
            dialogNavigationDepartmentSubject() {
                if (!this.isDepSubjNavigation() && this.$route.name === 'marksRoot') {
                    this.isDepartmentSubjectSwitcherOpened = true;
                }
            },  
        },
        data: () => ({
            isDepartmentSubjectSwitcherOpened: false,
        }),
    }
</script>