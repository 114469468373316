<template>
    <v-container grid-list-md fluid fill-height>
        <vue-headful
                title="Налаштування критеріїв"
        />
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 260px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Іспити</v-subheader>
                            <navigation-list-template :items="examNames"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex v-if="$route.params.examId !== undefined" style="margin-left: 260px; width: calc(100% - 560px);">
                <v-card>
                    <admin-exam-settings-criteria-index :key="$route.fullPath"></admin-exam-settings-criteria-index>
                </v-card>
            </v-flex>
            <v-flex v-else style="margin-left: 260px; width: calc(100% - 560px);">
                <div class="title">Будь ласка, виберіть іспит</div>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import AdminExamSettingsCriteriaIndex
        from "../../components/finalExam/adminDashboard/settingCriteria/AdminExamSettingsCriteriaIndex";

    export default {
        components: {
            NavigationListTemplate,
            AdminExamSettingsCriteriaIndex,
        },
        mounted() {
            HttpService.getInstance()
                .get("FinalExamAdmin/getFinalExamNamesAll")
                .then(navItems => {
                    this.examNames = this.remapSubjNavItems(navItems);
                });
        },
        methods: {
            remapSubjNavItems(subjNavItems) {
                if (subjNavItems === undefined) {
                    return [];
                }

                let subjNavigationRemapped = subjNavItems.slice(0);

                subjNavigationRemapped.map(spec => {
                    spec.name = `(${spec.speciality.nameShortUk}) ${spec.name}`;
                    spec.route = {
                        name: 'adminSettingsCriterias',
                        params: {
                            examId: spec.id,
                        }
                    };

                    return spec;
                });

                return subjNavigationRemapped;
            },
        },
        data: () => {
            return {
                examNames: [],
            };
        }
    }
</script>