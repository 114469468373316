<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                        v-on="on"
                        large
                        color="red darken-2"
                        v-if="value === markValuesEnum.absence"
                >font_download
                </v-icon>
            </template>
            <span>Пропуск заняття</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                        v-on="on"
                        large
                        color="red darken-2"
                        v-if="value === markValuesEnum.unsatisfactory"
                >filter_2
                </v-icon>
            </template>
            <span>Незараховане заняття</span>
        </v-tooltip>
    </div>
</template>

<script>
    import {markValuesEnum} from "../../../constants/markValuesEnum";

    export default {
        props: {
            value: Number,
        },
        data: () => ({
            markValuesEnum
        }),
    }
</script>