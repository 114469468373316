import NotFoundView from "../views/common/NotFoundView";
import UnauthorizedView from "../views/common/UnauthorizedView";
import HomeView from "../views/mainDashboard/HomeView";
import DashboardRootView from "../views/mainDashboard/DashboardRootView";

import SettingsRootView from "../views/mainDashboard/SettingsRootView";
import SettingsView from "../views/mainDashboard/SettingsView";
import ProfileView from "../views/mainDashboard/ProfileView";

import HelpDeskRootView from "../views/mainDashboard/helpdeskModule/HelpDeskRootView";
import HelpdeskMyTicketsView from "../views/mainDashboard/helpdeskModule/HelpdeskMyTicketsView";
import HelpdeskWatchingTicketsView from "../views/mainDashboard/helpdeskModule/HelpdeskWatchingTicketsView";

import routeGuard from "../routeGuards/routeGuard";
import CannotAccessView from "../views/common/CannotAccessView";

import TermsAndConditions from "../views/mainDashboard/TermsAndConditions";
import PrivacyPolicy from "../views/mainDashboard/PrivacyPolicy";
import LiqPayTermsAndConditions from "@/components/student/academicAbsents/LiqPayTermsAndConditions.vue";

export default (router) => {
    return [
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: TermsAndConditions,
        },
        {
            path: '/privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicy,
        },
        {
            path: '/',
            component: DashboardRootView,
            children: [
                {
                    path: '',
                    name: 'home',
                    component: HomeView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: '/unauthorized',
                    name: 'unauthorized',
                    component: UnauthorizedView,
                },
                {
                    path: '/cannot-access',
                    name: 'cannot-access',
                    component: CannotAccessView,
                },
                {
                    path: '/notfound',
                    name: 'notfound',
                    component: NotFoundView,
                },
                {
                    path: '/payment-terms-and-conditions',
                    name: 'payment-terms-and-conditions',
                    component: LiqPayTermsAndConditions,
                    props: { showAgreeBtn: false }
                },
            ]
        },
        {
            path: '/settings',
            component: SettingsRootView,
            name: 'settingsRoot',
            redirect: {name: 'profile'},
            children: [
                {
                    path: '/profile',
                    name: 'profile',
                    component: ProfileView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: SettingsView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
            ]
        },
        {
            path: '/helpdesk',
            component: HelpDeskRootView,
            name: 'helpdeskRoot',
            redirect: {name: 'myTickets'},
            children: [
                {
                    path: '/my-tickets',
                    name: 'myTickets',
                    component: HelpdeskMyTicketsView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: '/watching-tickets',
                    name: 'watchingTickets',
                    component: HelpdeskWatchingTicketsView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
            ]
        },
    ];
}