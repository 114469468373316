<template>
    <form @submit="submitForm" novalidate="true">
        <v-card class="mt-12">
            <v-app-bar dark color="primary" fixed>
                <v-btn icon dark
                       @click.native="$emit('shouldCloseAddEdit')"
                       :disabled="loadingSave"
                >
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{editMode ? 'Редагування пулу питань' : 'Додавання пулу питань'}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text
                           type="submit"
                           :loading="loadingSave"
                           :disabled="loadingSave"
                    >Зберегти
                    </v-btn>
                </v-toolbar-items>
            </v-app-bar>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.name" 
                                          v-model="form.name"
                                          label="Назва"></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-card :key="index" class="mb-1 elevation-7" v-for="(question, index) in form.pollQuestions">
                                <v-card-text>
                                    <v-layout wrap>
                                        <v-flex xs6 class="subtitle-1">
                                            <v-btn v-if="index !== 0" 
                                                   @click="removeQuestion(index)" 
                                                   small 
                                                   color="red" 
                                                   text 
                                                   icon>
                                                <v-icon>clear</v-icon>
                                            </v-btn>
                                            Питання №{{index + 1}}
                                        </v-flex>
                                        <v-flex xs6>
                                            <v-select
                                                    :items="pollQuestionTypes"
                                                    v-model="question.questionType"
                                                    :error-messages="getQuestionError(index, 'questionType')"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Тип запитання"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap>
                                        <v-flex xs6>
                                            <v-textarea
                                                    solo
                                                    label="Текст запитання (укр)"
                                                    v-model="question.textUk"
                                                    :error-messages="getQuestionError(index, 'textUk')"
                                            ></v-textarea>
                                        </v-flex>
                                        <v-flex xs6>
                                            <v-textarea
                                                    solo
                                                    label="Текст запитання (eng)"
                                                    v-model="question.textEn"
                                                    :error-messages="getQuestionError(index, 'textEn')"
                                            ></v-textarea>
                                        </v-flex>
                                        <v-flex v-if="question.questionType !== pollQuestionTypesEnum.freeAnswer"
                                                xs12 
                                                :key="answerIndex" 
                                                v-for="(answer, answerIndex) in question.answers">
                                            <v-layout wrap>
                                                <v-flex xs5>
                                                    <v-text-field
                                                            solo
                                                            v-model="answer.titleUk"
                                                            label="Текст відповіді (укр)"
                                                            :error-messages="getAnswerError(index, answerIndex, 'titleUk')"
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex xs5>
                                                    <v-text-field
                                                            solo
                                                            v-model="answer.titleEn"
                                                            label="Текст відповіді (en)"
                                                            :error-messages="getAnswerError(index, answerIndex, 'titleEn')"
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-flex xs1>
                                                    <v-btn 
                                                            v-if="answerIndex !== 0"
                                                            icon 
                                                            @click="removeAnswer(question.answers, answer)">
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>

                                        <v-flex xs12 v-if="question.questionType !== pollQuestionTypesEnum.freeAnswer">
                                            <v-layout wrap>
                                                <v-flex xs6>
                                                    <v-btn
                                                            @click="addQuestionAnswer(question)"
                                                            small
                                                    >Додати відповідь
                                                    </v-btn>
                                                </v-flex>

                                                <v-flex xs6>
                                                    <v-switch
                                                            class="free-question-switch"
                                                            label="Додати вільну відповідь"
                                                            v-model="question.withAnswerOther"
                                                    ></v-switch>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>

                        </v-flex>
                        <v-flex xs12>
                            <v-btn @click="addQuestion" color="primary">
                                <v-icon>add</v-icon>
                                Додати запитання
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import pollQuestionTypes, {pollQuestionTypesEnum} from "../../../../constants/admin/pollQuestionType";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    name: null,
                    pollQuestions: [
                        {
                            questionType: pollQuestionTypesEnum.singleChoice,
                            withAnswerOther: false,
                            answers: [
                                {
                                }
                            ],
                        }
                    ]
                },
                errors: {
                    name: [],
                },
                loading: false,
                loadingSave: false,
                pollQuestionTypes,
                pollQuestionTypesEnum,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`pollpools/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            getAnswerError(questionIndex, answerIndex, fieldName) {
                const answersErrors = this.getQuestionError(questionIndex, `answers[${answerIndex}]`);
                
                if (answersErrors !== "") {
                    return answersErrors[fieldName];
                }
                return "";
            },
            getQuestionError(questionIndex, fieldName) {
                const questionErrorRow = this.errors[`pollQuestions[${questionIndex}]`];
                if (questionErrorRow) {
                    const questionError = questionErrorRow[fieldName];
                    if (questionError) {
                        return questionError;
                    }

                    return "";
                }

                return "";
            },
            removeAnswer(answers, answer) {
                const indexOf = answers.indexOf(answer);
                
                answers.splice(indexOf, 1);
            },
            addQuestionAnswer(question) {
                question.answers.push({});
            },
            removeQuestion(index) {
                this.form.pollQuestions.splice(index, 1);
            },
            addQuestion() {
                this.form.pollQuestions.push(
                    {
                        questionType: pollQuestionTypesEnum.singleChoice,
                        withAnswerOther: false,
                        answers: [
                            {
                            }
                        ],
                    }
                );
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("pollPools", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>
<style lang="scss" scoped>
    .free-question-switch {
        margin-top: 0px;
    }
</style>