<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.semesterYear="{item}">
            {{`${item.semester.yearNumber} курс / ${item.semester.semesterNumber} семестр`}}
        </template>
        
        <template v-slot:item.username="{item}">
            <div v-if="item.username !== null">{{item.username}}</div>
            <div v-if="item.username === null">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon v-on="on" color="red">remove</v-icon>
                    </template>
                    <span>Немає створеного аккаунту</span>
                </v-tooltip>
            </div>
        </template>
        <template v-slot:item.email="{item}">
            <div v-if="item.email !== null">{{item.email}}</div>
            <div v-if="item.email === null">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-icon v-on="on" color="red">remove</v-icon>
                    </template>
                    <span>Немає створеного аккаунту</span>
                </v-tooltip>
            </div>
        </template>
        
        <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn
                            icon
                            v-on="on"
                    >
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="navigateToRegisters(item)">
                        <v-list-item-title>Переглянути оцінки</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateToStudentManagement(item)">
                        <v-list-item-title>Перейти до управління студентами</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="navigateToStudentPasswordCreation(item)">
                        <v-list-item-title>Перейти до створення аккаунтів</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
            navigateToRegisters(student) {
                this.$router.push({
                    name: "registers",
                    params: {
                        faculty: student.faculty.id,
                        speciality: student.speciality.id,
                        year: student.semester.id,
                        group: student.academicGroup.id,
                    }
                });
            },
            navigateToStudentManagement(student) {
                this.$router.push({
                    name: "studentsGrid",
                    params: {
                        faculty: student.faculty.id,
                        speciality: student.speciality.id,
                        semester: student.semester.id,
                        group: student.academicGroup.id,
                    }
                });
            },
            navigateToStudentPasswordCreation(student) {
                this.$router.push({
                    name: "studentsAccountsGrid",
                    params: {
                        faculty: student.faculty.id,
                        speciality: student.speciality.id,
                        semester: student.semester.id
                    }
                });
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Прізвище', value: 'surname', sortable: false},
                {text: 'Ім\'я', value: 'name', sortable: false},
                {text: 'По-батькові', value: 'patronymic', sortable: false},
                {text: 'Назва аккаунту', value: 'username', sortable: false},
                {text: 'Email аккаунту', value: 'email', sortable: false},
                {text: 'Факультет', value: 'faculty.nameUk', sortable: false},
                {text: 'Спеціальність', value: 'speciality.nameUk', sortable: false},
                {text: 'Курс/семестр', value: 'semesterYear', sortable: false},
                {text: 'Група', value: 'academicGroup.groupNumber', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>