<template>
    <div>
        <div class="headline">Тестові завдання</div>
        
        <v-form ref="searchForm" @submit="submitSearch" novalidate="true">
            <v-card-title class="px-0">
                <v-text-field
                    v-model="search"
                    label="Пошук по назві тесту"
                    single-line
                    hide-details
                    clearable
                    @click:clear="$emit('search', null)"
                ></v-text-field>
                <v-btn
                    color="primary"
                    type="submit">
                    <v-icon>search</v-icon>
                </v-btn>
            </v-card-title>
        </v-form>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.actions="{item}">
               <div style="min-width: 75px;">
                   <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                       <v-icon color="primary">edit</v-icon>
                   </v-btn>
                   <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                       <v-icon color="red">delete</v-icon>
                   </v-btn>
               </div>
            </template>
            <template v-slot:item.id="{item}">
                <div class="font-italic caption">{{item.id}}</div>
            </template>
            <template v-slot:item.question="{item}">
                <div>
                    <div>{{item.question}}</div>
                    <div v-if="item.image">
                        <img width="350" alt="" :src="photoUrlFull(item.image)"/>
                    </div>
                </div>
            </template>
            <template v-slot:item.quizAnswers="{item}">
                <div class="d-flex align-center" v-for="quizAnswer in item.quizAnswers">
                    <span>
                        <v-icon v-if="quizAnswer.isRight">check_circle</v-icon>
                        <v-icon v-if="!quizAnswer.isRight">remove</v-icon>
                    </span>
                    <span>{{quizAnswer.answer}}</span>
                    <span v-if="quizAnswer.image">
                         <img width="200" alt="" :src="photoUrlFull(quizAnswer.image)"/>
                    </span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {AppConfig} from "@/config/configFactory";
    import config from "@/app.config";
    
    let configuration = AppConfig(config);

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Дії', value: 'actions', sortable: false},
                {text: 'Id', value: 'id'},
                {text: 'Питання', value: 'question', width: "50%"},
                {text: 'Відповіді', value: 'quizAnswers', width: "30%"},
            ],
            search: null,
        }),
        methods: {
            submitSearch(e) {
                e.preventDefault();

                this.$emit('search', this.search);
            },
            photoUrlFull(photoUrl) {
                return `${configuration.apiEndpoint}/file/get/reworkQuizImage/${photoUrl}`;  
            },
        }
    }
</script>