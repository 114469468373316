<template>
    <v-card>
        <v-card-title class="title">Відпрацювання заняття</v-card-title>

        <v-card-text>
            <v-layout class="mb-2" align-center wrap>
                <v-flex xs6 class="text-center font-weight-medium" style="border-right: 1px solid #ccc">
                    <div>
                        <v-avatar class="mb-1" size="80px">
                            <v-icon v-if="gendersEnum.male === student.gender">person</v-icon>
                            <v-icon v-if="gendersEnum.female === student.gender">face</v-icon>
                        </v-avatar>
                    </div>
                    <div class="title">{{`${student.surname} ${student.name} ${student.patronymic}`}}</div>
                    <div class="subtitle-1">{{student.faculty.nameUk}}</div>
                    <div class="subtitle-1">{{student.speciality.nameUk}}</div>
                    <div class="subtitle-1">{{student.semester.yearNumber}} курс</div>
                    <div class="subtitle-1">{{student.academicGroup.groupNumber}} група</div>
                </v-flex>
                <v-flex xs6 class="text-center">
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Дисципліна: </span>
                        {{reworkItemData.subject.nameUk}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Тема заняття: </span>
                        {{reworkItemData.topic.nameUk}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Тривалість заняття: </span>
                        {{reworkItemData.topic.academicHours}}
                        год.
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Дата заняття: </span>
                        {{formatShortDate(reworkItemData.classDate)}}
                    </div>

                    <mark-unsatisfactory-icons class="mt-2" :value="reworkItemData.value"/>

                    <mark-input
                            class="mt-2"
                            style="margin: 0 auto;"
                            @change="newMarkValue = $event"
                            :value="newMarkValue.value"
                            :is-absence="false"
                            :topic="reworkItemData.topic"
                            :is-rework-mode="true"
                    />
                    <div v-if="errors.markValue != null"
                         class="red--text caption"
                    >{{errors.markValue}}</div>

                    <div class="d-flex justify-center mt-8" v-if="reworkItemDataClonned !== null">
                        <v-flex xs12 md6>
                            <date-picker
                                    v-model="reworkItemDataClonned.classDate"
                                    label="Дата перескладання"
                                    :max-date="new Date()"
                            ></date-picker>
                        </v-flex>
                    </div>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseSaveConfirm')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="saveRework"
                    :loading="loading"
                    :disabled="loading"
            >Зберегти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import MarkUnsatisfactoryIcons from "./MarkUnsatisfactoryIcons";
    import MarkInput from "../../../components/common/MarkInput";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";
    import DatePicker from "../DatePicker";
    import moment from "moment";
    import cloneDeep from "lodash/cloneDeep";

    export default {
        components: {
            MarkUnsatisfactoryIcons,
            MarkInput,
            DatePicker
        },
        props: {
            student: Object,
            reworkItem: Object,
        },
        data: () => {
            return {
                loading: false,
                formatShortDate,
                newMarkValue: {
                    id: null,
                    value: null,
                },
                errors: {
                    markValue: null,
                },
                reworkItemData: {
                    topic: {
                        nameUk: null,
                    },
                    subject: {
                        nameUk: null,
                        academicHours: null,
                    },
                    // module: {
                    //     ects5: null,
                    //     ects4: null,
                    //     ects3: null,
                    // },
                    value: null,
                    classDate: null,
                },
                reworkItemDataClonned: null,
                gendersEnum,
            };
        },
        mounted() {
            this.reworkItemData = cloneDeep(this.reworkItem);
            this.reworkItemDataClonned = cloneDeep(this.reworkItem);
            
            this.reworkItemDataClonned.classDate = moment().toDate();
        },
        methods: {
            saveRework() {
                this.loading = true;

                const saveObj = {
                    id: this.reworkItem.id,
                    markValue: this.newMarkValue.value,
                    student: this.student,
                    classDate: this.reworkItemDataClonned.classDate,
                };

                HttpService.getInstance()
                    .post(`reworks`, saveObj)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    })
                    .catch(err => {
                        this.loading = false;
                        
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>