<template>
    <div>
        <v-btn class="mb-3" @click="openCsv()">Завантажити CSV</v-btn>

        <rating-department-grid
                :items="items"
                :total="total"
                :loading="loading"
        >
        </rating-department-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import RatingDepartmentGrid from "./RatingDepartmentGrid";

    export default {
        components: {
            RatingDepartmentGrid,
        },
        methods: {
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingStat/getDepartmentStats`)
                    .then(items => {
                        this.items = items.items.map((i, index) => {
                            i.index = index + 1;

                            i.ratingDirections.forEach(direction => {
                                i[`direction${direction.order}`] = direction.pointsSum;
                                i[`direction${direction.order}_ratio`] = direction.pointsSumRatio;
                            });

                            return i;
                        });
                        // this.total = faculties.total;
                        this.loading = false;
                    })
            },

            openCsv() {
                window.open(`${this.$config.apiEndpoint}/ratingStat/getDepartmentStatsCsv`);
            }
        },
        mounted() {
            this.getItems();
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
            }
        }
    }
</script>