<template>
    <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
            <v-flex xs12>
                <student-quiz-wrapper
                        :api-get-question-student-overview="`studentStep/getQuestionStudentOverview/${$route.params.quizId}`"
                        :api-finish-quiz-attempt="`studentStep/finishQuizAttempt/${$route.params.quizId}`"
                        :api-get-question-student="`studentStep/getQuestionStudent/${$route.params.quizId}`"
                        :api-save-question-answer="`studentStep/saveQuestionAnswer/${$route.params.quizId}`"

                        route-quiz-finish="studentQuizTestFinish"
                        :route-quiz-finish-additional-params="{quizId: $route.params.quizId}"
                        route-quiz-home="studentQuizTest"
                        :route-quiz-additional-params="{quizId: $route.params.quizId}"
                        
                        :is-student-test="true"
                />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import StudentQuizWrapper from "../../components/studentQuiz/StudentQuizWrapper";
    
    export default {
        components: {
            StudentQuizWrapper 
        }
    }
</script>