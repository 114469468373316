<template>
    <div>
        <e-aristo-question-student-quiz-index v-if="questionsOverallResultPassed === null"
                                              :questions-to-pass="questionsOverall"
                                              :quiz-attempt-id="quizAttemptId"
                                              :is-overall-quiz="true"
                                              @submittedAnswers="onSubmittedAnswers"
        ></e-aristo-question-student-quiz-index>

        <div v-if="questionsOverallResultPassed !== null">
            <v-alert
                v-if="questionsOverallResultPassed"
                border="top"
                colored-border
                type="info"
                elevation="2"
            > 
                {{$t("eAristoStudent.youAnsweredCorrectThisBlock")}}  {{$t("eAristoStudent.yourResultIs")}} – <strong>{{questionsOverallResult.toFixed(2)}}%</strong>.
                {{$t("eAristoStudent.clickFinishTestToFinishTopic")}}
            </v-alert>
            <v-alert
                v-if="!questionsOverallResultPassed"
                border="top"
                colored-border
                type="warning"
                elevation="2"
            >
                {{$t("eAristoStudent.youNotAnsweredCorrectThisBlock")}}  {{$t("eAristoStudent.yourResultIs")}} – <strong>{{questionsOverallResult.toFixed(2)}}%</strong>.
                {{$t("eAristoStudent.clickFinishTestRestartTopic")}}
            </v-alert>
        </div>
    </div>
</template>

<script>

import eAristoQuestionStudentQuizIndex
    from "@/components/eAristoStudent/topicContent/questions/eAristoQuestionStudentQuizIndex.vue";
export default {
    name: "OverallTopicContent",
    props: {
        questionsOverall: {
            type: Array,
            default: () => {
                return [];
            }
        },
        quizAttemptId: {
            type: Number,
        },
        questionsOverallResult: {
            type: Number,
        },
        questionsOverallResultPassed: {
            type: Boolean
        }

    },
    components: {
        eAristoQuestionStudentQuizIndex,
    },
    methods: {
        onSubmittedAnswers(submitResult) {
            
            this.$emit('submittedAnswers', submitResult);
        }
    }
}
</script>

<style scoped>

</style>