<template>
    <v-container grid-list-md>
        <dean-admissions-documents-student-wrapper/>
    </v-container>
</template>

<script>
    import DeanAdmissionsDocumentsStudentWrapper
        from "../../components/dean/admissionsDocumentsStudent/DeanAdmissionsDocumentsStudentWrapper";

    export default {
        components: {
            DeanAdmissionsDocumentsStudentWrapper
        },
    }
</script>
