import RatingAdminRootView from "../views/ratingAdmin/RatingAdminRootView";
import routeGuardAdminRating from "../routeGuards/routeGuardAdminRating";
import RatingHomeView from "../views/rating/RatingHomeView";
import RatingAdminDirectionsIndexRootView from "../views/ratingAdmin/RatingAdminDirectionsIndexRootView";
import RatingDirectionsRootView from "../views/ratingAdmin/RatingDirectionsRootView";
import RatingCheckView from "../views/ratingAdmin/RatingCheckView";
import RatingistCheckView from "../views/ratingAdmin/RatingistCheckView";
import routeGuardRating from "../routeGuards/routeGuardRating";
import RatingAdminCheckDeptRatingAllRootView from "../views/ratingAdmin/RatingAdminCheckDeptRatingAllRootView";
import RatingCheckDeptDirectionsView from "../views/ratingAdmin/RatingCheckDeptDirectionsView";
import RatingDepartmentStat from "../views/rating/RatingDepartmentStat";
import RatingProfessorStat from "../views/rating/RatingProfessorStat";
import RatingDirectionsStat from "../views/rating/RatingDirectionsStat";
import RatingDirectionsStatDirectionView from "../views/ratingAdmin/RatingDirectionsStatDirectionView";

export default (router) => {
 return [
     {
         path: '/ratingAdmin',
         component: RatingAdminRootView,
         name: 'ratingAdminRoot',
         beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
         redirect: {name: 'ratingAdminHome'},
         children: [
             {
                 path: '',
                 name: 'ratingAdminHome',
                 component: RatingHomeView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingDictionary/ratingIndexes',
                 name: "ratingDirections",
                 component: RatingDirectionsRootView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingDictionary/ratingIndexes/:direction?',
                 name: "ratingIndexes",
                 component: RatingAdminDirectionsIndexRootView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingCheck',
                 name: "ratingCheck",
                 component: RatingCheckView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingCheck/:department?',
                 name: "ratingCheckDirections",
                 component: RatingAdminCheckDeptRatingAllRootView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingCheck/:department?/ratingIndexes/:direction?',
                 name: "ratingCheckWorkTypes",
                 component: RatingCheckDeptDirectionsView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             
             {
                 path: 'ratingCheckers',
                 name: "ratingCheckers",
                 component: RatingistCheckView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingDepartmentStat',
                 name: "ratingDepartmentStat",
                 component: RatingDepartmentStat,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingDirectionsStat',
                 name: "ratingDirectionsStat",
                 component: RatingDirectionsStat,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingDirectionsStat/:direction',
                 name: "ratingDirectionsStatDirection",
                 component: RatingDirectionsStatDirectionView,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
             {
                 path: 'ratingProfessorStat',
                 name: "ratingProfessorStat",
                 component: RatingProfessorStat,
                 beforeEnter: (to, from, next) => routeGuardAdminRating(to, from, next)(router),
             },
         ],
     },
 ];
}