<template>
    <v-container class="justify-center mt-12">
        <v-row>
            <v-col cols="12">
                <div class="align-center text-center">
                    <v-icon style="font-size: 140px" color="red">block</v-icon>
                </div>
            </v-col>
            <v-col cols="12">
                <div class="text-center">
                    <p class="headline text-center">Ви не маєте достатніх прав для доступу до даного функціоналу!</p>
                    <p class="headline text-center">
                        <router-link to="/">На головну</router-link>
                    </p>
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            isLoading: false,
        }),
    }
</script>