export const pollQuestionTypesEnum = {
    singleChoice:  1,
    multipleChoice: 2,
    freeAnswer: 3
};

export default [
    {
        id: pollQuestionTypesEnum.singleChoice,
        name: "Одна відповідь"
    },
    {
        id: pollQuestionTypesEnum.multipleChoice,
        name: "Кілька відповідей"
    },
    {
        id: pollQuestionTypesEnum.freeAnswer,
        name: "Вільна відповідь"
    },
];
