<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <search-bar
                        :loading="loading"
                        input-label="Логін або ПІП користувача..."
                        @search="getItemsSearch($event)"
                />
                <v-flex xs12>
                    <staff-management-grid
                            :items="items"
                            :total="total"
                            :loading="loading"
                            @paginate="onPaginate"
                            @editItem="editItem"
                    >
                    </staff-management-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" max-width="1000px" scrollable>
            <add-edit-staff-management
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-staff-management>
        </v-dialog>

        <v-btn
                color="primary"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import StaffManagementGrid from "./StaffManagementGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AddEditStaffManagement from "./AddEditStaffManagement";
    import SearchBar from "../common/SearchBar";

    export default {
        components: {
            StaffManagementGrid,
            AddEditStaffManagement,
            SearchBar,
        },
        mounted() {
            this.pagination = Object.assign({}, this.pagination);
        },
        watch: {
            '$route.params.staffTypeId': function (staffTypeId) {
                this.getItems(objectQueryStringify(this.pagination), staffTypeId);
            },
        },
        methods: {
            getItemsSearch(searchString){
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination), this.$route.params.staffTypeId);
            },
            getItems(queryParams, staffId = null) {
                this.loading = true;

                const apiUrl = staffId ? `StaffManagement/grid/${staffId}` : 'StaffManagement/grid';

                HttpService.getInstance()
                    .get(`${apiUrl}?${queryParams}`)
                    .then(users => {
                        this.items = users.items;
                        this.total = users.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination), this.$route.params.staffTypeId);
                }
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.staffTypeId);
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            }
        },
        data: () => {
            return {
                dialog: false,
                editId: null,
                editMode: false,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>