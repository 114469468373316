<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout row wrap>
            <v-flex xs12>
                <head-dept-rating-directions-index></head-dept-rating-directions-index>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>

    import HeadDeptRatingDirectionsIndex from "../../../components/chiefDept/rating/HeadDeptRatingDirectionsIndex";

    export default {
        components: {
            HeadDeptRatingDirectionsIndex
        }
    }
</script>