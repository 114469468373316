<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <search-bar
                        :key="$route.fullPath"
                        :loading="loading"
                        input-label="Логін або ПІП користувача..."
                        @search="getItemsSearch($event)"
                />
                <v-flex xs12>
                    <user-management-grid
                            :items="items"
                            :total="total"
                            :loading="loading"
                            @paginate="onPaginate"
                            @editItem="editItem"
                            @blockItem="blockItem"
                    >
                    </user-management-grid>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="dialog" max-width="800px" scrollable>
            <edit-user-management
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></edit-user-management>
        </v-dialog>

        <v-dialog v-model="dialogBlock" max-width="290">
            <block-confirm
                    @shouldCloseBlockConfirm="dialogBlock = false"
                    @blocked="blocked"
                    :block-id="blockId"
            >
            </block-confirm>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import UserManagementGrid from "./UserManagementGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import EditUserManagement from "./EditUserManagement";
    import BlockConfirm from "./BlockConfirm";
    import SearchBar from "../common/SearchBar";

    export default {
        components: {
            UserManagementGrid,
            EditUserManagement,
            BlockConfirm,
            SearchBar
        },
        mounted() {
            if (this.$route.params.facultyId !== undefined) {
                let filter = [{field: 'facultyId', value: this.$route.params.facultyId, operator: 'eq'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
            }
            if (this.$route.params.departmentType !== undefined) {
                let filter = [{field: 'departmentType', value: this.$route.params.departmentType, operator: 'eq'}];

                this.pagination = Object.assign({}, this.pagination, {filter});
            }
        },
        watch: {
            '$route.params.roleId': function (newRoleId) {
                this.getItems(objectQueryStringify(this.pagination), newRoleId);
            },
        },
        methods: {
            getItemsSearch(searchString){
                this.pagination = Object.assign({}, this.pagination, {searchString});
                this.getItems(objectQueryStringify(this.pagination), this.$route.params.roleId);
            },
            getItems(queryParams, roleId = null) {
                this.loading = true;

                const apiUrl = roleId != null && roleId !== undefined ? `userManagement/${roleId}` : 'userManagement';

                HttpService.getInstance()
                    .get(`${apiUrl}?${queryParams}`)
                    .then(users => {
                        this.items = users.items;
                        this.total = users.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.pagination = Object.assign(this.pagination, paginationData);

                    this.getItems(objectQueryStringify(this.pagination), this.$route.params.roleId);
                }
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.roleId);
            },
            blockItem(itemId) {
                this.dialogBlock = true;
                this.blockId = itemId;
            },
            blocked() {
                this.dialogBlock = false;
                this.blockId = null;

                this.getItems(objectQueryStringify(this.pagination), this.$route.params.roleId);
            },
        },
        data: () => {
            return {
                dialog: false,
                dialogBlock: false,
                editId: null,
                editMode: false,
                blockId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>