<template>
    <v-layout wrap>
        <v-flex xs2 class="px-2">
            <v-select
                    clearable
                    :disabled="isSelected"
                    :items="faculties"
                    v-model="faculty"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Факультет"
            ></v-select>
        </v-flex>

        <v-flex xs2 class="px-2" v-if="withSpeciality">
            <v-select
                    clearable
                    :disabled="faculty === null || isSelected"
                    :items="specialities"
                    v-model="speciality"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Спеціальність"
            ></v-select>
        </v-flex>

        <v-flex xs2 class="px-2" v-if="withSpeciality && withSemester">
            <v-select
                    clearable
                    :disabled="speciality === null || isSelected"
                    :items="semesters"
                    v-model="semester"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Семестр"
            ></v-select>
        </v-flex>

        <v-flex xs2 class="px-2" v-if="withSpeciality && withSemester && withGroup">
            <v-select
                    clearable
                    :disabled="semester === null || isSelected"
                    :items="academicGroups"
                    v-model="academicGroup"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Група"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-btn :disabled="!couldSelect || isSelected" color="primary" @click="onSelect()">Вибрати</v-btn>
            <v-btn :disabled="!isSelected" class="ml-2" @click="onChange()">Змінити</v-btn>
        </v-flex>
    </v-layout>
</template>
<script>
    import HttpService from "../../HttpService";

    export default {
        data: () => {
            return {
                treeNavigation: null,
                faculties: [],
                specialities: [],
                semesters: [],
                academicGroups: [],

                faculty: null,
                speciality: null,
                semester: null,
                academicGroup: null,

                isSelected: false,
                couldSelect: false,
            };
        },
        props: {
            navigationEndPoint: {
                type: String,
                default: "navigation/getNavRegisters"
            },
            withSpeciality: {
                type: Boolean,
                default: true,
            },
            withSemester: {
                type: Boolean,
                default: true,
            },
            withGroup: {
                type: Boolean,
                default: true,
            },
            selectedFaculty: {
                type: Object,
            },
            selectedSpeciality: {
                type: Object,
            },
            selectedSemester: {
                type: Object,
            },
            selectedAcademicGroup: {
                type: Object,
            }
        },
        methods: {
            onSelect() {
                this.isSelected = true;

                this.$emit("selectFaculty", this.faculty);
                this.$emit("selectSpeciality", this.speciality);
                this.$emit("selectSemester", this.semester);
                this.$emit("selectAcademicGroup", this.academicGroup);
            },
            onChange() {
                this.isSelected = false;
            }
        },
        watch: {
            selectedFacultyData: {
                handler: function(newData) {

                    if (newData.selectedFaculty && newData.faculties.length > 0) {
                        const facultyFound = this.faculties.find(f => f.id === newData.selectedFaculty.id);
                        this.faculty = facultyFound ? facultyFound : null;
                    }
                },
                immediate: true,
                deep: true,
            },
            selectedSpecialityData: {
                handler: function(newData) {
                    if (newData.selectedSpeciality && newData.specialities.length > 0) {
                        const specialityFound = this.specialities.find(f => f.id === newData.selectedSpeciality.id);
                        this.speciality = specialityFound ? specialityFound : null;
                    }
                },
                immediate: true,
                deep: true,
            },
            selectedSemesterData: {
                handler: function(newData) {
                    if (newData.selectedSemester && newData.semesters.length > 0) {
                        const semesterFound = this.semesters.find(f => f.id === newData.selectedSemester.id);
                        this.semester = semesterFound ? semesterFound : null;
                    }
                },
                immediate: true,
                deep: true,
            },
            selectedAcademicGroupData: {
                handler: function(newData) {
                    if (newData.selectedAcademicGroup && newData.academicGroups.length > 0) {
                        const academicGroupFound = this.academicGroups.find(f => f.id === newData.selectedAcademicGroup.id);
                        this.academicGroup = academicGroupFound ? academicGroupFound : null;
                    }
                },
                immediate: true,
                deep: true,
            },
            
            
            faculty: function (newFaculty, oldFaculty) {
                if (newFaculty) {
                    this.specialities = newFaculty.specialities;
                    
                    this.couldSelect = true;
                }
                
                if (newFaculty === undefined) {
                    this.faculty = null;
                }

                this.speciality = null;
                this.semester = null;
                this.academicGroup = null;
            },
            speciality: function (newSpeciality, oldSpeciality) {
                if (newSpeciality) {
                    if (this.withSemester) {
                        this.semesters = newSpeciality.semesters.map(s => {
                            s.name = `${s.yearNumber} курс (${s.semesterNumber} семестр)`;

                            return s;
                        });
                    }
                    
                    this.couldSelect = true;
                }

                if (newSpeciality === undefined) {
                    this.speciality = null;
                }

                this.semester = null;
                this.academicGroup = null;
            },
            semester: function (newSemester, oldSemesters) {
                if (newSemester) {
                    if (this.withGroup) {
                        this.academicGroups = newSemester.academicGroups.map(s => {
                            s.name = `${s.groupNumber} група`;

                            return s;
                        });
                    }
                    
                    this.couldSelect = true;
                }

                if (newSemester === undefined) {
                    this.semester = null;
                }

                this.academicGroup = null;
            },
            academicGroup: function (newAcademicGroup, oldAcademicGroup) {
                if (newAcademicGroup) {
                    this.couldSelect = true;
                }

                if (newAcademicGroup === undefined) {
                    this.academicGroup = null;
                }

            }
        },
        mounted() {
            HttpService.getInstance()
                .get(this.navigationEndPoint)
                .then(resp => {
                    this.treeNavigation = resp;

                    if (resp.length === 0) {
                        this.faculty = resp[0];
                    }

                    this.faculties = resp;

                })

        },
        computed: {
            selectedFacultyData() {
                const { selectedFaculty, faculties } = this;
                return {
                    selectedFaculty,
                    faculties
                }
            },
            selectedSpecialityData() {
                const { selectedSpeciality, specialities } = this;
                return {
                    selectedSpeciality,
                    specialities
                }
            },
            selectedSemesterData() {
                const { selectedSemester, semesters } = this;
                return {
                    selectedSemester,
                    semesters
                }
            },
            selectedAcademicGroupData() {
                const { selectedAcademicGroup, academicGroups } = this;
                return {
                    selectedAcademicGroup,
                    academicGroups
                }
            }
        }
    }
</script>