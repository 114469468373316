<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5">Статистика по університету</h2>
        <v-layout class="d-flex justify-center" v-if="loadingUniversity">
            <v-progress-circular
                :size="150"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-layout>
        <div class="mb-10 mt-2" v-if="!loadingUniversity">
            <div class="mb-2">
                <h4><span class="font-weight-light">Загальна кількість викладачів: </span> {{dataUniversity.countAllProfessors}}</h4>
                <h4><span class="font-weight-light">Загальна кількість студентів: </span> {{dataUniversity.countAllStudents}}</h4>
                <h4><span class="font-weight-light">Загальна кількість оцінок: </span> {{dataUniversity.countAllMarks}}</h4>
                <h4><span class="font-weight-light">Кількість оцінок на одного викладача: </span> {{dataUniversity.countMarksPerProfessor}}</h4>
            </div>
            <hr>
            <div class="mb-2 mt-2">
                <h4><span class="font-weight-light">Середній бал: </span> {{dataUniversity.averageMark.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал (студенти чоловічої статі): </span> {{dataUniversity.averageMarkMale.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал (студенти жіночої статі): </span> {{dataUniversity.averageMarkFemale.toFixed(2)}}</h4>
            </div>
            <hr>
            <div class="mb-2 mt-2">
                <h4><span class="font-weight-light">Середня кількість пропущених годин: </span> {{dataUniversity.averageAbsenceHrs.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середня кількість пропущених годин (студенти чоловічої статі): </span> {{dataUniversity.averageAbsenceHrsMale.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середня кількість пропущених годин (студенти жіночої статі): </span> {{dataUniversity.averageAbsenceHrsFemale.toFixed(2)}}</h4>
            </div>
            <hr>
            <div class="mb-2 mt-2">
                <h4><span class="font-weight-light">Середній бал за модульний контроль: </span> {{dataUniversity.averageModuleControlPoints.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал за модульний контроль (студенти чоловічої статі): </span> {{dataUniversity.averageModuleControlPointsMale.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал за модульний контроль (студенти жіночої статі): </span> {{dataUniversity.averageModuleControlPointsFemale.toFixed(2)}}</h4>
            </div>
            <hr>
            <div class="mb-2 mt-2">
                <h4><span class="font-weight-light">Середній бал за модуль: </span> {{dataUniversity.averageModuleTotalPoints.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал за модуль (студенти чоловічої статі): </span> {{dataUniversity.averageModuleTotalPointsMale.toFixed(2)}}</h4>
                <h4><span class="font-weight-light">Середній бал за модуль (студенти жіночої статі): </span> {{dataUniversity.averageModuleTotalPointsFemale.toFixed(2)}}</h4>
            </div>
        </div>

        <h2 class="mt-5 mb-2">Статистика по факультетах</h2>
        <v-data-table
            :headers="headers"
            :items="data"
            :loading="loadingFaculties"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.avgTime="{item}">
                {{item.avgTime.toFixed(2)}}
            </template>
            <template v-slot:item.avgMark="{item}">
                {{item.avgMark.toFixed(2)}}
            </template>
            <template v-slot:item.count5Percentage="{item}">
                {{item.count5Percentage.toFixed(2)}}% ({{item.count5}})
            </template>
            <template v-slot:item.count4Percentage="{item}">
                {{item.count4Percentage.toFixed(2)}}% ({{item.count4}})
            </template>
            <template v-slot:item.count3Percentage="{item}">
                {{item.count3Percentage.toFixed(2)}}% ({{item.count3}})
            </template>
            <template v-slot:item.count2Percentage="{item}">
                {{item.count2Percentage.toFixed(2)}}% ({{item.count2}})
            </template>
            <template v-slot:item.countAbsPercentage="{item}">
                {{item.countAbsPercentage.toFixed(2)}}% ({{item.countAbs}})
            </template>
            <template v-slot:item.countReworksPercentage="{item}">
                {{item.countReworksPercentage.toFixed(2)}}% ({{item.countReworks}})
            </template>
        </v-data-table>
        
    </v-container>
</template>

<script>
    import LineChart from '../../../common/statistics/LineChart';
    import StatisticsHeader from "../../../common/statistics/StatisticsHeader";
    import HttpService from "../../../../HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                headers: [
                    {text: 'Факультет', value: 'faculty.nameUk'},
                    {text: 'μ час, год', value: 'avgTime'},
                    {text: 'μ бал', value: 'avgMark'},
                    {text: '∑ студ.', value: 'countStudents'},
                    {text: '∑ оцінок', value: 'countMarks'},
                    {text: '∑ 5', value: 'count5Percentage'},
                    {text: '∑ 4', value: 'count4Percentage'},
                    {text: '∑ 3', value: 'count3Percentage'},
                    {text: '∑ 2', value: 'count2Percentage'},
                    {text: '∑ Нб', value: 'countAbsPercentage'},
                    {text: '∑ відпр.', value: 'countReworksPercentage'},
                ],
                loadingFaculties: false,
                loadingUniversity: false,
                
                dates: [],
                
                data: [],
                dataUniversity: {
                    countAllStudents: 0,
                    countAllProfessors: 0,
                    countAllMarks: 0,
                    countMarksPerProfessor: 0,
                    
                    averageMark: 0,
                    averageMarkMale: 0,
                    averageMarkFemale: 0,
                    
                    averageAbsenceHrs: 0,
                    averageAbsenceHrsMale: 0,
                    averageAbsenceHrsFemale: 0,

                    averageModuleControlPoints: 0,
                    averageModuleControlPointsMale: 0,
                    averageModuleControlPointsFemale: 0,
                    
                    averageModuleTotalPoints: 0,
                    averageModuleTotalPointsMale: 0,
                    averageModuleTotalPointsFemale: 0,
                },
            }
        },
        mounted() {
            this.getData();
            this.getDataUniversity();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
                this.getDataUniversity();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
                this.getDataUniversity(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loadingFaculties = true;
                HttpService.getInstance()
                    .get(`statisticsMarks/getByFacultyAll?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loadingFaculties = false;
                    })
                    .catch(e => {
                        
                    })
            },

            getDataUniversity(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loadingUniversity = true;
                HttpService.getInstance()
                    .get(`statisticsMarks/getAllUniversity?${queryParam}`)
                    .then(dataUniversity => {
                        this.dataUniversity = dataUniversity;

                        this.loadingUniversity = false;
                    })
                    .catch(e => {

                    })
            },
        }
    }
</script>