<template>
    <v-container grid-list-md text-center>
        <v-layout row wrap>
            <v-flex v-if="userIsStudent()" xs12 md6>
                <v-card>
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline mb-0">Інформація про студента</h3>
                        </div>
                    </v-card-title>
                    <v-card-text>
                       <div v-if="!loadingStudentInfo">
                           <h2 class="mb-4">{{studentInfo.surname}} {{studentInfo.name}} {{studentInfo.patronymic}}</h2>
                           <h4><span class="font-weight-light">Факультет:</span> {{studentInfo.faculty.nameUk}}</h4>
                           <h4><span class="font-weight-light">Спеціальність:</span> {{studentInfo.speciality.nameUk}}</h4>
                           <h4><span class="font-weight-light">Курс:</span> {{studentInfo.semester.yearNumber}}</h4>
                           <h4><span class="font-weight-light">Академічна група:</span> {{studentInfo.academicGroup.groupNumber}} група</h4>
                           <h4><span class="font-weight-light">Дата народження:</span> {{formatShortDate(studentInfo.birthday)}}</h4>
                       </div>
                        <v-progress-circular
                            v-if="loadingStudentInfo"
                            :size="150"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-flex>
            
            <v-flex xs12 md6>
                <v-card>
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline mb-0">Зміна паролю</h3>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <p>Ваш аккаунт є спільним для багатьох сервісів. Зміна паролю до Вашого аккаунту проводиться в
                            центральному авторизаційному провайдері.</p>
                        <p>Для зміни паролю натисніть кнопку нижче.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                type="button"
                                @click="navigateAuthService()"
                                :loading="loadingNavigate"
                                :disabled="loadingNavigate"
                        >Перейти до зміни паролю
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>

<!--            <v-flex xs12 md6>-->
<!--                <v-card>-->
<!--                    <v-card-title primary-title>-->
<!--                        <div>-->
<!--                            <h3 class="headline mb-0">Зміна логіну</h3>-->
<!--                        </div>-->
<!--                    </v-card-title>-->
<!--                    <v-card-text>-->
<!--                        <v-flex xs12>-->
<!--                            <v-text-field-->
<!--                                    class="username-text-field"-->
<!--                                    :error-messages="usernameErrors.username"-->
<!--                                    v-model="usernameForm.username"-->
<!--                                    solo-->
<!--                                    v-mask="['aaaaaaaaaa']"-->
<!--                                    prefix="adryan.barbe."-->
<!--                                    counter="10"-->
<!--                                    label="Логін"></v-text-field>-->
<!--                        </v-flex>-->
<!--                        <v-card-actions>-->
<!--                            <v-spacer></v-spacer>-->
<!--                            <v-btn-->
<!--                                    type="submit"-->
<!--                                    :loading="loadingSave"-->
<!--                                    :disabled="loadingSave"-->
<!--                            >Виконати запит-->
<!--                            </v-btn>-->
<!--                        </v-card-actions>-->
<!--                    </v-card-text>-->
<!--                </v-card>-->
<!--            </v-flex>-->

            <v-flex xs12 md6>
                <form @submit="submitFormChangePhoto" novalidate="true">
                <v-card>
                    <v-card-title primary-title>
                        <div>
                            <h3 class="headline mb-0">Зміна фото профіля</h3>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-flex xs12>
                            <image-uploader
                                    v-model="form.profilePictureUrl"
                                    :image-endpoint="`${$config.apiEndpoint}/file/get`"
                            />
                        </v-flex>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    type="submit"
                                    :loading="loadingSave"
                                    :disabled="loadingSave"
                            >Зберегти
                            </v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
                </form>
            </v-flex>

        </v-layout>
    </v-container>
</template>

<script>
    import ImageUploader from "../../common/ImageUploader";
    import HttpService from "../../../HttpService";
    import {rolesEnum} from "@/constants/rolesEnum";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        components: {
            ImageUploader,
        },

        data: () => ({
            loadingNavigate: false,
            loadingSave: false,
            loadingStudentInfo: false,

            titleImageUrl: null,

            usernameForm: {
                username: null,
            },
            usernameErrors: {
                username: []
            },
            
            form: {
                profilePictureUrl: null
            },
            
            studentInfo: {
                id: null,
                surname: null,
                name: null,
                patronymic: null,
                birthday: null,
                faculty: {
                    id: null,
                    nameUk: null,
                    nameEn: null,
                },
                semester: {
                    id: null,
                    yearNumber: null,
                },
                speciality: {
                    id: null,
                    nameUk: null,
                    nameEn: null,
                },
                academicGroup: {
                    id: null,
                    groupNumber: null,
                },
            },

            formatShortDate,
        }),
        mounted() {
            HttpService.getInstance()
                .get("profile/profilePicture")
                .then(resp => {
                    if (resp) {
                        this.form.profilePictureUrl = resp.profilePictureUrl;
                    }
                });
            
            if (this.userIsStudent()) {
                this.loadingStudentInfo = true;
                HttpService.getInstance()
                    .get("profile/studentInfo")
                    .then(resp => {
                        if (resp) {
                            this.studentInfo = resp;
                            this.loadingStudentInfo = false;
                        }
                    });
            }
        },
        methods: {
            navigateAuthService() {
                this.loadingNavigate = true;
                const currentUrl = window.location.href;
                
                const url = `${this.$config.oidc.authority}/Account/ChangePassword`;

                const win = window.open(url, 'name','height=800,width=960');
                this.loadingNavigate = false;
                win.focus();
            },
            submitFormChangePhoto(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("profile/profilePicture", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                    });
            },
            userIsStudent() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.student, rolesEnum.admin]);
            },
        }
    }
</script>
<style lang="scss">
    .username-text-field {
        & .v-text-field__prefix {
            padding-right: 1px;
        }
    }
</style>