<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex v-if="showModuleBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'educationalPlan', 
                            params: {department: $route.params.department, subject: $route.params.subject}}" 
                           color="primary"
                           class="mb-5 btn-go-back px-3"
                    ><v-icon>keyboard_backspace</v-icon> Перейти до списку модулів</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Модулі</v-subheader>
                            <navigation-list-template :items="modules"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.module" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть модуль
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <lectures-index :key="$route.fullPath" v-if="$route.params.module"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import LecturesIndex from "../../components/moderator/lectures/LecturesIndex";
    import HttpService from "../../HttpService";

    export default {
        components: {
            NavigationListTemplate,
            LecturesIndex
        },
        data: () => ({
            modules: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`module/getAll/${this.$route.params.department}/${this.$route.params.subject}`)
                .then(resp => {
                    this.modules = resp.map(module => {
                        module.name = `${module.orderBy}. ${module.nameUk}`;
                        module.route = {
                            name: 'lecturesPlan',
                            params: {
                                department: this.$route.params.department,
                                subject: this.$route.params.subject,
                                module: module.id
                            }
                        };

                        return module;
                    });
                });
        },
        methods: {
            showModuleBlock() {
                return this.$route.name === "lecturesPlan" && this.$route.params.department && this.$route.params.subject;
            },

        },
    }
</script>

<style scoped lang="scss">
    .btn-go-back {
        padding: 0 12px !important;
    }
</style>