<template>
    <v-card>
        <v-card-title class="title">Видалення оцінок поновлених студентів</v-card-title>
        <v-card-text>
            <div>Ви впевнені, що хочете видалити видалити оцінки поновлених студентів? </div>
            
            <div>
                <v-container fluid>
                    <v-row :key="index" v-for="(student, index) in studentsForFix.students">
                        <v-col cols="12">
                            <v-switch v-model="student.selected" :label="`${student.surname} ${student.name} ${student.patronymic}`"></v-switch>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            
            <div class="mt-3 text-gray">У даному випадку будуть видалені всі оцінки студента з даного модлуя до початку нинішнього навчального року.</div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldClose')"
                    :disabled="loadingDelete"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="removeItem"
                    :loading="loadingDelete"
                    :disabled="loadingDelete || !studentsForFixSelected.length"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loadingDelete: false,
                
                studentsForFix: [],
                studentsForFixSelected: [],
            };
        },
        props: {
            moduleId: {
                type: Number,
            }
        },
        mounted() {
            const groupId = this.$route.params.group;
            const moduleId = this.moduleId;
            
            HttpService.getInstance()
            .get(`teacher/teacherMarks/getInfoFixPastModuleMarks/${groupId}/${moduleId}`)
            .then(resp => {
                this.studentsForFix = resp;
            });
        },
        watch: {
          "studentsForFix.students": {
              handler: function(newVal, oldVal) {
                  this.studentsForFixSelected = newVal.filter(s => s.selected);
              },
              deep: true,
          },
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;
                
                const studentsForFixSave = {...this.studentsForFix, students: this.studentsForFixSelected};

                HttpService.getInstance()
                    .post(`teacher/teacherMarks/saveFixPastModuleMarks`, studentsForFixSave)
                    .then(resp => {
                        this.loadingDelete = false;

                        this.$emit("removed");
                    });
            }
        }
    }
</script>