<template>
    <v-layout row wrap xs12>
        <v-flex xs3>
            <v-select
                    :items="specialities"
                    v-model="form.speciality"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Спеціальність"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-select
                    :items="faculties"
                    v-model="form.faculty"
                    item-text="nameUk"
                    item-value="id"
                    return-object
                    label="Факультет"
                    :disabled="form.speciality === null"
            ></v-select>
        </v-flex>
        <v-flex xs3>
            <v-select
                    :items="finalExamNames"
                    v-model="form.finalExamName"
                    item-text="name"
                    item-value="id"
                    return-object
                    label="Назва іспиту"
                    :disabled="form.faculty === null"
            ></v-select>
        </v-flex>
        <v-flex xs3 v-if="form.finalExamName !== null">
            <v-btn @click="getRangeList()" :disabled="loading" color="primary">Переглянути список</v-btn>
        </v-flex>
        <v-flex xs12>
            <v-btn @click="printDialog = true" v-if="form.finalExamName !== null">
                <v-icon class="mr-2">print</v-icon>Роздрукувати протокол ранжування</v-btn>
        </v-flex>
        <v-flex xs12>
            <v-alert type="info">
                Список та розподіл оцінок ECTS виконаний автоматично та при потребі можуть бути внесені зміни в ньому. 
                В будь-якому випадку, Вам НЕОБХІДНО зберегти результати ранжування для формування подальших документів.
            </v-alert>
        </v-flex>
        <v-flex xs12>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">ПІП студента</th>
                        <th class="text-left">Академічна група</th>
                        <th class="text-left">Оцінка класична</th>
                        <th class="text-left">Кількість балів</th>
                        <th class="text-left">ECTS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in rangeList" :key="item.name">
                        <td>
                            {{ item.student.surname }} {{ item.student.name }} {{ item.student.patronymic }}
                        </td>
                        <td>{{item.student.academicGroup.groupNumber}} група</td>
                        <td>{{ item.totalClassicValue }}</td>
                        <td>{{ item.totalValue }}</td>
                        <td>
                            <v-select
                                    label="Виберіть оцінку ECTS"
                                    v-model="item.totalAbc"
                                    :items="selectEctsItems"
                            ></v-select>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-flex>
        <v-flex xs12>
            <v-btn v-if="rangeList.length > 0" 
                   :disabled="loading" 
                   @click="saveRangeList()"
                   large 
                   class="red" 
                   dark>Зберегти ранжування</v-btn>
        </v-flex>
        
        <v-dialog v-model="printDialog" max-width="1300px">
            <print-ranging-protocol
                    v-if="printDialog"
                    :faculty-id="form.faculty.id"
                    :speciality-id="form.speciality.id"
                    :final-exam-name-id="form.finalExamName.id"
                    @close="printDialog = false"
            ></print-ranging-protocol>
        </v-dialog>
    </v-layout>
</template>
<script>
    import HttpService from "../../../../HttpService";
    import PrintRangingProtocol from "./PrintRangingProtocol";

    export default {
        components: {
          PrintRangingProtocol,  
        },
        data: () => ({
            specialities: [],
            faculties: [],
            finalExamNames: [],

            form: {
                speciality: null,
                faculty: null,
                finalExamName: null,
            },
            
            rangeList: [],
            loading: false,
            
            selectEctsItems: ["A", "B", "C", "D", "E"],
            
            printDialog: false,
        }),
        watch: {
            "form.speciality": function (newSpec, oldSpec) {
                if (newSpec !== null) {
                    HttpService.getInstance()
                        .get(`faculties/getAllBySpeciality/${newSpec.id}`)
                        .then(resp => {
                            this.faculties = resp;
                        })
                }
            },
            "form.faculty": function (newFac, oldFac) {
                if (newFac !== null) {
                    HttpService.getInstance()
                        .get(`FinalExamAdmin/getFinalExamNames/${this.form.speciality.id}`)
                        .then(resp => {
                            this.finalExamNames = resp;
                        })
                }
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("specialities/getAllWithFinalExam")
                .then(resp => {
                    this.specialities = resp;
                })
        },
        methods: {
            getRangeList() {
                this.loading = true;
                HttpService.getInstance()
                .get(`finalExamAdminReports/getRangeStudents/${this.form.faculty.id}/${this.form.speciality.id}/${this.form.finalExamName.id}`)
                .then(resp => {
                    this.loading = false;
                    this.rangeList = resp;
                });
            },

            saveRangeList() {
                this.loading = true;
                HttpService.getInstance()
                    .post(`finalExamAdminReports/saveRangeStudents/${this.form.faculty.id}/${this.form.speciality.id}/${this.form.finalExamName.id}`, this.rangeList)
                    .then(resp => {
                        this.loading = false;
                        this.rangeList = resp;
                    });
            }
        }
    }
</script>