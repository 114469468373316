<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.username" v-model="form.username"
                                          label="Логін"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.email" v-model="form.email"
                                          label="E-mail"></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-switch
                                    label="Може логінитися через OAuth"
                                    v-model="form.canOAuthLogin"
                                    :error-messages="errors.canOAuthLogin"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.googleId" v-model="form.googleId"
                                          label="GoogleId"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-switch
                                    label="Змінити пароль"
                                    v-model="form.passwordShouldChange"
                                    :error-messages="errors.passwordShouldChange"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12 v-if="form.passwordShouldChange">
                            <v-text-field :error-messages="errors.password" 
                                          @input="form.password = $event"
                                          label="Новий пароль"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-radio-group :error-messages="errors.isBlocked" v-model="form.isBlocked" :mandatory="false">
                                <v-radio color="primary" label="Не заблоковано" :value="false"></v-radio>
                                <v-radio color="red darken-3" label="Заблоковано" :value="true"></v-radio>
                            </v-radio-group>
                        </v-flex>
<!--                        <v-flex xs12 md12>-->
<!--                            <v-select-->
<!--                                    :items="roles"-->
<!--                                    :error-messages="errors.roles"-->
<!--                                    v-model="form.roles"-->
<!--                                    item-text="name"-->
<!--                                    item-value="id"-->
<!--                                    return-object-->
<!--                                    multiple-->
<!--                                    chips-->
<!--                                    label="Ролі"-->
<!--                            ></v-select>-->
<!--                        </v-flex>-->
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    username: null,
                    email: null,
                    passwordShouldChange: null,
                    isBlocked: null,
                    roles: null,
                    canOAuthLogin: false,
                    googleId: null,
                },
                errors: {
                    username: [],
                    email: [],
                    passwordShouldChange: [],
                    isBlocked: [],
                    roles: [],
                    canOAuthLogin: [],
                    googleId: [],
                },
                
                roles: [],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("role")
                .then(resp => {
                    this.roles = resp;
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`userManagement/getOne/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("userManagement", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>