<template>
    <v-card tile>
        <v-toolbar dark color="orange">
            <v-btn icon
                   dark
                   tabs
                   @click.native="$emit('shouldCloseAddEdit')"
                   class="ml-3"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Пропуски та незараховані заняття для {{student.surname}} {{student.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs5>
                    <date-picker
                            v-model="filter.dateFrom"
                            label="Дата заняття (від)"
                    />
                </v-flex>
                <v-flex xs5>
                    <date-picker
                            v-model="filter.dateTo"
                            label="Дата заняття (до)"
                    />
                </v-flex>
                <v-flex xs2 class="justify-end">
                    <v-btn :loading="loading" color="primary" @click="getItems()">Застосувати</v-btn>
                </v-flex>
            </v-layout>

            <admission-types-legend/>

            <v-flex xs12>
                <div style="overflow-y: auto; height: calc(100vh - 280px);" class="px-2">
                    <v-data-table
                            :headers="headers"
                            :items="reworkItems"
                            :server-items-length="reworkItems.length"
                            :loading="loading"
                            class="elevation-3"
                            hide-default-footer
                    >
                        <template v-slot:item.attachment="{item}">
                            <div v-if="!isEmpty(item.attachmentFileName)" class="d-flex justify-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on" icon @click="onGetAttachment(item)">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Переглянути додаток (довідка)</span>
                                </v-tooltip>
                            </div>

                            <div class="text-center" v-else>-</div>
                        </template>

                        <template v-slot:item.actions="{item}">
                            <v-tooltip
                                    v-if="item.couldRework"
                                    bottom
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="openReworkDialog(item)" v-on="on" color="primary" fab small dark>
                                        <v-icon>done_outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Відпрацювати заняття</span>
                            </v-tooltip>
                        </template>
                        
                        <template v-slot:item.payable="{item}">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-if="item.isSubjectPayable" color="red" v-on="on">
                                        attach_money
                                    </v-icon>
                                </template>
                                <span>Оплачувані допуски</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-if="!item.isSubjectPayable" color="green" v-on="on">strikethrough_s</v-icon>
                                </template>
                                <span>НЕоплачувані допуски</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:item.subject="{item}">
                            {{ item.subject.nameUk }}
                        </template>
                        
                        <template v-slot:item.topic="{item}">
                            {{ `${item.topic.orderNumber}. ${item.topic.nameUk}`}}
                        </template>
                        
                        <template v-slot:item.classDate="{item}">
                            {{ formatShortDate(item.classDate) }}
                        </template>
                        
                        <template v-slot:item.mark="{item}">
                            <mark-unsatisfactory-icons :value="item.value"/>
                        </template>
                        
                        <template v-slot:item.admission="{item}">
                            <admission-details v-if="item.topicAbsence != null" :topicAbsence="item.topicAbsence"/>
                        </template>
                        
                    </v-data-table>
                </div>
            </v-flex>
        </v-container>

        <v-dialog v-model="confirmRework" max-width="800" scrollable>
            <rework-dialog
                    v-if="confirmRework"
                    @shouldCloseSaveConfirm="confirmRework = false"
                    @saved="savedAdmission"
                    :student="student"
                    :rework-item="selectedReworkItem"
            >
            </rework-dialog>
        </v-dialog>

        <v-dialog v-model="dialogUploadAttachmentView" max-width="1000px">
            <show-attachment
                    v-if="dialogUploadAttachmentView"
                    :item="dialogUploadItemView"
                    @shouldClose="dialogUploadAttachmentView = false"
            ></show-attachment>
        </v-dialog>
        
    </v-card>
</template>
<script>
    import moment from "moment";
    import HttpService from "../../../HttpService";
    import DatePicker from "../../../components/common/DatePicker";
    import AdmissionDetails from "../../../components/common/rework-admissions/AdmissionDetails";
    import AdmissionTypesLegend from "../../common/rework-admissions/AdmissionTypesLegend";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import AdmissionProlongateConfirm from "./AdmissionProlongateConfirm";
    import {markValuesEnum} from "../../../constants/markValuesEnum";
    import ReworkDialog from "./ReworkDialog";
    import MarkUnsatisfactoryIcons from "./MarkUnsatisfactoryIcons";
    import {rolesEnum} from "../../../constants/rolesEnum";
    import isEmpty from "lodash/isEmpty";
    import ShowAttachment from "../../student/academicAbsents/ShowAttachment";

    export default {
        components: {
            DatePicker,
            AdmissionDetails,
            AdmissionTypesLegend,
            AdmissionProlongateConfirm,
            ReworkDialog,
            MarkUnsatisfactoryIcons,
            ShowAttachment
        },
        data: () => ({
            formatShortDate,
            markValuesEnum,
            loading: false,
            loadingSave: false,
            reworkItems: [],
            
            selectedReworkItem: null,
            
            filter: {
                dateFrom: moment().subtract(6, "years"),
                dateTo: new Date(),
            },
            headers: [
                {text: 'Дії', value: "actions", sortable: false},
                {text: 'Оплачуваний', value: "payable", sortable: false},
                {text: 'Вкладення', value: 'attachment', sortable: false},
                {text: 'Дисципліна', value: "subject", sortable: false},
                {text: 'Тема', value: "topic", sortable: false},
                {text: 'Дата заняття', value: "classDate", sortable: false},
                {text: 'Оцінка', value: "mark", sortable: false},
                {text: 'Допуск', value: "admission", sortable: false},
            ],
            confirmRework: false,
            isEmpty,
            
            dialogUploadAttachmentView: false,
            dialogUploadItemView: null,
        }),
        props: {
            student: Object,
        },
        mounted() {
            this.getItems();
        },
        methods: {
            openReworkDialog(reworkItem) {
                this.confirmRework = true; 
                this.selectedReworkItem = reworkItem;
            },
            getItems() {
                this.loading = true;

                const dateFromQuery = moment(this.filter.dateFrom).format("YYYY-MM-DD");
                const dateToQuery = moment(this.filter.dateTo).format("YYYY-MM-DD");
                
                let url = "";
                
                if (this.userIsTeacher() || (this.userIsAdminOrEdudept() && this.$route.name === "reworkMarksRoot")) {
                    url = `reworks/getReworks/${this.student.id}/${this.$route.params.department}/${this.$route.params.subject}?dateFrom=${dateFromQuery}&dateTo=${dateToQuery}`;
                }
                
                if (this.userIsAdminOrEdudept() && this.$route.name !== "reworkMarksRoot") {
                    url = `reworks/getReworks/${this.student.id}?dateFrom=${dateFromQuery}&dateTo=${dateToQuery}`;
                }

                HttpService.getInstance()
                    .get(url)
                    .then(resp => {
                        this.reworkItems = resp;
                        this.loading = false;
                    });
            },
            savedAdmission() {
                this.getItems();
                this.confirmRework = false;
            },
            userIsTeacher() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.professor]);
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },

            onGetAttachment(item) {
                this.dialogUploadItemView = item;
                this.dialogUploadAttachmentView = true;
            }
        },
    }
</script>