<template>
    <div>
        <vue-headful title="ePlato"/>
        <teacher-exam-settings></teacher-exam-settings>
    </div>
</template>
<script>
    import TeacherExamSettings from "../../components/finalExam/teacherDashboard/TeacherExamSettings";

    export default {
        components: {
            TeacherExamSettings,
        }
    }
</script>