import EmptyView from "../views/EmptyView";
import ManagementSpaRootView from '../components/stepAdminSpa/management/ManagementSpaRootView';
import StepAdminSpaRootView from "../views/stepAdminSpa/StepAdminSpaRootView";
import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/stepAdminSpaModules',
         name: 'stepAdminSpaRoot',
         component: StepAdminSpaRootView,
         children: [
             {
                 path: '',
                 redirect: {name: 'stepAdminSpa'},
             },
             {
                 path: '/stepAdminSpaModules/dashboard',
                 name: 'stepAdminSpa',
                 component: EmptyView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                 children: [
                     {
                         path: '/stepAdminSpaModules/management/:subject/:faculty?/:speciality?/:semester?',
                         name: 'stepAdminSpaManagement',
                         component: ManagementSpaRootView,
                         beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                     },
                 ]
             },
             
         ],
     },
 ];
}