<template>
    <div class="expander-wrapper">
        <v-layout justify-center class="ml-2">
            <v-flex xs8>
                <v-btn 
                        v-if="studentsSelected.length !== 0" 
                        small 
                        color="blue" 
                        dark
                        @click="dialogStartQuiz = true"
                >Розпочати тестування</v-btn>
                <v-btn
                        v-if="studentsSelected.length !== 0"
                        small
                        color="warning"
                        dark
                        @click="dialogResultsQuiz = true"
                >Результати тестування</v-btn>
            </v-flex>
            <v-flex xs4></v-flex>
        </v-layout>
        
        <div class="ma-2">
            <table class="table">
                <thead>
                <th>
                    <v-checkbox
                            class="mt-0"
                            v-model="selectAllStudents"
                            label=""
                            hide-details
                    ></v-checkbox>
                </th>
                <th>ПІП студента</th>
                </thead>
                <tbody>
                <tr v-for="student in students">
                    <td>
                        <v-checkbox
                                class="student-checkbox"
                                v-model="studentsSelected"
                                :value="student"
                                hide-details
                        ></v-checkbox>
                    </td>
                    <td> {{student.surname}} {{student.name}} {{student.patronymic}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <v-dialog v-model="dialogStartQuiz" max-width="700" persistent>
            <confirm-quiz-group
                    v-if="dialogStartQuiz"
                    :selected-students="studentsSelected"
                    :start-group-quiz-url="startGroupQuizUrl"
                    @shouldClose="dialogStartQuiz = false"
                    @started="started"
            ></confirm-quiz-group>
        </v-dialog>

        <v-dialog v-model="dialogResultsQuiz" max-width="1700">
            <results-quiz-group
                    v-if="dialogResultsQuiz"
                    :selected-students="studentsSelected"
                    :group-quiz-results-url="groupQuizResultsUrl"
                    @shouldClose="dialogResultsQuiz = false"
                    :is-kti="isKti"
            ></results-quiz-group>
        </v-dialog>
        
    </div>
</template>

<script>
    import HttpService from "../../../HttpService";
    import ConfirmQuizGroup from "./ConfirmQuizGroup";
    import ResultsQuizGroup from "./ResultsQuizGroup";

    export default {
        components: {
            ConfirmQuizGroup,
            ResultsQuizGroup
        },
        data: () => ({
            students: [],
            studentsSelected: [],
            selectAllStudents: false,
            dialogStartQuiz: false,
            dialogResultsQuiz: false,
        }),
        props: {
            academicGroupId: Number,
            
            startGroupQuizUrl: String,
            groupQuizResultsUrl: String,
            isKti: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            selectAll() {
                this.studentsSelected = this.students;
            },
            started() {

            }
        },
        mounted() {
            this.studentsSelected = [];
            this.students = [];
            
            HttpService.getInstance()
                .get(`students/getByAcademicGroup/${this.academicGroupId}`)
                .then(resp => {
                    this.students = resp;
                });
        },
        watch: {
            selectAllStudents: function (newVal, oldVal) {
                if (newVal) {
                    this.selectAll();
                }
                else {
                    this.studentsSelected = [];
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .student-checkbox {
        margin-top: 0px;
    }
    
    .expander-wrapper {
        background: #FFF9C4;
    }
</style>