<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <deans-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import DeansIndex from "../../../../components/admin/PedagicalStaff/Deans/DeansIndex";
    
    export default  {
        components: {
            DeansIndex,
        }
    }
</script>