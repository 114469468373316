export const fileUploadEnum = {
    applicantsImages: "applicants-images",
    profileImages: "profile-images",
    announcementImages: "announcement-images",
    reworkAdmissions: "rework-admissions",
    finalExamScreenShot: "final-exam-screenshot",
    reworkQuiz: "rework-quiz",
    eAristoQuestions: "e-aristo-questions",
};

export default fileUploadEnum;