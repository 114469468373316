<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <poll-pools-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import PollPoolsIndex from "../../../components/admin/MainDashboard/PollPools/PollPoolsIndex";
    export default  {
        components: {
            PollPoolsIndex,
        }
    }
</script>