<template>
    <v-container v-if="pagerSlots.length" class="register-container marks-list-pager">
        <v-layout justify-center>
            <div>
                <v-btn
                        :disabled="currentPage - 1 < 0"
                        icon raised small
                        color="primary"
                        @click="$emit('pageChange', currentPage - 1)"
                >
                    <v-icon>chevron_left</v-icon>
                </v-btn>
                <v-btn @click="$emit('pageChange', pagerSlot)"
                       v-for="(pagerSlot, index) in pagerSlots"
                       :key="pagerSlot"
                       raised
                       small
                       :color="(pagerSlot === currentPage) ? 'primary' : 'white'">
                    {{(pagerSlot * marksToLoad) + 1}} - {{getPagerToValue(pagerSlot)}}
                </v-btn>
                <v-btn
                        :disabled="(currentPage + 1) >= (marksCount / marksToLoad)"
                        icon raised small
                        color="primary"
                        @click="$emit('pageChange', currentPage + 1)"
                >
                    <v-icon>chevron_right</v-icon>
                </v-btn>
            </div>
        </v-layout>
    </v-container>
</template>
<script>
    export default {
        props: {
            marksCount: {
                type: Number,
                default: 0
            },
            marksToLoad: {
                type: Number,
                default: 0
            },
            currentPage: {
                type: Number,
                default: 0,
            },
        },
        data: () => ({
            pagerSlots: [],
        }),
        mounted() {
            this.generatePagerValues();
        },
        methods: {
            getPagerToValue(pagerSlot) {
                const pagerToValue = (pagerSlot * this.marksToLoad) + this.marksToLoad;

                if (pagerToValue < this.marksCount) {
                    return pagerToValue;
                } else {
                    return this.marksCount;
                }
            },
            generatePagerValues: function () {
                const iteratorMax = Math.ceil(this.marksCount / this.marksToLoad);

                for (let iteratorValue = 0; iteratorValue < iteratorMax; iteratorValue++) {
                    this.pagerSlots.push(iteratorValue);
                }
            }
        }
    }
</script>
<style lang="scss">
    .marks-list-pager {
        height: 40px;
        margin-top: 10px;
        
        .v-btn {
            margin-top: 5px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
</style>