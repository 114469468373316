<template>
    <div>
        <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
        >
            <template v-slot:activator="{ on }">
                <v-btn v-if="isStudent" text color="orange lighten-1" v-on="on">Привітати</v-btn>
                <v-btn v-if="!isStudent" text color="primary" v-on="on">Привітати</v-btn>
            </template>
            <v-card>
                <v-card-title class="text--darken-2 grey--text">Привітання з Днем народження</v-card-title>
                <v-card-text>
                    <v-textarea
                            solo
                            name="input-7-4"
                            v-model="congratText"
                            label="Текст привітання"
                    ></v-textarea>
                    
                    <v-btn @click="sendCongrat()" v-if="isStudent" dark color="orange lighten-1">Відправити привітання</v-btn>
                    <v-btn @click="sendCongrat()" v-if="!isStudent" color="primary">Відправити привітання</v-btn>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";

    export default {
        props: {
            isStudent: {
                type: Boolean,
                default: false,
            },
            userItem: {
                type: Object,
                default: () => {return null;},
            }
        },
        data: () => ({
            menu: false,
            congratText: "",
        }),
        methods: {
            formatStudentCongrat(userItem) {
                let text = "";
                
                if (userItem.gender !== null && userItem.gender !== undefined) {
                    if (userItem.gender === gendersEnum.male) {
                        text += `Шановний `;
                    }

                    if (userItem.gender === gendersEnum.female) {
                        text += `Шановна `;
                    }
                    
                    text += `${userItem.name} ${userItem.patronymic}! `;
                }
                
                text += `Вітаю з Днем народження!`;
                
                return text;
            },
            formatProfessorCongrat(userItem) {
                let text = "";

                if (userItem.gender !== null && userItem.gender !== undefined) {
                    if (userItem.gender === gendersEnum.male) {
                        text += `Вельмишановний `;
                    }

                    if (userItem.gender === gendersEnum.female) {
                        text += `Вельмишановна `;
                    }

                    text += `${userItem.name} ${userItem.patronymic}! `;
                }

                text += `Щиросердечно вітаю Вас з Днем народження!`;

                return text;
            },
            sendCongrat() {
                alert("Цей функціонал ще в розробці! This business logic is still in development!");
            }
        },
        watch: {
            "userItem": {
                handler: function(newVal, oldVal) {
                    if (newVal !== null && this.isStudent) {
                        this.congratText = this.formatStudentCongrat(newVal);
                    }
                    if (newVal !== null && !this.isStudent) {
                        this.congratText = this.formatProfessorCongrat(newVal);
                    }
                },
                deep: true,
                immediate: true,
            }
        }
    }
</script>