<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування позиції' : 'Додавання позиції'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs6 class="mb-2">
                            <worktype-points 
                                    :work-type-is-english="workTypeIsEnglish"
                                    :work-type-points="workTypePoints"
                                    :rating-settings="ratingSettings"
                            ></worktype-points>
                            <v-divider/>
                        </v-flex>
                        <v-flex xs12 v-if="workTypeIsEnumerable">
                            <v-alert dense
                                     outlined
                                     type="info">Дана позиція є калькульованою. Тобто для однотипних завдань (наприклад, розробка тестів)
                                Ви можете вказати в назві їхню кількість, а в графі "Кількість балів" – загальна сума балів враховуючи
                                їхню кількість. Для даної позиції система дозволить внести більшу кількість балів, аніж вказано вище.</v-alert>
                        </v-flex>
                        <v-flex xs12>
                            <div class="subtitle-1">Назва та зміст позиції:</div>
                            <wysiwyg :options="getEditorConfig()" v-model="form.workName"></wysiwyg>
                            <div v-if="errors.workName != null" class="red--text caption">{{errors.workName}}</div>
                        </v-flex>
<!--                        <v-flex xs12>-->
<!--                            <div class="subtitle-1">Калькулятор для видів роботи, які містять множинну кількість позицій (наприклад, тести)</div>-->
<!--                            <v-layout wrap>-->
<!--                            <v-flex xs6>-->
<!--                                <v-text-field :error-messages="errors.point"-->
<!--                                              v-model="form.point"-->
<!--                                              @keypress="isNumber($event, 2)"-->
<!--                                              :label="`Кількість україномовних позицій (x ${ratingSettings.ukrainianRatio})`"-->
<!--                                ></v-text-field>-->
<!--                            </v-flex>-->
<!--                            <v-flex xs6>-->
<!--                                <v-text-field :error-messages="errors.point"-->
<!--                                              v-model="form.point"-->
<!--                                              @keypress="isNumber($event, 2)"-->
<!--                                              :label="`Кількість ЗОВНІШНІХ україномовних позицій (х${ratingSettings.externalWorkRatio})`"-->
<!--                                ></v-text-field>-->
<!--                            </v-flex>-->
<!--                            </v-layout>-->
<!--                            <v-layout wrap>-->
<!--                                <v-flex xs6>-->
<!--                                    <v-text-field :error-messages="errors.point"-->
<!--                                                  v-model="form.point"-->
<!--                                                  @keypress="isNumber($event, 2)"-->
<!--                                                  :label="`Кількість англомовних позицій (x${ratingSettings.englishRatio})`"-->
<!--                                    ></v-text-field>-->
<!--                                </v-flex>-->
<!--                                <v-flex xs6>-->
<!--                                    <v-text-field :error-messages="errors.point"-->
<!--                                                  v-model="form.point"-->
<!--                                                  @keypress="isNumber($event, 2)"-->
<!--                                                  :label="`Кількість ЗОВНІШНІХ англомовних позицій (х${ratingSettings.externalWorkRatio * ratingSettings.englishRatio})`"-->
<!--                                    ></v-text-field>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                        </v-flex>-->
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.point"
                                          v-model="form.point"
                                          @keypress="isNumber($event, 2)"
                                          label="Кількість балів"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="form.isMain !== false && !isDepartmentMode" >
                            <v-alert
                                    dense
                                    outlined
                                    type="info"
                            >
                                У випадку, якщо Ви виконували даний тип роботи (статтю) у співавтрстві з іншими колегами з Вашої кафедри,
                                просимо вказати співавторів та їхні фракції балу за даний вид роботи. Вказані співавтори зможуть побачити
                                даний вид роботи в своєму особистому кабінеті, проте не зможуть вносити зміни до нього.
                            </v-alert>

                            <v-alert
                                    dense
                                    outlined
                                    type="error"
                            >
                                Немає потреби вносити повторно позиції, які були виконані іншим основним автором. Попросіть основного автора внести
                                дану позицію і вона автоматично появиться у Вас!
                            </v-alert>
                        </v-flex>
                        <v-flex xs12 v-if="form.isMain !== false && !isDepartmentMode" >
                            <v-card class="mb-4" elevation="7" :key="index" v-for="(professorPoint, index) in form.anotherProfessorItems">
                                <v-layout wrap class="px-3">
                                   <v-flex xs7>
                                       <v-select
                                               :items="professors"
                                               :error-messages="getAnotherProfError(index, 'professor')"
                                               v-model="professorPoint.professor"
                                               item-text="fullName"
                                               item-value="id"
                                               return-object
                                               label="Викладач кафедри"
                                       ></v-select>
                                   </v-flex> 
                                   <v-flex xs4>
                                       <v-text-field :error-messages="getAnotherProfError(index, 'point')"
                                                     v-model="professorPoint.point"
                                                     @keypress="isNumber($event, 2)"
                                                     label="Кількість балів"
                                       ></v-text-field>
                                   </v-flex> 
                                    <v-flex xs1 class="d-flex align-center justify-end">
                                        <v-btn icon color="red" @click="removeProfessorPoint(index)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                            <div v-if="errors.anotherProfessorItems != null" class="red--text caption">{{errors.anotherProfessorItems}}</div>
                            
                            <v-btn
                                   class="mt-6" color="primary" text 
                                   @click="addProfessorPoint">Додати співпавтора/співучасника</v-btn>
                        </v-flex>
                        <v-flex xs12 class="mt-4">
                            <v-divider class="mb-5"/>
                            <div class="subtitle-1">Для підтвердження позиції Ви можете надати гіперпосилання на
                                джерело:
                            </div>
                            <v-text-field :error-messages="errors.workUrl"
                                          v-model="form.workUrl"
                                          label="Гіперпосилання"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <div class="title-bold">- АБО -</div>
                        </v-flex>
                        <v-flex xs12>
                            <div class="subtitle-1">завантажте файли підтвердження (до 5 файлів, максимум 10 Мб):</div>
                            <VueFileAgent
                                    :uploadUrl="`${configuration.apiEndpoint}/file/upload/ratingFile`"
                                    :uploadHeaders="{
                                       'Cache-Control': '',
                                       'Authorization': `Bearer ${getAccessToken()}`
                                    }"
                                    :multiple="true"
                                    :deletable="true"
                                    :linkable="true"
                                    :meta="true"
                                    :accept="'image/*,.pdf,'"
                                    :maxSize="'10MB'"
                                    :maxFiles="5"
                                    :helpText="'Виберіть файли'"
                                    :errorText="{
                                        type: 'Виберіть, будь ласка, файли формату JPEG, JPG, PNG або PDF',
                                        size: 'Ви вибрали надто великий файл!',
                                      }"
                                    :thumbnailSize="120"
                                    :theme="'list'"
                                    @select="filesSelected($event)"
                                    @upload="filesUploaded($event)"
                                    v-model="form.ratingFiles"
                            ></VueFileAgent>

                            <div v-if="errors.ratingFiles != null" class="red--text caption">{{errors.ratingFiles}}</div>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-alert
                        v-if="form.isMain === false"
                        dense
                        outlined
                        type="warning"
                >
                    Редагування неможливе, так як дана позиція належить до іншої основної позиції.
                    Редагування можливо проводити лише з аккаунта автора основної позиції.
                </v-alert>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        v-if="form.isMain !== false"
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import {isNumber} from "../../../helpers/numberHelper";
    import {AppConfig} from "../../../config/configFactory";
    import config from "../../../app.config";
    import WorktypePoints from "./WorktypePoints";
    import {nameInitials} from "../../../helpers/stringHelper";

    let configuration = AppConfig(config);

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            workTypeId: Number,
            workTypePoints: Number,
            workTypeIsEnglish: Boolean,
            workTypeIsEnumerable: Boolean,
            ratingSettings: Object,
            isDepartmentMode: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            LoadingPlaceholder,
            WorktypePoints,
        },
        data: () => {
            return {
                configuration,
                form: {
                    workName: null,
                    point: null,
                    workUrl: null,
                    ratingFiles: [],
                    anotherProfessorItems: [],
                    isMain: null,
                },
                errors: {
                    workName: null,
                    point: null,
                    workUrl: null,
                    ratingFiles: null,
                    anotherProfessorItems: null,
                },
                loading: false,
                loadingSave: false,
                isNumber,
                professors: [],
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getWorkTypesRatingItem/${this.editId}`)
                    .then(resp => {
                        this.loading = false;

                        const respData = resp;
                        
                        respData.ratingFiles = respData.ratingFiles.map(r => {
                            r.url = `${this.$config.apiEndpoint}/file/get/ratingFile/${r.fileName}`
                            
                            return r;
                        });

                        this.form = respData;
                    })
            }

            this.loading = true;
            
            HttpService.getInstance()
                .get(`teacher/navigation/departments/${this.$route.params.department}/professors`)
                .then(res => {
                    const remappedprofessors = res.map(professor => {
                        professor.fullName = `${professor.academicPosition.nameShortUk} ${professor.surname} ${nameInitials(professor.name)} ${nameInitials(professor.patronymic)}`;

                        return professor;
                    });

                    this.professors = remappedprofessors;
                    
                    this.loading = false;
                });
        },
        methods: {
            filesSelected() {
                this.loadingSave = true;
            },
            filesUploaded() {
                this.loadingSave = false;
            },
            getAccessToken() {
                return localStorage.getItem('access_token');
            },
            getEditorConfig() {
                return {
                    forcePlainTextOnPaste: true,
                    hideModules: {
                        italic: true,
                        underline: true,
                        justifyLeft: true,
                        justifyCenter: true,
                        justifyRight: true,
                        headings: true,
                        link: true,
                        code: true,
                        image: true,
                        table: true,
                        separator: true,
                    },
                }
            },
            addProfessorPoint() {
                this.form.anotherProfessorItems.push({});
            },
            removeProfessorPoint(index) {
                this.form.anotherProfessorItems.splice(index, 1);
            },
            getAnotherProfError(index, field) {
                const foundError = this.errors[`anotherProfessorItems[${index}]`];
                
                if (foundError) {
                    return foundError[field];
                }
                
                return null;
            },
            submitForm() {
                this.loadingSave = true;

                const formData = {...this.form};

                formData.ratingFiles = formData.ratingFiles.map(f => {
                    if (f.upload && f.upload.data) {
                        f.fileName = f.upload.data.fileName;
                        f.name = f.upload.data.name;
                    }
                    else {
                        f.name = f.fileName;
                    }
                    
                    return f;
                });
                formData.department = {id: this.$route.params.department};
                formData.ratingDirection = {id: this.$route.params.direction};
                formData.ratingWorktype = {id: this.workTypeId};

                HttpService.getInstance()
                    .post("ratingUser/saveWorkTypeRatingItem", formData)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited", this.createAnother);
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>