<template>
    <v-app>
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <nb-supervisor-toolbar
                :is-lang-switcher="false"
                color="red darken-4"
                @toggleDrawer="drawerApp = !drawerApp"
        ></nb-supervisor-toolbar>
        <v-content>
            <router-view/>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import NbSupervisorToolbar from "../../components/nbSupervisor/common/NbSupervisorToolbar";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import NbSupervisorNavigationDrawer from "../../components/nbSupervisor/common/NbSupervisorNavigationDrawer";

    export default {
        components: {
            NbSupervisorToolbar,
            navigation: NbSupervisorNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
