<template>
    <v-card>
        <v-card-title class="title">Результати тестування</v-card-title>
        <v-card-text>
            <group-results-print
                    v-if="!isKti"
                    ref="print"
                    :results="results"
            />
            <group-results-kti-print
                    v-if="isKti"
                    ref="print"
                    :results="results"
            />
        </v-card-text>
        <v-card-actions class="no-print">
            <v-spacer></v-spacer>
            <v-btn
                    color="warning display-none"
                    @click.native="$emit('shouldClose')"
                    :disabled="loading"
            >Закрити
            </v-btn>
            <v-btn
                    class="display-none"
                    color="primary"
                    @click.native="print"
            >Надрукувати
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import GroupResultsPrint from "./GroupResultsPrint";
    import GroupResultsKtiPrint from "./GroupResultsKtiPrint";
    import {Printd} from 'printd';

    export default {
        data: () => ({
            loading: false,
            printD: new Printd(),
            cssText: `
                .title,
                .display-none {
                   display: none !important;
                },
                table.table {
                   width: 100%;
                   margin-bottom: 1rem;
                   background-color: transparent;
                   border-collapse: collapse !important;
                }

                table.table th, table.table td {
                   padding: .2rem .75rem;
                   vertical-align: top;
                   border-top: 1px solid #dee2e6;
                }
                table.table thead th {
                   vertical-align: bottom;
                   border-bottom: 2px solid #dee2e6;
                }
                
                table.table th.rotate {
                    height: 220px;
                    font-weight: normal;
                    border: none !important;
                    vertical-align: middle !important;
                    padding: 0.05rem !important;
            
                }
                
                .rotate {
                    filter:  progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083);
                    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
                    -moz-transform: rotate(-90.0deg); 
                    -ms-transform: rotate(-90.0deg);
                    -o-transform: rotate(-90.0deg); 
                    -webkit-transform: rotate(-90.0deg);
                    transform: rotate(-90.0deg);
                }
            `,
            results: [],
        }),
        components: {
            GroupResultsPrint,
            GroupResultsKtiPrint
        },
        props: {
            selectedStudents: {
                type: Array,
                default: () => [],
            },
            groupQuizResultsUrl: String,
            isKti: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.loading = false;

            HttpService.getInstance()
                .post(this.groupQuizResultsUrl,
                    {
                        students: this.selectedStudents,
                        module: {id: this.$route.params.module},
                        subject: {id: this.$route.params.subject},
                        stepProfile: {id: this.$route.params.profile}
                    }
                )
                .then(resp => {
                    this.results = resp;
                });

        },
        methods: {
            print() {
                this.printD.print(this.$el, this.cssText);
            },
        }
    }
</script>