<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">{{ props.item.username }}</td>
                <td class="text-xs-left">{{ props.item.email }}</td>
                <td class="text-xs-left">{{ props.item.surname }}</td>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-left">
                    <v-chip color="primary" class="mb-1" :key="index" v-for="(role, index) in props.item.roles">{{role.name}}</v-chip>
                </td>
                <td class="text-xs-left">
                    <v-icon v-if="!props.item.passwordChanged">radio_button_unchecked</v-icon>
                    <v-icon v-if="props.item.passwordChanged">radio_button_checked</v-icon>
                </td>
                <td class="text-xs-left">
                    <v-icon v-if="!props.item.isBlocked">radio_button_unchecked</v-icon>
                    <v-icon v-if="props.item.isBlocked">radio_button_checked</v-icon>
                </td>
                <td class="text-xs-left">{{ props.item.loginsCount }}</td>
                <td class="text-xs-left">{{ formatShortDate(props.item.lastLogin) }}</td>
                <td class="text-xs-left">{{ props.item.lastLoginIp }}</td>

                <td class="justify-center layout px-2">
                    <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('blockItem', props.item.id)">
                        <v-icon color="red">power_settings_new</v-icon>
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            formatShortDate,
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Логін', value: 'username'},
                {text: 'Email', value: 'email'},
                {text: 'Прізвище', value: 'surname'},
                {text: 'Ім\'я', value: 'name'},
                {text: 'Роль', value: 'roles'},
                {text: 'Пароль змінено', value: 'passwordChanged'},
                {text: 'Заблоковано', value: 'isBlocked'},
                {text: 'К-сть входів', value: 'loginsCount'},
                {text: 'Останній вхід', value: 'lastLogin'},
                {text: 'Останній IP входу', value: 'lastLoginIp'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>