<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <h2 class="mt-5 mb-2">Статистика по факультету</h2>
        <div class="caption mb-2">По замовчуванню, відображаються дані за останній календарний міяць (-30 днів від сьогоднішньої дати)</div>
        <v-data-table
            fixed-header
            height="80vh"
            :headers="headers"
            :items="data"
            :loading="loading"
            disable-pagination
            hide-default-footer
            class="elevation-3"
        >
            <template v-slot:item.professor.surname="{item}">
                {{item.professor.surname}} {{item.professor.name}} {{item.professor.patronymic}}
            </template>

            <template v-slot:item.countDeanAll="{item}">
                {{item.countDeanAll}}
            </template>

            <template v-slot:item.countDeanFreeAdmissionPayableSubject="{item}">
                {{item.countDeanFreeAdmissionPayableSubjectPercentage.toFixed(2)}}% ({{item.countDeanFreeAdmissionPayableSubject}})
            </template>

            <template v-slot:item.countOverdueDeanFreeAdmissionPayableSubject="{item}">
                {{item.countOverdueDeanFreeAdmissionPayableSubjectPercentage.toFixed(2)}}% ({{item.countOverdueDeanFreeAdmissionPayableSubject}})
            </template>

            <template v-slot:item.countDeanFreeAdmissionFreeAdmissionSubject="{item}">
                {{item.countDeanFreeAdmissionFreeAdmissionSubjectPercentage.toFixed(2)}}% ({{item.countDeanFreeAdmissionFreeAdmissionSubject}})
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import LineChart from '@/components/common/statistics/LineChart';
    import StatisticsHeader from "@/components/common/statistics/StatisticsHeader";
    import HttpService from "@/HttpService";
    import moment from "moment";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatisticsHeader,
            StatDateRange,
        },
        data () {
            return {
                loading: false,
                dates: [],
                
                data: [],
                headers: [
                    {text: 'ПІП працівника деканату', value: 'professor.surname'},
                    {text: '∑ деканат', value: 'countDeanAll'},
                    {text: '∑ платні дисципліни', value: 'countDeanFreeAdmissionPayableSubject'}, // по довідці
                    {text: '∑ прострочених, платні дисципліни', value: 'countOverdueDeanFreeAdmissionPayableSubject'}, // всі видані деканатом, у яких закінчився термін
                    {text: '∑ безоплатні дисципліни', value: 'countDeanFreeAdmissionFreeAdmissionSubject'},
                ],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            clearFilter() {
                this.dates = [];
                
                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
            
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                this.loading = true;
                HttpService.getInstance()
                    .get(`statisticsReworkAdmissions/getByFaculty/${this.$route.params.faculty}?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        this.loading = false;
                    })
                    .catch(e => {})
            },
        }
    }
</script>