<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            loading-text="Loading... Please wait"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.date="{item}">
            {{formatShortDate(item.date)}}
        </template>
        <template v-slot:item.scheduleItems="{item}">
            <div>
                <v-chip class="mb-2 mt-2" v-for="(schedule, index) in item.scheduleItems" color="primary" :key="index">
                    {{schedule.nameUk}}
                    (<span :key="i" v-for="(spec, i) in schedule.specialities">{{spec.nameShortUk}}<span></span></span>)
                </v-chip>
            </div>
        </template>
        <template v-slot:item.reworkStartTime="{item}">
            {{formatLongDate(item.reworkStartTime)}}
        </template>
        <template v-slot:item.reworkEndTime="{item}">
            {{formatLongDate(item.reworkEndTime)}}
        </template>
        
        <template v-slot:item.actions="{item}">
            <v-btn icon class="mx-0" @click="$emit('editItem', item)">
                <v-icon color="primary">edit</v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
    import {formatLongDate, formatShortDate} from "../../../helpers/dateHelper";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Дата відробітків', value: 'date', sortable: false},
                {text: 'Дисципліни', value: 'scheduleItems', sortable: false},
                {text: 'Час початку', value: 'reworkStartTime', sortable: false},
                {text: 'Час завершення', value: 'reworkEndTime', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
            
            formatShortDate,
            formatLongDate,
        }),
    }
</script>