<template>
    <v-card :color="getColorStatus(computer)">
        <v-card-text class="pa-2 card-text-wrapper d-flex flex-column align-center justify-space-between">
            <div class="headline d-block">
                {{computer.pcName}}
            </div>
            <div class="d-block">
                <v-btn :disabled="loadingWarning"
                       v-if="computer.status.toLowerCase() === 'busy'"
                       @click="setWarning()"
                       small color="warning">Попередження
                </v-btn>
                <v-btn :disabled="loadingPenalty"
                       v-if="computer.status.toLowerCase() === 'warned'"
                       @click="setPenalty()"
                       small color="error">Покарання
                </v-btn>
                <v-btn :disabled="true"
                       v-if="computer.status.toLowerCase() === 'free'"
                       small color="warning">Вільний
                </v-btn>
                <v-btn :disabled="true"
                       v-if="computer.status.toLowerCase() === 'blocked'"
                       small color="warning">Заблокований
                </v-btn>
            </div>
            <div class="caption ">
                Upd: {{formatDate(computer.updatedDate)}}
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import * as moment from "moment";
    import * as lodash from "lodash";
    import HttpService from "../../HttpService";

    export default {
        props: {
            computer: Object,
        },
        data: () => ({
            loadingWarning: false,
            loadingPenalty: false,
        }),
        watch: {
            computer: {
                handler: function (newVal, oldVal) {
                    if (newVal.status !== oldVal.status) {
                        this.loadingWarning = false;
                        this.loadingPenalty = false;
                    }
                },
                deep: true
            }
        },
        methods: {
            setWarning() {
                this.loadingWarning = true;

                HttpService.getInstance()
                    .put("nbSupervisor/setWarning", this.computer)
                    .then(resp => {
                        this.$emit("stateChanged");
                    });
            },
            setPenalty() {
                this.loadingPenalty = true;

                HttpService.getInstance()
                    .put("nbSupervisor/setPenalty", this.computer)
                    .then(resp => {
                        this.$emit("stateChanged");
                    });
            },
            getColorStatus(pcName) {
                console.log(pcName.status.toLowerCase());
                switch (pcName.status.toLowerCase()) {
                    case "free":
                        return "success";
                    case "busy":
                        return "info";
                    case "warned":
                        return "warning";
                    case "blocked":
                        return "error";
                }
            },
            formatDate(dateString) {
                return moment(dateString).format("HH:mm DD.MM.YY");
            },
        }
    }
</script>
<style>
    .card-text-wrapper {
        min-height: 150px;
    }
</style>