<template>
    <div class="mark-input-control">
        <v-btn-toggle :value="value" @change="onChangeValue($event)">
            <v-btn small color="red darken-2" text :value="0">
                <span class="ml-2 mr-2">Відсутній</span>
            </v-btn>
            <v-btn small color="green darken-2" text :value="1">
                <span class="ml-2 mr-2">Присутній</span>
            </v-btn>
        </v-btn-toggle>
    </div>
</template>

<script>
    import {topicEvaluationModeEnum} from "../../constants/topicEvaluationModeTypes";
    
    export default {
        props: {
            value: {
                type: Number,
                default: 1,
            },
            id: {
                type: Number,
                default: null,
            },
            topic: {
                type: Object,
                default: () => ({
                    evaluationMode: topicEvaluationModeEnum.isNotEvaluated
                }),
            }
        },
        data: () => ({
            topicEvaluationModeEnum,
        }),
        mounted() {
            this.onChangeValue(this.value);
        },
        methods: {
            onChangeValue(value) {
                this.$emit('change', {
                    value,
                    id: this.id,
                });
            },
            
        }
    }
</script>

<style lang="scss">
    .mark-input-control {
        min-width: 200px;
        max-width: 230px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        padding-right: 10px;
        
        //& button.v-btn {
        //    padding: 0 !important;
        //    min-width: 30px !important;
        //}
    }

    //.v-btn--active:before {
    //    opacity: .3 !important;
    //}
</style>