<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex >
                <v-card>
                    <statistics-reworks-admissions-general :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import StatisticsReworksAdmissionsGeneral
    from "@/components/admin/StatisticsReworkAdmissions/StatisticsReworksAdmissionsGeneral";

export default  {
    components: {
        StatisticsReworksAdmissionsGeneral,
    },
    data: () => {
        return {


        };
    },
}
</script>