<template>
    <div>
        <div class="group-btns-wrapper">
            <v-btn v-if="selectedItemsComputed.length" @click="$emit('change')" color="primary">
                Змінити факультет/спеціальність/курс
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('deleteSelected')" color="red" dark>
                Видалити
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('expulseSelected')" color="pink darken-1" dark>
                Відрахувати
            </v-btn>
            <v-btn small v-if="selectedItemsComputed.length" @click="$emit('academicVacationSelected')" color="orange darken-3" dark>
                Академічна відпустка                
            </v-btn>
        </div>
        <v-data-table
                :hide-default-footer="true"
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                class="elevation-3"
                show-select
                v-model="selectedItemsComputed"
        >
            <template v-slot:item.surname="{ item }">
                <td class="text-xs-left">{{ item.surname }} {{ item.name }} {{ item.patronymic }}</td>
            </template>
            <template v-slot:item.birthday="{ item }">
                <td class="text-xl-start">{{formatShortDate(item.birthday)}}</td>
            </template>
            <template v-slot:item.gender="{ item }">
                <td class="text-xs-left">
                    <div v-if="item.gender === 0">Чоловіча</div>
                    <div v-if="item.gender === 1">Жіноча</div>
                </td>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                    <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {formatShortDate} from "../../../../helpers/dateHelper";

    export default {
        props: {
            items: {
                type: Array,
            },
            selectedItems: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        computed: {
            selectedItemsComputed: {
                get() { return this.selectedItems},
                set(val) { this.$emit('update:selectedItems', val)}
            }
        },
        data: () => ({
            formatShortDate,
            selected: [],
            headers: [
                {text: '№', value: 'index', sortable: false},
                {text: 'Прізвище, ім\'я, по-батькові', value: 'surname', sortable: false},
                {text: 'Дата народження', value: 'birthday', sortable: false},
                {text: 'Стать', value: 'gender', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>

<style lang="scss">
    .group-btns-wrapper {
        margin-bottom: 10px;
        height: 36px;

        & .v-btn {
            margin-right: 10px;
        }
    }
</style>