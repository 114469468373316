export default {
    message: {
        hello: 'hello world'
    },
    announcements: {
        title: "Announcements",
        norecords: "There are no actual announcements at this moment...",
        types: {
            educationalDpt: "Educational department",
            deanOffice: "Dean office",
            department: "Department",
            curator: "Curator",
        }
    },
    dashboard: {
        "loginButtonTextFull": "Log in into ePlato",
        "loginButtonTextShort": "Log in",
        "testVersionExclamation": "This version is for test purposes only!!! Please use <a href=\"https://ez.pdmu.edu.ua\">ez.pdmu.edu.ua/a>!!!",
        "supportTitle": "Electronic register support",
        //On the restoration of access, write, indicating: faculty, course, group, name by e-mail
        "accountQuestions": "On access recovery, please indicate faculty, year number, group number, name and send an e-mail to",
        "postNote": "WARNING! There is no need to contact other persons responsible for the work of Electronic register. " +
            "If necessary, your question / problem will be automatically forwarded to other specialists!",
        "cannotLogIn": "Cannot log in?",
        "universityName": "Poltava State Medical University"
    },
    surveys: {
        title: "Surveys",
        norecords: "There are no actual surveys at this moment...",
    },
    toolbar: {
        announcements: "Announcements",
        surveys: "Surveys",
        logOut: "Log off",
        settings: "Settings"

    },
    navigation: {
        student: {
            title: "Student",
            description: "All your marks"
        },
        admin: {
            title: "Admin",
            description: "Admin dashboard"
        },
        dean: {
            title: "Dean",
            description: "Dean dashboard"
        },
        chiefdept: {
            title: "ChiefDept",
            description: "ChiefDept dashboard"
        },
        moderator: {
            title: "Moderator",
            description: "Moderator dashboard"
        },
        teacher: {
            title: "Teacher",
            description: "Teacher dashboard"
        },
        rating: {
            title: "Rating",
            description: "Rating dashboard"
        },
        ratingAdmin: {
            title: "Rating:Admin",
            description: "Rating admin dashboard"
        },
        reworks: {
            title: "Reworks",
            description: "Reworks dashboard"
        },
        labstaff: {
            title: "Laborant",
            description: "Laborants dashboard"
        },
        teacherFeedback: {
            title: "Feedback",
            description: "Feedback dashboard"
        },
        finalExam: {
            title: "Graduation exam",
            description: "Graduation exam dashboard",
        },

        superAdminKrok: {
            title: "SuperAdmin Step",
            description: "SuperAdmin Step"
        },
        krok: {
            title: "Step",
            description: "Training on STEPs"
        },
        modulesKrok: {
            title: "Step: Modules",
            description: "Quiz by modules"
        },
        spaKrok: {
            title: "Step: SPA",
            description: "SPA quiz"
        },
        profileKrok: {
            title: "Step: KTI",
            description: "KTI quiz"
        },
        modulesQuizList: {
            title: "Quiz bank",
            description: "Quiz bank by modules"
        },
        nbSupervisor: {
            title: "Reworks supervisor",
            description: "Dashboard supervisor"
        },
        navigation: {
            title: "EzNb: Quiz bank",
            description: "Quiz bank Rework",
        },
        eAristoStudent: {
            title: "eAristo: Student",
            description: "Student self-study",
        },
        eAristoProfessor: {
            title: "eAristo: Teacher",
            description: "Teacher self-study",
        },
    },

    finalExam: {
        infoAbout: "Info about graduation exams",
        notYetRegistered: "You are not yet registered for the exam",
        registerForExam: "Register for the exam",
        youAreRegistered: "You have registered for the exam",
        examName: "Exam name",
        googleMeetLinkExam: "GoogleMeet link for passing the exam",
        linkShow10MinsBeforeExam: "The link will be provided 10 minutes before the exam",
        navigateToExam: "Navigate to the exam",
        noMeetLinkAvailable: "No GoogleMeet link",
        dateAndTimeExam: "Date and time of the exam",
        examinationPaperNumber: "Examination paper number",
        ticketNumberWillBeShown10Mins: "The paper number will be indicated 10 minutes before the exam",
        passed: "Passed",
        notPassed: "Not passed",
        resultGraduationExams: "The results of the graduation exams",
        noGraduationExamResults: "There are no exam results",
        examResultTraditional: "Exam result (traditional mark)",
        noPublishedResult: "There are no published results",
        examResultEcts: "Exam result (ECTS)",
        menuFinalExamStudentDashboard: "Graduation exams",
        menuFinalExamResults: "Graduation exams results",
        chooseMenuItemOnLeft: "Choose menu item on the left side",
        stStatus: "Status",
        resultKrokExam: "The result of KROK exam"
    },

    studentKrok: {
        electronicRegister: "Electronic register",
        spaTraining: "Training on SPA",
        ktiTraining: "Training on (KROK) KTI",
        spaSubjects: "SPA Subjects",
        topics: "Topics",
        selectSubject: "Select subject",
        selectTopic: "Select topic",
        questionsCount: "Questions count for quiz",
        startQuiz: "Start quiz",
        problematicQuestions: "Problematic questions",
        attempt: "Attempt",
        from: "з",
        attemptsOnPage: "Attempts on page",
        all: "All",
        continue: "Continue",
        start: "Start",
        finish: "Finish",
        questionCount: "Question count",
        questionCorrectCount: "Correct question count",
        questionCorrectPercentage: "% of correct count",
        actions: "Actions",
        noData: "No data",
        errorCount: "Errors count:",
        close: "Close",
        noProblematicQuestions: "No problematic questions at the moment",
        withProblematicQuestions: "Include problematic questions",
    },
    studentQuiz: {
        remaining: "Remaining",
        automaticSwitch: "Switch questions automatically",
        questionNo: "Question #",
        previousQuestion: "Previous question",
        nextQuestion: "Next question",
        answeredQuestion: "This question was already answered",
        finishQuiz: "Finish quiz",
        quizIsFinished: "Quiz is finished",
        logoutFromSystem: "Log-out from quiz"
    },
    student: {
        marks: "Marks",
        calendar: "Marks Calendar",
        marksMonthly: "Mark by Months",
        absents: "Absences",
        reworks: "Consultations & Reworks",
        payments: "Payments History",

        chooseSubject: "Please choose subject",
        showInfoModule: "View modules information",
        showGroupStatistics: "View group statistics",
        showFacultyStatistics: "View faculty statistics",

        moduleName: "Module name",
        currentPoints: "Classes points",
        averageMark: "Average mark",
        moduleControlMark: "Module control mark",
        totalMark: "Total mark",
        totalMarkClassic: "Total classic mark",
        totalMarkECTS: "Total ECTS mark",
        date: "Date",
        professor: "Professor",
        pip: "Name, surname",
        topic: "Topic",
        subject: "Subject",
        abs: "abs",
        numberof: "Number of",
        numberof2: "Number of '2'",
        numberofabsences: "Number of absences",

        paymentMsg1: "To choose missed classes without a valid reason",
        paymentMsg2: "click on them to pay. You can choose several at once.",

        pay: 'Pay',
        choosenAbs: 'Absences are selected',
        totalReworkPrice: 'Total cost of reworks',

        attachment: "Attachment",
        department: "Department",
        hours: "Hours",
        reworkPrice: "The cost of rework of absence",
        absenceType: "Absence type",

        emited: "Emited",
        expired: "Expired",
        nonvalid: "Non-valid",

        paymentStatus: 'Payment status',
        description: 'Description',
        amount: 'Amount',
        currency: 'Currency',
        paymentDate: 'Payment date',
        card: 'Card',

        overview: 'Overview',
        module: 'Module',
        practicalClasses: 'Practical classes',
        lecturesClasses: 'Lectures',
        independentClasses: 'Individual work',
    },
    eAristoStudent: {
        subjectList: "Subject list",
        favorites: "Favorites",
        statistics: "Progress statistics",
        goToModuleList: "Navigate to modules list",
        modules: "Modules",
        selectModule: "Please selet module",
        goToTopicsList: "Navigate to topics list",
        topics: "Topics",
        selectTopic: "Please select topic",
        topicName: "Topic name",
        action: "Action",
        quizStart: "Start",
        quizEnd: "End",
        quizResult: "Result",
        quizFinished: "Done",
        quizCounted: "Accounted",
        quizInteractiveVideo: "Interactive video",
        quizInteractivePresentation: "Interactive presentation",
        quizTopicAll: "Quiz on topic",
        thisActivity: "This type of activity must be completed only after completing other types of activities:",
        quizStartQuiz: "Start quiz",
        quizFinishQuiz: "Finish quiz",
        topicAndQuizPassed: "Topic and quiz is passed",
        sendAnswers: "Send answers",
        youAnsweredWrong: "You answered the question incorrectly. It is necessary to pass the last piece of material and run quiz again.",
        youAnsweredCorrect: "You answered correctly!",
        youAnsweredThisBlock: "You have answered this block of questions!",
        pleaseSelectOneAnswer: "Please choose one correct answer and click \"Send answer\"",
        pleaseFillMissedWords: "Please fill in the missing words in the fields below and click \"Send answer\". You must fill in all fields.",
        answer: "Answer",
        youAnsweredCorrectThisBlock: "You have successfully answered this block of questions!",
        youNotAnsweredCorrectThisBlock: "You have NOT completed this block of questions successfully!",
        videoBrowserWarning: "Unfortunately, passing the interactive video <strong>in Mozilla Firefox is not supported</strong>. Please choose any other modern web browser: Chrome, Safari, Opera.",
        subjectName: "Subject name",
        moduleName: "Module name",
        goToSubjectList: "Navigate to subject list",
        yourResultIs: "Your result",
        clickFinishTestToFinishTopic: "Click on \"Finish quiz\" to complete this topic.",
        clickFinishTestRestartTopic: "Click on \"Finish quiz\" after which you can start a new quiz to try again.",
        playVideo: "Play",
        pauseVideo: "Pause",
        presPrev: "Previous",
        presNext: "Next",
        youFinishedPresentation: "You have finished viewing the presentation. This is the last slide. If necessary, answer the questions below this slide and move on to another type of activity."
    },
}