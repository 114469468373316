<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex >
                <v-card>
                    <moderator-accountance-time :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import ModeratorAccountanceTime from "@/components/moderator/moderatorAccountanceTime/ModeratorAccountanceTime";

export default  {
    components: {
        ModeratorAccountanceTime,
    },
    data: () => {
        return {


        };
    },
}
</script>