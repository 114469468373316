import NbSupervisorRootView from '../views/nbSupervisor/NbSupervisorRootView';
import NbSupervisorHome from "../components/nbSupervisor/NbSupervisorHome";

import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/nbSupervisor',
         component: NbSupervisorRootView,
         name: 'nbSuperVisorRoot',
         redirect: {name: 'nbSupervisorHome'},
         children: [
             {
                 path: '',
                 name: 'nbSupervisorHome',
                 component: NbSupervisorHome,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
             },
         ],
     },
 ];
}