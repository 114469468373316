<template>
    <v-row>
        <v-col
                v-for="direction in ratingWorktypeDirections"
                :key="direction.id"
                cols="12"
                sm="6"
                md="4"
                lg="4"
        >
            <v-card>
                <v-card-title class="subheading font-weight-bold no-word-break">
                    <div class="header-wrapper">
                        {{ direction.order }}. {{ direction.directionName }}
                    </div>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <div class="subtitle-1">Коефіцієнт напрямку: <strong>{{direction.ratio}}</strong></div>
                    <div class="subtitle-1">Кількість набраних балів: <strong>{{direction.pointsSum}}</strong></div>
                    <div class="subtitle-1">Кількість балів (з коефіцієнтом): <span class="text-h5 text-bold">{{direction.pointsSumRatio}}</span></div>
                </v-card-text>

                <v-card-actions>
                    <v-btn :to="{name: routeToName, 
                            params: {direction: direction.id, ...routeToParams}}" 
                           color="deep-purple lighten-2"
                           text>Види робіт <v-icon>keyboard_arrow_right</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        props: {
            ratingWorktypeDirections: Array,
            routeToName: String,
            routeToParams: Object,
        },
        
    }
</script>