import FeedbackRootView from '../views/feedback/FeedbackRootView';

import routeGuard from "../routeGuards/routeGuard";
import FeedbackSuperAdminPreSelect from "../views/feedback/FeedbackSuperAdminPreSelect";
import AnnouncementsView from "../views/feedback/AnnouncementsView";
import routeGuardAllExceptStudents from "../routeGuards/routeGuardAllExceptStudents";

export default (router) => {
    return [
        {
            path: '/feedback',
            component: FeedbackRootView,
            name: 'feedbackRoot',
            beforeEnter: (to, from, next) => routeGuardAllExceptStudents(to, from, next)(router),
            redirect: {name: 'feedbackSuperAdminPreselect'},
            children: [
                {
                    path: 'feedback-sa-preselect',
                    name: "feedbackSuperAdminPreselect",
                    component: FeedbackSuperAdminPreSelect,
                    beforeEnter: (to, from, next) => routeGuardAllExceptStudents(to, from, next)(router),
                }, //feedbackHome
                {
                    path: 'announcements',
                    name: 'announcementsFeedback',
                    component: AnnouncementsView,
                    beforeEnter: (to, from, next) => routeGuardAllExceptStudents(to, from, next)(router),

                },
            ],
        },
    ];
}