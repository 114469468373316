<template>
    <v-layout class="mb-2" row wrap>
        <v-flex lg2 md3 sm6 xs6>
            <v-layout align-center>
                <v-flex xs3>
                    <v-icon class="ml-2" color="green" large>local_activity</v-icon>
                </v-flex>
                <v-flex xs9 class="ml-2">
                    <span class="subtitle-1 font-weight-light">{{$t('student.emited')}}</span>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg2 md3 sm6 xs6>
            <v-layout align-center>
                <v-flex xs3>
                    <v-icon class="ml-2" color="orange" large>local_activity</v-icon>
                </v-flex>
                <v-flex xs9 class="ml-2">
                    <span class="subtitle-1 font-weight-light">{{$t('student.expired')}}</span>
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex lg2 md3 sm6 xs6>
            <v-layout align-center>
                <v-flex xs3>
                    <v-icon class="ml-2" color="red" large>local_activity</v-icon>
                </v-flex>
                <v-flex xs9 class="ml-2 border-right">
                    <span class="subtitle-1 font-weight-light">{{$t('student.nonvalid')}}</span>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12>
            <v-layout row wrap>
                <v-flex lg12 md12 sm5 xs12
                        :key="index" v-for="(topicMarkAdmissionType, index) in topicMarkAdmissionTypes">
                    <v-layout align-center class="mt-1 ml-2">
                        <v-flex xs3>
                            <v-icon class="ml-4" color="red" medium>{{topicMarkAdmissionType.icon}}</v-icon>
                        </v-flex>
                        <v-flex xs9 class="ml-2">
                            <span class="body-1 font-weight-light">{{topicMarkAdmissionType.nameUk}}</span>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-layout>
</template>
<script>
    import topicMarkAdmissionTypes from "../../../constants/topicMarkAdmissionTypes";

    export default {
        data: () => ({
            topicMarkAdmissionTypes,
        }),
    }
</script>
<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';
    
    .border-right {
        border-right: 1px solid #ccc;
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        .border-right {
            border-right: none;
        }   
    }
</style>