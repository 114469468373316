<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <system-settings-index></system-settings-index>
        </v-layout>
    </v-container>
</template>
<script>

import SystemSettingsIndex from "@/components/admin/SystemSettings/SystemSettingsIndex.vue";

export default  {
    components: {
        SystemSettingsIndex,
    }
}
</script>