<template>
    <div>
        <div v-if="browserDetected.name !== 'firefox'" style="max-width: 100%">
            <video-player-limited 
                ref="video" 
                :video-content="videoContent" 
                :show-play-pause="showPlayPause"
                @currentTime="onCurrentTime"
            ></video-player-limited>

            <e-aristo-question-student-quiz-index v-if="questionsToPass.length > 0 && this.isCorrectCurrentSection === false"
                                                  :questions-to-pass="questionsToPass"
                                                  :quiz-attempt-id="quizAttemptId"
                                                  @submittedAnswers="onSubmittedAnswers"
            ></e-aristo-question-student-quiz-index>

            <v-alert color="success" dark v-if="this.isCorrectCurrentSection === true">
                {{$t("eAristoStudent.youAnsweredCorrectThisBlock")}}
            </v-alert>
        </div>
        <v-alert
            v-else
            border="top"
            colored-border
            type="warning"
            elevation="2"
        >
            {{$t("eAristoStudent.videoBrowserWarning")}}
        </v-alert>
    </div>
</template>

<script>

import {AppConfig} from "@/config/configFactory";
import config from "@/app.config";
import moment from "moment/moment";
import VideoPlayerLimited from "@/components/common/video-player/VideoPlayerLimited.vue";
import eAristoQuestionStudentQuizIndex
    from "@/components/eAristoStudent/topicContent/questions/eAristoQuestionStudentQuizIndex.vue";
import browser from 'browser-detect';

const configuration = AppConfig(config);
export default {
    name: "VideoStudentContent",
    components: {
        VideoPlayerLimited,
        eAristoQuestionStudentQuizIndex,
    },
    props: {
        videoContent: {
            type: Object,
            default: () => {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default: false,
        },
        questionsVideo: {
            type: Array,
            default: () => {
                return [];
            }
        },
        questionsVideoSegments: {
            type: Array,
            default: () => {
                return [];
            }
        },
        quizAttemptId: {
            type: Number,
        }
    },
    computed: {
        isCorrectCurrentSection: {
            get() {
                const foundQuestionVideo = this.questionsVideoSegments.find(q => q.marker === this.currentVideoTime);
                
                if (!foundQuestionVideo) {
                    return null;
                }

                return foundQuestionVideo && foundQuestionVideo.answeredCorrect;
            }
        },
    },
    data: () => {
        return {
            currentVideoTime: 0,
            questionsToPass: [],
            
            currentVideoTimeQuestions: null,
            
            showPlayPause: true,
            browserDetected: browser(),
        };
    },
    methods: {
        onCurrentTime(time) {
            const roundedTime = Math.round(time);
            
            if (roundedTime === this.currentVideoTime) {
                return;
            }
            
            this.currentVideoTime = roundedTime

            const foundQuestionVideo = this.questionsVideoSegments.find(q => q.marker === this.currentVideoTime);
            
            if (foundQuestionVideo && !foundQuestionVideo.answeredCorrect && this.currentVideoTime !== this.currentVideoTimeQuestions) {
                
                this.$refs.video.setPausePlayer();
                this.showPlayPause = false;
                this.questionsToPass = this.questionsVideo.filter(p => p.currentVideoTime === this.currentVideoTime);
            }
            setTimeout(() => {
                this.currentVideoTimeQuestions = this.currentVideoTime;
            }, 0);
        },
        formatVideoTime(time) {
            const timeMoment = moment.unix(time).utc();

            return timeMoment.format("HH:mm:ss");
        },

        onSubmittedAnswers(submitResult) {
            if (!submitResult) {

                const foundQuestionPresentation = this.questionsVideoSegments.find(q => q.marker === this.currentVideoTime);
                const index = this.questionsVideoSegments.indexOf(foundQuestionPresentation);
                const previousQuestionSegment = this.questionsVideoSegments[index - 1];
                
                if (previousQuestionSegment) {
                    this.$refs.video.setVideoTime(previousQuestionSegment.marker);
                }
                else {
                    if (this.currentVideoTime > 15) {
                        this.$refs.video.setVideoTime(this.currentVideoTime - 10);
                    }
                    else {
                        this.$refs.video.setVideoTime(0);
                    }
                }
                
                this.$refs.video.setPlayPlayer();
                this.showPlayPause = true;
            }
            else {
                this.$refs.video.setPlayPlayer();
                this.showPlayPause = true;
            }

            this.$emit('submittedAnswers', submitResult);
        }
    }
}
</script>

<style scoped>

</style>