<template>
    <v-card>
        <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12>
                    <v-btn 
                            large 
                            color="primary" 
                            :disabled="loading" 
                            :loading="loading"
                            @click="loadStats()"
                    >
                        Отримати статистику
                    </v-btn>
                </v-flex>
                <v-flex xs12>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Факультет</th>
                                <th>Кількість тестувань</th>
                                <th>Вірні відповіді</th>
                                <th>Середня оцінка</th>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr v-for="stat in statistics">
                                <td>{{stat.faculty.nameUk}}</td>
                                <td>{{stat.quizCount}}</td>
                                <td>{{stat.rightAnswers ? stat.rightAnswers.toFixed(3) : "-"}}</td>
                                <td>{{stat.averageMark ? stat.averageMark.toFixed(3) : "-"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    import HttpService from "../../../../HttpService";
    
    export default {
        data: () => {
            return {
                loading: false,
                statistics: [],
            };
        },
        methods: {
            loadStats() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`adminStep/getStatByDepartment/${this.$route.params.speciality}/${this.$route.params.semester}/${this.$route.params.department}`)
                    .then(res => {
                        this.statistics = res;

                        this.loading = false;
                    });
            }
        }
    }
</script>