<template>
    <v-layout wrap>
        <v-flex xs12>
            <v-card>
                <v-card-text>
                    <v-container fluid fill-height>
                        <v-layout row wrap justify-center>
                            <v-flex xs10>
                                <v-text-field large
                                              clearable
                                              autofocus
                                              :loading="loading"
                                              label="Прівище та/або ім'я студента"
                                              v-model="searchString"
                                              hide-details
                                />
                            </v-flex>
                            <v-flex xs2>
                                <v-btn @click="this.getStudents(searchString)" color="primary">Пошук</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs12 class="mt-2">
            <admin-payment-history-grid
                    :items="items"
                    :total="total"
                    :loading="loading"
                    @paginate="onPaginate"
                    @openAdmissions="openAdmissions"
            >
            </admin-payment-history-grid>
        </v-flex>
            
        <v-dialog v-model="admissionDialog"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable>
            <admin-payment-admissions
                    v-if="admissionDialog"
                    @shouldClose="admissionDialog = false"
                    :payment-item="selectedPaymentItem"
            >
            </admin-payment-admissions>
        </v-dialog>
    </v-layout>
</template>
<script>
    import AdminPaymentHistoryGrid from "./AdminPaymentHistoryGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AdminPaymentAdmissions from "./AdminPaymentAdmissions";
    import throttle from "lodash/throttle"

    export default {
        components: {
            AdminPaymentHistoryGrid,
            AdminPaymentAdmissions
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                admissionDialog: false,
                selectedPaymentItem: null,

                searchString: "",
            }
        },
        methods: {
            getThrottledItems: throttle(function (searchString) {
                this.getItems(objectQueryStringify(this.pagination), searchString);
            }, 1200),
            openAdmissions(item) {
                this.selectedPaymentItem = item;
                this.admissionDialog = true;
            },
            getItems(queryParams, searchString = '') {
                this.loading = true;

                HttpService.getInstance()
                    .get(`adminPaymentHistory?${queryParams}&searchString=${searchString}`)
                    .then(faculties => {
                        this.items = faculties.items;
                        this.total = faculties.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
    }
</script>