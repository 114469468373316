<template>
    <v-app>
        <vue-headful title="ePlato"/>
        
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>
        <v-main>
            <router-view/>
        </v-main>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/moderator/common/Toolbar";
    import LabstaffNavigationDrawer from "../../components/labstaff/common/LabstaffNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            navigation: LabstaffNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            drawerTeacherNav: false,
        }),
    }
</script>
