<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-break-point="0">
                        <v-list dense>
                            <v-subheader class="mt-3 grey--name name--darken-1">Курси</v-subheader>
                            <navigation-step-admin-nav-list route-to="stepAdminSpaManagement"
                                                      :additional-route-params="{
                                                            subject: $route.params.subject
                                                      }"
                            ></navigation-step-admin-nav-list>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="isBlank" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть курс
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    
                    <management-group-list
                            start-semester-quiz-url="adminStep/startSemesterSpaQuiz"
                            start-group-quiz-url="adminStep/startGroupSpaQuiz"
                            group-quiz-results-url="adminStep/getGroupQuizResultsSpa"
                            v-if="!isBlank"
                            :is-kti="false"
                    ></management-group-list>
                    
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationStepAdminNavList from "../../../components/common/NavigationStepAdminNavList";
    import ManagementGroupList from "../../stepAdmin/management/ManagementGroupList";

    export default {
        components: {
            NavigationStepAdminNavList,
            ManagementGroupList
        },
        data: () => ({
            isBlank: true,
        }),
        mounted() {
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            checkIfIsBlank(route) {
                this.isBlank = !(route.params.subject !== undefined && route.params.semester !== undefined);
            }
        },
        watch: {
            $route(route) {
                this.checkIfIsBlank(route);
            }
        }
    }
</script>