<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="students"
                :loading="loading"
                :server-items-length="students.length"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item="props">
                <tr :class="{'red lighten-5': props.item.countAbsences > 0}">
                    <td class="text-xs-left">{{ `${props.item.surname} ${props.item.name} ${props.item.patronymic}` }}</td>
                    <td class="text-xs-left">{{ props.item.semester.yearNumber }}</td>
                    <td class="text-xs-left">{{ props.item.speciality.nameUk }}</td>
                    <td class="text-xs-left">{{ `${props.item.academicGroup.groupNumber} група` }}</td>
                    <td class="text-xs-left">{{ props.item.countAbsences }}</td>
                    <td class="text-xs-left">{{ props.item.countUnsatisfactory }}</td>
                    <td class="justify-center layout px-0">
                        <div class="d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mx-0" @click="openAdmissionsByStudent(props.item)">
                                        <v-icon color="primary">assignment</v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="mode === reworkModeEnum.deanAdmissions">Переглянути пропуски</span>
                                <span v-if="mode === reworkModeEnum.teacherReworks">Переглянути пропуски та незараховані заняття</span>
                            </v-tooltip>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="admissionsPerStudentDialog && mode === reworkModeEnum.deanAdmissions"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition"
                  scrollable>
            <admissions-list-per-student
                    v-if="admissionsPerStudentDialog && mode === reworkModeEnum.deanAdmissions"
                    @shouldCloseAddEdit="admissionsPerStudentDialog = false"
                    :student="selectedStudent"
            ></admissions-list-per-student>
        </v-dialog>

        <v-dialog v-model="admissionsPerStudentDialog && mode === reworkModeEnum.teacherReworks"
                  fullscreen
                  persistent
                  content-class="reworks-list-dialog"
                  transition="dialog-bottom-transition" hide-overlay>
            <reworks-list-per-student
                    v-if="admissionsPerStudentDialog && mode === reworkModeEnum.teacherReworks"
                    @shouldCloseAddEdit="admissionsPerStudentDialog = false"
                    :student="selectedStudent"
            ></reworks-list-per-student>
        </v-dialog>

    </div>
</template>
<script>
    import AdmissionsListPerStudent from "./AdmissionsListPerStudent";
    import ReworksListPerStudent from "./ReworksListPerStudent";
    import {reworkModeEnum} from "../../../constants/_internal/reworkModeEnum";

    export default {
        components: {
            AdmissionsListPerStudent,
            ReworksListPerStudent
        },
        data: () => ({
            admissionsPerStudentDialog: false,
            selectedStudent: null,
            headers: [
                {text: 'ПІП', sortable: false},
                {text: 'Курс', sortable: false},
                {text: 'Спеціальність', sortable: false},
                {text: 'Академічна група', sortable: false},
                {text: 'Кількість пропусків', sortable: false},
                {text: 'Кількість "2"', sortable: false},
                {text: 'Дії', sortable: false}
            ],
            reworkModeEnum
        }),
        props: {
            students: Array,
            loading: Boolean,
            mode: {
                type: Number,
                default: () => {
                    return reworkModeEnum.deanAdmissions;
                }
            }
        },
        methods: {
            openAdmissionsByStudent(student) {
                this.selectedStudent = student;
                this.admissionsPerStudentDialog = true;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .reworks-list-dialog {
        overflow-y: unset !important;
    }
</style>