import AnnouncementView from '../views/mainDashboard/AnnouncementView';
import AnnouncementListView from '../views/mainDashboard/AnnouncementsListView';
import SurveysListView from '../views/mainDashboard/SurveysListView';
import SurveyView from '../views/mainDashboard/SurveyView';
import DashboardRootView from '../views/mainDashboard/DashboardRootView';

import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
    return [
        {
            path: '/dashboard',
            component: DashboardRootView,
            name: 'dashboardRoot',
            redirect: {name: 'announcementsList'},
            children: [
                {
                    path: 'announcements',
                    name: 'announcementsList',
                    component: AnnouncementListView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: 'announcements/:id',
                    name: 'announcementSingle',
                    component: AnnouncementView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },

                {
                    path: 'surveys',
                    name: 'surveysList',
                    component: SurveysListView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                {
                    path: 'surveys/:id',
                    name: 'surveySingle',
                    component: SurveyView,
                    beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
                },
                
            ]
        }
    ];
}