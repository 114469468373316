<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 100px); position: fixed; width: 360px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="360">
                        <v-list dense>
                            <v-subheader class="mt-1 grey--name name--darken-1 title">Групи</v-subheader>
                            <navigation-stud-nav-list route-to="admissionsGroup"
                                                      api-endpoint="navigation/getNavForDean"
                                                      :additional-route-params="{}"
                            />
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 360px; width: calc(100% - 460px);">
                <v-card>
                    <v-container v-if="!isSelectedGroup" grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть спеціальність, курс та групу
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>

                <dean-admissions-student-list v-if="isSelectedGroup" :key="$route.fullPath"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationStudNavList from "../../components/common/NavigationStudNavList";
    import DeanAdmissionsStudentList from "../../components/dean/admissionsGroup/DeanAdmissionsStudentList";
    
    export default {
        components: {
            NavigationStudNavList,
            DeanAdmissionsStudentList
        },
        data: () => ({
            isSelectedGroup: false, 
        }),
        watch: {
            $route: function(route, prevRoute) {
                if (route.params.group !== prevRoute.params.group) {
                    this.isSelectedGroup = route.params.group !== undefined;
                }
            },
        },
        mounted() {
            this.isSelectedGroup = this.$route.params.group !== undefined;
        }
    }
</script>
