<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <div class="headline">Модуль: {{form.module.name}}</div>
                        </v-flex>
                        
                        <v-flex xs12 md12>
                            <v-autocomplete
                                    :items="questionPools"
                                    :error-messages="errors.questionPool"
                                    v-model="form.questionPool"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Пули"
                            ></v-autocomplete>
                        </v-flex> 
                        <v-flex xs12 md12>
                            <v-autocomplete
                                    :items="professorsBySubject"
                                    :error-messages="errors.professors"
                                    v-model="form.professors"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    multiple
                                    label="Викладач (-і)"
                                    chips
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        flat
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        text
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    module: {
                        name: '',
                    },
                    name: null,
                    questionPool: null,
                    professors: [],
                },
                errors: {
                    name: [],
                    questionPool: [],
                    professors: [],
                },
                questionPools: [],
                professorsBySubject: [],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            HttpService.getInstance()
                .get("questionPool/getAll")
                .then(resp => {
                    this.questionPools = resp;
                });
            
            HttpService.getInstance()
                .get(`professor/getBySubject/${this.$route.params.subject}`)
                .then(resp => {
                    this.professorsBySubject = resp.map(p => {
                        p.name = `${p.surname} ${p.name} ${p.patronymic}`;
                        
                        return p;
                    });
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`modulePool/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .put("modulepool", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>