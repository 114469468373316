<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                width="250"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <v-container fluid>
                    <department-subject-switcher 
                            :type="navigationSelectorType"
                            :is-open="isDepartmentSubjectSwitcherOpened"
                            :is-show-button="isDepSubjNavigationVal"
                            @changeDialogState="isDepartmentSubjectSwitcherOpened = $event"
                            :route-to="switcherDialogRoute"
                            route-back="moderatorHome"
                    />
                </v-container>

                <v-subheader class="mt-1 grey--name name--darken-1">Електронний журнал</v-subheader>
                <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";
    import {navigationSelectorTypesEnum} from "../../../constants/navigationSelectorTypesEnum";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {            
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);

            this.dialogNavigationDepartmentSubject(this.$route.name);
        },
        watch: {
            $route (to, from){
                if (to.name != from.name) {
                    setTimeout(() => {
                        this.isDepartmentSubjectSwitcherOpened = false;
                    }, 100);
                }
                
                this.isDepSubjNavigation();
                this.dialogNavigationDepartmentSubject(to.name);
                
            }  
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            dialogNavigationDepartmentSubject(routeName) {
                if (!this.isDepSubjNavigation() && this.$route.name !== 'moderatorHome') {
                    this.navigationSelectorType = navigationSelectorTypesEnum.moderator;
                    
                    if (this.$route.params.department === undefined) {
                        this.isDepartmentSubjectSwitcherOpened = true;
                    }
                    
                    this.switcherDialogRoute = routeName;
                }

                if (this.$route.name === 'moderatorReworksRoot' || this.$route.name === 'moderatorTimeAccounting') {
                    this.navigationSelectorType = navigationSelectorTypesEnum.moderatorReworks;
                }
            },
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            },
            isDepSubjNavigation() {
                if (this.$route.name === 'moderatorReworksRoot' || this.$route.name === 'moderatorTimeAccounting') {
                   this.isDepSubjNavigationVal = this.$route.params.department !== undefined;
                   
                   return;
                }
                
                 this.isDepSubjNavigationVal = (this.$route.params.department !== undefined && 
                        this.$route.params.subject !== undefined);
            }
        },
        data: () => ({
            switcherDialogRoute: "",
            isDepartmentSubjectSwitcherOpened: false, 
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'alarm',
                    name: 'Відпрацювання',
                    route: {
                        name: 'moderatorReworksRoot'
                    }
                },
                {
                    icon: 'device_hub',
                    name: 'Розподіл груп',
                    route: {
                        name: 'groupDistribution'
                    }
                },
                {
                    icon: 'insert_invitation',
                    name: 'Навчальний план',
                    route: {
                        name: 'educationalPlan'
                    }
                },
                {
                    icon: 'date_range',
                    name: 'Облік академічного часу',
                    route: {
                        name: "moderatorTimeAccounting"
                    }
                },
                {
                    divider: true
                },
                {
                    icon: 'account_balance',
                    name: 'Журнали',
                    route: {name: 'moderatorRegistersRoot'}
                },
                {
                    divider: true
                },
            ],
            navigationSelectorType: navigationSelectorTypesEnum.moderator,

            isDepSubjNavigationVal: true,
        })
    }
</script>