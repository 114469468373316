import EmptyView from "../views/EmptyView";
import QuizListYearRootView from '../components/quizList/QuizListYearRootView';
import QuizListRootView from "../views/quizList/QuizListRootView";
import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/quizList',
         name: 'quizListRoot',
         component: QuizListRootView,
         children: [
             {
                 path: '',
                 redirect: {name: 'stepAdmin'},
             },
             {
                 path: '/quizList/:subject/:module/:topic?',
                 name: 'quizList',
                 component: QuizListYearRootView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
             },
             
         ],
     },
 ];
}