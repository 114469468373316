<template>
    <v-container grid-list-md>
        <announcements-list/>
    </v-container>
</template>
<script>
    import AnnouncementsList from "../../components/dashboard/AnnouncementsList";

    export default {
        components: {
            AnnouncementsList
        },
    }
</script>