<template>
    <div>
        <v-card class="mb-5" v-if="!loading">
            <v-card-text>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів:</div>
                    <div class="title">{{totalRating.totalSum}}</div>
                </div>
                <div class="d-flex align-center">
                    <div class="title font-weight-light mr-5">Загальна кількість балів (згідно коефіцієнтів):</div>
                    <div class="title">{{totalRating.totalSumRatio}}</div>
                </div>
                <div class="d-flex align-center mt-2">
                    <div v-if="totalRating.isConfirmed" class="title font-weight-light">
                        Кафедральний рейтинг: 
                        <v-chip
                                color="green"
                                text-color="white"
                        >
                            Затверджено завідувачем кафедрою
                        </v-chip></div>
                    <div v-if="!totalRating.isConfirmed" class="title font-weight-light">
                        Кафедральний рейтинг:
                        <v-chip
                                color="red"
                                text-color="white"
                        >
                            НЕ затверджено завідувачем кафедрою
                        </v-chip></div>
                </div>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <v-simple-table v-if="professorsRating.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                ПІП викладача
                            </th>
                            <th class="text-left" v-for="direction in professorsRating[0].ratingDirections">
                                {{direction.order}}. {{direction.directionName}}
                            </th>
                            <th class="text-left">Загальна сума</th>
                            <th class="text-left">
                                Затверджено викладачем
                            </th>
                            <th class="text-left">
                                Затверджено зав. кафедрою
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="professorRating in professorsRating">
                            <td>
                                <div>
                                    {{professorRating.surname}} {{professorRating.name}} {{professorRating.patronymic}}
                                </div>
                            </td>
                            <td v-for="direction in professorRating.ratingDirections">
                                {{direction.pointsSum}}
                            </td>
                            <td>
                                <div class="subtitle-1 font-weight-bold">{{professorRating.totalSum}}</div>
                            </td>
                            <td>
                                <v-chip
                                        v-if="professorRating.isConfirmed"
                                        color="green"
                                        text-color="white"
                                >
                                    Затверджено
                                </v-chip>
                                <v-chip
                                        v-if="!professorRating.isConfirmed"
                                        color="red"
                                        text-color="white"
                                >
                                    Не затверджено
                                </v-chip>
                            </td>
                            <td>
                                <v-chip
                                        v-if="professorRating.isDptHeadApproved"
                                        color="green"
                                        text-color="white"
                                >
                                    Затверджено зав. каф.
                                </v-chip>
                                <v-chip
                                        v-if="!professorRating.isDptHeadApproved"
                                        color="red"
                                        text-color="white"
                                >
                                    Не затверджено зав. каф.
                                </v-chip>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>

        <rating-check-directions
                :rating-worktype-directions="ratingWorktypeDirections"
                route-to-name="ratingCheckWorkTypes"
                :route-to-params="{department: $route.params.department}"
        ></rating-check-directions>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import RatingDirections from "../../rating/ratingDirections/RatingDirections";
    import PrintRatingDialog from "../../rating/ratingDirections/PrintRatingDialog";
    import RatingCheckDirections from "./RatingCheckDirections";

    export default {
        components: {
            PrintRatingDialog,
            RatingCheckDirections,
        },
        mounted() {
            if (this.$route.params.department) {
                this.getProfessorRatingSummary();
                this.getRatingSummary();
                this.getItems();
            }
        },
        data: () => {
            return {
                loading: false,
                ratingWorktypeDirections: [],
                totalRating: {
                    id: null,
                    totalSum: 0,
                    totalSumRatio: 0,
                    isConfirmed: null,
                    confirmationDate: null,
                },

                professorsRating: [],
            }
        },
        methods: {
            getProfessorRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/departmentProfessorsRatingSummary/${this.$route.params.department}`)
                    .then(ratingSummary => {
                        this.loading = false;

                        this.professorsRating = ratingSummary;
                    })
            },
            getRatingSummary() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDepartmentRatingSummary/${this.$route.params.department}`)
                    .then(totalRating => {
                        this.totalRating = totalRating;

                        this.loading = false;
                    });
            },
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getCheckDirectionsDepartmentWithSum/${this.$route.params.department}`)
                    .then(directions => {
                        this.ratingWorktypeDirections = directions;

                        this.loading = false;
                    })
            },
        },
    }
</script>
<style lang="scss">
    .header-wrapper {
        min-height: 90px;
    }

    .no-word-break {
        word-break: normal !important;
    }
</style>