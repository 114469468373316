export const staffTypesEnum = {
    professor: 1,
    educationalDepartment: 2,
    deanOffice: 3,
    laboratoryStaff: 4,
    additionalStaff: 5,
    adminNb: 6,
};

export default [
    {
        id: staffTypesEnum.professor,
        nameUk: "Викладач",
        nameEn: "Professor",
    },
    {
        id: staffTypesEnum.educationalDepartment,
        nameUk: "Навчальний відділ",
        nameEn: "Educational Department",
    },
    {
        id: staffTypesEnum.deanOffice,
        nameUk: "Персонал деканату",
        nameEn: "Dean office stuff",
    },
    {
        id: staffTypesEnum.laboratoryStaff,
        nameUk: "Лаборант",
        nameEn: "Laboratory Staff",
    },
    {
        id: staffTypesEnum.additionalStaff,
        nameUk: "Додатковий персонал",
        nameEn: "Additional Stuff",
    },
    {
        id: staffTypesEnum.adminNb,
        nameUk: "Адмін Нб",
        nameEn: "Admin Nb",
    },

];
