<template>
    <div>
        <v-layout row wrap class="mt-6">
            <v-flex xs12 text-center>
                <router-link :to="{name: 'surveysList'}" class="display-1 cursor-pointer">
                    {{ $t("surveys.title") }}
                </router-link>
            </v-flex>
        </v-layout>
        <v-layout row wrap justify-center>
            <v-flex v-if="surveys.length === 0" xs12 sm12>
                <v-alert outlined color="info" icon="info" :value="true">
                    <div class="subtitle-1">{{ $t("surveys.norecords") }}</div>
                </v-alert>
            </v-flex>
            <v-flex xs12 sm6 v-for="(survey, index) in surveys" :key="index">
                <survey-short :survey="survey"/>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import SurveyShort from "./SurveyShort";
    import HttpService from "../../HttpService";
    
    export default {
        components: {
            SurveyShort  
        },
        data: () => ({
            surveys: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get("survey")
                .then(resp => {
                    this.surveys = resp;
                })
        },
    }
</script>