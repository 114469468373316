<template>
    <v-card>
        <v-card-title class="title">Відпрацювання ПМК</v-card-title>

        <v-card-text>
            <v-layout class="mb-2" align-center wrap>
                <v-flex xs4 class="text-center font-weight-medium" style="border-right: 1px solid #ccc">
                    <div>
                        <v-avatar class="mb-1" size="80px">
                            <v-icon v-if="gendersEnum.male === student.gender">person</v-icon>
                            <v-icon v-if="gendersEnum.female === student.gender">face</v-icon>
                        </v-avatar>
                    </div>
                    <div class="title">{{`${student.surname} ${student.name} ${student.patronymic}`}}</div>
                    <div class="subtitle-1">{{student.faculty.nameUk}}</div>
                    <div class="subtitle-1">{{student.speciality.nameUk}}</div>
                    <div class="subtitle-1">{{student.semester.yearNumber}} курс</div>
                    <div class="subtitle-1">{{student.academicGroup.groupNumber}} група</div>
                </v-flex>
                <v-flex xs8 class="text-center">
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Дисципліна: </span>
                        {{reworkItem.subject.nameUk}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Модуль: </span>
                        {{reworkItem.module.nameUk}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Кількість "2": </span>
                        {{reworkItem.countUnsatisfactory}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Кількість "нб": </span>
                        {{reworkItem.countAbsents}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Поточний бал ECTS: </span>
                        {{reworkItem.moduleControlMark.marksValueCurrent}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Бали за модульний контроль: </span>
                        {{reworkItem.moduleControlMark.moduleControlPoints}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Загальний бал ECTS: </span>
                        {{reworkItem.moduleControlMark.totalPoints}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Оцінка ABC: </span>
                        {{reworkItem.moduleControlMark.totalAbc}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Традиційна оцінка: </span>
                        {{reworkItem.moduleControlMark.totalTraditional}}
                    </div>
                    <div class="subtitle-1">
                        <span class="font-weight-bold">Дата проведення: </span>
                        {{formatShortDate(reworkItem.moduleControlMark.classDate)}}
                    </div>
                    
                    <div class="mt-6 d-flex justify-center" 
                         v-if="reworkItem.module.finalControlType === finalControlTypesEnum.finalModuleControl">
                        <table class="modules-table elevation-3">
                            <thead>
                            <tr>
                                <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                    Середній бал
                                </th>
                                <th style="max-width: 80px"  class="mark-input-control-cell-header">
                                    Поточний бал
                                </th>
                                <th class="mark-input-control-cell-header">
                                    ІСРС
                                </th>
                                <th class="mark-input-control-cell-header">
                                    Результат МК
                                </th>
                                <th class="mark-input-control-cell-header">
                                    Загальна оцінка
                                </th>
                                <th class="mark-input-control-cell-header">
                                    Традиційна оцінка
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="reworkItemData != null">
                                <td class="mark-input-control-cell text-center">
                                    {{reworkItemData.markValueMean ? reworkItemData.markValueMean.toFixed(2) : "-"}}
                                </td>
                                <td class="mark-input-control-cell text-center">{{reworkItem.moduleControlMark.marksValueCurrent}}</td>
                                <td class="mark-input-control-cell text-center">{{reworkItemData.individualWorkPoints}}</td>
                                <td class="mark-input-control-cell text-center">
                                    <div class="module-points">
                                        <v-text-field
                                                v-model.number="reworkItemData.moduleControlMark.moduleControlPoints"
                                                solo
                                                label="Бали"
                                                hide-details
                                                :error-messages="errors.moduleControlPoints"
                                                @input="debouncedUpdate($event, reworkItemData.moduleControlMark)"
                                                @keypress="isNumber($event, 2)"
                                                clearable
                                                class="mb-2"
                                        ></v-text-field>
                                    </div>
                                </td>
                                <td class="mark-input-control-cell text-center">
                                    <div class="title">{{reworkItemData.moduleControlMark.totalPoints}}</div>
                                </td>
                                <td class="mark-input-control-cell  text-center">
                                    <v-chip v-if="reworkItemData.moduleControlMark.totalTraditional && !getIsError(reworkItemData.moduleControlMark)"
                                            text-color="white"
                                            :color="getValueTraditionalColor(reworkItemData.moduleControlMark.totalTraditional)">
                                        <span class="title">{{reworkItemData.moduleControlMark.totalTraditional}}</span>
                                    </v-chip>
                                    <div v-if="getIsError(reworkItemData.moduleControlMark)">
                                        <v-chip text-color="white" color="red">Помилка</v-chip>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-center mt-4">
                        <v-flex xs12 md6 v-if="reworkItemData !== null">
                            <date-picker
                                    v-model="reworkItemData.moduleControlMark.classDate"
                                    label="Дата перескладання"
                                    :max-date="new Date()"
                            ></date-picker>
                        </v-flex>
                    </div>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseSaveConfirm')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    v-if="reworkItem.couldReworkModule"
                    color="primary"
                    text
                    @click.native="saveRework"
                    :loading="loading"
                    :disabled="loading"
            >Зберегти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import MarkInput from "../../../components/common/MarkInput";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {gendersEnum} from "../../../constants/_internal/gendersEnum";
    import {isNumber} from "../../../helpers/numberHelper";
    import cloneDeep from "lodash/cloneDeep";
    import debounce from "lodash/debounce";
    import {finalControlTypesEnum} from "../../../constants/admin/finalControlTypes";
    import DatePicker from "../../common/DatePicker";
    import moment from "moment";

    export default {
        components: {
            DatePicker,
            MarkInput
        },
        props: {
            student: Object,
            reworkItem: Object,
        },
        data: () => {
            return {
                loading: false,
                formatShortDate,
                newMarkValue: {
                    id: null,
                    value: null,
                },
                errors: {
                    moduleControlPoints: null,
                },
                settings: {
                    maxModuleControlPoints: 0,
                    maxModulePoints: 0,
                    minModuleControlPoints: 0,
                    minModulePoints: 0,
                    points3: 0,
                    points4: 0,
                    points5: 0,
                },
                reworkItemData: null,
                gendersEnum,
                isNumber,

                finalControlTypesEnum,
            };
        },
        mounted() {
            this.reworkItemData = cloneDeep(this.reworkItem);

            if (this.reworkItemData && this.reworkItemData.moduleControlMark) {
                this.reworkItemData.moduleControlMark.classDate = moment().toDate();
            }

            this.loading = true;

            return HttpService.getInstance()
                .get(`settings/ectsModule`)
                .then((resp) => {
                    this.settings = resp;
                    this.loading = false;
                });
        },
        methods: {
            debouncedUpdate: debounce(function (event, moduleView) {
                this.updateTotalPoints(event, moduleView);
                this.updateTraditionalMark(moduleView);
            }, 200),
            updateTotalPoints(event, moduleView) {
                
                moduleView.totalPoints = null;
                if (!event) {
                    return;
                }

                const moduleControlPointsParsed = parseFloat(event);

                moduleView.totalPoints = moduleView.marksValueCurrent + this.reworkItem.individualWorkPoints + moduleControlPointsParsed;
            },
            updateTraditionalMark(moduleView) {
                moduleView.totalTraditional = null;
                if (moduleView.moduleControlPoints === null) {
                    return '';
                }

                if (moduleView.moduleControlPoints <= this.settings.maxModuleControlPoints &&
                    moduleView.moduleControlPoints >= this.settings.minModuleControlPoints) {

                    if (moduleView.totalPoints <= this.settings.maxModulePoints &&
                        moduleView.totalPoints >= this.settings.points5) {
                        moduleView.totalTraditional = 5;
                    }

                    else if (moduleView.totalPoints <= this.settings.points5 &&
                        moduleView.totalPoints >= this.settings.points4) {
                        moduleView.totalTraditional = 4;
                    }

                    else if (moduleView.totalPoints <= this.settings.points4 &&
                        moduleView.totalPoints >= this.settings.points3) {
                        moduleView.totalTraditional = 3;
                    }
                    else {
                        moduleView.totalTraditional = 2;
                    }
                }
                else if (moduleView.moduleControlPoints < this.settings.minModuleControlPoints) {
                    moduleView.totalTraditional = 2;
                }
            },
            getIsError(moduleView) {
                if (moduleView.moduleControlPoints !== null &&
                    moduleView.moduleControlPoints > this.settings.maxModuleControlPoints) {
                    return true;
                }
                return false;
            },
            getValueTraditionalColor(valueTraditional) {
                switch (valueTraditional) {
                    case 5:
                        return 'green accent-4';
                    case 4:
                        return 'light-blue darken-3';
                    case 3:
                        return 'orange darken-1';
                    case 2:
                        return 'red darken-1';
                    default:
                        return '';
                }
            },
            saveRework() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`teacher/teacherReworks/moduleRework`, this.reworkItemData.moduleControlMark)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("saved");
                    })
                    .catch(err => {
                        this.loading = false;

                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        },
    }
</script>
<style lang="scss">
    table.modules-table {
        & td.mark-input-control-cell {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding-left: 8px;
            padding-right: 8px;
            max-width: 235px;

            &:last-child {
                border-right: none;
            }
        }

        & th.mark-input-control-cell-header {
            padding: 0 5px;
            max-width: 235px;
        }
    }
</style>