<template>
    <v-card class="mb-1">
        <v-card-text 
                class="answer"
                @click="$emit('selectAnswer', answer)"
        >
            {{answer.answer}}
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            answer: {
                type: Object,
                default: () => {
                    return {
                        id: null,
                        answer: '',
                    }
                }
            },
        },
    }
</script>
<style lang="scss">
    .answer {
        &:hover {
            cursor: pointer;
            background: #BBDEFB;
        }
    }
</style>