export const answerRightTypeEnum = {
    firstAnswerRight: 0,
    asteriskAnswerRight: 1,
};

export default [
    {
        id: answerRightTypeEnum.firstAnswerRight,
        name: "Перша відповідь"
    },
    {
        id: answerRightTypeEnum.asteriskAnswerRight,
        name: "Позначена *"
    },
];
