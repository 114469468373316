import ModeratorRootView from '../views/moderator/ModeratorRootView';
import ModeratorHomeView from "../views/moderator/ModeratorHomeView";
import ReworksRootView from "../views/common/ReworksRootView";
import IndividualRootView from "../views/moderator/IndividualRootView";
import GroupDistributionRootView from "../views/moderator/GroupDistributionRootView";
import TopicsRootView from "../views/moderator/TopicsRootView";
import IndividualWorkRootView from "../views/moderator/IndividualWorkRootView";

import routeGuard from "../routeGuards/routeGuard";
import LecturesRootView from "../views/moderator/LecturesRootView";
import ModeratorSuperAdminPreSelect from "../views/moderator/ModeratorSuperAdminPreSelect";
import EmptyView from "../views/common/EmptyView";
import ChiefDeptRegistersView from "../views/chiefDept/Register/ChiefDeptRegistersView";
import ModeratorRegisterDeepRootView from "../views/moderator/ModeratorRegisterDeepRootView";
import ModeratorRegisterRootView from "../views/moderator/ModeratorRegisterRootView";
import {reworksViewMode} from "../constants/_internal/reworksViewMode";
import routeGuardModerator from "../routeGuards/routeGuardModerator";
import AcademicTimeAccountanceView from "@/views/teacher/AcademicTimeAccountanceView";
import routeGuardTeacher from "@/routeGuards/routeGuardTeacher";
import ModeratorAcademicTimeAccountanceView from "@/views/moderator/ModeratorAcademicTimeAccountanceView";

export default (router) => {
 return [
     {
         path: '/moderator',
         component: ModeratorRootView,
         name: 'moderatorRoot',
         beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
         redirect: {name: 'moderatorSuperAdminPreselect'},
         children: [
             {
                 path: 'moderator-sa-preselect',
                 name: "moderatorSuperAdminPreselect",
                 component: ModeratorSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: '',
                 name: 'moderatorHome',
                 component: ModeratorHomeView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'reworks/:department?/:professor?',
                 name: "moderatorReworksRoot",
                 component: ReworksRootView,
                 props: { mode: reworksViewMode.reworkMarksModerator },
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'groupDistribution/:department?/:subject?/:professor?',
                 name: "groupDistribution",
                 component: GroupDistributionRootView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'educationalPlan/:department?/:subject?',
                 name: "educationalPlan",
                 component: IndividualRootView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'educationalPlan/:department?/:subject?/topic/:module?',
                 name: "topicsPlan",
                 component: TopicsRootView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'educationalPlan/:department?/:subject?/individualWork/:module?',
                 name: "individualWorkPlan",
                 component: IndividualWorkRootView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'educationalPlan/:department?/:subject?/lectures/:module?',
                 name: "lecturesPlan",
                 component: LecturesRootView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
             {
                 path: 'timeAccounting/:department?',
                 name: 'moderatorTimeAccounting',
                 component: ModeratorAcademicTimeAccountanceView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'moderatorRegister',
                 component: EmptyView,
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
                 children: [
                     {
                         path: '/moderator/registers/:department?/:subject?',
                         name: 'moderatorRegistersRoot',
                         component: ChiefDeptRegistersView,
                         props: {routeTo: "moderatorRegisters"}
                     }
                 ]
             },
         ],
     },
     {
         path: '/moderator/registers/:department?/:subject?/:faculty?/:speciality?/:year?/:group?',
         component: ModeratorRegisterDeepRootView,
         name: 'moderatorRegistersFullRoot',
         redirect: {name: 'moderatorRegisters'},
         children: [
             {
                 path: '/moderator/registers/:department?/:subject?/:faculty?/:speciality?/:year?/:group?',
                 name: "moderatorRegisters",
                 component: ModeratorRegisterRootView,
                 props: {routeTo: "moderatorRegisters"},
                 beforeEnter: (to, from, next) => routeGuardModerator(to, from, next)(router),
             },
         ]
     },
 ];
}