import ReworksRootView from '../views/reworks/ReworksRootView';
import ReworksHomeView from "../views/reworks/ReworksHomeView";
import ReworksMarksRootView from "../views/reworks/ReworksMarksRootView";

import routeGuard from "../routeGuards/routeGuard";
import ReworksSuperAdminPreSelect from "../views/reworks/ReworksSuperAdminPreSelect";
import RewokrsModulesRootView from "../views/reworks/RewokrsModulesRootView";
import routeGuardTeacher from "../routeGuards/routeGuardTeacher";

export default (router) => {
 return [
     {
         path: '/reworks',
         component: ReworksRootView,
         name: 'reworksRoot',
         beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
         redirect: {name: 'reworkSuperAdminPreselect'},
         children: [
             {
                 path: 'rework-sa-preselect',
                 name: "reworkSuperAdminPreselect",
                 component: ReworksSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: '',
                 name: 'reworksHome',
                 component: ReworksHomeView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'reworkMarks/:department?/:subject?',
                 name: "reworkMarksRoot",
                 component: ReworksMarksRootView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'reworkModules/:department?/:subject?',
                 name: "reworkModulesRoot",
                 component: RewokrsModulesRootView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
         ]
     },
 ];
}