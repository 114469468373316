import LabstaffRootView from '../views/labstaff/LabstaffRootView';
import LabstaffHomeView from "../views/labstaff/LabstaffHomeView";
import FlowSelectionRootView from "../views/labstaff/FlowSelectionRootView";

import routeGuard from "../routeGuards/routeGuard";

export default (router) => {
 return [
     {
         path: '/labstaff',
         component: LabstaffRootView,
         name: 'labstaffRoot',
         redirect: {name: 'labstaffHome'},
         children: [
             {
                 path: '',
                 name: 'labstaffHome',
                 component: LabstaffHomeView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
             },
             {
                 path: 'flowSelection/:department?/:subject?/:flow?',
                 name: "flowSelection",
                 component: FlowSelectionRootView,
                 beforeEnter: (to, from, next) => routeGuard(to, from, next)(router),
             },
         ],
     },
 ];
}