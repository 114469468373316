<template>
    <div style="position: relative">
        <block-loader v-if="loading"/>
        <v-container class="register-container">
            <v-layout justify-center>
                <v-flex xs12>
                    <table class="marks-table elevation-3 mb-6">
                        <thead>
                            <tr>
                                <th colspan="2" class="student-name corner-left-bottom">
                                    ПІП студента
                                </th>
                                <th class="header-cell">
                                    Прогрес виконання самостійної роботи в eAristo
                                </th>
                                <th class="header-cell">Дії</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(modInfo, index) in individualWorkInfo">
                                <td class="counter-cell">{{index + 1}}. </td>
                                <td class="student-name">{{modInfo.student.surname}} {{modInfo.student.name}}</td>
                                <td class="cell px-2">
                                    <v-progress-linear
                                        v-model="modInfo.progress"
                                        :color="getProgressColor(modInfo)"
                                        height="25"
                                    >
                                        <strong>{{ modInfo.progress.toFixed(1) }}%</strong>
                                    </v-progress-linear>
                                </td>
                                <td class="pl-1 pr-1">
                                    <div class="d-flex justify-start">
                                        <v-btn @click="showDetails(modInfo.student)" icon class="mx-2">
                                            <v-icon color="primary">visibility</v-icon>
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-flex>
            </v-layout>
        </v-container>

        <v-dialog v-model="showIndividualWorkDialog" max-width="750px" scrollable>
            <add-individual-work-dialog
                    v-if="showIndividualWorkDialog"
                    :activeModule="activeModule"
                    :student="detailsStudent"
                    @shouldClose="showIndividualWorkDialog = false; showIndividualWorkDialog = null"
            ></add-individual-work-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import AddIndividualWorkDialog from "./AddIndividualWorkDialog";
    import BlockLoader from "../../common/BlockLoader";
    import {formatShortDate} from "../../../helpers/dateHelper";
    
    export default {
        components: {
            AddIndividualWorkDialog,
            BlockLoader,
        },
        props: {
            activeModule: {
                type: Object
            },
        },
        data() {
            return {
                fab: false,
                showIndividualWorkDialog: false,
                detailsStudent: null,
                
                individualWorkInfo: [],
                loading: false,
                formatShortDate,

                progress: 25,
            }
        },
        mounted() {
            this.$ga.event('professor', 'getIndividualWorks', 'groupId', this.$route.params.group);

            this.getModule();
        },
        methods: {
            // getMarkClass(modInfo) {
            //     if (modInfo.moduleControlMark && modInfo.moduleControlMark.totalTraditional) {
            //         return `mark mark${modInfo.moduleControlMark.totalTraditional}`;
            //     }
            //     else {
            //         return "mark mark0";
            //     }
            // },
            showDetails(student) {
                this.detailsStudent = student;
                
                this.showIndividualWorkDialog = true;
            },
            getModule() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`teacher/teacherIndividualWorkMarks/${this.$route.params.group}/${this.activeModule.id}`)
                    .then(res => {
                        this.individualWorkInfo = res;
                        this.loading = false;
                    });
            },
            getProgressColor(modInfo) {
                if (modInfo.progress === 0) {
                    return 'red';
                }
                else if (modInfo.progress > 0 && modInfo.progress < 50) {
                    return 'yellow';
                }
                else if (modInfo.progress >= 50 && modInfo.progress < 99) {
                    return 'blue';
                }
                else if (modInfo.progress === 100) {
                    return 'green';
                }
                else {
                    return 'gray';
                }
            },
            moduleAdded() {
                this.showIndividualWorkDialog = false;
            },
        }
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }
    .student-name-text {
        position: absolute;
        bottom: 2px;
        left: 2px;
    }

    .header-cell {
        text-align: center;
        vertical-align: middle;
        padding: 10px 5px 10px 5px;
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        min-width: 25px;
        font-weight: 500;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
                
                &.student-name {
                    padding: 5px 10px 5px 5px;
                    min-width: 200px;
                    border-right: 1px solid #ccc;
                }
                
                &.cell {
                    min-height: 15px;
                    text-align: center;
                    border-right: 1px solid #ccc;
                }
                
                &.individual-work-type {
                    padding: 5px 10px 5px 5px;
                }
            }
        }
    }
</style>