<template>
    <div>
        <v-navigation-drawer
                :width="250"
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense>
                <navigation-list-template :items="dictionaryItems" @selectValue="setTopNavigationItem($event)"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import {mapMutations} from "vuex";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        mounted() {
            this.dictionaryItems = this.remapItems(this.dictionaryItemsRaw);
            
            this.dictionaryItems = this.translateItems(this.dictionaryItems);

            this.dialogNavigationDepartmentSubject();
        },
        watch: {
            $route (to, from){
                this.dialogNavigationDepartmentSubject();
            }  
        },
        methods: {
            ...mapMutations(['setTopNavigationItem']),
            dialogNavigationDepartmentSubject() {
                if (!this.isDepSubjNavigation() && this.$route.name === 'marksRoot') {
                    this.isDepartmentSubjectSwitcherOpened = true;
                }
            },
            translateItems(items) {
              return items.map(i => {
                  i.name = this.$t(i.nameConst);
                  
                  return i;
              });  
            },
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    if (item.children !== undefined) {
                        item.children = item.children.map(ch => {
                            if (ch.route && ch.route.name && this.$route.name === ch.route.name) {
                                ch.model = true;
                                this.setTopNavigationItem(ch);
                            }
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    if (item.route && item.route.name && this.$route.name === item.route.name) {
                        this.setTopNavigationItem(item);
                    }

                    return item;
                })
            },
            isDepSubjNavigation() {
                return (this.$route.params.department !== undefined && 
                        this.$route.params.subject !== undefined);
            }
        },
        data: () => ({
            isDepartmentSubjectSwitcherOpened: false, 
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'turned_in',
                    nameConst: "nbSupervisor.quizComputers",
                    route: {
                        name: 'nbSupervisorHome'
                    }
                },
                {
                    divider: true
                },
            ],
        })
    }
</script>