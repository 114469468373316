<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">
                    <v-btn text color="primary" @click="$emit('openAdmissions', props.item)">Допуски</v-btn>
                </td>
                <td class="text-xs-left">{{ props.item.user.username }}</td>
                <td class="text-xs-left">{{ `${props.item.student.surname} ${props.item.student.name} ${props.item.student.patronymic}` }}</td>
                <td class="text-xs-left">{{ props.item.orderId }}</td>
                <td class="text-xs-left">{{ props.item.description }}</td>
                <td class="text-xs-left">{{ props.item.amount }}</td>
                <td class="text-xs-left">{{ props.item.currency }}</td>
                <td class="text-xs-left">{{ formatShortDate(props.item.endDate) }}</td>
                <td class="text-xs-left">{{ props.item.senderCardMask }}</td>
                <td class="text-xs-left">
                    <v-chip :color="getPaymentColor(props.item.paymentStatus)" text-color="white">
                        {{getPaymentStatus(props.item.paymentStatus)}}
                    </v-chip>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import paymentStatuses, {paymentStatusesEnum} from "../../../constants/paymentStatuses";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Дії', sortable: false},
                {text: 'Логін', sortable: false},
                {text: 'ПІП студента', sortable: false},
                {text: 'OrderId', sortable: false},
                {text: 'Опис', sortable: false},
                {text: 'Сума', sortable: false},
                {text: 'Грошова одиниця', sortable: false},
                {text: 'Дата оплати', sortable: false},
                {text: 'Картка', sortable: false},
                {text: 'Статус', sortable: false},
            ],
            formatShortDate,
        }),
        methods: {
            getPaymentStatus(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.nameUk;
            },
            getPaymentColor(paymentStatusId) {
                const paymentStatus = paymentStatuses.find(p => p.id === paymentStatusId);

                if (paymentStatus === undefined) {
                    return "";
                }

                return paymentStatus.color;
            }
        }
    }
</script>