<template>
    <div>
        <vue-headful title="ePlato"/>
        <student-dashboard></student-dashboard>
    </div>
</template>

<script>
    import StudentDashboard from "../../components/finalExam/studentDashboard/StudentDashboard";

    export default {
        components: {
            StudentDashboard,
        },
        data: () => ({
        }),
        
    }
</script>