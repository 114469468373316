<template>
    <v-card>
        <v-card-title class="title">Анонімна оцінка матеріалу</v-card-title>
        <v-card-text>
            <div class="mb-2">
                <div>Загальна оцінка матеріалу</div>
                <v-rating
                    v-model="rating"
                    background-color="indigo lighten-3"
                    color="indigo"
                    size="40"
                ></v-rating>
            </div>
            <div class="mb-2">
                <div>Оцінка змісту матеріалу</div>
                <v-rating
                    v-model="rating1"
                    background-color="indigo lighten-3"
                    color="indigo"
                    size="40"
                ></v-rating>
            </div>
            <div class="mb-2">
                <div>Оцінка викладення матеріалу</div>
                <v-rating
                    v-model="rating2"
                    background-color="indigo lighten-3"
                    color="indigo"
                    size="40"
                ></v-rating>
            </div>
            <div class="mb-2">
                <div>Оцінка повноти матеріалу</div>
                <v-rating
                    v-model="rating3"
                    background-color="indigo lighten-3"
                    color="indigo"
                    size="40"
                ></v-rating>
            </div>
            <v-textarea
                solo
                name="input-7-4"
                label="Текст відгуку"
            ></v-textarea> 
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseForm')"
                    :disabled="loading"
            >Відміна
            </v-btn>
            <v-btn
                    text
                    @click.native="removeItem"
                    :loading="loading"
                    :disabled="loading"
            >Відправити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        data: () => {
            return {
                loading: false,
                rating: 4,
                rating1: 5,
                rating2: 3,
                rating3: 5,
            };
        },
        props: {
            topicId: Number,
        },
        methods: {
            removeItem() {
                this.loadingDelete = true;

                HttpService.getInstance()
                    .delete(`/${this.topicId}`)
                    .then(resp => {
                        this.loading = false;

                        this.$emit("removed");
                    });
            }
        }
    }
</script>