<template>
    <div>
        <deans-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
        >
        </deans-grid>

        <v-dialog v-model="dialog" max-width="700px" scrollable>
            <add-edit-dean
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-dean>
        </v-dialog>

    </div>
</template>
<script>
    import DeansGrid from "./DeansGrid";
    import AddEditDean from "./AddEditDean";

    import HttpService from "../../../../HttpService";
    import {objectQueryStringify} from "../../../../helpers/querystringHelper";

    export default {
        components: {
            DeansGrid,
            AddEditDean,
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`deans?${queryParams}`)
                    .then(faculties => {
                        this.items = faculties.items;
                        this.total = faculties.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            addedEdited() {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
        data: () => {
            return {
                dialog: false,
                editId: null,
                editMode: false,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>