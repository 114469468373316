<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">{{ props.item.surname }} {{ props.item.name }} {{ props.item.patronymic }}</td>
                <td class="text-xs-left">{{ props.item.user.username }}</td>
                <td class="text-xs-left">
                    <v-chip color="primary" :key="index" v-for="(role, index) in props.item.user.roles">{{role.name}}</v-chip>
                </td>
                <td class="text-xs-left">
                    <v-chip color="red" text-color="white">{{ getStaffTypeName(props.item.staffType) }}</v-chip>
                </td>
                <td class="text-xs-left">{{ props.item.user.email }}</td>
                <td class="text-xs-left">{{ formatShortDate(props.item.birthday) }}</td>

                <td class="justify-center layout px-2">
                    <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import {formatShortDate} from "../../../helpers/dateHelper";
    import staffTypes from "../../../constants/staffTypes";
    
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
            getStaffTypeName(staffId) {
                const staffType = staffTypes.find(s => s.id === staffId);
                if (staffType) {
                    return staffType.nameUk;
                }
                
                return "";
            }
        },
        data: () => ({
            formatShortDate,
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'ПІП', value: 'surname'},
                {text: 'Логін', value: 'user.username', sortable: false},
                {text: 'Ролі', value: 'user.role', sortable: false},
                {text: 'Тип персоналу', value: 'staffType', sortable: false},
                {text: 'Email', value: 'user.email', sortable: false},
                {text: 'Дата народження', value: 'birthday', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>