<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <admissions-journal-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import AdmissionsJournalIndex from "../../components/dean/admissionsJournal/AdmissionsJournalIndex";

    export default  {
        components: {
            AdmissionsJournalIndex,
        }
    }
</script>