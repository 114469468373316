<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex xs12>
                <v-card>
                    <education-plans-index/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import EducationPlansIndex from "../../../../components/admin/Dictionaries/EducationPlans/EducationPlansIndex";

    export default {
        components: {
            EducationPlansIndex,
        },
    }
</script>