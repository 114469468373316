<template>
    <div>
        <div>
            <v-container>
                <v-layout justify-center>
                    <v-flex xs6>
                        <v-select
                                :items="[100, 200]"
                                v-model="quantity"
                                :label="$t('studentKrok.questionsCount')"
                                box
                                hide-details
                        ></v-select>
                    </v-flex>
                    <v-flex xs6>
                        <v-btn
                                @click="startQuiz()"
                                class="primary"
                                :disabled="loading"
                                large
                        >{{$t("studentKrok.startQuiz")}}</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        
        <quiz-by-kti-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
        >
        </quiz-by-kti-grid>
    </div>
</template>
<script>
    import QuizByKtiGrid from "./QuizByKtiGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import store from "../../../store";

    export default {
        components: {
            QuizByKtiGrid
        },
        methods: {
            startQuiz() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`studentStep/startKtiQuiz/${this.quantity}`)
                    .then((quizId) => {
                        if (quizId !== undefined ){
                            this.$router.push({name: 'studentQuizTest', params: {quizId, questionNumber: 1}});
                        }
                        else {
                            store.commit("snackMessages/set", {message: "Немає КТІ для даного студента", color: 'warning'});
                        }
                        
                        
                        this.loading = false;
                    });
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentStep/getStudentKtiAttempts?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData))  {
                        this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
        watch: {
            '$route.params.subject': function (newSubjectId) {
                if (newSubjectId !== undefined) {
                    this.pagination = Object.assign({}, this.pagination);

                    this.getItems(objectQueryStringify(this.pagination), newSubjectId);
                }
            },
        },
        data: () => {
            return {
                quantity: 100,
                
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
            }
        }
    }
</script>