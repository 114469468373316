<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout v-if="!isBlank" row wrap>
            <v-flex xs12>
                <div class="headline">Виберіть викладача</div>

                <v-progress-circular v-if="loading" :size="150"
                                     color="primary"
                                     indeterminate></v-progress-circular>

                <v-simple-table v-if="professorsRating.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>Дії</th>
                            <th class="text-left">
                                ПІП викладача
                            </th>
                            <th class="text-left" v-for="direction in professorsRating[0].ratingDirections">
                                {{direction.order}}. {{direction.directionName}}
                            </th>
                            <th class="text-left">Загальна сума</th>
                            <th class="text-left">
                                Затверджено викладачем
                            </th>
                            <th class="text-left">
                                Затверджено зав. кафедрою
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="professorRating in professorsRating">
                            <td>
                                <v-btn icon 
                                       color="primary"
                                       :to="{ name: 'headDepartmentRating', params: { department: $route.params.department, professor: professorRating.id }}">
                                    <v-icon>send</v-icon>
                                </v-btn>
                            </td>
                           <td>
                               <div>
                                   {{professorRating.surname}} {{professorRating.name}} {{professorRating.patronymic}}
                               </div>
                           </td> 
                            <td v-for="direction in professorRating.ratingDirections">
                                {{direction.pointsSum}}
                            </td>
                            <td>
                                <div class="subtitle-1 font-weight-bold">{{professorRating.totalSum}}</div>
                            </td>
                           <td>
                               <v-chip
                                       v-if="professorRating.isConfirmed"
                                       color="green"
                                       text-color="white"
                               >
                                   Затверджено
                               </v-chip>
                               <v-chip
                                       v-if="!professorRating.isConfirmed"
                                       color="red"
                                       text-color="white"
                               >
                                   НЕ затверджено
                               </v-chip>
                           </td> 
                           <td>
                               <v-chip
                                       v-if="professorRating.isDptHeadApproved"
                                       color="green"
                                       text-color="white"
                               >
                                   Затверджено зав. каф.
                               </v-chip>
                               <v-chip
                                       v-if="!professorRating.isDptHeadApproved"
                                       color="red"
                                       text-color="white"
                               >
                                   НЕ затверджено зав. каф.
                               </v-chip>
                           </td> 
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                
<!--                <navigation-list-template :items="professors"/>-->
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            NavigationListTemplate
        },
        props: {
            routeTo: {
                type: String,
                defaultsTo: "registers"
            },
        },
        data: () => ({
            isBlank: true,

            loading: false,
            professorsRating: [],
        }),
        mounted() {
            this.checkIfIsBlank(this.$route);
            
            if (this.isBlank === false) {
                this.getProfessorRatingSummary();
            }
        },
        methods: {
            checkIfIsBlank(route) {
                if (route.params.department !== undefined) {
                    this.isBlank = false;
                }
                else {
                    this.isBlank = true;
                }
            },
            getProfessorRatingSummary() {
                this.loading = true;
                
                HttpService.getInstance()
                    .get(`ratingUser/departmentProfessorsRatingSummary/${this.$route.params.department}`)
                    .then(ratingSummary => {
                        this.loading = false;
                        
                        this.professorsRating = ratingSummary;
                    })
            }
        },
        watch: {
            $route(route) {
                this.checkIfIsBlank(route);
                
                if (this.isBlank === false) {
                    this.getProfessorRatingSummary();
                }
            }
        },
    }
</script>