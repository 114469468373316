<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field disabled v-model="form.faculty.nameUk"
                                          label="Факультет"></v-text-field>
                        </v-flex>
                        <v-flex xs12 md12>
                            <v-autocomplete
                                    :items="deans"
                                    :error-messages="errors.deans"
                                    v-model="form.deans"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    multiple
                                    chips
                                    solo
                                    label="Декани"
                            ></v-autocomplete>
                        </v-flex>

                        <v-flex xs12 md12>
                            <v-alert type="warning">
                                Редагування інспекторів та призначення до факультетів проводиться через 
                                <a :to="{name: 'staffManagementGrid'}">пункт меню "Персонал"</a>.
                            </v-alert>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    faculty: {},
                    deans: [],
                },
                errors: {
                    deans: [],
                },
                deans: [],
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`professors/getAll`)
                    .then(resp => {
                        this.deans = resp.map(prof => {
                            let profNamePrefix = "";
                            
                            if (prof.academicPosition) {
                                profNamePrefix = `${prof.academicPosition.nameShortUk} `;
                            }
                            
                            prof.name = `${profNamePrefix}${prof.surname} ${prof.name} ${prof.patronymic}`;

                            return prof;
                        });
                    });

                HttpService.getInstance()
                    .get(`deans/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .put("deans", this.form)
                    .then(() => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>