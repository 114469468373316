<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <krok-results-wrapper></krok-results-wrapper>
        </v-layout>
    </v-container>
</template>
<script>
    import KrokResultsWrapper from "../../../components/admin/KrokResults/KrokResultsWrapper";

    export default {
        components: {
            KrokResultsWrapper,
        }
    }
</script>