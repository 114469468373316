<template>
    <div>
        <div>
            <v-container>
                <v-layout justify-center>
                    <v-flex xs4>
                        <v-select
                                :items="[50, 100, 200]"
                                v-model="quantity"
                                :label="$t('studentKrok.questionsCount')"
                                box 
                                hide-details
                        ></v-select>
                    </v-flex>
                    <v-flex xs8>
                        <v-layout>
                            <v-flex xs-7>
                                <v-btn
                                        @click="startQuiz()"
                                        class="primary"
                                        :disabled="loading"
                                        large
                                >{{$t("studentKrok.startQuiz")}}</v-btn>
                            </v-flex>
                            <v-flex xs-5>
                                <v-switch 
                                        v-model="withProblematicQuestions"
                                        :label="$t('studentKrok.withProblematicQuestions')"
                                ></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                   
                </v-layout>
                <v-layout>
                    <v-flex xs12>
                        <v-btn
                                outline
                                @click="failedQuestionsDialog = true"
                                class="orange"
                        >{{$t("studentKrok.problematicQuestions")}}</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
        
        <quiz-by-subjects-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
        >
        </quiz-by-subjects-grid>

        <v-dialog v-model="failedQuestionsDialog" max-width="1150px">
            <failed-questions-dialog
                    v-if="failedQuestionsDialog"
                    @shouldClose="failedQuestionsDialog = false"
            ></failed-questions-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import QuizBySubjectsGrid from "./QuizBySubjectsGrid";
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import FailedQuestionsDialog from "./FailedQuestionsDialog";

    export default {
        components: {
            QuizBySubjectsGrid,
            FailedQuestionsDialog
        },
        methods: {
            startQuiz() {
                this.loading = true;

                HttpService.getInstance()
                    .post(`studentStep/startSpaQuiz/${this.quantity}`, 
                        {
                            subject: {id: this.$route.params.subject},
                            shouldIncludeProblematicQuestions: this.withProblematicQuestions,
                        }
                    )
                    .then((quizId) => {
                        this.$router.push({name: 'studentQuizTest', params: {quizId, questionNumber: 1}});

                        this.loading = false; 
                    });
            },
            getItems(queryParams, subjectId) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`studentStep/getStudentSpaAttempts/${subjectId}?${queryParams}`)
                    .then(stepProfiles => {
                        this.items = stepProfiles.items;
                        this.total = stepProfiles.total;
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 ||
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData))  {
                        this.getItems(objectQueryStringify(paginationData), this.$route.params.subject);
                }

                this.pagination = paginationData;
            },
        },
        watch: {
            '$route.params.subject': function (newSubjectId) {
                if (newSubjectId !== undefined) {
                    this.pagination = Object.assign({}, this.pagination);

                    this.getItems(objectQueryStringify(this.pagination), newSubjectId);
                }
            },
        },
        data: () => {
            return {
                failedQuestionsDialog: false,
                
                quantity: 50,
                
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                withProblematicQuestions: false,
            }
        }
    }
</script>