<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">Додавання декількох груп</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-layout v-for="(group, index) in form.groupNumbers" :key="index">
                            <v-flex xs12>
                                <v-text-field :error-messages="errors[`groupNumbers[${index}]`]"
                                              v-model="group.groupNumber"
                                              v-mask="['###']"
                                              suffix="група"
                                              label="Номер групи"
                                              :append-outer-icon="index !== 0 ? 'delete_outline' : ''"
                                              @click:append-outer="removeGroup(index)"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                            <v-flex xs12>
                                <v-btn @click="addGroup" color="primary"><v-icon>add</v-icon> Додати ще</v-btn>
                            </v-flex>
                        <v-flex xs12 class="mt-4">Всього груп: <strong>{{form.groupNumbers.length}}</strong></v-flex>
                        <v-flex xs12>
                            <v-switch
                                    label="Навчальний план по замовчуванню"
                                    v-model="form.educationPlanByDefault"
                                    color="red"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs12>
                            <v-select
                                    :items="educationPlans"
                                    :error-messages="errors.educationPlan"
                                    v-model="form.educationPlan"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Навчальний план"
                                    :disabled="form.educationPlanByDefault"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddSeveral')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import maxBy from "lodash/maxBy";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            items: Array,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                form: {
                    groupNumbers: [],
                    educationPlanByDefault: true,
                    educationPlan: null,
                },
                errors: {
                    educationPlan: [],
                    groupNumbers: [[]]
                },
                loading: false,
                loadingSave: false,

                educationPlans: [],
            }
        },
        mounted() {
            let maxGroupNumber = maxBy(this.items, 'groupNumber');

            this.form.groupNumbers.push(
                {
                    groupNumber: maxGroupNumber !== undefined ? parseInt(maxGroupNumber.groupNumber) + 1 : null
                }
            );

            HttpService.getInstance()
                .get(`educationPlan/getAllBySpeciality/${this.$route.params.speciality}`)
                .then(resp => {
                    this.educationPlans = resp;
                    this.form.educationPlan = this.educationPlans.find(ep => {
                        return ep.isDefault;
                    });
                });

            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`academicGroup/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;

                        this.form.educationPlanByDefault = this.form.educationPlan.isDefault;
                    })
            }
        },
        watch: {
            "form.educationPlanByDefault": function (newVal, oldVal) {
                if (newVal !== oldVal && newVal === true) {
                    this.form.educationPlan = this.educationPlans.find(ep => {
                        return ep.isDefault;
                    });
                }
            }
        },
        methods: {
            removeGroup(index) {
                this.form.groupNumbers.splice(index, 1);
            },
            addGroup() {
                let maxGroupNumber = maxBy(this.form.groupNumbers, 'groupNumber');

                this.form.groupNumbers.push(
                    {
                        groupNumber: maxGroupNumber !== undefined ? parseInt(maxGroupNumber.groupNumber) + 1 : null
                    }
                );
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                let additionalParams = {
                    faculty: {id: this.$route.params.faculty},
                    semester: {id: this.$route.params.semester},
                    speciality: {id: this.$route.params.speciality}
                };

                HttpService.getInstance()
                    .post("academicGroup/multiple", {...this.form, ...additionalParams})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedSeveral");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>