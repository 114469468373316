<template>
    <div>
        <div class="headline">Теми самостійних занять</div>

        <v-btn class="mt-2 mb-4" color="primary" text
               :to="{name: 'eAristoEducationalTopicsPlan', params: {department: $route.params.department, subject: $route.params.subject, module: $route.params.module}}"
        >Перейти до внесення змісту тем самостійної роботи в Панелі eAristo <v-icon>arrow_forward</v-icon></v-btn>
        
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.isBlocked="{item}">
                <v-icon v-if="!item.isBlocked">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isBlocked">radio_button_checked</v-icon>
            </template>
            
            <template v-slot:item.evaluationMode="{item}">
                {{getEvaluationModeType(item.evaluationMode)}}
            </template>
            
            <template v-slot:item.actions="{item}">
                <div style="min-width: 80px;">
                    <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import topicEvaluationModeTypes, {topicEvaluationModeEnum} from "../../../constants/topicEvaluationModeTypes";

    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        methods: {
          getEvaluationModeType(type) {
              const foundEvaluationType = topicEvaluationModeTypes.find(i => i.id === type);
              
              if (foundEvaluationType) {
                  return foundEvaluationType.nameUk;
              }
              
              return "";
          }  
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: '№ п/п', value: 'orderNumber'},
                {text: '№ заняття', value: 'classNumber'},
                {text: 'Назва (укр)', value: 'nameUk'},
                {text: 'Академічні години', value: 'academicHours'},
                {text: 'Блокування', value: 'isBlocked'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
            topicEvaluationModeEnum,
        }),
    }
</script>