<template>
    <v-container grid-list-md>
        <v-layout wrap>
            <v-flex xs12>
                <v-layout v-if="!loading" wrap>
                    <v-flex xs6>
                        <v-date-picker
                                locale="uk-ua"
                                :first-day-of-week="1"
                                v-model="form.dates"
                                width="300"
                                multiple
                                :min="minDate"
                                reactive
                        ></v-date-picker>
                        <div v-if="errors.dates != null" 
                             class="red--text caption"
                        >{{errors.dates}}</div>
                    </v-flex>
                    <v-flex xs6>
                        <v-chip
                                v-for="(date, index) in form.dates"
                                :key="index"
                                color="primary"
                                text-color="white"
                        >{{formatShortDate(date)}}
                        </v-chip>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                                :items="subjects"
                                :error-messages="errors.subjects"
                                v-model="form.subjects"
                                item-text="name"
                                item-value="id"
                                return-object
                                label="Дисципліни"
                                multiple
                                chips
                                append-icon="control_point"
                                @click:append="selectAllSubjects"
                        >
                        </v-select>
                    </v-flex>
                    <v-btn class="mt-4 mb-6" @click="saveReworks()" :disabled="loading" large color="primary">Зберегти</v-btn>
                </v-layout>
                <v-container v-if="loading" fluid fill-height>
                    <v-layout align-center justify-center>
                        <v-progress-circular
                                :size="150"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HttpService from "../../../HttpService";
    import moment from "moment";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {formatShortDate} from "../../../helpers/dateHelper";
    import {reworksViewMode} from "../../../constants/_internal/reworksViewMode";

    export default {
        data: () => ({
            loading: false,
            subjects: [],
            form: {
                subjects: [],
                dates: [],
                departmentId: null,
            },
            errors: {
                dates: null,
                subjects: null
            },
            minDate: moment().format("YYYY-MM-DD"),
            formatShortDate,
        }),
        props: {
            mode: {
                type: Number,
            }
        },
        mounted() {
            this.loading = true;

            let routeNameToGetReworks = "";

            switch (this.mode) {
                case reworksViewMode.reworkModuleHeadept:
                    routeNameToGetReworks = `headDepartment/reworks/${this.$route.params.professor}/${this.$route.params.department}`;
                    break;
                case reworksViewMode.reworkMarksModerator:
                    routeNameToGetReworks = `moderator/reworks/${this.$route.params.professor}/${this.$route.params.department}`;
                    break;
            }
            
            HttpService.getInstance()
                .get(routeNameToGetReworks)
                .then(resp => {
                    this.form.subjects = resp.subjects;
                    this.form.dates = resp.dates
                        .map(date => moment(date).format("YYYY-MM-DD"));

                })
                .then(() => {
                    HttpService.getInstance()
                        .get(`teacher/navigation/department/${this.$route.params.department}/subject`)
                        .then(subjects => {
                            this.subjects = subjects.map(sub => {
                                const specs = sub.specialities.map(spec => spec.nameShortUk);
                                
                                sub.name = `${sub.nameUk} (${sub.nameShortUk}) (${specs.join(", ")})`;
                                return sub;
                            });
                            this.loading = false;
                        });
                });
        },
        methods: {
            saveReworks() {
                this.form.department = {id: this.$route.params.department};
                this.form.professor = {id: this.$route.params.professor};
                
                this.loading = true;

                let routeNameToSaveReworks = "";

                switch (this.mode) {
                    case reworksViewMode.reworkModuleHeadept:
                        routeNameToSaveReworks = `headDepartment/reworks`;
                        break;
                    case reworksViewMode.reworkMarksModerator:
                        routeNameToSaveReworks = `moderator/reworks`;
                        break;
                }
                
                HttpService.getInstance()
                    .post(routeNameToSaveReworks, this.form)
                    .then(resp => {
                        this.loading = false;

                        this.errors.subjects = null;
                        this.errors.dates = null;
                    })
                    .catch(err => {
                        this.loading = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
            selectAllSubjects() {
                this.form.subjects = this.subjects;
            }  
        },       
    }
</script>