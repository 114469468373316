<template>
    <div>
        <v-btn class="mb-3" @click="openCsv()">Завантажити CSV</v-btn>
        <rating-professor-grid
                :items="items"
                :total="total"
                :loading="loading"
        >
        </rating-professor-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import RatingProfessorGrid from "./RatingProfessorGrid";

    export default {
        components: {
            RatingProfessorGrid,
        },
        methods: {
            getItems() {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingStat/getProfessorsStats`)
                    .then(items => {
                        this.items = items.items.map((i, index) => {
                            i.index = index + 1;
                            
                            i.ratingDirections.forEach(direction => {
                               i[`direction${direction.order}`] = direction.pointsSum; 
                            });
                            
                            return i;
                        });
                        // this.total = faculties.total;
                        this.loading = false;
                    })
            },
            openCsv() {
                window.open(`${this.$config.apiEndpoint}/ratingStat/getProfessorsStatsCsv`);
            }
        },
        mounted() {
            this.getItems();
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
            }
        }
    }
</script>