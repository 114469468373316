<template>
    <div>
        <div v-if="workTypeIsEnglish" class="d-flex justify-space-between">
            <div class="d-flex align-center">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-flex flex-column"><img width="20"
                                                                       :src="enGb"/>
                        </div>
                        <div v-on="on" class="d-flex flex-column ml-2 points-preview-block">{{(workTypePoints
                            * ratingSettings.englishRatio).toFixed(2)}}
                        </div>
                    </template>
                    <span>Англомовна позиція (x{{ratingSettings.englishRatio}})</span>
                </v-tooltip>
            </div>
            <div class="d-flex align-center">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-flex flex-column ml-4">
                            <v-icon size="20">exit_to_app</v-icon>
                        </div>
                        <div v-on="on" class="d-flex flex-column ml-2 points-preview-block">{{(workTypePoints
                            * ratingSettings.englishRatio * ratingSettings.externalWorkRatio).toFixed(2)}}
                        </div>
                    </template>
                    <span>Англомовна позиція, x{{ratingSettings.englishRatio}}x{{ratingSettings.externalWorkRatio}} (основний автор на іншій кафедрі)</span>
                </v-tooltip>
            </div>
        </div>

        <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-flex flex-column"><img width="20"
                                                                       :src="ukUa"/>
                        </div>
                        <div v-on="on" class="d-flex flex-column ml-2 points-preview-block">
                            {{workTypePoints * ratingSettings.ukrainianRatio}}
                        </div>
                    </template>
                    <span>Україномовна позиція (x{{ratingSettings.ukrainianRatio}})</span>
                </v-tooltip>
            </div>

            <div class="d-flex align-center">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" class="d-flex flex-column ml-4">
                            <v-icon size="20">exit_to_app</v-icon>
                        </div>
                        <div v-on="on" class="d-flex flex-column ml-2 points-preview-block">
                            {{(workTypePoints * ratingSettings.ukrainianRatio * ratingSettings.externalWorkRatio).toFixed()}}
                        </div>
                    </template>
                    <span>Україномовна позиція, x{{ratingSettings.ukrainianRatio}}x{{ratingSettings.externalWorkRatio}} (основний автор на іншій кафедрі)</span>
                </v-tooltip>
            </div>
        </div>

    </div>
</template>

<script>
    import enGb from "../../../assets/images/flags/en-GB.png";
    import ukUa from "../../../assets/images/flags/uk-UA.png";
    
    export default {
        name: "WorktypePoints",
        props: {
            workTypeIsEnglish: Boolean,
            workTypePoints: Number,
            ratingSettings: Object,
        },
        data: () => {
            return {
                enGb,
                ukUa,
            };
        }
    }
</script>

<style scoped>

</style>