<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.directionName"
                                          v-model="form.directionName"
                                          label="Назва напрямку"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.order"
                                          v-model="form.order"
                                          v-mask="['###']"
                                          label="Порядковий номер"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.ratio"
                                          v-model="form.ratio"
                                          @keypress="isNumber($event, 2)"
                                          label="Коефіцієнт"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import {isNumber} from "../../../helpers/numberHelper";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                isNumber,
                form: {
                    directionName: null,
                    order: null,
                    ratio: 0,
                },
                errors: {
                    directionName: [],
                    order: [],
                    ratio: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingDirections/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm() {
                this.loadingSave = true;
                HttpService.getInstance()
                    .post("ratingDirections", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>