<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
               itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item="props">
            <tr>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td width="200" class="text-xs-left">
                    <v-chip v-for="academicGroup in props.item.academicGroup" :key="academicGroup.id">{{ academicGroup.groupNumber }} група</v-chip>
                </td>
                <td class="justify-center layout px-0">
                    <v-btn icon class="mx-0" @click="$emit('editItem', props.item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        methods: {
            mapSpecialitiesToString(specialities) {
                return specialities.map(spec => spec.nameUk).join(", ");
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва потоку', value: 'name'},
                {text: 'Академічні групи', value: 'academicGroup', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>