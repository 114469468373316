<template>
    <form @submit="submitForm" novalidate="true">
        <v-card class="mt-12">
            <v-app-bar dark color="primary" fixed>
                <v-btn icon dark
                       @click.native="$emit('shouldCloseAddEdit')"
                       :disabled="loadingSave"
                >
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{editMode ? 'Редагування оголошення' : 'Додавання оголошення'}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text
                           type="submit"
                           :loading="loadingSave"
                           :disabled="loadingSave"
                    >Зберегти
                    </v-btn>
                </v-toolbar-items>
            </v-app-bar>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.titleUk" v-model="form.titleUk"
                                          label="Назва (укр)"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.titleEn" v-model="form.titleEn"
                                          label="Назва (eng)"></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                            <v-text-field :error-messages="errors.descriptionEn" v-model="form.descriptionEn"
                                          label="Опис (eng)"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.descriptionUk" v-model="form.descriptionUk"
                                          label="Опис (укр)"></v-text-field>
                        </v-flex>

                        <v-flex xs6>
                            <v-switch
                                    label="Підтверджено публікацію"
                                    v-model="form.approved"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs6>
                            <v-switch
                                    label="Опубліковано"
                                    v-model="form.published"
                            ></v-switch>
                        </v-flex>
                        <v-flex xs3>
                            <date-picker
                                    v-model="form.publishStartTime"
                                    label="Дата початку публікації"
                                    :min-date="new Date()"
                                    :error-messages="errors.publishStartTime"
                                    :ignore-timezone="fale"
                            ></date-picker>
                        </v-flex>
                        <v-flex xs3>
                            <time-picker
                                    v-model="form.publishStartTime"
                                    label="Час початку публікації"
                            ></time-picker>
                        </v-flex>
                        <v-flex xs3>
                            <date-picker
                                    v-model="form.publishEndTime"
                                    label="Дата завершення публікації"
                                    :min-date="form.publishStartTime"
                                    :error-messages="errors.publishEndTime"
                                    :ignore-timezone="false"
                            ></date-picker>
                        </v-flex>
                        <v-flex xs3>
                            <time-picker
                                    v-model="form.publishEndTime"
                                    label="Час завершення публікації"
                            ></time-picker>
                        </v-flex>

                        <v-flex xs12>
                            <v-select
                                    clearable
                                    :items="pollQuestionPools"
                                    :error-messages="errors.pollQuestionPool"
                                    v-model="form.pollQuestionPool"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Пул питань"
                            ></v-select>
                        </v-flex>

                        <v-flex xs6 v-if="!form.roleDestination && !form.faculty">
                            <v-autocomplete
                                    clearable
                                    :items="departments"
                                    :error-messages="errors.department"
                                    v-model="form.department"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Кафедра"
                            ></v-autocomplete>
                        </v-flex>

                        <v-flex xs6 v-if="!form.department && !form.faculty">
                            <v-select
                                    clearable
                                    :items="roleDestinations"
                                    :error-messages="errors.roleDestination"
                                    v-model="form.roleDestination"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    label="Роль"
                            ></v-select>
                        </v-flex>

                        <template v-if="!form.department && !form.roleDestination">
                            <v-flex xs12>
                                <div class="title font-weight-thin">Для студентів</div>
                            </v-flex>
                            <v-flex xs6>
                                <v-select
                                        clearable
                                        :items="faculties"
                                        :error-messages="errors.faculty"
                                        v-model="form.faculty"
                                        item-text="titleUk"
                                        item-value="id"
                                        return-object
                                        label="Факультет"
                                ></v-select>
                            </v-flex>

                            <v-flex xs6>
                                <v-select
                                        clearable
                                        :items="specialities"
                                        :error-messages="errors.speciality"
                                        v-model="form.speciality"
                                        item-text="titleUk"
                                        item-value="id"
                                        return-object
                                        label="Спеціальність"
                                ></v-select>
                            </v-flex>

                            <v-flex xs6>
                                <v-select
                                        clearable
                                        :items="semesters"
                                        :error-messages="errors.semester"
                                        v-model="form.semester"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        label="Семестр"
                                ></v-select>
                            </v-flex>

                            <v-flex xs6>
                                <v-select
                                        clearable
                                        :items="academicGroups"
                                        :error-messages="errors.academicGroup"
                                        v-model="form.academicGroup"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        label="Група"
                                ></v-select>
                            </v-flex>
                        </template>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import announcementFromTypes, {announcementFromTypesEnum} from "../../../../constants/mainDashboard/announcementFromTypes";
    import DatePicker from "../../../common/DatePicker";
    import TimePicker from "../../../common/TimePicker";
    import ImageUploader from "../../../common/ImageUploader";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder,
            DatePicker,
            TimePicker,
            ImageUploader
        },
        data: () => {
            return {
                something: false,
                announcementFromTypes,
                form: {
                    titleUk: null,
                    titleEn: null,
                    descriptionEn: null,
                    descriptionUk: null,
                    approved: true,
                    published: true,
                    publishStartTime: new Date(),
                    publishEndTime: new Date(),
                    speciality: null,
                    semester: null,
                    faculty: null,
                    department: null,
                    academicGroup: null,
                    roleDestination: null,
                    pollQuestionPool: null,
                },
                errors: {
                    titleUk: [],
                    titleEn: [],
                    speciality: [],
                    semester: [],
                    faculty: [],
                    department: [],
                    academicGroup: [],
                    roleDestination: [],
                    descriptionEn: [],
                    descriptionUk: [],
                    pollQuestionPool: [],
                },
                loading: false,
                loadingSave: false,

                academicGroups: [],
                departments: [],
                faculties: [],
                roleDestinations: [],
                semesters: [],
                specialities: [],
                pollQuestionPools: [],
            }
        },
        mounted() {
            this.publishStartTime = new Date();
            this.publishEndTime = new Date();

            HttpService.getInstance()
                .get("role")
                .then(resp => {
                    this.roleDestinations = resp;
                });

            HttpService.getInstance()
                .get("pollPools/getAllSimpleList")
                .then(resp => {
                    this.pollQuestionPools = resp;
                });

            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.faculties = resp;
                });

            HttpService.getInstance()
                .get("departments/getAll")
                .then(resp => {
                    this.departments = resp;
                });


            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`pollsAdmin/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        watch: {
            "form.faculty.id": function (newFacultyId, oldFacultyId) {
                if (newFacultyId !== oldFacultyId) {
                    HttpService.getInstance()
                        .get(`specialities/getAllByFaculty/${newFacultyId}`)
                        .then(resp => {
                            this.specialities = resp;
                        });

                }

                if (newFacultyId === undefined) {
                    this.form.speciality = null;
                    this.form.semester = null;
                    this.form.academicGroup = null;
                }
            },
            "form.speciality.id": function (newSpecialityId, oldSpecialityId) {
                if (newSpecialityId !== oldSpecialityId) {
                    HttpService.getInstance()
                        .get(`semester/semesterBySpeciality/${newSpecialityId}`)
                        .then(resp => {
                            this.semesters = resp.map(r => {
                                r.name = `${r.yearNumber} курс (${r.semesterNumber} семестр)`;
                                return r;
                            });
                        });

                }

                if (newSpecialityId === undefined) {
                    this.form.semester = null;
                    this.form.academicGroup = null;
                }
            },
            "form.semester.id": function (newSemesterId, oldSemesterId) {
                if (newSemesterId !== oldSemesterId) {
                    HttpService.getInstance()
                        .get(`academicGroup/getAll/${this.form.faculty.id}/${this.form.speciality.id}/${newSemesterId}`)
                        .then(resp => {
                            this.academicGroups = resp.map(r => {
                                r.name = `${r.groupNumber} група`;

                                return r;
                            });
                        });

                }

                if (newSemesterId === undefined) {
                    this.form.academicGroup = null;
                }
            },
        },
        methods: {
            getEditorConfig() {
                return {
                    image: {
                        uploadURL: `${this.$config.apiEndpoint}/file/upload`,
                    },
                    maxHeight: "500px",
                }
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("pollsAdmin", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>