<template>
    <v-progress-circular indeterminate :size="150" color="primary"></v-progress-circular>
</template>
<script>
    export default {
        mounted() {
            this.$auth.handleAuthentication()
                .then(() => {
                    return this.$auth.getUser();
                })
                .then((userData) => {
                    const returnUrl = this.$cookie.get('returnUrl');

                    if (returnUrl != null) {
                        this.$router.push({path: returnUrl}).catch(err => {});
                        
                        this.$cookie.delete('returnUrl');
                    } else {
                        this.$router.push({name: 'home'}).catch(err => {});
                    }
                })
            .catch(e => {
                console.error(e);

                this.$router.push({
                    name: 'unauthorized',
                }).catch(err => {});
            });
        }
    };
</script>