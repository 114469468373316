<template>
    <v-container grid-list-md>
        <stat-date-range
            @onSearch="search"
            @onClear="clearFilter"
        />

        <line-chart :chart-data="datacollection" :options="options"></line-chart>
    </v-container>
</template>

<script>
    import LineChart from '../../common/statistics/LineChart';
    import moment from "moment";
    import HttpService from "@/HttpService";
    import {formatShortDate} from "@/helpers/dateHelper";
    import StatDateRange from "@/components/common/StatDateRange";

    export default {
        components: {
            LineChart,
            StatDateRange,
        },
        data () {
            return {
                datacollection: null,
                options: {
                    fill: false,
                    responsive: true,
                    maintainAspectRatio: false,
                },
                
                dates: [],
                
            }
        },
        mounted () {
            this.getData();
        },
        methods: {
            getData(dateFrom, dateTo) {
                let queryParam = "";
                if (dateFrom && dateTo) {
                    queryParam = `dateFrom=${moment(dateFrom).format('YYYY-MM-DD HH:mm')}
                    &dateTo=${moment(dateTo).add(1, 'days').subtract(1, 'seconds').format('YYYY-MM-DD HH:mm')}`;
                }

                HttpService.getInstance()
                    .get(`statisticsReworks/getByTimeFaculty/${this.$route.params.faculty}?${queryParam}`)
                    .then(data => {
                        this.data = data;
                        
                        this.fillData();
                    })
                    .catch(e => {

                    })
            },

            fillData() {
                const labels = this.data.map(d => formatShortDate(d.date));
                const data5 = this.data.map(d => d.statisticsRework.count5);
                const data4 = this.data.map(d => d.statisticsRework.count4);
                const data3 = this.data.map(d => d.statisticsRework.count3);
                const data2 = this.data.map(d => d.statisticsRework.count2);
                const dataNotFinished = this.data.map(d => d.statisticsRework.countNotFinished);
                const dataPenalty = this.data.map(d => d.statisticsRework.countPenalty);
                
                this.datacollection = {
                    labels,
                    datasets: [
                        {
                            label: 'Кількість 5',
                            backgroundColor: '#2E7D32',
                            borderColor: '#2E7D32',
                            fill: false,
                            data: data5,
                        }, 
                        {
                            label: 'Кількість 4',
                            backgroundColor: '#0277BD',
                            borderColor: '#0277BD',
                            fill: false,
                            data: data4,
                        },
                        {
                            label: 'Кількість 3',
                            backgroundColor: '#f89662',
                            borderColor: '#f89662',
                            fill: false,
                            data: data3,
                        },
                        {
                            label: 'Кількість 2',
                            backgroundColor: '#F4511E',
                            borderColor: '#F4511E',
                            fill: false,
                            data: data2,
                        },
                        {
                            label: 'Не завершено',
                            backgroundColor: '#757575',
                            borderColor: '#757575',
                            fill: false,
                            data: dataNotFinished,
                        },
                        {
                            label: 'Порушення регламенту',
                            backgroundColor: '#B71C1C',
                            borderColor: '#B71C1C',
                            fill: false,
                            data: dataPenalty,
                        }
                    ]
                }
            },

            clearFilter() {
                this.dates = [];

                this.getData();
            },

            search(dates) {
                this.dates = dates;
                this.getData(this.dates[0], this.dates[1]);
            },
        }
    }
</script>