<template>
    <v-stepper v-model="stepper">
        <v-stepper-header>
            <v-stepper-step
                :complete="stepper > 1"
                step="1"
            >
                Вибір типу запитання
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="stepper > 2"
                step="2"
            >
                Зміст запитання
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card
                    class="mb-12"
                    height="250px"
                >

                    <v-list>
                        <v-list-item-group
                            v-model="selectedQuestionType"
                            active-class="orange--text text--darken-4"
                        >
                            <template v-for="(item, index) in items">
                                <v-list-item :key="item.title">
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.title"></v-list-item-title>

                                            <v-list-item-subtitle
                                                class="text--primary"
                                                v-text="item.headline"
                                            ></v-list-item-subtitle>

                                            <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                                        </v-list-item-content>

<!--                                        <v-list-item-action>-->
<!--                                            <v-list-item-action-text v-text="item.action"></v-list-item-action-text>-->
<!--                                        </v-list-item-action>-->
                                    </template>
                                </v-list-item>

                                <v-divider
                                    v-if="index < items.length - 1"
                                    :key="index"
                                ></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                    
                </v-card>

                <v-btn
                    :disabled="selectedQuestionType === undefined || selectedQuestionType === null"
                    color="primary"
                    @click="stepper = 2"
                >
                    Продовжити
                </v-btn>

                <v-btn @click="close()" text class="ml-2">
                    Відміна
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
                <div
                    class="mb-15"
                >
                    <add-edit-question-single ref="questionSingle" v-if="selectedQuestionType === 0" 
                                              :education-material-video-id="educationMaterialVideoId" 
                                              :education-material-presentation-id="educationMaterialPresentationId"
                                              :current-video-time="currentVideoTime"
                                              
                                              :edit-mode="editMode"
                                              :edit-id="editId"
                                              
                                              @loadingSave="loading = $event"
                                              @addedEdited="$emit('addedEdited')"
                    ></add-edit-question-single>
                    <add-edit-question-fill-words ref="questionFillWords" v-if="selectedQuestionType === 1" 
                                                  :education-material-video-id="educationMaterialVideoId"
                                                  :education-material-presentation-id="educationMaterialPresentationId"
                                                  :current-video-time="currentVideoTime"

                                                  :edit-mode="editMode"
                                                  :edit-id="editId"

                                                  @loadingSave="loading = $event"
                                                  @addedEdited="$emit('addedEdited')"
                    ></add-edit-question-fill-words>
                    <add-edit-question-text-answer ref="questionTextAnswer" v-if="selectedQuestionType === 2" 
                                                   :education-material-video-id="educationMaterialVideoId"
                                                   :education-material-presentation-id="educationMaterialPresentationId"
                                                   :current-video-time="currentVideoTime"

                                                   :edit-mode="editMode"
                                                   :edit-id="editId"

                                                   @loadingSave="loading = $event"
                                                   @addedEdited="$emit('addedEdited')"
                    ></add-edit-question-text-answer>
                    <add-edit-question-single-image ref="questionSingleImage" v-if="selectedQuestionType === 3"
                                                    :education-material-video-id="educationMaterialVideoId"
                                                    :education-material-presentation-id="educationMaterialPresentationId"
                                                    :current-video-time="currentVideoTime"

                                                    :edit-mode="editMode"
                                                    :edit-id="editId"

                                                    @loadingSave="loading = $event"
                                                    @addedEdited="$emit('addedEdited')"
                    ></add-edit-question-single-image>
                </div>

                <v-btn
                    :disabled="loading"
                    class="ml-2"
                    color="primary"
                    @click="saveQuestion()"
                >
                    Зберегти запитання
                </v-btn>

                <v-btn @click="close()" text class="ml-2">
                    Відміна
                </v-btn>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
    import LoadingPlaceholder from "@/components/common/LoadingPlaceholder";
    import AddEditQuestionSingle
        from "@/components/eAristoProfessor/topicContent/eAristoQuestions/AddEditQuestionTypes/AddEditQuestionSingle.vue";
    import AddEditQuestionFillWords
        from "@/components/eAristoProfessor/topicContent/eAristoQuestions/AddEditQuestionTypes/AddEditQuestionFillWords.vue";
    import AddEditQuestionTextAnswer
        from "@/components/eAristoProfessor/topicContent/eAristoQuestions/AddEditQuestionTypes/AddEditQuestionTextAnswer.vue";
    import AddEditQuestionSingleImage
        from "@/components/eAristoProfessor/topicContent/eAristoQuestions/AddEditQuestionTypes/AddEditQuestionSingleImage.vue";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
            
            educationMaterialVideoId: {
                type: Number,
                default: null,
            },
            educationMaterialPresentationId: {
                type: Number,
                default: null,
            },

            editQuestionType: {
                type: Number,
                default: null,
            },

            currentVideoTime: {
                type: Number,
                default: null,
            },
        },
        components: {
            loadingPlaceholder: LoadingPlaceholder,
            AddEditQuestionSingle,
            AddEditQuestionSingleImage,
            AddEditQuestionFillWords,
            AddEditQuestionTextAnswer,
        },
        data: () => {
            return {
                stepper: 1,

                selectedQuestionType: 0,
                items: [
                    {
                        title: 'Тест з 1 правильною відповіддю',
                        headline: 'Класичний тест з 1 правильною відповіддю з-поміж 5',
                    },
                    {
                        title: 'Вписати пропущене слово',
                        headline: 'Тест на вписування пропущеного слова або слів',
                    },
                    {
                        title: 'Вписати відповідь',
                        headline: 'Тест, де студент повинен вписувати відповідь',
                    },
                    {
                        title: 'Тест з 1 правильною відповіддю зі зображеннями',
                        headline: 'Класичний тест зі зображеннями (у запитанні / відповідях) з 1 правильною відповіддю з-поміж 5',
                    },
                ],
                
                loading: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.selectedQuestionType = this.editQuestionType;
                this.stepper = 2;
            }
        },
        methods: {
            close() {
                this.$emit('shouldClose');
            },
            saveQuestion() {
                console.log("saveQuestion", this.selectedQuestionType);
                if (this.selectedQuestionType === 0) {
                    this.$refs.questionSingle.saveForm();
                }
                else if (this.selectedQuestionType === 1) {
                    this.$refs.questionFillWords.saveForm();
                }
                else if (this.selectedQuestionType === 2) {
                    this.$refs.questionTextAnswer.saveForm();
                }
                else if (this.selectedQuestionType === 3) {
                    this.$refs.questionSingleImage.saveForm();
                }
            }
        }
    }
</script>