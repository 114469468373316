<template>
    <div>
        <v-navigation-drawer
                fixed
                app
                :clipped="$vuetify.breakpoint.mdAndUp"
                :fixed="true"
                :value="drawer"
                @input="$emit('toggleDrawer', $event)"
        >
            <v-list dense two-line class="subject-list">
                <v-subheader class="mt-1">
                    <div class="title">Тестування по модулях</div>
                </v-subheader>

                <v-subheader class="mt-1 grey--name name--darken-1"></v-subheader>
                <navigation-list-template :items="subjectModules"/>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import NavigationListTemplate from "../../common/NavigationListTemplate";
    import DepartmentSubjectSwitcher from "../../common/DepartmentSubjectSwitcher";
    import HttpService from "../../../HttpService";

    export default {
        props: {
            drawer: {
                type: Boolean,
            }
        },
        components: {
            NavigationListTemplate,
            DepartmentSubjectSwitcher
        },
        data: () => ({
            isDepartmentSubjectSwitcherOpened: false,
            dictionaryItems: [],
            dictionaryItemsRaw: [
                {
                    icon: 'turned_in',
                    name: 'Modules',
                    route: {
                        name: 'marksRoot'
                    }
                },
            ],
            subjectModules: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`teacher/navigation/subjectModulesWithQuiz`)
                .then(resp => {
                    this.subjectModules = this.remapItems(resp);
                })
        },
        methods: {
            remapItems(items) {
                let itemsRemapped = items.slice(0);

                return itemsRemapped.map(item => {
                    item.name = `${item.nameUk} (${item.specialities.map(s => s.nameShortUk).join(", ")})`;

                    if (item.modules !== undefined) {
                        item.children = item.modules.map(ch => {
                            ch.route = {
                                name: "stepAdminManagement",
                                params: {
                                    module: ch.id,
                                    subject: item.id,
                                }
                            };

                            const moduleId = parseInt(this.$route.params.module);
                            if (this.$route.name === ch.route.name && moduleId === ch.route.params.module) {
                                ch.model = true;
                            }
                            
                            ch.name = ch.nameUk;
                            
                            return ch;
                        });

                        let someActiveChildren = item.children.find(ch => ch.model);

                        if (someActiveChildren !== undefined) {
                            item.model = true;
                        }

                        return item;
                    }

                    return item;
                });
            },
        },
    }
</script>
<style lang="scss">
    .subject-list {
        .v-list__tile__title {
            height: 30px;
            line-height: 14px;
            white-space: normal;
            overflow: hidden;
            text-overflow: initial;
        }
    }
</style>