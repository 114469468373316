<template>
    <form @submit.prevent="submitForm" novalidate="true" ref="addEditForm">
        <v-card>
            <v-card-title>
                <span class="headline">{{editMode ? 'Редагування' : 'Додавання'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.order"
                                          v-model="form.order"
                                          v-mask="['###']"
                                          label="№ по порядку"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field :error-messages="errors.orderCustom"
                                          v-model="form.orderCustom"
                                          label="№ пункта"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea :error-messages="errors.name"
                                        v-model="form.name"
                                        row-height="2"
                                        label="Назва пункту"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs6>
                            <v-textarea :error-messages="errors.description"
                                          v-model="form.description"
                                        row-height="2"
                                        label="Опис"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs6>
                            <v-textarea :error-messages="errors.example"
                                        v-model="form.example"
                                        row-height="2"
                                        label="Приклад"
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :error-messages="errors.points"
                                          v-model="form.points"
                                          @keypress="isNumber($event, 2)"
                                          label="Кількість балів"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-flex xs12>Індивідуальний показник</v-flex>
                            <v-btn-toggle v-model="form.isIndividual" mandatory>
                                <v-btn color="green" text :value="true">
                                    <span>Так</span>
                                    <v-icon color="green">label</v-icon>
                                </v-btn>
                                <v-btn color="red" text :value="false">
                                    <span>Ні</span>
                                    <v-icon color="red">label_off</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-flex>
                        <v-flex xs6>
                            <v-flex xs12>Кафедральний показник</v-flex>
                            <v-btn-toggle v-model="form.isDepartment" mandatory>
                                <v-btn color="green" text :value="true">
                                    <span>Так</span>
                                    <v-icon color="green">label</v-icon>
                                </v-btn>
                                <v-btn color="red" text :value="false">
                                    <span>Ні</span>
                                    <v-icon color="red">label_off</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-flex>
                        <v-flex xs6>
                            <v-flex xs12>Показник з кількістю</v-flex>
                            <v-btn-toggle v-model="form.isEnumerable" mandatory>
                                <v-btn color="green" text :value="true">
                                    <span>Так</span>
                                    <v-icon color="green">label</v-icon>
                                </v-btn>
                                <v-btn color="red" text :value="false">
                                    <span>Ні</span>
                                    <v-icon color="red">label_off</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-flex>
                        <v-flex xs6>
                            <v-flex xs12>Можливий англомовний показник</v-flex>
                            <v-btn-toggle v-model="form.isEnglish" mandatory>
                                <v-btn color="green" text :value="true">
                                    <span>Так</span>
                                    <v-icon color="green">label</v-icon>
                                </v-btn>
                                <v-btn color="red" text :value="false">
                                    <span>Ні</span>
                                    <v-icon color="red">label_off</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-flex>
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseAddEdit')"
                        :disabled="loadingSave"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import {isNumber} from "../../../helpers/numberHelper";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            LoadingPlaceholder
        },
        data: () => {
            return {
                isNumber,
                form: {
                    order: null,
                    orderCustom: null,
                    name: null,
                    description: null,
                    example: null,
                    points: 0,
                    isIndividual: false,
                    isDepartment: false,
                    isEnumerable: false,
                    isEnglish: false,
                },
                errors: {
                    order: [],
                    orderCustom: [],
                    name: [],
                    description: [],
                    example: [],
                    points: [],
                    isIndividual: [],
                    isDepartment: [],
                    isEnumerable: [],
                    isEnglish: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingWorktypes/getOne/${this.editId}`)
                    .then(resp => {
                        this.loading = false;
                        this.form = resp;
                    })
            }
        },
        methods: {
            submitForm() {
                this.loadingSave = true;

                HttpService.getInstance()
                    .post("ratingWorktypes", {...this.form, ...{ratingDirection: {id: this.$route.params.direction}}})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited", this.createAnother);
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>