<template>
    <div>
        <topics-grid
                :items="topicsItems"
                :total="topicsTotal"
                :loading="loading"
                @paginate="onTopicsPaginate"
                @navigateTo="navigateToTopic"
        >
        </topics-grid>
        <lectures-grid
            class="mt-5"
            :items="lectureItems"
            :total="lectureTotal"
            :loading="loading"
            @paginate="onLecturePaginate"
            @navigateTo="navigateToLecture"
        >
        </lectures-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import TopicsGrid from "./TopicsGrid";
    import LecturesGrid from "./LecturesGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";

    export default {
        components: {
            TopicsGrid,
            LecturesGrid,
        },
        data: () => {
            return {
                dialog: false,
                topicsItems: [],
                topicsTotal: null,
                lectureItems: [],
                lectureTotal: null,
                loading: false,
                topicsPagination: {
                    totalItems: 0,
                },
                lecturePagination: {
                    totalItems: 0,
                },
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`module/${this.$route.params.module}`)  
              .then(module => {
                  this.module = module;
              });
        },
        methods: {
            navigateToTopic(topicId, routeName) { 
                this.$router.push({
                    name: routeName,
                    params: {
                        subject: this.$route.params.subject,
                        department: this.$route.params.department,
                        module: this.$route.params.module,
                        topic: topicId,
                    }
                }).catch(err => {});
            },
            navigateToLecture(lectureId, routeName) {
                this.$router.push({
                    name: routeName,
                    params: {
                        subject: this.$route.params.subject,
                        department: this.$route.params.department,
                        module: this.$route.params.module,
                        lecture: lectureId,
                    }
                }).catch(err => {});
            },
            getTopicsItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`topic/getByModule/${this.$route.params.module}?${queryParams}`)
                    .then(topics => {
                        this.topicsItems = topics.items;
                        this.topicsTotal = topics.total;

                        this.loading = false;
                    })
            },
            getLectureItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`lecture/getByModule/${this.$route.params.module}?${queryParams}`)
                    .then(lectures => {
                        this.lectureItems = lectures.items;
                        this.lectureTotal = lectures.total;

                        this.loading = false;
                    })
            },
            onTopicsPaginate(paginationData) {
                if (this.topicsItems.length === 0 || 
                    JSON.stringify(this.topicsPagination) !== JSON.stringify(paginationData)) {
                    this.getTopicsItems(objectQueryStringify(paginationData));
                }

                this.topicsPagination = paginationData;
            },

            onLecturePaginate(paginationData) {
                if (this.lectureItems.length === 0 ||
                    JSON.stringify(this.lecturePagination) !== JSON.stringify(paginationData)) {
                    this.getLectureItems(objectQueryStringify(paginationData));
                }

                this.lecturePagination = paginationData;
            },
        },
    }
</script>