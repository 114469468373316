<template>
    <form @submit="submitForm" novalidate="true">
        <v-card class="mt-12">
            <v-app-bar dark color="primary" fixed>
                <v-btn icon dark
                       class="ml-2"
                       @click.native="$emit('shouldCloseAddEdit')"
                       :disabled="loadingSave"
                >
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{editMode ? 'Редагування оголошення' : 'Додавання оголошення'}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text
                           type="submit"
                           class="mr-2"
                           :loading="loadingSave"
                           :disabled="loadingSave"
                    >Зберегти
                    </v-btn>
                </v-toolbar-items>
            </v-app-bar>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-flex xs8>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field :error-messages="errors.titleUk" v-model="form.titleUk"
                                                  label="Назва (укр)"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field :error-messages="errors.titleEn" v-model="form.titleEn"
                                                  label="Назва (eng)"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs4>
                            <image-uploader
                                    v-if="loaded && editMode || !editMode"
                                    v-model="form.titleImageUrl"
                                    :image-endpoint="`${$config.apiEndpoint}/file/get`"
                                    :type="fileUploadEnum.announcementImages"
                            />
                        </v-flex>

                        <v-flex xs12>
                            <v-layout wrap>
                                <v-flex xs6 md3>
                                    <v-flex xs12 class="subtitle-1">Оголошення від:</v-flex>
                                </v-flex>

                                <v-radio-group xs6 md9 v-model="form.announcementFromType">
                                    <v-radio
                                            v-for="(announcementFromType, index) in announcementFromTypesList"
                                            :key="index"
                                            :label="announcementFromType.name"
                                            :value="announcementFromType.id"
                                    ></v-radio>
                                </v-radio-group>
                            </v-layout>
                        </v-flex>

                        <v-flex xs6>
                            <v-switch
                                    :disabled="!userIsAdminOrEdudept()"
                                    label="Підтверджено публікацію"
                                    v-model="form.approved"
                            ></v-switch>
                            <div v-if="!userIsAdminOrEdudept()">Підтвердження оголошення проводиться деканатами та
                                навчальним відділом
                            </div>
                        </v-flex>
                        <v-flex xs6>
                            <v-switch
                                    :disabled="!userIsAdminOrEdudept()"
                                    label="Опубліковано"
                                    v-model="form.published"
                            ></v-switch>
                            <div v-if="!userIsAdminOrEdudept()">Опублікування оголошення проводиться деканатами та
                                навчальним відділом
                            </div>

                        </v-flex>
                        <v-flex xs3>
                            <date-picker
                                    v-model="form.publishStartTime"
                                    label="Дата початку публікації"
                                    :min-date="new Date()"
                                    :error-messages="errors.publishStartTime"
                                    :ignore-timezone="false"
                            ></date-picker>
                        </v-flex>
                        <v-flex xs3>
                            <time-picker
                                    v-model="form.publishStartTime"
                                    label="Час початку публікації"
                            ></time-picker>
                        </v-flex>
                        <v-flex xs3>
                            <date-picker
                                    v-model="form.publishEndTime"
                                    label="Дата завершення публікації"
                                    :min-date="form.publishStartTime"
                                    :error-messages="errors.publishEndTime"
                                    :ignore-timezone="false"
                            ></date-picker>
                        </v-flex>
                        <v-flex xs3>
                            <time-picker
                                    v-model="form.publishEndTime"
                                    label="Час завершення публікації"
                            ></time-picker>
                        </v-flex>

                        <v-flex xs12>
                            <div class="subtitle-1">Текст оголошення (українською)</div>
                            <wysiwyg :options="getEditorConfig()" v-model="form.textUk"></wysiwyg>
                        </v-flex>
                        <v-flex xs12>
                            <div class="subtitle-1">Текст оголошення (англійською)</div>
                            <wysiwyg :options="getEditorConfig()" v-model="form.textEn"></wysiwyg>
                        </v-flex>

                        <v-flex xs12 v-if="userIsModeratorOrHeadOfDepartment()">
                            <v-select
                                    :items="departmentsBelongsTo"
                                    :error-messages="errors.belongsToDepartment"
                                    v-model="form.belongsToDepartment"
                                    item-text="nameUk"
                                    item-value="id"
                                    return-object
                                    label="Кафедра від якої публікується оголошення"
                            ></v-select>
                        </v-flex>

                        <template v-if="(userIsAdminOrEdudept() || userIsDeanOrInspector()) && form.faculty === null">
                            <v-flex xs6 v-if="!form.role && !form.faculty">
                                <v-autocomplete
                                        clearable
                                        :items="departments"
                                        :error-messages="errors.department"
                                        v-model="form.department"
                                        item-text="nameUk"
                                        item-value="id"
                                        return-object
                                        label="Кафедра для якої публікується (деканат та адмін)"
                                ></v-autocomplete>
                            </v-flex>
                        </template>

                        <template v-if="userIsAdminOrEdudept() && form.faculty === null">
                            <v-flex xs6 v-if="!form.department && !form.faculty">
                                <v-select
                                        clearable
                                        :items="roles"
                                        :error-messages="errors.role"
                                        v-model="form.role"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        label="Роль користувача для кого публікується (адмін)"
                                ></v-select>
                            </v-flex>
                        </template>

                        <template v-if="(userIsCurator() || userIsDeanOrInspector() || userIsAdminOrEdudept() && !form.academicGroup)">
                            <template v-if="!form.department && !form.role">
                                <v-flex xs12>
                                    <div class="title font-weight-thin">Публікація для факультету/спеціальності/семестру студентів</div>
                                </v-flex>
                                <faculty-speciality-semester-group-selector
                                        @selectFaculty="form.faculty = $event"
                                        @selectSpeciality="form.speciality = $event"
                                        @selectSemester="form.semester = $event"
                                        @selectAcademicGroup="form.academicGroup = $event"
                                        :selected-faculty="form.faculty"
                                        :selected-speciality="form.speciality"
                                        :selected-semester="form.semester"
                                        :selected-academic-group="form.academicGroup"
                                        :navigation-end-point="getNavigationEndPoint()"
                                />
                                <v-flex xs12>
                                    <div v-if="errors.faculty != null"
                                         class="red--text caption"
                                    >{{errors.faculty}}
                                    </div>
                                </v-flex>
                                <v-flex xs12>
                                    <div v-if="errors.speciality != null"
                                         class="red--text caption"
                                    >{{errors.speciality}}
                                    </div>
                                </v-flex>
                                <v-flex xs12>
                                    <div v-if="errors.semester != null"
                                         class="red--text caption"
                                    >{{errors.semester}}
                                    </div>
                                </v-flex>
                                <v-flex xs12>
                                    <div v-if="errors.academicGroup != null"
                                         class="red--text caption"
                                    >{{errors.academicGroup}}
                                    </div>
                                </v-flex>
                            </template>
                        </template>
                        
                    </v-layout>
                    <loading-placeholder v-else></loading-placeholder>
                </v-container>
            </v-card-text>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";
    import announcementFromTypes, {announcementFromTypesEnum} from "../../../../constants/mainDashboard/announcementFromTypes";
    import DatePicker from "../../../common/DatePicker";
    import TimePicker from "../../../common/TimePicker";
    import ImageUploader from "../../../common/ImageUploader";
    import {rolesEnum} from "../../../../constants/rolesEnum";
    import NavigationStudNavList from "../../../common/NavigationStudNavList";
    import {fileUploadEnum} from "../../../../constants/_internal/fileUploadEnum";
    import FacultySpecialitySemesterGroupSelector from "../../../common/FacultySpecialitySemesterGroupSelector";

    export default {
        props: {
            editId: Number,
            editMode: Boolean,
        },
        components: {
            FacultySpecialitySemesterGroupSelector,
            LoadingPlaceholder,
            DatePicker,
            TimePicker,
            ImageUploader,
            NavigationStudNavList,
        },
        data: () => {
            return {
                something: false,
                announcementFromTypes,
                announcementFromTypesList: [],
                form: {
                    titleImageUrl: null,
                    nameUk: null,
                    nameEn: null,
                    nameShortUk: null,
                    nameShortEn: null,
                    announcementFromType: null,
                    approved: false,
                    published: false,
                    publishStartTime: null,
                    publishEndTime: null,
                    
                    faculty: null,
                    speciality: null,
                    semester: null,
                    academicGroup: null,

                    department: null,
                    
                    role: null,
                    belongsToDepartment: null,
                },
                errors: {
                    nameUk: [],
                    nameEn: [],
                    nameShortUk: [],
                    nameShortEn: [],
                    announcementFromType: [],
                    approved: [],
                    published: [],
                    publishStartTime: [],
                    publishEndTime: [],

                    faculty: null,
                    speciality:  null,
                    semester: null,
                    academicGroup: null,
                    
                    department: [],
                    
                    role: [],
                    belongsToDepartment: [],
                },
                loading: false,
                loaded: false,
                loadingSave: false,

                academicGroups: [],
                departments: [],
                faculties: [],
                roles: [],
                semesters: [],
                specialities: [],

                rolesEnum,

                departmentsBelongsTo: [],
                facultiesNavigation: [],
                selectedNavigationItem: null,

                fileUploadEnum,
            }
        },
        mounted() {
            this.publishStartTime = new Date();
            this.publishEndTime = new Date();

            this.announcementFromTypesList = announcementFromTypes.filter(a => this.$auth.userHasAtLeastOneRole(a.roles));

            this.form.announcementFromType = this.announcementFromTypesList[0].id;

            HttpService.getInstance()
                .get("role")
                .then(resp => {
                    this.roles = resp;
                });

            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.faculties = resp;
                });

            HttpService.getInstance()
                .get("departments/getAll")
                .then(resp => {
                    this.departments = resp;
                });

            if (this.userIsModeratorOrHeadOfDepartment()) {
                HttpService.getInstance()
                    .get("teacher/navigation/department")
                    .then(departments => {
                        this.departmentsBelongsTo = departments;

                        if (departments.length === 1) {
                            this.form.belongsToDepartment = departments[0];
                        }
                    });
            }


            if (this.editMode) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`announcementsAdmin/${this.editId}`)
                    .then(resp => {
                        this.loading = false;

                        this.selectedNavigationItem = resp.academicGroup;

                        setTimeout(() => {
                            this.loaded = true;
                        }, 100);
                        this.form = resp;
                    })
            }
        },
        watch: {
            "form.faculty.id": function (newFacultyId, oldFacultyId) {
                if (newFacultyId !== oldFacultyId) {
                    HttpService.getInstance()
                        .get(`specialities/getAllByFaculty/${newFacultyId}`)
                        .then(resp => {
                            this.specialities = resp;
                        });

                }

                if (newFacultyId === undefined) {
                    this.form.speciality = null;
                    this.form.semester = null;
                    this.form.academicGroup = null;
                }
            },
            "form.speciality.id": function (newSpecialityId, oldSpecialityId) {
                if (newSpecialityId !== oldSpecialityId) {
                    HttpService.getInstance()
                        .get(`semester/semesterBySpeciality/${newSpecialityId}`)
                        .then(resp => {
                            this.semesters = resp.map(r => {
                                r.name = `${r.yearNumber} курс (${r.semesterNumber} семестр)`;
                                return r;
                            });
                        });

                }

                if (newSpecialityId === undefined) {
                    this.form.semester = null;
                    this.form.academicGroup = null;
                }
            },
            "form.semester.id": function (newSemesterId, oldSemesterId) {
                if (newSemesterId !== oldSemesterId) {
                    HttpService.getInstance()
                        .get(`academicGroup/getAll/${this.form.faculty.id}/${this.form.speciality.id}/${newSemesterId}`)
                        .then(resp => {
                            this.academicGroups = resp.map(r => {
                                r.name = `${r.groupNumber} група`;

                                return r;
                            });
                        });

                }

                if (newSemesterId === undefined) {
                    this.form.academicGroup = null;
                }
            },
            "form.belongsToDepartment.id": function (newBelongsToDepartmentId, oldBelongsToDepartmentId) {
                if (newBelongsToDepartmentId !== oldBelongsToDepartmentId) {
                    HttpService.getInstance()
                        .get(`navigation/getAllGroupsOnDepartmentNavigation/${newBelongsToDepartmentId}`)
                        .then(resp => {
                            this.facultiesNavigation = resp;
                        });
                }
            }
        },
        methods: {
            getNavigationEndPoint() {
               if (this.userIsDeanOrInspector()) {
                   return `navigation/getNavForDean`;
               }
               
               if (this.userIsModeratorOrHeadOfDepartment()) {
                   return `navigation/getAllGroupsOnDepartmentNavigation/${this.form.belongsToDepartment.id}`;
               }
               
               if (this.userIsAdminOrEdudept()) {
                   return `navigation/getNavRegisters`;
               }
            },
            getEditorConfig() {
                return {
                    image: {
                        uploadURL: `${this.$config.apiEndpoint}/file/upload/announcement`,
                        dropzoneOptions: {
                            method: "POST",
                            withCredentials: true,
                            headers: {
                                "Cache-Control": "",
                                "Authorization": `Bearer ${localStorage.getItem('access_token')}`
                            }
                        },
                    },
                }
            },
            userIsAdminOrEdudept() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.edudept]);
            },
            userIsModeratorOrHeadOfDepartment() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.headDepartment, rolesEnum.moderator]);
            },
            userIsDeanOrInspector() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.dean, rolesEnum.inspector]);
            },
            userIsCurator() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.curator]);
            },
            selectedGroup(item) {
                this.form.academicGroup = {id: item.academicGroupId};
                this.selectedNavigationItem = item;
            },
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("announcementsAdmin", this.form)
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("addedEdited");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>