<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <kroks-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import KroksIndex from "../../../../components/superAdminStep/Dictionaries/Kroks/KroksIndex";
    export default  {
        components: {
            KroksIndex,
        }
    }
</script>