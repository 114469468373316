<template>
    <v-menu
            ref="menu"
            :close-on-content-click="false"
            v-model="menu"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    v-on="on"
                    v-model="formattedDate"
                    label="Дата народження"
                    prepend-icon="event"
                    :error-messages="errorMessages"
                    readonly
            ></v-text-field>
        </template>
        <v-date-picker
                ref="picker"
                v-model="date"
                locale="uk-ua"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="save"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    import moment from "moment";

    export default {
        data: () => ({
            date: null,
            menu: false
        }),
        props: ['value', 'errorMessages'],
        computed: {
            formattedDate: {
                get() {
                    if (this.date !== null) {
                        return moment(this.date).format("DD.MM.YYYY");
                    }

                    return undefined;
                }
            }
        },
        mounted: function () {
            this.formatDate(this.value);
        },
        updated: function () {
            this.formatDate(this.value);
        },
        watch: {
            menu(val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        methods: {
            formatDate(date) {
                if (date !== null && date !== undefined) {
                    this.date = moment(date).format("YYYY-MM-DD");
                }
            },
            save(date) {
                this.$emit("input", moment(date).toDate());
                this.$refs.menu.save(date)
            },
        }
    }
</script>