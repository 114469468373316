<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Кафедра
                    </th>
                    <th class="text-left">
                        Дисципліни
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="department in data">
                    <td style="width: 25%;">{{department.nameUk}}</td>
                    <td>
                        <table class="nested-table">
                            <thead>
                            <tr>
                                <th>Дисципліна</th>
                                <th>Відео</th>
                                <th>Відео / теми</th>
                                <th>Презентації</th>
                                <th>Презентація / теми</th>
                                <th>Слайди / теми</th>
                                <th>Запитання всього</th>
                                <th>Запитання / теми</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="subject in department.subjects">
                                <td style="width: 250px;">{{subject.nameUk}} ({{subject.specialities.map(s => s.nameShortUk).join(", ")}})</td>
                                <td>{{subject.videos}}</td>
                                <td>{{subject.videosPerTopic.toFixed(1)}}</td>
                                <td>{{subject.presentations}}</td>
                                <td>{{subject.presentationsPerTopic.toFixed(1)}}</td>
                                <td>{{subject.slidesPerTopic.toFixed(1)}}</td>
                                <td>{{subject.questions}}</td>
                                <td>{{subject.questionsPerTopic.toFixed(1)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import HttpService from "@/HttpService";

export default {
    name: "eAristoStatistics",
    data() {
        return {
            data: [],
        }
    },
    mounted() {
        HttpService.getInstance()
            .get(`eAristoStats`)
            .then(resp => {
                this.loading = false;
                
                this.data = resp;
            });
    }
}
</script>

<style lang="scss" scoped>
    .nested-table {
        border-collapse: collapse;
        
        td, th {
            border: 1px solid #CCCCCC;
            padding: 5px;
        }
    }
</style>