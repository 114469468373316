<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <div class="title mb-3">Експрес-дії</div>
                <v-expansion-panels popout>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Пошук студента</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <admin-home-index/>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    import AdminHomeIndex from "./AdminHomeIndex";

    export default {
        components: {
            AdminHomeIndex
        }
    }
</script>