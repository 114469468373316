<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <question-pools-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import QuestionPoolsIndex from "../../../../components/superAdminStep/Dictionaries/QuestionPools/QuestionPoolsIndex";
    export default  {
        components: {
            QuestionPoolsIndex,
        }
    }
</script>