import TeacherRootView from '../views/teacher/TeacherRootView';
import TeacherMarksRootView from '../views/teacher/TeacherMarksRootView';
import TeacherHomeView from "../views/teacher/TeacherHomeView";
import MarksRootView from "../views/teacher/MarksRootView";
import MarksGroupRootView from "../views/teacher/MarksGroupRootView";
import MockView from "../views/admin/MockView";

import routeGuard from "../routeGuards/routeGuard";
import TeacherSuperAdminPreSelect from "../views/teacher/TeacherSuperAdminPreSelect";
import TeacherReworkScheduleView from "../views/teacher/TeacherReworkScheduleView";
import routeGuardTeacher from "../routeGuards/routeGuardTeacher";
import AcademicTimeAccountanceView from "@/views/teacher/AcademicTimeAccountanceView";

export default (router) => {
 return [
     {
         path: '/teacher',
         component: TeacherRootView,
         name: 'teacherRoot',
         beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
         redirect: {name: 'teacherSuperAdminPreselect'},
         children: [
             {
                 path: 'teacher-sa-preselect',
                 name: "teacherSuperAdminPreselect",
                 component: TeacherSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: '',
                 name: 'teacherHome',
                 component: TeacherHomeView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
                 redirect: {name: 'marksRoot'},
             },
             {
                 path: 'reworkSchedule',
                 name: 'reworkSchedule',
                 component: TeacherReworkScheduleView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'marks/:department?/:subject?',
                 name: "marksRoot",
                 component: MarksRootView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'monthlyReport',
                 name: 'monthlyReport',
                 component: MockView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
             {
                 path: 'timeAccounting/:department?',
                 name: 'timeAccounting',
                 component: AcademicTimeAccountanceView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
         ],
     },
     {
         path: '/teacher/marks/:department?/:subject?/:faculty?/:speciality?/:year?/:group?/:module?',
         component: TeacherMarksRootView,
         name: 'marksGroupRoot',
         redirect: {name: 'marksGroup'},
         children: [
             {
                 path: '/teacher/marks/:department?/:subject?/:faculty?/:speciality?/:year?/:group?/:module?',
                 name: "marksGroup",
                 component: MarksGroupRootView,
                 beforeEnter: (to, from, next) => routeGuardTeacher(to, from, next)(router),
             },
         ]
     }
 ];
}