<template>
    <v-card>
        <v-card-title style="display: block;">
            <div class="headline">
                Редагування оцінок
            </div>
            <div class="subtitle-1" style="display: inline-flex">
                за тему "<div style="max-width: 350px;" class="text-truncate">{{topicInfo.nameUk}}</div>"
                ({{formatShortDate(topicInfo.topicClass.classDate)}})
            </div>
        </v-card-title>
        <v-card-text>
            <mark-selector :activeModule="activeModule"
                           :selected-topics="infoTopicClass.topicsMarkValues"
                           :errors="errors"
                           :loadingSave="loadingSave"
                           @change="onChangeMarks($event)"
                           :classDate="infoTopicClass.classDate"
                           edit-mode
            />
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldClose')"
                    :disabled="loadingSave"
            >Відміна
            </v-btn>
            <v-btn
                    color="orange darken-1"
                    dark
                    type="button"
                    :disabled="loadingSave"
                    :loading="loadingSave"
                    @click="saveMarks"
            >Внести оцінки
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LoadingPlaceholder from "../../common/LoadingPlaceholder";
    import MarkSelector from "./MarkSelector";
    import HttpService from "../../../HttpService";
    import {remapErrors} from "../../../helpers/errorsHelper";
    import {formatShortDate} from "../../../helpers/dateHelper";

    export default {
        props: {
            activeModule: {
                type: Object
            },
            selectedTopics: {
                type: Array,
            },
            topicInfo: {
                type: Object,
                default: () => {
                    return {
                        topicClass: null,
                    };
                }
            },
        },
        components: {
            LoadingPlaceholder,
            MarkSelector
        },
        data: () => {
            return {
                activeStep: 1,
                loading: false,
                topicsMarkValues: null,

                errors: {},
                loadingSave: false,
                isSaved: false,
                formatShortDate,
                infoTopicClass: {
                    topicsMarkValues: [],
                    classDate: null,
                }
            }
        },
        mounted() {
            this.errors = {};
            
            HttpService.getInstance()
                .get(`teacher/teacherMarks/getMarksForEdit/${this.topicInfo.topicClass.id}`)
                .then(res => {
                    this.infoTopicClass = res;
                });
        },
        methods: {
            onChangeMarks(value) {
                this.topicsMarkValues = value;
            },
            saveMarks() {
                this.loadingSave = true;
                
                HttpService.getInstance()
                    .put(`teacher/teacherMarks`,
                        {
                            ...this.topicsMarkValues,
                            ...{
                                module: {id: this.activeModule.id},
                                academicGroup: {id: this.$route.params.group},
                            }
                        }
                    )
                    .then(resp => {
                        this.loadingSave = false;
                        this.isSaved = true;
                        this.$emit('shouldSaveAndClose');
                        this.$store.commit('snackMessages/set', {message: "Оцінки успішно відредаговані", color: 'success'});
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            }
        }
    }
</script>