<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex style="height: calc(100vh - 88px); position: fixed; width: 160px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-list-item :to="{name: 'staffManagementGrid'}"
                                         exact-active-class="list-title-active">
                                <v-list-item-content>
                                    <v-list-item-title>Всі типи</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-subheader class="mt-4 grey--name name--darken-1">Тип</v-subheader>
                            <navigation-list-template :items="staffTypesList"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 160px; width: calc(100% - 460px);">
                <v-card>
                    <staff-management-index/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import StaffManagementIndex from "../../../components/admin/StaffManagement/StaffManagementIndex";
    import NavigationListTemplate from "../../../components/common/NavigationListTemplate";
    import staffTypes, {staffTypesEnum} from "../../../constants/staffTypes";

    export default {
        components: {
            StaffManagementIndex,
            NavigationListTemplate
        },
        mounted() {
        },
        data: () => {
            return {
                staffTypesList: staffTypes.filter(s => s.id !== staffTypesEnum.professor)
                    .map(s => {
                    return {
                        id: s.id, 
                        name: s.nameUk,
                        route: {
                            name: 'staffManagementGrid',
                            params: {
                                staffTypeId: s.id,
                            }
                        },
                    };
                }),
            };
        }
    }
</script>