<template>
    <v-container grid-list-md fluid fill-height>
        <v-flex xs12>
            <div class="display-1">Список студентів, які підлягають оцінюванню Вами</div>
        </v-flex>
        <v-layout row wrap>
            <v-flex>
                <v-card :key="index" v-for="(exam, index) in exams" class="mb-3">
                        <v-layout wrap class="px-4 py-2">
                            <v-flex xs3>
                                <div class="body-2">ПІП студента</div>
                                <div class="title">
                                    {{exam.student.surname}}
                                    {{exam.student.name}}
                                    {{exam.student.patronymic}}
                                </div>
                            </v-flex>
                            <v-flex xs2>
                                <div>Час проведення іспиту</div>
                                <div class="title">
                                    {{formatLongDate(exam.examDateTime)}}
                                </div>
                            </v-flex>
                            <v-flex xs2>
                                <div class="body-2">Академічна група</div>
                                <div class="title">
                                    {{exam.student.academicGroup.groupNumber}}
                                </div>
                            </v-flex>
                            <v-flex xs1>
                                <div class="body-2">Факультет та спеціальність</div>
                                <div class="title">
                                    {{exam.student.faculty.nameShortUk}}
                                    {{exam.student.speciality.nameShortUk}}
                                </div>
                            </v-flex>
                            <v-flex xs1 class="text--darken-3">
                                <div class="body-2">Статус</div>
                                <div class="subtitle-2">
                                    <div v-if="exam.isPassed">Пройдено</div>
                                    <div v-if="!exam.isPassed">Не пройдено</div>
                                </div>
                            </v-flex>
                            <v-flex xs3 class="align-center d-flex">
                                <div class="title">
                                    <v-btn :to="{name: 'studentExamListDetail', params: {examId: exam.id}}" 
                                           color="primary">Перейти до складання іспиту</v-btn>
                                </div>
                            </v-flex>

                        </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {formatLongDate} from "../../../helpers/dateHelper";
    import moment from "moment";
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            exams: [],
            formatLongDate,
            
            interval: null,
        }),
        mounted() {
            this.getExamsList();

            this.interval = setInterval(function () {
                this.getExamsList();
            }.bind(this), 30000);
        },
        methods: {
            getExamsList() {
                HttpService.getInstance()
                    .get("FinalExamTeacher/GetStudentExamList")
                    .then(resp => {
                        this.exams = resp;
                    })
            },
        },
        beforeDestroy: function(){
            clearInterval(this.interval);
        }
    }
</script>