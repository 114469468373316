<template>
    <v-app>
        <vue-headful title="ePlato"/>

        <chief-dept-navigation-drawer-stud-nav
                @toggleDrawer="drawerApp = $event"
                :drawer="drawerApp"
                route-to="moderatorRegisters"
                :additional-route-params="{
                                            department: $route.params.department, 
                                            subject: $route.params.subject}"
                :api-endpoint="getNavigationApiEndpoint"
        />

        <toolbar @toggleDrawer="drawerApp = !drawerApp"/>
        
        <v-main>
            <router-view :key="$route.fullPath"/>
        </v-main>
        
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/moderator/common/Toolbar";
    import TeacherNavigationDrawer from "../../components/teacher/common/TeacherNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";
    import ChiefDeptNavigationDrawerStudNav from "../../components/chiefDept/common/ChiefDeptNavigationDrawerStudNav";

    export default {
        components: {
            Toolbar,
            ChiefDeptNavigationDrawerStudNav,
            navigation: TeacherNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
            navigationApiEndpoint: "",
        }),
        computed: {
            getNavigationApiEndpoint() {
                return `navigation/getNavRegistersForDepartmentSubject/${this.$route.params.department}/${this.$route.params.subject}`;
            }
        }
    }
</script>
