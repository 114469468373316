<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <rating-professor-index></rating-professor-index>
        </v-layout>
    </v-container>
</template>
<script>
    import RatingProfessorIndex from "../../components/ratingAdmin/ratingProfessorStat/RatingProfessorIndex";

    export default  {
        components: {
            RatingProfessorIndex,
        }
    }
</script>