import StatisticsMarksByFacultyView from "../views/admin/StatisticsMarks/StatisticsMarksByFacultyView";

const AdminRootView = () => import(/* webpackChunkName: "admin" */ "../views/admin/AdminRootView");
const SuccessSheets = () => import(/* webpackChunkName: "admin" */ "../views/admin/SuccessSheet/SuccessSheetsView");
const FacultiesView = () => import(/* webpackChunkName: "admin" */ '../views/admin/Dictionaries/Other/FacultiesView');
const SpecialitiesView = () => import(/* webpackChunkName: "admin" */ '../views/admin/Dictionaries/Other/SpecialitiesView');
const DepartmentsView = () => import(/* webpackChunkName: "admin" */ '../views/admin/Dictionaries/Other/DepartmentsView');

import AcademicPositionsView from '../views/admin/Dictionaries/Other/AcademicPositionsView';
import AcademicStatusesView from '../views/admin/Dictionaries/Other/AcademicStatusesView';
import ScientificDegreesView from '../views/admin/Dictionaries/Other/ScientificDegreesView';
import SubjectsView from '../views/admin/Dictionaries/Other/SubjectsView';
import ProfessorsView from '../views/admin/Dictionaries/PedagogicalStaff/ProfessorsView';
import EducationPlansView from '../views/admin/Dictionaries/Other/EducationPlansView';
import GroupsView from '../views/admin/Dictionaries/Students/GroupsView';
import StudentsView from '../views/admin/Dictionaries/Students/StudentsView';
import StudentsAccountsView from '../views/admin/Dictionaries/Students/StudentsAccountsView';
import UserManagement from '../views/admin/UserManagement/UserManagement';

import AnnouncementsView from '../views/admin/MainDashboard/AnnouncementsView';
import PollsView from '../views/admin/MainDashboard/PollsView';
import PollPoolsView from '../views/admin/MainDashboard/PollPoolsView';

import AdminPaymentsHistory from "../views/admin/Payments/AdminPaymentsHistory";

import ModeratorsView from "../views/admin/Dictionaries/PedagogicalStaff/ModeratorsView";
import DeansView from "../views/admin/Dictionaries/PedagogicalStaff/DeansView";
import CuratorsView from "../views/admin/Dictionaries/PedagogicalStaff/CuratorsView";

import EmptyView from '../views/common/EmptyView';
import AdminRegistersRootView from "../views/admin/AdminRegistersRootView";
import RegisterRootView from "../views/admin/Register/RegisterRootView";
import RegistersView from "../views/admin/Register/RegistersView";
import AuditView from "../views/admin/Logs/AuditView";
import LogsView from "../views/admin/Logs/LogsView";
import ApplicantsView from "../views/admin/Dictionaries/Students/ApplicantsView";
import LecturesView from "../views/admin/Lectures/LecturesView";
import AdminLecturesRootView from "../views/admin/AdminLecturesRootView";
import LecturesRootView from "../views/admin/Lectures/LecturesRootView";
import StaffView from "../views/admin/Staff/StaffView";
import ExpelledAcademicVacationView from "../views/admin/Dictionaries/Students/ExpelledAcademicVacationView";

import routeGuardAdmin from "../routeGuards/routeGuardAdmin";
import HomeView from "../views/admin/Home/HomeView";
import KrokResults from "../views/admin/krokResults/KrokResults";
import ApplicantsImportView from "../views/admin/Dictionaries/Students/ApplicantsImportView";
import StatisticsReworksByFacultyView from "@/views/admin/StatisticsReworks/StatisticsReworksByFacultyView";
import StatisticsReworksAllView from "@/views/admin/StatisticsReworks/StatisticsReworksAllView";
import StatisticsMarksGeneralView from "@/views/admin/StatisticsMarks/StatisticsMarksGeneralView";
import StatisticsMarksByDepartmentView from "@/views/admin/StatisticsMarks/StatisticsMarksByDepartmentView";
import routeGuardDean from "@/routeGuards/routeGuardDean";
import StatSuccessRate from "@/views/admin/Statistics/StatSuccessRate";
import StatisticsMarksByFacultyYears from "@/views/admin/StatisticsMarks/StatisticsMarksByFacultyYears";
import StatisticsMarksTimeFacultyView from "@/views/admin/StatisticsMarks/StatisticsMarksTimeFacultyView";
import StatisticsMarksTimeDepartmentView from "@/views/admin/StatisticsMarks/StatisticsMarksTimeDepartmentView";
import StatisticsMarksByDepartmentGenderAgeView
    from "@/views/admin/StatisticsMarks/StatisticsMarksByDepartmentGenderAgeView";
import AdminTimeAccountingDepartmentView from "@/views/admin/Statistics/AdminTimeAccountingDepartmentView";
import StatisticsReworkAdmissionsFacultyView from "@/views/admin/StatisticsMarks/StatisticsReworkAdmissionsFacultyView";
import StatisticsReworkAdmissionsGeneralView from "@/views/admin/StatisticsMarks/StatisticsReworkAdmissionsGeneralView";
import SystemSettingsView from "@/views/admin/SystemSettings/SystemSettingsView.vue";
import EAristoStatisticsView from "@/views/admin/eAristoStatistics/eAristoStatisticsView.vue";
import StatisticsProfessorMarksView from "@/views/admin/StatisticsMarks/StatisticsProfessorMarksView.vue";

export default (router) => {
    return [
        {
            path: '/admin',
            component: AdminRootView,
            name: 'adminRoot',
            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
            redirect: {name: 'adminHome'},
            children: [
                {
                    path: 'adminHome',
                    name: 'adminHome',
                    component: HomeView,
                },
                {
                    path: 'register',
                    component: EmptyView,
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    children: [
                        {
                            path: '/registers/:subject?',
                            name: 'registersRoot',
                            component: RegistersView,
                        }
                    ]
                },
                {
                    path: 'success-sheet',
                    component: EmptyView,
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    children: [
                        {
                            path: 'success-sheets',
                            name: 'successSheets',
                            component: SuccessSheets,
                        }
                    ]
                },
                {
                    path: 'userManagement/:roleId?',
                    name: 'userManagementGrid',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: UserManagement,
                },
                {
                    path: 'staffManagement/:staffTypeId?',
                    name: 'staffManagementGrid',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: StaffView,
                },
                {
                    path: 'paymentsHistory',
                    name: 'paymentsHistoryGrid',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: AdminPaymentsHistory,
                },
                {
                    path: 'krokResults',
                    name: 'krokResults',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: KrokResults,
                },
                {
                    path: 'dictionaries',
                    component: EmptyView,
                    children: [
                        {
                            path: 'faculties',
                            name: 'facultiesGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: FacultiesView,
                        },
                        {
                            path: 'specialities',
                            name: 'specialitiesGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: SpecialitiesView,
                        },
                        {
                            path: 'departments/:facultyId?',
                            name: 'departmentsGridFaculty',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: DepartmentsView,
                        },
                        {
                            path: 'departments/type/:departmentType?',
                            name: 'departmentsGridDepartment',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: DepartmentsView,
                        },
                        {
                            path: 'academicPositions',
                            name: 'academicPositionsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: AcademicPositionsView,
                        },
                        {
                            path: 'academicStatuses',
                            name: 'academicStatusesGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: AcademicStatusesView,
                        },
                        {
                            path: 'scientificDegrees',
                            name: 'scientificDegreesGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: ScientificDegreesView,
                        },
                        {
                            path: 'subjects/:specialityId?',
                            name: 'subjectsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: SubjectsView,
                        },
                        {
                            path: 'lecture',
                            component: EmptyView,
                            children: [
                                {
                                    path: '/lectures',
                                    name: 'lecturesRoot',
                                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                                    component: LecturesView,
                                }
                            ]
                        },
                        {
                            path: 'educationPlans',
                            name: 'educationPlan',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: EducationPlansView,
                        },
                        {
                            path: 'professors/:departmentId?',
                            name: 'professorsDepartmentsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: ProfessorsView,
                        },
                        {
                            path: 'moderators',
                            name: 'moderatorsGrid',
                            component: ModeratorsView,
                        },
                        {
                            path: 'deans',
                            name: 'deansGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: DeansView,
                        },
                        {
                            path: 'curators',
                            name: 'curatorsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: CuratorsView,
                        },
                        {
                            path: 'groups/:faculty?/:speciality?/:semester?',
                            name: 'groupsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: GroupsView,
                        },
                        {
                            path: 'students/:faculty?/:speciality?/:semester?/:group?',
                            name: 'studentsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StudentsView,
                        },
                        {
                            path: 'expelledAcademicVacation',
                            name: 'expelledAcademicVacation',
                            component: ExpelledAcademicVacationView,
                        },
                        {
                            path: 'studentsAccounts/:faculty?/:speciality?/:semester?',
                            name: 'studentsAccountsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StudentsAccountsView,
                        },
                        {
                            path: 'applicants-import',
                            name: 'applicantsImportGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: ApplicantsImportView,
                        },
                        {
                            path: 'applicants',
                            name: 'applicantsGrid',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: ApplicantsView,
                        },
                        {
                            path: 'practics',
                            name: 'practics',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: EmptyView,
                        },
                    ]
                },

                {
                    path: 'statistics',
                    component: EmptyView,
                    children: [
                        {
                            path: 'statisticsMarks',
                            name: 'statisticsMarks',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksByFacultyView,
                        },
                        {
                            path: 'statisticsMarksGeneral',
                            name: 'statisticsMarksGeneral',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksGeneralView,
                        },
                        {
                            path: 'statisticsMarksDepartments',
                            name: 'statisticsMarksDepartments',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksByDepartmentView,
                        },
                        {
                            path: 'statisticsMarksDepartmentsGenderAge',
                            name: 'statisticsMarksDepartmentsGenderAge',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksByDepartmentGenderAgeView,
                        },
                        {
                            path: 'statisticsMarksFaculties/:semester?',
                            name: 'statisticsMarksFaculties',
                            component: StatisticsMarksByFacultyYears,
                            beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
                        },
                        {
                            path: 'statisticsReworksAll',
                            name: 'statisticsReworksAll',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsReworksAllView,
                        },
                        {
                            path: 'statisticsReworks/:faculty?',
                            name: 'statisticsReworks',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsReworksByFacultyView,
                        },

                        {
                            path: 'successRate/:faculty?/:speciality?/:semester?',
                            name: 'adminSuccessRate',
                            component: StatSuccessRate,
                            beforeEnter: (to, from, next) => routeGuardDean(to, from, next)(router),
                        },
                        {
                            path: 'statisticsMarksTimeFaculty/:faculty?',
                            name: 'statisticsMarksTimeFaculty',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksTimeFacultyView,
                        },
                        {
                            path: 'statisticsMarksTimeDepartment/:department?',
                            name: 'statisticsMarksTimeDepartment',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsMarksTimeDepartmentView,
                        },
                        {
                            path: 'timeAccounting/:department?',
                            name: 'adminTimeAccounting',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: AdminTimeAccountingDepartmentView,
                        },

                        {
                            path: 'statisticsReworkAdmissionsGeneral',
                            name: 'statisticsReworkAdmissionsGeneral',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsReworkAdmissionsGeneralView,
                        },
                        {
                            path: 'statisticsReworkAdmissions/:faculty?',
                            name: 'statisticsReworkAdmissions',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsReworkAdmissionsFacultyView,
                        },
                        {
                            path: 'eAristoStats',
                            name: 'eAristoStats',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: EAristoStatisticsView,
                        },
                        {
                            path: 'statisticsProfessorMarks',
                            name: 'statisticsProfessorMarks',
                            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                            component: StatisticsProfessorMarksView,
                        },
                    ]
                },

                {
                    path: 'mainDashboard',
                    component: EmptyView,
                    children: [
                        {
                            path: 'announcements',
                            name: 'announcementsGrid',
                            component: AnnouncementsView,
                        },
                        {
                            path: 'polls',
                            name: 'pollsGrid',
                            component: PollsView,
                        },
                        {
                            path: 'pollPools',
                            name: 'pollPoolsGrid',
                            component: PollPoolsView,
                        },
                    ]
                },
                {
                    path: 'audit',
                    name: 'audit',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: AuditView,
                },
                {
                    path: 'logs',
                    name: 'logs',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: LogsView,
                },
                {
                    path: 'systemSettings',
                    name: 'systemSettings',
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                    component: SystemSettingsView,
                },
            ],
        },
        {
            path: '/admin/registers/:faculty?/:speciality?/:year?/:group?/:subject?',
            component: AdminRegistersRootView,
            name: 'registersFullRoot',
            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
            redirect: {name: 'registers'},
            children: [
                {
                    path: '/admin/registers/:faculty?/:speciality?/:year?/:group?/:subject?',
                    name: "registers",
                    component: RegisterRootView,
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                },
            ]
        },
        {
            path: '/admin/lectures/:faculty?/:speciality?/:semester?',
            component: AdminLecturesRootView,
            name: 'lecturesFullRoot',
            beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
            redirect: {name: 'lectures'},
            children: [
                {
                    path: '/admin/lectures/:faculty?/:speciality?/:semester?/:subject?',
                    name: "lectures",
                    component: LecturesRootView,
                    beforeEnter: (to, from, next) => routeGuardAdmin(to, from, next)(router),
                },
            ]
        },
    ]
}