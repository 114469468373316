import NbQuizRootView from '../views/nbQuizAdmin/NbQuizRootView';
import NbQuizHomeView from "../views/nbQuizAdmin/NbQuizHomeView";
import ModuleRootView from "../views/nbQuizAdmin/ModuleRootView";
import TopicsRootView from "../views/nbQuizAdmin/TopicsRootView";
import QuizRootTopicView from "../views/nbQuizAdmin/QuizRootTopicView";
import QuizRootLectureView from "../views/nbQuizAdmin/QuizRootLectureView";

import NbQuizSuperAdminPreSelect from "../views/nbQuizAdmin/NbQuizSuperAdminPreSelect";
import routeGuardNbQuizAdmin from "../routeGuards/routeGuardNbQuizAdmin";

export default (router) => {
 return [
     {
         path: '/nbQuizAdmin',
         component: NbQuizRootView,
         name: 'nbQuizAdminRoot',
         beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
         redirect: {name: 'nbQuizSuperAdminPreselect'},
         children: [
             {
                 path: 'nb-quiz-sa-preselect',
                 name: "nbQuizSuperAdminPreselect",
                 component: NbQuizSuperAdminPreSelect,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
             {
                 path: '',
                 name: 'nbQuizAdminHome',
                 component: NbQuizHomeView,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
             {
                 path: 'quizBank/:department?/:subject?',
                 name: "quizBank",
                 component: ModuleRootView,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
             {
                 path: 'quizBank/:department?/:subject?/topic/:module?',
                 name: "quizBankModule",
                 component: TopicsRootView,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
             {
                 path: 'quizBank/:department?/:subject?/topic/:module?/quizTopic/:topic?',
                 name: "quizBankTopic",
                 component: QuizRootTopicView,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
             {
                 path: 'quizBank/:department?/:subject?/topic/:module?/quizLecture/:lecture?',
                 name: "quizBankLecture",
                 component: QuizRootLectureView,
                 beforeEnter: (to, from, next) => routeGuardNbQuizAdmin(to, from, next)(router),
             },
         ],
     },
 ];
}