<template>
    <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
    >
        <template v-slot:item.finalControlType="{item}">{{ mapFinalControlType(item.finalControlType) }}</template>
        <template v-slot:item.canBlockModules="{item}">
            <v-icon v-if="!item.canBlockModules">radio_button_unchecked</v-icon>
            <v-icon v-if="item.canBlockModules">radio_button_checked</v-icon>
        </template>
        <template v-slot:item.canBlockTopics="{item}">
            <v-icon v-if="!item.canBlockTopics">radio_button_unchecked</v-icon>
            <v-icon v-if="item.canBlockTopics">radio_button_checked</v-icon>
        </template>
        <template v-slot:item.departments="{item}">
            <div :key="index" v-for="(department, index) in item.departments">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-chip v-on="on">{{department.nameShortUk}}</v-chip>
                    </template>
                    {{department.nameUk}}
                </v-tooltip>
            </div>
        </template>
        <template v-slot:item.specialities="{item}">
            <div :key="index" v-for="(speciality, index) in item.specialities">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-chip v-on="on">{{speciality.nameShortUk}}</v-chip>
                    </template>
                    {{speciality.nameUk}}
                </v-tooltip>
            </div>
        </template>

        <template v-slot:item.actions="{item}">
            <div class="actions-wrapper">
                <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                    <v-icon color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>
</template>
<script>
    import finalControlTypes from "../../../../constants/admin/finalControlTypes";
    
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        methods: {
            mapFinalControlType(finalControlType) {
                return finalControlTypes.find(f => f.typeEnum === finalControlType).nameConst;
            }
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            headers: [
                {text: 'Назва (укр)', value: 'nameUk'},
                // {text: 'Назва (eng)', value: 'nameEn'},
                {text: 'Скорочено (укр)', value: 'nameShortUk'},
                // {text: 'Скорочено (eng)', value: 'nameShortEn'},
                {text: 'Кафедри', value: 'departments'},
                {text: 'Спеціальності', value: 'specialities'},
                {text: 'Тип', value: 'finalControlType'},
                {text: 'Блок. модулів', value: 'canBlockModules'},
                {text: 'Блок. тем', value: 'canBlockTopics'},
                {text: 'Вартість відпр.', value: 'reworkPrice'},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>