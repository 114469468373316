var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture-input-wrapper d-flex align-center justify-center"},[(_vm.isError && !_vm.loading)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-layout',{staticClass:"justify-center text-center",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"text--darken-1 red--text mt-6"},[_vm._v("Виникла помилка")]),_c('v-btn',{staticClass:"mt-2",on:{"click":function($event){_vm.isError = false}}},[_vm._v("Спробувати ще раз")])],1)],1)],1):_vm._e(),(_vm.loading)?_c('div',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"size":150,"color":"primary","indeterminate":""}})],1)],1):_vm._e(),(!_vm.isError && !_vm.loading)?_c('picture-input',{ref:"pictureInput",attrs:{"zIndex":0,"width":"200","height":"200","margin":"16","accept":"image/jpeg,image/png","size":"10","buttonClass":"v-btn v-btn--contained theme--light v-size--default primary upload-btn","removeButtonClass":"v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text upload-btn","prefill":_vm.getImagePrefill,"removable":true,"customStrings":{
              upload: '<p>Ваш прийстрій не підтримує завантаження файлів.</p>',
              drag: 'Перетягніть зображення або <br>клікніть для вибору файлу',
              tap: 'Торкніться тут, щоб вибрати фото <br>з вашої галереї',
              change: 'Змінити зображення',
              remove: 'Видалити зображення',
              select: 'Вибрати зображення',
              selected: '<p>Фото було успішно вибрано!</p>',
              fileSize: 'Файл перевищує максимальний розмір',
              fileType: 'Даний тип файлу не підтримується.',
              aspect: 'Landscape/Portrait'
        }},on:{"change":_vm.onFileUpload,"remove":_vm.onFileRemove}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }