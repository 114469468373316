<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-card>
                        <v-card-title>Виберіть модератора, щоб переглянути аккаунт</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-layout
                                    text-center
                                    wrap
                                >
                                    <v-flex xs12 class="mt-6">
                                        <v-autocomplete
                                            :items="professors"
                                            v-model="selectedProfessor"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            solo
                                            label="Модератор"
                                        ></v-autocomplete>
                                    </v-flex>
                                    <v-flex v-if="selectedProfessor" xs12 class="mt-6">
                                        <v-btn :disabled="loadingSave" @click="putData()" large dark color="red darken-2">Перейти</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import HttpService from "../../HttpService";
import {rolesEnum} from "../../constants/rolesEnum";

export default {
    data: () => ({
        loadingSave: false,

        selectedProfessor: null,
        professors: [],
    }),
    mounted() {
        const roles = this.getUserRole();

        if (roles.indexOf(rolesEnum.admin) > -1) {

            HttpService.getInstance()
                .get(`moderators/getAll`)
                .then(resp => {
                    this.professors = resp.map(prof => {
                        let profNamePrefix = "";

                        if (prof.academicPosition) {
                            profNamePrefix = `${prof.academicPosition.nameShortUk} `;
                        }

                        prof.name = `${profNamePrefix}${prof.surname} ${prof.name} ${prof.patronymic}`;

                        return prof;
                    });
                });
        } else {
            this.$router.push({name: "moderatorHome"}).catch(err => {});
        }

    },
    methods: {
        getUserRole() {
            return this.$auth.getRoles();
        },
        putData() {
            this.loadingSave = true;

            HttpService.getInstance()
                .put("superAdmin/setProfessor", this.selectedProfessor)
                .then(resp => {
                    this.loadingSave = false;

                    this.$router.push({name: "eAristoProfessorHome"}).catch(err => {});
                })
                .catch(err => {
                    this.loadingSave = false;
                });
        }
    }
}
</script>