<template>
    <v-app-bar
            :color="color"
            dark
            app
            :clipped-left="$vuetify.breakpoint.mdAndUp"
            fixed
    >
        <v-toolbar-title style="width: 200px" class="ml-0 pl-0 cursor-pointer" @click="navigateTo()">
            <toolbar-logo></toolbar-logo>
        </v-toolbar-title>

        <v-toolbar-items class="hidden-sm-and-down" v-if="$auth.isAuthenticated()">
            <v-btn id="announcement-list" class="hidden-sm-and-down" :to="{name: 'announcementsList'}" activeClass="active-route-btn" text>
                {{ $t("toolbar.announcements") }}
            </v-btn>
            
            <v-btn id="survey-list" class="hidden-sm-and-down" :to="{name: 'surveysList'}" activeClass="active-route-btn" text>
                {{ $t("toolbar.surveys") }}
            </v-btn>
        </v-toolbar-items>

        <language-switcher/>

        <v-spacer></v-spacer>
        
        <quick-navigation/>
        <logout-button/>
        
    </v-app-bar>
</template>
<script>
    import LanguageSwitcher from "../../common/LanguageSwitcher";
    import LogoutButton from "../../common/toolbar-controls/LogoutButton";    
    import QuickNavigation from "../../common/toolbar-controls/QuickNavigation";
    import ToolbarLogo from "../../common/ToolbarLogo";    
    
    export default {
        components: {
            LogoutButton,
            QuickNavigation,
            LanguageSwitcher,
            ToolbarLogo,
        },
        props: {
            color: {
                type: String
            }
        },
        methods: {
            navigateTo(){
                if (this.$route.name !== 'unauthorized') {
                    this.$router.push({name: "home"}).catch(err => {});
                }
            }
    
        }
    }
</script>