<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex >
                <v-card>
                    <statistics-marks-by-departments-gender-age-index :key="$route.fullPath"/>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import StatisticsMarksByDepartmentsGenderAgeIndex
        from "@/components/admin/StatisticsMarks/StatisticsMarksByDepartmentsGenderAgeIndex";

    export default  {
        components: {
            StatisticsMarksByDepartmentsGenderAgeIndex,
        },
        data: () => {
            return {
             

            };
        },
    }
</script>