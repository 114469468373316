<template>
    <div>
        <lectures-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @editItem="editItem"
                @deleteItem="deleteItem"
                @moveditems="removed"
                @navigateTo=""
        >
        </lectures-grid>

        <v-dialog v-model="dialog" max-width="850px" scrollable>
            <add-edit-lecture
                    v-if="dialog"
                    @shouldCloseAddEdit="dialog = false"
                    @addedEdited="addedEdited"
                    :max-topic-order="maxTopicOrder"
                    :max-class-order="maxClassOrder"
                    :edit-mode="editMode"
                    :edit-id="editId"
            ></add-edit-lecture>
        </v-dialog>

        <v-dialog v-model="dialogRemove" max-width="290">
            <delete-confirm
                    @shouldCloseDeleteConfirm="dialogRemove = false"
                    @removed="removed"
                    :delete-id="deleteId"
            >
            </delete-confirm>
        </v-dialog>

        <v-btn
                color="orange"
                dark
                fixed
                bottom
                right
                fab
                @click="addItem()"
        >
            <v-icon>add</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import LecturesGrid from "./LecturesGrid";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import AddEditLecture from "./AddEditLecture";
    import DeleteConfirm from "./DeleteConfirm";
    import maxBy from "lodash/maxBy";

    export default {
        components: {
            LecturesGrid,
            AddEditLecture,
            DeleteConfirm
        },
        data: () => {
            return {
                dialog: false,
                dialogRemove: false,
                editId: null,
                editMode: false,
                deleteId: null,
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                },
                maxTopicOrder: 0,
                maxClassOrder: 0,
                module: {
                    topicsAllCount: 0,
                },
            }
        },
        mounted() {
          HttpService.getInstance()
              .get(`module/${this.$route.params.module}`)  
              .then(module => {
                  this.module = module;
              })
        },
        methods: {
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`lecture/getByModule/${this.$route.params.module}?${queryParams}`)
                    .then(modules => {
                        this.items = modules.items;
                        this.total = modules.total;

                        this.setMaxOrderNumber();

                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
            addItem() {
                this.dialog = true;
                this.editId = null;
                this.editMode = false;

                this.setMaxOrderNumber();                               
            },
            editItem(itemId) {
                this.dialog = true;
                this.editId = itemId;
                this.editMode = true;
            },
            setMaxOrderNumber() {
                const topic = maxBy(this.items, 'orderNumber');

                if (topic) {
                    this.maxTopicOrder = topic.orderNumber;
                    this.maxClassOrder = topic.classNumber;
                }
            },
            addedEdited(createAnother) {
                this.dialog = false;
                this.editId = null;

                this.getItems(objectQueryStringify(this.pagination));
                
                if (createAnother) {
                    setTimeout(() => {
                        this.addItem();
                    }, 200);
                }
            },
            deleteItem(itemId) {
                this.dialogRemove = true;
                this.deleteId = itemId;
            },
            removed() {
                this.dialogRemove = false;
                this.deleteId = null;

                this.getItems(objectQueryStringify(this.pagination));
            },
        },
    }
</script>