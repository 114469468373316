<template>
    <div>
        <div class="group-btns-wrapper">
            <v-btn v-if="selectedItemsComputed.length" @click="$emit('change')" color="primary">
                Поновити на вибраний факультет/спеціальність/курс
            </v-btn>
        </div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                show-select
                v-model="selectedItemsComputed"
                :footer-props="{
                    itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
                }"
        >
            <template v-slot:item.surname="{ item }">{{ item.surname }} {{ item.name }} {{ item.patronymic }}</template>
            <template v-slot:item.birthday="{ item }">{{formatShortDate(item.birthday)}}</template>
            <template v-slot:item.isExpelled="{ item }">
                <v-radio-group v-model="item.isExpelled">
                    <v-radio
                            :value="true"
                    ></v-radio>
                </v-radio-group>
            </template>
            <template v-slot:item.isAcademicVacation="{ item }">
                <v-radio-group v-model="item.isAcademicVacation">
                    <v-radio
                            :value="true"
                    ></v-radio>
                </v-radio-group>
            </template>
            <template v-slot:item.semester="{ item }">{{ `${item.semester.yearNumber} (${item.semester.semesterNumber} сем.)` }}</template>
            <template v-slot:item.actions="{ item }">
                <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                    <v-icon color="red">delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {formatShortDate} from "../../../../helpers/dateHelper";

    export default {
        props: {
            items: {
                type: Array,
            },
            selectedItems: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        computed: {
            selectedItemsComputed: {
                get() { return this.selectedItems},
                set(val) { this.$emit('update:selectedItems', val)}
            }
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            }
        },
        data: () => ({
            pagination: {
                totalItems: 0,
            },
            formatShortDate,
            selected: [],
            headers: [
                {text: '№', value: 'index', sortable: false},
                {text: 'Прізвище, ім\'я, по-батькові', value: 'surname', sortable: true},
                {text: 'Дата народження', value: 'birthday', sortable: true},
                {text: 'Відрахований', value: 'isExpelled', sortable: true},
                {text: 'Академ. відпустка', value: 'isAcademicVacation', sortable: true},
                {text: 'Факультет', value: 'faculty.nameUk', sortable: false},
                {text: 'Спеціальність', value: 'speciality.nameUk', sortable: false},
                {text: 'Курс (семестр)', value: 'semester', sortable: false},
                {text: 'Група', value: 'academicGroup.groupNumber', sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>

<style lang="scss">
    .group-btns-wrapper {
        margin-bottom: 10px;
        height: 36px;

        & .v-btn {
            margin-right: 10px;
        }
    }
</style>