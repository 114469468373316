export const finalControlTypesEnum = {
    finalModuleControl: 0,
    nonEvaluableCredit: 1,
};

export default [
    {
        typeEnum: finalControlTypesEnum.finalModuleControl,
        nameConst: "Модульний контроль"
    },
    {
        typeEnum: finalControlTypesEnum.nonEvaluableCredit,
        nameConst: "Залік"
    },
];
