<template>
    <div>
        <div class="headline">Рейтингові види робіт</div>
        <v-data-table
                :headers="headers"
                :items="items"
                :server-items-length="total"
                :loading="loading"
                :options.sync="pagination"
                class="elevation-3"
                hide-default-footer
        >
            <template v-slot:item.isIndividual="{item}">
                <v-icon v-if="!item.isIndividual">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isIndividual">radio_button_checked</v-icon>
            </template>

            <template v-slot:item.isDepartment="{item}">
                <v-icon v-if="!item.isDepartment">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isDepartment">radio_button_checked</v-icon>
            </template>

            <template v-slot:item.isEnumerable="{item}">
                <v-icon v-if="!item.isEnumerable">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isEnumerable">radio_button_checked</v-icon>
            </template>

            <template v-slot:item.isEnglish="{item}">
                <v-icon v-if="!item.isEnglish">radio_button_unchecked</v-icon>
                <v-icon v-if="item.isEnglish">radio_button_checked</v-icon>
            </template>

            <template v-slot:item.description="{item}">
                <div class="description-mini">{{item.description}}</div>
            </template>
            
            <template v-slot:item.actions="{item}">
                <div style="min-width: 80px;">
                    <v-btn icon class="mx-0" @click="$emit('editItem', item.id)">
                        <v-icon color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="$emit('deleteItem', item.id)">
                        <v-icon color="red">delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
            },
            total: {
                type: Number,
            },
            loading: {
                type: Boolean,
            },
        },
        watch: {
            pagination(paginationVal, prevPaginationVal) {
                this.$emit("paginate", paginationVal);
            },
        },
        data: () => ({
            pagination: {
                rowsPerPage: -1,
            },
            headers: [
                {text: '№ п/п', value: 'order', wdith: "7%", sortable: false},
                {text: '№ пункту', value: 'orderCustom', wdith: "7%", sortable: false},
                {text: 'Назва', value: 'name', width: "25%", sortable: false},
                {text: 'Опис', value: 'description', width: "15%", sortable: false},
                {text: 'Приклад', value: 'example', width: "15%", sortable: false},
                {text: 'К-сть балів', value: 'points', sortable: false},
                {text: 'Індивідуальний показник', value: 'isIndividual', width: "7%", sortable: false},
                {text: 'Кафедральний показник', value: 'isDepartment', width: "7%", sortable: false},
                {text: 'Множинний?', value: 'isEnumerable', width: "3%", sortable: false},
                {text: 'Англомовний?', value: 'isEnglish', width: "3%", sortable: false},
                {text: 'Дії', value: 'actions', sortable: false}
            ],
        }),
    }
</script>
<style lang="scss">
    .description-mini {
        font-size: 10px;
    }
</style>