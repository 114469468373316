<template>
    <v-app>
        <navigation @toggleDrawer="drawerApp = $event" :drawer="drawerApp"/>
        <toolbar
                :is-lang-switcher="false"
                color="indigo darken-3"
                @toggleDrawer="drawerApp = !drawerApp"
        />
        <v-content>
            <router-view/>
        </v-content>
        <snack-displayer/>
    </v-app>
</template>

<script>
    import Toolbar from "../../components/common/Toolbar";
    import StepAdminProfileNavigationDrawer from "../../components/stepAdminProfile/common/StepAdminProfileNavigationDrawer";
    import SnackDisplayer from "../../components/common/SnackDisplayer";

    export default {
        components: {
            Toolbar,
            navigation: StepAdminProfileNavigationDrawer,
            snackDisplayer: SnackDisplayer,
        },
        data: () => ({
            drawerApp: true,
        }),
    }
</script>
