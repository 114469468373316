<template>
    <div>
        <v-form ref="searchForm" @submit="submitSearch" novalidate="true">
            <v-card-title class="px-0">
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    label="Пошук по імені"
                    single-line
                    hide-details
                    clearable
                    @click:clear="$emit('search', null)"
                ></v-text-field>
                <v-btn
                    color="primary"
                    type="submit">
                    <v-icon>search</v-icon>
                </v-btn>
            </v-card-title>
        </v-form>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :options.sync="pagination"
            class="elevation-3"
            :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, {text: 'Всі', value: -1}]
            }"
        >
            <template v-slot:item="props">
                <tr>
                    <td class="text-xs-left">{{ props.item.name }}</td>
                    <td class="text-xs-left">{{ props.item.questionsCount }}</td>
                    <td class="justify-center layout px-0">
                        <v-btn icon class="mx-0" @click="$emit('editItem', props.item)">
                            <v-icon color="primary">edit</v-icon>
                        </v-btn>
                        <v-btn icon class="mx-0" @click="$emit('deleteItem', props.item.id)">
                            <v-icon color="red">delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loading: {
            type: Boolean,
        },
    },
    watch: {
        pagination(paginationVal, prevPaginationVal) {
            this.$emit("paginate", paginationVal);
        }
    },
    methods: {
        mapSpecialitiesToString(specialities) {
            return specialities.map(spec => spec.nameUk).join(", ");
        },
        submitSearch(e) {
            e.preventDefault();

            this.$emit('search', this.search);
        }
    },
    data: () => ({
        search: null,
        pagination: {
            totalItems: 0,
        },
        headers: [
            {text: 'Назва', value: 'name'},
            {text: 'К-сть запитань', value: 'questionsCount'},
            {text: 'Дії', value: 'actions', sortable: false}
        ],
    }),
}
</script>