<template>
    <v-container style="position: relative">
        <block-loader v-if="statLoading"/>
        <v-layout justify-center>
            <v-flex xs12>
                <table class="marks-table elevation-3 mb-6">
                    <tbody>
                    <tr v-for="(studentStat, index) in this.studentStatInfo">
                        <td class="counter-cell">{{index + 1}}.</td>
                        <td class="student-name">
                            {{studentStat.student.surname}} {{studentStat.student.name}}
                            {{studentStat.student.patronymic}}
                        </td>
                        <td class="module-overview-cell">
                            {{studentStat.markMeanValue.toFixed(2)}}
                        </td>
                        <td class="module-overview-cell">
                            <mean-mark-linear 
                                    :mark-mean-percentage="studentStat.markMeanPercentage" 
                                    :mark-mean-value="studentStat.markMeanValue"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import HttpService from "../../HttpService";
    import BlockLoader from "./BlockLoader";
    import MeanMarkLinear from "../common/MeanMarkLinear";

    export default {
        components: {
            BlockLoader,
            MeanMarkLinear
        },
        props: {
            activeModule: {
                type: Object
            }
        },
        data() {
            return {
                studentStatInfo: [],
                statLoading: false,
            }
        },
        mounted() {
            this.statLoading = true;
            HttpService.getInstance()
                .get(`teacher/teacherMarks/studentsMarksMean/${this.$route.params.group}/${this.$route.params.subject}`)
                .then(resp => {
                    this.studentStatInfo = resp;
                    this.statLoading = false;
                });
        },
    }
</script>

<style lang="scss">
    .register-container {
        padding: 5px;
    }

    .lesson-date {
        font-weight: 200;
    }

    .student-name-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 50px;
        font-weight: 200;
        color: #ccc;
    }

    .corner-left-bottom {
        border: 1px solid transparent;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight: normal;
        font-size: 0.9em;
        text-align: left;
        color: #ccc;
    }

    .student-name {
        padding: 0 10px 0 5px;
        min-width: 240px;
        max-width: 240px;
    }

    .counter-header,
    .counter-cell {
        width: 20px;
        text-align: right;
        padding-left: 10px;
    }

    .counter-header {
        border-bottom: 1px solid #ccc;
    }

    table.marks-table {
        border-collapse: collapse;
        margin: 0 auto;
        background: #fff;

        & tr {
            & td {
                border: 1px solid transparent;
                border-bottom: 1px solid #ccc;
                &.module-overview-cell {
                    border-right: 1px solid #ccc;
                    text-align: center;
                    padding: 0px 5px;
                }
            }
        }
    }
</style>