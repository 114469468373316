<template>
    <div>
        <rating-directions-stat-grid
                :items="items"
                :total="total"
                :loading="loading"
                @paginate="onPaginate"
                @navigateTo="navigateTo"
        >
        </rating-directions-stat-grid>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import {objectQueryStringify} from "../../../helpers/querystringHelper";
    import RatingDirectionsStatGrid from "./RatingDirectionsStatGrid";

    export default {
        components: {
            RatingDirectionsStatGrid
        },
        data: () => {
            return {
                items: [],
                total: null,
                loading: false,
                pagination: {
                    totalItems: 0,
                }
            }
        },
        methods: {
            navigateTo(directionId, routeName) {
                console.log("navigate to", directionId, routeName);
                
                this.$router.push({
                    name: routeName, 
                    params: {
                        direction: directionId,
                    }
                }).catch(err => {});  
            },
            getItems(queryParams) {
                this.loading = true;

                HttpService.getInstance()
                    .get(`ratingUser/getDirectionsWithAllSum`)
                    .then(items => {
                        this.items = items;
                        this.total = items.length;
                        
                        this.loading = false;
                    })
            },
            onPaginate(paginationData) {
                if (this.items.length === 0 || 
                    JSON.stringify(this.pagination) !== JSON.stringify(paginationData)) {
                    this.getItems(objectQueryStringify(paginationData));
                }

                this.pagination = paginationData;
            },
        },
    }
</script>