<template>
    <div>
        <v-layout row wrap class="mt-6">
            <v-flex xs12 text-center>
                <div class="display-1">Дні народження</div>
            </v-flex>
        </v-layout>
        <v-layout row wrap justify-center>
            <v-flex xs12 sm6>
                <div class="headline d-flex justify-center mb-2">
                    <v-icon class="mr-2">people</v-icon>Викладачі
                </div>
                <v-layout row wrap justify-center>
                    <v-layout align-center justify-center v-if="professorsLoading">
                        <v-progress-circular
                                :size="150"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-layout>
                    <v-flex xs10 sm5 v-for="(professorBirthday, index) in professorBirthdays" :key="index" v-if="!professorsLoading">
                        <v-card class="mx-1 mb-3" v-if="professorBirthday">
                            <div class="ribbon-wrapper-red" v-if="professorBirthday.isAnniversary">
                                <div class="ribbon-red">Ювілей</div>
                            </div>
                            <v-img
                                    v-if="professorBirthday.photoName"
                                    :src="professorBirthday.photoName"
                                    height="200px"
                            ></v-img>
                            <v-img
                                    v-if="!professorBirthday.photoName && professorBirthday.gender === gendersEnum.male"
                                    :src="professorMaleMockPhoto"
                                    height="200px"
                            ></v-img>
                            <v-img
                                    v-if="!professorBirthday.photoName && professorBirthday.gender === gendersEnum.female"
                                    :src="professorFemaleMockPhoto"
                                    height="200px"
                            ></v-img>

                            <v-img
                                    v-if="!professorBirthday.photoName && (professorBirthday.gender === undefined || professorBirthday.gender === null)"
                                    :src="professorMockPhoto"
                                    height="200px"
                            ></v-img>

                            <v-card-title>
                                <fit-text>{{professorBirthday.surname}} {{professorBirthday.name}}</fit-text>
                            </v-card-title>

                            <v-card-subtitle class="mb-0 py-1 subtitle-min-height">
                                {{professorBirthday.scientificDegree ? professorBirthday.scientificDegree.nameUk : ""}}
                                <span v-if="professorBirthday.scientificDegree">, </span> 
                                {{professorBirthday.academicPosition ? professorBirthday.academicPosition.nameUk : ""}}
                            </v-card-subtitle>

                            <v-card-subtitle class="mt-0 py-1 subtitle-min-height">
                                <div v-if="professorBirthday.departments.length > 0" 
                                     v-for="department in professorBirthday.departments">Кафедра: {{department.nameUk}}</div>
                            </v-card-subtitle>

                            <v-card-actions>
                                <student-birthday-congrats :user-item="professorBirthday"></student-birthday-congrats>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                    <v-flex v-if="professorBirthdays.length === 0 && !professorsLoading">
                        <v-alert type="info" outlined>
                            На сьогодні немає днів народження викладачів...
                        </v-alert>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm6>
                <div class="headline d-flex justify-center mb-2">
                    <v-icon class="mr-2">school</v-icon>Студенти
                </div>
                <v-layout row wrap justify-center>
                    <v-layout align-center justify-center v-if="studentsLoading">
                        <v-progress-circular
                                :size="150"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-layout>
                    <v-flex xs10 sm5 v-for="(studentBirthday, index) in studentBirthdays" :key="index" v-if="!studentsLoading">
                        <v-card class="mx-1 mb-3" v-if="studentBirthday">
                            <div class="ribbon-wrapper-red" v-if="studentBirthday.isAnniversary">
                                <div class="ribbon-red">Ювілей</div>
                            </div>
                            <v-img
                                    v-if="studentBirthday.photoName"
                                    :src="`${$config.apiEndpoint}/file/get/profile/${studentBirthday.photoName}`"
                                    height="200px"
                            ></v-img>
                            <v-img
                                    v-if="!studentBirthday.photoName && studentBirthday.gender === gendersEnum.male"
                                    :src="studentMaleMockPhoto"
                                    height="200px"
                            ></v-img>
                            <v-img
                                    v-if="!studentBirthday.photoName && studentBirthday.gender === gendersEnum.female"
                                    :src="studentFemaleMockPhoto"
                                    height="200px"
                            ></v-img>

                            <v-img
                                    v-if="!studentBirthday.photoName && (studentBirthday.gender === undefined || studentBirthday.gender === null)"
                                    :src="studentMockPhoto"
                                    height="200px"
                            ></v-img>

                            <v-card-title>
                                <fit-text>{{studentBirthday.surname}} {{studentBirthday.name}}</fit-text>
                            </v-card-title>

                            <v-card-subtitle class="mb-0 py-1 subtitle-min-height">
                                <div>{{studentBirthday.faculty ? studentBirthday.faculty.nameUk : ""}}</div>
                                <div>{{studentBirthday.speciality ? studentBirthday.speciality.nameUk : ""}}</div>
                            </v-card-subtitle>

                            <v-card-subtitle class="mb-0 py-1 subtitle-min-height">
                                <div>{{studentBirthday.semester ? studentBirthday.semester.yearNumber : ""}} курс</div>
                                <div v-if="studentBirthday.academicGroup">{{studentBirthday.academicGroup.groupNumber}} група</div>
                            </v-card-subtitle>

                            <v-card-actions>
                                <student-birthday-congrats :is-student="true" :user-item="studentBirthday"></student-birthday-congrats>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                    <v-flex v-if="studentBirthdays.length === 0 && !studentsLoading">
                        <v-alert type="info" outlined>
                            На сьогодні немає днів народження студентів...
                        </v-alert>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
    import HttpService from "../../HttpService";
    import {gendersEnum} from "../../constants/_internal/gendersEnum";

    import studentMockPhoto from "../../assets/images/student-photo-mock/student.png";
    import studentMaleMockPhoto from "../../assets/images/student-photo-mock/student_male.png";
    import studentFemaleMockPhoto from "../../assets/images/student-photo-mock/student_female.png";

    import professorMockPhoto from "../../assets/images/professor-photo-mock/professor.jpg";
    import professorMaleMockPhoto from "../../assets/images/professor-photo-mock/professor_male.png";
    import professorFemaleMockPhoto from "../../assets/images/professor-photo-mock/professor_female.png";
    import StudentBirthdayCongrats from "./birthdays/StudentBirthdayCongrats";
    import FitText from "../../helpers/FitText";


    export default {
        components: {
            StudentBirthdayCongrats,
            FitText,
        },
        data: () => ({
            professorBirthdays: [],
            studentBirthdays: [],
            
            professorsLoading: false,
            studentsLoading: false,

            gendersEnum,

            studentMockPhoto,
            studentMaleMockPhoto,
            studentFemaleMockPhoto,

            professorMockPhoto,
            professorMaleMockPhoto,
            professorFemaleMockPhoto,
        }),
        mounted() {
            this.professorsLoading = true;
            this.studentsLoading = true;
            
            HttpService.getInstance()
                .get("birthday/professor")
                .then(resp => {
                    this.professorBirthdays = resp;

                    this.professorsLoading = false;
                });

            HttpService.getInstance()
                .get("birthday/student")
                .then(resp => {
                    this.studentBirthdays = resp;

                    this.studentsLoading = false;
                })
        },
    }
</script>
<style lang="scss">
    .subtitle-min-height {
        min-height: 5em;
    }

    .ribbon-wrapper-red {
        width: 85px;
        height: 88px;
        overflow: hidden;
        position: absolute;
        top: -3px;
        right: -3px;
    }
    .ribbon-red {
        font: bold 15px Sans-Serif;
        color: #fff;
        text-align: center;
        text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        position: relative;
        padding: 7px 0;
        left: -5px;
        top: 15px;
        width: 120px;
        background-color: #ea181e;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ea181e), to(#b90005));
        background-image: -webkit-linear-gradient(top, #ea181e, #b90005);
        background-image: -moz-linear-gradient(top, #BFDC7A, #8EBF45);
        background-image: -ms-linear-gradient(top, #BFDC7A, #8EBF45);
        background-image: -o-linear-gradient(top, #BFDC7A, #8EBF45);
        color: #fff;
        -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        z-index: 2;
    }

    .ribbon-red:before,
    .ribbon-red:after{
        content: "";
        border-top: 3px solid #b90005;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        position:absolute;
        bottom: -3px;
    }
    .ribbon-red:before{
        left: 0;
    }

    .ribbon-red:after{
        right: 0;
    }
</style>