<template>
    <div>
        <v-container grid-list-md fluid fill-height>
            <v-layout row wrap xs12>
                <v-carousel
                        :interval="12000"
                        dark
                        cycle
                        height="700"
                        hide-delimiter-background
                        show-arrows-on-hover
                >
                    <v-carousel-item
                            :key="index" v-for="(item, index) in statItems"
                    >
                        <v-sheet
                                light
                                height="100%"
                                class="pa-4"
                        >
                            <v-card class="mb-5 pb-5">
                                <v-layout row wrap xs12>
                                    <v-flex xs12>
                                        <div class="pa-2 title text-center">{{item.name}}</div>
                                    </v-flex>
                                    <v-flex xs12>
                                        <div style="position: relative;">
                                            <horizontal-bar-chart style="height: 85px;"
                                                                  :chart-data="getChartData(item)"
                                                                  :options="chartOptions"
                                            ></horizontal-bar-chart>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 class="px-5 py-2">
                                        <v-layout row wrap xs12>
                                            <v-flex xs4 :key="ind" v-for="(exam, ind) in item.examItems">
                                                <v-card elevation="8" class="pa-2 mx-2 my-1">
                                                    <v-layout row wrap xs12>
                                                        <v-flex xs5>
                                                            <div class="caption">Студент</div>
                                                            <div class="subtitle-2">
                                                                {{exam.currentStudent.surname}}
                                                                {{exam.currentStudent.name.charAt(0)}}.
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs3>
                                                            <div class="caption">Час іспиту</div>
                                                            <div class="caption">
                                                                {{moment(exam.examStartTime).format("HH:mm")}}-{{moment(exam.examEndTime).format("HH:mm")}}
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs4 class="text-right">
                                                            <div class="caption">Екзаменатор</div>
                                                            <div class="subtitle-2">
                                                                {{exam.currentProfessor.surname}}
                                                                {{exam.currentProfessor.name.charAt(0)}}.
                                                                {{exam.currentProfessor.patronymic.charAt(0)}}.
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-card>
                                            </v-flex>
                                            <v-flex xs12 v-if="item.examItems.length === 0">
                                                <div class="subtitle-1 text-center text-italic">Даний іспит завершено</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    import HttpService from "../../../HttpService";
    import moment from "moment";
    import HorizontalBarChart from "../../common/statistics/HorizontalBarChart";
    import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        components: {
            HorizontalBarChart
        },
        data: () => ({
            date: moment().format("DD-MM-YYYY"),
            statItems: [],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            },
            moment,
        }),
        mounted() {
            this.getExamStats();

            this.interval = setInterval(function () {
                this.getExamStats();
            }.bind(this), 30000);
        },
        methods: {
            getExamStats() {
                HttpService.getInstance()
                    .get(`FinalExamAdminManage/GetExamStatsAll?dateStr=${this.date}`)
                    .then(items => {
                        this.statItems = items;
                    });
            },
            getChartData(stats) {
                return {
                    barThickness: 20,
                    datasets: [
                        {
                            label: "Склали",
                            barThickness: 30,
                            maxBarThickness: 30,
                            backgroundColor: '#43A047',
                            data: [stats.passed],
                            datalabels: {
                                color: '#fff',
                                font: {
                                    size: 16,
                                }
                            }
                        },
                        {
                            label: "Повинні скласти",
                            barThickness: 25,
                            maxBarThickness: 25,
                            data: [stats.needToPass],
                            backgroundColor: '#FB8C00',
                            datalabels: {
                                color: '#fff',
                                font: {
                                    size: 16,
                                }
                            }
                        }
                    ],
                    plugins: [ChartDataLabels],
                }
            }
        }
    }
</script>