<template>
    <v-card>
        <v-card-title class="title">Зняття затвердження звіту</v-card-title>
        <v-card-text>
            Ви впевнені, що хочете ОБИДВА затвердження рейтигового звіту?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    color="primary"
                    @click.native="$emit('shouldCloseConfirm')"
                    :disabled="loading"
            >Ні
            </v-btn>
            <v-btn
                    color="primary"
                    text
                    @click.native="$emit('shouldConfirm')"
                    :loading="loading"
                    :disabled="loading"
            >Так
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import HttpService from "../../../HttpService";

    export default {
        props: {
            loading: Boolean,
            deleteId: Number,
        },
    }
</script>