<template>
    <v-container fluid fill-height>
        <v-layout style="display: block;">
            <ratingist-check-index/>
        </v-layout>
    </v-container>
</template>
<script>
    import RatingistCheckIndex from "../../components/ratingAdmin/ratingistCheck/RatingistCheckIndex";

    export default  {
        components: {
            RatingistCheckIndex,
        }
    }
</script>