import {rolesEnum} from "../rolesEnum";

export const announcementFromTypesEnum = {
    educationalDpt: 1,
    deanOffice: 2,
    department: 3,
    curator: 4,
};

export default [
    {
        id: announcementFromTypesEnum.educationalDpt,
        nameConst: "educationalDpt",
        name: "Навчальний відділ",
        roles: [rolesEnum.edudept, rolesEnum.admin]
    },
    {
        id: announcementFromTypesEnum.deanOffice,
        nameConst: "deanOffice",
        name: "Деканат",
        roles: [rolesEnum.dean, rolesEnum.inspector]
    },
    {
        id: announcementFromTypesEnum.department,
        nameConst: "department",
        name: "Кафедра",
        roles: [rolesEnum.headDepartment, rolesEnum.moderator, rolesEnum.admin]
    },
    {
        id: announcementFromTypesEnum.curator,
        nameConst: "curator",
        name: "Куратор",
        roles: [rolesEnum.curator, rolesEnum.admin]
    }
];