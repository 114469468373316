<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap>
            <v-flex>
                <div class="display-1 mb-4">{{$t("finalExam.resultGraduationExams")}}</div>
                <v-alert type="info" v-if="exams.length === 0">
                    {{$t("finalExam.noGraduationExamResults")}}
                </v-alert>
                <v-card :key="index" v-for="(exam, index) in exams" class="mb-3 px-4 py-2">
                        <v-layout wrap>
                            <v-flex xs6>
                                <div class="body-2">{{$t("finalExam.examName")}}</div>
                                <div class="title">
                                    {{exam.finalExamName.name}}
                                </div>
                            </v-flex>
                            <v-flex xs3>
                                <div class="body-2">{{$t("finalExam.examResultTraditional")}}</div>
                                <div class="title">
                                    <div v-if="exam.totalClassicValue !== null">{{exam.totalClassicValue}}</div>
                                    <div class="subtitle-2" v-if="exam.totalClassicValue === null"><i>{{$t("finalExam.noPublishedResult")}}</i></div>
                                </div>
                            </v-flex>
                            <v-flex xs3>
                                <div class="body-2">{{$t("finalExam.examResultEcts")}}</div>
                                <div class="title">
                                    <div v-if="exam.totalValue !== null">{{exam.totalValue}}</div>
                                    <div class="subtitle-2" v-if="exam.totalValue === null"><i>{{$t("finalExam.noPublishedResult")}}</i></div>
                                </div>
                            </v-flex>
                           
                        </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import moment from "moment";
    import {formatLongDate} from "../../../helpers/dateHelper";
    import HttpService from "../../../HttpService";

    export default {
        data: () => ({
            exams: [],
            formatLongDate,
        }),
        mounted() {
            this.getExamsList();
        },
        methods: {
            getExamsList() {
                HttpService.getInstance()
                    .get("FinalExamStudent/GetStudentExamResults")
                    .then(resp => {
                        this.exams = resp;
                    })
            },
        }
    }
</script>