<template>
    <v-container
            fluid
            grid-list-lg
    >
        <v-layout row wrap>
            <v-flex xs12>
                <marks-wrapper/>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import MarksWrapper from "../../../components/teacher/marks/MarksWrapper";

    export default {
        components: {
            MarksWrapper
        }
    }
</script>