<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Лекційні потоки</v-subheader>
                            <navigation-list-template :loading="isFlowsLoading" :items="flows"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex v-if="!isSelectedFlow" xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть лекційний потік
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!--<group-distribution-list v-if="isSelectedFlow"/>-->
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import HttpService from "../../HttpService";
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    // import GroupDistributionList from "../../components/moderator/groupDistribution/GroupDistributionList";

    export default {
        components: {
            NavigationListTemplate,
            // GroupDistributionList,
        },
        data: () => ({
            isBlank: true,
            flows: [],
            isSelectedFlow: false,
            isFlowsLoading: true,
        }),
        mounted() {
            this.isSelectedFlow = this.$route.params.flow !== undefined;

            if (this.$route.params.department !== undefined && this.$route.params.subject !== undefined) {
                this.getFlows();
            }
            
            this.checkIfIsBlank(this.$route);
        },
        methods: {
            getFlows() {
                this.isFlowsLoading = true;
                
                HttpService.getInstance()
                    .get(`lecturesSchedule/getAll/${this.$route.params.subject}`)
                    .then(res => {
                        this.flows = res.map(flow => {
                            flow.name = `${flow.name} (${flow.subject.nameUk}, ${flow.semester.yearNumber} курс, ${flow.speciality.nameShortUk})`;
                            flow.route = {
                                name: 'flowSelection',
                                params: {
                                    department: this.$route.params.department,
                                    subject: this.$route.params.subject,
                                    flow: flow.id,
                                }
                            };

                            return flow;
                        });
                        this.isFlowsLoading = false;
                    });
            },
            checkIfIsBlank(route) {
                if (route.params.department !== undefined && route.params.subject !== undefined) {
                    this.isBlank = false;
                }
                else {
                    this.isBlank = true;
                }
            }
        },
        watch: {
            $route: function(route, prevRoute) {
                if (!route.params.flow) {
                    this.flows = [];
                }
                
                if (route.params.department !== undefined && 
                    route.params.subject !== undefined && 
                    prevRoute.params.subject !== route.params.subject) {
                    
                    this.getFlows();
                }
                if (route.params.flow !== prevRoute.params.flow) {
                    this.isSelectedFlow = this.$route.params.flow !== undefined;
                }
                
                this.checkIfIsBlank(route);
            },
        }
    }
</script>