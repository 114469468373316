<template>
    <v-menu
            v-model="menu"
            bottom left
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
            transition="slide-x-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs"
                    v-on="on"
                    color="red">error_outline
            </v-icon>
        </template>

        <v-card>
            <v-card-text>
                <v-textarea
                        :disabled="!isRatingist()"
                        v-model="comment"
                        solo
                        label="Причина відхилення"
                ></v-textarea>
                <v-btn v-if="isRatingist()" :disabled="loadingSave" @click="modifyComment()" color="primary">Зберегти
                </v-btn>
            </v-card-text>
        </v-card>
    </v-menu>
</template>
<script>
    import {rolesEnum} from "../../../constants/rolesEnum";
    import HttpService from "../../../HttpService";

    export default {
        props: {
            comment: {
                type: String,
                default: "",
            },
            id: {
                type: Number,
            }
        },
        methods: {
            isRatingist() {
                return this.$auth.userHasAtLeastOneRole([rolesEnum.admin, rolesEnum.ratingist, rolesEnum.ratingistChecker]);
            },
            modifyComment() {
                this.loadingSave = true;
                HttpService.getInstance()
                    .post(`ratingAdmin/rejectPosition/${this.id}`, {reason: this.comment})
                    .then(_ => {
                        this.loadingSave = false;

                        this.$emit("modified");
                    });
            }
        },
        data: () => ({
            menu: false,
            loadingSave: false,
        }),
    }
</script>