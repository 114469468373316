<template>
    <v-container grid-list-md fluid fill-height>
        <v-layout row wrap style="width: 100%;">
            <v-flex v-if="showDirectionsBlock()" style="height: calc(100% - 100px); position: fixed; width: 300px;">
                <v-card height="100%">
                    <v-btn :to="{name: 'ratingDirections'}"
                           color="primary"
                           block
                           class="px-3"
                    ><v-icon>keyboard_backspace</v-icon> Перейти до напрямків</v-btn>
                    <v-navigation-drawer :mobile-breakpoint="0" :width="300">
                        <v-list dense>
                            <v-subheader class="mt-4 grey--name name--darken-1">Напрямки рейтингування</v-subheader>
                            <navigation-list-template :items="ratingDirections"/>
                        </v-list>
                    </v-navigation-drawer>
                </v-card>
            </v-flex>

            <v-flex style="margin-left: 300px; width: calc(100% - 460px);">
                <v-card v-if="!$route.params.direction" >
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <div class="headline">
                                    <v-icon>keyboard_backspace</v-icon>
                                    Виберіть напрямок
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
                <rating-worktypes-index :key="$route.fullPath" v-if="$route.params.direction"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import NavigationListTemplate from "../../components/common/NavigationListTemplate";
    import HttpService from "../../HttpService";
    import RatingWorktypesIndex from "../../components/ratingAdmin/ratingWorktypes/RatingWorktypesIndex";

    export default {
        components: {
            NavigationListTemplate,
            RatingWorktypesIndex
        },
        data: () => ({
            ratingDirections: [],
        }),
        mounted() {
            HttpService.getInstance()
                .get(`ratingDirections/getAll`)
                .then(resp => {
                    this.ratingDirections = resp.map(direction => {
                        direction.name = `${direction.order}. ${direction.directionName}`;
                        direction.route = {
                            name: 'ratingIndexes',
                            params: {
                                direction: direction.id
                            }
                        };

                        return direction;
                    });
                });
        },
        methods: {
            showDirectionsBlock() {
                return this.$route.name === "ratingIndexes" && this.$route.params.direction;
            },

        },
    }
</script>