<template>
    <v-container>
        <v-layout justify-center>
            <v-flex xs4>
                <v-checkbox
                        v-model="selectAllGroups"
                        label="Вибрати всі групи"
                ></v-checkbox>
            </v-flex>
            <v-flex xs4>
                <v-btn @click="dialogStartQuiz = true" v-if="groupsSelected.length !== 0">Розпочати тестування</v-btn>
            </v-flex>
            <v-flex xs4></v-flex>
        </v-layout>
        <v-expansion-panels                     lazy
                                                ripple
                                                v-for="(group, index) in groups"
                                                :key="group.id">
        <v-expansion-panel v-model="expansionModel">
            <v-expansion-panel-header>
                <v-checkbox
                    @click.native.stop
                    class="mt-0"
                    v-model="groupsSelected"
                    :label="`${group.groupNumber} група`"
                    :value="group"
                    hide-details
                ></v-checkbox>
            </v-expansion-panel-header>
            <v-expansion-panel-content

            >
                <v-card>
                    <management-group-students
                            :academic-group-id="group.id"
                            :start-group-quiz-url="startGroupQuizUrl"
                            :group-quiz-results-url="groupQuizResultsUrl"
                            :is-kti="isKti"
                    />

                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
        
        <v-dialog v-model="dialogStartQuiz" max-width="700" persistent>
            <confirm-quiz-semester
                    v-if="dialogStartQuiz"
                    :selected-groups="groupsSelected"
                    @shouldClose="dialogStartQuiz = false"
                    @started="started"
                    :start-semester-quiz-url="startSemesterQuizUrl"
            ></confirm-quiz-semester>
        </v-dialog>
        
    </v-container>
</template>

<script>
    import HttpService from "../../../HttpService";
    import ManagementGroupStudents from "./ManagementGroupStudents";
    import ConfirmQuizSemester from "./ConfirmQuizSemester";

    export default {
        data: () => ({
            groups: [],
            groupsSelected: [],
            selectAllGroups: false,
            expansionModel: null,
            dialogStartQuiz: false,
        }),
        props: {
            startSemesterQuizUrl: String,
            startGroupQuizUrl: String,
            groupQuizResultsUrl: String,  
            isKti: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            ManagementGroupStudents,
            ConfirmQuizSemester
        },
        methods: {
            selectAll() {
                this.groupsSelected = this.groups;
            },
            getItems() {
                HttpService.getInstance()
                    .get(`teacher/navigation/academicGroups/${this.$route.params.faculty}/${this.$route.params.speciality}/${this.$route.params.semester}`)
                    .then(resp => {
                        this.groups = resp;
                    });
            },
            started() {
                
            }
        },
        mounted() {
            this.getItems();
        },
        watch: {
            "$route.params": function (newParams, oldParams) {
                if (newParams.speciality !== oldParams.speciality ||
                    newParams.faculty !== oldParams.faculty ||
                    newParams.semester !== oldParams.semester
                ) {
                    this.getItems();
                }
            },
            selectAllGroups: function (newVal, oldVal) {
                if (newVal) {
                    this.selectAll();
                    this.expansionModel = null;
                }
                else {
                    this.groupsSelected = [];
                    this.expansionModel = null;
                }
            }
        },
    };
</script>