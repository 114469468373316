<template>
    <div>
        <v-menu
                v-if="topicInfo.lectureTopicClass"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                transition="slide-x-transition"
                class="class-date-topic"
        >
            <template v-slot:activator="{ on }">
                <span v-on="on"
                      :class="`cursor-pointer cell-lesson-date 
                        ${(topicInfo.evaluationMode === topicEvaluationModeEnum.isNotEvaluated) ? 'yellow lighten-4': ''}`">
                    <slot></slot>
                    <v-icon v-if="getMarkCouldBeEditedDeleted" small>edit</v-icon>
                </span>
            </template>
            <v-card class="class-header-popover">
                <v-card-text>
                    <div>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Тип заняття: </span>
                            лекція
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Номер лекції: </span>
                            {{topicInfo.orderNumber}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Кількість годин: </span>
                            {{topicInfo.academicHours}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Дата лекції: </span>
                            {{formatShortDate(topicInfo.lectureTopicClass.classDate)}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Дата внесення: </span>
                            {{formatLongDate(topicInfo.lectureTopicClass.inputDate)}}
                        </v-flex>
                        <v-flex xs12 style="max-width: 500px">
                            <span class="subtitle-1 font-weight-bold">Тема: </span>
                            {{topicInfo.nameUk}}
                        </v-flex>
                        <v-flex xs12>
                            <span class="subtitle-1 font-weight-bold">Лектор: </span>
                            {{`${topicInfo.professor.surname} ${topicInfo.professor.name.charAt(0)}.
                            ${topicInfo.professor.patronymic.charAt(0)}.`}}
                        </v-flex>
                    </div>
                    <div>
                        <v-btn v-if="topicInfo.lectureTopicClass.editType !== null"
                               color="amber darken-4"
                               dark
                               @click="dialogHistory = true">
                            <v-icon>schedule</v-icon>
                            Історія
                        </v-btn>
                    </div>


                    <template v-if="loading">
                        <v-container align-center align-content-center grid-list-md fluid fill-height>
                            <v-layout row wrap justi>
                                <v-progress-circular
                                        :size="50"
                                        color="primary"
                                        indeterminate
                                ></v-progress-circular>
                            </v-layout>
                        </v-container>
                    </template>

                    <div v-if="!loading" class="mt-5 ml-3">
                        <v-layout row wrap justify-center>
                            <v-flex xs5>
                                <v-tooltip bottom v-if="canEdit">
                                    <template v-slot:activator="{on}">
                                        <v-btn 
                                               v-on="on"
                                               color="primary"
                                               @click="dialogClassEdit = true"
                                               class="mr-2"
                                               
                                               fab
                                        >
                                            <v-icon>create</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Редагувати</span>
                                </v-tooltip>
                                <div class="grey--text" v-if="canEdit">Редагування можливе до {{formatLongDate(getMarkEditTimeTill)}}</div>
                                <div class="grey--text" v-if="!canEdit">
                                    Редагування недоступне
                                </div>
                            </v-flex>
                            <v-flex xs5 class="ml-2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{on}" v-if="canDelete">
                                        <v-btn v-on="on"
                                               color="red"
                                               dark
                                               
                                               fab
                                               @click="dialogClassRemove = true"
                                        >
                                            <v-icon>clear</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Видалити</span>
                                </v-tooltip>
                                <div class="grey--text" v-if="canDelete">Видалення можливе до {{formatLongDate(getMarkDeleteTimeTill)}}</div>
                                <div class="grey--text" v-if="!canDelete">
                                    Видалення недоступне
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
            </v-card>
        </v-menu>
        <div v-if="!topicInfo.lectureTopicClass">
            <slot></slot>
        </div>

        <v-dialog v-model="dialogClassRemove" max-width="400">
            <delete-class-confirm
                    v-if="dialogClassRemove"
                    @shouldCloseDeleteConfirm="dialogClassRemove = false"
                    @removed="removed"
                    :topic-info="topicInfo"
            >
            </delete-class-confirm>
        </v-dialog>

        <v-dialog v-model="dialogClassEdit" max-width="700" scrollable>
            <edit-mark-dialog
                    v-if="dialogClassEdit"
                    :active-module="activeModule"
                    :topicInfo="topicInfo"
                    :selectedTopics="[topicInfo.lectureTopicClass]"
                    @shouldClose="dialogClassEdit = false"
                    @shouldSaveAndClose="shouldSaveAndClose()"
            />
        </v-dialog>

        <v-dialog v-model="dialogHistory" max-width="700" scrollable>
            <mark-history-dialog
                    :topicInfo="topicInfo"
                    @shouldClose="dialogHistory = false"
            />
        </v-dialog>
    </div>
</template>
<script>
    import moment from "moment";

    import {formatShortDate, formatLongDate} from "../../../helpers/dateHelper";
    import topicEvaluationModeTypes, {topicEvaluationModeEnum} from "../../../constants/topicEvaluationModeTypes";
    import DeleteClassConfirm from "./../marks-management/DeleteClassConfirm";
    import EditMarkDialog from "./../marks-management/EditMarkDialog";
    import MarkHistoryDialog from "./../marks-management/MarkHistoryDialog";
    import userRoleMatrix from "../../../roles-acl/roles-acl";
    import HttpService from "../../../HttpService";

    export default {
        components: {
            DeleteClassConfirm,
            EditMarkDialog,
            MarkHistoryDialog
        },
        props: {
            topicInfo: {
                type: Object,
                default: () => {
                    return {
                        lectureTopicClass: null,
                    };
                }
            },
            activeModule: {
                type: Object,
            },
            markEditDeleteSettings: {
                type: Object,
            }
        },
        data: () => ({
            menu: false,
            dialogClassRemove: false,
            dialogClassEdit: false,
            dialogHistory: false,
            loading: false,

            canEdit: false,
            canDelete: false,

            formatShortDate,
            formatLongDate,
            topicEvaluationModeEnum,
            userRoleMatrix
        }),
        watch: {
            "menu": function (newMenuValue, oldMenuValue) {
                if (newMenuValue !== oldMenuValue && newMenuValue === true) {
                    this.loading = true;

                    HttpService.getInstance()
                        .get(`teacher/teacherMarks/getMarksListTopic/${this.topicInfo.lectureTopicClass.id}`)
                        .then(resp => {
                            this.loading = false;

                            this.canEdit = resp.canEdit;
                            this.canDelete = resp.canDelete;
                        });
                }
            }
        },
        computed: {
            getMarkCouldBeEditedDeleted() {
                const editTillDate = moment(this.topicInfo.lectureTopicClass.inputDate).add(this.markEditDeleteSettings.markEditMinutes, "minutes");
                const deleteTillDate = moment(this.topicInfo.lectureTopicClass.inputDate).add(this.markEditDeleteSettings.markDeleteMinutes, "minutes");

                if (editTillDate.isSameOrAfter(moment()) && deleteTillDate.isSameOrAfter(moment())) {
                    return true;
                } else {
                    return false;
                }
            },
            getMarkEditTimeTill() {
                return moment(this.topicInfo.lectureTopicClass.inputDate).add(this.markEditDeleteSettings.markEditMinutes, "minutes");
            },
            getMarkDeleteTimeTill() {
                return moment(this.topicInfo.lectureTopicClass.inputDate).add(this.markEditDeleteSettings.markEditMinutes, "minutes");
            }
        },
        methods: {
            shouldSaveAndClose() {
                this.dialogClassEdit = false;
                this.$emit("save");
            },
            getEvaluationMode(evaluationMode) {
                const evaluationModeFound = topicEvaluationModeTypes.find(t => t.id === evaluationMode);

                if (!evaluationModeFound) {
                    return "";
                }

                return evaluationModeFound.nameUk;
            },
            removed() {
                this.dialogClassRemove = false;
                this.$emit("removed");
            },
            getUserRole() {
                return this.$auth.getRoles();
            }
        }
    }
</script>
<style lang="scss">
    .class-header-popover {
        max-width: 345px;
    }
</style>