<template>
    <form @submit="submitForm" novalidate="true">
        <v-card>
            <v-card-title>
                <span class="headline">Переміщення вибраних груп</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout v-if="!loading" wrap>
                        <v-select
                                :items="faculties"
                                :error-messages="errors.faculty"
                                v-model="form.faculty"
                                item-text="nameUk"
                                item-value="id"
                                return-object
                                label="Факультет"
                        ></v-select>
                    </v-layout>
                    <v-layout v-if="!loading && form.faculty" wrap>
                        <v-select
                                :items="specialities"
                                :error-messages="errors.speciality"
                                v-model="form.speciality"
                                item-text="nameUk"
                                item-value="id"
                                return-object
                                label="Спеціальність"
                        ></v-select>
                    </v-layout>
                    <v-layout v-if="!loading && form.speciality" wrap>
                        <v-select
                                :items="semesters"
                                :error-messages="errors.semester"
                                v-model="form.semester"
                                item-text="value"
                                item-value="id"
                                return-object
                                label="Семестер"
                        ></v-select>
                    </v-layout>
                    <loading-placeholder v-if="loading"/>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click.native="$emit('shouldCloseGroupMove')"
                >Відміна
                </v-btn>
                <v-btn
                        color="primary"
                        type="submit"
                        :loading="loadingSave"
                        :disabled="loadingSave"
                >Перемістити
                </v-btn>
            </v-card-actions>
        </v-card>
    </form>
</template>

<script>
    import HttpService from "../../../../HttpService";
    import {remapErrors} from "../../../../helpers/errorsHelper";
    import LoadingPlaceholder from "../../../common/LoadingPlaceholder";

    export default {
        props: {
            selected: Array,
        },
        components: {
            LoadingPlaceholder,
        },
        data: () => {
            return {
                faculties: [],
                specialities: [],
                semesters: [],
                
                form: {
                    faculty: null, 
                    speciality: null, 
                    semester: null, 
                },
                errors: {
                    faculty: [],
                    speciality: [],
                    semester: [],
                },
                loading: false,
                loadingSave: false,
            }
        },
        mounted() {
            this.loading = true;
            
            HttpService.getInstance()
                .get("faculties/getAll")
                .then(resp => {
                    this.loading = false;
                    this.faculties = resp 
                });
        },
        watch: {
          "form.faculty": function (newFaculty, oldFaculty) {
              if (newFaculty !== oldFaculty) {
                  HttpService.getInstance()
                      .get(`specialities/getAllByFaculty/${newFaculty.id}`)
                      .then(resp => {
                         this.specialities = resp; 
                      });
              }
          },
            "form.speciality": function (newSpeciality, oldSpeciality) {
                if (newSpeciality !== oldSpeciality) {
                    HttpService.getInstance()
                        .get(`semester/semesterBySpeciality/${newSpeciality.id}`)
                        .then(resp => {
                           this.semesters = resp.map(r => {
                               r.value = `${r.yearNumber} курс (${r.semesterNumber} семестр)`;
                               
                               return r;
                           });
                        });
                }
            }
        },
        methods: {
            submitForm(e) {
                e.preventDefault();

                this.loadingSave = true;

                HttpService.getInstance()
                    .post("academicGroup/move", {...this.form, ...{academicGroups: this.selected}})
                    .then(resp => {
                        this.loadingSave = false;
                        this.$emit("groupMoved");
                    })
                    .catch(err => {
                        this.loadingSave = false;
                        if (err.errors) {
                            this.errors = remapErrors(err.errors);
                        }
                    });
            },
        }
    }
</script>